import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

import Select from "react-select";
import Moment from "react-moment";

import Box from "@mui/material/Box";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import { isEmpty } from "pages/Common/textUtilities";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";

const ClaimsProcessClaimtOrderDtls = (props) => {
  const {
    viewOnlyTab,
    setViewOnlyTab,
    setRequestTab,
    handleSnack,
    claimOrderData,
    setClaimOrderData,
    claimRecordData,
    setClaimRecordData,
    claimRecordId,
    claimNumber,
    machineData,
    warrantyId,
    claimApprover,
    setClaimApprover,
    failureCode,
    setFailureCode,
    openAttachmentMenu,
    attachmentMenuAnchorEl,
    claimType = [],
    claimStatus = [],
    claimRequestTypeOptions = [],
    claimStatusOptions = [],
    rmaTypeOptions = [],
    rmaResonOptions = [],
    failureCodeOptions = [],
    replacement,
    setReplacement,
    handleClaimRecordDataChange,
    handleOpenAttachmentMenu,
    handleCloseAttachmentMenu,
    handleShowFilesModal,
    handleSaveClaimOrderChanges,
  } = props;

  // claim tab input text change
  const handleClaimTabInputTextChange = (e) => {
    const { name, value } = e.target;
    setClaimOrderData({ ...claimOrderData, [name]: value });
  };

  // Individual claim record Select & date field value change
  const handleClaimRecordSelectDataChange = (e, keyName) => {
    // const { name, value } = e.;
    setClaimRecordData({ ...claimRecordData, [keyName]: e });
  };

  // claim tab select & Date value change
  const handleClaimTabSelectValueChange = (e, keyName) => {
    setClaimOrderData({ ...claimOrderData, [keyName]: e });
  };

  return (
    <>
      {!viewOnlyTab.claimViewOnly ? (
        <>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-2">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimOrderData?.claiment}
                    disabled
                    placeholder="Claiment"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY REQUEST NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimRecordId}
                    placeholder="Claim Request Id"
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PARTNER NAME</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimOrderData?.claiment === "PARTNER" ? claimOrderData?.claiment : ""}
                    disabled
                    name="partnerName"
                    placeholder="Partner Name"
                    onChange={handleClaimRecordDataChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM ORDER NUMBER</label>
                  <input
                    type="text"
                    disabled
                    className="form-control border-radius-10 text-primary"
                    value={claimNumber}
                    name="claimNumber"
                    placeholder="Claim Number"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    disabled
                    value={machineData?.serialNo}
                    name="serialNumber"
                    placeholder="Serial Number"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY ID</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={warrantyId}
                    name="warrantyId"
                    placeholder="Warranty Id"
                    onChange={handleClaimRecordDataChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    disabled
                    value={machineData?.model}
                    name="modelNumber"
                    placeholder="Model Number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-2">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{ alignItems: "start", marginLeft: 0 }}
                      control={<Switch checked={replacement} onChange={(e) => setReplacement(e.target.checked)} />}
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PRODUCT CODE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimOrderData.componentCode}
                    name="componentCode"
                    placeholder="Component Code"
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PRODUCT SERIAL NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimOrderData?.componentSerialNumber}
                    name="componentSerialNumber"
                    placeholder="Component Serial Number"
                    onChange={handleClaimTabInputTextChange}
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PART NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimOrderData?.partNumber}
                    name="partNumber"
                    placeholder="Part Number"
                    onChange={handleClaimTabInputTextChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-2">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM TYPE</label>
                  <Select className="text-primary" isDisabled options={claimRequestTypeOptions} value={claimType} styles={FONT_STYLE_SELECT} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM STATUS</label>
                  <Select className="text-primary" isDisabled options={claimStatusOptions} value={claimStatus} styles={FONT_STYLE_SELECT} />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM APPROVER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimApprover}
                    name="claimApprover"
                    disabled
                    placeholder="Claim Approver"
                    onChange={(e) => setClaimApprover(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">RMA TYPE</label>
                  <Select
                    className="text-primary"
                    options={rmaTypeOptions}
                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaType: e })}
                    value={claimOrderData.rmaType}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">RMA REASON</label>
                  <Select
                    className="text-primary"
                    options={rmaResonOptions}
                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaReason: e })}
                    value={claimOrderData.rmaReason}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">RMA NUMBER</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="rmaNumber"
                    placeholder="RMA Number"
                    value={claimOrderData.rmaNumber}
                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaNumber: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE CODE</label>
                  <Select
                    className="text-primary"
                    options={failureCodeOptions}
                    onChange={(e) => setFailureCode(e)}
                    value={failureCode}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2 mb-3" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <div className="row input-fields mt-4">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={claimRecordData.failDate}
                        onChange={(e) => handleClaimRecordSelectDataChange(e, "failDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REPAIR DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={claimRecordData?.repairDate}
                        onChange={(e) => handleClaimRecordSelectDataChange(e, "repairDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CREATED DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={claimRecordData.createdDate}
                        onChange={(e) => handleClaimRecordSelectDataChange(e, "createdDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLOSED DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={claimRecordData.closedDate}
                        onChange={(e) => handleClaimRecordSelectDataChange(e, "closedDate")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">APPROVED/REJECTED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={claimRecordData.appoverRejectedOn}
                        onChange={(e) => handleClaimRecordSelectDataChange(e, "appoverRejectedOn")}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <fieldset className="my-4" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <legend>Claim Overview</legend>
            <div className="row input-fields">
              <ReadOnlyField label="CLAIM NUMBER" value={claimNumber} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="CLAIMENT" value={claimOrderData?.claiment} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="WARRANTY REQUEST NUMBER" value={claimRecordId} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="CLAIM TYPE" value={claimType?.label} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="CLAIM STATUS" value={claimStatus?.label} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="CLAIM APPROVER" value={claimApprover} className="col-md-3 col-sm-3" />
            </div>
          </fieldset>
          <fieldset className="my-4" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <legend>Product & Failure Details</legend>
            <div className="row input-fields">
              <ReadOnlyField label="MODEL NUMBER" value={machineData?.model} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="SERIAL NUMBER" value={machineData?.serialNo} className="col-md-3 col-sm-3" />
              <ReadOnlyField
                label="PARTNER NAME"
                value={`${claimOrderData?.claiment === "PARTNER" ? claimOrderData?.claiment : ""}`}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField label="FAILURE CODE" value={failureCode?.label} className="col-md-3 col-sm-3" />
            </div>
          </fieldset>
          <fieldset className="my-4" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <legend>Repair & Replacement Details</legend>
            <div className="row input-fields">
              <ReadOnlyField label="REPLACEMENT" value={replacement ? "YES" : "NO"} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="REPLACEMENT APPROVAL" value={replacement ? "YES" : "NO"} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="REPAIR TECHNICIAN" value={"NA"} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="RMA TYPE" value={claimOrderData.rmaType?.label} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="RMA REASON" value={claimOrderData.rmaReason?.label} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="RMA NUMBER" value={claimOrderData.claimNumber} className="col-md-3 col-sm-3" />
            </div>
          </fieldset>
          <fieldset className="my-4" style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
            <legend>Timeline and Dates</legend>
            <div className="row input-fields">
              <ReadOnlyField
                label="FAILURE DATE"
                value={<Moment format="DD/MM/YYYY">{claimRecordData.failDate}</Moment>}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="REPAIR DATE"
                value={<Moment format="DD/MM/YYYY">{claimRecordData?.repairDate}</Moment>}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="CREATED DATE"
                value={<Moment format="DD/MM/YYYY">{claimRecordData.createdDate}</Moment>}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="CLOSED DATE"
                value={<Moment format="DD/MM/YYYY">{claimRecordData.closedDate}</Moment>}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="APPROVED/REJECTED ON"
                value={<Moment format="DD/MM/YYYY">{claimRecordData.appoverRejectedOn}</Moment>}
                className="col-md-3 col-sm-3"
              />
            </div>
          </fieldset>
        </>
      )}
      <div className="Add-new-segment-div p-3 border-radius-10 mt-2">
        <div class="repairbtn-dropdown">
          {viewOnlyTab.claimViewOnly ? (
            <div className="d-flex align-items-center">
              <button className="attachment-dropdown bg-primary text-white ml-2 dropbtn" onClick={() => setRequestTab("relatedPartsAndExpenses")}>
                Next
              </button>
              <div>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", marginX: 2 }}>
                  <IconButton
                    className="btn border-primary text-black font-size-14 pr-0 ml-2 attachment-dropdown"
                    style={{ borderRadius: "5px" }}
                    onClick={handleOpenAttachmentMenu}
                    size="medium"
                    aria-controls={openAttachmentMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAttachmentMenu ? "true" : undefined}
                  >
                    <span className="mx-2">
                      Attachment
                      <span>
                        <KeyboardArrowDownIcon />
                      </span>
                    </span>
                  </IconButton>
                </Box>
                <Menu
                  className="convert-top-left"
                  anchorEl={attachmentMenuAnchorEl}
                  id="account-menu"
                  open={openAttachmentMenu}
                  onClose={handleCloseAttachmentMenu}
                  onClick={handleCloseAttachmentMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                    Certificate
                  </MenuItem>
                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                    Proof
                  </MenuItem>
                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                    Faulty
                  </MenuItem>
                  <Divider />
                </Menu>
              </div>
            </div>
          ) : (
            <button className="btn bg-primary text-white ml-2 dropbtn" onClick={() => handleSaveClaimOrderChanges({}, true)}>
              Save Changes
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ClaimsProcessClaimtOrderDtls;
