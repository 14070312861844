import React, { useState } from "react";

import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Stepper, Step, StepLabel } from "@mui/material";
import { Card, Grid, Typography, Box, Tabs, Tab, Divider, Radio, TextField } from "@mui/material";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import SearchBox from "pages/Common/SearchBox";
import { FONT_STYLE_SELECT } from "pages/Common/constants";

import { customerSearch, machineSearch } from "services/searchServices";

import { priorityOptions, priorityTypeOptions, requestTypeOptions, seniorityLevelOptions, serviceRequestObj, tagOptions } from "../FieldServiceConstants";

const serviceCallSteps = ["Customer Information", "Machine Details", "General Information "];

const assignToOptions = [
    { label: "Branch", value: "BRNACH" },
    { label: "Location", value: "LOCATION" },
];

const tabData = [
    { label: "Customer Call", icon: <CallIcon /> },
    { label: "Service Branch", icon: <LocationOnIcon /> },
    { label: "Account Manager", icon: <PersonIcon /> },
];

const ServiceCallNewModal = ({ show, hideModal, handleSnack }) => {
    const [activeStep, setActiveStep] = useState(0);

    const [recordData, setRecordData] = useState({ ...serviceRequestObj, requestType: { label: "Customer Call", value: "CUSTOMER_CALL" }, priority: "LOW " });

    const [selectedCustInfoTab, setSelectedCustInfoTab] = useState(0);

    const [noOptionsCust, setNoOptionsCust] = useState(false);
    const [searchCustResults, setSearchCustResults] = useState([]);

    const [noOptionsModel, setNoOptionsModel] = useState(false);
    const [searchModelResults, setSearchModelResults] = useState([]);

    const [noOptionsSerial, setNoOptionsSerial] = useState(false);
    const [searchSerialResults, setSearchSerialResults] = useState([]);

    // Customer information tabs Change
    const handleCustInfoTabChange = (event, newValue) => {
        setSelectedCustInfoTab(newValue);
    };

    // change input text value
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        setRecordData({ ...recordData, [name]: value });
    };

    // Search Customer with customer ID
    const handleCustSearch = async (searchCustfieldName, searchText) => {
        setSearchCustResults([]);
        recordData.customerId = searchText;
        if (searchText) {
            await customerSearch(searchCustfieldName + "~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchCustResults(result);
                        setNoOptionsCust(false);
                    } else {
                        setNoOptionsCust(true);
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the customer!");
                });
        }
    };

    // Select the customer from search result
    const handleCustSelect = (type, currentItem) => {
        setRecordData({
            ...recordData,

            customerId: currentItem.customerId,
            customerName: currentItem.fullName,
            // customerPhone: "",
            customerEmail: currentItem.email,
            customerCity: currentItem.addressDTO?.district,
            // postalCode: "",
            state: currentItem.addressDTO?.regionOrState,
            country: currentItem.addressDTO?.country,
        });
        setSearchCustResults([]);
    };

    // Machine search based on model and serial number
    const handleMachineSearch = async (searchMachinefieldName, searchText) => {
        let searchQueryMachine = "";
        setSearchModelResults([]);
        setSearchSerialResults([]);

        if (searchMachinefieldName === "model") {
            recordData.model = searchText;
            searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
        } else if (searchMachinefieldName === "serialNumber") {
            recordData.serialNumber = searchText;
            searchQueryMachine = searchText ? (recordData.model ? `model:${recordData.model} AND equipmentNumber~` + searchText : "equipmentNumber~" + searchText) : "";
        }

        if (searchQueryMachine) {
            await machineSearch(searchQueryMachine)
                .then((result) => {
                    if (result) {
                        if (searchMachinefieldName === "model") {
                            if (result && result.length > 0) {
                                setSearchModelResults(result);
                                setNoOptionsModel(false);
                            } else {
                                setNoOptionsModel(true);
                            }
                        } else if (searchMachinefieldName === "serialNumber") {
                            if (result && result.length > 0) {
                                setSearchSerialResults(result);
                                setNoOptionsSerial(false);
                            } else {
                                setNoOptionsSerial(true);
                            }
                        }
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the machine!");
                });
        } else {
            searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
        }
    };

    // Select machine from the search result
    const handleModelSelect = (type, currentItem) => {
        if (type === "model") {
            setRecordData({ ...recordData, model: currentItem.model });
            setSearchModelResults([]);
        } else if (type === "equipmentNumber") {
            setRecordData({
                ...recordData,
                serialNumber: currentItem.equipmentNumber,
                model: currentItem.model,
                make: currentItem.maker,
            });
            setSearchSerialResults([]);
        }
    };

    const handleNext = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, serviceCallSteps.length - 1));
    };

    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    //
    const viewCustomerInfo = () => {
        return (
            <>
                <div className="w-100 px-2"></div>
            </>
        );
    };

    // View Customer Information
    const viewCustomerInformation = () => {
        return (
            <>
                <div></div>
                <div className="card px-2 py-3">
                    <div className="mb-3">
                        <h5 className="mb-2">Customer Information</h5>
                        <Divider className="mb-4" />
                        <Box sx={{ borderColor: "divider", width: "100%" }}>
                            <div className=" text-black font-weight-600 font-size-16 mb-3">Request Type</div>
                            <Tabs
                                value={selectedCustInfoTab}
                                onChange={handleCustInfoTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                TabIndicatorProps={{ sx: { height: 3, backgroundColor: "primary.main" } }}
                            >
                                {tabData.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                {tab.icon}
                                                {tab.label}
                                                <Radio
                                                    checked={selectedCustInfoTab === index}
                                                    onChange={() => setSelectedCustInfoTab(index)}
                                                    color="primary"
                                                    sx={{ padding: 0, marginLeft: 5 }}
                                                />
                                            </Box>
                                        }
                                        sx={{ border: 1, borderColor: selectedCustInfoTab === index ? "primary.main" : "divider", borderRadius: 1, marginX: 0.5 }}
                                    />
                                ))}
                            </Tabs>

                            {selectedCustInfoTab === 0 && (
                                <>
                                    <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                                        Contact Information
                                    </h5>

                                    <div className="row input-fields mt-3">
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                                                <SearchBox
                                                    value={recordData.customerId}
                                                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                                    type="customerId"
                                                    result={searchCustResults}
                                                    onSelect={handleCustSelect}
                                                    noOptions={noOptionsCust}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerName"
                                                    value={recordData?.customerName}
                                                    // onChange={handleInputTextChange}
                                                    placeholder="Customer Name"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerPhone"
                                                    value={recordData?.customerPhone}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Phone"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerEmail"
                                                    value={recordData?.customerEmail}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Email"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                                        Address Information
                                    </h5>

                                    <div className="row input-fields mt-3">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CITY</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerCity"
                                                    value={recordData?.customerCity}
                                                    onChange={handleInputTextChange}
                                                    placeholder="City"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">POSTAL CODE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="postalCode"
                                                    value={recordData?.postalCode}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Postal Code"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="state"
                                                    value={recordData?.state}
                                                    onChange={handleInputTextChange}
                                                    placeholder="State"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="country"
                                                    value={recordData?.country}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Country"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedCustInfoTab === 1 && (
                                <div>
                                    <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                                        Personal Information
                                    </h5>
                                    <div className="row input-fields mt-3">
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                                                <SearchBox
                                                    value={recordData.customerId}
                                                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                                    type="customerId"
                                                    result={searchCustResults}
                                                    onSelect={handleCustSelect}
                                                    noOptions={noOptionsCust}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerName"
                                                    value={recordData?.customerName}
                                                    // onChange={handleInputTextChange}
                                                    placeholder="Customer Name"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerPhone"
                                                    value={recordData?.customerPhone}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Phone"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerEmail"
                                                    placeholder="Email"
                                                    value={recordData?.customerEmail}
                                                    onChange={handleInputTextChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                                                <textarea
                                                    name="address"
                                                    cols="20"
                                                    rows="1"
                                                    placeholder="Address"
                                                    value={recordData?.address}
                                                    onChange={handleInputTextChange}
                                                    className="form-control border-radius-10 text-primary"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">SERVICE BRANCH</label>
                                                <Select
                                                    className="text-primary"
                                                    name="serviceBranch"
                                                    value={recordData?.serviceBranch}
                                                    onChange={(e) => setRecordData({ ...recordData, serviceBranch: e })}
                                                    // options={serviceBranchOptions}
                                                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedCustInfoTab === 2 && (
                                <div>
                                    <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                                        Personal Information
                                    </h5>

                                    <div className="row input-fields mt-3">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                                                <SearchBox
                                                    value={recordData.customerId}
                                                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                                    type="customerId"
                                                    result={searchCustResults}
                                                    onSelect={handleCustSelect}
                                                    noOptions={noOptionsCust}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerName"
                                                    value={recordData?.customerName}
                                                    // onChange={handleInputTextChange}
                                                    placeholder="Customer Name"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerPhone"
                                                    value={recordData?.customerPhone}
                                                    onChange={handleInputTextChange}
                                                    placeholder="Phone"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="customerEmail"
                                                    placeholder="Email"
                                                    value={recordData?.customerEmail}
                                                    onChange={handleInputTextChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                                                <textarea
                                                    name="address"
                                                    cols="20"
                                                    rows="1"
                                                    placeholder="Address"
                                                    value={recordData?.address}
                                                    onChange={handleInputTextChange}
                                                    className="form-control border-radius-10 text-primary"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                                        Manager Information
                                    </h5>
                                    <div className="row input-fields mt-3">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">MANAGER NAME</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="managerName"
                                                    placeholder="Manager Name"
                                                    value={recordData?.managerName}
                                                    onChange={handleInputTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">MANAGER PHONE</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="managerPhone"
                                                    placeholder="Manager Phone"
                                                    value={recordData?.managerPhone}
                                                    onChange={handleInputTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">MANAGER EMAIL</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    name="managerEmail"
                                                    placeholder="Manager Email"
                                                    value={recordData?.managerEmail}
                                                    onChange={handleInputTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="form-group">
                                                <label className="text-light-dark font-size-12 font-weight-500">SENIORITY LEVEL</label>
                                                <Select
                                                    className="text-primary"
                                                    name="seniorityLevel"
                                                    value={recordData?.seniorityLevel}
                                                    onChange={(e) => setRecordData({ ...recordData, seniorityLevel: e })}
                                                    options={seniorityLevelOptions}
                                                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Box>
                    </div>
                </div>
            </>
        );
    };

    // machine details
    const viewMachineDetails = () => {
        return (
            <>
                <div className="card p-4">
                    <div className=" ps-3 mb-3">
                        <h5 className="mb-2">Machine Details</h5>
                        <Divider className="mb-4" />
                        <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                            Machine Information
                        </h5>

                        <div className="row input-fields mt-3">
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                                    <SearchBox
                                        value={recordData.serialNumber}
                                        onChange={(e) => handleMachineSearch("serialNumber", e.target.value)}
                                        type="equipmentNumber"
                                        result={searchSerialResults}
                                        onSelect={handleModelSelect}
                                        noOptions={noOptionsSerial}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">Model</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="model"
                                        placeholder="Model"
                                        value={recordData?.model}
                                        onChange={handleInputTextChange}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="make"
                                        placeholder="Make"
                                        value={recordData?.make}
                                        onChange={handleInputTextChange}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                            Address Information
                        </h5>
                        <div className="row input-fields mt-3">
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">CITY</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="city"
                                        placeholder="City"
                                        value={recordData?.city}
                                        onChange={handleInputTextChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="machineState"
                                        placeholder="State"
                                        value={recordData?.machineState}
                                        onChange={handleInputTextChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="machineCountry"
                                        placeholder="Country"
                                        value={recordData?.machineCountry}
                                        onChange={handleInputTextChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 ">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">POSTAL CODE</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="machinePostalCode"
                                        placeholder="Postal Code"
                                        value={recordData?.machinePostalCode}
                                        onChange={handleInputTextChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    // General Information
    const viewGeneralInformation = () => {
        return (
            <>
                <div className="card p-4">
                    <div className="ps-3 mb-3">
                        <h5 className="mb-2">General Data</h5>
                        <Divider className="mb-4" />
                        <Box sx={{ borderColor: "divider", width: "100%" }}>
                            <div className=" text-black font-weight-600 font-size-16 mb-3">Priority</div>
                            <Tabs
                                value={priorityOptions.indexOf(recordData?.priority)}
                                onChange={(e, value) => setRecordData({ ...recordData, priority: priorityOptions[value] })}
                                textColor="primary"
                                indicatorColor="none"
                                TabIndicatorProps={{ sx: { display: "none" } }}
                            >
                                {priorityOptions.map((item, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <Box display="flex" alignItems="center" justifyContent="center" gap={1} sx={{ width: "150px" }}>
                                                <Box
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        borderRadius: "50%",
                                                        backgroundColor: item === "LOW" ? "green" : item === "MEDIUM" ? "orange" : "red",
                                                    }}
                                                />
                                                <Typography variant="body2">{item}</Typography>
                                            </Box>
                                        }
                                        sx={{ border: 1, borderColor: recordData?.priority === item ? "primary.main" : "divider", borderRadius: 1, marginX: 0.5 }}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <div className="row input-fields mt-3">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                                    <textarea
                                        name="description"
                                        cols="20"
                                        rows="3"
                                        placeholder="Short Description"
                                        value={recordData?.description}
                                        onChange={handleInputTextChange}
                                        className="form-control border-radius-10 text-primary"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row input-fields mt-2">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">REQUEST TYPE</label>
                                    <Select
                                        className="text-primary"
                                        name="requestType"
                                        value={recordData?.requestType}
                                        onChange={(e) => setRecordData({ ...recordData, requestType: e })}
                                        options={requestTypeOptions}
                                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">ASSIGNEE</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="assigneeName"
                                        value={recordData?.assigneeName}
                                        onChange={handleInputTextChange}
                                        placeholder="Person who is assigning"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row input-fields mt-2">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">REQUESTER</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-10 text-primary"
                                        name="requesterName"
                                        value={recordData?.requesterName}
                                        onChange={handleInputTextChange}
                                        placeholder="Name of Contact"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500"> ASSIGN TO </label>
                                    <Select
                                        className="text-primary"
                                        name="assignTo"
                                        value={recordData?.assignTo}
                                        onChange={(e) => setRecordData({ ...recordData, assignTo: e })}
                                        options={assignToOptions}
                                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row input-fields mt-2">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                                    <textarea
                                        name="longDescription"
                                        cols="20"
                                        rows="5"
                                        placeholder="Long Description"
                                        value={recordData?.longDescription}
                                        onChange={handleInputTextChange}
                                        className="form-control border-radius-10 text-primary"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row input-fields mt-2">
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">TAGS</label>
                                    <Select
                                        isMulti
                                        options={tagOptions}
                                        value={recordData?.tags}
                                        onChange={(e) => setRecordData({ ...recordData, tags: e })}
                                        placeholder="Select Tags"
                                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="lg">
                <Modal.Body>
                    <div>
                        <h4 className="font-size-32 font-weight-bold text-center mt-4 mb-1">Service Call</h4>
                        <p className="text-center font-size-20 font-weight-600">Create a service call terms with the guided process</p>
                    </div>
                    <Box sx={{ width: "100%", marginY: 3 }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {serviceCallSteps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <div>
                        {activeStep === 0 && viewCustomerInfo()}
                        {/* {activeStep === 0 && viewCustomerInformation()} */}
                    </div>
                    <div class="container-fluid ">
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: 4 }}>
                                Service Call
                            </Typography>
                            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                Create a service call terms with the guided process
                            </Typography>
                            <Box sx={{ width: "100%", marginY: 3 }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {serviceCallSteps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                        {activeStep === 0 && viewCustomerInformation()}
                        {activeStep === 1 && viewMachineDetails()}
                        {activeStep === 2 && viewGeneralInformation()}
                    </div>
                    <div className="row px-4 mb-3" style={{ justifyContent: "right" }}>
                        <button className="btn border-primary text-primary mx-2" onClick={handleBack}>
                            Cancel
                        </button>
                        {activeStep === 2 ? (
                            <button className="btn bg-primary text-white" onClick={hideModal}>
                                Submit As New
                            </button>
                        ) : (
                            <button className="btn bg-primary text-white" onClick={handleNext}>
                                Confirm
                            </button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ServiceCallNewModal;
