import React, { useCallback, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";

import { TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE, FONT_STYLE_SELECT, GENERAL_TRANSPRINT_GRID_STYLE } from "pages/Common/constants";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import AuthorizationAccptedRejectModal from "pages/Warranty_Management/ClaimsProcessing/AuthorizationAccptedRejectModal";

const reasonOptions = [
    {
        label: "Supplier production Problem",
        value: "SUPPLIER_PRODUCTION_PROBLEM",
    },
    {
        label: "Incoming inspection Problem",
        value: "INCOMING_INSPECTION_PROBLEM",
    },
    {
        label: "In-production audit Problem",
        value: "IN_PRODUCTION_AUDIT_PROBLEM",
    },
    {
        label: "Final inspection and testing Problem",
        value: "FINAL_INSPECTION_AND_TESTING_PROBLEM",
    },
    { label: "PDI Problem", value: "PDI_PROBLEM" },
    { label: "Field Warranty failure Problem", value: "FIELDS_WARRANTY" },
    { label: "Customer complaint Problem", value: "CUSTOMER_COMPLAINT_pROBLEM" },
    {
        label: "Field survey result Problem",
        value: "FIELD_SURVEY_RESULT_PROBLEM",
    },
];

const warrantyDiagnosisOptions = [
    {
        label: "Symptoms observed by the Customer",
        value: "SYMPTOMS_OBSERVED_BY_THE_CUSTOMER",
    },
    {
        label: "Involvement of any related part (s) and/or its condition",
        value: "INVOLVEMENT_OF_ANY_PART_RELATED_PART_AND/OR_ITS_CONDITION",
    },
    {
        label: "Usage pattern of the customer",
        value: "USAGE_PATTERN_OF_CUSTOMER",
    },
    {
        label: "External environmental conditions",
        value: "EXTERNAL_ENVIRONMENTAL_CONDITIONS",
    },
    {
        label: "Observation during fault diagnosis",
        value: "OBSERVATION_DURING_FAULT_DIAGNOSIS",
    },
];

const ReturnAnalysisModal = ({ show, hideModal, handleSnack }) => {
    const [tabValue, setTabValue] = useState("returnDetails");

    const [customerData, setCustomerData] = useState({
        customerID: "",
        customerName: "",
        contactEmail: "",
        contactPhone: "",
    });

    const [machineData, setMachineData] = useState({
        make: "",
        model: "",
        serialNo: "",
        smu: "",
        fleetNo: "",
        equipmentNumber: "",
        family: "",
    });

    const [yearlyWarrantyData, setYearlyWarrantyData] = useState({
        warrantyId: 0,
        warrantyTitle: "",
        warrantyType: "",
        warrantyStartDate: new Date(),
        warrantyEndDate: new Date(),
        warrantyStatus: "",
        yearlyWarrantyId: 0,
        year: { label: "Year 1", value: "1" },
    });
    const [authorizationStatusRecords, setAuthorizationStatusRecords] = useState([
        {
            id: 1,
            requestType: "",
            authorizationStatus: "PENDING",
            authorizationCode: "",
            authorizedBy: "",
            authorizedOn: new Date(),
            status: "",
        },
    ]);
    const [authorizationStatus, setAuthorizationStatus] = useState("PENDING");

    const [openAuthDtlsModal, setOpenAuthDtlsModal] = useState(false);
    const [openAuthAcceptRejectModal, setOpenAuthAcceptRejectModal] = useState(false);
    const [selectedAuthRow, setSelectedAuthRow] = useState(null);
    const [statusIs, setStatusIs] = useState("");

    // create new return anlysis
    const handleSaveAndNextClick = (e) => {
        const { id } = e.target;

        if (id === "returnDetails") {
            handleSnack("success", "Return Analysis Created Successfully.");
            setTabValue("analysis");
        } else if (id === "analysis") {
            hideModal();
        }
    };

    // view return details
    const viewReturnDetails = () => {
        return (
            <>
                <div className="card border px-3 py-2">
                    <div className="row input-fields mt-2">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">RETURN REASON</label>
                                <Select
                                    className="text-primary"
                                    options={reasonOptions}
                                    //   onChange={(e) => handleSelectChange(e, `question1`)}
                                    //   value={recordObj.questions1}
                                    styles={FONT_STYLE_SELECT}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">PART NUMBER</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="partNumber"
                                    placeholder="Part Number"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">PART GROUP</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="partGroup"
                                    placeholder="Part Group"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">PART SUB GROUP</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="partSubGroup"
                                    placeholder="Part Sub Group"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">WARRANTY CLAIM NUMBER</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="warrantyCliatNumber"
                                    placeholder="Warranty Claim Number"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">FAIL DATE</label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            // value={claimRecord.fillDate}
                                            // onChange={(e) => handleSelectChange(e, "fillDate")}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CREATION DATE/UPDATE DATE</label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            // maxDate={new Date()}
                                            closeOnSelect
                                            // value={claimRecord.fillDate}
                                            // onChange={(e) => handleSelectChange(e, "fillDate")}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: FONT_STYLE,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CREATED BY/REVIEWED BY</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="createdBy"
                                    placeholder="Created by / Reviewed by "
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary" onClick={hideModal}>
                        Cancel
                    </button>
                    <button className="btn bg-primary text-white mx-3" id="returnDetails" onClick={handleSaveAndNextClick}>
                        Save & Next
                    </button>
                </div>
            </>
        );
    };

    // aview analysis
    const viewAnalysis = () => {
        return (
            <>
                <h5>Source Failure Details</h5>
                <div className="card border px-3 py-2">
                    <div className="row input-fields mt-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">SOURCE</label>
                                <textarea className="form-control border-radius-10 text-primary" name="source" cols="30" rows="2" placeholder="Source..."></textarea>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">WARRANTY DIAGNOSIS NOTES</label>
                                <Select
                                    className="text-primary"
                                    options={warrantyDiagnosisOptions}
                                    //   onChange={(e) => handleSelectChange(e, `question1`)}
                                    //   value={recordObj.questions1}
                                    styles={FONT_STYLE_SELECT}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">POTENTIAL FAILURE MODE</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="potentialFailureMode"
                                    placeholder="Potential Failure Mode"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">SERVICE CODE</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serviceCode"
                                    placeholder="Service Code"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">POTENTIAL EFFECTS OF FAILURE</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="potentialEffectsOfFailure"
                                    placeholder="Potential Effects of Failure"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">SEVERITY</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="severity"
                                    placeholder="Severity"
                                    min={1}
                                    max={10}
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary" onClick={hideModal}>
                        Back
                    </button>
                    <button className="btn bg-primary text-white mx-3" id="analysis" onClick={handleSaveAndNextClick}>
                        Save & Close
                    </button>
                </div>
            </>
        );
    };

    //
    const viewRootCause = () => {
        return (
            <>
                <h5>Corrective Action</h5>
                <div className="card border px-3 py-2 mb-0">
                    <div className="row input-fields mt-2">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CAUSE</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="cause"
                                    placeholder="Cause"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">POTENTIAL CAUSE(S) OF FAILURE</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="potentialCauseOfFailure"
                                    placeholder="Potential Cause(s) of Failure"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">OCCURRENCE</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="occurrence"
                                    placeholder="occurrence"
                                    min={1}
                                    max={10}
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">ACTIONS TO TAKE</label>
                                <textarea
                                    className="form-control border-radius-10 text-primary"
                                    name="actionsToTake"
                                    cols="30"
                                    rows="2"
                                    placeholder="Actions to Take..."
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CREATED BY/REVIEWED BY</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="createdBy"
                                    placeholder="Created by / Reviewed by "
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">REVISED SEVERITY</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="revisedSeverity"
                                    placeholder="Revised Severity"
                                    min={1}
                                    max={10}
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">REVISED OCCURRENCE</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="revisedOccurrence"
                                    placeholder="Revised Occurrence"
                                    min={1}
                                    max={10}
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">REVISED DETECTION</label>
                                <input
                                    type="number"
                                    className="form-control border-radius-10 text-primary"
                                    name="revisedDetection"
                                    placeholder="Revised Detection"
                                    min={1}
                                    max={10}
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">ASSIGNED INVESTIGATOR</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="revisedDetection"
                                    placeholder="Assigned Investigator"
                                    //   value={evaluatedByRecordObj.firstName}
                                    //   onChange={handleEvaluatedByInputTextChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">INVESTIGATION NOTES</label>
                                <textarea
                                    className="form-control border-radius-10 text-primary"
                                    name="actionsToTake"
                                    cols="30"
                                    rows="2"
                                    placeholder="Investigation Notes..."
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">RECOMMENDATIONS FOR PROCESS IMPROVEMENT</label>
                                <textarea
                                    className="form-control border-radius-10 text-primary"
                                    name="actionsToTake"
                                    cols="30"
                                    rows="2"
                                    placeholder="Recommendations for process improvement..."
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    // Accept/Reject Authorization
    const handleAcceptRejectAuthrization = (row, status) => {
        setStatusIs(status);
        setSelectedAuthRow(row);
        setOpenAuthAcceptRejectModal(true);
    };

    const authStatusColumns = [
        {
            field: "authorizationStatus",
            headerName: "Status",
            sortable: false,
            width: 250,
            //   flex: 1,
            renderCell: ({ row }) => (
                <div className="d-flex align-items-center">
                    <span className={row?.authorizationStatus === "AUTHORIZED" ? "led-green" : row?.authorizationStatus === "DENIED" ? "led-red" : "led-orange"}></span>
                    <span>{row?.authorizationStatus === "AUTHORIZED" ? "Authorized" : row?.authorizationStatus === "DENIED" ? "Denied" : "Pending"}</span>
                </div>
            ),
        },
        {
            field: "details",
            headerName: "Details",
            sortable: false,
            width: 550,
            //   flex: 1,
            renderCell: ({ row }) => (
                <div className="d-block">
                    {/* <h6 className="mb-1">
              <span>Serial # :-</span>
              {machineData?.serialNo || "N/A"}
            </h6>
            <h6 className="mb-1">Customer # :- {customerData?.customerID || "N/A"}</h6>
            <h6 className="mb-1">Warranty Id :- {yearlyWarrantyData?.warrantyId || "N/A"}</h6> */}
                    {`Serial #:- ${machineData?.serialNo || "N/A"} | Customer #:- ${customerData?.customerID || "N/A"} | Warranty Id: ${
                        yearlyWarrantyData?.warrantyId || "N/A"
                    }`}
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            width: 300,
            //   flex: 1,
            type: "actions",
            getActions: ({ row }) => {
                if (!row) return [];
                const actions = [];
                if (row.authorizationStatus === "PENDING") {
                    actions.push(
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Accept">
                                    <CheckCircleOutlineIcon sx={{ fontSize: 28, color: "#67d11e" }} />
                                </Tooltip>
                            }
                            label="Accept"
                            onClick={() => handleAcceptRejectAuthrization(row, "AUTHORIZED")}
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Reject">
                                    <CancelOutlinedIcon sx={{ fontSize: 28, color: "#f84343" }} />
                                </Tooltip>
                            }
                            label="Reject"
                            onClick={() => handleAcceptRejectAuthrization(row, "DENIED")}
                        />
                    );
                }
                // actions.push(
                //     <GridActionsCellItem
                //         icon={
                //             <Tooltip title="Edit">
                //                 <EditOutlinedIcon sx={{ fontSize: 28 }} />
                //             </Tooltip>
                //         }
                //         label="Edit"
                //         // onClick={() => handleOpenAuthDtlsModal(row)}
                //     />
                // );
                return actions;
            },
        },
    ];

    // view Authorization Status tab details
    const viewAuthorizationStatusDetails = useCallback(() => {
        return (
            <>
                <DataGrid
                    sx={GENERAL_TRANSPRINT_GRID_STYLE}
                    rows={authorizationStatusRecords}
                    columns={authStatusColumns.map((col) => {
                        return col;
                    })}
                    pageSize={10}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    //   hideFooter
                    //   disableColumnMenu
                    sortingOrder={["asc", "desc"]}
                    autoHeight
                    disableSelectionOnClick
                />
                {/* <div className="row px-3 mt-2 mb-0" style={{ justifyContent: "right" }}>
                    <button type="button" className="btn bg-primary text-white" id="authorizationStatus" onClick={handleAddUpdateClaimOrder}>
                        Next
                    </button>
                </div> */}
            </>
        );
    }, [authorizationStatusRecords, machineData, customerData, yearlyWarrantyData]);

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    {/* <Modal.Title className="mb-2">Return Analysis</Modal.Title> */}
                    <div className="card border">
                        <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#f8f8f8" }}>
                                    <TabList className="custom-tabs-div" onChange={(e, value) => setTabValue(value)}>
                                        <Tab label="RETURN DETAILS" value="returnDetails" />
                                        <Tab label="ANALYSIS" value="analysis" />
                                        <Tab label="ROOT CAUSE" value="rootCause" />
                                        <Tab label="DISPOSITION" value="disposition" />
                                    </TabList>
                                </Box>
                                <TabPanel value={tabValue}>
                                    {tabValue === "returnDetails" && viewReturnDetails()}
                                    {tabValue === "analysis" && viewAnalysis()}
                                    {tabValue === "rootCause" && viewRootCause()}
                                    {tabValue === "disposition" && viewAuthorizationStatusDetails()}
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </Modal.Body>
            </Modal>

            {openAuthAcceptRejectModal && (
                <AuthorizationAccptedRejectModal
                    show={openAuthAcceptRejectModal}
                    hideModal={() => {
                        setStatusIs("");
                        setSelectedAuthRow(null);
                        setOpenAuthAcceptRejectModal(false);
                    }}
                    rowData={selectedAuthRow}
                    status={statusIs}
                    authorizationStatusRecords={authorizationStatusRecords}
                    setAuthorizationStatusRecords={setAuthorizationStatusRecords}
                    setAuthorizationStatus={setAuthorizationStatus}
                    handleSnack={handleSnack}
                />
            )}
        </>
    );
};

export default ReturnAnalysisModal;
