import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";

import { TextField, Tooltip } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import LoadingProgress from "pages/Repair/components/Loader";
import { chargeCodeOptions, laborPriceMethodOptions, laborTypeOptions, serviceTypeOptions } from "../equipmentConstant";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { GET_LABOUR_PRICE } from "services/CONSTANTS";
import Moment from "react-moment";
import { laborPriceRequestObj } from "../masterConstents";

const LaborPriceAddUpdate = ({ show, hideModal, recordId, laborRecord = {}, priceData, setPriceData, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...laborPriceRequestObj });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      setViewModeOn(true);
      const rUrl = `${GET_LABOUR_PRICE}/${recordId}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;

            const _priceMethod = laborPriceMethodOptions.find((row) => row?.value === responseData?.priceMethod);
            const _chargeCode = chargeCodeOptions.find((row) => row?.value === responseData?.chargeCode);
            const _serviceType = serviceTypeOptions.find((row) => row?.value === responseData?.serviceType);
            const _laborType = laborTypeOptions.find((row) => row?.value === responseData?.laborType);
            setRecordData({
              ...responseData,
              priceMethod: _priceMethod || "",
              chargeCode: _chargeCode || "",
              serviceType: _serviceType || "",
              laborType: _laborType || "",
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      const _priceMethod = laborPriceMethodOptions.find((row) => row?.value === laborRecord?.priceMethod);
      const _chargeCode = chargeCodeOptions.find((row) => row?.value === laborRecord?.chargeCode);
      const _serviceType = serviceTypeOptions.find((row) => row?.value === laborRecord?.serviceType);
      const _laborType = laborTypeOptions.find((row) => row?.value === laborRecord?.laborType);
      setRecordData({
        ...recordData,
        jobCode: laborRecord?.jobCode || "",
        priceMethod: _priceMethod || "",
        chargeCode: _chargeCode || "",
        serviceType: _serviceType || "",
        laborType: _laborType || "",
      });
    }
  }, [recordId]);

  // input value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // add - update price
  const handleSubmit = () => {
    const rUrl = `${GET_LABOUR_PRICE}`;
    const rObj = {
      ...recordData,
      priceMethod: recordData?.priceMethod?.value || "EMPTY",
      chargeCode: recordData?.chargeCode?.value || "EMPTY",
      serviceType: recordData?.serviceType?.value || "EMPTY",
      laborType: recordData?.laborType?.value || "EMPTY",
    };
    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData?.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _priceData = priceData.map((item) => (item?.id === responseData?.id ? { ...responseData } : { ...item }));
            setPriceData([..._priceData]);
            handleSnack("success", "Price updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong.");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong.");
        }
      );
    } else {
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRecordData({ ...recordData, id: responseData?.id });
          const _priceData = [...priceData];
          _priceData.unshift(responseData);
          setPriceData([..._priceData]);
          handleSnack("success", "Price Created successfully!");
          setViewModeOn(true);
        } else {
          handleSnack("error", "Something went wrong.");
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="d-flex align-items-center mb-0">Labor Price</h4>
            {recordData?.id && (
              <Tooltip title="Edit">
                <span className="upload-icon-button cursor" onClick={() => setViewModeOn(false)}>
                  <EditIcon sx={{ fontSize: 40 }} />
                </span>
              </Tooltip>
            )}
          </div>
          {loading ? (
            <LoadingProgress />
          ) : (
            <>
              <div className="card border px-3 mb-3">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">PRICE METHOD</label>
                          <Select
                            className="text-primary"
                            value={recordData?.priceMethod}
                            onChange={(e) => setRecordData({ ...recordData, priceMethod: e })}
                            options={laborPriceMethodOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">CHARGE CODE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.chargeCode}
                            onChange={(e) => setRecordData({ ...recordData, chargeCode: e })}
                            options={chargeCodeOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICE TYPE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.serviceType}
                            onChange={(e) => setRecordData({ ...recordData, serviceType: e })}
                            options={serviceTypeOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">LABOR TYPE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.laborType}
                            onChange={(e) => setRecordData({ ...recordData, laborType: e })}
                            options={laborTypeOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">QUANTITY</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="quantity"
                            placeholder="Quantity"
                            value={recordData?.quantity}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COST PRICE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="costPrice"
                            placeholder="Cost Price"
                            value={recordData?.costPrice}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="price"
                            placeholder="Price"
                            value={recordData?.price}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">VALID FROM</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                value={recordData?.validFrom}
                                onChange={(e) => setRecordData({ ...recordData, validFrom: e })}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">VALID TO</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                value={recordData?.validTo}
                                onChange={(e) => setRecordData({ ...recordData, validTo: e })}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE METHOD" value={recordData?.priceMethod?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="CHARGE CODE" value={recordData?.chargeCode?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERVICE TYPE" value={recordData?.serviceType?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="LABOR TYPE" value={recordData?.laborType?.value} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="QUANTITY" value={recordData?.quantity} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="COST PRICE" value={recordData?.costPrice} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={recordData?.price} />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="VALID FROM"
                        value={recordData?.validFrom ? <Moment format="DD/MM/YYYY">{recordData?.validFrom}</Moment> : "NA"}
                      />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="VALID TO"
                        value={recordData?.validTo ? <Moment format="DD/MM/YYYY">{recordData?.validTo}</Moment> : "NA"}
                      />
                    </div>
                  </>
                )}
              </div>
              {!viewModeOn ? (
                <div className="row px-3" style={{ justifyContent: "right" }}>
                  <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                    Cancel
                  </button>
                  <button className="btn bg-primary text-white" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              ) : (
                <div className="row px-3" style={{ justifyContent: "right" }}>
                  <button className="btn bg-primary text-white" onClick={hideModal}>
                    Close
                  </button>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaborPriceAddUpdate;
