import React, { useCallback, useState } from "react";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TextField } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Moment from "react-moment";
import Select from "react-select";

import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { accountAssignmentOptions, FONT_STYLE, FONT_STYLE_SELECT, GENERAL_TRANSPRINT_GRID_STYLE, locationOptions } from "pages/Common/constants";

const ServiceOrderPlan = ({
  orderPlanGeneralData,
  setOrderPlanGeneralData,
  orderPlanFinanceInfoData,
  setOrderPlanFinanceInfoData,
  orderPlanJobStatusRecords,
  setOrderPlanJobStatusRecords,
  setActiveStep,
  handleSnack,
}) => {
  const [tabValue, setTabValue] = useState("general");

  // header tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // general tab text change
  const handleGeneralTaxtChange = (e) => {
    const { name, value } = e.target;
    setOrderPlanGeneralData({ ...orderPlanGeneralData, [name]: value });
  };

  // Finance tab text change
  const handleFinanceInfoTaxtChange = (e) => {
    const { name, value } = e.target;
    setOrderPlanFinanceInfoData({ ...orderPlanFinanceInfoData, [name]: value });
  };

  // mark the status completed
  const handleMarkCompleted = (row) => {
    if (!row?.completed) {
      const updatedRecords = orderPlanJobStatusRecords.map((record) => (record.id === row.id ? { ...record, completed: !record.completed } : record));
      setOrderPlanJobStatusRecords(updatedRecords);
      handleSnack("success", "this task is mark as completed");
    } else {
      handleSnack("info", "this task is already completed");
    }
  };

  const jobStatusColumns = [
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      width: 100,
      sortable: false,
      cellClassName: "status-column-cell",
      headerClassName: "status-column-header-class",
      renderCell: ({ row }) => (
        <div
          className="d-flex justify-content-between align-items-center py-2"
          style={{
            fontSize: 15,
            // backgroundColor: colorStatus[params.row.status],
            paddingInline: 5,
            paddingBlock: 2,
            borderRadius: 6,
            // textTransform: "capitalize",
            color: row?.completed ? "#67d11e" : "#f84343",
          }}
        >
          <span
            style={{
              borderRadius: 10,
              content: '" "',
              display: "block",
              marginRight: 8,
              height: 10,
              width: 10,
              backgroundColor: row?.completed ? "#67d11e" : "#f84343",
            }}
          />
          {row.status}
        </div>
      ),
    },
    {
      field: "orderNo",
      headerName: "DETAILS",
      sortable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span>{currencyFormatter.format(row?.price || 0)}</span>
          <span className="mx-2">
            <Moment format="DD MMMM YYYY, HH:MM a">{row?.orderDate}</Moment>
          </span>
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "ADDITIONALS/ACTION",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className={`d-flex align-items-center px-2 py-1 cursor`}
                style={{ color: row.completed ? "#67d11e" : "#f84343", border: `1px solid ${row.completed ? "#67d11e" : "#f84343"}` }}
                onClick={() => handleMarkCompleted(row)}
              >
                <span className=" font-size-16">Completed</span>
                <span className="font-size-18 ml-2">{row?.completed ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}</span>
              </div>
            }
            label="Assign"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // update the general info data
  const updateGeneralInfoData = () => {
    setTabValue("financeInfo");
  };

  // update the financial info data
  const updateFinanceInfoData = () => {
    setTabValue("jobStatus");
  };

  // General info content
  const generalInfo = () => {
    return (
      <div className="card border px-3 py-3">
        <div className="row input-fields">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">COMPLETION DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    closeOnSelect
                    value={orderPlanGeneralData?.completionDate}
                    onChange={(e) => setOrderPlanGeneralData({ ...orderPlanGeneralData, completionDate: e })}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">REQUESTED DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    closeOnSelect
                    value={orderPlanGeneralData?.requestedDate}
                    onChange={(e) => setOrderPlanGeneralData({ ...orderPlanGeneralData, requestedDate: e })}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">PLANT CODE</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanGeneralData?.plantCode}
                name="plantCode"
                placeholder="Plant Code"
                onChange={handleGeneralTaxtChange}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">COMPANY CODE</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanGeneralData?.companyCode}
                name="companyCode"
                placeholder="Compnay Code"
                onChange={handleGeneralTaxtChange}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SALES ORGANIZATION</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanGeneralData?.salesOrganization}
                name="salesOrganization"
                placeholder="Sales Organization"
                onChange={handleGeneralTaxtChange}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SERVICE ORGANIZATION</label>
              <Select
                className="text-primary"
                value={orderPlanGeneralData?.serviceOrganization}
                onChange={(e) => setOrderPlanGeneralData({ ...orderPlanGeneralData, serviceOrganization: e })}
                options={locationOptions}
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SERVICE BRANCH</label>
              <Select
                className="text-primary"
                value={orderPlanGeneralData?.serviceBranch}
                onChange={(e) => setOrderPlanGeneralData({ ...orderPlanGeneralData, serviceBranch: e })}
                options={locationOptions}
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SALES ORGANIZATION</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanGeneralData?.salesOrganization}
                name="salesOrganization"
                placeholder="Sales Organization"
                onChange={handleGeneralTaxtChange}
              />
            </div>
          </div>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          <button type="button" className="btn btn-light bg-primary text-white mr-1">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-light bg-primary text-white"
            // disabled={!(customerData.source && customerData.contactEmail && customerData.customerGroup && customerData.contactName) || noOptionsCust}
            onClick={updateGeneralInfoData}
          >
            Save & Next
          </button>
        </div>
      </div>
    );
  };

  // Finance info content
  const FinanceInfo = () => {
    return (
      <div className="card border px-3 py-3">
        <div className="row input-fields">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">ACCOUNT ASSIGNMENT</label>
              <Select
                className="text-primary"
                value={orderPlanFinanceInfoData?.accountAssignment}
                onChange={(e) => setOrderPlanFinanceInfoData({ ...orderPlanFinanceInfoData, accountAssignment: e })}
                options={accountAssignmentOptions}
                styles={FONT_STYLE_SELECT}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">COST CENTRE</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanFinanceInfoData?.costCentre}
                name="costCentre"
                placeholder="Cost Centre"
                onChange={handleFinanceInfoTaxtChange}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">PROFIT CENTRE</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanFinanceInfoData?.profitCentre}
                name="profitCentre"
                placeholder="Profit Centre"
                onChange={handleFinanceInfoTaxtChange}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">RECON ACCOUNT</label>
              <input
                type="text"
                className="form-control border-radius-10 text-primary"
                value={orderPlanFinanceInfoData?.reconAccount}
                name="reconAccount"
                placeholder="Recon Account"
                onChange={handleFinanceInfoTaxtChange}
              />
            </div>
          </div>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          <button type="button" className="btn btn-light bg-primary text-white mr-1">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-light bg-primary text-white"
            // disabled={!(customerData.source && customerData.contactEmail && customerData.customerGroup && customerData.contactName) || noOptionsCust}
            onClick={updateFinanceInfoData}
          >
            Save & Next
          </button>
        </div>
      </div>
    );
  };

  // Job Status Content
  const jobStatusInfo = useCallback(() => {
    return (
      <>
        <DataGrid
          sx={GENERAL_TRANSPRINT_GRID_STYLE}
          rows={orderPlanJobStatusRecords} // Reactively updates based on `orderPlanJobStatusRecords` state
          columns={jobStatusColumns.map((col) => {
            if (col.field === "action") {
              return {
                ...col,
                getActions: ({ row }) => {
                  const currentIndex = orderPlanJobStatusRecords.findIndex((r) => r.id === row.id);
                  const previousRow = orderPlanJobStatusRecords[currentIndex - 1];
                  const isEnabled = currentIndex === 0 || previousRow?.completed;

                  return [
                    <GridActionsCellItem
                      key={`action-${row.id}`}
                      icon={
                        <div
                          className={`d-flex align-items-center px-2 py-1 cursor ${isEnabled ? "" : "disabled"}`}
                          style={{
                            color: row.completed ? "#67d11e" : "#f84343",
                            border: `1px solid ${row.completed ? "#67d11e" : "#f84343"}`,
                            pointerEvents: isEnabled ? "auto" : "none",
                            opacity: isEnabled ? 1 : 0.5,
                          }}
                          onClick={() => isEnabled && handleMarkCompleted(row)}
                        >
                          <span className="font-size-16">Completed</span>
                          <span className="font-size-18 ml-2">{row.completed ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}</span>
                        </div>
                      }
                      label="Assign"
                      className="textPrimary"
                      color="inherit"
                    />,
                  ];
                },
              };
            }
            return col;
          })}
          pageSize={11}
          rowsPerPageOptions={[5, 10, 20, 50]}
          hideFooter // Hides the entire footer including pagination and record count
          disableColumnMenu // Disables the column menu for a cleaner UI
          sortingOrder={["asc", "desc"]} // Enables sorting functionality
          autoHeight
          disableSelectionOnClick
          isRowSelectable={(params) => {
            const currentIndex = orderPlanJobStatusRecords.findIndex((row) => row.id === params.id);
            const previousRow = orderPlanJobStatusRecords[currentIndex - 1];

            // Enable only if it's the first row or the previous row is completed
            return currentIndex === 0 || previousRow?.completed;
          }}
          getRowClassName={(params) => {
            const currentIndex = orderPlanJobStatusRecords.findIndex((row) => row.id === params.id);
            const previousRow = orderPlanJobStatusRecords[currentIndex - 1];

            if (currentIndex === 0 || previousRow?.completed) {
              return "row-enabled"; // Add a CSS class for enabled rows
            }
            return "row-disabled"; // Add a CSS class for disabled rows
          }}
        />
      </>
    );
  }, [orderPlanJobStatusRecords]);
  return (
    <>
      <Box className="mt-2" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList className="custom-tabs-div" onChange={handleChange}>
              <Tab label="GENERAL INFO" value="general" />
              <Tab label="FINANCE INFO " value="financeInfo" />
              <Tab label="JOB STATUS" value="jobStatus" />
            </TabList>
            <TabPanel value={tabValue}>
              {tabValue === "general" && generalInfo()}
              {tabValue === "financeInfo" && FinanceInfo()}
              {tabValue === "jobStatus" && jobStatusInfo()}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};

export default ServiceOrderPlan;
