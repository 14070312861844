import React, { useCallback, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";

import { FONT_STYLE, FONT_STYLE_SELECT, GENERAL_TRANSPRINT_GRID_STYLE } from "pages/Common/constants";
import SearchBox from "pages/Common/SearchBox";
import ShopReapirRequestCard from "./ShopReapirRequestCard";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

const machineCompDats = {
    id: 1,
    location: "",
    machineComponent: "",
    serialNo: "",
    model: "",
    make: "",
    requestDate: new Date(),
    serviceOrder: "",
    requestNumber: "",
    customerId: "",
    customerName: "",
    submited: false,
    viewMode: false,
};

const ShopReapirRequestRecipt = ({
    setActiveStep,
    handleSnack,
    machineCompos = [],
    setMachineCompos,
    receiptRecords = [],
    setReceiptRecords,
    returnedRecords = [],
    setReturnedRecords,
}) => {
    const [tabValue, setTabValue] = useState("request");
    const [expanded, setExpanded] = useState("panel0");

    // tab change
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // expend accordian
    const handleExpand = (panel, index) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        let _machineCompos = [...machineCompos];
        let obj = _machineCompos[index];
        obj["viewMode"] = obj["submited"] ? true : false;
        setMachineCompos(_machineCompos);
    };

    // add new machine components
    const handleAddNewMachineCompo = () => {
        const _machineCompos = [...machineCompos];
        _machineCompos.push({ ...machineCompDats, id: machineCompos.length + 10 });
        setMachineCompos(_machineCompos);
    };

    // click on edit icon
    const handleEditClick = (e, index) => {
        e.stopPropagation();
        let _machineCompos = [...machineCompos];
        let obj = _machineCompos[index];
        obj["viewMode"] = false;
        setMachineCompos(_machineCompos);
    };

    // click on Request tab next button
    const handleRequestNextClick = (e) => {
        const _submitedRequests = machineCompos.filter((item) => item?.submited);

        const _receiptRecords = [...receiptRecords];

        if (_submitedRequests.length > 0) {
            _submitedRequests.forEach((item) => {
                let index = _receiptRecords.findIndex((row) => row.id === item?.id);

                if (index === -1) {
                    // If the item doesn't exist, add the new rows
                    _receiptRecords.push(
                        {
                            ...item,
                            statusTitle: "Repair Request",
                            isRequested: true,
                            isReceipt: false,
                            received: false,
                            isReturned: false,
                            returned: false,
                        },
                        {
                            ...item,
                            statusTitle: "Returned For Repair",
                            isRequested: false,
                            isReceipt: true,
                            received: false,
                            isReturned: false,
                            returned: false,
                            id: item?.id + 1,
                        }
                    );
                } else {
                    // If the item exists, update the rows
                    const requestRow = _receiptRecords[index];
                    const repairRow = _receiptRecords.find((row) => row.id === item?.id + 1);

                    // Update the request row
                    _receiptRecords[index] = {
                        ...requestRow,
                        ...item,
                        statusTitle: "Repair Request",
                        isRequested: true,
                        isReceipt: false,
                        received: false,
                        isReturned: false,
                        returned: false,
                    };

                    // Update the repair row (if it exists)
                    if (repairRow) {
                        const repairIndex = _receiptRecords.findIndex((row) => row.id === item?.id + 1);
                        _receiptRecords[repairIndex] = {
                            ...repairRow,
                            ...item,
                            id: repairRow?.id,
                            statusTitle: "Returned For Repair",
                            isRequested: repairRow?.isRequested,
                            isReceipt: repairRow?.isReceipt,
                            received: repairRow?.received,
                            isReturned: repairRow?.isReturned,
                            returned: repairRow?.returned,
                        };
                    }
                }
            });
        }

        // Update state with the modified records
        setReceiptRecords(_receiptRecords);

        setTabValue("receipt");
    };

    // request tab content
    const requestContent = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4>Machine & Components</h4>
                    <span className="font-size-16 font-weight-400 cursor" onClick={handleAddNewMachineCompo}>
                        + Add New
                    </span>
                </div>
                <div>
                    {machineCompos.length > 0 &&
                        machineCompos.map((item, index) => (
                            <Accordion
                                expanded={expanded === `panel${index}`}
                                onChange={handleExpand(`panel${index}`, index)}
                                sx={{
                                    border: "1px solid #E0E0E0",
                                    // border: "dashed 2px #872ff7",
                                    marginBottom: 1,
                                }}
                            >
                                <AccordionSummary
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: expanded === `panel${index}` ? "32px" : "30px",
                                        padding: expanded === `panel${index}` ? "4px 16px" : "0px 16px",
                                        borderColor: "transparent",
                                        "&.Mui-expanded": { minHeight: "32px", padding: "0px 16px 0px" },
                                        "&:hover": { backgroundColor: expanded === `panel${index}` ? "#F4F5F7" : "transparent" },
                                    }}
                                >
                                    <div className="row d-flex align-items-center w-100">
                                        <div className="col-md-4 col-sm-4">
                                            <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                                                <ExpandMoreIcon sx={{ fontSize: 40, transform: `${expanded === `panel${index}` ? "rotate(0deg)" : "rotate(-90deg)"}` }} />
                                                <div className="d-flex align-items-center">
                                                    <div className={item?.submited ? "led-green" : "led-orange"}></div>
                                                    <h5 className="ml-2" style={{ margin: 0, flex: 1 }}>
                                                        Repair Request
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div className="d-flex align-items-center">
                                                <h5 className="ml-2" style={{ margin: 0, flex: 1 }}>
                                                    {item?.serialNo || item?.customerName ? `${item?.serialNo} - ${item?.customerName}` : "New Machine/Component"}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-2 d-flex align-items-center px-0" style={{ justifyContent: "right" }}>
                                            {expanded === `panel${index}` ? (
                                                item?.submited ? (
                                                    <div className="cursor" onClick={(e) => handleEditClick(e, index)}>
                                                        <EditIcon sx={{ fontSize: 40 }} />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <div className="d-flex align-items-center" style={{ color: `${item?.submited ? "#008000" : "#f84343"}` }}>
                                                    <h5 className="font-weight-600 mb-0 mr-2" style={{ color: `${item?.submited ? "#008000" : "#f84343"}` }}>
                                                        Submitted
                                                    </h5>
                                                    {item?.submited ? <CheckCircleOutlineIcon sx={{ fontSize: 35 }} /> : <CancelOutlinedIcon sx={{ fontSize: 35 }} />}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ShopReapirRequestCard
                                        item={item}
                                        index={index}
                                        machineCompos={machineCompos}
                                        setMachineCompos={setMachineCompos}
                                        handleSnack={handleSnack}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </div>
                <div className="row px-3 mt-3 mb-0" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary next-save-btn" onClick={handleRequestNextClick}>
                        Next
                    </button>
                </div>
            </>
        );
    };

    // recive the component
    const handleReceiveComponent = (row) => {
        setReceiptRecords((prev) => prev.map((item) => (item?.id === row?.id ? { ...item, received: true } : item)));
    };

    // click on receipt next click
    const handleReceiptNextClick = () => {
        const _submitedRequests = machineCompos.filter((item) => item?.submited);

        const _returnedRecords = [...returnedRecords];

        if (_submitedRequests.length > 0) {
            _submitedRequests.forEach((item) => {
                let receiptIndex = receiptRecords.findIndex((row) => row.id === item?.id);
                let receiptRow = receiptRecords.find((row) => row.id === item?.id + 1);
                let index = _returnedRecords.findIndex((row) => row.id === item?.id);

                if (index === -1) {
                    // If the item doesn't exist, add the new rows
                    _returnedRecords.push(
                        {
                            ...item,
                            statusTitle: "Repair Request",
                            isRequested: true,
                            isReceipt: false,
                            received: false,
                            isReturned: false,
                            returned: false,
                        },
                        {
                            ...item,
                            statusTitle: "Returned For Repair",
                            isRequested: receiptRow?.isRequested,
                            isReceipt: receiptRow?.isReceipt,
                            received: receiptRow?.received,
                            isReturned: receiptRow?.isReturned,
                            returned: receiptRow?.returned,
                            id: receiptRow?.id,
                        },
                        {
                            ...item,
                            statusTitle: "Return after Repair",
                            id: receiptRow?.id + 1,
                            isRequested: false,
                            isReceipt: false,
                            received: receiptRow?.received,
                            isReturned: true,
                            returned: false,
                        }
                    );
                } else {
                    // If the item exists, update the rows
                    const requestRow = _returnedRecords[index];
                    const receiptRow = _returnedRecords.find((row) => row.id === item?.id + 1);
                    const returnedRow = _returnedRecords.find((row) => row.id === item?.id + 2);

                    // Update the request row
                    _returnedRecords[index] = {
                        ...requestRow,
                        ...item,
                        statusTitle: "Repair Request",
                        isRequested: true,
                        isReceipt: false,
                        received: false,
                        isReturned: false,
                        returned: false,
                    };

                    // Update the repair row (if it exists)
                    if (receiptRow) {
                        const repairIndex = _returnedRecords.findIndex((row) => row.id === item?.id + 1);
                        _returnedRecords[repairIndex] = {
                            ...receiptRow,
                            ...item,
                            id: receiptRow?.id,
                            statusTitle: "Returned For Repair",
                            isRequested: receiptRow?.isRequested,
                            isReceipt: receiptRow?.isReceipt,
                            received: receiptRow?.received,
                            isReturned: receiptRow?.isReturned,
                            returned: receiptRow?.returned,
                        };
                    }

                    // Update the repair row (if it exists)
                    if (returnedRow) {
                        const repairIndex = _returnedRecords.findIndex((row) => row.id === item?.id + 2);
                        _returnedRecords[repairIndex] = {
                            ...returnedRow,
                            ...item,
                            id: returnedRow?.id,
                            statusTitle: "Return after Repair",
                            isRequested: returnedRow?.isRequested,
                            isReceipt: returnedRow?.isReceipt,
                            received: returnedRow?.received,
                            isReturned: returnedRow?.isReturned,
                            returned: returnedRow?.returned,
                        };
                    }
                }
            });
        }

        // Update state with the modified records
        setReturnedRecords(_returnedRecords);
        setTabValue("returned");
    };

    const receiptColumns = [
        {
            field: "statusTitle",
            headerName: "STATUS",
            flex: 1,
            width: 100,
            sortable: false,
            renderCell: ({ row }) => (
                <div
                    className="d-flex justify-content-between align-items-center py-2"
                    style={{
                        fontSize: 15,
                        paddingInline: 5,
                        paddingBlock: 2,
                        borderRadius: 6,
                        // color: row?.submited ? "#008000" : "#f84343",
                    }}
                >
                    <span
                        style={{
                            borderRadius: 10,
                            content: '" "',
                            display: "block",
                            marginRight: 8,
                            height: 10,
                            width: 10,
                            backgroundColor: row?.isRequested ? "#008000" : row?.isReceipt ? (row?.received ? "#008000" : "#f84343") : "#f84343",
                        }}
                    />
                    {row?.statusTitle}
                </div>
            ),
        },
        {
            field: "orderNo",
            headerName: "DETAILS",
            sortable: false,
            flex: 1,
            width: 200,
            renderCell: ({ row }) => (
                <div className="">
                    <span className="">{row?.serialNo || row?.customerName ? `${row?.serialNo} - ${row?.customerName}` : ""}</span>
                </div>
            ),
        },
        {
            field: "action",
            type: "actions",
            headerName: "ADDITIONALS/ACTION",
            flex: 1,
            cellClassName: "actions",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            row?.isRequested ? (
                                <div className="d-flex align-items-center" style={{ color: "#008000" }}>
                                    <h5 className="font-weight-600 mb-0 mr-2" style={{ color: "#008000" }}>
                                        Submitted
                                    </h5>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 35 }} />
                                </div>
                            ) : row?.isReceipt ? (
                                row?.received ? (
                                    <div className="d-flex align-items-center" style={{ color: "#008000" }}>
                                        <h5 className="font-weight-600 mb-0 mr-2" style={{ color: "#008000" }}>
                                            Received
                                        </h5>
                                        <CheckCircleOutlineIcon sx={{ fontSize: 35 }} />
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex align-items-center justify-content-center border border-radius-6 px-3 py-2 cursor"
                                        style={{
                                            color: "#f84343",
                                            backgroundColor: "#f9f9f9",
                                            border: "1px solid #f84343",
                                            borderRadius: "6px",
                                            fontWeight: "600",
                                        }}
                                        onClick={() => handleReceiveComponent(row)}
                                    >
                                        Receive Component
                                    </div>
                                )
                            ) : (
                                ""
                            )
                        }
                        label="Receive Component"
                    />,
                ];
            },
        },
    ];

    // receipt tab content
    const receiptContent = useCallback(() => {
        return (
            <>
                <DataGrid rows={receiptRecords} columns={receiptColumns} sx={GENERAL_TRANSPRINT_GRID_STYLE} autoHeight />
                <div className="row px-3 mt-3 mb-0" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary next-save-btn" onClick={handleReceiptNextClick}>
                        Next
                    </button>
                </div>
            </>
        );
    }, [receiptRecords]);

    // Return Componet, received
    const handleReturnRecivedComp = (row) => {
        setReturnedRecords((prev) => prev.map((item) => (item?.id === row?.id ? { ...item, received: true } : item)));
    };

    // return component
    const handleReturnedComp = (row) => {
        setReturnedRecords((prev) => prev.map((item) => (item?.id === row?.id ? { ...item, returned: true } : item)));
    };

    // returned coumns
    const returnedColumns = [
        {
            field: "statusTitle",
            headerName: "STATUS",
            flex: 1,
            width: 100,
            sortable: false,
            renderCell: ({ row }) => (
                <div className="d-flex justify-content-between align-items-center py-2" style={{ fontSize: 15, paddingInline: 5, paddingBlock: 2, borderRadius: 6 }}>
                    <span
                        style={{
                            borderRadius: 10,
                            content: '" "',
                            display: "block",
                            marginRight: 8,
                            height: 10,
                            width: 10,
                            backgroundColor: row?.isRequested
                                ? "#008000"
                                : row?.isReceipt
                                ? row?.received
                                    ? "#008000"
                                    : "#f84343"
                                : row?.isReturned
                                ? row?.returned
                                    ? "#008000"
                                    : "#f84343"
                                : "#f84343",
                        }}
                    />
                    {row?.statusTitle}{" "}
                </div>
            ),
        },
        {
            field: "orderNo",
            headerName: "DETAILS",
            sortable: false,
            flex: 1,
            width: 200,
            renderCell: ({ row }) => (
                <div className="">
                    <span className="">{row?.serialNo || row?.customerName ? `${row?.serialNo} - ${row?.customerName}` : ""}</span>
                </div>
            ),
        },
        {
            field: "action",
            type: "actions",
            headerName: "ADDITIONALS/ACTION",
            flex: 1,
            cellClassName: "actions",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            row?.isRequested ? (
                                <div className="d-flex align-items-center" style={{ color: "#008000" }}>
                                    <h5 className="font-weight-600 mb-0 mr-2" style={{ color: "#008000" }}>
                                        Submitted
                                    </h5>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 35 }} />
                                </div>
                            ) : row?.isReceipt ? (
                                row?.received ? (
                                    <div className="d-flex align-items-center" style={{ color: "#008000" }}>
                                        <h5 className="font-weight-600 mb-0 mr-2" style={{ color: "#008000" }}>
                                            Received
                                        </h5>
                                        <CheckCircleOutlineIcon sx={{ fontSize: 35 }} />
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex align-items-center justify-content-center border border-radius-6 px-3 py-2 cursor"
                                        style={{ color: "#f84343", backgroundColor: "#f9f9f9", border: "1px solid #f84343", borderRadius: "6px", fontWeight: "600" }}
                                        onClick={() => handleReturnRecivedComp(row)}
                                    >
                                        Receive Component
                                    </div>
                                )
                            ) : row?.isReturned ? (
                                row?.returned ? (
                                    <div className="d-flex align-items-center" style={{ color: "#008000" }}>
                                        <h5 className="font-weight-600 mb-0 mr-2" style={{ color: "#008000" }}>
                                            Returned
                                        </h5>
                                        <CheckCircleOutlineIcon sx={{ fontSize: 35 }} />
                                    </div>
                                ) : (
                                    <div
                                        className="d-flex align-items-center justify-content-center border border-radius-6 px-3 py-2 cursor"
                                        style={{ color: "#f84343", backgroundColor: "#f9f9f9", border: "1px solid #f84343", borderRadius: "6px", fontWeight: "600" }}
                                        onClick={() => handleReturnedComp(row)}
                                    >
                                        Returned Component
                                    </div>
                                )
                            ) : (
                                ""
                            )
                        }
                        label="Receive Component"
                    />,
                ];
            },
        },
    ];

    // returned tab content
    const returnedContent = useCallback(() => {
        return (
            <>
                <DataGrid rows={returnedRecords} columns={returnedColumns} sx={GENERAL_TRANSPRINT_GRID_STYLE} autoHeight />
                <div className="row px-3 mt-3 mb-0" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary next-save-btn" onClick={() => setActiveStep((pre) => pre + 1)}>
                        Next
                    </button>
                </div>
            </>
        );
    }, [returnedRecords]);

    return (
        <>
            <Box className="mt-2" sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList className="custom-tabs-div" onChange={handleChange}>
                            <Tab label="REQUEST" value="request" />
                            <Tab label="RECEIPT " value="receipt" />
                            <Tab label="RETURNED" value="returned" />
                        </TabList>
                    </Box>
                    <TabPanel value={tabValue}>
                        {tabValue === "request" && requestContent()}
                        {tabValue === "receipt" && receiptContent()}
                        {tabValue === "returned" && returnedContent()}
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
};

export default ShopReapirRequestRecipt;
