import React, { useEffect, useState } from "react";

import uploadIcon from "../../../assets/icons/svg/upload.svg";

import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Box, Tab, Tooltip } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { currencyFormatter } from "pages/Common/utils/currencyFormatter";

import Moment from "react-moment";
import DataTable from "react-data-table-component";
import LoadingProgress from "pages/Repair/components/Loader";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { expendedTableCustomStyle, expendTableCustomStyle, inventoryStatusBgColor, inventoryStatusColor } from "../warehouseConstents";
import SearchBox from "pages/Common/SearchBox";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { PARTS_ORDER_MASTER_URL, SPARE_PARTS_MASTER_URL_DATA_SVC, WAREHOUSE_INVENTORY_PARTS_URL } from "services/CONSTANTS";
import { GRID_STYLE } from "pages/Common/constants";

import InventoryPartSearch from "./InventoryPartSearch";
import { ProgressBar } from "react-bootstrap";
import AllocateStock from "./AllocateStock";
import AddUpdateSTO from "../STO/AddUpdateSTO";
import AddUpdatePR from "../PR/AddUpdatePR";
import PartsDetailsModal from "./PartsDetailsModal";
import PartsWarehouseAddUpdateModal from "./PartsWarehouseAddUpdateModal";

const InventoryParts = ({ handleSnack }) => {
  const [tabValue, setTabValue] = useState("inventory");

  const [expandedPartRowId, setExpandedPartRowId] = useState(null);
  const [partsRecords, setPartsRecords] = useState([]);
  const [partPageNo, setPartPageNo] = useState(1);
  const [partsPerPage, setPartsPerPage] = useState(5);
  const [partsTotalRows, setPartsTotalRows] = useState(0);
  const [partsLoading, setPartsLoading] = useState(false);
  const [removeFilter, setRemoveFilter] = useState(0);

  const [wareHousePartPageNo, setWareHousePartPageNo] = useState(1);
  const [wareHousePartPerPage, setWareHousePartPerPage] = useState(5);
  const [wareHousePartsTotalRows, setWareHousePartsTotalRows] = useState(0);
  const [wareHouserPartsLoading, setWareHouserPartsLoading] = useState(false);

  const [orderStockRecords, setOrderStockRecords] = useState([]);
  const [orderStockPageNo, setOrderStockPageNo] = useState(1);
  const [orderStockPerPage, setOrderStockPerPage] = useState(10);
  const [orderStockTotalRows, setOrderStockTotalRows] = useState(0);
  const [orderStockLoading, setOrderStockLoading] = useState(false);

  const [partStockWareHouses, setPartStockWareHouses] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderRow, setSelectedOrderRow] = useState(null);

  const [openPartsAllocationModal, setOpenPartsAllocationModal] = useState(false);

  const [partsOrderId, setPartsOrderId] = useState(null);
  const [partsCartId, setPartsCartId] = useState(null);
  const [partsOrderObj, setPartsOrderObj] = useState({
    partOrderNumber: "",
    customer: "",
    address: "",
    partsQty: null,
  });
  const [pendingParts, setPendingParts] = useState([]);
  const [partOrderRow, setPartOrderRow] = useState(null);

  const [openSTOModal, setOpenSTOModal] = useState(false);
  const [openPRModal, setOpenPRModal] = useState(false);

  const [openPartDtlsModal, setOpenPartDtlsModal] = useState(false);
  const [partRecordId, setPartRecordId] = useState(null);
  const [partWarehouse, setPartWarehouse] = useState("");
  const [partWarehouseRow, setPartWarehouseRow] = useState(null);
  const [partsExpendedRow, setPartsExpendedRow] = useState(null);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrderRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getSpareParts();
  }, [removeFilter]);

  useEffect(() => {
    getPartsOrders();
  }, []);

  // get spare parts
  const getSpareParts = () => {
    setPartsLoading(true);
    const rUrl = SPARE_PARTS_MASTER_URL_DATA_SVC + `/?pageNumber=${0}&pageSize=${15}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartsTotalRows(responseData.length);
          setPartsRecords(responseData);
          setPartsLoading(false);
        } else {
          setPartsTotalRows(0);
          setPartsRecords([]);
          setPartsLoading(false);
        }
      },
      (error) => {
        setPartsTotalRows(0);
        setPartsRecords([]);
        setPartsLoading(false);
      }
    );
  };

  // get parts orders
  const getPartsOrders = () => {
    // const rUrl = PARTS_ORDER_MASTER_URL + `/service-cpq?pageNumber=${orderStockPageNo - 1}&pageSize=${orderStockPerPage}`;
    const rUrl = PARTS_ORDER_MASTER_URL + `/service-cpq?pageNumber=${0}&pageSize=${20}`;
    setOrderStockLoading(false);
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setOrderStockTotalRows(responseData.length);

          const _responseData = responseData.map((item) => ({
            ...item,
            // sendToERP: false,
            salesOffice: item?.salesOffice ? Number(item?.salesOffice) || 0 : 0,
          }));
          setOrderStockRecords(_responseData);
        } else {
          setOrderStockTotalRows(0);
          setOrderStockRecords([]);
        }
      },
      (error) => {
        setOrderStockTotalRows(0);
        setOrderStockRecords([]);
      }
    );
  };

  // is decimal number
  const isDecimal = (num) => {
    return num % 1 !== 0;
  };

  // expend or hide the table
  const handleExpendOpenCloseTable = (row) => {
    if (row.id === expandedPartRowId) {
      setExpandedPartRowId(null);
      setPartStockWareHouses([]);
      setWareHouserPartsLoading(false);
      setPartsExpendedRow(null);
    } else {
      setPartsExpendedRow(row);
      setWareHouserPartsLoading(true);
      const rUrl = `${WAREHOUSE_INVENTORY_PARTS_URL}${row?.partNumber}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setPartStockWareHouses(responseData);
            setWareHouserPartsLoading(false);
          } else {
            setPartStockWareHouses([]);
            setWareHouserPartsLoading(false);
          }
        },
        (error) => {
          setPartStockWareHouses([]);
          setWareHouserPartsLoading(false);
        }
      );
      // if (row?.legacyMaterial === "D_WH_01") {
      //   const _row = [
      //     {
      //       ...row,
      //       legacyMaterial: "D_WH_01",
      //       replacesPartNumber: isDecimal(Number(row["replacesPartNumber"]) / 2)
      //         ? Number(Number(row["replacesPartNumber"]) + 1) / 2
      //         : Number(row["replacesPartNumber"]) / 2,
      //       modelGroupDescription: isDecimal(Number(row["modelGroupDescription"]) / 2)
      //         ? Number(Number(row["modelGroupDescription"]) + 1) / 2
      //         : Number(row["modelGroupDescription"]) / 2,
      //       materialGroup: isDecimal(Number(row["materialGroup"]) / 2)
      //         ? Number(Number(row["materialGroup"]) + 1) / 2
      //         : Number(row["materialGroup"]) / 2,
      //       materialGroupDescription: isDecimal(Number(row["materialGroupDescription"]) / 2)
      //         ? Number(Number(row["materialGroupDescription"]) + 1) / 2
      //         : Number(row["materialGroupDescription"]) / 2,
      //       availability: isDecimal(Number(row["availability"]) / 2) ? Number(Number(row["availability"]) + 1) / 2 : Number(row["availability"]) / 2,
      //     },
      //     {
      //       ...row,
      //       legacyMaterial: "M_WH_01",
      //       replacesPartNumber: isDecimal(Number(row["replacesPartNumber"]) / 2)
      //         ? Number(Number(row["replacesPartNumber"]) - 1) / 2
      //         : Number(row["replacesPartNumber"]) / 2,
      //       modelGroupDescription: isDecimal(Number(row["modelGroupDescription"]) / 2)
      //         ? Number(Number(row["modelGroupDescription"]) - 1) / 2
      //         : Number(row["modelGroupDescription"]) / 2,
      //       materialGroup: isDecimal(Number(row["materialGroup"]) / 2)
      //         ? Number(Number(row["materialGroup"]) - 1) / 2
      //         : Number(row["materialGroup"]) / 2,
      //       materialGroupDescription: isDecimal(Number(row["materialGroupDescription"]) / 2)
      //         ? Number(Number(row["materialGroupDescription"]) - 1) / 2
      //         : Number(row["materialGroupDescription"]) / 2,
      //       availability: isDecimal(Number(row["availability"]) / 2) ? Number(Number(row["availability"]) - 1) / 2 : Number(row["availability"]) / 2,
      //     },
      //   ];
      //   setPartStockWareHouses([..._row]);
      // } else {
      //   setPartStockWareHouses([row]);
      // }
      setExpandedPartRowId(row.id);
    }
  };

  // parts column
  const partsColumns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partNumber || "-",
    },
    {
      id: "partDescription",
      name: "Part Description",
      selector: (row) => row.partDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partDescription || "-",
    },
    {
      id: "partType",
      name: "Type",
      selector: (row) => row.partType,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partType || "-",
    },
    {
      id: "partsGroup",
      name: "Part Group",
      selector: (row) => row.partsGroup,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partsGroup || "-",
    },
    {
      id: "model",
      name: "Model",
      selector: (row) => row.model,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.model || "-",
    },
    {
      id: "stockQuantity",
      name: "Total Stock",
      selector: (row) => row.stockQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => {
        const _warehouseInventories = row["warehouseInventories"];
        const _totalStock = _warehouseInventories.reduce((sum, item) => {
          return sum + item?.totalQuantity;
        }, 0);
        return (
          <>
            <div className="d-flex justify-content-between align-items-center w-100">
              {/* <span>{row.demand || 0}</span> */}
              <span>{_totalStock || 0}</span>
              <button
                className={`border px-2 py-1 border-radius-10 ${row.id === expandedPartRowId ? "active-filter-warehouse" : ""}`}
                onClick={() => handleExpendOpenCloseTable(row)}
              >
                {row.id === expandedPartRowId ? (
                  <>
                    Hide <ExpandLessIcon className="font-size-32 mx-1" />
                  </>
                ) : (
                  <>
                    Expand <ExpandMoreIcon className="font-size-32 mx-1" />
                  </>
                )}
              </button>
            </div>
          </>
        );
      },
    },
    {
      id: "stockStatus",
      name: "Status",
      selector: (row) => row.stockStatus,
      wrap: true,
      minWidth: 100,
      cell: (row) => (
        <>
          <div
            className="d-flex justify-content-between align-items-center py-2"
            style={{
              fontSize: 15,
              backgroundColor:
                inventoryStatusBgColor[
                  row.availability ? (row.availability === 0 ? "out of stock" : row.availability > 30 ? "in stock" : "low stock") : "out of stock"
                ],
              paddingInline: 5,
              paddingBlock: 2,
              borderRadius: 6,
              textTransform: "capitalize",
              color:
                inventoryStatusColor[
                  row.availability ? (row.availability === 0 ? "out of stock" : row.availability > 30 ? "in stock" : "low stock") : "out of stock"
                ],
            }}
          >
            <span
              style={{
                borderRadius: 10,
                content: '" "',
                display: "block",
                marginRight: 8,
                height: 10,
                width: 10,
                backgroundColor:
                  inventoryStatusColor[
                    row.availability ? (row.availability === 0 ? "out of stock" : row.availability > 30 ? "in stock" : "low stock") : "out of stock"
                  ],
              }}
            ></span>
            {row.availability ? (row.availability === 0 ? "Out of Stock" : row.availability > 30 ? "In Stock" : "Low Stock") : "Out of Stock"}
          </div>
        </>
      ),
    },
  ];

  // part warehouse columns
  const partWareHouseStockColumns = [
    {
      id: "partNumber",
      name: "Part Number",
      selector: (row) => row.partsNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partsNumber || "-",
    },
    {
      id: "partDescription",
      name: "Part Description",
      selector: (row) => row.partsDescription,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.partsDescription || "-",
    },
    {
      id: "plant",
      name: "Plant",
      selector: (row) => row.warehouseNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.warehouseNumber || "",
    },
    {
      id: "warehouse",
      name: "Warehouse",
      selector: (row) => row.warehouseNumber,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.warehouseNumber || "-",
    },
    {
      id: "available",
      name: "Available",
      selector: (row) => row.availableQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row.availableQuantity || "0",
    },
    {
      id: "qualityInspection",
      name: "Quality Inspection",
      selector: (row) => row?.qualityInspectionQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row?.qualityInspectionQuantity || "0",
    },
    {
      id: "blocked",
      name: "Blocked",
      selector: (row) => row?.blockedQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row?.blockedQuantity || "0",
    },
    {
      id: "onOrderStock",
      name: "On-Order Stock",
      selector: (row) => row?.blockedQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row?.blockedQuantity || "0",
    },
    {
      id: "consignmentStock",
      name: "Consignment Stock",
      selector: (row) => row?.consignmentStockQuantity,
      wrap: true,
      minWidth: 100,
      cell: (row) => row?.consignmentStockQuantity || "0",
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => row.status,
      wrap: true,
      minWidth: 200,
      cell: (row) => (
        <div className="d-flex justify-content-center align-items-center">
          {/* <div className="cursor">
            <Tooltip title="View">
              <VisibilityIcon />
            </Tooltip>
          </div> */}
          <div className="cursor mx-2" onClick={() => handleOpenPartDtlsModal(row)}>
            <Tooltip title="Edit">
              <EditOutlinedIcon />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  // open Parts Details Modal
  const handleOpenPartDtlsModal = (row) => {
    setPartRecordId(row?.id);
    // setPartWarehouse(row?.legacyMaterial);
    // setPartRecordId(row?.partsId);
    setPartWarehouse(row?.warehouseNumber);
    setPartWarehouseRow(row);
    setOpenPartDtlsModal(true);
  };

  // open Parts Details Create Modal
  const handleOpenPartDtlsCreateModal = (row) => {
    setPartRecordId(null);
    setPartWarehouse("");
    setPartsExpendedRow(row);
    setPartWarehouseRow(null);
    setOpenPartDtlsModal(true);
  };

  // Allocate Part
  const handleAllocatePart = (row) => {
    handleOpenPartsAllocationModal(selectedOrderRow);
    handleClose();
    // setAnchorEl(null);
  };

  // sent to ERP Part
  const handleSendToERP = (row) => {
    handleSnack("info", "Order is sent to ERP");
    handleClose();
    // setAnchorEl(null);
  };

  // order stock columns
  const orderStockColumns = [
    {
      field: "partOrderNumber",
      headerName: "Order #",
      flex: 1,
      width: 100,
    },
    {
      field: "totalPrice",
      headerName: "Price",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => currencyFormatter.format(row.totalPrice || 0),
    },
    {
      field: "partsOrderDate",
      headerName: "Created",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (row?.partsOrderDate ? <Moment format="DD/MM/YYYY">{row?.partsOrderDate}</Moment> : "-"),
    },
    {
      field: "orderingPlantName",
      headerName: "Ordering Plant",
      flex: 1,
      width: 100,
    },
    {
      field: "deliveringPlantName",
      headerName: "Delivery Plant",
      flex: 1,
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => {
        const _totalAllocatedQuantity = row["totalAllocatedQuantity"];
        const totalPartsQty =
          row["partsQty"] && row["partsQty"].length > 0 ? row["partsQty"].reduce((acc, curr) => acc + (curr?.quantity || 0), 0) : 0;

        const statusClass =
          _totalAllocatedQuantity === totalPartsQty ? "text-success" : _totalAllocatedQuantity === 0 ? "text-danger" : "text-warning";

        const statusText = _totalAllocatedQuantity === totalPartsQty ? "Complete" : _totalAllocatedQuantity === 0 ? "Pending" : "Partially Received";
        return <div className={`${statusClass} py-2 px-3 border border-radius-10 font-weight-500`}>{statusText}</div>;
      },
    },
    {
      field: "itemReceived",
      headerName: "Item Received",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => {
        const _totalAllocatedQuantity = row["totalAllocatedQuantity"];
        const totalPartsQty =
          row["partsQty"] && row["partsQty"].length > 0 ? row["partsQty"].reduce((acc, curr) => acc + (curr?.quantity || 0), 0) : 0;
        const progressbarColor = _totalAllocatedQuantity === totalPartsQty ? "success" : _totalAllocatedQuantity === 0 ? "danger" : "warning";

        const progressbarClass =
          _totalAllocatedQuantity === totalPartsQty
            ? "custom-in-stock-bar"
            : _totalAllocatedQuantity === 0
            ? "custom-out-of-stock-bar"
            : "custom-low-stock-bar";

        return (
          <div className="d-flex align-items-center w-100">
            <ProgressBar
              variant={`${progressbarColor}`}
              now={Math.ceil((_totalAllocatedQuantity / totalPartsQty) * 100) || 0}
              className={`${progressbarClass} mr-2 w-100`}
              style={{ height: "8px" }}
            />
            <span>
              {_totalAllocatedQuantity}/{totalPartsQty}
            </span>
          </div>
        );
      },
    },
    {
      field: "sendToERP",
      headerName: "Send ERP",
      flex: 1,
      width: 100,
      renderCell: ({ row }) =>
        row?.sendToERP ? (
          <span className="erp-status-sent">
            <CheckIcon />
          </span>
        ) : (
          <span className="erp-status-not-sent">X</span>
        ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      width: 200,
      renderCell: ({ row }) => (
        <>
          <button className="btn text-primary font-size-500" onClick={() => handleOpenPartsAllocationModal(row)}>
            Allocate
          </button>
        </>
      ),
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <>
                <div>
                  <IconButton onClick={(event) => handleClick(event, row)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                    className="border"
                    sx={{
                      "& .MuiPaper-root": { boxShadow: "none", border: "1px solid #cfcece !important" },
                    }}
                  >
                    <MenuItem className="mb-0" sx={{ padding: "8px 8px" }} onClick={() => handleAllocatePart(row)}>
                      Allocate {row?.id}
                    </MenuItem>
                    <MenuItem className="mb-0" sx={{ padding: "8px 8px" }} onClick={() => handleSendToERP(row)}>
                      Send to ERP {row?.id}
                    </MenuItem>
                  </Menu>
                </div>
              </>
            }
            label="View"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // fetch Parts Stock Items
  const fetchPartsStockItems = async (isExpanded, row) => {
    if (isExpanded) {
      try {
        setWareHousePartsTotalRows(1);
        setPartStockWareHouses([row]);
        setExpandedPartRowId(row.id);
      } catch (error) {
        console.error("Error fetching components:", error);
      } finally {
        //   setComponentLoading(false);
      }
    } else {
      setExpandedPartRowId(null);
    }
  };

  // expedn Parts Stock Row
  const expendablePartStockRow = (row) => {
    return (
      <div className="p-3 border-bottom mb-2 expended-table-inventory">
        <div className="border">
          <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0 font-weight-600 font-size-14 mr-3">Plant Stocks</h6>
            </div>
            <div className="d-flex align-items-center ">
              <div className=" text-center border-left">
                <a href="#" className="mr-3 font-size-14 cursor" title="Upload">
                  <img src={uploadIcon}></img>
                </a>
                <span className="font-size-14 cursor" onClick={() => handleOpenPartDtlsCreateModal(row)}>
                  + Add New
                </span>
              </div>
            </div>
          </div>
          <DataTable
            data={partStockWareHouses}
            columns={partWareHouseStockColumns}
            customStyles={expendedTableCustomStyle}
            paginationPerPage={wareHousePartPerPage}
            onChangePage={(e) => setWareHousePartPageNo(e)}
            onChangeRowsPerPage={(e) => {
              setWareHousePartPageNo(1);
              setWareHousePartPerPage(e);
            }}
            paginationTotalRows={wareHousePartsTotalRows}
            paginationRowsPerPageOptions={[5, 10]}
            paginationDefaultPage={wareHousePartPageNo}
            progressPending={wareHouserPartsLoading}
            progressComponent={<LoadingProgress />}
            persistTableHead
            pagination
            paginationServer
          />
        </div>
      </div>
    );
  };

  // open parts order Allocation Modal
  const handleOpenPartsAllocationModal = (row) => {
    let _customer = ``;

    if (row["customerName"] && row["customerName"].includes(" - ")) {
      _customer = row?.customerName;
    } else {
      _customer = `${row?.customerId} ${row?.customerName && "-" + row?.customerName}`;
    }
    setPartsOrderObj({
      partOrderNumber: row?.partOrderNumber,
      customer: _customer,
      address: row?.shippingAddress,
      partsQty: row?.partsQty,
    });
    setPartsOrderId(row.partsOrderId);
    setPartsCartId(row?.cart);
    setPartOrderRow(row);
    setPendingParts([]);
    // setPendingParts(row?.partsQty ? row?.partsQty : []);

    setOpenPartsAllocationModal(true);
  };

  // open parts order Allocation Modal
  const handleClosePartsAllocationModal = () => {
    setOpenPartsAllocationModal(false);
    setPartsOrderId(null);
  };

  // open create STO Modal
  const handleOpenCreateSTOModal = () => {
    setOpenSTOModal(true);
    setOpenPartsAllocationModal(false);
  };

  // close Create STO Modal
  const handleCloseCreateSTOModal = () => {
    setOpenSTOModal(false);
  };

  // open create CTO Modal
  const handleOpenCreatePRModal = () => {
    setOpenPRModal(true);
    setOpenPartsAllocationModal(false);
  };

  // close Create CTO Modal
  const handleCloseCreatePRModal = () => {
    setOpenPRModal(false);
  };

  return (
    <>
      <div className="row my-5">
        <div className="col-md-2 col-sm-2 border-right pr-5">
          <div className="d-flex">
            <MonetizationOnOutlinedIcon className="font-size-32 text-primary mt-1" />
            <div className="mx-2">
              <span className="font-size-13 font-weight-500">TOTAL ASSET VALUE</span>
              <h3>
                {currencyFormatter.format(
                  partsRecords.reduce((sum, partItem) => {
                    return sum + (partItem.listPrice || 0) * Number(partItem.availability || 0);
                  }, 0)
                )}
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 pl-5">
          <div className="d-flex align-items-center">
            <h3>{partsTotalRows}</h3>
            <span className="font-size-13 font-weight-500 mx-2">PRODUCT</span>
          </div>
          <div className="d-flex justify-content-evenly">
            <div
              className="inventory-products-line  border-radius-10 bg-green"
              style={{ width: `${(partsRecords.filter((item) => item.availability > 30).length / partsTotalRows) * 100}%` }}
            ></div>
            <div
              className="inventory-products-line border-radius-10 mx-2 bg-yellow"
              style={{ width: `${(partsRecords.filter((item) => item.availability > 0 && item.availability <= 30).length / partsTotalRows) * 100}%` }}
            ></div>
            <div
              className="inventory-products-line border-radius-10 bg-red"
              style={{ width: `${(partsRecords.filter((item) => !item.availability || item.availability === 0).length / partsTotalRows) * 100}%` }}
            ></div>
          </div>
          <div className="d-flex justify-content-evenly mt-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#00b8b0" }}
                ></span>
                <span className="font-size-13">In Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">
                {partsRecords.filter((item) => item.availability > 30).length}
                <span className="font-size-10 mx-1">Product</span>
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center mx-3">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#d8bd5f" }}
                ></span>
                <span className="font-size-13">Low Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">
                {partsRecords.filter((item) => item.availability > 0 && item.availability <= 30).length}
                <span className="font-size-10 mx-1">Product</span>
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ borderRadius: 10, content: '" "', display: "block", marginRight: 5, height: 10, width: 10, backgroundColor: "#c14e56" }}
                ></span>
                <span className="font-size-13">Out of Stock:</span>
              </div>
              <span className="font-size-14 font-weight-500 mx-2">
                {partsRecords.filter((item) => !item.availability || item.availability === 0).length}
                <span className="font-size-10 mx-1">Product</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 3 }}>
            <TabList className="" onChange={(e, type) => setTabValue(type)}>
              <Tab label="Inventory" value={"inventory"} className="heading-tabs" />
              <Tab label="Order Stock" value={"orderStock"} className="heading-tabs" />
            </TabList>
          </Box>
          <TabPanel value="inventory" sx={{ paddingX: 0 }}>
            <>
              <InventoryPartSearch
                setPartsRecords={setPartsRecords}
                setPartsTotalRows={setPartsTotalRows}
                setPartsLoading={setPartsLoading}
                setRemoveFilter={setRemoveFilter}
                handleSnack={handleSnack}
                setExpandedPartRowId={setExpandedPartRowId}
                setPartStockWareHouses={setPartStockWareHouses}
                setWareHouserPartsLoading={setWareHouserPartsLoading}
              />
              <DataTable
                data={partsRecords}
                columns={partsColumns}
                customStyles={{
                  ...expendTableCustomStyle,
                  expanderCell: { style: { display: "none" } },
                  expanderButton: { style: { display: "none" } },
                }}
                expandableRows={true}
                expandableRowsComponent={(itemData) => expendablePartStockRow(itemData.data, true)}
                expandableRowExpanded={(row) => row.id === expandedPartRowId}
                onRowExpandToggled={fetchPartsStockItems}
                paginationPerPage={partsPerPage}
                onChangePage={(e) => setPartPageNo(e)}
                onChangeRowsPerPage={(e) => {
                  setPartPageNo(1);
                  setPartsPerPage(e);
                }}
                paginationTotalRows={partsTotalRows}
                paginationRowsPerPageOptions={[5, 10]}
                paginationDefaultPage={partPageNo}
                progressPending={partsLoading}
                progressComponent={<LoadingProgress />}
                persistTableHead
                pagination
              />
            </>
          </TabPanel>
          <TabPanel value="orderStock" sx={{ paddingX: 0 }}>
            <div className="d-flex justify-content-between align-items-center my-3">
              <div className="customselectsearch w-25">
                <div className={`form-control-search`}>
                  <span class="fa fa-search fa-lg " />
                </div>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  placeholder="Search Part"
                  // value={props.value}
                  autoComplete="off"
                  // onChange={props.onChange}
                  // disabled={props.disabled}
                  // placeholder={props.placeholder || ""}
                />
              </div>
              <button className="btn border-primary text-primary">+ Create New</button>
            </div>
            <DataGrid
              loading={orderStockLoading}
              sx={GRID_STYLE}
              getRowId={(row) => row.partsOrderId}
              // page={orderStockPageNo + 1}
              autoHeight
              // pageSize={orderStockPerPage}
              // onPageChange={(newPage) => getStoRecords(newPage, orderStockPerPage)}
              // onPageSizeChange={(newPageSize) => getStoRecords(orderStockPageNo, newPageSize)}
              rows={orderStockRecords}
              pageSize={10}
              columns={orderStockColumns}
              rowsPerPageOptions={[10, 20, 50]}
              pagination={true}
              // paginationMode="server"
              // rowCount={totalRows}
            />
          </TabPanel>
        </TabContext>
      </Box>

      {openPartsAllocationModal && (
        <AllocateStock
          show={openPartsAllocationModal}
          hideModal={handleClosePartsAllocationModal}
          handleSnack={handleSnack}
          recordId={partsOrderId}
          partsOrderObj={partsOrderObj}
          pendingParts={pendingParts}
          setPendingParts={setPendingParts}
          handleOpenCreateSTOModal={handleOpenCreateSTOModal}
          handleOpenCreatePRModal={handleOpenCreatePRModal}
          orderStockRecords={orderStockRecords}
          setOrderStockRecords={setOrderStockRecords}
          partOrderRow={partOrderRow}
          // recordId={partsOrderId}
        />
      )}

      {openSTOModal && (
        <AddUpdateSTO
          show={openSTOModal}
          hideModal={handleCloseCreateSTOModal}
          handleSnack={handleSnack}
          partsOrderObj={partsOrderObj}
          pendingParts={pendingParts}
        />
      )}
      {openPRModal && (
        <AddUpdatePR
          show={openPRModal}
          hideModal={handleCloseCreatePRModal}
          handleSnack={handleSnack}
          partsOrderObj={partsOrderObj}
          pendingParts={pendingParts}
        />
      )}

      {/* {openPartDtlsModal && (
        <PartsDetailsModal
          show={openPartDtlsModal}
          hideModal={() => setOpenPartDtlsModal(false)}
          recordId={partRecordId}
          warehouse={partWarehouse}
          handleSnack={handleSnack}
          rowData={partWarehouseRow}
        />
      )} */}
      {openPartDtlsModal && (
        <PartsWarehouseAddUpdateModal
          show={openPartDtlsModal}
          hideModal={() => setOpenPartDtlsModal(false)}
          recordId={partRecordId}
          partsRow={partsExpendedRow}
          partsRecords={partsRecords}
          setPartsRecords={setPartsRecords}
          partStockWareHouses={partStockWareHouses}
          setPartStockWareHouses={setPartStockWareHouses}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default InventoryParts;
