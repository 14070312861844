import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";

import { Modal } from "react-bootstrap";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";

import LoadingProgress from "pages/Repair/components/Loader";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { serviceMasterRequestObj } from "../masterConstents";
import { EXTERNAL_WORK_MASTER_URL } from "services/CONSTANTS";

const ServiceMasterAddUpdateModal = ({ show, hideModal, recordId, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...serviceMasterRequestObj });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setViewModeOn(true);
      setLoading(true);
      const rUrl = EXTERNAL_WORK_MASTER_URL;
      callGetApi(
        `${rUrl}/${recordId}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...responseData, unit: "Time", estimatedHours: "15 Hours" });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // submit data
  const handleSubmit = () => {
    const rUrl = EXTERNAL_WORK_MASTER_URL;
    const rObj = { ...recordData };

    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Service updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData.id });
            handleSnack("success", "Service Created Successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    }
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5 className="d-flex align-items-center mb-0">
          <div className="" style={{ display: "contents" }}>
            <span className="mr-3">Service</span>
            <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
              <EditIcon />
            </a>
            <a className="btn-sm cursor">
              <BookmarkBorderIcon />
            </a>
            <a className="btn-sm cursor">
              <CreateNewFolderIcon />
            </a>
          </div>
          <div className="input-group icons border-radius-10 border">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                <img src={shearchIcon} />
              </span>
            </div>
            <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
          </div>
        </h5>
        <hr className="mb-0" />
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ACTIVITY ID</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="activityId"
                            placeholder="Activity Id"
                            value={recordData?.activityId}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ACTIVITY NAME</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="activityDescription"
                            placeholder="Activity Name"
                            value={recordData?.activityDescription}
                            onChange={handleInputTextChange}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ACTIVITY DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="longDescription"
                            placeholder="Activity Description"
                            value={recordData?.longDescription}
                            onChange={handleInputTextChange}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SUPPLYING VENDOR</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="supplyingVendorName"
                            placeholder="Supplying Vendor"
                            value={recordData?.supplyingVendorName}
                            onChange={handleInputTextChange}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">UNIT OF MEASURE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="unit"
                            placeholder="Unit of Measure"
                            value={recordData?.unit}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ESTIMATED HOURS/DAYS</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="estimatedHours"
                            placeholder="Estimated Housr/Day"
                            value={recordData?.estimatedHours}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ACTIVITY DIMENSIONS</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="dimension1"
                            placeholder="Activity Dimensions"
                            value={recordData?.dimension1}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            value={recordData?.comments}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ACTIVITY ID" value={recordData?.activityId} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ACTIVITY NAME" value={recordData?.activityDescription} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ACTIVITY DESCRIPTION" value={recordData?.longDescription} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SUPPLYING VENDOR" value={recordData?.supplyingVendorName} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="UNIT OF MEASURE" value={recordData?.unit} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ESTIMATED HOURS/DAYS" value={recordData?.estimatedHours} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ACTIVITY DIMENSIONS" value={recordData?.dimension1} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-12 col-sm-12" label="COMMENTS" value={recordData?.comments} />
                    </div>
                  </>
                )}
                {!viewModeOn ? (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Cancel
                    </button>
                    <button
                      className="btn bg-primary text-white"
                      onClick={handleSubmit}
                      disabled={!recordData?.activityDescription || !recordData?.longDescription || !recordData?.supplyingVendorName}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Service Version</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className="">
                        {/* <MuiMenuComponent options={activityOptions} /> */}
                      </a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>ACTIVITY NAME</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Welding
                  </p>
                  <p className="text-black mb-2">CHROMING</p>
                  <div className="mt-3">
                    <small>SUPPLYING VENDOR</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    TEST AGENCY -6
                  </p>
                  <p className="text-black mb-2">TEST AGENCY -1</p>
                  <div className="mt-3">
                    <small>ACTIVITY DIMENSIONS</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    2 X 4 X 6 CHROMES
                  </p>
                  <p className="text-black mb-2">4 X 5 X 6 CHROMES</p>
                  {/* <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ServiceMasterAddUpdateModal;
