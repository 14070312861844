import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const AuthorizationAccptedRejectModal = ({
  show,
  hideModal,
  handleSnack,
  rowData,
  status,
  authorizationStatusRecords,
  setAuthorizationStatusRecords,
  setAuthorizationStatus,
}) => {
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (message.trim() === "") {
      handleSnack("error", "message must not be empty");
      return;
    }
    const updatedRecords = authorizationStatusRecords.map((item) =>
      item?.id === rowData?.id ? { ...item, authorizationStatus: status, acceptRejectMessage: message, authorizedOn: new Date() } : item
    );
    handleSnack("success", `Warranty ${status === "AUTHORIZED" ? "Authorized" : "Denied"} Successfully`);
    setAuthorizationStatus(status);
    setAuthorizationStatusRecords(updatedRecords);
    hideModal();
  };
  return (
    <>
      <Modal show={show} size="w-50" centered>
        <Modal.Header>
          <Modal.Title>{status === "AUTHORIZED" ? "Authorized" : "Denied"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row input-fields mt-2">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">MESSAGE</label>
                <textarea
                  className="form-control border-radius-10 text-primary"
                  name="message"
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message here..."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row px-2" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary" onClick={hideModal}>
              Close
            </button>
            <button className="btn bg-primary text-white ml-2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthorizationAccptedRejectModal;
