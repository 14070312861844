import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { DataGrid } from "@mui/x-data-grid";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, LinearProgress, Tooltip } from "@mui/material";

import LoadingProgress from "pages/Repair/components/Loader";

import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import ViewOrderInformation from "pages/ConstructionManagement/ConstructionOrder/ViewOrderInformation";
import ViewOrderShippingDetails from "pages/ConstructionManagement/ConstructionOrder/ViewOrderShippingDetails";
import ViewOrderBillingDetails from "pages/ConstructionManagement/ConstructionOrder/ViewOrderBillingDetails";
import { billingTypesOptions, GRID_STYLE } from "pages/Common/constants";
import { getQuoteCommonConfig } from "services";
import { callGetApi } from "services/searchQueryService";
import { API_SUCCESS } from "services/ResponseCode";
import { FETCH_PARTS_OF_PARTLIST } from "services/CONSTANTS";
import { builderSearch, fetchBuilderVersionDet, fetchPartlistFromBuilder, fetchPartsFromPartlist } from "services/repairBuilderServices";
import { INITIAL_PAGE_NO, INITIAL_PAGE_SIZE } from "pages/Repair/CONSTANTS";

const ProgressBar = ({ progress, styles }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{ height: 10, borderRadius: 5, backgroundColor: "#e0e0e0" }}
      sx={{
        "& .MuiLinearProgress-bar": { borderRadius: 5, backgroundColor: "#872ff7", ...styles },
      }}
    />
  );
};

const intialOrderData = {
  accountName: "",
  dealerStore: "",
  orderType: "",
  orderedBy: "",
  emailAddress: "",
  phoneNumber: "",
  paymentTerms: { label: "Immediate", value: "IMMEDIATE" },
  currency: { label: "USD", value: "USD" },
  billingType: { label: "Pay Fixed Price", value: "PAY_FIXED_PRICE" },
  billingFrequency: "",
  billingAddress: "",
  deliveryType: "",
  deliveryPriority: "",
  leadTime: "",
  shippingCondition: "",
  shippingPoint: "",
  pickUpInst: "",
  shippingAddress: "",
  partialShipment: false,
  machineBreakdown: false,
  needInstallationHelp: false,
  serialNumber: "",
  model: "",
};

const ViewOrderDetails = ({
  show,
  hideModal,
  handleSnack,
  recordId,
  rowData = null,
  currencyFormatter,
  openOrderInfo,
  setOpenOrderInfo,
  openBillinfDtls,
  setOpenBillinfDtls,
  openShippingDtls,
  setOpenShippingDtls,
  generateInVoice,
  partRecords = [],
  kitItem = false,
}) => {
  const [loading, seLoading] = useState(false);
  const [orderedParts, setOrderedParts] = useState(null);
  const [partItems, setPartItems] = useState([]);

  const [orderRecord, setOrderRecord] = useState(intialOrderData);
  const [labourCharge, setLabourCharge] = useState(0);

  const [orderProgressExpanded, setOrderProgressExpanded] = useState("");

  const [selectAddressType, setSelectAddressType] = useState("");
  const [quoteCurrencyOptions, setQuoteCurrencyOptions] = useState([]);
  const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([...billingTypesOptions]);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [sortDetail, setSortDetail] = useState({ sortColumn: "", orderBy: "" });
  const [filterQuery, setFilterQuery] = useState("");
  const [totalPartsCount, setTotalPartsCount] = useState(0);

  useEffect(() => {
    // quote-Currency
    getQuoteCommonConfig("currency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d !== "EMPTY") {
              options.push({ value: d, label: d });
            }
          });
        // const options = res.map((d) => ({
        //   value: d,
        //   label: d,
        // }));
        setQuoteCurrencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // Billing-Frequency
    getQuoteCommonConfig("billing-frequency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d.key !== "EMPTY") {
              options.push({ value: d.key, label: d.value });
            }
          });
        setBillingFrequencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(() => {
    if (!kitItem) {
      seLoading(true);
      getPartDetails(recordId);
    } else {
      setPartItems(partRecords);
    }
  }, [kitItem]);

  // get the part details
  const getPartDetails = () => {
    fetchBuilderVersionDet(rowData?.builderId, rowData?.versionNumber)
      .then((result) => {
        fetchPartlist(result.id);
      })
      .catch((err) => {
        seLoading(false);
      });
  };

  const fetchPartlist = (id) => {
    fetchPartlistFromBuilder(id)
      .then((partListResult) => {
        if (partListResult) {
          //   setPartListNo(partListResult[0]);
          fetchPartsOfPartlist(partListResult[0], INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
        }
      })
      .catch((err) => {
        seLoading(false);
        handleSnack("error", "Error occurred while fetching all parts of partlist");
      });
  };

  const fetchPartsOfPartlist = async (partlistId, pageNo, rowsPerPage) => {
    setPage(pageNo);
    setPageSize(rowsPerPage);
    let sort = sortDetail.sortColumn ? `&sortColumn=${sortDetail.sortColumn}&orderBY=${sortDetail.orderBy}` : "&sortColumn=createdAt&orderBY=ASC";
    let filter = filterQuery ? `&search=${filterQuery}` : "";
    const query = `pageNumber=${pageNo}&pageSize=${rowsPerPage}${sort}${filter}`;
    await fetchPartsFromPartlist(partlistId, query)
      .then((partsResult) => {
        setTotalPartsCount(partsResult.totalRows);
        setPartItems(partsResult.result);
        seLoading(false);
      })
      .catch((err) => {
        seLoading(false);
        handleSnack("error", "Error occured while fetching parts");
      });
  };

  // order progress expend
  const handleExpendOrderProgress = (panel) => (event, isExpanded) => {
    setOrderProgressExpanded(isExpanded ? panel : false);
  };

  // order progress expend on Track order click
  const handleTrackOrder = (panel) => {
    if (!orderProgressExpanded) {
      setOrderProgressExpanded(panel);
    } else {
      setOrderProgressExpanded("");
    }
  };

  const orderColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "description", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "listPrice", headerName: "Price", flex: 1, renderCell: ({ row }) => currencyFormatter.format(row.unitPrice) },
    { field: "tax", headerName: "Tax", flex: 1, renderCell: ({ row }) => currencyFormatter.format((row.unitPrice * 10) / 100) },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format((row.unitPrice + (row.unitPrice * 10) / 100) * row?.quantity)}</div>
      ),
    },
  ];

  return (
    <>
      {show && (
        <Modal show={show} onHide={hideModal} size="xl">
          <Modal.Body style={{ backgroundColor: "#f6f6f6" }}>
            {loading ? (
              <LoadingProgress />
            ) : (
              <>
                <div className="d-flex justify-content-between my-2 mt-2 ">
                  <div>
                    <h3 className="text-light">#{rowData?.estimationNumber || "NA"}</h3>
                    <h5 className="text-muted">
                      Order History / Order Details / {orderedParts && orderedParts?.cartNumber} -{" "}
                      <Moment format="DD-MM-YYYY hh:mm A">{rowData?.createdAt}</Moment>
                    </h5>
                  </div>
                  <div>
                    {/* <button className="btn btn-danger mr-2">
                      <DeleteIcon /> Delete Order
                    </button> */}
                    <button className="btn btn-info mr-2 " onClick={() => handleTrackOrder("progressExpended")}>
                      <TrackChangesIcon /> Track Order
                    </button>
                    <button className="btn bg-primary text-white mr-2" onClick={generateInVoice}>
                      {/* <DriveFileRenameOutlineIcon />  */}
                      <ReceiptIcon /> Generate Invoice
                    </button>
                  </div>
                </div>
                <div className="card border px-3 py-2">
                  <h4 className="mb-1">Progress</h4>
                  <h6 className="text-muted">Current Order Status</h6>
                  <div className="d-flex">
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <AllInboxIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Order Confirming</h5>
                      <ProgressBar progress={100} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <PaymentIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Payment Pending</h5>
                      <ProgressBar progress={100} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <AccountTreeIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Processing</h5>
                      <ProgressBar progress={50} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <LocalShippingIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Shipping</h5>
                      <ProgressBar progress={0} />
                    </div>
                    <div className="card border p-3 m-2" style={{ width: "20%" }}>
                      <div>
                        <CheckCircleRoundedIcon />
                      </div>
                      <h5 className="mt-2 mb-3">Delivered</h5>
                      <ProgressBar progress={0} />
                    </div>
                  </div>
                </div>
                <Divider sx={{ my: 0 }} />
                <Accordion
                  sx={{ borderRadius: 1, marginY: 3, backgroundColor: "#00000000" }}
                  expanded={orderProgressExpanded === "progressExpended"}
                  onChange={handleExpendOrderProgress("progressExpended")}
                >
                  <AccordionSummary sx={{ border: "none" }} expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px" }} />}>
                    <h4 className="mb-1">View Progress</h4>
                  </AccordionSummary>
                  <AccordionDetails sx={{ border: "none" }}>
                    <Grid container spacing={2} sx={{ minHeight: "200px", maxHeight: "200px" }}>
                      <Grid item xs={6} sx={{ marginBottom: 6 }}>
                        <div className="card border px-3 py-2 mb-0" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <h5 className="text-dark">Be patient, package on deliver!</h5>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center text-dark border border-radius-10 bg-white px-2 py-2">
                              <LocalShippingOutlinedIcon sx={{ fontSize: "32px" }} />
                              <span className="ml-1 font-size-13">Malang, Indonesia</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center gap-1">
                              <span className="d-flex align-items-center">
                                <span className="bg-dark rounded-circle" style={{ width: "5px", height: "5px" }}></span>
                                <span style={{ width: "6px" }}>-</span>
                              </span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>-</span>
                              <span style={{ width: "6px" }}>{">"}</span>
                            </div>
                            <div className="d-flex align-items-center text-dark border border-radius-10 bg-white px-2 py-2">
                              <LocationOnOutlinedIcon sx={{ fontSize: "32px" }} />
                              <span className="ml-1 font-size-13">Emir's House, Indonesia</span>
                            </div>
                          </div>
                          <div className="mt-3">
                            <ProgressBar progress={80} styles={{ backgroundColor: "#F85A10" }} />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={3} sx={{ marginBottom: 0 }}>
                        <di mb-0v className="card border px-3 py-2" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <div className="position-absolute" style={{ bottom: 0 }}>
                            <span>Estimated Arrival</span>
                            <h5>
                              <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                            </h5>
                          </div>
                        </di>
                      </Grid>
                      <Grid item xs={3} sx={{ marginBottom: 0 }}>
                        <div className="card border px-3 py-2 mb-0" style={{ backgroundColor: "#F5F5F5", minHeight: "160px", maxHeight: "160px" }}>
                          <div
                            className="d-flex justify-content-center align-items-center bg-white rounded-circle"
                            style={{ height: "40px", width: "40px" }}
                          >
                            <TimerOutlinedIcon sx={{ fontSize: "40px" }} />
                          </div>
                          <div className="position-absolute" style={{ bottom: 0 }}>
                            <span>Delivered in</span>
                            <h5>5 Days</h5>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="card border px-3 py-3">
                          <span className="font-size-13">Timeline</span>
                          <div className="row mt-2">
                            <div className="col-md-3 col-sm-3">
                              <h5 className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </h5>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <h5 className="mb-0">Your package is packed by the courier</h5>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <h5>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </h5>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-3 col-sm-3">
                              <span className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span className="mb-0">Shipment has been created</span>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <span>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-3 col-sm-3">
                              <span className="mb-0">
                                <Moment format="DD MMM YYYY">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span className="mb-0">Order Placed</span>
                            </div>
                            <div className="col-md-3 col-sm-3">
                              <span>
                                <Moment format="hh:mm A">{rowData?.createdAt}</Moment>
                              </span>
                            </div>
                            <div className="col-md-9 col-sm-9">
                              <span>Malang, Indonesia</span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="card border px-3 py-3">
                          <span className="font-size-13">Shipment</span>
                          <div className="d-flex align-items-center mt-2">
                            <div
                              className="d-flex justify-content-center align-items-center bg-white border rounded-circle"
                              style={{ height: "40px", width: "40px" }}
                            >
                              <LocalShippingOutlinedIcon sx={{ fontSize: "40px" }} />
                            </div>
                            <div className="ml-3">
                              <h5 className="mb-0">Doordash Indonesia</h5>
                              <span>Surabaya, Lorkidul, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-6 col-sm-6">
                              <p className="mb-0 font-weight-400">Recipient</p>
                              <span className="text-dark font-weight-500">Emir</span>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <p className="mb-0 font-weight-400">Delivery Address</p>
                              <span className="text-dark font-weight-500">Malang, East Java, Indonesia</span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-4 col-sm-4">
                              <p className="mb-0 font-weight-400">Tracking No.</p>
                              <span className="d-flex justify-content-between border border-radius-10 px-2 py-1 mt-1">
                                <span className="text-black font-weight-500">98765409</span>
                                <span className="cursor text-dark font-weight-500">
                                  <ContentCopyOutlinedIcon />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <div className="card border px-3 py-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="mb-0">Customer</h4>
                      <h6 className="text-muted">Information Details</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-4">
                      <div className="card border" style={{ overflow: "hidden" }}>
                        <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                          <h6 className="bg-green text-white mb-0">Order Info </h6>
                          <Tooltip title="View Order info">
                            <ControlPointIcon className="cursor" onClick={() => setOpenOrderInfo(true)} />
                          </Tooltip>
                        </div>
                        <div className={"p-2"}>
                          <div className="row py-2">
                            <div className="col-md-3 col-sm-3">
                              <p className="mb-0">Account</p>
                            </div>
                            <div className="col-md-9 col-sm-9 text-right px-3">
                              <h6 className="mb-0 ">
                                <b>{orderRecord?.accountName || "-"}</b>
                              </h6>
                            </div>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Store</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.dealerStore?.label || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Order Type</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.orderType?.label || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Order By</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.orderedBy || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Email</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.emailAddress || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Contact</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.phoneNumber || "-"}</b>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="card border" style={{ overflow: "hidden", height: "17.4rem" }}>
                        <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                          <h6 className="bg-green text-white mb-0">Shipping Details</h6>
                          <Tooltip title="View Shipping Details">
                            <AddLocationAltIcon className="cursor" onClick={() => setOpenShippingDtls(true)} />
                          </Tooltip>
                        </div>
                        <div className={"p-2"}>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Delivery Type</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.deliveryType?.label || "Standard"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Delivery Priority</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.deliveryPriority?.label || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Lead Time</p>
                            <h6 className="mb-0">
                              <b>{"$" + (orderRecord?.leadTime || 0) + " Days" || "-"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-block py-2">
                            <p className="mb-0">Address</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.shippingAddress || orderRecord?.pickUpInst || "-"}</b>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="card border" style={{ overflow: "hidden", height: "17.4rem" }}>
                        <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                          <h6 className="bg-green text-white mb-0">Billing Details</h6>
                          <Tooltip title="View Billing Details">
                            <ControlPointIcon className="cursor" onClick={() => setOpenBillinfDtls(true)} />
                          </Tooltip>
                        </div>
                        <div className={"p-2"}>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Payment Terms</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.paymentTerms?.label || "Immediate"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Currency</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.currency?.label || "USD"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Billing Type</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.billingType?.label || "Pay Fixed Price"}</b>
                            </h6>
                          </div>
                          <div className="hr my-0"></div>
                          <div className="d-flex justify-content-between py-2">
                            <p className="mb-0">Billing Frequency</p>
                            <h6 className="mb-0">
                              <b>{orderRecord?.billingFrequency?.label || "One Time"}</b>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-sm-8">
                    <div className="card border p-2">
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div>
                          <h4 className="mb-1">Product</h4>
                          <h6 className="text-muted">Your Shipment</h6>
                        </div>
                        <div>
                          <DescriptionIcon /> Download CSV
                        </div>
                      </div>
                      <Divider className="mb-2" />
                      <div className="order-summary-items">
                        <DataGrid
                          //   rows={(orderedParts && orderedParts["partsQty"]) || []}
                          rows={partItems}
                          columns={orderColumns}
                          disableSelectionOnClick
                          sx={GRID_STYLE}
                          hideFooter={true}
                          autoHeight
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="card border p-2 mb-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h4 className="mb-0">Payment</h4>
                          <h6 className="text-muted">Final Payment Amount</h6>
                        </div>
                        <div>
                          <SimCardDownloadIcon /> Download Invoice
                        </div>
                      </div>
                      <div className="hr my-0"></div>
                      <div className="py-2">
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Sub Total</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                (partItems.length > 0 &&
                                  partItems.reduce((sum, partItem) => {
                                    return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Tax (10%)</p>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                (((partItems.length > 0 &&
                                  partItems.reduce((sum, partItem) => {
                                    return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) *
                                  10) /
                                  100
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <div className="d-flex align-items-center mb-0">
                            <p className="mb-0">Discount</p>
                            <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                          </div>
                          <h6 className="mb-0">
                            <b>
                              -{" "}
                              {currencyFormatter.format(
                                (((partItems.length > 0 &&
                                  partItems.reduce((sum, partItem) => {
                                    return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) *
                                  2) /
                                  100
                              )}
                            </b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <p className="mb-0">Shipping Cost</p>
                          <h6 className="mb-0">
                            <b>{currencyFormatter.format(500)}</b>
                          </h6>
                        </div>
                        <div className="hr my-0"></div>
                        <div className="d-flex justify-content-between py-2">
                          <h6 className="mb-0">
                            <b>Total</b>
                          </h6>
                          <h6 className="mb-0">
                            <b>
                              {currencyFormatter.format(
                                ((partItems.length > 0 &&
                                  partItems.reduce((sum, partItem) => {
                                    return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                  }, 0)) ||
                                  0) +
                                  (((partItems.length > 0 &&
                                    partItems.reduce((sum, partItem) => {
                                      return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                    }, 0)) ||
                                    0) *
                                    10) /
                                    100 -
                                  (((partItems.length > 0 &&
                                    partItems.reduce((sum, partItem) => {
                                      return sum + (partItem.unitPrice + (partItem.unitPrice * 10) / 100) * partItem?.quantity;
                                    }, 0)) ||
                                    0) *
                                    2) /
                                    100 +
                                  500
                              )}
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      <button className="btn bg-primary text-white w-100" onClick={hideModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
      {/* order information details modal */}
      {openOrderInfo && (
        <ViewOrderInformation
          show={openOrderInfo}
          hideModal={() => setOpenOrderInfo(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
        />
      )}
      {/* order shipping details modal */}
      {openShippingDtls && (
        <ViewOrderShippingDetails
          show={openShippingDtls}
          hideModal={() => setOpenShippingDtls(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          setLabourCharge={setLabourCharge}
        />
      )}

      {/* order billing details modal */}
      {openBillinfDtls && (
        <ViewOrderBillingDetails
          show={openBillinfDtls}
          hideModal={() => setOpenBillinfDtls(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          selectAddressType={selectAddressType}
          setSelectAddressType={setSelectAddressType}
          currencyOptions={quoteCurrencyOptions}
          billingTypeOptions={billingTypeOptions}
          billingFrequencyOptions={billingFrequencyOptions}
        />
      )}
    </>
  );
};

export default ViewOrderDetails;
