import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { Divider, Grid, Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Label } from "recharts";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

const ClaimServiceReportModal = ({ show, hideModal, onSubmit, viewMode, reportData }) => {
  const [formData, setFormData] = useState(reportData);

  // Handle the submit action and send the value to the parent
  const handleSubmit = () => {
    onSubmit(formData);
    hideModal();
  };

  const customerNumberOptions = [
    { label: "234567", value: "234567" },
    { label: "567891", value: "567891" },
    { label: "432167", value: "432167" },
  ];

  const condtMachineOptions = [
    { label: "Under Breakdown", value: "Under_Breakdown" },
    { label: "Under Repair", value: "Under_Repair" },
    { label: "Under Maintenance", value: "Under_Maintenance" },
  ];

  const machineModelOptions = [
    { label: "KSM403", value: "KSM403" },
    { label: "SER678", value: "SER678" },
    { label: "ATM234", value: "ATM234" },
  ];

  return (
    <Modal show={show} onHide={hideModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="card p-3 ">
          <h4>{viewMode ? "View Service Report" : "Add/Edit Service Report"}</h4>
          <Divider sx={{ mb: 2 }} />{" "}
          {!viewMode ? (
            <>
              <div className="row input-fields mt-3 ">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REPORT NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="reportnumber"
                      value={formData.reportnumber || ""}
                      // placeholder="E.G.RKJ11032"
                      onChange={(e) => setFormData((prev) => ({ ...prev, reportnumber: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REPORT DESCRIPTION</label>
                    <input type="text" className="form-control border-radius-10 text-primary" name="reportdescription" placeholder="Breakdown" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2">
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NUMBER</label>
                    <Select className="text-primary" options={customerNumberOptions} value={customerNumberOptions[0]} styles={FONT_STYLE_SELECT} />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RO NUMBER</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="RO1234" name="ronumber" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REPAIR DATE</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="10.01.2022" name="repairdate" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SMU(METER UNIT)</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="4250 Hours" name="smu" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2">
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MACHINE MODEL</label>
                    <Select className="text-primary" options={machineModelOptions} value={machineModelOptions[0]} styles={FONT_STYLE_SELECT} />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">MACHINE SERAIL NUMBER</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="U654" name="machineslno" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">COMPONENT MODEL</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="KTA38C" name="componentno" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">COMPONENT SERIAL NUMBER</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="25461036" name="composelno" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-3 ">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CONDITION OF MACHINE</label>
                    <Select className="text-primary" options={condtMachineOptions} value={condtMachineOptions[0]} styles={FONT_STYLE_SELECT} />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2 ">
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER COMPLAINT</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Noise coming out" name="customercmplt" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2 ">
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">RESOLUTION</label>
                    <textarea
                      name="owneradd"
                      cols="20"
                      rows="5"
                      placeholder=" We have unlocked your account,you may try again"
                      className="form-control border-radius-10 text-primary"
                    />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2 ">
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER REMARK</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Noise coming out" name="customerremark" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2 ">
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">ENGINEER REMARK</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Noise coming out" name="engremark" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2 ">
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ADDRESS</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="amohanty" name="custaddr" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">EMAIL(if not same in profile)</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Johndoe@gmail.com" name="email" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CONTACT(if not same in profile)</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Contacts" />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-3 ">
                <div className="col-md-12 col-sm-12">
                  <div className="dashed-container">
                    <button className="add-attachments-btn">
                      <span>+</span> Add Attachments{" "}
                      <span>
                        <KeyboardArrowDownIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-4 ">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">ENGINEER SIGNATURE</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="amohanty" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SIGNATURE CUSTOMER CONTACT</label>
                    <input type="text" className="form-control border-radius-10 text-primary" placeholder="Johndoe@gmail.com" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row input-fields mt-3 ">
                <ReadOnlyField className="col-md-6 col-sm-6" label="REPORT NUMBER" value={formData?.reportnumber || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="REPORT DESCRIPTION" value={formData?.reportdescription || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="RO NUMBER" value={formData?.ronumber || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="REPAIR DATE" value={formData?.repairdate || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="SMU(METER UNIT)" value={formData?.smu || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="MACHINE SERAIL NUMBER" value={formData?.machineslno || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="COMPONENT MODEL" value={formData?.componentno || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="COMPONENT SERIAL NUMBER" value={formData?.composelno || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="CUSTOMER COMPLAINT" value={formData?.customercmplt || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="CUSTOMER REMARK" value={formData?.customerremark || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="ENGINEER REMARK" value={formData?.engremark || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="CUSTOMER ADDRESS" value={formData?.custaddr || "N/A"} />
                <ReadOnlyField className="col-md-6 col-sm-6" label="EMAIL(if not same in profile)" value={formData?.email || "N/A"} />
              </div>
            </>
          )}
          <div className="row px-4  my-3" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
              Cancel
            </button>
            {!viewMode ? (
              <button className="btn bg-primary text-white" onClick={handleSubmit}>
                Save
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimServiceReportModal;
