import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { TextField, Tooltip } from "@mui/material";
import { FONT_STYLE } from "pages/Common/constants";
import {
  EQUIPMENT_CONTRACT_DETAILS,
  EQUIPMENT_FAILURE_REPORT_DETAILS,
  EQUIPMENT_USAGE_SMU_REPORT_DETAILS,
  EQUIPMNT_USAGE_REPORT_DETAILS,
} from "../equipmentMasterConstants";

const EquipmentAddUpdateModal = ({ show, hideModal, rowData = {}, setData, type }) => {
  const [recordData, setRecordData] = useState({ ...rowData });
  const [viewModeOn, setViewModeOn] = useState(true);
  const [loading, setLoading] = useState(false);

  // input value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // contract details
  const ContractDtls = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ENTITLEMENT #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="entitlementId"
                  placeholder="Entitlement Id"
                  value={recordData?.entitlementId}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ENTITLEMENT TITLE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="title"
                  placeholder="Entitlement Title"
                  value={recordData?.title}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CATEGORY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="category"
                  placeholder="Category"
                  value={recordData?.category}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">BASIS</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="basis"
                  placeholder="Basis"
                  value={recordData?.basis}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">AMOUNT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="amount"
                  placeholder="Amount"
                  value={recordData?.amount}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="currency"
                  placeholder="Currency"
                  value={recordData?.currency}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">VALID FOR</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="validFor"
                  placeholder="Valid For"
                  value={recordData?.validFor}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="unitOfMeasure"
                  placeholder="Unit"
                  value={recordData?.unitOfMeasure}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">START DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData.startDate}
                      onChange={(e) => setRecordData({ ...recordData, startDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">END DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData.endDate}
                      onChange={(e) => setRecordData({ ...recordData, endDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">START USAGE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="startUsage"
                  placeholder="Start Usage"
                  value={recordData?.startUsage}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">END USAGE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="endUsage"
                  placeholder="End Usage"
                  value={recordData?.endUsage}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="ENTITLEMENT #" value={recordData?.entitlementId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="ENTITLEMENT TITLE" value={recordData?.title} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="CATEGORY" value={recordData?.category} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="BASIS" value={recordData?.basis} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="AMOUNT" value={recordData?.amount} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="CURRENCY" value={recordData?.currency} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="VALID FOR" value={recordData?.validFor} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="UNIT" value={recordData?.unitOfMeasure} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="START DATE" value={recordData?.startDate} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="END DATE" value={recordData?.endDate} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="START USAGE" value={recordData?.startUsage} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="END USAGE" value={recordData?.endUsage} />
          </div>
        )}
      </>
    );
  };

  // Usage Details
  const usageDetails = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CURRENT USAGE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="currentUsage"
                  placeholder="Current Usage"
                  value={recordData?.currentUsage}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">AVERAGE USAGE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="averageUsage"
                  placeholder="Average Usage"
                  value={recordData?.averageUsage}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">LAST UPDATED DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData.updatedAt}
                      onChange={(e) => setRecordData({ ...recordData, updatedAt: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SENSOR ID</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="sensorId"
                  placeholder="Sendor Id"
                  value={recordData?.sensorId}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SMU ID</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="smuId"
                  placeholder="SMU Id"
                  value={recordData?.smuId}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SMU TYPE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="smuType"
                  placeholder="SMU Type"
                  value={recordData?.smuType}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="CURRENT USAGE" value={recordData?.currentUsage} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="AVERAGE USAGE" value={recordData?.averageUsage} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="LAST UPDATED DATE" value={recordData?.updatedAt} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SENSOR ID" value={recordData?.sensorId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SMU ID" value={recordData?.smuId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SMU TYPE" value={recordData?.smuType} />
          </div>
        )}
      </>
    );
  };

  // SMU Item Usage Details
  const smuItemDetails = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SMU ID/SENSOR ID</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="smuID"
                  placeholder="SMU Id/Sensor Id"
                  value={recordData?.smuId}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SMU TYPE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="smuType"
                  placeholder="SMU Type"
                  value={recordData?.smuType}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">USAGE ID</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="usageId"
                  placeholder="Usage Id"
                  value={recordData?.usageId}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">READING DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData?.readingDate}
                      onChange={(e) => setRecordData({ ...recordData, readingDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="unitOfMeasure"
                  placeholder="Unit"
                  value={recordData?.unitOfMeasure}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">READING DESCRIPTION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="readingDescription"
                  placeholder="Reading Description"
                  value={recordData?.readingDescription}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">OVERWRITE/ERROR</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="overWritenError"
                  placeholder="Overwrite/Error"
                  value={recordData?.overWritenError}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="SMU ID/SENSOR ID" value={recordData?.smuId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SMU TYPE" value={recordData?.smuType} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="USAGE ID" value={recordData?.usageId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="READING DATE" value={recordData?.readingDate} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="UNIT" value={recordData?.unitOfMeasure} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="READING DESCRIPTION" value={recordData?.readingDescription} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="OVERWRITE/ERROR" value={recordData?.overWritenError} />
          </div>
        )}
      </>
    );
  };

  // failure Report
  const failureReport = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PART #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="partNumber"
                  placeholder="Part #"
                  value={recordData?.partNumber}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  placeholder="Quantity"
                  value={recordData?.quantity}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SUB SEEMBLY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="subAssembly"
                  placeholder="Sub-Assembly"
                  value={recordData?.subAssembly}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">WARRANTY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="warranty"
                  placeholder="Warranty"
                  value={recordData?.warranty}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData?.failureDate}
                      onChange={(e) => setRecordData({ ...recordData, failureDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REPAIR DATE</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData?.repairDate}
                      onChange={(e) => setRecordData({ ...recordData, repairDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">HOURS ON PART</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="hoursOnPart"
                  placeholder="Hours on part"
                  value={recordData?.hoursOnPart}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">COMPLAINT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="complaint"
                  placeholder="Complaint"
                  value={recordData?.complaint}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CAUSE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="cause"
                  placeholder="Cause"
                  value={recordData?.cause}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CORRECTION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="correction"
                  placeholder="Correction"
                  value={recordData?.correction}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SPECIFIC INFORMATION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="specificInfo"
                  placeholder="Specific Information"
                  value={recordData?.specificInfo}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="PART #" value={recordData?.partNumber} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="QUANTITY" value={recordData?.quantity} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SUB ASSEMBLY" value={recordData?.subAssembly} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="WARRANTY" value={recordData?.warranty} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="FAILURE DATE" value={recordData?.failureDate} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="REPAIR DATE" value={recordData?.repairDate} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="HOURS ON PART" value={recordData?.hoursOnPart} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="COMPLAINT" value={recordData?.complaint} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="CAUSE" value={recordData?.cause} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="CORRECTION" value={recordData?.correction} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SPECIFIC INFORMATION" value={recordData?.specificInfo} />
          </div>
        )}
      </>
    );
  };

  // submit the data
  const handleSubmit = () => {
    setData((pre) => pre.map((item) => (item?.id === rowData?.id ? recordData : item)));
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="md" centered>
        <div className="d-flex justify-content-between align-items-center p-2">
          <h4>
            {type === EQUIPMENT_CONTRACT_DETAILS
              ? "Contract Details"
              : type === EQUIPMNT_USAGE_REPORT_DETAILS
              ? "Usage Report"
              : type === EQUIPMENT_USAGE_SMU_REPORT_DETAILS
              ? "Usage Report"
              : type === EQUIPMENT_FAILURE_REPORT_DETAILS
              ? "Failure Report"
              : ""}
          </h4>
          {recordData?.id && (
            <Tooltip title="Edit">
              <span className="upload-icon-button cursor" onClick={() => setViewModeOn(false)}>
                <EditOutlinedIcon sx={{ fontSize: 40 }} />
              </span>
            </Tooltip>
          )}
        </div>
        <div className="px-2 mb-3">
          <div className="card border px-3 mb-3">
            {type === EQUIPMENT_CONTRACT_DETAILS
              ? ContractDtls()
              : type === EQUIPMNT_USAGE_REPORT_DETAILS
              ? usageDetails()
              : type === EQUIPMENT_USAGE_SMU_REPORT_DETAILS
              ? smuItemDetails()
              : type === EQUIPMENT_FAILURE_REPORT_DETAILS
              ? failureReport()
              : ""}
          </div>
          {!viewModeOn ? (
            <div className="row px-3" style={{ justifyContent: "right" }}>
              <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                Cancel
              </button>
              <button className="btn bg-primary text-white" onClick={handleSubmit}>
                Save
              </button>
            </div>
          ) : (
            <div className="row px-3" style={{ justifyContent: "right" }}>
              <button className="btn bg-primary text-white" onClick={hideModal}>
                Close
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EquipmentAddUpdateModal;
