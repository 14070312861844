import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import EquipmentSearchMaster from "pages/MasterData/EquipmentSearchMaster";
import { SEARCH_FLAG_VEHICLE } from "pages/MasterData/equipmentMasterConstants";
import VehicleAddModal from "./VehicleAddModal";
import VehicleSearchLists from "./VehicleSearchLists";
import Moment from "react-moment";
import { isEmpty } from "pages/Common/textUtilities";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { VEHICLES_MASTER_URL } from "services/CONSTANTS";
import LoadingProgress from "pages/Repair/components/Loader";

const VehicleMaster = () => {
  const [vehicleSerachList, setVehicleSerachList] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [vehicleRecord, setVehicleRecord] = useState(null);

  const [openVehicleAddModal, setOpenVehicleAddModal] = useState(false);
  const [vehiclerecordId, setVehiclerecordId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recentBuildersLoading, setRecentBuildersLoading] = useState(true);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    setSelectedVehicleId(null);
  }, [vehicleSerachList]);

  // open Employee Add Modal
  const handleOpenVehicleAddModal = () => {
    setOpenVehicleAddModal(true);
    setVehiclerecordId(null);
  };

  // view selected vehicle details
  const handleViewDetails = (id, row) => {
    setSelectedVehicleId(id);

    // get the vehicle details
    getVehicleRecords(id);
  };

  // get the vehicle details
  const getVehicleRecords = (id) => {
    if (id) {
      setLoading(true);
      callGetApi(
        `${VEHICLES_MASTER_URL}/${id}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setVehicleRecord(responseData);
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  // open Vehicle edit modal
  const handleOpenVehicleModal = (row) => {
    setVehiclerecordId(row?.id);
    setOpenVehicleAddModal(true);
  };

  // employe detials Page
  const vehicleDtlsPage = () => {
    return (
      <>
        <div className="bg-white p-3 border-radius-10 overflow-hidden br-t">
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">VEHICLE NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.vehicleNumber) ? "N/A" : vehicleRecord?.vehicleNumber}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERIAL NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.serialNumber) ? "N/A" : vehicleRecord?.serialNumber}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">CHASSIS NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(vehicleRecord?.chasisno) ? "N/A" : vehicleRecord?.chasisno}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">REGISTRATION NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.registractionNo) ? "N/A" : vehicleRecord?.registractionNo}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">MAKE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(vehicleRecord?.make) ? "N/A" : vehicleRecord?.make}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">MODEL</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(vehicleRecord?.model) ? "N/A" : vehicleRecord?.model}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">OWNER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.ownerName) ? "N/A" : vehicleRecord?.ownerName}
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">OWNER ADDRESS</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {isEmpty(vehicleRecord?.ownerAddress) ? "N/A" : vehicleRecord?.ownerAddress}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICE LOCATION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.serviceLocation) ? "N/A" : vehicleRecord?.serviceLocation}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICEABLE AREAS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.servicablearea) ? "N/A" : vehicleRecord?.servicablearea}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EMPLOYEE IN CHARGE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(vehicleRecord?.empincharge) ? "N/A" : vehicleRecord?.empincharge}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">VEHICLE OPERATIONAL STATUS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(vehicleRecord?.status) ? "N/A" : vehicleRecord?.status}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">COMMENTS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(vehicleRecord?.comments) ? "N/A" : vehicleRecord?.comments}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h5 className="font-weight-600 mb-0 mt-3">Vechicle Master</h5>
          <p className="mb-1 mt-4 font-size-12">Select the search criteria for Vechicle</p>
          <EquipmentSearchMaster
            falgType="vehicle"
            searchFlag={SEARCH_FLAG_VEHICLE}
            setSearchList={setVehicleSerachList}
            showBtn={true}
            handleShowAddModal={handleOpenVehicleAddModal}
          />
          <div className="row mt-3 mb-5">
            {vehicleSerachList.length !== 0 && (
              <VehicleSearchLists searchList={vehicleSerachList} selectedVehicleId={selectedVehicleId} handleViewDetails={handleViewDetails} />
            )}
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
              {loading ? (
                <LoadingProgress />
              ) : (
                <>
                  {selectedVehicleId && (
                    <div className="">
                      <div className="bg-white p-3 border-radius-10 ">
                        <div className="d-flex align-items-center justify-content-between equipment-pagination">
                          <h5 className="font-weight-600 mb-0">{vehicleRecord?.vehicleNumber}</h5>
                          <div className="d-flex align-items-center mx-1">
                            <Tooltip title="Edit">
                              <span className="upload-icon-button cursor" onClick={() => handleOpenVehicleModal(vehicleRecord)}>
                                <EditOutlinedIcon sx={{ fontSize: 40 }} />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-block mt-3">
                            <h6 className="text-primary font-weight-600">{vehicleRecord?.vehiclePlateNumber}</h6>
                            <p className="text-light-60 font-size-12 mb-0">
                              {vehicleRecord?.ownerName} - {vehicleRecord?.ownerAddress}
                            </p>
                          </div>
                        </div>
                      </div>
                      {vehicleDtlsPage()}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {openVehicleAddModal && (
        <VehicleAddModal
          show={openVehicleAddModal}
          hideModal={() => setOpenVehicleAddModal(false)}
          recordId={vehiclerecordId}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default VehicleMaster;
