import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import shearchIcon from "../../../assets/icons/svg/search.svg";
import LoadingProgress from "pages/Repair/components/Loader";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import EditIcon from "@mui/icons-material/Edit";
import Select from "react-select";


const LocationNewAddModal = ({ hideModal, show, onSubmit }) => {
  const [newLocation, setNewLocation] = useState({
    locationName: "",
    address: "",
    locationType: "",
    serviceGroup: "",
    totalEmployees: 0,
  });

  // Handle input change
  const handleInputChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setNewLocation((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  // Handle form submission
  const handleSubmit = () => {
    onSubmit(newLocation);
    setNewLocation({ locationName: "", address: "", locationType: "", serviceGroup: "", totalEmployees: 15 });
    hideModal();
  };
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false);

  // handleclick to add employee
  const handleAddEmployeeClick = () => {
    setShowInputFields(true);
  };

  const LocationTypeOptions = [
    { label: "Service Workshop", value: "serviceworkshop" },
    { label: "Service Centre", value: "servicecenter" },
    { label: "Workshop & Service centre", value: "workshopservicecentre" },
  ];

  const ServiceGroupOptions = [
    { label: "Engineers", value: "Engineers" },
    { label: "Admin", value: "Admin" },
    { label: "Coordinators", value: "Coordinators" },
  ];
  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5 className="d-flex align-items-center mb-0">
          <div className="" style={{ display: "contents" }}>
            <span className="mr-3">LOCATION</span>
            <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
              <EditIcon />
            </a>
            <a className="btn-sm cursor">
              <BookmarkBorderIcon />
            </a>
            <a className="btn-sm cursor">
              <CreateNewFolderIcon />
            </a>
          </div>
          <div className="input-group icons border-radius-10 border">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                <img src={shearchIcon} />
              </span>
            </div>
            <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
          </div>
        </h5>
        <hr className="mb-0" />
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                <div className="row input-fields mt-2">
                  <div className="col-md-4 col-sm-4">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Location Name</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="locationName"
                        placeholder="Location Name"
                        value={newLocation?.locationName}
                        onChange={(e) =>
                          setNewLocation({
                            ...newLocation,
                            locationName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row input-fields mt-3">
                  <div className="col-md-4 col-sm-4">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Location Type</label>
                      <Select
                        className="text-primary"
                        name="locationType"
                        value={LocationTypeOptions.find((option) => option.value === newLocation.locationType)}
                        onChange={(option, actionMeta) => handleInputChange(option, actionMeta)}
                        options={LocationTypeOptions}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4 col-sm-4">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Service Group</label>
                      <Select
                        className="text-primary"
                        name="serviceGroup"
                        value={ServiceGroupOptions.find((option) => option.value === newLocation.ServiceGroupOptions)}
                        onChange={(option, actionMeta) => handleInputChange(option, actionMeta)}
                        options={ServiceGroupOptions}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="row input-fields mt-3">
                  <div className="col-md-4 col-sm-4">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Service Group</label>
                      <Select
                        className="text-primary"
                        name="serviceGroup"
                        value={ServiceGroupOptions.find((option) => option.value === newLocation.ServiceGroupOptions)}
                        onChange={(option, actionMeta) => handleInputChange(option, actionMeta)}
                        options={ServiceGroupOptions}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Total Employee</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="totalEmployees"
                        placeholder="Total Employee"
                        value={newLocation.totalEmployees}
                        disabled
                        onChange={(e) =>
                          setNewLocation({
                            ...newLocation,
                            totalEmployees: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row input-fields mt-3">
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">Address</label>
                      <textarea
                        name="address"
                        cols="20"
                        rows="1"
                        placeholder="Address"
                        className="form-control border-radius-10 text-primary"
                        value={newLocation.address}
                        onChange={(e) =>
                          setNewLocation({
                            ...newLocation,
                            address: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                {showInputFields && (
                  <div className="row input-fields mt-3">
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">Employee ID</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="employeeid"
                          placeholder="Employee Id"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">Employee Name</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="employeeName" placeholder="Employee Name" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row input-fields mt-3">
                  <div className="col-md-6 col-sm-6">
                    <span className="cursor font-size-16 font-weight-500" onClick={handleAddEmployeeClick}>
                      + ADD A EMPLOYEE TO THE TABLE
                    </span>
                  </div>
                </div>
                <div className="row px-4 mt-4" style={{ justifyContent: "right" }}>
                  <button className="btn border-primary text-primary mx-3" onClick={hideModal}>
                    Cancel
                  </button>
                  <button className="btn bg-primary text-white" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Location 1</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className=""></a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>MAKE</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Chinalco Sa,Beijing,Chaina(code 302037)
                  </p>
                  <p className="text-black mb-2">Chinalco Sa,Beijing,Chaina(code 302037)</p>
                  <div className="mt-3">
                    <small>FAMILY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Alberto Franco,Head of Purchase
                  </p>
                  <p className="text-black mb-2">Alberto Franco,Head of Purchase</p>
                  <div className="mt-3">
                    <small>RECOMMENDED FREQUENCY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    SFI234
                  </p>
                  <p className="text-black mb-2">SFI234</p>
                  <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LocationNewAddModal;
