import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Tab, TextField } from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import { ExpertiseLevelOptions, OnboardStatusOptions, LocationTypeOptions, GenderOptions } from "../masterConstents";
import shearchIcon from "../../../assets/icons/svg/search.svg";
import LoadingProgress from "pages/Repair/components/Loader";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE } from "pages/Repair/CONSTANTS";

import Select from "react-select";
import { employeeRecords } from "../equipmentConstant";
import Moment from "react-moment";

const EmployeeAddModal = ({ show, hideModal, recordId, handleSnack }) => {
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empRecordData, setEmpRecordData] = useState(null);

  useEffect(() => {
    if (recordId) {
      const _empRecordData = employeeRecords.find((item) => item?.id === recordId);
      const _gender = GenderOptions.find((row) => row?.value === _empRecordData?.gender);
      const _expertiseLevel = ExpertiseLevelOptions.find((row) => row?.value === _empRecordData?.expertiseLevel);
      const _serviceLevel = LocationTypeOptions.find((row) => row?.value === _empRecordData?.serviceLevel);
      const _onboardStatus = OnboardStatusOptions.find((row) => row?.value === _empRecordData?.onboardStatus);
      setEmpRecordData({
        ..._empRecordData,
        gender: _gender || "",
        expertiseLevel: _expertiseLevel || "",
        serviceLevel: _serviceLevel || "",
        onboardStatus: _onboardStatus || "",
      });
      setViewModeOn(true);
    }
  }, [recordId]);

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setEmpRecordData({ ...empRecordData, [name]: value });
  };

  const handleSelectChange = (keyName, e) => {
    setEmpRecordData({ ...empRecordData, [keyName]: e });
  };

  const handleSubmit = () => {
    if (recordId) {
      handleSnack("success", "Employess details updated");
    } else {
      handleSnack("success", "Employess details created");
    }
    hideModal();
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5 className="d-flex align-items-center mb-0">
          <div className="" style={{ display: "contents" }}>
            <span className="mr-3">EMPLOYEE</span>
            <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
              <EditIcon />
            </a>
            <a className="btn-sm cursor">
              <BookmarkBorderIcon />
            </a>
            <a className="btn-sm cursor">
              <CreateNewFolderIcon />
            </a>
          </div>
          <div className="input-group icons border-radius-10 border">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                <img src={shearchIcon} />
              </span>
            </div>
            <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
          </div>
        </h5>
        <hr className="mb-0" />
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EMPLOYEE ID</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="empolyeeId"
                            placeholder="Employee Id"
                            value={empRecordData?.empolyeeId}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EMPLOYEE NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="employeNumber"
                            placeholder="Employee Number"
                            value={empRecordData?.employeNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">HR NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="hrNumber"
                            placeholder="HR Number"
                            value={empRecordData?.hrNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EMPOLOYEE NAME</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="name"
                            placeholder="Employee Name"
                            value={empRecordData?.name}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">GENDER</label>
                          <Select
                            className="text-primary"
                            options={GenderOptions}
                            value={empRecordData?.gender}
                            onChange={(e) => handleSelectChange("gender", e)}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">AGE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="age"
                            placeholder="Age"
                            value={empRecordData?.age}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">DL NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="dlNumber"
                            placeholder="DL Number"
                            value={empRecordData?.dlNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">IDENTIFICATION NUMBER </label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="idNumber"
                            placeholder="Identification Number "
                            value={empRecordData?.idNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">CONTACT NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="contactNumber"
                            placeholder="Contact Number"
                            value={empRecordData?.contactNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ALTERNATIVE CONTACT NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="altContactNumber"
                            placeholder="Alternative Contact No"
                            value={empRecordData?.altContactNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-8 col-sm-8">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                          <textarea
                            name="plannedDuration"
                            cols="20"
                            rows="1"
                            placeholder="Address"
                            className="form-control border-radius-10 text-primary"
                            value={empRecordData?.plannedDuration}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="email"
                            placeholder="Email"
                            value={empRecordData?.email}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">PREFERRED SERVICE AREA</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="preferedServiceArea"
                            placeholder="Preferred Service Area"
                            value={empRecordData?.preferedServiceArea}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EXPERTISE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="expertise"
                            placeholder="Expertise"
                            value={empRecordData?.expertise}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EXPERTISE LEVEL</label>
                          <Select
                            className="text-primary"
                            value={empRecordData?.expertiseLevel}
                            onChange={(e) => handleSelectChange("expertiseLevel", e)}
                            options={ExpertiseLevelOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICE LOCATION</label>
                          <Select
                            className="text-primary"
                            value={empRecordData?.serviceLevel}
                            onChange={(e) => handleSelectChange("serviceLevel", e)}
                            options={LocationTypeOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ONBOARD STATUS</label>
                          <Select
                            className="text-primary"
                            options={OnboardStatusOptions}
                            value={empRecordData?.onboardStatus}
                            onChange={(e) => handleSelectChange("onboardStatus", e)}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">START DATE</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10 "
                                closeOnSelect
                                value={empRecordData?.startDate}
                                onChange={(e) => handleSelectChange("startDate", e)}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">END DATE</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10 "
                                closeOnSelect
                                value={empRecordData?.endDate}
                                onChange={(e) => handleSelectChange("endDate", e)}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">RETIRE DATE</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10 "
                                closeOnSelect
                                value={empRecordData?.retriveDate}
                                onChange={(e) => handleSelectChange("retriveDate", e)}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            value={empRecordData?.comments}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EMPLOYEE ID" value={empRecordData?.empolyeeId} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EMPLOYEE NUMBER" value={empRecordData?.employeNumber} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="HR NUMBER" value={empRecordData?.hrNumber} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EMPOLOYEE NAME" value={empRecordData?.name} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="GENDER" value={empRecordData?.gender?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="AGE" value={empRecordData?.age} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="DL NUMBER" value={empRecordData?.dlNumber} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="IDENTIFICATION NUMBER" value={empRecordData?.idNumber} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="CONTACT NUMBER" value={empRecordData?.contactNumber} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ALTERNATIVE CONTACT NUMBER" value={empRecordData?.altContactNumber} />
                      <ReadOnlyField className="col-md-8 col-sm-8" label="ADDRESS" value={empRecordData?.plannedDuration} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EMAIL" value={empRecordData?.email} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="PREFERRED SERVICE AREA" value={empRecordData?.preferedServiceArea} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EXPERTISE" value={empRecordData?.expertise} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="EXPERTISE LEVEL" value={empRecordData?.expertiseLevel?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERVICE LOCATION" value={empRecordData?.serviceLevel?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ONBOARD STATUS" value={empRecordData?.onboardStatus?.label} />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="START DATE"
                        value={empRecordData?.startDate ? <Moment format="DD/MM/YYYY">{empRecordData?.startDate}</Moment> : "NA"}
                      />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="END DATE"
                        value={empRecordData?.endDate ? <Moment format="DD/MM/YYYY">{empRecordData?.endDate}</Moment> : "NA"}
                      />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="RETIRE DATE"
                        value={empRecordData?.retriveDate ? <Moment format="DD/MM/YYYY">{empRecordData?.retriveDate}</Moment> : "NA"}
                      />
                    </div>
                    <div className="row mt-2">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="COMMENTS" value={empRecordData?.comments} />
                    </div>
                  </>
                )}
                <div className="row px-4" style={{ justifyContent: "right" }}>
                  {!viewModeOn ? (
                    <>
                      <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                        Cancel
                      </button>
                      <button className="btn bg-primary text-white" onClick={handleSubmit}>
                        Save
                      </button>
                    </>
                  ) : (
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Close
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Employee Version</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className=""></a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>EMPLOYEE NAME</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Rahul Sharma
                  </p>
                  <p className="text-black mb-2">Ravi Kumar</p>
                  <div className="mt-3">
                    <small>HR NUMBER</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    HR2600
                  </p>
                  <p className="text-black mb-2">HR2645</p>
                  <div className="mt-3">
                    <small>IDENTIFICATION NUMBER</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    RN0015
                  </p>
                  <p className="text-black mb-2">RN0001</p>
                  {/* <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmployeeAddModal;
