import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import LoadingProgress from "pages/Repair/components/Loader";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { consumableRequestobj } from "../masterConstents";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { DATA_SVC_CONSUMABLE_MASTER_URL } from "services/CONSTANTS";

const ConsumableAddUpdateModal = ({ show, hideModal, recordId, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...consumableRequestobj });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setViewModeOn(true);
      setLoading(true);
      const rUrl = DATA_SVC_CONSUMABLE_MASTER_URL;
      callGetApi(
        `${rUrl}/${recordId}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...responseData });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // submit the data
  const handleSubmit = () => {
    const rUrl = DATA_SVC_CONSUMABLE_MASTER_URL;
    const rObj = { ...recordData };

    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Consumable updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData.id });
            handleSnack("success", "Consumable Created Successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h5 className="d-flex align-items-center mb-0">
            <div className="" style={{ display: "contents" }}>
              <span className="mr-3">Consumable</span>
              <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
                <EditIcon />
              </a>
              <a className="btn-sm cursor">
                <BookmarkBorderIcon />
              </a>
              <a className="btn-sm cursor">
                <CreateNewFolderIcon />
              </a>
            </div>
            <div className="input-group icons border-radius-10 border">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                  <img src={shearchIcon} />
                </span>
              </div>
              <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
            </div>
          </h5>
          <hr className="mb-0" />
          {loading ? (
            <LoadingProgress />
          ) : (
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">CONSUMABLE NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="consumableId"
                            placeholder="Consumable Number"
                            value={recordData?.consumableId}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="description"
                            placeholder="Description"
                            value={recordData?.description}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">STOCK/NON STOCK</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="stockItem"
                            placeholder="Stock/Non Stock"
                            value={recordData?.stockItem}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">UNIT OF MEASURE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="unit"
                            placeholder="Unit"
                            value={recordData?.unit}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="sourceOrVendor"
                            placeholder="Supplier"
                            value={recordData?.sourceOrVendor}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">AVAILABILITY</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="availability"
                            placeholder="Availability"
                            value={recordData?.availability}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">TOTAL AVAILABLE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="avgContractedPrice"
                            placeholder="Total Available"
                            value={recordData?.avgContractedPrice}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            value={recordData?.comments}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="CONSUMABLE NUMBER" value={recordData?.consumableId} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="DESCRIPTION" value={recordData?.description} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="STOCK/NON STOCK" value={recordData?.stockItem} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="UNIT OF MEASURE" value={recordData?.unit} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SUPPLIER" value={recordData?.sourceOrVendor} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABILITY" value={recordData?.availability} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="TOTAL AVAILABLE" value={recordData?.avgContractedPrice} />
                    </div>
                  </>
                )}

                {!viewModeOn ? (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Cancel
                    </button>
                    <button className="btn bg-primary text-white" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Labor 1</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className="">
                        {/* <MuiMenuComponent options={activityOptions} /> */}
                      </a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>MAKE</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Chinalco Sa,Beijing,Chaina(code 302037)
                  </p>
                  <p className="text-black mb-2">Chinalco Sa,Beijing,Chaina(code 302037)</p>
                  <div className="mt-3">
                    <small>FAMILY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Alberto Franco,Head of Purchase
                  </p>
                  <p className="text-black mb-2">Alberto Franco,Head of Purchase</p>
                  <div className="mt-3">
                    <small>RECOMMENDED FREQUENCY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    SFI234
                  </p>
                  <p className="text-black mb-2">SFI234</p>
                  <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConsumableAddUpdateModal;
