import React, { useCallback, useState } from "react";

import penIcon from "../../../assets/images/pen.png";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Button, Grid, TextField, Tooltip } from "@mui/material";
import { FONT_STYLE, GENERAL_TRANSPRINT_GRID_STYLE, GRID_STYLE } from "pages/Common/constants";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Moment from "react-moment";
import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const estimatedByUsers = [
  { name: "John", email: "john@gmail.com" },
  { name: "David", email: "david@gmail.com" },
  { name: "Michael", email: "michael@gmail.com" },
  { name: "Emma", email: "emma@gmail.com" },
  { name: "Robert", email: "robert@gmail.com" },
  { name: "Sarah", email: "sarah@gmail.com" },
  { name: "Daniel", email: "daniel@gmail.com" },
];

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows } = props;

  const handleClick = () => {
    const payerId = "new";
    setRows((oldRows) => [...oldRows, { payerId, payerName: "", billingSplit: "", price: 0, isNew: true }]);
    setRowModesModel((oldModel) => ({ ...oldModel, [payerId]: { mode: GridRowModes.Edit, fieldToFocus: "payerName" } }));
  };

  return (
    <GridToolbarContainer className="row">
      <div className="col-md-6 col-lg-8 font-weight-500">PAYERS</div>
      <div className="row col-md-6 col-lg-4" style={{ justifyContent: "right" }}>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Payer
        </Button>
      </div>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

const dummyApprovalRecords = [
  {
    id: 1,
    status: "Waiting for Travel Approval",
    costCode: "Travel",
    jobDescription: "description 1",
    price: 26,
    createdAt: new Date(),
    updatedStatus: "Travel Approved",
    isUpdated: false,
  },
  {
    id: 2,
    status: "Advance Payment Requested",
    jobDescription: "description 2",
    costCode: "Advance Payment",
    price: 30,
    createdAt: new Date(),
    updatedStatus: "Advance Payment Received",
    isUpdated: false,
  },
  {
    id: 3,
    status: "Waiting for Warranty Approval",
    costCode: "Warranty",
    jobDescription: "description 3",
    price: 120,
    createdAt: new Date(),
    updatedStatus: "Warranty Approved",
    isUpdated: false,
  },
  {
    id: 4,
    status: "Waiting for Rework/Goodwill/Policy Approval",
    costCode: "Rework/Goodwill/Policy",
    jobDescription: "description 4",
    price: 90,
    createdAt: new Date(),
    updatedStatus: " Goodwill Approved",
    isUpdated: false,
  },
];

const RepairBuilderApproval = ({ setActiveStep, handleSnack }) => {
  const [tabValue, setTabValue] = useState("costing");

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});

  const [approvalRecords, setApprovalRecords] = useState([...dummyApprovalRecords]);

  // header tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // update the general data
  const handleUpdateGenData = () => {
    setTabValue("moreInfo");
  };

  // update the more info
  const handleUpdateMoreInfo = () => {
    setTabValue("finances");
  };

  // row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // row edit end
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // click on edit icons
  const handleEditClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.Edit } });
  };

  // click on save icon
  const handleSaveClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.View } });
  };

  // click on delete icon
  const handleDeleteClick = (payerId) => () => {
    // removePayer(payerId)
    //   .then((success) => {
    //     handleSnack("success", "Payer has been removed!");
    //     setRows(rows.filter((row) => row.payerId !== payerId));
    //   })
    //   .catch((e) => {
    //     handleSnack("error", "Payer couldn't be removed!");
    //     throw e;
    //   });
  };

  // click on cancel icon
  const handleCancelClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.View, ignoreModifications: true } });

    const editedRow = rows.find((row) => row.payerId === payerId);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.payerId !== payerId));
    }
  };

  // rows data update
  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: false };
        if (updatedRow.payerId === "new") {
          console.log(oldRow, newRow, rows);
          //   addQuotePayer(props.quoteId, { ...updatedRow, payerId: undefined, isNew: undefined })
          //     .then((savedPayer) => {
          //       props.handleSnack("success", `Payer has been added!`);
          //       setRows(rows.map((row) => (row.payerId === newRow.payerId ? savedPayer : row)));
          //       resolve(savedPayer);
          //     })
          //     .catch((e) => {
          //       props.handleSnack("error", "Payer details could not be added");
          //       resolve(oldRow);
          //     });
        } else {
          //   updatePayerData(newRow.payerId, updatedRow)
          //     .then((savedPayer) => {
          //       props.handleSnack("success", "Payer has been updated!");
          //       setRows(rows.map((row) => (row.payerId === savedPayer.data.payerId ? savedPayer.data : row)));
          //       resolve(savedPayer.data);
          //     })
          //     .catch((e) => {
          //       props.handleSnack("error", "Payer details could not be updated");
          //       resolve(oldRow);
          //     });
        }
      }),
    [rows]
  );

  // handle row mode model change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      headerName: "Cost Code",
      field: "costCode",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Chargeable", value: "CHARGEABLE" },
        { label: "Warranty", value: "WARRANTY" },
        { label: "Goodwill", value: "GOODWILL" },
        { label: "Rework", value: "REWORK" },
        { label: "Policy", value: "POLICY" },
        { label: "Project", value: "PROJECT" },
        { label: "General", value: "GENERAL" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Payers",
      field: "payerName",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Customer", value: "CUSTOMER" },
        { label: "OEM", value: "OEM" },
        { label: "Own", value: "OWN" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Cost Receiver",
      field: "costReceiver",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Warranty Expense", value: "WARRANTY_EXPENSE" },
        { label: "Cost Centre", value: "COST_CENTRE" },
        { label: "Profit Centre", value: "PROFIT_CENTRE" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Receiver Code",
      field: "receiverCode",
      editable: true,
      flex: 1,
    },
    {
      headerName: "Billing Split",
      field: "billingSplit",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.payerId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.payerId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.payerId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <img className="m-1" src={penIcon} alt="Edit" />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.payerId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDeleteClick(row.payerId)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // consting tab content
  const costingTab = () => {
    return (
      <>
        {/* <div className="card border px-3 py-2"></div> */}
        <DataGrid
          sx={GRID_STYLE}
          loading={loading}
          rows={rows}
          getRowId={(row) => row.payerId}
          autoHeight
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          experimentalFeatures={{ newEditingApi: true }}
          onProcessRowUpdateError={(error) => console.log(error)}
          processRowUpdate={processRowUpdate}
          components={{ Toolbar: EditToolbar }}
          componentsProps={{ toolbar: { setRows, setRowModesModel, rows } }}
        />
      </>
    );
  };

  // update the status
  const handleUpdateApprovalStatus = (row) => {
    if (!row?.isUpdated) {
      const updatedRecords = approvalRecords.map((record) => (record.id === row.id ? { ...record, isUpdated: !record.completed } : record));
      setApprovalRecords(updatedRecords);
      handleSnack("success", `this task is status ${row?.updatedStatus}`);
    } else {
      handleSnack("info", `this task is already approved`);
    }
  };

  const approvalTabColumns = [
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      width: 200,
      sortable: false,
      cellClassName: "status-column-cell",
      headerClassName: "status-column-header-class",
      renderCell: ({ row }) => (
        <div
          className="d-flex justify-content-between align-items-center py-2"
          style={{ fontSize: 15, paddingInline: 5, paddingBlock: 2, borderRadius: 6, color: row?.isUpdated ? "#67d11e" : "#f84343" }}
        >
          <span
            style={{
              borderRadius: 10,
              content: '" "',
              display: "block",
              marginRight: 8,
              height: 10,
              width: 10,
              backgroundColor: row?.isUpdated ? "#67d11e" : "#f84343",
            }}
          />
          {row?.isUpdated ? row?.updatedStatus : row.status}
        </div>
      ),
    },
    {
      field: "costCode",
      headerName: "COST CODE",
      sortable: false,
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="">{row?.costCode}</span>
        </div>
      ),
    },
    {
      field: "jobDescription",
      headerName: "JOB DESCRIPTION",
      sortable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="">{row?.jobDescription}</span>
        </div>
      ),
    },
    {
      field: "orderNo",
      headerName: "DETAILS",
      sortable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          {/* <span>{currencyFormatter.format(row?.price || 0)}</span> */}
          <span className="mx-2">
            <Moment format="DD MMMM YYYY, HH:MM a">{row?.orderDate}</Moment>
          </span>
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "ADDITIONALS/ACTION",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className={`d-flex align-items-center px-2 py-1 cursor`}
                style={{ color: row.isUpdated ? "#67d11e" : "#f84343", border: `1px solid ${row.isUpdated ? "#67d11e" : "#f84343"}` }}
                onClick={() => handleUpdateApprovalStatus(row)}
              >
                <span className=" font-size-16">Approved</span>
                <span className="font-size-18 ml-2">{row?.isUpdated ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}</span>
              </div>
            }
            label="Assign"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // consting tab content
  const approvalTab = useCallback(() => {
    return (
      <>
        <DataGrid
          sx={GENERAL_TRANSPRINT_GRID_STYLE}
          rows={approvalRecords} // Reactively updates based on `record` state
          columns={approvalTabColumns.map((col) => {
            // if (col.field === "action") {
            //   return {
            //     ...col,
            //     getActions: ({ row }) => {
            //       const currentIndex = approvalRecords.findIndex((r) => r.id === row.id);
            //       const previousRow = approvalRecords[currentIndex - 1];
            //       const isEnabled = currentIndex === 0 || previousRow?.isUpdated;

            //       return [
            //         <GridActionsCellItem
            //           key={`action-${row.id}`}
            //           icon={
            //             <div
            //               className={`d-flex align-items-center px-2 py-1 cursor ${isEnabled ? "" : "disabled"}`}
            //               style={{
            //                 color: row.isUpdated ? "#67d11e" : "#f84343",
            //                 border: `1px solid ${row.isUpdated ? "#67d11e" : "#f84343"}`,
            //                 pointerEvents: isEnabled ? "auto" : "none",
            //                 opacity: isEnabled ? 1 : 0.5,
            //               }}
            //               onClick={() => isEnabled && handleUpdateApprovalStatus(row)}
            //             >
            //               <span className="font-size-16">Completed</span>
            //               <span className="font-size-18 ml-2">{row.isUpdated ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}</span>
            //             </div>
            //           }
            //           label="Assign"
            //           className="textPrimary"
            //           color="inherit"
            //         />,
            //       ];
            //     },
            //   };
            // }
            return col;
          })}
          pageSize={11}
          rowsPerPageOptions={[5, 10, 20, 50]}
          hideFooter // Hides the entire footer including pagination and record count
          disableColumnMenu // Disables the column menu for a cleaner UI
          sortingOrder={["asc", "desc"]} // Enables sorting functionality
          autoHeight
          disableSelectionOnClick
          // isRowSelectable={(params) => {
          //   const currentIndex = approvalRecords.findIndex((row) => row.id === params.id);
          //   const previousRow = approvalRecords[currentIndex - 1];

          //   return currentIndex === 0 || previousRow?.isUpdated;
          // }}
          // getRowClassName={(params) => {
          //   const currentIndex = approvalRecords.findIndex((row) => row.id === params.id);
          //   const previousRow = approvalRecords[currentIndex - 1];

          //   if (currentIndex === 0 || previousRow?.isUpdated) {
          //     return "row-enabled"; // Add a CSS class for enabled rows
          //   }
          //   return "row-disabled"; // Add a CSS class for disabled rows
          // }}
        />
      </>
    );
  }, [approvalRecords]);

  // general tab
  const generalTab = () => {
    return (
      <div className="card border px-3 py-3">
        <div className="row input-fields">
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">COMPRISING ORDER NUMBER</label>
              <input type="text" disabled className="form-control border-radius-10 text-primary" id="customer-src" />
              {/* <div className="css-w8dmq8">*Mandatory</div> */}
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">TOTAL PRICE</label>
              <input
                type="text"
                // value={customerData.customerName}
                name="totalPrice"
                // onChange={handleCustomerDataChange}
                className="form-control border-radius-10 text-primary"
                id="totalPrice"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">PROMISE DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    // minDate={estimationData.preparedOn}
                    maxDate={new Date()}
                    closeOnSelect
                    // value={estimationData.preparedOn}
                    // onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">START DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    // minDate={estimationData.preparedOn}
                    maxDate={new Date()}
                    closeOnSelect
                    // value={estimationData.preparedOn}
                    // onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SERVICE ORGANISATION</label>
              <input
                type="text"
                // value={customerData.customerName}
                name="totalPrice"
                // onChange={handleCustomerDataChange}
                className="form-control border-radius-10 text-primary"
                id="totalPrice"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">SALES BRANCH</label>
              <input
                type="text"
                // value={customerData.customerName}
                name="totalPrice"
                // onChange={handleCustomerDataChange}
                className="form-control border-radius-10 text-primary"
                id="totalPrice"
              />
            </div>
          </div>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {/* <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6"></div> */}
          <button
            type="button"
            className="btn border-primary text-primary mr-1"
            // onClick={() => handleResetData("CANCEL")}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn bg-primary text-white"
            onClick={handleUpdateGenData}
            //   disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
          >
            Save & Next
          </button>
        </div>
      </div>
    );
  };

  // more info tab
  const moreInfo = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="estimated-approved-div py-2 px-3 border-radius-10 mb-3">
              <h5 className="d-flex align-items-center mb-0" style={{ color: "#2fa00a" }}>
                <ThumbUpOutlinedIcon sx={{ fontSize: 32, marginRight: 1 }} /> Estimate Approved On {"  "}
                <Moment format="MMMM DD, YYYY" style={{ marginLeft: "8px" }}>
                  {new Date()}
                </Moment>
              </h5>
            </div>
          </div>
        </div>
        <div className="">
          <h5 className="my-3">Approved By</h5>
          <Grid container spacing={2}>
            {estimatedByUsers.length !== 0 &&
              estimatedByUsers.map((user) => (
                <Grid item xs={3}>
                  <h6 className="mb-0 d-flex align-items-center">
                    <img
                      src="../../assets/images/user_profile.png"
                      alt="user-profile"
                      style={{ width: "25px", height: "25px", marginRight: "8px", border: "1px solid #B2B1B0", borderRadius: "50%" }}
                    />
                    {/* <Avatar
                            sx={{
                              width: 25,
                              height: 25,
                              marginRight: 1,
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                              border: "1px solid #B2B1B0",
                            }}
                            src="../../assets/images/user_profile.png"
                          /> */}
                    {user?.name}
                  </h6>
                </Grid>
              ))}
          </Grid>
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {/* <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6"></div> */}
          <button
            type="button"
            className="btn border-primary text-primary mr-1"
            // onClick={() => handleResetData("CANCEL")}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn bg-primary text-white"
            onClick={handleUpdateMoreInfo}
            //   disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
          >
            Save & Next
          </button>
        </div>
      </>
    );
  };

  // finances tab
  const finances = () => {
    return (
      <>
        <div className="row input-fields">
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">TOTAL COST</label>
              <input type="text" className="form-control border-radius-10 text-primary" id="customer-src" />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">REVENUE</label>
              <input type="text" className="form-control border-radius-10 text-primary" id="customer-src" />
            </div>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "right" }}>
          <button
            type="button"
            className="btn btn-light bg-primary text-white mr-1"
            // onClick={() => handleResetData("CANCEL")}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-light bg-primary text-white" onClick={() => setActiveStep((prev) => prev + 1)}>
            Save & Next
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <Box className="mt-2" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList className="custom-tabs-div" onChange={handleChange}>
              <Tab label="COSTING" value="costing" />
              <Tab label="APPROVAL" value="approval" />
              {/* <Tab label="GENERAL" value="general" />
              <Tab label="MORE INFO " value="moreInfo" />
              <Tab label="FINANCES" value="finances" /> */}
            </TabList>
          </Box>
          <TabPanel value={tabValue}>
            {tabValue === "costing" && costingTab()}
            {tabValue === "approval" && approvalTab()}
            {tabValue === "general" && generalTab()}
            {tabValue === "moreInfo" && moreInfo()}
            {tabValue === "finances" && finances()}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default RepairBuilderApproval;
