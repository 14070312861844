import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { TextField, Tooltip } from "@mui/material";
import { FONT_STYLE } from "pages/Common/constants";

import {
  SPARE_PARTS_ALTERNATE_PARTS_DETAILS,
  SPARE_PARTS_PRICE_DETAILS,
  SPARE_PARTS_REMAN_OR_REFURB_DETAILS,
  SPARE_PARTS_REPLACED_BY_DETAILS,
} from "../equipmentMasterConstants";
import Moment from "react-moment";
import { GET_SPAREPART_PRICE } from "services/CONSTANTS";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { Description } from "@material-ui/icons";

const PartItemAddUpdateModal = ({ show, hideModal, rowData = {}, data = [], setData, type, partRecord, handleSnack }) => {
  const [recordData, setRecordData] = useState(null);
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (rowData) {
      setViewModeOn(true);
      if (type === SPARE_PARTS_PRICE_DETAILS) {
        setRecordData({ ...rowData, quantity: 1, partType: "NEW", partId: partRecord?.partNumber || "" });
      } else {
        setRecordData({ ...rowData });
      }
    } else {
      if (type === SPARE_PARTS_PRICE_DETAILS) {
        setRecordData({
          ...rowData,
          quantity: 1,
          partType: "NEW",
          partId: partRecord?.partNumber || "",
          description: partRecord?.partDescription || "",
        });
      } else {
        setRecordData({ ...rowData });
      }
    }
  }, [rowData]);

  // input value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // Replaced by Details
  const replacedByDtls = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REPLACED BY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="replacedBy"
                  placeholder="Replaced By"
                  value={recordData?.replacedBy}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REPLACED QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  placeholder="Replaced Quantity"
                  value={recordData?.quantity}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">AVAILABILITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="availability"
                  placeholder="Availability"
                  value={recordData?.availability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TOTAL AVAILABLE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="totalAvailability"
                  placeholder="Total Available"
                  value={recordData?.totalAvailability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="salesUnit"
                  placeholder="Sales Unit"
                  value={recordData?.salesUnit}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="price"
                  placeholder="Price"
                  value={recordData?.price}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="REPLACED BY" value={recordData?.replacedBy} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="REPLACED QUANTITY" value={recordData?.quantity} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABILITY" value={recordData?.availability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="TOTAL AVAILABLE" value={recordData?.totalAvailability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SALES UNIT" value={recordData?.salesUnit} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={recordData?.price} />
          </div>
        )}
      </>
    );
  };

  // Alternate PArt Details
  const alternatePartsDtls = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ALTERNATE PART #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="itemName"
                  placeholder="Alternate Part"
                  value={recordData?.itemName}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  placeholder="Quantity"
                  value={recordData?.quantity}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">AVAILABILITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="availability"
                  placeholder="Availability"
                  value={recordData?.availability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TOTAL AVAILABLE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="totalAvailability"
                  placeholder="Total Available"
                  value={recordData?.totalAvailability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="salesUnit"
                  placeholder="Sales Unit"
                  value={recordData?.salesUnit}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="price"
                  placeholder="Price"
                  value={recordData?.price}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="ALTERNATE PART #" value={recordData?.itemName} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="QUANTITY" value={recordData?.quantity} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABILITY" value={recordData?.availability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="TOTAL AVAILABLE" value={recordData?.totalAvailability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SALES UNIT" value={recordData?.salesUnit} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={recordData?.price} />
          </div>
        )}
      </>
    );
  };

  // Reman or Refurb Option Item Details
  const remanOrRefurnItemDtls = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ALTERNATE PART #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="itemName"
                  placeholder="Alternate Part"
                  value={recordData?.itemName}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  placeholder="Quantity"
                  value={recordData?.quantity}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">AVAILABILITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="availability"
                  placeholder="Availability"
                  value={recordData?.availability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TOTAL AVAILABLE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="totalAvailability"
                  placeholder="Total Available"
                  value={recordData?.totalAvailability}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="salesUnit"
                  placeholder="Sales Unit"
                  value={recordData?.salesUnit}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="price"
                  placeholder="Price"
                  value={recordData?.price}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="ALTERNATE PART #" value={recordData?.itemName} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="QUANTITY" value={recordData?.quantity} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABILITY" value={recordData?.availability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="TOTAL AVAILABLE" value={recordData?.totalAvailability} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SALES UNIT" value={recordData?.salesUnit} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={recordData?.price} />
          </div>
        )}
      </>
    );
  };

  // Price Details
  const priceDtls = () => {
    return (
      <>
        {!viewModeOn ? (
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">GROUP #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="productGroup"
                  placeholder="Group Number"
                  value={recordData?.productGroup}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="partType"
                  placeholder="Type"
                  value={recordData?.partType}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PART #</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="partId"
                  placeholder="Part Number"
                  value={recordData?.partId}
                  onChange={handleInputTextChange}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="unit"
                  placeholder="Sales Unit"
                  value={recordData?.unit}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">QUANTITY</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="quantity"
                  placeholder="Quantity"
                  value={recordData?.quantity}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="price"
                  placeholder="Price"
                  value={recordData?.price}
                  onChange={handleInputTextChange}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">VALID FROM</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData?.validFrom}
                      onChange={(e) => setRecordData({ ...recordData, validFrom: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">VALID TO</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      value={recordData?.validTo}
                      onChange={(e) => setRecordData({ ...recordData, validTo: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <ReadOnlyField className="col-md-4 col-sm-4" label="GROUP #" value={recordData?.productGroup} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="TYPE" value={recordData?.partType} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="PART #" value={recordData?.partId} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="SALES UNIT" value={recordData?.unit} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="QUANTITY" value={recordData?.quantity} />
            <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={recordData?.price} />
            <ReadOnlyField
              className="col-md-4 col-sm-4"
              label="VALID FROM"
              value={recordData?.validFrom ? <Moment format="DD/MM/YYYY">{recordData?.validFrom}</Moment> : "NA"}
            />
            <ReadOnlyField
              className="col-md-4 col-sm-4"
              label="VALID TO"
              value={recordData?.validTo ? <Moment format="DD/MM/YYYY">{recordData?.validTo}</Moment> : "NA"}
            />
          </div>
        )}
      </>
    );
  };

  // submit the data
  const handleSubmit = () => {
    if (type === SPARE_PARTS_PRICE_DETAILS) {
      const rUrl = GET_SPAREPART_PRICE;
      const rObj = {
        ...rowData,
        country: rowData?.country?.value || rowData?.country || "INDIA",
        currency: rowData?.currency?.value || rowData?.currency || "INR",
      };
      if (rowData?.id) {
        callPutApi(null, `${rUrl}/${rowData?.id}`, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _data = data.map((item) => (item?.id === responseData?.id ? { ...responseData } : item));
            setData(_data);
            handleSnack("success", "Price updated Successfully!");
          } else {
            handleSnack("error", "Something went wrong");
          }
        });
      } else {
        callPostApi(null, `${rUrl}`, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _data = [...data];
            _data.unshift(responseData);
            setData(_data);
            handleSnack("success", "Price created Successfully!");
          } else {
            handleSnack("error", "Something went wrong");
          }
        });
      }
    } else {
      if (rowData?.id) {
        setData((pre) => pre.map((item) => (item?.id === rowData?.id ? recordData : item)));
        handleSnack("success", "Price updated Successfully!");
      } else {
        const _data = [...data];
        _data.push({ ...recordData, id: data.length + 1 });
        setData(_data);
        handleSnack("success", "Price created Successfully!");
      }
    }
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" centered>
        <div className="d-flex justify-content-between align-items-center p-2">
          <h4>
            {type === SPARE_PARTS_REPLACED_BY_DETAILS
              ? "Replaced By"
              : type === SPARE_PARTS_ALTERNATE_PARTS_DETAILS
              ? "Alternate Parts"
              : type === SPARE_PARTS_REMAN_OR_REFURB_DETAILS
              ? "Reman or Refurb Options"
              : type === SPARE_PARTS_PRICE_DETAILS
              ? "Price Details"
              : ""}
          </h4>
          {recordData?.id && (
            <Tooltip title="Edit">
              <span className="upload-icon-button cursor" onClick={() => setViewModeOn(false)}>
                <EditOutlinedIcon sx={{ fontSize: 40 }} />
              </span>
            </Tooltip>
          )}
        </div>
        <div className="px-2 mb-3">
          <div className="card border px-3 mb-3">
            {type === SPARE_PARTS_REPLACED_BY_DETAILS
              ? replacedByDtls()
              : type === SPARE_PARTS_ALTERNATE_PARTS_DETAILS
              ? alternatePartsDtls()
              : type === SPARE_PARTS_REMAN_OR_REFURB_DETAILS
              ? remanOrRefurnItemDtls()
              : type === SPARE_PARTS_PRICE_DETAILS
              ? priceDtls()
              : ""}
          </div>
          {!viewModeOn ? (
            <div className="row px-3" style={{ justifyContent: "right" }}>
              <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                Cancel
              </button>
              <button className="btn bg-primary text-white" onClick={handleSubmit}>
                Save
              </button>
            </div>
          ) : (
            <div className="row px-3" style={{ justifyContent: "right" }}>
              <button className="btn bg-primary text-white" onClick={hideModal}>
                Close
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PartItemAddUpdateModal;
