import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Box, Checkbox, FormControlLabel, Typography, Paper, Tooltip, FormGroup, Switch, TextField } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Moment from "react-moment";

import { FONT_STYLE, FONT_STYLE_SELECT, GENERAL_TRANSPRINT_GRID_STYLE } from "pages/Common/constants";
import Select from "react-select";
import ClaimProcessSattlement from "./ClaimProcessSattlement";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: "0.6rem",
  "&:(:last-child)": { marginBottom: 0 },
  "&::before": { display: "none" },
}));

const AccordionSummary = styled((props) => {
  const { expanded } = props;
  return <MuiAccordionSummary expandIcon={expanded ? <RemoveIcon sx={{ fontSize: "3rem" }} /> : <AddIcon sx={{ fontSize: "3rem" }} />} {...props} />;
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ClaimApprovalClosures = (props) => {
  const {
    machineData,
    customerData,
    yearlyWarrantyData,
    claimRecordData,
    authorizationStatusOptions = [],
    authorizationStatusRecords = [],
    setAuthorizationStatusRecords,
    authorizationStatus,
    setAuthorizationStatus,
    approvalStatusOptions,
    settlementIntgrate,
    setSettlementIntgrate,
    handleBack,
    handleSnack,
    settlementValueId,
    setSettlementValueId,
    claimOrderId,
    claimNumber,
    handleViewAndAddNotes,
    claimSettlementRecord,
    setClaimSettlementRecord,
    claimStatus,
    handleUpdateClaimOrder,
    claimValueRecordData,
    setClaimStatus,
    setSettlementCount,
    handleShowClaimErrorsModal,
    finalApprovalRecordData,
    setFinalApprovalRecordData,
    finalApprovalViewOnly,
    setFinalApprovalViewOnly,
  } = props;

  const [expended, setExpended] = useState("finalApprovalSection");

  // expend sefment
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpended(isExpanded ? panel : false);
  };

  // Final Approval Input texy change
  const handleFinalApprovalTextChange = (e) => {
    const { name, value } = e.target;
    setFinalApprovalRecordData({ ...finalApprovalRecordData, [name]: value });
  };

  // save the final approval record
  const handleSaveFinalApprovalData = () => {
    handleSnack("success", "Final Approval changes saved successfully.");
    setFinalApprovalViewOnly(true);
  };

  const authStatusColumns = [
    {
      field: "authorizationStatus",
      headerName: "Status",
      sortable: false,
      width: 250,
      //   flex: 1,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span
            className={row?.authorizationStatus === "AUTHORIZED" ? "led-green" : row?.authorizationStatus === "DENIED" ? "led-red" : "led-orange"}
          ></span>
          <span>{row?.authorizationStatus === "AUTHORIZED" ? "Authorized" : row?.authorizationStatus === "DENIED" ? "Denied" : "Pending"}</span>
        </div>
      ),
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      width: 550,
      //   flex: 1,
      renderCell: ({ row }) => (
        <div className="d-block">
          {`Serial #:- ${machineData?.serialNo || "N/A"} | Customer #:- ${customerData?.customerID || "N/A"} | Warranty Id: ${
            yearlyWarrantyData?.warrantyId || "N/A"
          }`}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 300,
      //   flex: 1,
      type: "actions",
      getActions: ({ row }) => {
        if (!row) return [];
        const actions = [];
        if (row.authorizationStatus === "PENDING") {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Authorized">
                  <CheckCircleOutlineIcon sx={{ fontSize: 28, color: "#67d11e" }} />
                </Tooltip>
              }
              label="Authorized"
              //   onClick={() => handleAcceptRejectAuthrization(row, "AUTHORIZED")}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Denied">
                  <CancelOutlinedIcon sx={{ fontSize: 28, color: "#f84343" }} />
                </Tooltip>
              }
              label="Denied"
              //   onClick={() => handleAcceptRejectAuthrization(row, "DENIED")}
            />
          );
        }
        actions.push(
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon sx={{ fontSize: 28 }} />
              </Tooltip>
            }
            label="Edit"
            // onClick={() => handleOpenAuthDtlsModal(row)}
          />
        );
        return actions;
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={GENERAL_TRANSPRINT_GRID_STYLE}
        rows={authorizationStatusRecords}
        columns={authStatusColumns.map((col) => {
          return col;
        })}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20, 50]}
        hideFooter
        disableColumnMenu
        sortingOrder={["asc", "desc"]}
        autoHeight
        disableSelectionOnClick
      />

      <div className="row">
        <div className="col-md-7 col-sm-7">
          <div className="card border p-3 mt-2">
            <div className="d-flex">
              <FormControlLabel sx={{ px: 0, marginRight: 0 }} control={<Checkbox defaultChecked />} />
              <div className="ml-0">
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Securely save my information for 1-click checkout
                </Typography>
                <Typography variant="body2" style={{ color: "#6c757d" }}>
                  Pay faster on Skiff World, Inc. and everywhere Link is accepted
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion expanded={expended === "finalApprovalSection"} onChange={handleExpand("finalApprovalSection")} key={"finalApprovalSection"}>
        <AccordionSummary expanded={expended === "finalApprovalSection" ? true : false}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
            <Typography>{"Final Approval Section"}</Typography>
            {expended === "finalApprovalSection" && (
              <span
                className="mr-1 cursor"
                onClick={(e) => {
                  e.stopPropagation();
                  setFinalApprovalViewOnly(false);
                }}
              >
                <Tooltip title="Edit">
                  <i className="fa fa-pencil font-size-18" aria-hidden="true"></i>
                </Tooltip>
              </span>
            )}
            {/* {claimStatus?.value !== "CLAIM_SUBMITTED" && expended === "finalApprovalSection" && assesstmentId && (
              <span className="mr-1 cursor" onClick={(e) => handleMakeAccordionEditable(e, "finalApprovalSection")}>
                <Tooltip title="Edit">
                  <i className="fa fa-pencil font-size-18" aria-hidden="true"></i>
                </Tooltip>
              </span>
            )} */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {!finalApprovalViewOnly ? (
            <>
              <div className="row input-fields mt-2">
                <div className="col-md-4 col-sm-4">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CLAIM NUMBER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="claimNumber"
                      value={claimNumber}
                      //   onChange={handleMachineDataChange}
                      placeholder="Claim Number"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">
                      <span className=" mr-2">CLAIM DATE/MONTH</span>
                    </label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          closeOnSelect
                          value={claimRecordData?.createdDate}
                          // onChange={(e) => setFinalApprovalRecordData({ ...finalApprovalRecordData, approvalDate: e })}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                          )}
                          disabled
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2">
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">APPROVAL STATUS</label>
                    <Select
                      onChange={(e) => setFinalApprovalRecordData({ ...finalApprovalRecordData, approvalStatus: e })}
                      options={approvalStatusOptions}
                      value={finalApprovalRecordData?.approvalStatus}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">APPROVAL AUTHORITY</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="approvalAuthority"
                      value={finalApprovalRecordData?.approvalAuthority}
                      onChange={handleFinalApprovalTextChange}
                      placeholder="Approval Authority"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">
                      <span className=" mr-2">APPROVAL DATE</span>
                    </label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          closeOnSelect
                          value={finalApprovalRecordData?.approvalDate}
                          onChange={(e) => setFinalApprovalRecordData({ ...finalApprovalRecordData, approvalDate: e })}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REJECTION REASON</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="rejectionReason"
                      value={finalApprovalRecordData?.rejectionReason}
                      onChange={handleFinalApprovalTextChange}
                      placeholder="Rejection Reason"
                    />
                  </div>
                </div>
              </div>
              <div className="row input-fields mt-2">
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">GOODWILL</label>
                    <Select
                      onChange={(e) => setFinalApprovalRecordData({ ...finalApprovalRecordData, goodWillStatus: e })}
                      options={approvalStatusOptions}
                      value={finalApprovalRecordData?.goodWillStatus}
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">APPROVAL AUTHORITY</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="goodwillApprovalAuthority"
                      value={finalApprovalRecordData?.goodwillApprovalAuthority}
                      onChange={handleFinalApprovalTextChange}
                      placeholder="Approval Authority"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">
                      <span className=" mr-2">APPROVAL DATE</span>
                    </label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          closeOnSelect
                          value={finalApprovalRecordData?.goodwillApprovalAuthorityDate}
                          onChange={(e) => setFinalApprovalRecordData({ ...finalApprovalRecordData, goodwillApprovalAuthorityDate: e })}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">REJECTION REASON</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      name="goodWillRejectReason"
                      value={finalApprovalRecordData?.goodWillRejectReason}
                      onChange={handleFinalApprovalTextChange}
                      placeholder="Rejection Reason"
                    />
                  </div>
                </div>
              </div>

              <div className="row  px-3" style={{ justifyContent: "right" }}>
                <button className="btn bg-primary text-white" onClick={handleSaveFinalApprovalData}>
                  Save Changes
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="row input-fields mt-2">
                <ReadOnlyField label="CLAIM NUMBER" value={claimNumber} className="col-md-4 col-sm-4" />
                <ReadOnlyField label="CLAIM DATE/MONTH" value={claimNumber} className="col-md-4 col-sm-4" />
              </div>
              <div className="row input-fields mt-2">
                <ReadOnlyField label="APPROVAL STATUS" value={finalApprovalRecordData?.approvalStatus?.label} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="APPROVAL AUTHORITY" value={finalApprovalRecordData?.approvalAuthority} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="APPROVAL DATE" value={finalApprovalRecordData?.approvalDate} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="REJECTION REASON" value={finalApprovalRecordData?.rejectionReason} className="col-md-3 col-sm-3" />
              </div>
              <div className="row input-fields mt-2">
                <ReadOnlyField label="GOODWILL" value={finalApprovalRecordData?.goodWillStatus?.label} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="APPROVAL AUTHORITY" value={finalApprovalRecordData?.goodwillApprovalAuthority} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="APPROVAL DATE" value={finalApprovalRecordData?.goodwillApprovalAuthorityDate} className="col-md-3 col-sm-3" />
                <ReadOnlyField label="REJECTION REASON" value={finalApprovalRecordData?.goodWillRejectReason} className="col-md-3 col-sm-3" />
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expended === "settlement"} onChange={handleExpand("settlement")} key={"settlement"}>
        <AccordionSummary expanded={expended === "settlement" ? true : false}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
            <Typography>{"Settlement"}</Typography>
            {claimStatus?.value !== "SETTLED" && (
              <span className={`px-3 py-1 settlement-status`}>
                {claimStatus?.value === "REGISTERED" ||
                claimStatus?.value === "CLAIM_SUBMITTED" ||
                claimStatus?.value === "CLAIM_ACKNOWLEDGED" ||
                claimStatus?.value === "CLAIM_ACCEPTED" ||
                claimStatus?.value === "CONTESTED"
                  ? "Settlement Pending"
                  : claimStatus?.value === "CLAIM_REJECTED"
                  ? "Claim Rejected"
                  : claimStatus?.value === "SETTLED"
                  ? "Settlement Received"
                  : claimStatus?.value === "ARCHIVED" || claimStatus?.value === "CLOSED"
                  ? "Settlement Closed"
                  : ""}
              </span>
            )}
            {/* {claimStatus?.value !== "CLAIM_SUBMITTED" && expended === "settlement" && assesstmentId && (
              <span className="mr-1 cursor" onClick={(e) => handleMakeAccordionEditable(e, "settlement")}>
                <Tooltip title="Edit">
                  <i className="fa fa-pencil font-size-18" aria-hidden="true"></i>
                </Tooltip>
              </span>
            )} */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12 col-sm-12">
            <div className=" d-flex justify-content-between align-items-center">
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={settlementIntgrate} onChange={(e) => setSettlementIntgrate(e.target.checked)} name="externalWorkEnabled" />
                    }
                    label="Settlement Integration"
                    value={settlementIntgrate}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          {settlementIntgrate && (
            <>
              <ClaimProcessSattlement
                handleBack={handleBack}
                handleSnack={handleSnack}
                settlementValueId={settlementValueId}
                setSettlementValueId={setSettlementValueId}
                claimOrderId={claimOrderId}
                // claimNumber={claimNumber}
                handleViewAndAddNotes={handleViewAndAddNotes}
                claimSettlementRecord={claimSettlementRecord}
                setClaimSettlementRecord={setClaimSettlementRecord}
                claimStatus={claimStatus}
                handleUpdateClaimOrder={handleUpdateClaimOrder}
                claimValueRecordData={claimValueRecordData}
                setClaimStatus={setClaimStatus}
                setSettlementCount={setSettlementCount}
                handleShowClaimErrorsModal={handleShowClaimErrorsModal}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expended === "invoice"} onChange={handleExpand("invoice")} key={"invoice"}>
        <AccordionSummary expanded={expended === "invoice" ? true : false}>
          <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
            <Typography>{"Invoice"}</Typography>
            {/* {claimStatus?.value !== "CLAIM_SUBMITTED" && expended === "invoice" && assesstmentId && (
              <span className="mr-1 cursor" onClick={(e) => handleMakeAccordionEditable(e, "invoice")}>
                <Tooltip title="Edit">
                  <i className="fa fa-pencil font-size-18" aria-hidden="true"></i>
                </Tooltip>
              </span>
            )} */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row input-fields mt-2">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVOICE NUMBER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="invoiceNumber"
                  //   value={machineData.make}
                  //   onChange={handleMachineDataChange}
                  // placeholder="Auto Filled"
                  // disabled
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">
                  <span className=" mr-2">INVOICE DATE</span>
                </label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      // value={yearlyWarrantyData?.warrantyStartDate}
                      // onChange={(e) => setYearlyWarrantyData({ ...yearlyWarrantyData, warrantyStartDate: e })}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">INVOICE AMOUNT</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="invoiceNumber"
                  //   value={machineData.make}
                  //   onChange={handleMachineDataChange}
                  // placeholder="Auto Filled"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="customerName"
                  value={customerData.customerName}
                  //   onChange={handleMachineDataChange}
                  // placeholder="Customer name"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CONTACT INFORMATION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="contactInformation"
                  value={customerData.contactNumber}
                  //   onChange={handleMachineDataChange}
                  // placeholder="Auto Filled"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row px-3" style={{ justifyContent: "right" }}>
            <button className="btn bg-primary text-white">Generate Invoice</button>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ClaimApprovalClosures;
