import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { WAREHOUSE_INVENTORY_MASTER_URL } from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";

import { partsReqObj, warehouseInventory } from "../warehouseConstents";

import { isEmpty } from "pages/Common/textUtilities";
import ImageViewer from "pages/Common/utils/ImageViewer";
import { ReadOnlyField } from "pages/Repair/components/ReadOnlyField";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import WarehouseSearch from "./WarehouseSearch";

const PartsWarehouseAddUpdateModal = ({
  show,
  hideModal,
  recordId,
  partsRow = {},
  partsRecords,
  setPartsRecords,
  partStockWareHouses,
  setPartStockWareHouses,
  handleSnack,
}) => {
  const [viewModeOn, setVideModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("partsRow :::: ", partsRow)

  const [inventoryId, setInventoryId] = useState(null);

  const [partsRecordRow, setPartsRecordRow] = useState({ ...partsRow });
  const [inventoryRecordObj, setInventoryRecordObj] = useState({
    ...warehouseInventory,
    sparepartId: partsRow?.id || 0,
    warehouseName: "",
    warehouseNumber: "",
  });

  useEffect(() => {
    if (recordId) {
      setVideModeOn(true);
      setLoading(true);
      const rUrl = `${WAREHOUSE_INVENTORY_MASTER_URL}/${recordId}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setInventoryId(responseData?.id);
            setInventoryRecordObj({ ...responseData, sparepartId: responseData?.partsId });
            setLoading(false);
          } else {
            setInventoryId(null);
            setLoading(false);
          }
        },
        (error) => {
          setInventoryId(null);
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // inventorry fields text change
  const handleInventoryTextChange = (e) => {
    const { name, value } = e.target;
    setInventoryRecordObj({ ...inventoryRecordObj, [name]: value });
  };

  // add update inventory record
  const handleSaveChanges = (e) => {
    const rObj = { ...inventoryRecordObj };
    const rUrl = WAREHOUSE_INVENTORY_MASTER_URL;
    if (inventoryId) {
      callPutApi(
        null,
        `${rUrl}/${inventoryId}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _partsRecords = partsRecords.map((row) =>
              row?.id === partsRow?.id
                ? {
                    ...row,
                    warehouseInventories: row.warehouseInventories.map((warehouse) =>
                      warehouse.id === inventoryId ? { ...responseData } : warehouse
                    ),
                  }
                : row
            );

            setPartStockWareHouses(partStockWareHouses.map((warehouse) => (warehouse.id === inventoryId ? { ...responseData } : warehouse)));
            setPartsRecords(_partsRecords);
            setVideModeOn(true);
            handleSnack("success", "Inventory Details Updated Successfully");
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;

            const _partStockWareHouses = [...partStockWareHouses];
            _partStockWareHouses.push(responseData);

            const _partsRecords = partsRecords.map((row) =>
              row?.id === partsRow?.id ? { ...row, warehouseInventories: [..._partStockWareHouses] } : row
            );

            setPartStockWareHouses([..._partStockWareHouses]);
            setPartsRecords(_partsRecords);
            setInventoryId(responseData.id);
            setVideModeOn(true);
            handleSnack("success", "Inventory Record Created Successfully");
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" centered>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4>Part Inventory Details</h4>
            {inventoryRecordObj?.id && (
              <button className="btn border-primary text-primary" onClick={() => setVideModeOn(false)}>
                Edit
              </button>
            )}
          </div>
          <div className="card border px-3 py-2 mb-3">
            <div className="row mt-2">
              <div className="col-md-12 col-sm-12">
                {!viewModeOn ? (
                  <div className="row input-fields">
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PART NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.partNumber}
                          name="partNumber"
                          placeholder="Part Number"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.partDescription}
                          name="partDescription"
                          placeholder="Description"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.partType}
                          name="partType"
                          placeholder="Part Type"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.manufacturer}
                          name="manufacturer"
                          placeholder="Manufacturer"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.model}
                          name="model"
                          placeholder="Model"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PRICE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.listPrice}
                          name="listPrice"
                          placeholder="Price"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">GROUP NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={partsRecordRow?.groupNumber}
                          name="groupNumber"
                          placeholder="Group Number"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">WAREHOUSE NAME</label>
                        <WarehouseSearch placeholder="Warehouse Name" title="Warehouse" value={inventoryRecordObj?.warehouseName} />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PLANT NAME</label>
                        <WarehouseSearch placeholder="Plant Name" title="Plant" />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">TOTAL QUANTITY</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={
                            Number(inventoryRecordObj?.availableQuantity || 0) +
                            Number(inventoryRecordObj?.blockedQuantity || 0) +
                            Number(inventoryRecordObj?.qualityInspectionQuantity || 0) +
                            Number(inventoryRecordObj?.onOrderStockQuantity || 0) +
                            Number(inventoryRecordObj?.consignmentStockQuantity || 0)
                          }
                          name="totalQuantity"
                          placeholder="Total Quantity"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">AVAILABLE STOCKS</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={inventoryRecordObj?.availableQuantity}
                          name="availableQuantity"
                          placeholder="Available Stocks"
                          onChange={handleInventoryTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">BLOCKED</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={inventoryRecordObj?.blockedQuantity}
                          name="blockedQuantity"
                          placeholder="Blocked Quantity"
                          onChange={handleInventoryTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">QUALITY INSPECTION</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={inventoryRecordObj?.qualityInspectionQuantity}
                          name="qualityInspectionQuantity"
                          placeholder="Quality Inscription"
                          onChange={handleInventoryTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ON ORDER STOCK</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={inventoryRecordObj?.onOrderStockQuantity}
                          name="onOrderStockQuantity"
                          placeholder="On Order Stock"
                          onChange={handleInventoryTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONSIGNMENT STOCK</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          value={inventoryRecordObj?.consignmentStockQuantity}
                          name="consignmentStockQuantity"
                          placeholder="Consignment Stock"
                          onChange={handleInventoryTextChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <ReadOnlyField className="col-md-4 col-sm-4" label="PART NUMBER" value={partsRecordRow?.partNumber} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="DESCRIPTION" value={partsRecordRow?.partDescription} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="TYPE" value={partsRecordRow?.partType} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="MANUFACTURER" value={partsRecordRow?.manufacturer} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="MODEL" value={partsRecordRow?.model} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="PRICE" value={currencyFormatter.format(partsRecordRow?.listPrice || 0)} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="GROUP NUMBER" value={partsRecordRow?.groupNumber} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="WAREHOUSE NAME" value={inventoryRecordObj?.warehouseName} />
                    <ReadOnlyField
                      className="col-md-4 col-sm-4"
                      label="TOTAL QUANTITY"
                      value={
                        Number(inventoryRecordObj?.availableQuantity || 0) +
                        Number(inventoryRecordObj?.blockedQuantity || 0) +
                        Number(inventoryRecordObj?.qualityInspectionQuantity || 0) +
                        Number(inventoryRecordObj?.onOrderStockQuantity || 0) +
                        Number(inventoryRecordObj?.consignmentStockQuantity || 0)
                      }
                    />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABLE STOCKS" value={inventoryRecordObj?.availableQuantity} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="BLOCKED" value={inventoryRecordObj?.blockedQuantity} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="QUALITY INSPECTION" value={inventoryRecordObj?.qualityInspectionQuantity} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="ON ORDER STOCK" value={inventoryRecordObj?.onOrderStockQuantity} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CONSIGNMENT STOCK" value={inventoryRecordObj?.consignmentStockQuantity} />
                  </div>
                )}
              </div>
              {/* <div className="col-md-3 col-sm-3">
                <ImageViewer
                  src={partsRecordRow?.sparepartImages && partsRecordRow?.sparepartImages.length > 0 ? partsRecordRow?.sparepartImages[0] || "" : ""}
                  style={{ objectFit: "fill" }}
                />
              </div> */}
            </div>
          </div>
          <div className="row px-2" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
              Close
            </button>
            {!viewModeOn && (
              <button className="btn bg-primary text-white mx-2" onClick={handleSaveChanges}>
                Save
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartsWarehouseAddUpdateModal;
