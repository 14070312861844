import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { serviceLocationObj, serviceLocationTypeOptions } from "../FieldServiceConstants";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SERVICE_LOCATION_MASTER_URL } from "services/CONSTANTS";
import LoadingProgress from "pages/Repair/components/Loader";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

const LocationAddUpdateModal = ({ show, hideModal, handleSnack, recordId, handleRefresh }) => {
  const [recordData, setRecordData] = useState({
    ...serviceLocationObj,
    locationType: serviceLocationTypeOptions[0],
  });
  const [viewMode, setViewMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      setViewMode(true);
      const rUrl = `${SERVICE_LOCATION_MASTER_URL}/${recordId}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _locationType = serviceLocationTypeOptions.find((item) => item?.value === responseData.locationType);
            setRecordData({ ...responseData, locationType: _locationType || serviceLocationTypeOptions[0] });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // add update the record
  const handleSubmit = () => {
    const rUrl = `${SERVICE_LOCATION_MASTER_URL}`;
    const rObj = { ...recordData, locationType: recordData?.locationType?.value };
    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData?.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Location updated successfully!");
            setViewMode(true);
            handleRefresh();
          } else {
            handleSnack("error", "Error occurred while updating the location!");
          }
        },
        (err) => {
          handleSnack("error", "Error occurred while updating the location!");
        }
      );
    } else {
      callPostApi(
        null,
        `${rUrl}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData?.id });
            handleSnack("success", "Location created successfully!");
            setViewMode(true);
            handleRefresh();
          } else {
            handleSnack("error", "Error occurred while creating the location!");
          }
        },
        (error) => {
          handleSnack("error", "Error occurred while creating the location!");
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex align-items-center mb-2">
            <div className="d-flex justify-content-between align-items-center ">
              <h5 className="mb-0">Location Details</h5>
              {recordData?.id && (
                <a className="btn-sm cursor ml-2" onClick={() => setViewMode(false)}>
                  <EditIcon sx={{ fontSize: 28 }} />
                </a>
              )}
            </div>
          </div>
          {loading ? (
            <LoadingProgress />
          ) : (
            <>
              <div className="card border px-3 mb-2">
                {!viewMode ? (
                  <>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">LOCATION NAME</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="locationName"
                            placeholder="Location Name"
                            value={recordData?.locationName}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">LOCATION TYPE</label>
                          <Select
                            className="text-primary"
                            name="locationType"
                            value={recordData?.locationType}
                            onChange={(e) => setRecordData({ ...recordData, locationType: e })}
                            options={serviceLocationTypeOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICE GROUP</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="serviceGroup"
                            placeholder="Service Group"
                            value={recordData?.serviceGroup}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                          <textarea
                            className="form-control border-radius-10 text-primary"
                            name="address"
                            placeholder="Address"
                            rows="3"
                            value={recordData?.address}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField label="LOCATION NAME" value={recordData.locationName} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="LOCATION TYPE" value={recordData.locationType?.label} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="SERVICE GROUP" value={recordData.serviceGroup} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="ADDRESS" value={recordData.address} className="col-md-12 col-sm-12" />
                    </div>
                  </>
                )}
              </div>
              <div className="row px-3" style={{ justifyContent: "right" }}>
                <button className="btn border-primary text-primary" onClick={hideModal}>
                  Close
                </button>
                {!viewMode && (
                  <button className="btn bg-primary text-white ml-2" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LocationAddUpdateModal;
