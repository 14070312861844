import React, { useEffect, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";

import Moment from "react-moment";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { GET_AVAILABLE_EMPLOYEES } from "services/CONSTANTS";

import AssignCalander from "./AssignCalander";
import LoadingProgress from "pages/Repair/components/Loader";

const dateTime = new Date();

const filterOptions = [
    { label: "Today", value: "TODAY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Monthly", value: "MONTHLY" },
];

const timeSlots = [
    {
        id: 1,
        customerName: "John sans",
        timeSlot: "08.00-09.00",
    },
    {
        id: 2,
        customerName: "David Smith",
        timeSlot: "09.00-11.00",
    },
    {
        id: 3,
        customerName: "Nick Waliger",
        timeSlot: "11.00-13.00",
    },
    {
        id: 4,
        customerName: "James rocks",
        timeSlot: "13.00-14.00",
    },
    {
        id: 5,
        customerName: "Stiven Oops",
        timeSlot: "14.00-16.00",
    },
];

const getGreeting = () => {
    const currentHour = dateTime.getHours(); // Get the current hour (0-23)
    if (currentHour < 12) {
        return "Good Morning";
    } else if (currentHour < 18) {
        return "Good Afternoon";
    } else {
        return "Good Evening";
    }
};

const AssignWorkflow = ({ show, hideModal, handleSnack }) => {
    const [filterValue, setFilterValue] = useState(filterOptions[0]);

    const [timeRecords, setTimeRecords] = useState([...timeSlots]);

    const [employeeRecords, setEmployeeRecords] = useState([]);
    const [empRecordId, setEmpRecordId] = useState(null);

    const [openDateAssignModal, setOpenDateAssignModal] = useState(false);
    const [customerRecordId, setCustomerRecordId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const rUrl = `${GET_AVAILABLE_EMPLOYEES}?filterType=${filterValue?.value}`;
        callGetApi(
            rUrl,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setEmployeeRecords(responseData);
                    setLoading(false);
                } else {
                    setEmployeeRecords([]);
                    setLoading(false);
                }
            },
            (error) => {
                setLoading(false);
                setEmployeeRecords([]);
            }
        );
    }, [filterValue]);

    // assign date to the customer
    const handleAssignDate = (row) => {
        setOpenDateAssignModal(true);
        setEmpRecordId(row?.id);
        setCustomerRecordId(row?.id);
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="lg">
                <Modal.Body className="px-0">
                    <div className="d-flex justify-content-between align-items-center px-3">
                        <div className="d-block">
                            <h4 className="mb-0">{getGreeting()}, Ashok</h4>
                            <span className="font-size-15">
                                Today, <Moment format="ddd DD MMM YYYY">{dateTime}</Moment>
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <Select
                                defaultValue={filterOptions[0]}
                                options={filterOptions}
                                value={filterValue}
                                onChange={(e) => setFilterValue(e)}
                                sx={{
                                    control: (provided) => ({ ...provided, borderRadius: 10, boxShadow: "none" }),
                                    menu: (provided) => ({ ...provided, width: "300px" }),
                                    menuList: (provided) => ({ ...provided, width: "300px" }),
                                }}
                            />
                            <span className="p-1 bg-white ml-2 border border-radius-5 cursor">
                                <DragHandleIcon sx={{ fontSize: 35 }} />
                            </span>
                        </div>
                    </div>
                    <Divider sx={{ my: 2 }} />
                    <div className="px-3">
                        {loading ? (
                            <LoadingProgress />
                        ) : (
                            <>
                                {employeeRecords.length > 0 ? (
                                    employeeRecords.map((item, index) => (
                                        <div
                                            className="card border border-radius-6 px-3 py-2 bg-white mb-2 text-dark  cursor"
                                            key={index}
                                            onClick={() => handleAssignDate(item)}
                                        >
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <div className="d-flex align-items-center  cursor" style={{ gap: "8px" }}>
                                                    <h5 style={{ margin: 0, flex: 1 }}>{`${item?.employeeNumber}-${item?.employeeName}`}</h5>
                                                </div>
                                                <div className="d-flex align-items-start" style={{ color: "#202020" }}>
                                                    {/* <div
                                                        className="d-flex align-items-center border border-radius-10 p-1 font-weight-500  cursor"
                                                        style={{ background: "#F6F6F6" }}
                                                    >
                                                        <AccessTimeIcon />
                                                        <span className="ml-2">{item?.timeSlot}</span>
                                                    </div> */}
                                                    <span className=" cursor">
                                                        <MoreHorizIcon className="ml-2" sx={{ fontSize: 40 }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                        {/* {timeRecords.length !== 0 &&
                            timeRecords.map((item, index) => (
                                <div className="card border border-radius-6 px-3 py-2 bg-white mb-2 text-dark  cursor" key={index} onClick={() => handleAssignDate(item)}>
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <div className="d-flex align-items-center  cursor" style={{ gap: "8px" }}>
                                            <h5 style={{ margin: 0, flex: 1 }}>{item?.customerName}</h5>
                                        </div>
                                        <div className="d-flex align-items-start" style={{ color: "#202020" }}>
                                            <div className="d-flex align-items-center border border-radius-10 p-1 font-weight-500  cursor" style={{ background: "#F6F6F6" }}>
                                                <AccessTimeIcon />
                                                <span className="ml-2">{item?.timeSlot}</span>
                                            </div>
                                            <span className=" cursor">
                                                <MoreHorizIcon className="ml-2" sx={{ fontSize: 40 }} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))} */}
                    </div>
                </Modal.Body>
            </Modal>

            {openDateAssignModal && (
                <AssignCalander show={openDateAssignModal} hideModal={() => setOpenDateAssignModal(false)} recordId={empRecordId} handleSnack={handleSnack} />
            )}
        </>
    );
};
export default AssignWorkflow;
