import { useEffect, useState } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import penIcon from "../../../assets/images/pen.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFolderPlus, faPlus, faShareAlt, faUpload } from "@fortawesome/free-solid-svg-icons";

import { Grid, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";

import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { repairActions } from "pages/Repair/dropdowns/repairSlice";
import { repairQuoteActions } from "pages/Repair/dropdowns/quoteRepairSlice";
import { BUILDER_SEARCH_Q_OPTIONS, GRID_STYLE, WITH_PARTS } from "pages/Common/constants";
import { builderSearch, createBuilder } from "services/repairBuilderServices";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { FIELD_SERVICES_ORDER_DETAILS } from "navigation/CONSTANTS";
import LoadingProgress from "pages/Repair/components/Loader";
import SearchComponentTemplate from "pages/Repair/components/SearchComponentTemplate";

const ServiceOrderMaster = () => {
    const history = useHistory();

    const [recentBuilders, setRecentBuilders] = useState([]);
    const [recentBuildersLoading, setRecentBuildersLoading] = useState(false);

    const [querySearchSelector, setQuerySearchSelector] = useState([
        {
            id: 0,
            selectCategory: "",
            selectOperator: "",
            inputSearch: "",
            selectOptions: [],
            selectedOption: "",
        },
    ]);
    const [masterData, setMasterData] = useState([]);

    // Snack Bar State
    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(repairQuoteActions.fetchQuoteDropdowns());
        dispatch(repairActions.fetchDropdowns());
        fetchRecentBuilders(`builderType:${WITH_PARTS}&saved:true&pageNumber=0&pageSize=10&sortColumn=updatedAt&orderBY=DESC`);
        if (JSON.parse(localStorage.getItem("exitingType"))) {
            localStorage.removeItem("exitingType");
        }
    }, []);

    const fetchRecentBuilders = async (searchQuery) => {
        setRecentBuildersLoading(true);
        await builderSearch(searchQuery)
            .then((result) => {
                setRecentBuilders(result);
            })
            .catch((err) => {
                handleSnack("error", "Error occurred while fetching builders");
            });
        setRecentBuildersLoading(false);
    };

    // create new builder
    const createNewBuilder = () => {
        let builderDetails = {
            builderId: "",
            bId: "",
            type: "new",
            builderType: WITH_PARTS,
        };
        createBuilder({
            builderType: WITH_PARTS,
            activeVersion: true,
            versionNumber: 1,
            status: "DRAFT",
        })
            .then((result) => {
                builderDetails.builderId = result.builderId;
                builderDetails.bId = result.id;

                history.push({
                    pathname: FIELD_SERVICES_ORDER_DETAILS,
                    state: builderDetails,
                });
            })
            .catch((err) => {
                console.log("Error Occurred", err);
                handleSnack("error", "Error occurred while creating builder!");
            });
    };

    // make builder editable
    const makeBuilderEditable = (builder) => {
        let builderDetails = {
            builderId: "",
            bId: "",
            type: "fetch",
            builderType: WITH_PARTS,
        };
        builderDetails.builderId = builder.builderId;
        builderDetails.bId = builder.id;
        history.push({
            pathname: FIELD_SERVICES_ORDER_DETAILS,
            state: builderDetails,
        });
    };

    // Once opetion has been selected clear the search results
    const clearFilteredData = () => {
        setMasterData([]);
    };

    // click on search button and call the Search functionality
    const handleQuerySearchClick = async () => {
        $(".scrollbar").css("display", "none");
        var searchStr = "";
        querySearchSelector.map(function (item, i) {
            if (i === 0 && item.selectCategory.value && item.inputSearch) {
                searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
            } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
                searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
            }
            return searchStr;
        });

        try {
            if (searchStr) {
                const res = await builderSearch(`builderType:${WITH_PARTS} AND saved:true AND ${searchStr}`);
                setMasterData(res);
            } else {
                handleSnack("info", "Please fill the search criteria!");
            }
        } catch (err) {
            handleSnack("error", "Error occurred while fetching spare parts!");
        }
    };

    const searchBuilderColumns = [
        { field: "builderId", headerName: "ID#", flex: 1, width: 70 },
        { field: "description", headerName: "Description", flex: 1, width: 130 },
        { field: "customerId", headerName: "Customer#", flex: 1, width: 130 },
        { field: "make", headerName: "Make", flex: 1, width: 130 },
        { field: "model", headerName: "Model", flex: 1, width: 130 },
        { field: "family", headerName: "Family", flex: 1, width: 130 },
        { field: "serialNo", headerName: "Serial#", flex: 1, width: 130 },
        { field: "createdBy", headerName: "Created by", flex: 1, width: 130 },
        {
            field: "createdAt",
            headerName: "Created On",
            flex: 1,
            width: 130,
            renderCell: (params) => (
                <Moment format="DD MMM YY HH:MM a" style={{ fontSize: 12 }}>
                    {params.value}
                </Moment>
            ),
        },
        {
            field: "netPrice",
            headerName: "Total $",
            flex: 1,
            width: 130,
            renderCell: (params) => <span style={{ fontSize: 12 }}>{parseFloat(params.value)?.toFixed(2)}</span>,
        },
        { field: "status", headerName: "Status", flex: 1, width: 130 },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <div className=" cursor">
                                <Tooltip title="Edit">
                                    <img className="m-1" src={penIcon} alt="Edit" />
                                </Tooltip>
                            </div>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={() => makeBuilderEditable(params.row)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <>
            <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
            <div className="content-body" style={{ minHeight: "884px" }}>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <h5 className="font-weight-600 mb-0">Service Order</h5>
                        <div>
                            <button onClick={createNewBuilder} className="btn bg-primary text-white">
                                <span className="mr-2">
                                    <FontAwesomeIcon icon={faPlus} />
                                </span>
                                Create New<span className="ml-2"></span>
                            </button>
                        </div>
                    </div>

                    <Grid container spacing={2} sx={{ mt: 2, justifyContent: "center" }}>
                        <Grid item xs={2}>
                            <div className="card p-3" style={{ height: "10rem", backgroundColor: "#d9e6fe" }}>
                                <div className="light-sky-blue mt-2 mb-4"></div>
                                <Typography variant="body2">Active Repairs</Typography>
                                <Typography variant="h6">12</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="card p-3" style={{ height: "10rem", backgroundColor: "#fbf0d6" }}>
                                <div className="light-cream mt-2 mb-4"></div>
                                <Typography variant="body2">Pending</Typography>
                                <Typography variant="h6">5</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="card p-3" style={{ height: "10rem", backgroundColor: "#daf4df" }}>
                                <div className="light-mint-green mt-2 mb-4"></div>
                                <Typography variant="body2">Completed Today</Typography>
                                <Typography variant="h6">7</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="card p-3" style={{ height: "10rem", backgroundColor: "#fedbda" }}>
                                <div className="light-blush-pink mt-2 mb-4"></div>
                                <Typography variant="body2">Delayed</Typography>
                                <Typography variant="h6">2</Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="px-5 mt-5">
                        <h5 className="font-weight-600 text-grey mb-3 px-5">RECENT SERVICE ORDERS</h5>
                        <div className="row px-5">
                            {recentBuildersLoading ? (
                                <div className="col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
                                    <LoadingProgress />
                                </div>
                            ) : recentBuilders.length > 0 ? (
                                recentBuilders.map((indBuilder) => (
                                    <div className="col-md-4 col-sm-4 mb-2">
                                        <div className="card border px-3 py-2 mb-2" style={{ height: "6.5rem" }}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-block">
                                                    <h5
                                                        className="mb-0 font-size-18 cursor shop-repair-card-title"
                                                        style={{ textDecoration: "underline" }}
                                                        onClick={() => makeBuilderEditable(indBuilder)}
                                                    >
                                                        {/* Excavator CAT 320 */}
                                                        {indBuilder.builderId}
                                                    </h5>
                                                    <span className="text-gray font-size-15 font-weight-500 text-truncate">
                                                        {/* Track replacement */}
                                                        {indBuilder?.description}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span className="shop-repair-high px-3 py-1 font-weight-500 rounded-pill">
                                                        {`Version ${indBuilder?.versionNumber || 1}`}
                                                    </span>
                                                    <span className="ml-2 shop-repair-in-progress px-3 py-1 font-weight-500 rounded-pill">{indBuilder?.status}</span>
                                                </div>
                                                {/* <div className="d-flex align-items-center">
                                                    <span className="shop-repair-high px-3 py-1 font-weight-500 rounded-pill">High</span>
                                                    <span className="ml-2 shop-repair-in-progress px-3 py-1 font-weight-500 rounded-pill">In-Progress</span>
                                                </div> */}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-1">
                                                <div>
                                                    <span className="text-gray font-size-15 font-weight-400 text-truncate">
                                                        {/* Construction Solution */}
                                                        {indBuilder?.customerName}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span>
                                                        <Moment format="HH:MM a">{indBuilder.updatedAt}</Moment>, <Moment format="DD MMM YY">{indBuilder.updatedAt}</Moment>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="ml-3 mt-4">
                                    <Typography>No Service Orders Found</Typography>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="bg-primary px-3 mb-3 border-radius-10">
                        <div className="row align-items-center">
                            <div className="col-11 mx-2">
                                <div className="d-flex align-items-center bg-primary w-100">
                                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                                        <h5 className="mr-2 mb-0 text-white">
                                            <span>Search</span>
                                        </h5>
                                    </div>
                                    <SearchComponentTemplate
                                        querySearchSelector={querySearchSelector}
                                        setQuerySearchSelector={setQuerySearchSelector}
                                        clearFilteredData={clearFilteredData}
                                        handleSnack={handleSnack}
                                        searchAPI={builderSearch}
                                        searchClick={handleQuerySearchClick}
                                        options={BUILDER_SEARCH_Q_OPTIONS}
                                        color="white"
                                        builderType={WITH_PARTS}
                                        buttonText="SEARCH"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <DataGrid sx={GRID_STYLE} rows={masterData} columns={searchBuilderColumns} pageSize={5} rowsPerPageOptions={[5]} autoHeight />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceOrderMaster;
