import React, { useCallback, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import TaskIcon from "@mui/icons-material/Task";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HourglassBottomTwoToneIcon from "@mui/icons-material/HourglassBottomTwoTone";

import { Divider, Grid, Tooltip } from "@mui/material";

import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import Moment from "react-moment";

// Tab data with icons
const tabs = [
    { name: "Stage Info", icon: <InfoIcon /> },
    { name: "Tasks", icon: <TaskIcon /> },
    { name: "Photos & Doc", icon: <PhotoLibraryIcon /> },
];

const initialStageData = [
    {
        id: 1,
        stageName: "Arrival Inspection",
        status: "Pending",
        checked: false,
    },
    {
        id: 2,
        stageName: "Pre-Delivery Inspection",
        status: "Pending",
        checked: false,
    },
    {
        id: 3,
        stageName: "Installation or Commissioning",
        status: "Pending",
        checked: false,
    },
];

const initialTaskData = [
    {
        id: 1,
        radioCheck: false,
        comments: [],
        categoryName: "General Condition",
        tasks: [
            { taskId: 1, taskName: "Visible signs of damage", checked: false },
            { taskId: 2, taskName: "Corrosion or rust", checked: false },
            { taskId: 3, taskName: "Missing parts or components", checked: false },
            { taskId: 4, taskName: "Proper labeling and identification", checked: false },
        ],
    },
    {
        id: 2,
        radioCheck: false,
        comments: [],
        categoryName: "Mechanical Functionality",
        tasks: [
            { taskId: 1, taskName: "Power on/off functionality", checked: false },
            { taskId: 2, taskName: "Movement of all moving parts", checked: false },
            { taskId: 3, taskName: "Noise levels during operation", checked: false },
            { taskId: 4, taskName: "Check for unusual vibrations or sounds", checked: false },
        ],
    },
    {
        id: 3,
        radioCheck: false,
        comments: [],
        categoryName: "Safety Compliance",
        tasks: [
            { taskId: 1, taskName: "Check emergency stop buttons", checked: false },
            { taskId: 2, taskName: "Verify safety guard installation", checked: false },
            { taskId: 3, taskName: "Inspect safety labels", checked: false },
            { taskId: 4, taskName: "Test safety mechanisms", checked: false },
        ],
    },
    {
        id: 4,
        radioCheck: false,
        comments: [],
        categoryName: "Fluid Level",
        tasks: [
            { taskId: 1, taskName: "Oil levels", checked: false },
            { taskId: 2, taskName: "Coolant levels", checked: false },
            { taskId: 3, taskName: "Hydraulic fluid levels", checked: false },
        ],
    },
    {
        id: 5,
        radioCheck: false,
        comments: [],
        categoryName: "Electrical Checks",
        tasks: [
            { taskId: 1, taskName: "Power cord condition", checked: false },
            { taskId: 2, taskName: "Voltage and amperage verification", checked: false },
            { taskId: 3, taskName: "Functionality of controls and switches", checked: false },
        ],
    },
    {
        id: 6,
        radioCheck: false,
        comments: [],
        categoryName: "Documentation and compliance",
        tasks: [
            { taskId: 1, taskName: "Manufacturer's manual and specifications", checked: false },
            { taskId: 2, taskName: "Safety certificates and compliance documents", checked: false },
        ],
    },
];

const ShopReapirInspectionDetailsModal = ({ show, hideModal, handleSnack }) => {
    const [activeTab, setActiveTab] = useState("Stage Info");

    const [stageRecords, setStageRecords] = useState([...initialStageData]);

    const [taskRecords, setTaskRecords] = useState([...initialTaskData]);
    const [taskCatRecordId, setTaskCatRecordId] = useState(null);
    const [taskName, setTaskName] = useState("");
    const [isTaskOpen, setIsTaskOpen] = useState(false);

    const [commentMsg, setCommentMsg] = useState("");
    const [openCommentModal, setOpenCommentModal] = useState(false);

    // ! -------------- Stages Tab Functionality Start ------------- ! //

    // check stages Staus on the behalf of Task CAtegory status
    const areAllTasksChecked = useCallback(() => {
        return taskRecords.every((category) => category.tasks.every((task) => task.checked));
    }, [taskRecords]);

    // select stage
    const handleSelectStage = (stage) => {
        setStageRecords((pre) => pre.map((item) => (item?.id === stage?.id ? { ...item, checked: true } : { ...item, checked: false })));
    };

    // ! -------------- Stages Tab Functionality End ------------- ! //

    // ! -------------- Task Tab Functionality Start ------------- ! //

    // Function to toggle the visibility of the textarea
    const handleAddNewTask = () => {
        setIsTaskOpen(true);
    };

    // check category check box check uncheck
    const isCategoryChecked = (category) => {
        const categoryRow = taskRecords.find((item) => item.id === category?.id);
        const isChecked = categoryRow["tasks"].every((task) => task.checked);
        return isChecked;
    };

    // Select task category
    const handleSelectTaskCategory = (category) => {
        setTaskRecords((pre) => pre.map((item) => (item?.id === category?.id ? { ...item, radioCheck: true } : { ...item, radioCheck: false })));
    };

    // Task category check box check uncheck
    const handleTaskAllCategoryCheckUncheck = (category) => {
        let categoryRow = taskRecords.find((item) => item.id === category?.id);
        const isChecked = categoryRow["tasks"].every((task) => task.checked);
        let categoryTasks = [];
        if (isChecked) {
            categoryTasks = category["tasks"].map((task) => ({ ...task, checked: false }));
        } else {
            categoryTasks = category["tasks"].map((task) => ({ ...task, checked: true }));
        }
        setTaskRecords((pre) => pre.map((item) => (item?.id === category?.id ? { ...categoryRow, tasks: [...categoryTasks] } : item)));
    };

    // check uncheck category tasks
    const handleCategoryTaskCheck = (category, taskRow) => {
        const categoryRow = taskRecords.find((item) => item.id === category?.id);
        const _tasks = category["tasks"].map((task) => (task?.taskId === taskRow?.taskId ? { ...taskRow, checked: !task?.checked } : task));
        setTaskRecords((pre) => pre.map((item) => (item?.id === category?.id ? { ...categoryRow, tasks: [..._tasks] } : item)));
    };

    // Function to save the task
    const handleSaveTask = () => {
        if (taskName.trim().length === 0) {
            handleSnack("error", "Task name must not be empty");
            return;
        }
        let _taskRecords = [...taskRecords];
        if (_taskRecords.length === 6) {
            _taskRecords.push({
                id: _taskRecords.length + 1,
                radioCheck: false,
                categoryName: "New Task",
                tasks: [{ taskId: 1, taskName: taskName, checked: false }],
            });
        } else {
            _taskRecords = _taskRecords.map((item) =>
                item?.id === 7 ? { ...item, tasks: [...item["tasks"], { taskId: item["tasks"].length + 1, taskName: taskName, checked: false }] } : item
            );
        }
        setTaskRecords(_taskRecords);
        setTaskName("");
        setIsTaskOpen(false);
    };

    // open Cooment modal
    const handleOpenCommentModal = (item) => {
        setOpenCommentModal(true);
        setTaskCatRecordId(item?.id);
    };

    // close comment modal
    const hideCommentModal = () => {
        setCommentMsg("");
        setOpenCommentModal(false);
    };

    // submit comment message
    const handleSubmitCommentMsg = () => {
        if (commentMsg.trim().length === 0) {
            handleSnack("error", "Comment message must not be empty.");
            return;
        }
        setTaskRecords((pre) => pre.map((item) => (item?.id === taskCatRecordId ? { ...item, comments: [...item["comments"], commentMsg] } : item)));
        setOpenCommentModal(false);
        setCommentMsg("");
    };

    // ! -------------- Task Tab Functionality End ------------- ! //

    // view Stage Tab Details
    const viewStageTabDetails = () => {
        return (
            <>
                <div className="px-2">
                    <h5 className="mb-4">Stage Information</h5>
                    <div>
                        {stageRecords.length !== 0 &&
                            stageRecords.map((stage) => (
                                <div>
                                    <label className="d-flex align-items-baseline">
                                        <input type="radio" className="mb-0" value={stage?.stageName} checked={stage?.checked} onChange={() => handleSelectStage(stage)} />
                                        <div className="w-100 ml-3 mb-2">
                                            <span className="font-size-14 font-weight-600 ">{stage?.stageName}</span>
                                            <div className="d-flex align-items-center justify-content-between mt-2 font-size-14 font-weight-500">
                                                <div className="d-flex align-items-center">
                                                    <span style={{ color: "#ffc107", marginRight: "6px" }}>
                                                        {areAllTasksChecked() ? <CloudDoneIcon style={{ color: "#28a745" }} /> : <HourglassBottomTwoToneIcon />}
                                                    </span>
                                                    {areAllTasksChecked() ? "Work Completed" : "Pending..."}
                                                </div>
                                                <div style={{ color: "#28a745", border: "1px solid #28a745", padding: "2px 8px", borderRadius: "4px" }}>
                                                    {areAllTasksChecked() ? "Work Completed" : "Work in Progress"}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    <Divider sx={{ mb: 2 }} />
                                </div>
                            ))}
                    </div>
                </div>
            </>
        );
    };

    // view Tasks Tab Details
    const viewTasksTabDetails = () => {
        return (
            <>
                <div className="">
                    <div className="d-flex align-items-center justify-content-between mb-2 w-100">
                        <h5 className="">Tasks</h5>
                        <span className="px-2 bg-primary text-white font-size-18 cursor" onClick={handleAddNewTask}>
                            +
                        </span>
                    </div>
                    <div className="px-2">
                        {taskRecords.length > 0 &&
                            taskRecords?.map((item) => (
                                <div>
                                    <div className="d-flex align-items-baseline">
                                        <input
                                            type="radio"
                                            name="category"
                                            id={`radio-${item?.categoryName}`}
                                            className="mb-0"
                                            onChange={() => handleSelectTaskCategory(item)}
                                            checked={item?.radioCheck}
                                        />
                                        <div className="w-100 ml-3">
                                            <label htmlFor={`radio-${item?.categoryName}`} className="font-size-14 font-weight-600 mb-2">
                                                {item?.categoryName}:
                                            </label>
                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                <p className="d-flex align-items-center mb-0 overflow-hidden white-space text-primary">
                                                    <DateRangeTwoToneIcon />
                                                    <span className="font-weight-500 ml-2">
                                                        {isCategoryChecked(item) ? <Moment format="YYYY-MM-DD">{new Date()}</Moment> : "Today"}
                                                    </span>
                                                </p>
                                                <div className="d-flex align-items-center">
                                                    <Tooltip title="Edit" arrow>
                                                        <span className="btn-sm cursor">
                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Comment" arrow>
                                                        <span className="mx-3 font-size-14 cursor">
                                                            <ChatBubbleOutlineIcon onClick={() => handleOpenCommentModal(item)} />
                                                        </span>
                                                    </Tooltip>
                                                    <div className="">
                                                        <input
                                                            type="checkbox"
                                                            id="main-checkbox"
                                                            checked={isCategoryChecked(item)}
                                                            onChange={() => handleTaskAllCategoryCheckUncheck(item)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider sx={{ my: 2 }} />
                                    <div className="">
                                        {item?.radioCheck && (
                                            <ul className="bullet-points">
                                                {item["tasks"].map((taskItem, index) => (
                                                    <li key={`${item.category}-item-${index}`}>
                                                        <input
                                                            type="checkbox"
                                                            id={`task-${item.category}-${index}`}
                                                            checked={taskItem?.checked}
                                                            onChange={() => handleCategoryTaskCheck(item, taskItem)}
                                                        />
                                                        <span className="text">{taskItem?.taskName}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                {isTaskOpen && (
                    <div className="mt-2">
                        <textarea className="form-control" rows="4" value={taskName} onChange={(e) => setTaskName(e.target.value)} placeholder="Write your task here..." />
                        <div className="row px-4  my-3" style={{ justifyContent: "right" }}>
                            <button className="btn bg-primary text-white" onClick={handleSaveTask}>
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

    // view photos and odcs Tab details
    const viewPhotosDocsTabDtls = () => {
        return (
            <>
                <div className="photos-content ">
                    <h5 className="mb-4">Photos & Doc</h5>
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="card border card-box-shadow p-3  ">
                            <div className="repair-builder-upload ">
                                <div className="text-center ">
                                    <h5>Upload photos & documents</h5>
                                    <div className="d-inline-flex justify-content-center align-items-center border rounded p-2 my-4" style={{ width: "80px", height: "50px" }}>
                                        <AddIcon fontSize="large" />
                                    </div>
                                    <h5 className="my-2">Drag and drop files to upload</h5>
                                    or
                                    <FileUploader name="file" types={["JPG", "PNG"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {/* <Modal show={show} onHide={hideModal} className="inspection-details-right-side-modal right-side-modal" backdropClassName="custom-backdrop" centered> */}
            <Modal show={show} onHide={hideModal} size="lg">
                <Modal.Header>
                    <Modal.Title>Inspection Report</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <div className="d-flex px-3 py-2 rounded-pill" style={{ backgroundColor: "#f0f0f0" }}>
                        {tabs.map((tab) => (
                            <div key={`${tab.name}-${tab.icon}`} className={`inspectiontab ${activeTab === tab.name ? "active" : ""}`} onClick={() => setActiveTab(tab.name)}>
                                <span>{tab.icon}</span>
                                <span>{tab.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className="my-4">
                        {activeTab === "Stage Info" && viewStageTabDetails()}
                        {activeTab === "Tasks" && viewTasksTabDetails()}
                        {activeTab === "Photos & Doc" && viewPhotosDocsTabDtls()}
                    </div>
                    <div className="row px-3" style={{ justifyContent: "right" }}>
                        <button className="btn bg-primary text-white" onClick={hideModal}>
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {openCommentModal && (
                <Modal show={openCommentModal} size="w-50" centered>
                    <Modal.Body>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h4 className="mb-0">Comment</h4>
                            <span className="cursor" onClick={hideCommentModal}>
                                <CloseIcon sx={{ fontSize: 34 }} />
                            </span>
                        </div>
                        <div className="card border px-3 mb-3">
                            <div className="row input-fields mt-2">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">MESSAGE</label>
                                        <textarea
                                            className="form-control border-radius-10 text-primary"
                                            name="message"
                                            cols="30"
                                            rows="4"
                                            value={commentMsg}
                                            onChange={(e) => setCommentMsg(e.target.value)}
                                            placeholder="Type you comment message here..."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row px-3" style={{ justifyContent: "right" }}>
                            <button className="btn border-primary text-primary" onClick={hideCommentModal}>
                                Close
                            </button>
                            <button className="btn bg-primary text-white ml-2" onClick={handleSubmitCommentMsg}>
                                Save
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default ShopReapirInspectionDetailsModal;
