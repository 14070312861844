import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_STYLE } from "pages/Common/constants";
import { Tooltip } from "@mui/material";
import PartSearchComp from "./PartSearchComp";
import {
  SPARE_PARTS_ALTERNATE_PARTS_DETAILS,
  SPARE_PARTS_ERP_DETAILS,
  SPARE_PARTS_PRICE_DETAILS,
  SPARE_PARTS_REMAN_OR_REFURB_DETAILS,
  SPARE_PARTS_REPLACED_BY_DETAILS,
  SPARE_PARTS_WARRENTY_DETAILS,
} from "../equipmentMasterConstants";
import PartItemAddUpdateModal from "./PartItemAddUpdateModal";
import Moment from "react-moment";

const PartsTableMaster = ({ data, setData, searchFor, btnText, type, title, showSearch = true, partRecord, handleSnack }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openServiceReportModal, setOpenServiceReportModal] = useState(false);

  const handleOpenAddModal = () => {
    setOpenDetailsModal(true);
  };

  // open View Details Modal
  const handleOpenDtlsModal = (row) => {
    setSelectedRow(row);
    setOpenDetailsModal(true);
  };

  // close View Details Modal
  const handleCloseDtlsModal = () => {
    setSelectedRow(null);
    setOpenDetailsModal(false);
  };

  // Replaced By columns
  const replacedByColumns = [
    {
      field: "replacedBy",
      headerName: "Replaced By",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Replaced Quantity",
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "totalAvailability",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "salesUnit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // Alternate Parts columns
  const alternatePartColumns = [
    {
      field: "itemName",
      headerName: "Alternte Part #",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "totalAvailability",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "salesUnit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // Reman or Refurn Options columns
  const remanOrRefurbColumns = [
    {
      field: "itemName",
      headerName: "Alternte Part #",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Availability",
      flex: 1,
    },
    {
      field: "totalAvailability",
      headerName: "Total Available",
      flex: 1,
    },
    {
      field: "salesUnit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // Price columns
  const priceColumns = [
    {
      field: "productGroup",
      headerName: "Group#",
      flex: 1,
    },
    {
      field: "partType",
      headerName: "Type",
      flex: 1,
      renderCell: ({ row }) => row?.partType || "NEW",
    },
    {
      field: "partId",
      headerName: "Part#",
      flex: 1,
    },
    {
      field: "unit",
      headerName: "Sales Unit",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "validFrom",
      headerName: "Valid From",
      flex: 1,
      renderCell: ({ row }) => (row?.validFrom ? <Moment format="DD/MM/YYYY">{row?.validFrom}</Moment> : "NA"),
    },
    {
      field: "validTo",
      headerName: "Valid To",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (row?.validTo ? <Moment format="DD/MM/YYYY">{row?.validTo}</Moment> : "NA"),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // Price columns
  const erpPriceColumns = [
    {
      field: "erpCondition",
      headerName: "ERP Condition",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "ERP Amount",
      flex: 1,
    },
    {
      field: "costPrice",
      headerName: "ERP Cost Price",
      flex: 1,
    },
    {
      field: "margin",
      headerName: "ERP Margin",
      flex: 1,
    },
    {
      field: "lastPricedDate",
      headerName: "Last Priced Date",
      flex: 1,
      renderCell: ({ row }) => (row?.lastPricedDate ? <Moment format="DD/MM/YYYY">{row?.lastPricedDate}</Moment> : "NA"),
    },
    {
      field: "priceChangeDate",
      headerName: "Price Change Date",
      flex: 1,
      renderCell: ({ row }) => (row?.priceChangeDate ? <Moment format="DD/MM/YYYY">{row?.priceChangeDate}</Moment> : "NA"),
    },
  ];

  // Warranty columns
  const warrantyColumns = [
    {
      field: "warrantyType",
      headerName: "Warranty Type",
      flex: 1,
    },
    {
      field: "warrentyDuration",
      headerName: "Warranty Duration",
      //   width: 90,
      flex: 1,
    },
    {
      field: "dateOfSale",
      headerName: "Date of Sale",
      flex: 1,
      renderCell: ({ row }) => (row?.dateOfSale ? <Moment format="DD/MM/YYYY">{row?.dateOfSale}</Moment> : "NA"),
    },
    {
      field: "dateOfInstallation",
      headerName: "Date of Installation",
      flex: 1,
      renderCell: ({ row }) => (row?.dateOfInstallation ? <Moment format="DD/MM/YYYY">{row?.dateOfInstallation}</Moment> : "NA"),
    },
  ];

  return (
    <>
      <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
        <PartSearchComp searchFor={searchFor} btnText={btnText} showSearch={showSearch} openAddModal={handleOpenAddModal} handleSnack={handleSnack} />

        <DataGrid
          sx={GRID_STYLE}
          rows={data}
          columns={
            type === SPARE_PARTS_REPLACED_BY_DETAILS
              ? replacedByColumns
              : type === SPARE_PARTS_ALTERNATE_PARTS_DETAILS
              ? alternatePartColumns
              : type === SPARE_PARTS_REMAN_OR_REFURB_DETAILS
              ? remanOrRefurbColumns
              : type === SPARE_PARTS_PRICE_DETAILS
              ? priceColumns
              : type === SPARE_PARTS_ERP_DETAILS
              ? erpPriceColumns
              : type === SPARE_PARTS_WARRENTY_DETAILS
              ? warrantyColumns
              : []
          }
          autoHeight
        />
      </div>

      {openDetailsModal && (
        <PartItemAddUpdateModal
          show={openDetailsModal}
          hideModal={handleCloseDtlsModal}
          rowData={selectedRow}
          data={data}
          setData={setData}
          type={type}
          partRecord={partRecord}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default PartsTableMaster;
