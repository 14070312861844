import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import Moment from "react-moment";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { PARTS_ORDER_MASTER_URL, SEARCH_Builder } from "services/CONSTANTS";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { GRID_STYLE } from "pages/Common/constants";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";

import InVoiceGenerateModal from "./InVoiceGenerateModal";

const InvoiceMaster = () => {
  const [orderRecords, setOrderRecords] = useState([]);
  const [partsOrderRecordCount, setPartsOrderRecordCount] = useState(10);
  const [partsPageno, setPartsPageno] = useState(0);
  const [partsPageSize, setPartsPageSize] = useState(10);
  const [loading, seLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [recordId, setRecordId] = useState(null);

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [recentBuildersLoading, setRecentBuildersLoading] = useState(true);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getPartOrderRecords();
  }, []);

  // get the parts order history records
  const getPartOrderRecords = async () => {
    seLoading(true);

    const rUrl = SEARCH_Builder(`builderType:PARTLIST AND saved:true&pageSize=10&sortColumn=updatedAt&orderBY=DESC`);
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartsOrderRecordCount(responseData.length);
          setOrderRecords(responseData);
          seLoading(false);
        } else {
          setPartsOrderRecordCount(0);
          setOrderRecords([]);
          seLoading(false);
        }
      },
      (error) => {
        setPartsOrderRecordCount(0);
        setOrderRecords([]);
        seLoading(false);
      }
    );
  };

  const orderColumns = [
    {
      field: "partsOrderName",
      headerName: "Order#",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => `#${row?.estimationNumber.replace(/^PL/, "PO")}`,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => <span>PAID</span>,
    },
    {
      field: "totalPrice",
      headerName: "Price",
      flex: 1,
      minWidth: 120,
      //   renderCell: ({ row }) => currencyFormatter.format(row?.totalPrice),
      renderCell: ({ row }) => currencyFormatter.format(row?.netPrice || 0),
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      flex: 1,
      minWidth: 120,
      renderCell: ({ row }) => <Moment format="DD/MM/YYYY">{row.estimationDate}</Moment>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleViewOrderDetails(row)}>
                <EditOutlinedIcon />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // view the order details
  const handleViewOrderDetails = (row) => {
    setSelectedRow({ ...row, estimationNumber: row?.estimationNumber.replace(/^PL/, "PO") });
    setRecordId(row?.id);
    setOpenInvoiceModal(true);
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h5 className="font-weight-600 mb-0 mt-3">Invoice</h5>
          <div className="my-4">
            <div className="d-flex justify-content-between">
              <div className=" align-items-center m-0">
                <div className={`transaction-search-box d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
                  <div className="transaction-search customize">
                    <input
                      className="transaction-input-sleact pr-1"
                      type="text"
                      placeholder="Search Billing"
                      // value={inputRegion}
                      autoComplete="off"
                      style={{ width: "300px" }}
                      // onChange={handleDropDownSearchForRegion}
                    />
                    <div
                      className="btn bg-primary text-white cursor"
                      // onClick={handleSearchForRegion}
                    >
                      <span className="mr-2">
                        <SearchIcon />
                      </span>
                      SEARCH
                    </div>
                    {
                      //   <ul className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`} id="style">
                      //     {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 && <li className="list-group-item">No Result found</li>}
                      //     {inputRegion.length !== 0 &&
                      //       searchedDropDownRegion.length !== 0 &&
                      //       searchedDropDownRegion.map((currentItem, j) => (
                      //         <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForRegion(currentItem)}>
                      //           {currentItem["city"]}
                      //         </li>
                      //       ))}
                      //   </ul>
                    }
                  </div>
                </div>
              </div>
              <div className="mr-3 my-2">
                <button className="btn transparent">
                  <FilterListIcon /> Filters
                </button>
              </div>
            </div>
          </div>
          <DataGrid
            loading={loading}
            rows={orderRecords}
            columns={orderColumns}
            page={partsPageno}
            autoHeight
            pageSize={partsPageSize}
            sx={GRID_STYLE}
            onPageChange={(newPage) => setPartsPageno(newPage)}
            onPageSizeChange={(newPageSize) => setPartsPageSize(newPageSize)}
            rowCount={partsOrderRecordCount}
            disableRowSelectionOnClick
            // getRowId={(row) => row.partsOrderId}
            getRowId={(row) => row.id}
            pagination
            rowsPerPageOptions={[5, 10, 15]}
          />
        </div>
      </div>

      {openInvoiceModal && (
        <InVoiceGenerateModal
          show={openInvoiceModal}
          hideModal={() => setOpenInvoiceModal(false)}
          handleSnack={handleSnack}
          recordId={recordId}
          rowData={selectedRow}
          currencyFormatter={currencyFormatter}
        />
      )}
    </>
  );
};

export default InvoiceMaster;
