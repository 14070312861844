import React from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Box, Typography, Chip, Avatar, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";
import { GRID_STYLE } from "../CONSTANTS";

const rows = [
    {
        id: 1,
        order: "12345",
        segment: {
            title: "Orley Web Design",
            description: "Hero 8",
            icon: "https://via.placeholder.com/50", // Placeholder image
        },
        duration: "01:57:34",
        date: "16-05-2023",
        category: {
            label: "Website",
            color: "#DFF4E3",
            textColor: "#2E7D32",
        },
    },
    {
        id: 2,
        order: "12346",
        segment: {
            title: "Research Plan",
            description: "The Core",
            icon: "https://via.placeholder.com/50", // Placeholder image
        },
        duration: "04:44:04",
        date: "15-05-2023",
        category: {
            label: "UX Research",
            color: "#F3E5F5",
            textColor: "#6A1B9A",
        },
    },
    {
        id: 3,
        order: "12347",
        segment: {
            title: "Sketching",
            description: "Antrian",
            icon: "https://via.placeholder.com/50", // Placeholder image
        },
        duration: "03:22:32",
        date: "14-05-2023",
        category: {
            label: "Illustration",
            color: "#E0F7FA",
            textColor: "#00796B",
        },
    },
];

const ShopRepairTimeReportModal = ({ show, hideModal, handleSnack }) => {
    const columns = [
        {
            field: "order",
            headerName: "Order #",
            width: 120,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "segment",
            headerName: "Segment & Operation Description",
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Avatar src={params.row.segment.icon} alt={params.row.segment.title} />
                    <Box>
                        <Typography variant="body1" fontWeight="bold">
                            {params.row.segment.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {params.row.segment.description}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            field: "duration",
            headerName: "Duration",
            width: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "category",
            headerName: "Categories",
            width: 200,
            renderCell: (params) => (
                <Chip
                    label={params.row.category.label}
                    sx={{
                        backgroundColor: params.row.category.color,
                        color: params.row.category.textColor,
                        fontWeight: "bold",
                    }}
                />
            ),
        },
        {
            field: "actions",
            headerName: "",
            width: 50,
            renderCell: () => (
                <IconButton>
                    <MoreVertIcon />
                </IconButton>
            ),
        },
    ];
    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center  mb-2">
                        <h6 className="font-size-18 font-weight-500">Time Report</h6>
                        <div className="border rounded-pill px-3 py-1 font-weight-500">
                            This Week: 31:12:12
                            <ArrowDropDownIcon sx={{ fontSize: 32 }} />
                        </div>
                    </div>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        hideFooterPagination
                        sx={GRID_STYLE}
                        autoHeight
                        // sx={{
                        //     "& .MuiDataGrid-columnHeaders": { backgroundColor: "#F5F5F5", fontWeight: "bold", fontSize: "16px" },
                        //     "& .MuiDataGrid-cell": { borderBottom: "1px solid #E0E0E0" },
                        // }}
                    />

                    <div className="row px-3 mt-2" style={{ justifyContent: "right" }}>
                        <button className="btn bg-primary text-white" onClick={hideModal}>
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShopRepairTimeReportModal;
