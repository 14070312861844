import React, { useState } from "react";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormGroup, Switch, FormControlLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";
import Moment from "react-moment";
import { FileUploader } from "react-drag-drop-files";

import { isEmpty } from "pages/Common/textUtilities";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { CLAIM_ORDER_MASTER_URL } from "services/CONSTANTS";
import { callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { customerSearch, machineSearch } from "services/searchServices";
import SearchBox from "pages/Common/SearchBox";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { faFileAlt, faFolderPlus, faPlus, faShareAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, LinearProgress } from "@mui/material";
import ServiceReportModal from "./ServiceReportModal";

const ClaimOrderDetails = (props) => {
  const {
    claimOrderId,
    returnDetailsTab,
    setReturnDetailsTab,
    viewOnlyTab,
    generalData,
    setGeneralData,
    reference,
    setReference,
    estimationData,
    setEstimationData,
    customerData,
    setCustomerData,
    machineData,
    setMachineData,
    yearlyWarrantyData,
    setYearlyWarrantyData,
    warrantyBasisOptions = [],
    handleSnack,
    claimStatus,
    handleAddUpdateClaimOrder,
    warrantyId,
    authorizationCode,
    getSelectEquReplacementCode,
  } = props;

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [hasReport, setHasReport] = useState(false);
  const [message, setMessage] = useState(""); // State for input message
  const [submittedMessage, setSubmittedMessage] = useState("Do you have any questions or additional notes about this claim? Write it here");
  const [reportData, setReportData] = useState({
    reportnumber: "",
    reportdescription: "Breakdown",
    ronumber: "RO1234",
    repairdate: "10.01.2022",
    smu: "4250 Hours",
    machineslno: "U654",
    componentno: "KTA38C",
    composelno: "25461036",
    customercmplt: "Noise coming out",
    customerremark: "Noise coming out",
    engremark: "Noise coming out",
    custaddr: "amohanty",
    email: "Johndoe@gmail.com",
  });

  const handleSubmitMessage = () => {
    if (message.trim()) {
      setSubmittedMessage(message); // Update submitted message
      setMessage(""); // Clear input field
    }
  };

  // Function to update the report number when the modal's submit button is clicked
  const handleReportSubmit = (newReportNumber) => {
    setReportData(newReportNumber);
    setHasReport(true);
    setOpenServiceModal(false);
  };

  // Individual genral tab >> request details select fields change
  const handleGeneralSelectFieldChange = (e, keyName) => {
    setGeneralData({ ...generalData, [keyName]: e });
  };

  // Individual genral tab >> request details field value change
  const handleGeneralInputFieldChange = (e) => {
    const { name, value } = e.target;
    setGeneralData({ ...generalData, [name]: value });
  };

  //Individual estimation details  >> request details field value change
  const handleEstimationDataChange = (e) => {
    const { name, value } = e.target;
    setEstimationData({ ...estimationData, [name]: value });
  };

  // open service modal
  const handleOpenServiceReportModal = () => {
    // setViewMode(isViewMode);
    setOpenServiceModal(true);
  };

  //Individual customer field value change
  const handleCustomerDataChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    customerData.customerID = searchText;
    customerData.customerName = "";
    customerData.contactEmail = "";
    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    setCustomerData({
      ...customerData,
      customerID: currentItem.customerId,
      customerName: currentItem.fullName,
      contactEmail: currentItem.email,
    });
    setSearchCustResults([]);
  };

  //Individual machine field value change
  const handleMachineDataChange = (e) => {
    const { name, value } = e.target;
    setMachineData({ ...machineData, [name]: value });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} %26%26 makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({
        ...machineData,
        model: currentItem.model,
      });
      //   setPvPanelReqObj((prev) => ({ ...prev, pvPanelModel: currentItem.model }));
      //   setPvInversterReqObj((prev) => ({ ...prev }));
      //   setPvOptimiserReqObj((prev) => ({ ...prev }));
      //   setPvBatteryReqObj((prev) => ({ ...prev }));
      //   setPvOtherReqObj((prev) => ({ ...prev }));
      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setMachineData({
        ...machineData,
        make: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });

      if (currentItem?.replacedEquipmentNumber) {
        getSelectEquReplacementCode(currentItem?.replacedEquipmentNumber);
      }

      setCustomerData({
        ...customerData,
        customerID: currentItem.currentClient,
        customerName: currentItem.customer,
      });
      setSearchSerialResults([]);
    }
  };

  //Individual warranty field value change
  const handleWarrantyDataChange = (e) => {
    const { name, value } = e.target;
    setYearlyWarrantyData({ ...yearlyWarrantyData, [name]: value });
  };

  //Individual warranty field Select value change
  const handleWarrantySelectChange = (e, keyName) => {
    let _warrantyStartDate = yearlyWarrantyData?.warrantyStartDate;
    let _warrantyEndDate = yearlyWarrantyData?.warrantyEndDate;
    let _warrantyStartUsage = yearlyWarrantyData?.warrantyStartUsage;
    let _warrantyEndUsage = yearlyWarrantyData?.warrantyEndUsage;
    if (keyName === "basis") {
      if (e.value === "TIME") {
        _warrantyStartUsage = "";
        _warrantyEndUsage = "";
      } else if (e.value === "USAGE") {
        _warrantyStartDate = "";
        _warrantyEndDate = "";
      }
      setYearlyWarrantyData({
        ...yearlyWarrantyData,
        [keyName]: e,
        warrantyStartUsage: _warrantyStartUsage || "",
        warrantyEndUsage: _warrantyEndUsage || "",
        warrantyStartDate: _warrantyStartDate || "",
        warrantyEndDate: _warrantyEndDate || "",
      });
    } else {
      setYearlyWarrantyData({ ...yearlyWarrantyData, [keyName]: e });
    }
  };

  // view general tab details
  const viewGeneralDetails = () => {
    return (
      <>
        {!viewOnlyTab.generalViewOnly ? (
          <>
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY ID</label>
                  <input type="text" disabled className="form-control border-radius-10 text-primary" id="warrantyId" value={warrantyId} />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM REQUEST ID</label>
                  <input
                    type="text"
                    disabled
                    className="form-control border-radius-10 text-primary"
                    id="warrantyRequestId"
                    value={claimOrderId}
                    // value={claimRecordDetail?.claimNumber}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="description"
                    maxLength={140}
                    value={generalData.description}
                    onChange={handleGeneralInputFieldChange}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="reference"
                    // maxLength={140}
                    // value={generalData.reference}
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                    // onChange={handleGeneralInputFieldChange}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">
                    <span className=" mr-2">WARRANTY REQUEST DATE</span>
                  </label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        minDate={generalData.claimRequestDate}
                        maxDate={new Date()}
                        closeOnSelect
                        value={generalData.claimRequestDate}
                        onChange={(e) =>
                          setGeneralData({
                            ...generalData,
                            claimRequestDate: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZATION CODE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="reference"
                    // maxLength={140}
                    value={authorizationCode}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PREPARED BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={estimationData.preparedBy}
                    name="preparedBy"
                    onChange={handleEstimationDataChange}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PREPARED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // minDate={
                        //   estimationData.preparedOn
                        // }
                        // maxDate={new Date()}
                        closeOnSelect
                        value={estimationData.preparedOn}
                        onChange={(e) =>
                          setEstimationData({
                            ...estimationData,
                            preparedOn: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REVISED BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={estimationData.revisedBy}
                    name="revisedBy"
                    onChange={handleEstimationDataChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REVISED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        minDate={estimationData.revisedOn}
                        maxDate={new Date()}
                        closeOnSelect
                        value={estimationData.revisedOn}
                        onChange={(e) =>
                          setEstimationData({
                            ...estimationData,
                            revisedOn: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">
                                        WARRANTY REQUEST STATUS
                                    </label>
                                    <Select
                                        // defaultValue={selectedOption}
                                        onChange={(e) => {
                                            setGeneralData({
                                                ...generalData,
                                                warrantyClaimStatus: e,
                                            });
                                            setClaimStatus(e);
                                        }}
                                        options={claimStatusOptions}
                                        // value={
                                        //     generalData.warrantyClaimStatus
                                        // }
                                        value={claimStatus}
                                        styles={FONT_STYLE_SELECT}
                                    />
                                </div>
                            </div> */}
            </div>
            <div
              className="row"
              style={{
                justifyContent: "right",
              }}
            >
              <button
                type="button"
                className="btn bg-primary text-white"
                disabled={
                  !generalData.description || !reference
                  // !generalData.reference
                }
                id="general"
                onClick={handleAddUpdateClaimOrder}
              >
                Save & Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-3">
              <ReadOnlyField label="WARRANTY ID" value={warrantyId} className="col-md-4 col-sm-4" />
              <ReadOnlyField
                label="CLAIM REQUEST ID"
                // label="WARRANTY REQUEST ID"
                // value={generalData.estimationNo}
                value={claimOrderId}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField label="DESCRIPTION" value={generalData.description} className="col-md-4 col-sm-4" />
              <ReadOnlyField
                label="REFERENCE"
                value={reference}
                // value={generalData.reference}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField
                label="WARRANTY REQUEST DATE"
                // value={
                //     "NA"
                // }
                value={<Moment format="DD/MM/YYYY">{generalData.claimRequestDate}</Moment>}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField label="AUTHORIZATION CODE" value={authorizationCode} className="col-md-4 col-sm-4" />
              {/* <ReadOnlyField
                            label="WARRANTY REQUEST STATUS"
                            // value={
                            //     generalData
                            //         .warrantyClaimStatus
                            //         ?.label
                            // }
                            value={claimStatus?.label}
                            className="col-md-4 col-sm-4"
                        /> */}
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button type="button" className="btn bg-primary text-white" id="general" onClick={handleAddUpdateClaimOrder}>
                Next
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  // view estimation tab details
  const viewEstimationDetails = () => {
    return (
      <>
        {!viewOnlyTab.estViewOnly ? (
          <>
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PREPARED BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={estimationData.preparedBy}
                    name="preparedBy"
                    onChange={handleEstimationDataChange}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <label className="text-light-dark font-size-12 font-weight-500">
                                              APPROVED BY
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control border-radius-10 text-primary"
                                              value={estimationData.approvedBy}
                                              name="approvedBy"
                                              onChange={handleEstimationDataChange}
                                            />
                                          </div>
                                        </div> */}
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">PREPARED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // minDate={
                        //   estimationData.preparedOn
                        // }
                        // maxDate={new Date()}
                        closeOnSelect
                        value={estimationData.preparedOn}
                        onChange={(e) =>
                          setEstimationData({
                            ...estimationData,
                            preparedOn: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REVISED BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={estimationData.revisedBy}
                    name="revisedBy"
                    onChange={handleEstimationDataChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REVISED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        minDate={estimationData.revisedOn}
                        maxDate={new Date()}
                        closeOnSelect
                        value={estimationData.revisedOn}
                        onChange={(e) =>
                          setEstimationData({
                            ...estimationData,
                            revisedOn: e,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              style: FONT_STYLE,
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button
                type="button"
                className="btn bg-primary text-white"
                disabled={!estimationData.preparedBy || !estimationData.preparedOn}
                id="estimate"
                onClick={handleAddUpdateClaimOrder}
              >
                Save & Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-3">
              <ReadOnlyField label="PREPARED BY" value={estimationData.preparedBy} className="col-md-4 col-sm-4" />
              <ReadOnlyField
                label="PREPARED ON"
                value={<Moment format="DD/MM/YYYY">{estimationData.preparedOn}</Moment>}
                className="col-md-4 col-sm-4"
              />
              <ReadOnlyField label="REVISED BY" value={estimationData.revisedBy} className="col-md-4 col-sm-4" />
              <ReadOnlyField
                label="REVISED ON"
                value={<Moment format="DD/MM/YYYY">{estimationData.revisedOn}</Moment>}
                className="col-md-4 col-sm-4"
              />
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button type="button" className="btn bg-primary text-white" id="general" onClick={handleAddUpdateClaimOrder}>
                Next
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  // view warranty tab details
  const viewWarrantyDetails = () => {
    return (
      <>
        {!viewOnlyTab.warrantyViewOnly ? (
          <>
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">YEAR</label>
                  <Select
                    className="text-primary"
                    name="year"
                    placeholder="Year 1"
                    // options={serviceBranchOptions}
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
            </div>
            <div className="text-light-dark font-size-14 font-weight-500 my-2">USAGE</div>
            <div className="row input-fields">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">START USAGE</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-right-0 rounded-left text-primary"
                      placeholder="10,000 hours"
                      name="startUsage"
                    />
                    <span className="input-group-text border-start-0  rounded-top-left-0 rounded-bottom-left-0 ">Hours</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">END USAGE</label>
                  <div className="input-group">
                    <input type="text" className="form-control border-right-0 rounded-left text-primary" placeholder="16,000 hours" name="endUsage" />
                    <span className="input-group-text border-start-0  rounded-top-left-0 rounded-bottom-left-0 ">Hours</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> USAGE TYPE</label>
                  <Select
                    className="text-primary"
                    name="year"
                    placeholder="Planned Usage"
                    // options={serviceBranchOptions}
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
            </div>
            <div className="text-light-dark font-size-14 font-weight-500 my-2">QUANTITY</div>
            <div className="row input-fields">
              <div className="col-md-5 col-sm-5">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> FREQUENCY</label>
                  <Select
                    className="text-primary"
                    name="frequency"
                    placeholder="Cyclical"
                    // options={serviceBranchOptions}
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
              <div className="col-md-5 col-sm-5">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">NUMBER OF EVENTS</label>
                  <div className="input-group">
                    <input type="text" className="form-control border-right-0 rounded-left text-primary" placeholder="250" name="noofevent" />
                    <span className="input-group-text border-start-0  rounded-top-left-0 rounded-bottom-left-0 ">Per Year</span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-2">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginLeft: 0,
                      }}
                      control={<Switch />}
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">SUPPRESSION</span>}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-3">
              <ReadOnlyField label="YEAR" value="Year 1" className="col-md-6 col-sm-6" />
            </div>
            <div className="text-light-dark font-size-14 font-weight-500 my-2">USAGE</div>
            <div className="row input-fields">
              <ReadOnlyField label="START USAGE" value="10,000 hours" className="col-md-4 col-sm-4" />
              <ReadOnlyField label="END USAGE" value="16,000 hours" className="col-md-4 col-sm-4" />
              <ReadOnlyField label="USAGE TYPE" value="Planned Usage" className="col-md-4 col-sm-4" />
            </div>
            <div className="text-light-dark font-size-14 font-weight-500 my-2">QUANTITY</div>
            <div className="row input-fields">
              <ReadOnlyField label="FREQUENCY" value="Cyclical" className="col-md-5 col-sm-5" />
              <ReadOnlyField label="NUMBER OF EVENTS" value="250" className="col-md-5 col-sm-5" />
              <div className="col-md-2 col-sm-2">
                <div className="form-group">
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        alignItems: "start",
                        marginLeft: 0,
                      }}
                      control={<Switch />}
                      labelPlacement="top"
                      label={<span className="text-light-dark font-size-12 font-weight-500">SUPPRESSION</span>}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  // view estimation tab details
  // const viewEstimationDetails = () => {
  //   return (
  //     <>
  //       {!viewOnlyTab.estViewOnly ? (
  //         <>
  //           <div className="row input-fields">
  //             <div className="col-md-6 col-sm-6">
  //               <div className="form-group">
  //                 <label className="text-light-dark font-size-12 font-weight-500">PREPARED BY</label>
  //                 <input
  //                   type="text"
  //                   className="form-control border-radius-10 text-primary"
  //                   value={estimationData.preparedBy}
  //                   name="preparedBy"
  //                   onChange={handleEstimationDataChange}
  //                 />
  //                 <div className="css-w8dmq8">*Mandatory</div>
  //               </div>
  //             </div>
  //             {/* <div className="col-md-6 col-sm-6">
  //                                         <div className="form-group">
  //                                           <label className="text-light-dark font-size-12 font-weight-500">
  //                                             APPROVED BY
  //                                           </label>
  //                                           <input
  //                                             type="text"
  //                                             className="form-control border-radius-10 text-primary"
  //                                             value={estimationData.approvedBy}
  //                                             name="approvedBy"
  //                                             onChange={handleEstimationDataChange}
  //                                           />
  //                                         </div>
  //                                       </div> */}
  //             <div className="col-md-6 col-sm-6">
  //               <div className="form-group">
  //                 <label className="text-light-dark font-size-12 font-weight-500">PREPARED ON</label>
  //                 <div className="align-items-center date-box">
  //                   <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                     <MobileDatePicker
  //                       inputFormat="dd/MM/yyyy"
  //                       className="form-controldate border-radius-10"
  //                       // minDate={
  //                       //   estimationData.preparedOn
  //                       // }
  //                       // maxDate={new Date()}
  //                       closeOnSelect
  //                       value={estimationData.preparedOn}
  //                       onChange={(e) =>
  //                         setEstimationData({
  //                           ...estimationData,
  //                           preparedOn: e,
  //                         })
  //                       }
  //                       renderInput={(params) => (
  //                         <TextField
  //                           {...params}
  //                           variant="standard"
  //                           inputProps={{
  //                             ...params.inputProps,
  //                             style: FONT_STYLE,
  //                           }}
  //                         />
  //                       )}
  //                     />
  //                   </LocalizationProvider>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="col-md-6 col-sm-6">
  //               <div className="form-group">
  //                 <label className="text-light-dark font-size-12 font-weight-500">REVISED BY</label>
  //                 <input
  //                   type="text"
  //                   className="form-control border-radius-10 text-primary"
  //                   value={estimationData.revisedBy}
  //                   name="revisedBy"
  //                   onChange={handleEstimationDataChange}
  //                 />
  //               </div>
  //             </div>
  //             <div className="col-md-6 col-sm-6">
  //               <div className="form-group">
  //                 <label className="text-light-dark font-size-12 font-weight-500">REVISED ON</label>
  //                 <div className="align-items-center date-box">
  //                   <LocalizationProvider dateAdapter={AdapterDateFns}>
  //                     <MobileDatePicker
  //                       inputFormat="dd/MM/yyyy"
  //                       className="form-controldate border-radius-10"
  //                       minDate={estimationData.revisedOn}
  //                       maxDate={new Date()}
  //                       closeOnSelect
  //                       value={estimationData.revisedOn}
  //                       onChange={(e) =>
  //                         setEstimationData({
  //                           ...estimationData,
  //                           revisedOn: e,
  //                         })
  //                       }
  //                       renderInput={(params) => (
  //                         <TextField
  //                           {...params}
  //                           variant="standard"
  //                           inputProps={{
  //                             ...params.inputProps,
  //                             style: FONT_STYLE,
  //                           }}
  //                         />
  //                       )}
  //                     />
  //                   </LocalizationProvider>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div
  //             className="row"
  //             style={{
  //               justifyContent: "right",
  //             }}
  //           >
  //             <button
  //               type="button"
  //               className="btn bg-primary text-white"
  //               disabled={!estimationData.preparedBy || !estimationData.preparedOn}
  //               id="estimate"
  //               onClick={handleAddUpdateClaimOrder}
  //             >
  //               Save & Next
  //             </button>
  //           </div>
  //         </>
  //       ) : (
  //         <>
  //           <div className="row mt-3">
  //             <ReadOnlyField label="PREPARED BY" value={estimationData.preparedBy} className="col-md-4 col-sm-4" />
  //             {/* <ReadOnlyField
  //                                       label="APPROVED BY"
  //                                       value={estimationData.approvedBy}
  //                                       className="col-md-4 col-sm-4"
  //                                     /> */}

  //             <ReadOnlyField
  //               label="PREPARED ON"
  //               value={<Moment format="DD/MM/YYYY">{estimationData.preparedOn}</Moment>}
  //               className="col-md-4 col-sm-4"
  //             />
  //             <ReadOnlyField label="REVISED BY" value={estimationData.revisedBy} className="col-md-4 col-sm-4" />
  //             <ReadOnlyField
  //               label="REVISED ON"
  //               value={<Moment format="DD/MM/YYYY">{estimationData.revisedOn}</Moment>}
  //               className="col-md-4 col-sm-4"
  //             />
  //           </div>
  //           <div className="row" style={{ justifyContent: "right" }}>
  //             <button type="button" className="btn bg-primary text-white" id="estimation" onClick={handleAddUpdateClaimOrder}>
  //               Next
  //             </button>
  //           </div>
  //         </>
  //       )}
  //     </>
  //   );
  // };

  const viewActionNeededDetails = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="card border card-box-shadow   ">
              <div className="row">
                <div className="col-md-12 col-sm-12 position-relative">
                  <div className="d-flex align-items-center py-2 repair-support-chat-header px-3 py-3">
                    <div className="repair-support-chat-header-profile" style={{ position: "relative" }}>
                      <img src={`../../../assets/images/users/8.jpg`} alt="Profile" />
                      <div className="online"></div>
                    </div>
                    <div className="ml-2">
                      <p className="m-0 font-size-14 font-weight-500 text-black">SUPPORT CHAT</p>
                    </div>
                  </div>
                  <p className="font-size-14 font-weight-400 bg-light p-3 mx-2  rounded" style={{ position: "relative" }}>
                    {submittedMessage}
                  </p>
                  <div className=" d-flex justify-content-between align-items-center font-size-14 font-weight-400 bg-light p-2  mt-5 rounded ">
                    <input
                      type="text"
                      placeholder="Type your message here.."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control border-0 bg-light"
                      style={{ flex: 1, marginRight: "10px" }}
                    />
                    <span onClick={handleSubmitMessage}>
                      <ArrowCircleUpIcon style={{ fontSize: "2rem" }} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card border card-box-shadow px-3 py-2">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="repair-builder-notes-container">
                    <div className="d-flex align-items-center repair-builder-notes-header" style={{ marginBottom: "8px" }}>
                      <DescriptionIcon />
                      <p className="mb-0 font-size-14 font-weight-500" style={{ color: "#5C6A82" }}>
                        NOTES
                      </p>
                    </div>
                    <textarea placeholder="You can write any notes here." cols={5} rows={8}></textarea>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="card border card-box-shadow px-3 py-2  h-75">
              <div className="repair-builder-upload ">
                <h6 className="text-center font-weight-500 my-3">Upload service report</h6>
                <FileUploader name="file" types={["JPG", "PNG"]} />
                <br /> or
                <div className="col-md-12">
                  <div className="recent-items mt-3">
                    <div className="d-flex justify-content-between align-items-center ">
                      <p className="mb-0 overflow-hidden white-space">
                        <FontAwesomeIcon className=" font-size-14" icon={faFileAlt} />
                        <span className="font-weight-500 ml-2">{reportData.reportnumber ? reportData.reportnumber : "Add Service Report"} </span>
                      </p>
                      <div className="d-flex align-items-center ">
                        {/* Conditional rendering based on whether a report exists */}
                        {!hasReport ? (
                          <a
                            href="#"
                            className="d-flex align-items-center justify-content-center border border-primary rounded p-1"
                            style={{
                              width: "150px",
                              height: "30px",
                            }}
                            onClick={handleOpenServiceReportModal}
                          >
                            <span className=" font-weight-500">Add Service Report</span>
                            <ControlPointOutlinedIcon style={{ fontSize: "20px", color: "#007bff", marginLeft: 6 }} />
                          </a>
                        ) : (
                          <a
                            href="#"
                            className="d-flex align-items-center justify-content-center border border-primary rounded p-1"
                            style={{
                              width: "150px",
                              height: "30px",
                            }}
                            onClick={() => handleOpenServiceReportModal(true)}
                          >
                            <span className="font-weight-500">View Service Report</span>
                            <VisibilityIcon style={{ fontSize: "20px", color: "#007bff", marginLeft: 6 }} />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  // view customer tab details
  const viewCustomerDetails = () => {
    return (
      <>
        {!viewOnlyTab.custViewOnly ? (
          <>
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                  <SearchBox
                    value={customerData.customerID}
                    onChange={(e) => handleCustSearch("customerId", e.target.value)}
                    type="customerId"
                    result={searchCustResults}
                    onSelect={handleCustSelect}
                    noOptions={noOptionsCust}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                  <input
                    type="text"
                    value={customerData.customerName}
                    name="customerName"
                    onChange={handleCustomerDataChange}
                    className="form-control border-radius-10 text-primary"
                    id="customerNameid"
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CONTACT EMAIL</label>
                  <input
                    type="email"
                    value={customerData.contactEmail}
                    name="contactEmail"
                    onChange={handleCustomerDataChange}
                    className="form-control border-radius-10 text-primary"
                    id="contatEmail"
                    aria-describedby="emailHelp"
                  />
                  {/* <div className="css-w8dmq8">*Mandatory</div> */}
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">CONTACT PHONE</label>
                  <input
                    type="tel"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleCustomerDataChange}
                    value={customerData.contactPhone}
                    name="contactPhone"
                  />
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                justifyContent: "right",
              }}
            >
              <button
                type="button"
                className="btn bg-primary text-white"
                // disabled={
                //     customerData.contactEmail &&
                //     (noOptionsCust ||
                //         searchCustResults.length !== 0)
                // }
                id="customer"
                onClick={handleAddUpdateClaimOrder}
              >
                Save & Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-3">
              <ReadOnlyField label="CUSTOMER ID" value={customerData.customerID} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="CUSTOMER NAME" value={customerData.customerName} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="CUSTOMER EMAIL" value={customerData.contactEmail} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="CONTACT PHONE" value={customerData.contactPhone} className="col-md-4 col-sm-4" />
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button type="button" className="btn bg-primary text-white" id="customer" onClick={handleAddUpdateClaimOrder}>
                Next
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  // view Machine tab details
  const viewMachineDetails = () => {
    return (
      <>
        {!viewOnlyTab.machineViewOnly ? (
          <>
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="make-id"
                    name="make"
                    value={machineData.make}
                    onChange={handleMachineDataChange}
                    placeholder="Auto Filled"
                    disabled
                  />
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-6">
                                          <div className="form-group">
                                            <label className="text-light-dark font-size-12 font-weight-500">
                                              FAMILY
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control border-radius-10 text-primary"
                                              id="family-id"
                                              name="family"
                                              value={machineData.family}
                                              onChange={handleMachineDataChange}
                                              placeholder="Auto Filled"
                                              disabled
                                            />
                                          </div>
                                        </div> */}
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                  <SearchBox
                    value={machineData.model}
                    onChange={(e) => handleMachineSearch("model", e.target.value)}
                    type="model"
                    result={searchModelResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsModel}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL #</label>
                  <SearchBox
                    value={machineData.serialNo}
                    onChange={(e) => handleMachineSearch("serialNo", e.target.value)}
                    type="makerSerialNumber"
                    result={searchSerialResults}
                    onSelect={handleModelSelect}
                    noOptions={noOptionsSerial}
                  />
                  <div className="css-w8dmq8">*Mandatory</div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SMU (Service Meter Unit)</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    id="smu-id"
                    name="smu"
                    value={machineData.smu}
                    onChange={handleMachineDataChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">UNIT NO / FLEET NO</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    onChange={handleMachineDataChange}
                    value={machineData.fleetNo}
                    name="fleetNo"
                    id="fleet-id"
                  />
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                justifyContent: "right",
              }}
            >
              <button
                type="button"
                className="btn bg-primary text-white"
                disabled={!(machineData.model && machineData.serialNo) || noOptionsModel || noOptionsSerial}
                id="machine"
                onClick={handleAddUpdateClaimOrder}
              >
                Save & Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-3">
              <ReadOnlyField label="MAKE" value={machineData.make} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="MODEL" value={machineData.model} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="SERIAL NO" value={machineData.serialNo} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="SMU (Service Meter Unit)" value={machineData.smu} className="col-md-4 col-sm-4" />
              <ReadOnlyField label="UNIT NO / FLEET NO" value={machineData.fleetNo} className="col-md-4 col-sm-4" />
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button type="button" className="btn bg-primary text-white" id="machine" onClick={handleAddUpdateClaimOrder}>
                Next
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  // view Waranty tab details
  const viewWarantyDetails = () => {
    return (
      <>
        <div className="row input-fields">
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">YEAR</label>
              <Select
                value={yearlyWarrantyData.year}
                options={[{ label: "Year 1", value: "1" }]}
                styles={FONT_STYLE_SELECT}
                onChange={(e) => setYearlyWarrantyData({ ...yearlyWarrantyData, year: e })}
              />
            </div>
          </div>
        </div>
        <span className="font-size-14 font-weight-500">USAGE</span>
        <div className="row input-fields mt-2">
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">USAGE TYPE</label>
              <Select
                options={warrantyBasisOptions}
                styles={FONT_STYLE_SELECT}
                value={yearlyWarrantyData.basis}
                onChange={(e) => handleWarrantySelectChange(e, "basis")}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">START USAGE</label>
              <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                <input
                  type="number"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="Start Usage"
                  name="warrantyStartUsage"
                  value={yearlyWarrantyData.warrantyStartUsage}
                  onChange={handleWarrantyDataChange}
                  disabled={yearlyWarrantyData.basis?.value === "TIME"}
                />
                <span className="hours-div font-weight-500">Hours</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">END USAGE</label>
              <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                <input
                  type="number"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="End Usage"
                  name="warrantyEndUsage"
                  value={yearlyWarrantyData.warrantyEndUsage}
                  onChange={handleWarrantyDataChange}
                  disabled={yearlyWarrantyData.basis?.value === "TIME"}
                />
                <span className="hours-div font-weight-500">Hours</span>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">START DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    // minDate={estimationData.revisedOn}
                    // maxDate={new Date()}
                    closeOnSelect
                    value={yearlyWarrantyData.warrantyStartDate}
                    onChange={(e) => handleWarrantySelectChange(e, "warrantyStartDate")}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    disabled={yearlyWarrantyData.basis?.value === "USAGE"}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">END DATE</label>
              <div className="align-items-center date-box">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="dd/MM/yyyy"
                    className="form-controldate border-radius-10"
                    // minDate={estimationData.revisedOn}
                    // maxDate={new Date()}
                    closeOnSelect
                    value={yearlyWarrantyData.warrantyEndDate}
                    onChange={(e) => handleWarrantySelectChange(e, "warrantyEndDate")}
                    renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    disabled={yearlyWarrantyData.basis?.value === "USAGE"}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div class="form-group mt-1">
              <FormGroup>
                <FormControlLabel
                  style={{ alignItems: "start", marginLeft: 0 }}
                  control={
                    <Switch
                    // checked={extWorkData.flatRateIndicator}
                    />
                  }
                  labelPlacement="top"
                  label={<span className="text-light-dark font-size-12 font-weight-500">SUPRESSION</span>}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        {/* <span className="font-size-14 font-weight-500">QUANTITY</span>
        <div className="row input-fields mt-2">
          <div className="col-md-5 col-sm-5">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">FREQUENCY</label>
              <Select options={[]} styles={FONT_STYLE_SELECT} />
            </div>
          </div>
          <div className="col-md-5 col-sm-5">
            <div className="form-group">
              <label className="text-light-dark font-size-12 font-weight-500">NUMBER OF EVENTS</label>
              <div className=" d-flex form-control-date" style={{ overflow: "hidden" }}>
                <input
                  type="number"
                  className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                  placeholder="No. of Events"
                  name="endUsage"
                  // value={itemPriceRequestObj.startUsage}
                  // onChange={handlePriceInputChange}
                />
                <span className="hours-div font-weight-500">Per Year</span>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-2">
            <div class="form-group mt-1">
              <FormGroup>
                <FormControlLabel
                  style={{ alignItems: "start", marginLeft: 0 }}
                  control={
                    <Switch
                    // checked={extWorkData.flatRateIndicator}
                    />
                  }
                  labelPlacement="top"
                  label={<span className="text-light-dark font-size-12 font-weight-500">SUPRESSION</span>}
                />
              </FormGroup>
            </div>
          </div>
        </div> */}
      </>
    );
  };

  return (
    <div className={`card border`}>
      <Box className="mt-0" sx={{ width: "100%", typography: "body1", backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}>
        <TabContext value={returnDetailsTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#f8f8f8" }}>
            <TabList className="custom-tabs-div" sx={{ paddingX: 2 }} onChange={(e, value) => setReturnDetailsTab(value)}>
              <Tab label="MACHINE" value="machine" />

              {/* <Tab label="WARRANTY" value="warrranty" />
              <Tab label="ACTIONS NEEDED" value="actionsNeeded" />
              <Tab label="APPROVAL STATUS" value="approvalStatus" /> */}

              <Tab label="WARRANTY" value="warranty" />
              <Tab label="GENERAL DETAILS" value="general" />
              {/* <Tab label="ESTIMATION DETAILS" value="estimation" /> */}
              <Tab label="CUSTOMER" value="customer" />
              <Tab label="ACTIONS NEEDED" value="actionsneeded" />
            </TabList>
          </Box>
          <TabPanel value={returnDetailsTab}>
            {returnDetailsTab === "machine" && viewMachineDetails()}

            {/* {returnDetailsTab === "warrranty" && viewWarantyDetails()} */}

            {returnDetailsTab === "warranty" && viewWarrantyDetails()}
            {returnDetailsTab === "general" && viewGeneralDetails()}
            {/* {returnDetailsTab === "estimation" && viewEstimationDetails()} */}
            {returnDetailsTab === "customer" && viewCustomerDetails()}
            {returnDetailsTab === "actionsneeded" && viewActionNeededDetails()}
          </TabPanel>
        </TabContext>
      </Box>
      {openServiceModal && (
        <ServiceReportModal
          show={openServiceModal}
          hideModal={() => setOpenServiceModal(false)}
          onSubmit={handleReportSubmit}
          viewMode={hasReport}
          reportData={reportData}
        />
      )}
    </div>
  );
};

export default ClaimOrderDetails;
