import React, { useEffect, useState } from "react";

import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

import { Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Moment from "react-moment";

import { GRID_STYLE } from "pages/Common/constants";

import AssignWorkflow from "./AssignWorkflow";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";

import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { PARTS_ORDER_MASTER_URL } from "services/CONSTANTS";

const assignOrders = [
    {
        id: 1,
        orderDescription: "Sample Order",
        orderId: "12345",
        serialNo: "ABC123",
        model: "992K",
        hours: 12,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "NEW",
    },
    {
        id: 2,
        orderDescription: "Sample Order",
        orderId: "12346",
        serialNo: "ABC123",
        model: "992K",
        hours: 10,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "ASSIGNED",
    },
    {
        id: 3,
        orderDescription: "Sample Order",
        orderId: "12347",
        serialNo: "ABC123",
        model: "992K",
        hours: 10,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "ACCEPTED",
    },
    {
        id: 4,
        orderDescription: "Sample Order",
        orderId: "12347",
        serialNo: "ABC123",
        model: "992K",
        hours: 10,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "REJECTED",
    },
    {
        id: 5,
        orderDescription: "Sample Order",
        orderId: "12348",
        serialNo: "ABC123",
        model: "992K",
        hours: 10,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "NOT_STARTED",
    },
    {
        id: 6,
        orderDescription: "Sample Order",
        orderId: "12341",
        serialNo: "ABC123",
        model: "992K",
        hours: 10,
        updatedAt: new Date(),
        assignedAt: new Date(),
        status: "COMPLETED",
    },
];

const colorStatus = {
    NEW: "#872ff7",
    ASSIGNED: "#FFFF00",
    ACCEPTED: "#008000",
    REJECTED: "#FF0000",
    NOT_STARTED: "#808080",
    COMPLETED: "#90ee90",
};

const AssignmentMaster = () => {
    const [loading, setLoading] = useState(false);
    // const [records, setRecords] = useState([...assignOrders]);
    const [records, setRecords] = useState([]);
    const [openAssignWorkflow, setOpenAssignWorkflow] = useState(false);

    // Snack Bar State
    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    useEffect(() => {
        setLoading(true);
        const rUrl = `${PARTS_ORDER_MASTER_URL}?pageNumber=${0}&pageSize=${10}`;
        callGetApi(
            rUrl,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setRecords(responseData);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            },
            (error) => {
                setLoading(false);
            }
        );
    }, []);

    // assign the workflow
    const handleAssign = (row) => {
        setOpenAssignWorkflow(true);
    };

    const columns = [
        {
            field: "orderDescription",
            headerName: " Order Description",
            flex: 1,
            width: 100,
        },
        {
            field: "partOrderNumber",
            headerName: "Order ID",
            flex: 1,
            width: 100,
        },
        {
            field: "serialNumber",
            headerName: "Serial  Number",
            flex: 1,
            width: 100,
        },
        {
            field: "model",
            headerName: "Model",
            flex: 1,
            width: 100,
        },
        {
            field: "hours",
            headerName: "Hours",
            flex: 1,
            width: 100,
        },
        {
            field: "updatedAt",
            headerName: "Last Updated",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (row?.updatedAt ? <Moment format="DD/MM/YYYY">{row?.updatedAt}</Moment> : ""),
        },
        {
            field: "createdAt",
            headerName: "Assigned Date",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (row?.createdAt ? <Moment format="DD/MM/YYYY">{row?.createdAt}</Moment> : ""),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 100,
            renderCell: ({ row }) => (
                <div
                    className="d-flex justify-content-between align-items-center py-2"
                    style={{
                        fontSize: 15,
                        backgroundColor: colorStatus[row.status],
                        paddingInline: 5,
                        paddingBlock: 2,
                        borderRadius: 6,
                        textTransform: "capitalize",
                        // color: statusPointColor[row.status],
                    }}
                >
                    {/* <span
            style={{
              borderRadius: 10,
              content: '" "',
              display: "block",
              marginRight: 8,
              height: 10,
              width: 10,
              backgroundColor: statusPointColor[params.row.status],
            }}
          ></span> */}
                    {row.status}
                </div>
            ),
        },
        {
            field: "action",
            type: "actions",
            headerName: "Action",
            flex: 1,
            cellClassName: "actions",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <div className="cursor" onClick={() => handleAssign(row)}>
                                <Tooltip title="Assign">
                                    <AssignmentIndOutlinedIcon sx={{ fontSize: 35 }} />
                                </Tooltip>
                            </div>
                        }
                        label="Assign"
                        className="textPrimary"
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <>
            <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
            <div className="content-body" style={{ minHeight: "884px" }}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-baseline mt-4">
                        <h4 className="font-weight-600 mb-0">Assignments</h4>
                    </div>
                    <div className="mb-4 mt-2">
                        <DataGrid
                            loading={loading}
                            sx={GRID_STYLE}
                            getRowId={(row) => row.partsOrderId}
                            // page={pageNo + 1}
                            autoHeight
                            // pageSize={perPageSize}
                            // onPageChange={(newPage) => getStoRecords(newPage, perPageSize)}
                            // onPageSizeChange={(newPageSize) => getStoRecords(pageNo, newPageSize)}
                            rows={records}
                            columns={columns}
                            rowsPerPageOptions={[10, 20, 50]}
                            disableRowSelectionOnClick
                            isRowSelectable={() => false}
                            // paginationMode="server"
                            // rowCount={totalRows}
                        />
                    </div>
                </div>
            </div>

            {openAssignWorkflow && <AssignWorkflow show={openAssignWorkflow} hideModal={() => setOpenAssignWorkflow(false)} handleSnack={handleSnack} />}
        </>
    );
};

export default AssignmentMaster;
