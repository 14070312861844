import React, { useState } from "react";

import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { Stepper, Step, StepLabel } from "@mui/material";
import { Grid, Typography, Box, Tabs, Tab, Divider, Radio } from "@mui/material";

import Select from "react-select";

import SearchBox from "pages/Common/SearchBox";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import ServiceCallNewModal from "./ServiceCallNewModal";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";

const serviceCallSteps = ["Customer Information", "Machine Details", "General Information "];

const requesterOptions = [
  { label: "Customer Call", value: "CUSTOMER_CALL" },
  { label: "Branch Initiated", value: "BRANCH_INITIATED" },
  { label: "Manager Request", value: "MANAGER_REQUEST" },
];

const assignToOptions = [
  { label: "Branch", value: "BRNACH" },
  { label: "Location", value: "LOCATION" },
];

const tagOptions = [
  { label: "Field Service", value: "FIELD_SERVICE" },
  { label: "Warranty", value: "WARRANTY" },
  { label: "Shop Request", value: "SHOP_REQUEST" },
  { label: "Contract", value: "CONTRACT" },
  { label: "Part Leads", value: "PART_LEADS" },
  { label: "Service Leads", value: "SERVICE_LEADS" },
];

const tabData = [
  { label: "Customer Call", icon: <CallIcon /> },
  { label: "Service Branch", icon: <LocationOnIcon /> },
  { label: "Account Manager", icon: <PersonIcon /> },
];

const ServiceCallMaster = () => {
  const [activeStep, setActiveStep] = useState(0);

  const [selectedTab, setSelectedTab] = useState(0);
  const [tabForGeneral, setTabForGeneral] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [noOptionsCustomerId, setNoOptionsCustomerId] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  //
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeTabGen = (e, newValue) => {
    setTabForGeneral(newValue);
  };

  const handleChangeForTag = (selectedOptions) => {
    setSelectedTags(selectedOptions || []);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, serviceCallSteps.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleNewModal = () => {
    setOpenNewModal(true);
  };

  const customerInformation = () => {
    return (
      <>
        <div className="card p-4">
          <div className="ps-3 mb-3">
            <h5 className="mb-2">Customer Information</h5>
            <Divider className="mb-4" />
            <Box sx={{ borderColor: "divider", width: "100%" }}>
              <div className=" text-black font-weight-600 font-size-16 mb-3">Request Type</div>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                TabIndicatorProps={{ sx: { height: 3, backgroundColor: "primary.main" } }}
              >
                {tabData.map((tab, index) => (
                  <Tab
                    key={index}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        {tab.icon}
                        {tab.label}
                        <Radio
                          checked={selectedTab === index}
                          onChange={() => setSelectedTab(index)}
                          color="primary"
                          sx={{ padding: 0, marginLeft: 5 }}
                        />
                      </Box>
                    }
                    sx={{ border: 1, borderColor: selectedTab === index ? "primary.main" : "divider", borderRadius: 1, marginX: 0.5 }}
                  />
                ))}
              </Tabs>
              {selectedTab === 0 && (
                <>
                  <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                    Contact Information
                  </h5>

                  <div className="row input-fields mt-3">
                    <div className="col-md-6 col-sm-6 ">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                        <SearchBox type="customerid" noOptions={noOptionsCustomerId} />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="phone" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> CUSTOMER NAME</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="customername" placeholder="Customer Name" />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="email" placeholder="Email" />
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                    Address Information
                  </h5>

                  <div className="row input-fields mt-3">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> CITY</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="city" placeholder="City" />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="syate" placeholder="State" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">POSTAL CODE</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="postalcode" placeholder="Postal Code" />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="country" placeholder="Country" />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {selectedTab === 1 && (
                <div>
                  <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                    Personal Information
                  </h5>
                  <div className="row input-fields mt-3">
                    <div className="col-md-6 col-sm-6 ">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                        <SearchBox type="customerid" noOptions={noOptionsCustomerId} />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                        <input type="text" className="form-control border-radius-10 text-primary" name="phone" placeholder="Phone" />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                        <textarea name="owneradd" cols="20" rows="1" placeholder="Address" className="form-control border-radius-10 text-primary" />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> CUSTOMER NAME</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="customername"
                          placeholder="Customer Name"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="email"
                          placeholder="Email"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> SERVICE BRANCH </label>
                        <Select
                          className="text-primary"
                          name="servloc"
                          // options={serviceBranchOptions}
                          styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 2 && (
                <div>
                  <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                    Personal Information
                  </h5>

                  <div className="row input-fields mt-3">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                        <SearchBox type="customerid" noOptions={noOptionsCustomerId} />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> CUSTOMER NAME</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="customername"
                          placeholder="Customer Name"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row input-fields mt-2">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PHONE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="phone"
                          placeholder="Phone"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">EMAIL</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="email"
                          placeholder="Email"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row input-fields mt-2">
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                        <textarea
                          name="owneradd"
                          cols="20"
                          rows="1"
                          placeholder="Address"
                          className="form-control border-radius-10 text-primary"
                          // value={vehicleRecordData?.owneradd}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
                    Manager Information
                  </h5>

                  <div className="row input-fields mt-3">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MANAGER NAME</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="managerName"
                          placeholder="Manager Name"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MANAGER PHONE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="managerphone"
                          placeholder="Manager Phone"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row input-fields mt-2">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MANAGER EMAIL</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="manageremail"
                          placeholder="Manager Email"
                          // value={vehicleRecordData?.empincharge}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500"> SENIORITY LEVEL </label>
                        <Select
                          className="text-primary"
                          name="servloc"
                          // value={vehicleRecordData?.servloc}
                          // onChange={(selectedOption) => handleInputChange(selectedOption, { name: "servloc" })}
                          // options={serviceLocationOptions}
                          styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </div>
        </div>
        <div className="row px-4 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn border-primary text-primary mx-2" onClick={handleBack}>
            Cancel
          </button>
          <button className="btn bg-primary text-white" onClick={handleNext}>
            Confirm
          </button>
        </div>
      </>
    );
  };

  const machineDetails = () => {
    return (
      <>
        <div className="card p-4">
          <div className=" ps-3 mb-3">
            <h5 className="mb-2">Machine Details</h5>
            <Divider className="mb-4" />
            <h5 className="mt-4 p-2  text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
              Machine Information
            </h5>

            <div className="row input-fields mt-3">
              <div className="col-md-6 col-sm-6 ">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER </label>
                  <SearchBox type="customerid" noOptions={noOptionsCustomerId} />
                </div>
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">Model</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="phone"
                    placeholder="Model"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="make"
                    placeholder="Make"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <h5 className="mt-4 p-2 text-dark border-bottom" style={{ backgroundColor: " #eae6f9" }}>
              Address Information
            </h5>
            <div className="row input-fields mt-3">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> CITY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="city"
                    placeholder="City"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">STATE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="syate"
                    placeholder="State"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">POSTAL CODE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="postalcode"
                    placeholder="Postal Code"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="country"
                    placeholder="Country"
                    // value={vehicleRecordData?.empincharge}
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-4 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn border-primary text-primary mx-2" onClick={handleBack}>
            Cancel
          </button>
          <button className="btn bg-primary text-white" onClick={handleNext}>
            Confirm
          </button>
        </div>
      </>
    );
  };

  const generalInformation = () => {
    return (
      <>
        <div className="card p-4">
          <div className="ps-3 mb-3">
            <h5 className="mb-2">General Data</h5>
            <Divider className="mb-4" />
            <Box sx={{ borderColor: "divider", width: "100%" }}>
              <div className=" text-black font-weight-600 font-size-16 mb-3">Priority</div>
              <Tabs
                value={tabForGeneral}
                onChange={handleChangeTabGen}
                textColor="primary"
                indicatorColor="none"
                TabIndicatorProps={{ sx: { display: "none" } }}
              >
                {["Low", "Medium", "High"].map((label, index) => (
                  <Tab
                    key={index}
                    label={
                      <Box display="flex" alignItems="center" justifyContent="center" gap={1} sx={{ width: "150px" }}>
                        <Box
                          sx={{ width: 12, height: 12, borderRadius: "50%", backgroundColor: index === 0 ? "green" : index === 1 ? "orange" : "red" }}
                        />
                        <Typography variant="body2">{label}</Typography>
                      </Box>
                    }
                    sx={{ border: 1, borderColor: tabForGeneral === index ? "primary.main" : "divider", borderRadius: 1, marginX: 0.5 }}
                  />
                ))}
              </Tabs>
            </Box>
            <div className="row input-fields mt-3">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                  <textarea
                    name="owneradd"
                    cols="20"
                    rows="3"
                    placeholder=" Short Description"
                    className="form-control border-radius-10 text-primary"
                  />
                </div>
              </div>
            </div>
            <div className="row input-fields mt-2">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> REQUEST TYPE </label>
                  <Select
                    className="text-primary"
                    name="servloc"
                    options={requesterOptions}
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> ASSIGNEE </label>
                  <input type="text" className="form-control border-radius-10 text-primary" name="assignee" placeholder="Person who is assigning" />
                </div>
              </div>
            </div>
            <div className="row input-fields mt-2">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> REQUESTER </label>
                  <input type="text" className="form-control border-radius-10 text-primary" name="assignee" placeholder="Name of contact" />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> ASSIGN TO </label>
                  <Select
                    className="text-primary"
                    name="servloc"
                    options={assignToOptions}
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
            </div>
            <div className="row input-fields mt-2">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                  <textarea
                    name="owneradd"
                    cols="20"
                    rows="5"
                    placeholder=" Long Description"
                    className="form-control border-radius-10 text-primary"
                  />
                </div>
              </div>
            </div>
            <div className="row input-fields mt-2">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500"> TAGS </label>
                  <Select
                    isMulti
                    options={tagOptions}
                    value={selectedTags}
                    onChange={handleChangeForTag}
                    placeholder="Select Tags"
                    styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-4 mb-3" style={{ justifyContent: "right" }}>
          <button className="btn border-primary text-primary mx-2" onClick={handleBack}>
            Cancel
          </button>
          <button
            className="btn bg-primary text-white"
            // onClick={handleNext}
          >
            Submit As New
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid ">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: 4 }}>
              Service Call
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              Create a service call terms with the guided process
            </Typography>
          </Grid>
          <div className="row px-4 mb-3" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary mx-2" onClick={handleNewModal}>
              New Modal
            </button>
          </div>
          <Box sx={{ width: "100%", marginY: 3 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {serviceCallSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 && customerInformation()}
          {activeStep === 1 && machineDetails()}
          {activeStep === 2 && generalInformation()}
        </div>
      </div>
      {openNewModal && <ServiceCallNewModal show={openNewModal} hideModal={() => setOpenNewModal(false)} handleSnack={handleSnack} />}
    </>
  );
};

export default ServiceCallMaster;
