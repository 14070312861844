import React, { useEffect, useState } from "react";

import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copyIcon from "../../../assets/icons/svg/Copy.svg";
import deleteIcon from "../../../assets/icons/svg/delete.svg";
import folderaddIcon from "../../../assets/icons/svg/folder-add.svg";
import shareIcon from "../../../assets/icons/svg/share.svg";
import uploadIcon from "../../../assets/icons/svg/upload.svg";

import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ReviewAddIcon from "@mui/icons-material/CreateNewFolderOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import TabContext from "@mui/lab/TabContext";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { TextField, Tooltip } from "@mui/material";

import Select from "react-select";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
    builderSearch,
    createBuilderVersion,
    createStandardJob,
    fetchBuilderDetails,
    fetchBuilderSegmentOperationItems,
    fetchBuilderVersionDet,
    FetchConsumableItems,
    FetchExtWorkItems,
    FetchLaborItems,
    fetchPartlistFromOperation,
    updateBuilderCustomer,
    updateBuilderEstimation,
    updateBuilderGeneralDet,
    updateBuilderMachine,
    updateBuilderPrice,
    updateBuilderStatus,
} from "services/repairBuilderServices";

import Validator from "utils/validator";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { createRepairQuote } from "services/repairQuoteServices";
import { REPAIR_BUILDER, REPAIR_QUOTE_DETAILS, STANDARD_JOB_DETAIL } from "navigation/CONSTANTS";
import { selectDropdownOption, selectPricingMethodList } from "../dropdowns/repairSlice";
import { useAppSelector } from "app/hooks";
import { ERROR_MAX_VERSIONS, FONT_STYLE, FONT_STYLE_SELECT, STATUS_OPTIONS } from "../CONSTANTS";
import { customerSearch, machineSearch } from "services/searchServices";
import ModalCreateVersion from "../components/ModalCreateVersion";
import { ReadOnlyField } from "../components/ReadOnlyField";
import LoadingProgress from "../components/Loader";
import SearchBox from "../components/SearchBox";
import PriceMethodTable from "../components/PriceMethodTable";
import PriceSummaryTable from "../components/PriceSummaryTable";
import SparePartsStepper from "../Stepper/SparePartsStepper";
import ShopReapirRequestRecipt from "./ShopReapirRequestRecipt";
import MaintenanceBuilderOrderPlan from "../MaintenanceBuilder/MaintenanceBuilderOrderPlan";
import RepairBuilderApproval from "../RepairBuilder/RepairBuilderApproval";
import RepairBuilderOrder from "../RepairBuilder/RepairBuilderOrder";
import MaintenanceBuilderInvoice from "../MaintenanceBuilder/MaintenanceBuilderInvoice";
import ShopRepairEstimations from "./ShopRepairEstimations";
import ShopRepairDisassembly from "./ShopRepairDisassembly";
import ShopRepairProposalGeneration from "./ShopRepairProposalGeneration";
import ShopRepairApproval from "./ShopRepairApproval";
import ShopRepairExecution from "./ShopRepairExecution";
import { orderApprovalDummyRecords } from "pages/Common/constants";
// import RepairBuilderAccordian from "./RepairBuilderAccordian";
// import RepairBuilderOrder from "./RepairBuilderOrder";
// import RepairBuilderApproval from "./RepairBuilderApproval";
// import MaintenanceBuilderInvoice from "../MaintenanceBuilder/MaintenanceBuilderInvoice";
// import MaintenanceBuilderOrderPlan from "../MaintenanceBuilder/MaintenanceBuilderOrderPlan";

const validityOptions = [
    { value: 15, label: "15 days" },
    { value: 30, label: "1 month" },
    { value: 45, label: "45 days" },
    { value: 60, label: "2 months" },
];

const warrantyClaimStatusOption = [
    { label: "Registered", value: "REGISTERED" },
    { label: "Acknowledged", value: "ACKNOWLEDGED" },
    { label: "Accepted", value: "ACCEPTED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Closed", value: "CLOSED" },
];

const salesOfficeOptions = [
    { value: "Location1", label: "Location1" },
    { value: "Location2", label: "Location2" },
    { value: "Location3", label: "Location3" },
    { value: "Location4", label: "Location4" },
];

const steps = [
    { label: "Request & Receipt", completed: true },
    { label: "Disassembly", completed: false },
    { label: "Cost Estimation", completed: false },
    { label: "Proposal Generation", completed: false },
    // { label: "Order Plan", completed: false },
    { label: "Approval", completed: false },
    { label: "Repair Execution", completed: false },
    // { label: "Order Exceution", completed: false },
    { label: "Invoice", completed: false },
];

const ShopReapirDetails = (props) => {
    const history = useHistory();
    const { state } = props.location;

    // Retrieve price methods
    const priceMethodOptions = useAppSelector(selectDropdownOption(selectPricingMethodList));

    const [searchCustResults, setSearchCustResults] = useState([]);
    const [searchModelResults, setSearchModelResults] = useState([]);
    const [searchSerialResults, setSearchSerialResults] = useState([]);
    const [builderId, setBuilderId] = useState("");
    const [bId, setBId] = useState("");
    const [quoteDescription, setQuoteDescription] = useState("");
    const [quoteReference, setQuoteReference] = useState("");
    const [versionOpen, setVersionOpen] = useState(false);
    const [versionDescription, setVersionDescription] = useState("");
    const [noOptionsCust, setNoOptionsCust] = useState(false);
    const [noOptionsModel, setNoOptionsModel] = useState(false);
    const [noOptionsSerial, setNoOptionsSerial] = useState(false);
    const currencyOptions = [{ value: "USD", label: "USD" }];
    const [savedHeaderDetails, setSavedBuilderDetails] = useState([]);
    const [templateOpen, setTemplateOpen] = useState(false);
    const [openQuotePopup, setOpenQuotePopup] = useState(false);
    const [templateVersion, setTemplateVersion] = useState({ value: "GOLD", label: "Gold" });
    const [templateReference, setTemplateReference] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [activeElement, setActiveElement] = useState({
        name: "header",
        bId: "",
        sId: "",
        oId: "",
        builderType: "",
    });
    const [selBuilderStatus, setSelBuilderStatus] = useState({ value: "DRAFT", label: "Draft" });
    const recentList = () => {
        history.push({ pathname: REPAIR_BUILDER });
    };
    const [viewOnlyTab, setViewOnlyTab] = useState({
        custViewOnly: false,
        machineViewOnly: false,
        generalViewOnly: false,
        estViewOnly: false,
        priceViewOnly: false,
    });

    const [activeStep, setActiveStep] = useState(0);

    const [segments, setSegments] = useState([]);
    const [segmentItems, setSegmentItems] = useState(null);

    const [partListRecords, setPartListRecords] = useState([]);
    const [laborItemsRecords, setLaborItemsRecords] = useState([]);
    const [consumableItemsRecords, setConsumableItemsRecords] = useState([]);
    const [extWorkItemsRecords, setExtWorkItemsRecords] = useState([]);

    const [orderApprovalRecords, setOrderApprovalRecords] = useState([...orderApprovalDummyRecords]);

    const [customerData, setCustomerData] = useState({
        source: "User Generated",
        customerID: "",
        customerName: "",
        contactEmail: "",
        contactName: "",
        contactPhone: "",
        customerGroup: "",
        customerSegment: "",
        regionOrState: "",
        country: "",
    });
    const [machineData, setMachineData] = useState({
        make: "",
        family: "",
        model: "",
        serialNo: "",
        smu: "",
        fleetNo: "",
        registrationNo: "",
        chasisNo: "",
        productSegment: "",
        productGroup: "",
    });
    const [generalData, setGeneralData] = useState({
        estimationDate: new Date(),
        estimationNo: "",
        description: "",
        reference: "",
        validity: null,
        version: "",
        warrantyClaimStatus: "",
    });
    const [estimationData, setEstimationData] = useState({
        preparedBy: "user1",
        approvedBy: "user1",
        preparedOn: new Date(),
        revisedBy: "user1",
        revisedOn: new Date(),
        salesOffice: null,
    });

    const [pricingData, setPricingData] = useState({
        netPrice: 0.0,
        priceDate: new Date(),
        adjustedPrice: 0.0,
        currency: "",
        priceDetailDTO: [],
        priceEstimateDTO: [],
    });

    const [selectedOption, setSelectedOption] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [value, setValue] = useState("customer");
    const [open, setOpen] = useState(false);

    const [builderType, setBuilderType] = useState("");

    const [headerLoading, setHeaderLoading] = useState(false);
    const [builderVersionOptions, setBuilderVersionOptions] = useState([{ label: "Version 1", value: 1 }]);
    const [selectedVersion, setSelectedVersion] = useState({ label: "Version 1", value: 1 });

    const [machineCompos, setMachineCompos] = useState([
        {
            id: 1,
            location: "",
            machineComponent: "",
            serialNo: "",
            model: "",
            make: "",
            requestDate: new Date(),
            serviceOrder: "",
            requestNumber: "",
            customerId: "",
            customerName: "",
            submited: false,
            viewMode: false,
        },
    ]);
    const [receiptRecords, setReceiptRecords] = useState([]);

    const [returnedRecords, setReturnedRecords] = useState([]);

    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    useEffect(() => {
        if (state && state.type === "new") {
            setBuilderId(state.builderId);
            setBId(state.bId);
            setBuilderType(state.builderType);
            setGeneralData({ ...generalData, estimationNo: state.builderId });
        } else if (state) {
            setBuilderId(state.builderId);
            setBId(state.bId);
            setBuilderType(state.builderType);
            fetchAllDetails(state.bId);
        }
        setActiveElement({ ...activeElement, builderType: state.builderType });
    }, []);

    // fetch all details
    const fetchAllDetails = async (builderId) => {
        var versionHistoryData = {
            builderId: "",
            exitingType: "repair",
            editable: false,
        };
        localStorage.setItem("exitingType", JSON.stringify(versionHistoryData));
        if (builderId) {
            setHeaderLoading(true);
            await builderSearch(!state.builderType ? `builderId:${builderId}` : `id:${state.bId}`)
                .then((result) => {
                    if (result.length !== 0) {
                        setBuilderId(result[0].builderId);
                        populateHeader(result[0]);
                    } else {
                        handleSnack("info", "No Record Found!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    handleSnack("error", "Error occured while fetching header details");
                });

            await fetchBuilderSegmentOperationItems(`builder_id=${builderId}&segment_id=0&operation_id=0`).then((result) => {
                if (result) {
                    if (result.segments && result["segments"].length > 0) {
                        setSegmentItems({
                            ...result,
                            segments: result["segments"].map((item) => ({
                                ...item,
                                notes: [
                                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam aut vel soluta obcaecati voluptas doloribus quae assumenda ad veritatis ipsa. Voluptate quam laboriosam quidem quos, minima mollitia deleniti debitis, inventore ex sapiente nihil ut corrupti.",
                                ],
                            })),
                        });
                    } else {
                        setSegmentItems(result);
                    }
                    getSegmentResultsDetails(result);
                }
            });
            setHeaderLoading(false);
        }
    };

    // Segment details like Operation || Labor || Parts || Consumable Items || Mis. Work
    const getSegmentResultsDetails = async (result) => {
        let _segments = result.segments;

        let _laborItems = [];
        let _partList = [];
        let _consumableItems = [];
        let _extWorkItems = [];

        let _partListPromises = [];
        let _laborItemsPromises = [];
        let _consumableItemsPromise = [];
        let _extWorkItemsPromise = [];
        for (let segment of _segments) {
            let _operations = segment["operations"];
            for (let operation of _operations) {
                _partListPromises.push(fetchPartlistFromOperation(operation?.id));
                if (operation?.labourDetails?.id) {
                    _laborItemsPromises.push(FetchLaborItems(operation?.labourDetails?.id));
                }

                if (operation?.consumableDetails?.id) {
                    _consumableItemsPromise.push(FetchConsumableItems(operation?.consumableDetails?.id));
                }

                if (operation?.externalWorkDetailsItems?.id) {
                    _extWorkItemsPromise.push(FetchExtWorkItems(operation?.externalWorkDetailsItems?.id));
                }
            }
        }

        // part list items
        let partListsResults = await Promise.all(_partListPromises);
        _partList = partListsResults.flat();
        setPartListRecords(_partList);

        // labor items
        await Promise.all(_laborItemsPromises).then((item) => {
            item.map((res) => _laborItems.push(...res["result"]));
        });
        // _laborItems = _laborItems.flat();
        setLaborItemsRecords(_laborItems);

        // consumable items
        await Promise.all(_consumableItemsPromise).then((item) => {
            item.map((res) => _consumableItems.push(...res["result"]));
        });
        // _consumableItems = _consumableItems.flat();
        setConsumableItemsRecords(_consumableItems);

        // external work items
        await Promise.all(_extWorkItemsPromise).then((item) => {
            item.map((res) => _extWorkItems.push(...res["result"]));
        });
        // _extWorkItems = _extWorkItems.flat();
        setExtWorkItemsRecords(_extWorkItems);
    };

    // populate the header details
    const populateHeader = (result) => {
        setSavedBuilderDetails(result);
        setViewOnlyTab({
            custViewOnly: result.customerId ? true : false,
            machineViewOnly: result.serialNo ? true : false,
            generalViewOnly: result.estimationDate ? true : false,
            estViewOnly: result.preparedBy ? true : false,
            priceViewOnly: result.priceMethod !== "EMPTY" && result.priceMethod !== null && result.priceMethod !== "" ? true : false,
        });
        setBId(result.id);
        setSelBuilderStatus(STATUS_OPTIONS.filter((x) => x.value === result.status)[0]);
        let versions = result.versionList?.map((versionNo) => ({
            value: versionNo,
            label: "Version " + versionNo,
        }));
        setBuilderVersionOptions(versions);
        setSelectedVersion({
            label: "Version " + result.versionNumber,
            value: result.versionNumber,
        });

        populateCustomerData(result);
        populateMachineData(result);
        populateGeneralData(result);
        populateEstData(result);
        populatePricingData(result);
        setSegments(result.segmentDTOs);
    };

    // populate the customer tab data
    const populateCustomerData = (result) => {
        setCustomerData({
            customerID: result.customerId ? result.customerId : "",
            contactEmail: result.contactEmail ? result.contactEmail : "",
            contactName: result.contactName ? result.contactName : "",
            contactPhone: result.contactPhone ? result.contactPhone : "",
            customerGroup: result.customerGroup ? result.customerGroup : "",
            customerName: result.customerName ? result.customerName : "",
            source: result.source ? result.source : "User Generated",
            customerSegment: result.customerSegment ? result.customerSegment : "",
            country: result.country ? result.country : "",
            regionOrState: result.regionOrState ? result.regionOrState : "",
        });
        setSearchCustResults([]);
    };

    // populate the machine tab data
    const populateMachineData = (result) => {
        setMachineData({
            make: result.make ? result.make : "",
            family: result.family ? result.family : "",
            model: result.model ? result.model : "",
            serialNo: result.serialNo ? result.serialNo : "",
            fleetNo: result.fleetNo ? result.fleetNo : "",
            smu: result.smu ? result.smu : "",
            registrationNo: result.registrationNo ? result.registrationNo : "",
            chasisNo: result.chasisNo ? result.chasisNo : "",
            productSegment: result.productSegment ? result.productSegment : "",
            productGroup: result.productGroup ? result.productGroup : "",
        });
        setSearchModelResults([]);
        setSearchSerialResults([]);
    };

    // populate the general tab data
    const populateGeneralData = (result) => {
        setGeneralData({
            description: result.description ? result.description : "",
            estimationDate: result.estimationDate ? result.estimationDate : new Date(),
            estimationNo: result.builderId ? result.builderId : state.builderId,
            reference: result.reference ? result.reference : "",
            validity:
                result.validityDays && result.validityDays !== "EMPTY" ? validityOptions.find((element) => element.value === result.validityDays) : { label: "", value: "" },
            version: result.versionNumber ? result.versionNumber : "",
        });
    };

    // populate the estimation tab data
    const populateEstData = (result) => {
        setEstimationData({
            approvedBy: result.approver ? result.approver : "",
            preparedBy: result.preparedBy ? result.preparedBy : "",
            preparedOn: result.preparedOn ? result.preparedOn : new Date(),
            revisedBy: result.revisedBy ? result.revisedBy : "",
            revisedOn: result.revisedOn ? result.revisedOn : new Date(),
            salesOffice: result.salesOffice ? salesOfficeOptions.find((element) => element.value === result.salesOffice) : { label: "", value: "" },
        });
    };

    // populate the price tab data
    const populatePricingData = (result) => {
        setPricingData({
            priceDate: result.priceDate ? result.priceDate : new Date(),
            // priceMethod:
            //   result.priceMethod && result.priceMethod !== "EMPTY"
            //     ? priceMethodOptions.find(
            //         (element) => element.value === result.priceMethod
            //       )
            //     : { label: "", value: "" },
            netPrice: result.netPrice ? result.netPrice : 0.0,
            adjustedPrice: result.adjustedPrice ? result.adjustedPrice : 0.0,
            currency: result.currency ? currencyOptions.find((element) => element.value === result.currency) : { label: "", value: "" },
            priceDetailDTO: result.priceDetailDTO,
            priceEstimateDTO: result.priceEstimateDTO,
        });
    };

    const handleVersion = (e) => {
        setSelectedVersion(e);
        fetchBuilderVersionDet(builderId, e.value).then((result) => {
            populateHeader(result);
        });
        setActiveElement({
            name: "header",
            bId,
            sId: "",
            oId: "",
        });
    };

    // handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // create the Quote
    const handleCreateQuote = async () => {
        await createRepairQuote(bId, quoteDescription, quoteReference)
            .then((createdQuote) => {
                handleSnack("success", "Quote has been created successfully!");
                let quoteDetails = {
                    quoteId: "",
                    // templateDBId: "",
                    type: "fetch",
                };
                quoteDetails.quoteId = createdQuote.quoteId;
                // templateDetails.templateDBId = createdQuote.id;
                history.push({
                    pathname: REPAIR_QUOTE_DETAILS,
                    state: quoteDetails,
                });
            })
            .catch((e) => {
                handleSnack("error", "Error occurred while creating quote");
            });
        setOpenQuotePopup(false);
    };

    // Update the status of the builder : Active, Revised etc.
    const handleBuilderStatus = async (e) => {
        await updateBuilderStatus(bId, e.value)
            .then((result) => {
                setSelBuilderStatus(e);
                setActiveElement({ ...activeElement, builderStatus: e.value });
                handleSnack("success", "Status has been updated!");
            })
            .catch((err) => {
                handleSnack("error", `Failed to update the status!`);
            });
    };

    // Search Customer with customer ID
    const handleCustSearch = async (searchCustfieldName, searchText) => {
        setSearchCustResults([]);
        customerData.customerID = searchText;
        if (searchText) {
            await customerSearch(searchCustfieldName + "~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchCustResults(result);
                        setNoOptionsCust(false);
                    } else {
                        setNoOptionsCust(true);
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the customer!");
                });
        }
    };

    // Select the customer from search result
    const handleCustSelect = (type, currentItem) => {
        setCustomerData({
            ...customerData,
            customerID: currentItem.customerId,
            contactEmail: currentItem.email,
            contactName: currentItem.contactName,
            customerGroup: currentItem.customerGroup,
            customerName: currentItem.fullName,
            customerSegment: currentItem.customerSegment,
            country: currentItem.addressDTO?.country,
            regionOrState: currentItem.addressDTO?.regionOrState,
        });
        setSearchCustResults([]);
    };

    //Individual customer field value change
    const handleCustomerDataChange = (e) => {
        const { name, value } = e.target;
        setCustomerData({ ...customerData, [name]: value });
    };

    // Machine search based on model and serial number
    const handleMachineSearch = async (searchMachinefieldName, searchText) => {
        let searchQueryMachine = "";
        setSearchModelResults([]);
        setSearchSerialResults([]);

        if (searchMachinefieldName === "model") {
            machineData.model = searchText;
            searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
        } else if (searchMachinefieldName === "serialNo") {
            machineData.serialNo = searchText;
            searchQueryMachine = searchText ? (machineData.model ? `model:${machineData.model} AND equipmentNumber~` + searchText : "equipmentNumber~" + searchText) : "";
        }
        if (searchQueryMachine) {
            await machineSearch(searchQueryMachine)
                .then((result) => {
                    if (result) {
                        if (searchMachinefieldName === "model") {
                            if (result && result.length > 0) {
                                setSearchModelResults(result);
                                setNoOptionsModel(false);
                            } else {
                                setNoOptionsModel(true);
                            }
                        } else if (searchMachinefieldName === "serialNo") {
                            if (result && result.length > 0) {
                                setSearchSerialResults(result);
                                setNoOptionsSerial(false);
                            } else {
                                setNoOptionsSerial(true);
                            }
                        }
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the machine!");
                });
        } else {
            searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
        }
    };

    // Select machine from the search result
    const handleModelSelect = (type, currentItem) => {
        if (type === "model") {
            setMachineData({ ...machineData, model: currentItem.model });
            setSearchModelResults([]);
        } else if (type === "equipmentNumber") {
            setMachineData({
                ...machineData,
                model: currentItem.model,
                fleetNo: currentItem.stockNumber,
                serialNo: currentItem.equipmentNumber,
                smu: currentItem.sensorId,
                make: currentItem.maker,
                family: currentItem.market,
                productGroup: currentItem.productGroup,
                productSegment: currentItem.productSegment,
            });
            setSearchSerialResults([]);
        }
    };

    //Individual machine field value change
    const handleMachineDataChange = (e) => {
        const { name, value } = e.target;
        setMachineData({ ...machineData, [name]: value });
    };

    //Individual estimation details field value change
    const handleEstimationDataChange = (e) => {
        const { name, value } = e.target;
        setEstimationData({ ...estimationData, [name]: value });
    };

    // update the customer data
    const updateCustomerData = () => {
        let data = {
            builderId,
            source: customerData.source,
            customerId: customerData.customerID,
            customerName: customerData.customerName,
            contactName: customerData.contactName,
            contactEmail: customerData.contactEmail,
            customerGroup: customerData.customerGroup,
            contactPhone: customerData.contactPhone,
            customerSegment: customerData.customerSegment,
            regionOrState: customerData.regionOrState,
            country: customerData.country,
        };
        console.log(data);
        const validator = new Validator();
        if (!validator.emailValidation(customerData.contactEmail)) {
            handleSnack("error", "Please enter the email address in correct format");
        } else {
            updateBuilderCustomer(bId, data)
                .then((result) => {
                    setSavedBuilderDetails(result);
                    setViewOnlyTab({ ...viewOnlyTab, custViewOnly: true });
                    setValue("machine");
                    handleSnack("success", "Customer details updated!");
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating the customer data!");
                });
        }
    };

    // update the machine data
    const updateMachineData = () => {
        let data = {
            builderId,
            make: machineData.make,
            family: machineData.family,
            model: machineData.model,
            fleetNo: machineData.fleetNo,
            smu: machineData.smu,
            registrationNo: machineData.registrationNo,
            chasisNo: machineData.chasisNo,
            serialNo: machineData.serialNo,
            productGroup: machineData.productGroup,
            productSegment: machineData.productSegment,
        };
        updateBuilderMachine(bId, data)
            .then((result) => {
                setSavedBuilderDetails(result);
                setValue("estimation");
                setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: true });
                handleSnack("success", "Machine details updated!");
            })
            .catch((err) => {
                handleSnack("error", "Error occurred while updating the machine data!");
            });
    };

    // update the general data
    const updateGeneralData = () => {
        let data = {
            builderId,
            estimationDate: generalData.estimationDate,
            description: generalData.description,
            reference: generalData.reference,
            validityDays: generalData.validity?.value,
            estimationNumber: generalData.estimationNo,
        };
        updateBuilderGeneralDet(bId, data)
            .then((result) => {
                setSavedBuilderDetails(result);
                setValue("price");
                setViewOnlyTab({ ...viewOnlyTab, generalViewOnly: true });
                handleSnack("success", "General details updated!");
            })
            .catch((err) => {
                handleSnack("error", "Error occurred while updating the general details!");
            });
    };

    // update the estimation data
    const updateEstData = () => {
        let data = {
            builderId,
            preparedBy: estimationData.preparedBy,
            preparedOn: estimationData.preparedOn,
            revisedBy: estimationData.revisedBy,
            revisedOn: estimationData.revisedOn,
            approver: estimationData.approvedBy,
            salesOffice: estimationData.salesOffice?.value,
        };
        updateBuilderEstimation(bId, data)
            .then((result) => {
                setSavedBuilderDetails(result);
                setValue("general");
                setViewOnlyTab({ ...viewOnlyTab, estViewOnly: true });
                handleSnack("success", "Estimation details updated!");
            })
            .catch((err) => {
                handleSnack("error", "Error occurred while updating the estimation details!");
            });
    };

    // update the price data
    const updatePriceData = () => {
        let data = {
            builderId,
            priceDetailDTO: pricingData.priceDetailDTO,
            priceEstimateDTO: pricingData.priceEstimateDTO,
            currency: pricingData.currency?.value,
            priceDate: pricingData.priceDate,
            // adjustedPrice:
            //   pricingData.priceMethod?.value === "FLAT_RATE"
            //     ? pricingData.adjustedPrice
            //     : 0,
        };
        updateBuilderPrice(bId, data)
            .then((result) => {
                setSavedBuilderDetails(result);
                if (result) {
                    setPricingData({
                        ...pricingData,
                        adjustedPrice: result.adjustedPrice,
                        netPrice: result.netPrice,
                        priceDetailDTO: result.priceDetailDTO,
                        priceEstimateDTO: result.priceEstimateDTO,
                    });
                }
                setViewOnlyTab({ ...viewOnlyTab, priceViewOnly: true });
                handleSnack("success", "Pricing details updated!");
            })
            .catch((err) => {
                setPricingData({
                    ...pricingData,
                    adjustedPrice: savedHeaderDetails.adjustedPrice,
                    priceDetailDTO: savedHeaderDetails.priceDetailDTO,
                    priceEstimateDTO: savedHeaderDetails.priceEstimateDTO,
                    netPrice: savedHeaderDetails.netPrice,
                });
                handleSnack("error", "Error occurred while updating the pricing details!");
            });
    };

    //Logic to handle status changes
    const disableStatusOptions = (option) => {
        const selectedValue = selBuilderStatus.value;
        const changeToValue = option.value;
        return !(
            (["DRAFT", "REVISED"].indexOf(selectedValue) > -1 && changeToValue === "ACTIVE") ||
            (["ARCHIVED", "REVISED"].indexOf(changeToValue) > -1 && selectedValue === "ACTIVE")
        );
    };

    //
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (selBuilderStatus?.value !== "ACTIVE") handleSnack("info", "Set active status to do “convert to”");
        else setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleResetData = (action) => {
        if (action === "RESET") {
            value === "customer" && populateCustomerData(savedHeaderDetails);
            value === "machine" && populateMachineData(savedHeaderDetails);
            value === "general" && populateGeneralData(savedHeaderDetails);
            value === "estimation" && populateEstData(savedHeaderDetails);
            value === "price" && populatePricingData(savedHeaderDetails);
        } else if (action === "CANCEL") {
            populateHeader(savedHeaderDetails);
        }
        // setViewOnlyTab({ ...viewOnlyTab, custViewOnly: false });
    };

    // make header editable
    const makeHeaderEditable = () => {
        if (value === "customer" && viewOnlyTab.custViewOnly) setViewOnlyTab({ ...viewOnlyTab, custViewOnly: false });
        else if (value === "machine" && viewOnlyTab.machineViewOnly)
            setViewOnlyTab({
                ...viewOnlyTab,
                machineViewOnly: false,
            });
        else if (value === "estimation" && viewOnlyTab.estViewOnly) setViewOnlyTab({ ...viewOnlyTab, estViewOnly: false });
        else if (value === "general" && viewOnlyTab.generalViewOnly)
            setViewOnlyTab({
                ...viewOnlyTab,
                generalViewOnly: false,
            });
        else if (value === "price" && viewOnlyTab.priceViewOnly)
            setViewOnlyTab({
                ...viewOnlyTab,
                priceViewOnly: false,
            });
    };

    // create template
    const handleCreateTemplate = () => {
        // if (selBuilderStatus?.value === "ACTIVE") {
        const data = {
            description: templateDescription,
            reference: templateReference,
            version: templateVersion?.value,
        };
        createStandardJob(bId, data)
            .then((res) => {
                handleSnack("success", `Template ${res.standardJobId} has been successfully created!`);
                let templateDetails = {
                    templateId: "",
                    templateDBId: "",
                    type: "fetch",
                };
                templateDetails.templateId = res.templateId;
                templateDetails.templateDBId = res.id;
                history.push({
                    pathname: STANDARD_JOB_DETAIL,
                    state: templateDetails,
                });
            })
            .catch((e) => {
                handleSnack("error", "Conversion to Standard Job has been failed!");
                setTemplateOpen(false);
            });
        // } else {
        //   handleSnack("warning", "Builder is not active yet!");
        // }
    };

    // create new version
    const createVersion = async (versionDesc) => {
        await createBuilderVersion(bId, versionDesc)
            .then((result) => {
                setVersionOpen(false);
                setBId(result.id);
                setSelectedVersion({
                    label: "Version " + result.versionNumber,
                    value: result.versionNumber,
                });
                populateHeader(result);
                setVersionDescription("");
                handleSnack("success", `Version ${result.versionNumber} has been created`);
            })
            .catch((err) => {
                setVersionOpen(false);

                if (err.message === "Not Allowed") handleSnack("warning", ERROR_MAX_VERSIONS);
                else handleSnack("error", "Error occurred while creating builder version");
                setVersionDescription("");
            });
    };

    return (
        <>
            <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
            <ModalCreateVersion
                versionOpen={versionOpen}
                handleCloseVersion={() => setVersionOpen(false)}
                message="Another version of this builder will be created."
                handleCreateVersion={createVersion}
                description={versionDescription}
                setDescription={setVersionDescription}
            />
            <div className="content-body">
                <div className="container-fluid ">
                    <div className="card border px-3 py-3">
                        <div className="px-3">
                            <h3 className="mb-1">{`${machineData?.make},  ${machineData?.family},  ${machineData?.model}`}</h3>
                            <div className="d-flex align-items-center mb-1">
                                <h4>
                                    {machineData?.serialNo}
                                    {/* {","} */}
                                </h4>
                                {/* <h4 className="mx-2">
                                    <Moment format="DD/MM/YYYY">{generalData?.estimationDate}</Moment>
                                </h4> */}
                            </div>
                        </div>
                        <SparePartsStepper activeStep={activeStep} setActiveStep={setActiveStep} steps={steps} />
                        <Divider sx={{ marginY: 2 }} />
                        {activeStep === 0 && (
                            <ShopReapirRequestRecipt
                                setActiveStep={setActiveStep}
                                handleSnack={handleSnack}
                                machineCompos={machineCompos}
                                setMachineCompos={setMachineCompos}
                                receiptRecords={receiptRecords}
                                setReceiptRecords={setReceiptRecords}
                                returnedRecords={returnedRecords}
                                setReturnedRecords={setReturnedRecords}
                            />
                        )}
                        {activeStep === 1 && <ShopRepairDisassembly setActiveStep={setActiveStep} handleSnack={handleSnack} />}
                        {activeStep === 2 && (
                            <>
                                <ShopRepairEstimations
                                    open={open}
                                    anchorEl={anchorEl}
                                    activeElement={activeElement}
                                    setVersionOpen={setVersionOpen}
                                    setTemplateOpen={setTemplateOpen}
                                    setOpenQuotePopup={setOpenQuotePopup}
                                    STATUS_OPTIONS={STATUS_OPTIONS}
                                    selBuilderStatus={selBuilderStatus}
                                    makeHeaderEditable={makeHeaderEditable}
                                    handleResetData={handleResetData}
                                    recentList={recentList}
                                    headerLoading={headerLoading}
                                    value={value}
                                    handleChange={handleChange}
                                    viewOnlyTab={viewOnlyTab}
                                    customerData={customerData}
                                    searchCustResults={searchCustResults}
                                    handleCustSearch={handleCustSearch}
                                    handleCustSelect={handleCustSelect}
                                    noOptionsCust={noOptionsCust}
                                    handleCustomerDataChange={handleCustomerDataChange}
                                    updateCustomerData={updateCustomerData}
                                    machineData={machineData}
                                    handleMachineDataChange={handleMachineDataChange}
                                    searchModelResults={searchModelResults}
                                    handleModelSelect={handleModelSelect}
                                    noOptionsModel={noOptionsModel}
                                    noOptionsSerial={noOptionsSerial}
                                    handleMachineSearch={handleMachineSearch}
                                    searchSerialResults={searchSerialResults}
                                    updateMachineData={updateMachineData}
                                    estimationData={estimationData}
                                    setEstimationData={setEstimationData}
                                    handleEstimationDataChange={handleEstimationDataChange}
                                    salesOfficeOptions={salesOfficeOptions}
                                    updateEstData={updateEstData}
                                    generalData={generalData}
                                    setGeneralData={setGeneralData}
                                    validityOptions={validityOptions}
                                    warrantyClaimStatusOption={warrantyClaimStatusOption}
                                    updateGeneralData={updateGeneralData}
                                    pricingData={pricingData}
                                    setPricingData={setPricingData}
                                    currencyOptions={currencyOptions}
                                    updatePriceData={updatePriceData}
                                    handleSnack={handleSnack}
                                    handleClick={handleClick}
                                    handleClose={handleClose}
                                    handleVersion={handleVersion}
                                    selectedVersion={selectedVersion}
                                    handleBuilderStatus={handleBuilderStatus}
                                    disableStatusOptions={disableStatusOptions}
                                    builderVersionOptions={builderVersionOptions}
                                    bId={bId}
                                    segmentItems={segmentItems}
                                    setSegmentItems={setSegmentItems}
                                    partListRecords={partListRecords}
                                    setPartListRecords={setPartListRecords}
                                    laborItemsRecords={laborItemsRecords}
                                    setLaborItemsRecords={setLaborItemsRecords}
                                    consumableItemsRecords={consumableItemsRecords}
                                    setConsumableItemsRecords={setConsumableItemsRecords}
                                    extWorkItemsRecords={extWorkItemsRecords}
                                    setExtWorkItemsRecords={setExtWorkItemsRecords}
                                />
                            </>
                        )}
                        {activeStep === 3 && (
                            <ShopRepairProposalGeneration
                                machineData={machineData}
                                customerData={customerData}
                                laborItemsRecords={laborItemsRecords}
                                consumableItemsRecords={consumableItemsRecords}
                                extWorkItemsRecords={extWorkItemsRecords}
                                partListRecords={partListRecords}
                                segmentItems={segmentItems}
                                setActiveStep={setActiveStep}
                                handleSnack={handleSnack}
                            />
                        )}
                        {activeStep === 4 && (
                            <ShopRepairApproval
                                machineData={machineData}
                                customerData={customerData}
                                laborItemsRecords={laborItemsRecords}
                                consumableItemsRecords={consumableItemsRecords}
                                extWorkItemsRecords={extWorkItemsRecords}
                                partListRecords={partListRecords}
                                segmentItems={segmentItems}
                                setActiveStep={setActiveStep}
                                orderApprovalRecords={orderApprovalRecords}
                                setOrderApprovalRecords={setOrderApprovalRecords}
                                handleSnack={handleSnack}
                            />
                        )}
                        {activeStep === 5 && (
                            <ShopRepairExecution
                                laborItemsRecords={laborItemsRecords}
                                consumableItemsRecords={consumableItemsRecords}
                                extWorkItemsRecords={extWorkItemsRecords}
                                partListRecords={partListRecords}
                                segmentItems={segmentItems}
                                setActiveStep={setActiveStep}
                                handleSnack={handleSnack}
                            />
                        )}
                        {activeStep === 6 && <MaintenanceBuilderInvoice setActiveStep={setActiveStep} handleSnack={handleSnack} />}
                        {/* 
                        {activeStep === 3 && <MaintenanceBuilderOrderPlan setActiveStep={setActiveStep} handleSnack={handleSnack} />}
                        {activeStep === 4 && <RepairBuilderApproval setActiveStep={setActiveStep} handleSnack={handleSnack} />}
                        {activeStep === 5 && <RepairBuilderOrder setActiveStep={setActiveStep} handleSnack={handleSnack} />}
                        {activeStep === 6 && <MaintenanceBuilderInvoice setActiveStep={setActiveStep} handleSnack={handleSnack} />} */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopReapirDetails;
