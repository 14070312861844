import React from "react";
import { Container, Typography, Box } from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HighWater from "./ActionableInsightImages/HighWaterTemp.png";
import LockOut from "./ActionableInsightImages/LockOut.png";
import Lower from "./ActionableInsightImages/Lower.png";
import Upper from "./ActionableInsightImages/Upper.png";
import WaterHeater from "./ActionableInsightImages/WaterHeater.png";
import Rotten from "./ActionableInsightImages/rotten.png";

const FactCard = ({ fact, detail, image }) => {
  return (
    <div style={{ padding: "10px", height: "430px" }}>
      <div style={{ height: "100%", background: "#fff", borderRadius: "8px", overflow: "hidden", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <img src={image} alt={fact} style={{ width: "100%", height: "140px", objectFit: "cover" }} />
        <div style={{ padding: "16px" }}>
          <Typography variant="h6" component="div" gutterBottom>
            {fact}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {detail}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const InsightLanding = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    navText: [
      '<button class="btn btn-primary rounded-circle" style="position: absolute; top: 45%; left: -20px; transform: translateY(-50%); font-size: 14px; padding: 12px;"><span class="carousel-control-prev-icon" style="width: 1em; height: 1em;"></span></button>',
      '<button class="btn btn-primary rounded-circle" style="position: absolute; top: 45%; right: -20px; transform: translateY(-50%); font-size: 14px; padding: 12px;"><span class="carousel-control-next-icon" style="width: 1em; height: 1em;"></span></button>',
    ],
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <TipsAndUpdatesOutlinedIcon fontSize="large" style={{ marginRight: "10px", transform: "rotate(45deg)" }} className="text-warning" />
        <Typography variant="h4" className="text-light">
          Common Failures
        </Typography>
      </Box>
      <OwlCarousel className="owl-theme" {...options}>
        <FactCard
          fact="High water temperature and shut down"
          detail="Turn off the water heater and remove the control box cover. Measure the resistance of the upper (blue/yellow wires) and lower (orange/blue wires) heating elements; they should be 5-25 ohms. Check the thermistor resistance (red and black wires) at various water temperatures. If within specifications, replace the control board."
          image={HighWater}
        />
        <FactCard
          fact="Water heater temperature and shut down"
          detail="Shut off circuit breakers and remove the water heater control box cover. Check and reconnect the thermistor wire harness. Measure resistance through the black (upper) and red (lower) thermistor wires. If resistance is correct (50k-5k ohms, based on temperature) but not detected, replace the control board; otherwise, check and repair wiring."
          image={WaterHeater}
        />
        <FactCard
          fact="Upper heating element failure"
          detail="Turn off the water heater and remove the control box cover. Inspect the yellow and blue relay wires for damage. Measure resistance between these wires with a multi-meter; it should be 5-25 ohms. Replace the upper heating element if defective. If the element and wiring are okay, replace the control board."
          image={Upper}
        />
        <FactCard
          fact="Lower heating element failure"
          detail="Turn off the water heater and remove the control box cover. Inspect the orange and blue relay wires for damage. Measure resistance between these wires with a multi-meter; it should be 5-25 ohms. Replace the lower heating element if defective. If the element and wiring are okay, replace the control board."
          image={Lower}
        />
        <FactCard
          fact="Lock-out condition"
          detail="The water heater stops working due to a malfunction in the control board. Reset the unit by turning off power and waiting 10 minutes. If the green light is still flashing 6 times after resetting the control, replace the control board."
          image={LockOut}
        />
        <FactCard
          fact="Why does my hot water smell like rotten eggs?"
          detail="Certain water conditions cause a reaction between a water heater's anode rod and supply water, causing hydrogen sulfide gas to dissolve in the tank water. The hydrogen sulfide gas gives the water a rotten-egg smell. Installing a less-reactive aluminum alloy anode rod and chlorinating the water supply system eliminates the rotten-egg smell in hot water."
          image={Rotten}
        />
      </OwlCarousel>
    </Container>
  );
};

export default InsightLanding;
