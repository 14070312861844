import React, { useEffect } from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import { Grid } from "@mui/material";
import { Segment } from "@mui/icons-material";
import Moment from "react-moment";

const ShopRepairProposalGeneration = ({
    machineData,
    customerData,
    laborItemsRecords,
    consumableItemsRecords,
    extWorkItemsRecords,
    partListRecords,
    segmentItems,
    setSegmentItems,
    setActiveStep,
    handleSnack,
}) => {
    // Generate Purposal
    const handleGeneartePurposal = () => {
        handleSnack("success", "Proposal Generated Successfully.");
    };
    return (
        <>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3 cursor">
                        <div className="d-flex align-items-center mb-2">
                            <TimerOutlinedIcon sx={{ fontSize: 28 }} />
                            <h5 className="ml-1 mb-0">Machine Details</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Check-in-date</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                <Moment format="DD MMM, YYYY">{new Date()}</Moment>
                            </span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Hours Operated</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2500</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Client</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">{customerData?.customerName}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3 cursor">
                        <div className="d-flex align-items-center mb-2">
                            <BuildOutlinedIcon sx={{ fontSize: 28 }} />
                            <h5 className="ml-1 mb-0">Labor & Costs</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Labor Hours</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                {(laborItemsRecords.length > 0 &&
                                    laborItemsRecords.reduce((sum, partItem) => {
                                        return sum + (partItem?.estimatedHours || 0);
                                    }, 0)) ||
                                    0}
                            </span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Labor Rate</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                $
                                {(laborItemsRecords.length > 0 &&
                                    laborItemsRecords.reduce((sum, partItem) => {
                                        return sum + (partItem?.unitPrice || 0);
                                    }, 0)) ||
                                    0}
                                /hr
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Labor Rate</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                $
                                {(laborItemsRecords.length > 0 &&
                                    laborItemsRecords.reduce((sum, partItem) => {
                                        return sum + (partItem?.estimatedHours || 0) * (partItem?.unitPrice || 0);
                                    }, 0)) ||
                                    0}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3 cursor" style={{ height: "11.2rem" }}>
                        <div className="d-flex align-items-center mb-2">
                            <Inventory2OutlinedIcon sx={{ fontSize: 28 }} />
                            <h5 className="ml-1 mb-0">Parts Used</h5>
                        </div>
                        <div className="shop-repair-parts-approval pr-2">
                            {partListRecords.length > 0 &&
                                partListRecords.map((partsList) => {
                                    if (partsList?.spareparts?.length > 0) {
                                        return partsList.spareparts.map((item, index) => (
                                            <>
                                                <div key={index} className="d-flex justify-content-between align-items-center">
                                                    <span className="text-gray font-weight-600 font-size-14">
                                                        {item?.partNumber} X {item?.quantity}
                                                    </span>
                                                    <span className="text-dark font-weight-600 font-size-14 ml-2">${item.unitPrice * item?.quantity}</span>
                                                </div>
                                                <hr className="my-1" />
                                            </>
                                        ));
                                    }
                                    return null;
                                })}
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <span className="text-gray font-weight-600 font-size-14">Hydraulic Pump X 1</span>
                                <span className="text-dark font-weight-600 font-size-14 ml-2">$1200</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2">
                                <span className="text-gray font-weight-600 font-size-14">Hydraulic Oil X 5</span>
                                <span className="text-dark font-weight-600 font-size-14 ml-2">$375</span>
                            </div> */}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3 cursor">
                        <div className="d-flex align-items-center mb-2">
                            <AttachMoneyIcon sx={{ fontSize: 28 }} />
                            <h5 className="ml-1 mb-0">Cost Summary</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Parts Total</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                $
                                {parseFloat(
                                    (
                                        (partListRecords.length > 0 &&
                                            partListRecords.reduce((total, record) => {
                                                const sparePartsTotal =
                                                    (record.spareparts.length > 0 &&
                                                        record.spareparts.reduce((spareTotal, sparePart) => {
                                                            return spareTotal + sparePart.unitPrice * sparePart.quantity;
                                                        }, 0)) ||
                                                    0;
                                                return total + sparePartsTotal;
                                            }, 0)) ||
                                        0
                                    ).toFixed(2)
                                )}
                            </span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Labor Totel</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                $
                                {(
                                    (laborItemsRecords.length > 0 &&
                                        laborItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.estimatedHours || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                    0
                                ).toFixed(2)}
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Misc Charges</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">
                                $
                                {(
                                    ((consumableItemsRecords.length > 0 &&
                                        consumableItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.quantity || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                        0) +
                                    ((extWorkItemsRecords.length > 0 &&
                                        extWorkItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.estimatedHours || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                        0)
                                ).toFixed(2)}
                            </span>
                        </div>
                        <hr className="my-2" />
                        <div className="d-flex align-items-center">
                            <span className="text-dark font-weight-bold font-size-15">Estimated Total</span>
                            <span className="text-dark font-weight-bold font-size-15 ml-2">
                                $
                                {(
                                    ((partListRecords.length > 0 &&
                                        partListRecords.reduce((total, record) => {
                                            const sparePartsTotal =
                                                (record.spareparts.length > 0 &&
                                                    record.spareparts.reduce((spareTotal, sparePart) => {
                                                        return spareTotal + sparePart.unitPrice * sparePart.quantity;
                                                    }, 0)) ||
                                                0;
                                            return total + sparePartsTotal;
                                        }, 0)) ||
                                        0) +
                                    ((laborItemsRecords.length > 0 &&
                                        laborItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.estimatedHours || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                        0) +
                                    ((consumableItemsRecords.length > 0 &&
                                        consumableItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.quantity || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                        0) +
                                    ((extWorkItemsRecords.length > 0 &&
                                        extWorkItemsRecords.reduce((sum, partItem) => {
                                            return sum + (partItem?.estimatedHours || 0) * (partItem?.unitPrice || 0);
                                        }, 0)) ||
                                        0)
                                ).toFixed(2)}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className="row px-3" style={{ justifyContent: "right" }}>
                <button className="btn bg-primary text-white" onClick={handleGeneartePurposal}>
                    Generate Puropsal
                </button>
            </div>
        </>
    );
};

export default ShopRepairProposalGeneration;
