import React, { useState } from "react";

import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import { Grid } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import SupplierClaimServiceReportModal from "./SupplierClaimServiceReportModal";

const SupplierClaimEvaluation = () => {
    const [message, setMessage] = useState("");
    const [submittedMessage, setSubmittedMessage] = useState("Do you have any questions or additional notes about this claim? Write it here");
    const [reportData, setReportData] = useState({
        reportnumber: "",
        reportdescription: "Breakdown",
        ronumber: "RO1234",
        repairdate: "10.01.2022",
        smu: "4250 Hours",
        machineslno: "U654",
        componentno: "KTA38C",
        composelno: "25461036",
        customercmplt: "Noise coming out",
        customerremark: "Noise coming out",
        engremark: "Noise coming out",
        custaddr: "amohanty",
        email: "Johndoe@gmail.com",
    });
    const [hasReport, setHasReport] = useState(false);

    const [openServiceModal, setOpenServiceModal] = useState(false);

    const handleSubmitMessage = () => {
        if (message.trim()) {
            setSubmittedMessage(message); // Update submitted message
            setMessage(""); // Clear input field
        }
    };

    // open service modal
    const handleOpenServiceReportModal = () => {
        // setViewMode(isViewMode);
        setOpenServiceModal(true);
    };

    // Function to update the report number when the modal's submit button is clicked
    const handleReportSubmit = (newReportNumber) => {
        setReportData(newReportNumber);
        setHasReport(true);
        setOpenServiceModal(false);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div className="card border card-box-shadow">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 position-relative">
                                <div className="d-flex align-items-center py-2 repair-support-chat-header px-3 py-3">
                                    <div className="repair-support-chat-header-profile" style={{ position: "relative" }}>
                                        <img src={`../../../assets/images/users/8.jpg`} alt="Profile" />
                                        <div className="online"></div>
                                    </div>
                                    <div className="ml-2">
                                        <p className="m-0 font-size-14 font-weight-500 text-black">SUPPORT CHAT</p>
                                    </div>
                                </div>
                                <p className="font-size-14 font-weight-400 bg-light p-3 mx-2  rounded" style={{ position: "relative" }}>
                                    {submittedMessage}
                                </p>
                                <div className=" d-flex justify-content-between align-items-center font-size-14 font-weight-400 bg-light p-2  mt-5 rounded ">
                                    <input
                                        type="text"
                                        placeholder="Type your message here.."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="form-control border-0 bg-light"
                                        style={{ flex: 1, marginRight: "10px" }}
                                    />
                                    <span onClick={handleSubmitMessage}>
                                        <ArrowCircleUpIcon style={{ fontSize: "2rem" }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border card-box-shadow px-3 py-2">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="repair-builder-notes-container">
                                    <div className="d-flex align-items-center repair-builder-notes-header" style={{ marginBottom: "8px" }}>
                                        <DescriptionIcon />
                                        <p className="mb-0 font-size-14 font-weight-500" style={{ color: "#5C6A82" }}>
                                            NOTES
                                        </p>
                                    </div>
                                    <textarea placeholder="You can write any notes here." cols={5} rows={8}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border card-box-shadow px-3 py-2  h-75">
                        <div className="repair-builder-upload ">
                            <h6 className="text-center font-weight-500 my-3">Upload service report</h6>
                            <FileUploader name="file" types={["JPG", "PNG"]} />
                            <br /> or
                            <div className="col-md-12">
                                <div className="recent-items mt-3">
                                    <div className="d-flex justify-content-between align-items-center ">
                                        <p className="mb-0 overflow-hidden white-space">
                                            <FontAwesomeIcon className=" font-size-14" icon={faFileAlt} />
                                            <span className="font-weight-500 ml-2">{reportData.reportnumber ? reportData.reportnumber : "Add Service Report"} </span>
                                        </p>
                                        <div className="d-flex align-items-center ">
                                            {/* Conditional rendering based on whether a report exists */}
                                            {!hasReport ? (
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center justify-content-center border border-primary rounded p-1"
                                                    style={{ width: "150px", height: "30px" }}
                                                    onClick={handleOpenServiceReportModal}
                                                >
                                                    <span className=" font-weight-500">Add Service Report</span>
                                                    <ControlPointOutlinedIcon style={{ fontSize: "20px", color: "#007bff", marginLeft: 6 }} />
                                                </a>
                                            ) : (
                                                <a
                                                    href="#"
                                                    className="d-flex align-items-center justify-content-center border border-primary rounded p-1"
                                                    style={{ width: "150px", height: "30px" }}
                                                    onClick={() => handleOpenServiceReportModal(true)}
                                                >
                                                    <span className="font-weight-500">View Service Report</span>
                                                    <VisibilityIcon style={{ fontSize: "20px", color: "#007bff", marginLeft: 6 }} />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {openServiceModal && (
                <SupplierClaimServiceReportModal
                    show={openServiceModal}
                    hideModal={() => setOpenServiceModal(false)}
                    onSubmit={handleReportSubmit}
                    viewMode={hasReport}
                    reportData={reportData}
                />
            )}
        </>
    );
};

export default SupplierClaimEvaluation;
