export const ROOT = "/";
export const OVERVIEW = "/overview";
export const ANALYTICS = "/analytics";
export const WORK_LIST = "/workList";
export const WORK_LIST_NEW = "/workList/new";
export const REPORTS = "/reports";
export const LOGIN = "/login";
export const INSIGHTS = "/insights";
export const ARTIFICIALINT = "/artificialintl";

export const PORTFOLIO_SUMMARY = "/portfolio";
export const RESET = "/reset-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PERMISSION = "/permission";
export const PERMISSION_SETTING = "/permission/settings";
export const DASHBOARD = "/dashboard";
export const PAGE1 = "/page1";
export const AUTH_PAGE1 = "/authorized1";
export const PROFILE = "/profile";
// export const ACCOUNT = "/account";
export const LANDING_PAGE_LOGIN = "/LandingPageLogin";
export const RIPAIR_SERVICE_ESTIMATE = "/RepairServiceEstimate";
// export const WITHOUTSPARE_REPAIR_OPTION = "/WithoutSpareParts";
export const WITH_SPARE_PARTS = "/RepairWithSpareParts/BuilderDetails";
export const PART_LIST = "/RepairPartList/PartList";
export const REPAIR_PARTLIST = "/RepairPartlist";
export const REPAIR_WITH_SPARE_PARTS = "/RepairWithSpareParts";
export const REPAIR_WITHOUT_SPARE_PARTS = "/RepairWithoutSpareParts";
export const MAINTENANCE_BUILDER = "/maintenance-builder";
export const REPAIR_BUILDER = "/repair-builder";
// export const REPAIR_SERVICE_PARTS_TEMPLATE = "/RepairServicePartTemplate";
export const TEMPLATE = "/standard-job";
export const REPAIR_KITS = "/RepairKits";
export const STANDARD_JOB_DETAIL = "/standard-job/details";
// export const SERVICE_PART_TEMPLATES = "/RepairServicePartTemplate/ServicePartTemplates";
export const KITS = "/RepairKits/Kits";
export const WITHOUT_SPARE_PARTS_DETAILS = "/RepairWithoutSpareParts/BuilderDetails";
export const MAINTENANCE_BUILDER_DETAILS = "/maintenance-builder/details";
export const REPAIR_BUILDER_DETAILS = "/repair-builder/details";

export const QUOTE_SPARE_PARTS = "/spare-part-quote";
export const QUOTE_SPARE_PARTS_SEARCH = "/spare-part-quote/search";
export const QUOTE_SPARE_PART_CONFIGURATION = "/spare-part-quote/create";
export const QUOTE_RECENT_REPAIR = "/repair-quote";
export const QUOTE_REPAIR_SEARCH = "/repair-quote/search";
export const QUOTE_REPAIR_CREATE = "/repair-quote/create";
export const SOLUTION_QUOTE = "/solution-quote";
export const SOLUTION_QUOTE_SEARCH = "/solution-quote/search";
export const SOLUTION_QUOTE_CREATE = "/solution-quote/create";
export const SOLUTION_QUOTE_CONFIG = "/SolutionQuoteConfiguration";
export const SOLUTION_SERVICE_PORTFOLIO = "/SolutionServicePortfolio";
export const SPARE_PARTS_QUOTE_DETAILS = "/spare-part-quote/details";
export const QUOTE_SOLUTION_BUILDER = "/QuoteSolutionBuilder";
export const REPAIR_QUOTE_WITH_EVALUATION = "/repair-quote/evaluation";
export const REPAIR_QUOTE_DETAILS = "/repair-quote/details";
export const SOLUTION_QUOTE_DETAILS = "/solution-quote/details";
export const SOLUTION_SEARCH_TEMPLATE = "/SolutionSearchTemplate";
export const QUOTE_SOLUTION_CONFIGURATION = "/QuoteSolutionConfiguration";
export const SHOPPING_CART_LIST = "/ShoppingCartList";
export const PRICE_SETTING = "/PriceSetting";
export const PRICE_CONFIGURATION = "/PriceConfiguration";
export const PRICE_GLOBAL_SETTING = "/PriceGlobalSetting";
export const PRICE_DETERMINATION = "/PriceDetermination";
export const PRICE_MAINTENANCE = "/PriceMaintenance";
export const PRICE_COMPUTATION = "/PriceComputation";
export const VERIFY_EMAIL = "/verify-email/:id";

//Solution Builder Module
export const SOLUTION_BUILDER = "/solutionBuilder";
export const PORTFOLIO_AND_BUILDER = "/portfolioBuilder";
export const SOLUTION_TEMPLATES = "/SolutionTemplates";

export const PORTFOLIO_AND_BUILDER_NEW = "/portfolio/new";
export const SERVICE_NEW = "/service/new";
// export const TEST_REACT_TABLE_NESTED = "/react-table-nested";

export const SOLUTION_BUILDER_ANALYTICS = SOLUTION_BUILDER + "/analytics";
export const GUIDED_SOLUTION_BUILDER = SOLUTION_BUILDER + "/guide";
export const SOLUTION_BUILDER_SERVICE_PORTFOLIO = SOLUTION_BUILDER + "/servicePortfolio";
export const SOLUTION_BUILDER_NEW = SOLUTION_BUILDER + "/new";
export const SOLUTION_BUILDER_CUSTOMIZED_PORRTFOLIO = SOLUTION_BUILDER + "/customizedPortfolio";
export const SOLUTION_BUILDER_PORRTFOLIO_TEMP = SOLUTION_BUILDER + "/portfolioTemplates";
export const SOLUTION_TEMPLATE_SELECTED_PORTFOLIO_RESULT = SOLUTION_BUILDER + "/solutionTemplate";
export const SOLUTION_BUILDER_CUSTOM_PORTFOLIO_CREATE = SOLUTION_BUILDER + "/create";
export const CREATED_CUSTOM_PORTFOLIO_DETAILS = SOLUTION_BUILDER + "/customPortfolioTemplate";

// equipment-master
export const EQUIPMENT_MASTER = "/eqipment-master";
export const PARTS_360 = "/parts-360";
export const SERVICE_MASTER = "/service-master";
export const CONSUMABLE_MASTER = "/consumable-master";
export const CUSTOMER_MASTER = "/customer-master";
export const WARRENTY_MASTER = "/warranty-master";
export const SUPPLIER_MASTER = "/supplier-master";
export const CLAIM_MASTER = "/claim-master";
export const COMPONENT_MASTER = "/component-master";
export const SERVICE_PROGRESS = "/service-progress";
export const WARRANTY_RETURN = "/warranty-return";
export const SUPPLIER_CLAIM_MASTER = "/supplier-claim";
export const SUPPLIER_CLAIM_MANAGE = "/manage-supplier-claim";
export const SUPPLIER_CLAIM_PROCESS = "/supplier-claim-process";
export const WARRANTY_ANALYTICS = "/warranty-analytics";

// Warranty Management
export const ANALYTICS_DASHBOARD = "/analytics-dashboard";
export const WARRANTY_POLICIES = "/warranty-policies";
export const CLAIMS_PROCESSING = "/claims-processing";
export const SUPPLIER_CLAIM_HANDLING = "/suplier-claim-handling";
export const SUPPLIER_CLAIM_HANDLING_MANAGE = "/suplier-claim/manage";
export const RETURNS_MANAGEMENT = "/returns-management";
export const FAILED_PART_MANAGEMENT = "/failed-part-management";

// warehousse-master
export const WAREHOUSE_INVENTORY = "/inventory";
export const WAREHOUSE_GR = "/gr";
export const WAREHOUSE_GI = "/gi";
export const WAREHOUSE_PR = "/pr";
export const WAREHOUSE_STO = "/sto";
export const WAREHOUSE_VENDORS = "/vendors";
export const WAREHOUSE_REQUESTS = "/requests";
export const WAREHOUSE_PO = "/po";
export const WAREHOUSE_INVOICES = "invoices";

// Construction Management
export const BUILDING_CUSTOMER_MASTER = "/building-customer-master";
export const BUILDING_MATERIAL_MASTER = "/building-material-master";
export const BUILDING_ESTIMATE_MASTER = "/building-estimate";
export const BUILDING_ESTIMATE_PART_LIST = "/building-estimate/PartList";
export const BUILDING_ESTIMATE_ORDER = "/building-estimate/Order";
export const BUILDING_ESTIMATE_INVOICE = "/building-estimate/invoice";
export const BUILDER_ORDER_MASTER = "/building-order";

export const FIELD_SERVICES_HOME = "/service-home";
export const FIELD_SERVICES_CALL = "/services-call";
export const FIELD_SERVICES_REQUEST = "/services-request";
export const FIELD_SERVICES_ORDER = "/services-order";
export const FIELD_SERVICES_ORDER_DETAILS = "/services-order/details";
export const FIELD_PARTS_ORDER = "/parts-order";
export const FIELD_ASSIGMENT_MASTER = "/assignment";
export const FIELD_LOCATION_AND_EMPLOYEE = "/location-and-employee";
export const FIELD_VEHICLE_MASTER = "/vehicle-master";
export const FIELD_INVOICE_MASTER = "/service-invoice";

export const SHOP_REPAIR = "/shop-repair";
export const SHOP_REPAIR_DETAILS = "/shop-repair/details";

// Order & Invoice
export const ORDER_INVOICE_ORDER_MASTER = "/orders";
export const ORDER_INVOICE_INVOICE_MASTER = "/invoice";

//Aux Admin
export const AUX_ADMIN_PROVISION = "/aux-admin/provision";
export const AUX_ADMIN_ACCOUNT = "/aux-admin/account";
export const ACCOUNT_RENEWAL_BILLING = "/account-billing";
export const ACCOUNT_CONFIGURATION = "/account-config";
export const ACCOUNT_SETTINGs = "/account-settings";
export const ACCOUNT_SUPPORT = "/account-support";
export const ACCOUNT_PACKAGES = "/packages";
export const PLAN_MOMENTUM = "MOMENTUM";
export const PLAN_STARTER = "STARTER";
export const PLAN_GROWTH = "GROWTH";
export const PLAN_ENTERPRISE = "ENTERPRISE";
