import React, { useCallback, useEffect, useState } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Box, Grid, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Moment from "react-moment";

import { GRID_STYLE } from "pages/Common/constants";
import SearchBox from "pages/Common/filter/SearchBox";
import SelectBox from "pages/Common/SelectBox";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import {
  CLAIM_MASTER_URL,
  CLAIM_ORDER_MASTER_URL,
  Claim_Pagination_List_GET,
  Warranty_Country_List_GET,
  Warranty_Evaluation_Questions_Get_GET,
} from "services/CONSTANTS";
// import ClaimDetailsModal from "./ClaimDetailsModal";
// import ClaimRequestModal from "./ClaimRequestModal";
// import WarrantyRequestCreateModal from "./WarrantyRequestCreateModal";
// import ClaimReturnRequester from "./ClaimReturnRequester";
// import QuickCreateModal from "../QuickCreateModal";
// import WarrantyRequestModal from "../returnMaster/WarrantyRequestModal";
// import CreateWarrantyRequest from "../warrantyMaster/CreateWarrantyRequest";
// import WarrantyQuestions from "../warrantyMaster/WarrantyQuestions";
// import WarrantyRequestAuthorization from "../warrantyMaster/WarrantyRequestAuthorization";
// import ReplacementModal from "../returnMaster/ReplacementModal";
// import ClaimOrderRequestModal from "../claimOrderManagement/ClaimOrderRequestModal";
// import { claimStatusOptions, } from "../warrantyManagementConstants";
import { claimValueSearchById, settlementValueSearchById } from "services/searchServices";
import { claimStatusOptions, equipmentRequestObj, warrantyClaimStatusOptions } from "pages/warrantyManagement/warrantyManagementConstants";
import ClaimsProcessingRequestModal from "./ClaimsProcessingRequestModal";
// import ClaimErrorsModal from "../claimOrderManagement/ClaimErrorsModal";

const DataGridContainer = (props) => (
  <Box
    margin={"auto"}
    sx={{ backgroundColor: "#ffffff", minHeight: 500, borderRadius: 5, width: "100%", display: "flex", justifyContent: "center", padding: 2 }}
  >
    {props.children}
  </Box>
);

const returnTypeOptions = ["Standard", "Extended", "Service Letter", "Goodwill"];

const workFlowOptions = ["None", "Atria"];

const ClaimsProcessingMaster = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [tabValue, setTabValue] = useState("claims");
  const [claimExpanded, setClaimExpanded] = useState("claimStatus");
  const [pageSize, setPageSize] = useState(10);
  const [claimOrderStatus, setClaimOrderStatus] = useState("all");

  const [warrantyExpanded, setWarrantyExpanded] = useState("status");
  const [warrantyStatus, setWarrantyStatus] = useState("all");

  const [countryList, setCountryList] = useState([]);
  const [partsRecords, setPartsRecords] = useState([]);

  const [openClaimRequestModal, setOpenClaimRequestModal] = useState(false);
  const [openPartCreateModal, setOpenPartCreateModal] = useState(false);
  const [openReturnRequsterModal, setOpenReturnRequsterModal] = useState(false);

  const [returnNumber, setReturnNumber] = useState("");
  const [returnType, setReturnType] = useState("");

  const [claimRecordDetail, setClaimRecordDetail] = useState(null);
  const [claimRecordData, setClaimRecordData] = useState([]);
  const [recentClaimRecords, setRecentClaimRecords] = useState([]);
  const [warrantyReqClaims, setWarrantyReqClaims] = useState([]);

  const [claimRecordId, setClaimRecordId] = useState(null);
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [evaluationId, setEvaluationId] = useState(null);
  const [assesstmentId, setAssesstmentId] = useState(null);
  const [warrantyRecordId, setWarrantyRecordId] = useState(null);

  const [claimentSource, setClaimentSource] = useState("");

  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
  const [openClaimDetailsModal, setOpenClaimDetailsModal] = useState(false);
  const [openWarrantyRequestModal, setOpenWarrantyRequestModal] = useState(false);
  const [showJobHoursModal, setShowJobHoursModal] = useState(false);
  const [showClaimErrorModal, setShowClaimErrorModal] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showClaimFileUploadModal, setShowClaimFileUploadModal] = useState(false);
  const [showViewFileModal, setShowViewFileModal] = useState(false);

  const [openQuickWarrantyCreateModal, setOpenQuickWarrantyCreateModal] = useState(false);

  const [showWarrantyRequestModal, setShowWarrantyRequestModal] = useState(false);
  const [openQuestionsModal, setOpenQuestionsModal] = useState(false);
  const [questionNo, setQuestionNo] = useState(0);

  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [isPreAuthorized, setIsPreAuthorized] = useState(false);
  const [showRepalcementQueModal, setShowRepalcementQueModal] = useState(false);
  const [showReplacmentModal, setShowReplacmentModal] = useState(false);
  const [openEnrollEquModal, setOpenEnrollEquModal] = useState(false);
  const [openEquFileUploadModal, setOpenEquFileUploadModal] = useState(false);
  const [equipmentRowData, setEquipmentRowData] = useState({ ...equipmentRequestObj });
  const [contWithNo, setContWithNo] = useState(false);

  const [showClaimRequestModal, setShowClaimRequestModal] = useState(false);
  const [openReplacementModal, setOpenReplacementModal] = useState(false);
  const [isReplacement, setIsReplacement] = useState(false);

  const [showWarrantyRequestWithPwaModal, setShowWarrantyRequestWithPwaModal] = useState(false);

  const [pwaNumber, setPwaNumber] = useState(null);
  const [warrantyRequestType, setWarrantyRequestType] = useState("");
  const [autorizationPreReqObj, setAutorizationPreReqObj] = useState({
    requesterDetails: "",
    requestType: "",
    customerNumber: "",
    customerName: "",
  });
  const [warrantyRequestFor, setWarrantyRequestFor] = useState("");

  const [warrantyReturnId, setWarrantyReturnId] = useState(null);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (
      !openClaimRequestModal &&
      !openPartCreateModal &&
      !openReturnRequsterModal &&
      !showFilesModal &&
      !showJobHoursModal &&
      !showClaimErrorModal &&
      !showClaimFileUploadModal &&
      !showViewFileModal
    ) {
      setClaimRecordId(null);
      setWarrantyRecordId(null);
      setClaimOrderId(null);
      setClaimRecordDetail(null);
      setRefresh((pre) => pre + 1);
    }

    if (!openReturnRequsterModal) {
      setPartsRecords([]);
    }
  }, [
    openClaimRequestModal,
    openPartCreateModal,
    openReturnRequsterModal,
    showFilesModal,
    showClaimFileUploadModal,
    showViewFileModal,
    showJobHoursModal,
    showClaimErrorModal,
  ]);

  useEffect(() => {
    getRecentClaimRecord();
    getEvaluationQuestions();
    getCountryKeyValueList();
  }, []);

  useEffect(() => {
    if (!openClaimRequestModal) {
      getClaimOrderList();
    }
  }, [openClaimRequestModal, claimOrderStatus]);

  useEffect(() => {
    getClaimWarrantyReqList();
  }, [warrantyStatus, refresh]);

  // get recent activities (claim)
  const getRecentClaimRecord = () => {
    const rUrl = `${CLAIM_MASTER_URL}?pageSize=${5}&sortColumn=updatedAt&orderBY=DESC`;
    // const rUrl = `${CLAIM_ORDER_MASTER_URL}?pageSize=${5}&sortColumn=updatedAt&orderBY=DESC`;
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setRecentClaimRecords(responseData);
      }
    });
  };

  // get recent activities (claim)
  const getClaimWarrantyReqList = () => {
    let rUrl = CLAIM_MASTER_URL;
    if (warrantyStatus === "all") {
      rUrl = rUrl + `?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
    } else {
      rUrl = rUrl + `/search-by-fields?field_name=claimStatus&field_value=${warrantyStatus}`;
    }
    // const rUrl = `${CLAIM_MASTER_URL}?pageNumber=${0}&pageSize=${10}&sortColumn=updatedAt&orderBY=DESC`;
    // // const rUrl = `${CLAIM_ORDER_MASTER_URL}?pageSize=${5}&sortColumn=updatedAt&orderBY=DESC`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setWarrantyReqClaims(responseData);
        } else {
          setWarrantyReqClaims([]);
        }
      },
      (error) => {
        setClaimRecordData([]);
      }
    );
  };

  // get claim list
  const getClaimOrderList = () => {
    let rUrl = CLAIM_ORDER_MASTER_URL;
    if (claimOrderStatus === "all") {
      rUrl = rUrl + `?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
    } else {
      rUrl = rUrl + `/search-by-fields?field_name=claimOrderStatus&field_value=${claimOrderStatus.replace(/_/g, " ")}`;
    }
    // const rUrl = `${CLAIM_ORDER_MASTER_URL}?pageNumber=${0}&pageSize=${10}&sortColumn=createdAt&orderBY=DESC`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          // setClaimRecordData(responseData);
          // getClaimOrderStatus(responseData);
          if (responseData.length !== 0) {
            setClaimRecordData(responseData);
            // getClaimOrderValue(responseData);
          } else {
            setClaimRecordData([]);
          }
        } else {
          setClaimRecordData([]);
        }
      },
      (error) => {
        setClaimRecordData([]);
      }
    );
  };

  // get claim order value
  const getClaimOrderValue = async (responseData) => {
    const recordArr = [];
    for (let row of responseData) {
      //  const searchStr = `${CLAIM_MASTER_URL}/${row.claimValueId}`;
      if (row.claimValueId) {
        const res = await claimValueSearchById(row.claimValueId);
        if (res.status === API_SUCCESS) {
          recordArr.push({
            ...row,
            totalAmountClaimed: res?.data?.totalAmountClaimed,
          });
        } else {
          recordArr.push({
            ...row,
            totalAmountClaimed: 0,
          });
        }
      } else {
        recordArr.push({
          ...row,
          totalAmountClaimed: 0,
        });
      }
    }
    getSettlementDetials(recordArr);
  };

  const getSettlementDetials = async (responseData) => {
    const recordArr = [];
    for (let row of responseData) {
      //  const searchStr = `${CLAIM_MASTER_URL}/${row.claimValueId}`;
      if (row.settlementValueId) {
        const res = await settlementValueSearchById(row.settlementValueId);
        if (res.status === API_SUCCESS) {
          recordArr.push({
            ...row,
            settledAmount: res?.data?.totalAmountClaimed,
            settlementStatus: res?.data?.type,
          });
        } else {
          recordArr.push({
            ...row,
            settledAmount: 0,
            settlementStatus: "",
          });
        }

        // if (res?.data?.type === "SETTLED") {
        //     handleSnack("success", `New settlement received for claim ${row.claimOrderNumber}`);
        // }
      } else {
        recordArr.push({
          ...row,
          settledAmount: 0,
          settlementStatus: "",
        });
      }
    }
    setClaimRecordData(recordArr);
  };

  const getClaimOrderStatus = async (responseData) => {
    const recordArr = [];

    for (let row of responseData) {
      // const searchStr = `${CLAIM_MASTER_URL}/${row.claimId}`;
      // if (row.claimId) {
      //     const res = await claimSearchById(row.claimId);
      //     recordArr.push({
      //         ...row,
      //         claimStatus: res.claimStatus,
      //     });
      // } else {
      //     recordArr.push({
      //         ...row,
      //         claimStatus: "REGISTERED",
      //     });
      recordArr.push({
        ...row,
        claimStatus: "REGISTERED",
      });
      // }
    }
    setClaimRecordData(recordArr);
  };

  // evaluation questions list
  const getEvaluationQuestions = () => {
    const rUrl = `${Warranty_Evaluation_Questions_Get_GET}pageNumber=${1}&pageSize=${10}`;
    // callGetApi( rUrl, (response) => {
    //   if (response.status === API_SUCCESS) {
    //     const responseData = response.data;
    //     const options = [];
    //     const shortQuestions = responseData.sort(
    //       (a, b) => a.questionId - b.questionId
    //     );
    //     shortQuestions.map((row) => {
    //       if (row.questionId === 5 || row.questionId === 6) {
    //         options.push({ ...row, type: "input" });
    //       } else {
    //         options.push({ ...row, type: "select" });
    //       }
    //     });
    //     setEvaluationQuestions(options);
    //   }
    // });
  };

  // country key value list
  const getCountryKeyValueList = () => {
    const rUrl = `${Warranty_Country_List_GET}?pageNumber=${0}&pageSize=${10}`;
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        const options = [];
        responseData.map((row) =>
          // options.push({ label: row.countryName, value: row.countryId })
          options.push({
            label: row.countryName,
            value: row.countryName,
          })
        );
        setCountryList(options);
      }
    });
  };

  // change tab
  const changeTab = (event, type) => {
    setTabValue(type);
  };

  //  select claim status
  const handleClaimStatus = (event) => {
    setClaimOrderStatus(event.target.value);
  };

  // Change Warranty status
  const handleWarrantyStatus = (event) => {
    setWarrantyStatus(event.target.value);
  };

  // claim filter expanded
  const handleClaimExpand = (panel) => (event, isExpanded) => {
    setClaimExpanded(isExpanded ? panel : false);
  };

  // warranty filter expanded
  const handleWarrantyExpand = (panel) => (event, isExpanded) => {
    setWarrantyExpanded(isExpanded ? panel : false);
  };

  // claim details model open
  const handleOpenClaimDetailsModal = (row) => {
    setContWithNo(false);
    setEquipmentRowData({ ...equipmentRequestObj });

    const _claimId = row["claimId"];
    if (_claimId) {
      setClaimRecordId(_claimId);
    }

    // const _warrantyId = row["warrantyId"];
    // if (_warrantyId) {
    //     setWarrantyRecordId(_warrantyId);
    // }

    const _claimOrderId = row["claimOrderId"];
    if (_claimOrderId) {
      setClaimOrderId(_claimOrderId);
    }

    // const _evaluationId = row["evaluationId"];
    // if (_evaluationId) {
    //     setEvaluationId(_evaluationId);
    // }

    // const _assessmentId = row["assessmentId"];
    // if (_assessmentId) {
    //     setAssesstmentId(_assessmentId);
    // }

    // if (_claimId) {
    //     callGetApi(null, `${CLAIM_MASTER_URL}/${_claimId}`, (response) => {
    //         if (response.status === API_SUCCESS) {
    //             const responseData = response.data;
    //             setClaimRecordDetail(responseData);
    //         }
    //     });
    // }
    setOpenClaimRequestModal(true);
  };

  // open claim authorixation Modal
  const handleOpenClaimAutModal = (row) => {
    setClaimRecordId(row.claimId);
    setShowAuthorizationModal(true);
    setEquipmentRowData({ ...equipmentRequestObj });
  };

  // claim order/Request model show hide
  const handleOpenClaimRequestModal = () => {
    setOpenClaimDetailsModal(false);
    setOpenClaimRequestModal(true);
  };

  // show part create modal
  const handleShowPartCreateModal = () => {
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenPartCreateModal(!openPartCreateModal);
  };

  // opem return requester model box
  const handleShowReturnRequetrModal = (row) => {
    if (row) {
      setPartsRecords([row]);
    } else {
      setPartsRecords([]);
    }
    setOpenClaimRequestModal(!openClaimRequestModal);
    setOpenReturnRequsterModal(!openReturnRequsterModal);
  };

  // open
  const handleShowWarrantyReqDtlsModal = () => {
    setOpenQuickWarrantyCreateModal(false);
    setShowWarrantyRequestWithPwaModal(true);
  };

  // open Quick Create|Warranty Request Modal
  const handleOpenWarrantyRequestModal = () => {
    setClaimRecordId(null);
    setShowWarrantyRequestModal(true);
  };

  const handleCloseWarrantyReqDtlModal = () => {
    setPwaNumber(null);
    setWarrantyRequestType("");
    setShowWarrantyRequestWithPwaModal(false);
  };

  // open questions Modal
  const handleOpenQuestionsModal = () => {
    setShowWarrantyRequestModal(false);
    setQuestionNo(0);
    setOpenQuestionsModal(true);
  };

  // open claaim request Create modal
  const handleShowClaimRequestModal = () => {
    setOpenQuestionsModal(false);
    setShowClaimRequestModal(true);
  };

  // Open Autorization Create Modal Box
  const handleOpenAutorizationCreateModal = (isPreAuthorizedReq = false) => {
    setOpenQuestionsModal(false);
    setShowAuthorizationModal(true);
    setIsPreAuthorized(isPreAuthorizedReq);
  };

  // go back to Authorization create model to Quesctions Scrren
  const handleGoBackToQurestionsModal = () => {
    if (warrantyRequestFor === "replacement") {
      setShowAuthorizationModal(false);
      setOpenReplacementModal(true);
    } else {
      setShowAuthorizationModal(false);
    }
    // setQuestionNo(2);
    // setShowAuthorizationModal(false);
    // setOpenQuestionsModal(true);
  };

  // show replacement Question Modal
  const handleShowReplacementQueModal = () => {
    setShowAuthorizationModal(false);
    setShowRepalcementQueModal(true);
  };
  // hide replacement Question Modal
  const handleCloseReplacementQueModal = () => {
    setShowRepalcementQueModal(false);
  };

  // open replacement modal
  const handleShowReplacementModal = () => {
    setShowRepalcementQueModal(false);
    setShowReplacmentModal(true);
  };

  // close replacement modal
  const handleCloseReplacementModal = () => {
    setShowReplacmentModal(false);
  };

  // open|close equipment create modal >> from authorization create modal
  const handleShowCreateNewEquModal = () => {
    setOpenEnrollEquModal(!openEnrollEquModal);
    setShowAuthorizationModal(!showAuthorizationModal);
  };

  // open file Upload Modal
  const handleShowEnrollFileUploadModal = () => {
    setOpenEnrollEquModal(!openEnrollEquModal);
    setOpenEquFileUploadModal(!openEquFileUploadModal);
  };

  // show job hours modal box
  const handleShowOrderJobHoursModal = () => {
    setShowJobHoursModal(!showJobHoursModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim errors modal box
  const handleShowClaimErrorsModal = () => {
    setShowClaimErrorModal(!showClaimErrorModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files modal box
  const handleShowFilesModal = () => {
    setShowFilesModal(!showFilesModal);
    setOpenClaimRequestModal(!openClaimRequestModal);
  };

  // show Claim Files upload modal box
  const handleShowClaimFileUploadModal = () => {
    setShowFilesModal(!showFilesModal);
    setShowClaimFileUploadModal(!showClaimFileUploadModal);
  };

  // show view files(image modal)
  const handleShowViewFileModal = () => {
    setShowViewFileModal(!showViewFileModal);
    setShowFilesModal(!showFilesModal);
  };

  // claim details model open
  const handleOpenClaimOrderCreateModal = (row) => {
    const _claimId = row["claimId"];
    if (_claimId) {
      setClaimRecordId(_claimId);
    }

    const _claimOrderId = row["claimOrderId"];
    if (_claimOrderId) {
      setClaimOrderId(_claimOrderId);
    }

    setContWithNo(true);
    setEquipmentRowData({
      ...equipmentRowData,
      maker: row?.make,
      model: row?.modelNumber,
      makerSerialNumber: row?.serialNumber,
      smu: row?.smu,
      fleetNo: row?.fleetNo,
      equipmentNumber: row?.equipmentNumber,
      market: row?.family,
      warrantyId: row?.warrantyId,
      currentClient: row?.customerNumber,
      customer: row?.customerName,
      emailId: row?.emailId,
      contact: row?.contact,
    });

    let _claiment = row?.claiment?.claiment || row?.claiment || "PARTNER";
    setClaimentSource(_claiment);

    setShowAuthorizationModal(false);
    setOpenClaimRequestModal(true);
  };

  const claimColumn = [
    {
      field: "claimOrderNumber",
      // field: "claimId",
      // field: "claimOrderId",
      headerName: "Claim Number",
      flex: 1,
    },
    {
      field: "claimRequestDate",
      headerName: "Submitted On",
      flex: 1,
    },
    {
      field: "partnerName",
      headerName: "Submitted By",
      flex: 1,
      renderCell: ({ row }) => row?.partnerName || "OEM",
    },
    {
      field: "claimOrderStatus",
      headerName: "Claim Status",
      flex: 1,
      // renderCell: ({ row }) => <>REGISTERED</>,
    },
    {
      field: "totalAmountClaimed",
      headerName: "Claim Value",
      flex: 1,
      renderCell: ({ row }) => <>{(row.totalAmountClaimed || 0).toFixed(2)}</>,
    },
    {
      field: "settledAmount",
      headerName: "Settled Amount",
      flex: 1,
      renderCell: ({ row }) => <>{(row.settledAmount || 0).toFixed(2)}</>,
    },
    {
      field: "settlementStatus",
      headerName: "Settlement Status",
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row?.claimOrderStatus === "REGISTERED" ||
          row?.claimOrderStatus === "CLAIM_SUBMITTED" ||
          row?.claimOrderStatus === "CLAIM_ACKNOWLEDGED" ||
          row?.claimOrderStatus === "CLAIM_ACCEPTED" ||
          row?.claimOrderStatus === "CONTESTED"
            ? "Settlement Pending"
            : row?.claimOrderStatus === "CLAIM_REJECTED"
            ? "Claim Rejected"
            : row?.claimOrderStatus === "SETTLED"
            ? "Settlement Received"
            : row?.claimOrderStatus === "ARCHIVED" || row?.claimOrderStatus === "CLOSED"
            ? "Settlement Closed"
            : ""}
        </>
      ),
    },
    // {
    //     field: "claimType",
    //     headerName: "Claim Type",
    //     flex: 1,
    // },
    // {
    //     field: "rmaNumber",
    //     headerName: "RMA Number",
    //     flex: 1,
    // },
    // {
    //     field: "claimRequestDate",
    //     // field: "createdOn",
    //     headerName: "Claim Date",
    //     flex: 1,
    // },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleOpenClaimDetailsModal(row)}>
                <Tooltip title="Claim Process">
                  {/* <CachedIcon /> */}
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const warrantyReqColumns = [
    {
      field: "claimId",
      // field: "claimNumber",
      headerName: "Warranty Request Number",
      flex: 1,
    },
    {
      field: "claimType",
      headerName: "Claim Type",
      flex: 1,
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      flex: 1,
    },
    {
      field: "authorizationCode",
      headerName: "Authorization Code",
      flex: 1,
    },
    {
      field: "claimReceiptDate",
      headerName: "Request Receipt Date",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleOpenClaimAutModal(row)}>
                <Tooltip title="Claim Process">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div>
        <div className="content-body bg-white" style={{ minHeight: "884px" }}>
          <div className="container-fluid mt-3">
            <h4 className="font-weight-600 mb-0 mt-4">Claim Administration</h4>
            <Grid
              container
              spacing={3}
              sx={{
                width: "100%",
                borderRadius: 5,
                marginBlock: 0,
              }}
            >
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="card border border-radius-10" style={{ height: "380px" }}>
                      <div
                        className="d-flex justify-content-between align-items-center p-3 border-bottom bg-light-pink"
                        style={{
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      >
                        <span className="font-size-14">Recent Activity</span>
                        {/* <MuiMenuComponent options={workFlowOptions} /> */}
                      </div>
                      <div className="px-3 py-1">
                        {recentClaimRecords.length !== 0 &&
                          recentClaimRecords.map((claimRow, i) => (
                            <div
                              className={`d-flex justify-content-between align-items-baseline ${i === 4 ? "" : "border-bottom"} py-2`}
                              key={claimRow?.claimId}
                            >
                              <div>
                                <h6 className="mb-1 text-primary">
                                  Claim {`${claimRow?.claimId}-${claimRow?.authorizationCode}`}
                                  {/* {
                                                                              claimRow?.claimNumber
                                                                          } */}
                                </h6>
                                <span className="cursor" onClick={() => handleOpenClaimDetailsModal(claimRow)}>
                                  <KeyboardArrowUpIcon />
                                  View Details
                                </span>
                              </div>
                              <span>
                                <Moment format="HH:MM A">{claimRow.updatedAt}</Moment>, <Moment format="DD MMM YY">{claimRow.updatedAt}</Moment>
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="card border border-radius-10" style={{ height: "380px" }}>
                      <div
                        className="d-flex justify-content-between align-items-center p-3 border-bottom workflow-task"
                        style={{
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      >
                        <span className="font-size-14">Workflow Task</span>
                        {/* <MuiMenuComponent options={workFlowOptions} /> */}
                      </div>
                      <div className="px-3 py-1">
                        <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                          <h6>
                            <span className="text-primary">77699</span> requires your attention
                          </h6>
                          <span>view Details</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                          <h6>
                            <span className="text-primary">77699</span> requires your attention
                          </h6>
                          <span>view Details</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                          <h6>
                            <span className="text-primary">77699</span> requires your attention
                          </h6>
                          <span>view Details</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                          <h6>
                            <span className="text-primary">77699</span> requires your attention
                          </h6>
                          <span>view Details</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-3">
                          <h6>
                            <span className="text-primary">77699</span> requires your attention
                          </h6>
                          <span>view Details</span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <div className="card border px-3 py-3 warranty-req-dash-card mb-4">
                  <div className="d-flex justify-content-between align-items-baseline cursor" onClick={handleOpenWarrantyRequestModal}>
                    <span className="mb-0 font-size-14">Warranty Request</span>
                    <span>
                      <ArrowForwardIosIcon />
                    </span>
                  </div>
                </div>
                <div className="card border px-3 py-3 claim-req-dash-card mb-4">
                  <div className="d-flex justify-content-between align-items-center cursor" onClick={() => setOpenClaimRequestModal(true)}>
                    <span className="mb-0 font-size-14">Claim Request</span>
                    <span className="font-weight-500">
                      <ArrowForwardIosIcon />
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container sx={{ width: "100%", backgroundColor: "#f3eafe", borderRadius: 5, marginBlock: 2 }}>
              <Grid item xs={12}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 3, marginInline: 5 }}>
                    <TabList className="" onChange={changeTab}>
                      <Tab label="CLAIMS REQUESTS" value={"claims"} className="heading-tabs" />
                      <Tab label="WARRANTY REQUESTS" value={"warranty"} className="heading-tabs" />
                    </TabList>
                  </Box>
                  <TabPanel value="claims" sx={{ marginTop: 0 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <Box sx={{ marginBlock: 2 }}>
                          <Accordion defaultExpanded expanded={claimExpanded === "claimStatus"} onChange={handleClaimExpand("claimStatus")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Claim Status</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RadioGroup value={claimOrderStatus} onChange={handleClaimStatus}>
                                <FormControl>
                                  <FormControlLabel label={<Typography sx={{ fontSize: 14 }}>All</Typography>} value={"all"} control={<Radio />} />
                                  {claimStatusOptions.map((statusType) => {
                                    if (
                                      statusType?.value === "REGISTERED" ||
                                      statusType?.value === "CLAIM_ACCEPTED" ||
                                      statusType?.value === "CLAIM_REJECTED" ||
                                      statusType?.value === "SETTLED" ||
                                      statusType?.value === "CONTESTED" ||
                                      statusType?.value === "CLOSED"
                                    ) {
                                      return (
                                        <FormControlLabel
                                          label={<Typography sx={{ fontSize: 14 }}>{statusType?.label}</Typography>}
                                          value={statusType?.value}
                                          control={<Radio />}
                                        />
                                      );
                                    }
                                  })}
                                </FormControl>
                              </RadioGroup>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={10} container>
                        <DataGridContainer>
                          <DataGrid
                            // loading={isLoading}
                            sx={GRID_STYLE}
                            getRowId={(row) => row.claimOrderId}
                            // getRowId={(row) => row.claimId}
                            rows={claimRecordData}
                            columns={claimColumn}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            autoHeight
                          />
                        </DataGridContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="warranty" sx={{ marginTop: 0 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <Box sx={{ marginBlock: 2 }}>
                          <Accordion defaultExpanded expanded={warrantyExpanded === "status"} onChange={handleWarrantyExpand("status")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Warranty Status</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RadioGroup value={warrantyStatus} onChange={handleWarrantyStatus}>
                                <FormControl>
                                  <FormControlLabel label={<Typography sx={{ fontSize: 14 }}>All</Typography>} value={"all"} control={<Radio />} />
                                  {warrantyClaimStatusOptions.map((statusType) => {
                                    if (statusType?.value === "REGISTERED") {
                                      return (
                                        <FormControlLabel
                                          key={statusType?.value}
                                          label={<Typography sx={{ fontSize: 14 }}>{statusType?.label}</Typography>}
                                          value={statusType?.value}
                                          control={<Radio />}
                                        />
                                      );
                                    }
                                  })}
                                </FormControl>
                              </RadioGroup>
                            </AccordionDetails>
                          </Accordion>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid item xs={10} container>
                        <DataGridContainer>
                          <DataGrid
                            // loading={isLoading}
                            sx={GRID_STYLE}
                            getRowId={(row) => row.claimId}
                            // getRowId={(row) => row.claimId}
                            rows={warrantyReqClaims}
                            columns={warrantyReqColumns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            autoHeight
                          />
                        </DataGridContainer>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* {openClaimDetailsModal && (
        <ClaimDetailsModal
          show={openClaimDetailsModal}
          hideModal={() => setOpenClaimDetailsModal(false)}
          recordId={claimRecordId}
          handleSnack={handleSnack}
          handleOpenClaimRequestModal={handleOpenClaimRequestModal}
        />
      )} */}

      {(openClaimRequestModal ||
        openPartCreateModal ||
        showJobHoursModal ||
        showClaimErrorModal ||
        showFilesModal ||
        showClaimFileUploadModal ||
        showViewFileModal ||
        openReturnRequsterModal) && (
        <ClaimsProcessingRequestModal
          show={openClaimRequestModal}
          hideModal={() => setOpenClaimRequestModal(false)}
          handleSnack={handleSnack}
          claimRecordDetail={claimRecordDetail}
          warrantyRecordId={warrantyRecordId}
          claimOrderId={claimOrderId}
          setClaimOrderId={setClaimOrderId}
          claimRecordId={claimRecordId}
          setClaimRecordId={setClaimRecordId}
          assesstmentId={assesstmentId}
          setAssesstmentId={setAssesstmentId}
          evaluationId={evaluationId}
          setEvaluationId={setEvaluationId}
          openPartCreateModal={openPartCreateModal}
          handleShowPartCreateModal={handleShowPartCreateModal}
          byAuthCode={false}
          requesterDetails={autorizationPreReqObj?.requesterDetails}
          showJobHoursModal={showJobHoursModal}
          handleShowOrderJobHoursModal={handleShowOrderJobHoursModal}
          showClaimErrorModal={showClaimErrorModal}
          handleShowClaimErrorsModal={handleShowClaimErrorsModal}
          showFilesModal={showFilesModal}
          handleShowFilesModal={handleShowFilesModal}
          showClaimFileUploadModal={showClaimFileUploadModal}
          handleShowClaimFileUploadModal={handleShowClaimFileUploadModal}
          showViewFileModal={showViewFileModal}
          handleShowViewFileModal={handleShowViewFileModal}
          handleShowReturnRequetrModal={handleShowReturnRequetrModal}
          openReturnRequsterModal={openReturnRequsterModal}
          partsRecords={partsRecords}
          countryOptions={countryList}
          equipmentRowData={equipmentRowData}
          contWithNo={contWithNo}
          claimentSource={claimentSource || "PARTNER"}
        />
      )}

      {/* {openWarrantyRequestModal && (
        <WarrantyRequestCreateModal show={openWarrantyRequestModal} hideModal={() => setOpenWarrantyRequestModal(false)} handleSnack={handleSnack} />
      )} */}
      {/* 
      {openQuickWarrantyCreateModal && (
        <QuickCreateModal
          show={openQuickWarrantyCreateModal}
          hideModal={() => setOpenQuickWarrantyCreateModal(false)}
          handleSnack={handleSnack}
          setPwaNumber={setPwaNumber}
          setWarrantyRequestType={setWarrantyRequestType}
          openWarrantyDetailsModal={handleShowWarrantyReqDtlsModal}
        />
      )} */}

      {/* {showWarrantyRequestModal && (
        <CreateWarrantyRequest
          show={showWarrantyRequestModal}
          hideModal={() => setShowWarrantyRequestModal(false)}
          handleSnack={handleSnack}
          setPwaNumber={setPwaNumber}
          setWarrantyRequestType={setWarrantyRequestType}
          handleOpenQuestionsModal={handleOpenQuestionsModal}
          setClaimRecordId={setClaimRecordId}
          setClaimRecordDetail={setClaimRecordDetail}
          setAutorizationPreReqObj={setAutorizationPreReqObj}
          // handleShowAutorizationModal={handleShowAutorizationModal}
        />
      )} */}
      {/* 
      {(openQuestionsModal || showClaimRequestModal) && (
        <WarrantyQuestions
          show={openQuestionsModal}
          hideModal={() => setOpenQuestionsModal(false)}
          handleSnack={handleSnack}
          openAutorizationCreateModal={handleOpenAutorizationCreateModal}
          openClaimRequestModal={showClaimRequestModal}
          handleOpenClaimRequestModal={handleShowClaimRequestModal}
          handleCloseRequestModal={() => setShowClaimRequestModal(false)}
          questionNo={questionNo}
          claimRecordId={claimRecordId}
          claimRecordDetail={claimRecordDetail}
          setClaimRecordDetail={setClaimRecordDetail}
          setAutorizationPreReqObj={setAutorizationPreReqObj}
          setIsReplacement={setIsReplacement}
        />
      )} */}

      {/* {(showAuthorizationModal || showRepalcementQueModal || showReplacmentModal || openEnrollEquModal || openEquFileUploadModal) && (
        <WarrantyRequestAuthorization
          show={showAuthorizationModal}
          // hideModal={handleCloseWarrantyReqDtlModal}
          hideModal={() => setShowAuthorizationModal(false)}
          handleSnack={handleSnack}
          pwaNumber={pwaNumber}
          setPwaNumber={setPwaNumber}
          warrantyRequestType={warrantyRequestType}
          claimRecordId={claimRecordId}
          setClaimRecordId={setClaimRecordId}
          handleGoBackToQurestionsModal={handleGoBackToQurestionsModal}
          claimRecordDetail={claimRecordDetail}
          autoReqObj={autorizationPreReqObj}
          isReplacement={isReplacement}
          handleCloseReplacementQueModal={handleCloseReplacementQueModal}
          showRepalcementQueModal={showRepalcementQueModal}
          showReplacmentModal={showReplacmentModal}
          handleShowReplacementQueModal={handleShowReplacementQueModal}
          handleShowReplacementModal={handleShowReplacementModal}
          handleCloseReplacementModal={handleCloseReplacementModal}
          handleShowCreateNewEquModal={handleShowCreateNewEquModal}
          openEnrollEquModal={openEnrollEquModal}
          openEquFileUploadModal={openEquFileUploadModal}
          handleOpenClaimOrderCreateModal={handleOpenClaimOrderCreateModal}
          isPreAuthorized={isPreAuthorized}
        />
      )} */}

      {/* {showWarrantyRequestWithPwaModal && (
        <WarrantyRequestModal
          show={showWarrantyRequestWithPwaModal}
          hideModal={handleCloseWarrantyReqDtlModal}
          handleSnack={handleSnack}
          pwaNumber={pwaNumber}
          warrantyRequestType={warrantyRequestType}
        />
      )} */}

      {/* {openReplacementModal && (
        <ReplacementModal show={openReplacementModal} hideModal={() => setOpenReplacementModal(false)} handleSnack={handleSnack} />
      )} */}
    </>
  );
};

export default ClaimsProcessingMaster;
