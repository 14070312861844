import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import $ from "jquery";
import SelectFilter from "react-select";

import { sparePartSearch } from "services/searchServices";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import { Link } from "@mui/material";

const partsSearchOptions = [
  { label: "Part Number", value: "partNumber" },
  { label: "Description", value: "partDescription" },
  { label: "Model", value: "model" },
];

const materialSearchOptions = [
  { label: "Material Number", value: "partNumber" },
  { label: "Description", value: "partDescription" },
  { label: "Model", value: "model" },
];

const PartsSeachMaster = ({ flagType, setSelectedPart, setViewDetails, setSearchPartList, handleSnack, showAddBtn = false, handleOpenModal }) => {
  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  // handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search category
  const handleSearchCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    obj.selectOptions = [];
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // input search
  const handleInputSearch = (e, i) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[i];
    let searchString = tempArray[i].selectCategory.value + "~" + e.target.value;
    if (tempArray[i].selectCategory.value && e.target.value) {
      sparePartSearch(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[i] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${i}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // select data from search list
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete the query search
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  // click on search button
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    setSelectedPart(null);
    setViewDetails(false);
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await sparePartSearch(searchStr);
        setSearchPartList(res);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // higlight text
  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="text-primary font-weight-500 font-size-13" style={{ color: "green" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <div className="w-100 equipment-select br-bl pb-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center border-radius-10">
            <div className="d-flex justify-content-between align-items-center border-radius-10">
              <div className="row align-items-center m-0">
                {querySearchSelector.length !== 0 &&
                  querySearchSelector.map((obj, i) => (
                    <div className={`customselect py-1 d-flex align-items-center mr-3${i > 0 ? " customselect-margin" : ""}`}>
                      {i > 0 && (
                        <SelectFilter
                          isClearable={true}
                          defaultValue={{ label: "And", value: "AND" }}
                          options={[
                            { label: "And", value: "AND", id: i },
                            { label: "OR", value: "OR", id: i },
                          ]}
                          placeholder="And/Or"
                          onChange={(e) => handleOperator(e, i)}
                          value={obj.selectOperator}
                        />
                      )}
                      <div>
                        <SelectFilter
                          // isClearable={true}
                          options={flagType === "Parts" ? partsSearchOptions : flagType === "Material" ? materialSearchOptions : []}
                          onChange={(e) => handleSearchCategory(e, i)}
                          value={obj.selectCategory}
                        />
                      </div>
                      <div className="customselectsearch custom-search-master pl-2">
                        <input
                          className="custom-input-sleact"
                          type="text"
                          value={obj.inputSearch}
                          placeholder={`Search by ${flagType} Number, Description and Model`}
                          id={"inputSearch-" + i}
                          autoComplete="off"
                          onChange={(e) => handleInputSearch(e, i)}
                          style={{ width: "94%" }}
                        />
                        <ul className={`list-group customselectsearch-list scrollbar scrollbar-${i} style`}>
                          {obj.selectOptions.map((currentItem, j) => (
                            <li
                              className="list-group-item part-search-master-list-item d-flex justify-content-between align-items-center"
                              key={j}
                              onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}
                            >
                              <div className="col-md-4 col-sm-4 text-start text-wrap px-2">
                                <div className="row">
                                  <div className="col-md-3 col-sm-3">
                                    <span
                                      className="d-flex justify-content-center align-items-center border-primary text-primary rounded-circle font-size-14"
                                      style={{ height: "28px", width: "28px" }}
                                    >
                                      J
                                    </span>
                                  </div>
                                  <div className="col-md-9 col-sm-9 text-truncate">
                                    <p className="mb-0 text-black">
                                      {obj.selectCategory.value === "partNumber"
                                        ? highlightText(currentItem.partNumber, obj.inputSearch)
                                        : currentItem.partNumber}
                                    </p>
                                    <span className="text-gray text-truncate">
                                      {obj.selectCategory.value === "partDescription"
                                        ? highlightText(currentItem.partDescription, obj.inputSearch)
                                        : currentItem.partDescription}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-2 text-start text-wrap border-left-1 border-right-1">
                                <div>
                                  <p className="mb-0 text-black">Type</p>
                                  <span className="text-gray font-weight-500">{currentItem?.partType}</span>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-2 text-start text-wrap px-2">
                                <div>
                                  <p className="mb-0 text-black">Price</p>
                                  <span className="text-gray font-weight-500">{currencyFormatter.format(currentItem?.listPrice || 0)}</span>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-2 text-start text-wrap px-2 border-left-1 border-right-1">
                                <div>
                                  <p className="mb-0 text-black">Status</p>
                                  <span className="text-gray font-weight-500">{currentItem?.status}</span>
                                </div>
                              </div>
                              <div className="col-md-2 col-sm-2 text-start text-wrap px-2">
                                <div>
                                  <p className="mb-0 text-black">Availability</p>
                                  <span className="text-gray font-weight-500">{currentItem?.availability || 0}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <SearchIcon className="text-primary" />
                      </div>
                    </div>
                  ))}
                <div className={`d-flex align-items-center mr-3 ${querySearchSelector.length > 1 ? "add-delete-mt" : ""}`}>
                  <div>
                    <Link to="#" className="btn-sm cursor p-0 font-size-16 mr-2 bg-white text-violet">
                      +
                    </Link>
                  </div>
                  <div onClick={handleDeleteQuerySearch}>
                    <Link to="#" className="p-1 bg-white cursor">
                      <svg width="14" height="14" viewBox="0 0 18 18" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                          fill="#872FF7"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5 2.25C7.08579 2.25 6.75 2.58579 6.75 3V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V3C5.25 1.75736 6.25736 0.75 7.5 0.75H10.5C11.7426 0.75 12.75 1.75736 12.75 3V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V3C11.25 2.58579 10.9142 2.25 10.5 2.25H7.5ZM3.75 3.75C4.16421 3.75 4.5 4.08579 4.5 4.5V15C4.5 15.4142 4.83579 15.75 5.25 15.75H12.75C13.1642 15.75 13.5 15.4142 13.5 15V4.5C13.5 4.08579 13.8358 3.75 14.25 3.75C14.6642 3.75 15 4.08579 15 4.5V15C15 16.2426 13.9926 17.25 12.75 17.25H5.25C4.00736 17.25 3 16.2426 3 15V4.5C3 4.08579 3.33579 3.75 3.75 3.75Z"
                          fill="#872FF7"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                          fill="#872FF7"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                          fill="#872FF7"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <Link className="btn bg-primary text-white cursor" onClick={handleQuerySearchClick}>
                <span className="ml-1">Search</span>
              </Link>
            </div>
          </div>
          {showAddBtn && (
            <span className="font-size-16 font-weight-400 cursor create-new-btn-span" onClick={handleOpenModal}>
              + Add New
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PartsSeachMaster;
