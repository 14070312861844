import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Tooltip } from "@mui/material";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { laborMasterRequestObj } from "../masterConstents";
import LoadingProgress from "pages/Repair/components/Loader";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { DATA_SVC_LABOUR_CONTROLLER } from "services/CONSTANTS";
import { masterstatusOptions } from "../equipmentConstant";

const LaborMasterAddUpdateModal = ({ show, hideModal, recordId, handleSnack, chargeCodeList, laborTypeList, serviceTypeList, laborCodeList }) => {
  const [recordData, setRecordData] = useState({ ...laborMasterRequestObj });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setViewModeOn(true);
      setLoading(true);
      const rUrl = DATA_SVC_LABOUR_CONTROLLER();
      callGetApi(
        `${rUrl}/${recordId}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _serviceType = serviceTypeList.find((row) => row?.value === responseData?.serviceType);
            const _laborType = laborTypeList.find((row) => row?.value === responseData?.laborType);
            const _chargeCode = chargeCodeList.find((row) => row?.value === responseData?.chargeCode);
            const _laborCode = laborCodeList.find((row) => row?.value === responseData?.laborCode);
            setRecordData({
              ...responseData,
              serviceType: _serviceType || "",
              laborType: _laborType || "",
              chargeCode: _chargeCode || "",
              laborCode: _laborCode || "",
              estimatedDuration: responseData?.plannedDuration,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  const handleSubmit = () => {
    const rUrl = DATA_SVC_LABOUR_CONTROLLER();
    const rObj = {
      ...recordData,
      serviceType: recordData?.serviceType?.value || "EMPTY",
      laborType: recordData?.laborType?.value || "EMPTY",
      chargeCode: recordData?.chargeCode?.value || "EMPTY",
      laborCode: recordData?.laborCode?.value || "EMPTY",
      status: recordData?.status?.value || "",
    };

    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Labor updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData.id });
            handleSnack("success", "Labor Created Successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h5 className="d-flex align-items-center mb-0">
            <div className="" style={{ display: "contents" }}>
              <span className="mr-3">Labor</span>
              <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
                <EditIcon />
              </a>
              <a className="btn-sm cursor">
                <BookmarkBorderIcon />
              </a>
              <a className="btn-sm cursor">
                <CreateNewFolderIcon />
              </a>
            </div>
            <div className="input-group icons border-radius-10 border">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                  <img src={shearchIcon} />
                </span>
              </div>
              <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
            </div>
          </h5>
          <hr className="mb-0" />
          {loading ? (
            <LoadingProgress />
          ) : (
            <div className="row mt-3">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">JOB CODE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="jobCode"
                            placeholder="Job Code"
                            value={recordData?.jobCode}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">STATUS</label>
                          <Select
                            className="text-primary"
                            value={recordData?.status}
                            onChange={(e) => setRecordData({ ...recordData, status: e })}
                            options={masterstatusOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICE DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="laborDescription"
                            placeholder="Service Description"
                            value={recordData?.laborDescription}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICE TYPE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.serviceType}
                            onChange={(e) => setRecordData({ ...recordData, serviceType: e })}
                            options={serviceTypeList}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">LABOR TYPE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.laborType}
                            onChange={(e) => setRecordData({ ...recordData, laborType: e })}
                            options={laborTypeList}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">CHARGE CODE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.chargeCode}
                            onChange={(e) => setRecordData({ ...recordData, chargeCode: e })}
                            options={chargeCodeList}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">LABOR CODE</label>
                          <Select
                            className="text-primary"
                            value={recordData?.laborCode}
                            onChange={(e) => setRecordData({ ...recordData, laborCode: e })}
                            options={laborCodeList}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">RECOMMENDED DURATION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="recommendedDuration"
                            placeholder="Recommended Duration"
                            value={recordData?.recommendedDuration}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ACTUAL DURATION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="plannedDuration"
                            placeholder="Actual Duration"
                            value={recordData?.plannedDuration}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">ESTIMATED DURATION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="estimatedDuration"
                            placeholder="Estimated Duration"
                            value={recordData?.estimatedDuration}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            value={recordData?.comments}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div> */}
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="JOB CODE" value={recordData?.jobCode} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERVICE DESCRIPTION" value={recordData?.laborDescription} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERVICE TYPE" value={recordData?.serviceType?.label} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="LABOR TYPE" value={recordData?.laborType?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="CHARGE CODE" value={recordData?.chargeCode?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="LABOR CODE" value={recordData?.laborCode?.label} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="RECOMMENDED DURATION" value={recordData?.recommendedDuration} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ACTUAL DURATION" value={recordData?.plannedDuration} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="ESTIMATED DURATION" value={recordData?.estimatedDuration} />
                    </div>

                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-12 col-sm-12" label="COMMENTS" value={recordData?.comments} />
                    </div>
                  </>
                )}
                {!viewModeOn ? (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Cancel
                    </button>
                    <button
                      className="btn bg-primary text-white"
                      onClick={handleSubmit}
                      disabled={
                        !recordData?.serviceType?.value ||
                        !recordData?.laborType?.value ||
                        !recordData?.chargeCode?.value ||
                        !recordData?.laborCode?.value
                      }
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Labor Version</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className="">
                        {/* <MuiMenuComponent options={activityOptions} /> */}
                      </a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>SERVICE DESCRIPTION</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Engine repair
                  </p>
                  <p className="text-black mb-2">Engine Overhaul</p>
                  <div className="mt-3">
                    <small>CHARGE CODE</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Minimum service charge
                  </p>
                  <p className="text-black mb-2">Additional Service Charge</p>
                  <div className="mt-3">
                    <small>STATUS</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Saved
                  </p>
                  <p className="text-black mb-2">Active</p>
                  {/* <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LaborMasterAddUpdateModal;
