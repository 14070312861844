export const inventoryOptions = [
  { label: "Parts", value: "PARTS" },
  { label: "Consumables", value: "CONSUMABLES" },
];

export const plantByOptions = [
  { label: "Delhi", value: "Delhi" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Karnataka", value: "Karnataka" },
  { label: "Odisha", value: "Odisha" },
];

export const deliveryTypeOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Express", value: "EXPRESS" },
];

export const sippingConditionOptions = [
  { label: "Ship to Me", value: "INTRA_COMPANY" },
  { label: "Pickup", value: "WITHIN_COMPANY" },
];

export const expendTableCustomStyle = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#872ff7",
      color: "#fff",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};
export const expendedTableCustomStyle = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#F9FAFB",
      color: "#9699A2",
      borderRadius: 0,
      // borderRight: "1px solid rgba(0,0,0,.12)",
      // BorderBottom: 0,
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      // borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};

export const partsOrderReqObj = {
  partsOrderName: "",
  source: "",
  customerId: "",
  customerName: "",
  contactEmail: "",
  contactPhone: "",
  requester: "",
  customerGroup: "",
  customerSegment: "",
  make: "",
  model: "",
  serialNumber: "",
  family: "",
  smu: "",
  fleetNo: "",
  registrationNo: "",
  chasisNo: "",
  preparedBy: "",
  approvedBy: "",
  preparedOn: new Date(),
  revisedBy: "",
  revisedOn: new Date(),
  salesOffice: "",
  partsOrderDate: new Date(),
  description: "",
  reference: "",
  validity: "FIFTEEN_DAYS",
  version: "VERSION_1",
  paymentTerms: "IMMEDIATE",
  billingFrequency: "ONE_TIME",
  billingType: "PAY_SUPPORT",
  deliveryType: "STANDARD",
  deliveryPriority: "NORMAL",
  leadTime: "",
  serviceRecipientAddress: "",
  priceDate: new Date(),
  priceMethod: "LIST_PRICE",
  currency: "USD",
  partsPrice: 0,
  servicePrice: 0,
  laborPrice: 0,
  miscPrice: 0,
  environmentalPrice: 0,
  tax: 0,
  costPrice: 0,
  deliveryPrice: 0,
  totalPrice: 0,
  margin: 0,
  discount: 0,
  netPrice: 0,
  adjustedPrice: 0,
  status: "PENDING_ACTIVE",
  builderId: "",
  standardJobId: "",
  kitId: "",
  builderType: "BUILDER_WITH_SPAREPART",
  dealerStore: "",
  accountName: "",
  machineSerialNumber: "",
  orderType: "EMMERGENCY",
  orderedBy: "",
  emailAddress: "",
  phoneNumber: "",
  shippingCondition: "SHIP_TO_ME",
  pickUpInst: "",
  shippingAddress: "",
  billingAddress: "",
  shippingPoint: "",
  demandIndicator: false,
  partialShipment: false,
  machineBreakdown: false,
  needInstallationHelp: false,
  orderAsFavorite: false,
  cart: 0,
  discountApproved: false,
  updatedViaLink: false,
  orderRequisition: false,
  otherItemPrice: 0,
  review: false,
  favorite: false,
};

export const inverntoryPartSearchOptions = [
  { label: "Part Number", value: "partNumber" },
  { label: "Plant", value: "application" },
  { label: "Warehouse", value: "legacyMaterial" },
  { label: "Model", value: "model" },
];

export const serviceLocationSearchOptions = [
  { label: "Name", value: "name" },
  { label: "Employee", value: "employee" },
  { label: "Location Type", value: "locationtype" },

]

export const inventoryStatusColor = {
  "in stock": "#00b8b0",
  "low stock": "#d8bd5f",
  "out of stock": "#c14e56",
};

export const inventoryStatusBgColor = {
  "in stock": "#dfffc9",
  "low stock": "#e6c6566b",
  "out of stock": "#c14e567a",
};

export const stoReqQObj = {
  stoNumber: "",
  orderNumber: "",
  referenceDescription: "",
  referenceId: "",
  deliveryType: "",
  shippingCondition: "",
  senderPlant: "",
  senderStorageLocation: "",
  shippedOn: new Date(),
  trackingNumber: "",
  receiverPlant: "",
  receiverLocation: "",
  receiverAddress: "",
};

export const prReqQObj = {
  // requisitionNumber: "",
  // orderNumber: "",
  // referenceDescription: "",
  // deliveryType: "",
  // shippingCondition: "",
  // senderStorageLocation: "",
  // shippedOn: new Date(),
  // trackingNumber: "",
  // receiverPlant: "",
  // receiverLocation: "",
  // receiverAddress: "",

  prNumber: "",
  orderNumber: "",
  referenceDescription: "",
  referenceId: "",
  deliveryType: "",
  shippingCondition: "",
  senderPlant: "",
  senderStorageLocation: "",
  shippedOn: new Date(),
  trackingNumber: "",
  receiverPlant: "",
  receiverLocation: "",
  receiverAddress: "",
  warehouseId: 0,
  prPartsQuantities: [],
  description: "",
};

export const grReqObj = {
  // stoNumber: "",
  // orderNumber: "",
  // referenceDescription: "",
  // deliveryType: "",
  // shippingCondition: "",
  // senderPlant: "",
  // senderStorageLocation: "",
  // shippedOn: new Date(),
  // trackingNumber: "",
  // receiverPlant: "",
  // receiverLocation: "",
  // receiverAddress: "",

  grNumber: "",
  stoNumber: "",
  orderNumber: "",
  referenceDescription: "",
  referenceId: "",
  deliveryType: "",
  shippingCondition: "",
  senderPlant: "",
  senderStorageLocation: "",
  shippedOn: new Date(),
  trackingNumber: "",
  receiverPlant: "",
  receiverLocation: "",
  receiverAddress: "",
  warehouseId: 0,
  grPartsQuantities: [],
  description: "",
};

export const prStatusOptions = [
  { label: "Draft", value: "DRAFT" },
  { label: "Active", value: "ACTIVE" },
  { label: "Sent for Approval", value: "SENT_FOR_APPROVAL" },
  { label: "Approved", value: "APPROVED" },
];

export const defaultPartsRecord = [
  {
    id: 15143,
    partNumber: "test125",
    replacedByPartNumber: "part123",
    replacesPartNumber: "part124",
    manufacturer: "Makino124",
    model: "J3",
    groupNumber: "test",
    partDescription: "test desc",
    partsGroup: "",
    modelGroupDescription: "inf",
    partGroupDescription: "inf2",
    yearOfManufacture: "2023",
    salesUnit: "PC",
    listPrice: 458.55,
    costPrice: 356.2,
    currency: "INR",
    partType: "NEW",
    usageArea: "usage1",
    availability: "122",
    status: "ACTIVE",
    partComplexity: "partc",
    partUsage: "545",
    demand: "demand1",
    application: "app1",
    erpMaterialNumber: "erp123",
    legacyMaterial: "legacy123",
    alternativePart: "",
    materialGroup: null,
    materialGroupDescription: "",
    becCode: "",
    becCodeDescription: "",
    sparepartImages: [null],
    createdAt: "2024-07-17T10:44:30.196083",
    updatedAt: "2024-07-29T18:27:05.039846",
    createdBy: "74",
    updatedBy: "74",
    stockStatus: "In Stock",
    stockQuantity: 200,
    wareHouseStocks: [
      {
        id: 20240801,
        partNumber: "test125",
        partDescription: "test desc",
        wareHouseNumber: "WH-103023",
        stockQuantity: 100,
        status: "In Stock",
      },
      {
        id: 20240802,
        partNumber: "test125",
        partDescription: "test desc",
        wareHouseNumber: "WH-103024",
        stockQuantity: 30,
        status: "In Stock",
      },
      {
        id: 20240803,
        partNumber: "test125",
        partDescription: "test desc",
        wareHouseNumber: "WH-103025",
        stockQuantity: 50,
        status: "In Stock",
      },
      {
        id: 20240804,
        partNumber: "test125",
        partDescription: "test desc",
        wareHouseNumber: "WH-103026",
        stockQuantity: 20,
        status: "In Stock",
      },
    ],
  },
  {
    id: 15142,
    partNumber: "test123",
    replacedByPartNumber: "",
    replacesPartNumber: "",
    manufacturer: "Makino123",
    model: "J3",
    groupNumber: "test",
    partDescription: "test desc",
    partsGroup: "",
    modelGroupDescription: "",
    partGroupDescription: "",
    yearOfManufacture: "",
    salesUnit: "PC",
    listPrice: 5426.36,
    costPrice: 4251.13,
    currency: "INR",
    partType: "NEW",
    usageArea: "",
    availability: "20",
    status: "ACTIVE",
    partComplexity: "",
    partUsage: "556",
    demand: "",
    application: "",
    erpMaterialNumber: "",
    legacyMaterial: "",
    alternativePart: "",
    materialGroup: null,
    materialGroupDescription: "",
    becCode: "",
    becCodeDescription: "",
    sparepartImages: [],
    createdAt: "2024-07-17T10:44:30.107072",
    updatedAt: "2024-07-17T10:44:30.107072",
    createdBy: "74",
    updatedBy: "74",
    stockStatus: "Low Stock",
    stockQuantity: 10,
    wareHouseStocks: [
      {
        id: 20240901,
        partNumber: "test123",
        partDescription: "test desc",
        wareHouseNumber: "WH-103123",
        stockQuantity: 3,
        status: "Low Stock",
      },
      {
        id: 20240902,
        partNumber: "test123",
        partDescription: "test desc",
        wareHouseNumber: "WH-103124",
        stockQuantity: 5,
        status: "Low Stock",
      },
      {
        id: 20240903,
        partNumber: "test123",
        partDescription: "test desc",
        wareHouseNumber: "WH-103125",
        stockQuantity: 1,
        status: "Low Stock",
      },
      {
        id: 20240904,
        partNumber: "test123",
        partDescription: "test desc",
        wareHouseNumber: "WH-103126",
        stockQuantity: 1,
        status: "Low Stock",
      },
    ],
  },
  {
    id: 15141,
    partNumber: "Z411A-0003",
    replacedByPartNumber: "",
    replacesPartNumber: "",
    manufacturer: "Makino",
    model: "S33",
    groupNumber: "",
    partDescription: "POWER PACK, APC (ACE SYSMEC)*",
    partsGroup: "",
    modelGroupDescription: "",
    partGroupDescription: "",
    yearOfManufacture: "",
    salesUnit: "PC",
    listPrice: 4536.88,
    costPrice: 4500.45,
    currency: "INR",
    partType: "NEW",
    usageArea: "",
    availability: "52",
    status: "ACTIVE",
    partComplexity: "",
    partUsage: "545",
    demand: "",
    application: "",
    erpMaterialNumber: "",
    legacyMaterial: "",
    alternativePart: "",
    materialGroup: null,
    materialGroupDescription: "",
    becCode: "",
    becCodeDescription: "",
    sparepartImages: [],
    createdAt: "2024-07-16T20:31:19.151193",
    updatedAt: "2024-07-16T20:31:19.151193",
    createdBy: "74",
    updatedBy: "74",
    stockStatus: "Out of Stock",
    stockQuantity: 0,
    wareHouseStocks: [],
  },
  {
    id: 15140,
    partNumber: "Z297Z-0015-00",
    replacedByPartNumber: "",
    replacesPartNumber: "",
    manufacturer: "Makino",
    model: "S33",
    groupNumber: "",
    partDescription: "BELLOW, X-RIGHT (650)*",
    partsGroup: "",
    modelGroupDescription: "",
    partGroupDescription: "",
    yearOfManufacture: "",
    salesUnit: "PC",
    listPrice: 476.89,
    costPrice: 470.43,
    currency: "INR",
    partType: "NEW",
    usageArea: "",
    availability: "84",
    status: "ACTIVE",
    partComplexity: "",
    partUsage: "556",
    demand: "",
    application: "",
    erpMaterialNumber: "",
    legacyMaterial: "",
    alternativePart: "",
    materialGroup: null,
    materialGroupDescription: "",
    becCode: "",
    becCodeDescription: "",
    sparepartImages: [],
    createdAt: "2024-07-16T20:31:19.12819",
    updatedAt: "2024-07-16T20:31:19.12819",
    createdBy: "74",
    updatedBy: "74",
    stockStatus: "In Stock",
    stockQuantity: 160,
    wareHouseStocks: [
      {
        id: 20241001,
        partNumber: "Z297Z-0015-00",
        partDescription: "BELLOW, X-RIGHT (650)*",
        wareHouseNumber: "WH-102923",
        stockQuantity: 80,
        status: "In Stock",
      },
      {
        id: 20241002,
        partNumber: "Z297Z-0015-00",
        partDescription: "BELLOW, X-RIGHT (650)*",
        wareHouseNumber: "WH-102924",
        stockQuantity: 50,
        status: "In Stock",
      },
      {
        id: 20241003,
        partNumber: "Z297Z-0015-00",
        partDescription: "BELLOW, X-RIGHT (650)*",
        wareHouseNumber: "WH-102925",
        stockQuantity: 50,
        status: "In Stock",
      },
    ],
  },
  {
    id: 15139,
    partNumber: "Z297Z-0014-00",
    replacedByPartNumber: "",
    replacesPartNumber: "",
    manufacturer: "Makino",
    model: "S33",
    groupNumber: "",
    partDescription: "BELLOW, X-LEFT (650)",
    partsGroup: "",
    modelGroupDescription: "",
    partGroupDescription: "",
    yearOfManufacture: "",
    salesUnit: "PC",
    listPrice: 3846.34,
    costPrice: 3800.99,
    currency: "INR",
    partType: "NEW",
    usageArea: "",
    availability: "25",
    status: "ACTIVE",
    partComplexity: "",
    partUsage: "545",
    demand: "",
    application: "",
    erpMaterialNumber: "",
    legacyMaterial: "",
    alternativePart: "",
    materialGroup: null,
    materialGroupDescription: "",
    becCode: "",
    becCodeDescription: "",
    sparepartImages: [],
    createdAt: "2024-07-16T20:31:19.103254",
    updatedAt: "2024-07-16T20:31:19.103254",
    createdBy: "74",
    updatedBy: "74",
    stockStatus: "In Stock",
    stockQuantity: 100,
    wareHouseStocks: [
      {
        id: 20240701,
        partNumber: "Z297Z-0014-00",
        partDescription: "BELLOW, X-LEFT (650)",
        wareHouseNumber: "WH-105123",
        stockQuantity: 10,
        status: "In Stock",
      },
      {
        id: 20240702,
        partNumber: "Z297Z-0014-00",
        partDescription: "BELLOW, X-LEFT (650)",
        wareHouseNumber: "WH-105124",
        stockQuantity: 30,
        status: "In Stock",
      },
      {
        id: 20240703,
        partNumber: "Z297Z-0014-00",
        partDescription: "BELLOW, X-LEFT (650)",
        wareHouseNumber: "WH-105125",
        stockQuantity: 50,
        status: "In Stock",
      },
      {
        id: 20240704,
        partNumber: "Z297Z-0014-00",
        partDescription: "BELLOW, X-LEFT (650)",
        wareHouseNumber: "WH-105126",
        stockQuantity: 10,
        status: "In Stock",
      },
    ],
  },
];

export const partsReqObj = {
  id: 0,
  partNumber: "",
  replacedByPartNumber: "",
  replacesPartNumber: "",
  manufacturer: "",
  model: "",
  groupNumber: "",
  partDescription: "",
  partsGroup: "",
  modelGroupDescription: "",
  partGroupDescription: "",
  yearOfManufacture: "",
  salesUnit: "",
  listPrice: 0,
  costPrice: 0,
  currency: "",
  partType: "",
  usageArea: "",
  availability: "",
  status: "",
  partComplexity: "",
  partUsage: "",
  demand: "",
  application: "",
  erpMaterialNumber: "",
  legacyMaterial: "",
  alternativePart: "",
  materialGroup: "",
  materialGroupDescription: "",
  becCode: "",
  becCodeDescription: "",
  sparepartImages: [],
  warehouses: [],
  createdBy: "",
  updatedBy: "",
};

export const warehouseInventory = {
  warehouseId: 0,
  sparepartId: 0,
  availableQuantity: 0,
  qualityInspectionQuantity: 0,
  blockedQuantity: 0,
  onOrderStockQuantity: 0,
  consignmentStockQuantity: 0,
  totalQuantity: 0,
};
