import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Modal } from "react-bootstrap";

import SearchBox from "pages/Common/SearchBox";
import { machineSearch } from "services/searchServices";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SPARE_PARTS_MASTER_URL_DATA_SVC } from "services/CONSTANTS";
import { partsMasterRequestObj } from "../masterConstents";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { Tooltip } from "@mui/material";
import LoadingProgress from "pages/Repair/components/Loader";

const PartAddUpdateModal = ({ show, hideModal, recordId = null, handleSnack, title = "Part" }) => {
  const [recordData, setRecordData] = useState(partsMasterRequestObj);
  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [machineData, setMachineData] = useState({
    make: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    equipmentNumber: "",
    family: "",
  });

  const [customerData, setCustomerData] = useState({
    customerID: "",
    customerName: "",
    contactEmail: "",
    contactPhone: "",
  });

  useEffect(() => {
    if (recordId) {
      setViewModeOn(true);
      setLoading(true);
      const rUrl = `${SPARE_PARTS_MASTER_URL_DATA_SVC}/${recordId}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...responseData });
            setMachineData({ ...machineData, model: responseData.model, manufacturer: responseData.manufacturer });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // change the input value
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} %26%26 makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({ ...machineData, model: currentItem.model, manufacturer: currentItem.maker });
      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setMachineData({
        ...machineData,
        manufacturer: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });

      //   if (currentItem?.replacedEquipmentNumber) {
      //     getSelectEquReplacementCode(currentItem?.replacedEquipmentNumber);
      //   }

      setCustomerData({ ...customerData, customerID: currentItem.currentClient, customerName: currentItem.customer });
      setSearchSerialResults([]);
    }
  };

  // click on submit button
  const handleSubmit = () => {
    const rUrl = SPARE_PARTS_MASTER_URL_DATA_SVC;
    const rObj = { ...recordData };

    if (recordData?.id) {
      callPutApi(null, `${rUrl}/${recordData?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleSnack("success", "Part updated successfully!");
          hideModal();
        } else {
          handleSnack("error", "Error occurred while updating the part!");
        }
      });
    } else {
      callPostApi(null, rUrl, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setRecordData({ ...recordData, id: responseData?.id });
          handleSnack("success", "Part is created successfully");
          hideModal();
        } else {
          handleSnack("error", "Error occurred while creating the part!");
        }
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h5 className="d-flex align-items-center mb-0">
            <div className="" style={{ display: "contents" }}>
              <span className="mr-3">Part</span>
              <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
                <EditIcon />
              </a>
              <a className="btn-sm cursor">
                <BookmarkBorderIcon />
              </a>
              <a className="btn-sm cursor">
                <CreateNewFolderIcon />
              </a>
            </div>
            <div className="input-group icons border-radius-10 border">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                  <img src={shearchIcon} />
                </span>
              </div>
              <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
            </div>
          </h5>
          <hr className="mb-0" />
          {loading ? (
            <LoadingProgress />
          ) : (
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="partDescription"
                            placeholder="Description"
                            value={recordData?.partDescription}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">STATUS</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="status"
                            placeholder="Status"
                            value={recordData?.status}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            id="manufacturer"
                            placeholder="Manufacturer"
                            value={machineData?.manufacturer}
                            disabled
                            // onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                          <SearchBox
                            value={machineData.model}
                            onChange={(e) => handleMachineSearch("model", e.target.value)}
                            type="model"
                            result={searchModelResults}
                            onSelect={handleModelSelect}
                            noOptions={noOptionsModel}
                            placeholder="Model"
                          />
                          <div className="css-w8dmq8">*Mandatory</div>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="partType"
                            placeholder="Type"
                            value={recordData?.partType}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">GROUP NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="groupNumber"
                            placeholder="Group Number"
                            value={recordData?.groupNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">{title === "part" ? "PARTS" : "MATERIAL"} GROUP</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="partsGroup"
                            placeholder="Parts Group"
                            value={recordData?.partsGroup}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">BEC CODE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            id="becCode"
                            placeholder="BEC Code"
                            value={recordData?.becCode}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">BEC CODE DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            id="becCodeDescription"
                            placeholder="BEC Code Description"
                            value={recordData?.becCodeDescription}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER (IF ANY)</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="serialNumber"
                            placeholder="Serial Number"
                            value={recordData?.serialNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            value={recordData?.comments}
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="DESCRIPTION" value={recordData?.partDescription} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="STATUS" value={recordData?.status} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="MANUFACTURER" value={machineData?.manufacturer} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="MODEL" value={machineData?.model} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="TYPE" value={recordData?.partType} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="GROUP NUMBER" value={recordData?.groupNumber} />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label={`${title === "part" ? "PARTS" : "MATERIAL"} GROUP`}
                        value={recordData?.partsGroup}
                      />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="BEC CODE" value={recordData?.becCode} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="BEC CODE DESCRIPTION" value={recordData?.becCodeDescription} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERIAL NUMBER (IF ANY)" value={recordData?.serialNumber} />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField className="col-md-12 col-sm-12" label="COMMENTS" value={recordData?.comments} />
                    </div>
                  </>
                )}
                {!viewModeOn ? (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Cancel
                    </button>
                    <button className="btn bg-primary text-white" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Labor 1</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className="">
                        {/* <MuiMenuComponent options={activityOptions} /> */}
                      </a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>MAKE</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Chinalco Sa,Beijing,Chaina(code 302037)
                  </p>
                  <p className="text-black mb-2">Chinalco Sa,Beijing,Chaina(code 302037)</p>
                  <div className="mt-3">
                    <small>FAMILY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Alberto Franco,Head of Purchase
                  </p>
                  <p className="text-black mb-2">Alberto Franco,Head of Purchase</p>
                  <div className="mt-3">
                    <small>RECOMMENDED FREQUENCY</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    SFI234
                  </p>
                  <p className="text-black mb-2">SFI234</p>
                  <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PartAddUpdateModal;
