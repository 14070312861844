import React, { useState } from "react";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

import { Grid, Tooltip } from "@mui/material";
import ShopReapirInspectionDetailsModal from "./ShopReapirInspectionDetailsModal";

const ShopRepairDisassembly = ({ setActiveStep, handleSnack }) => {
    const [openInspectionDtlsModal, setOpenInspectionDtlsModal] = useState(false);

    const handleOpenInspectionDtlsModal = () => {
        setOpenInspectionDtlsModal(true);
    };
    return (
        <>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3">
                        <div className="d-flex align-items-center mb-2">
                            <TimerOutlinedIcon />
                            <h5 className="ml-1 mb-0">Machine Details</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Check-in-date</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2024-03-15</span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Hours Operated</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2500</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Client</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">BuildCorp Inc.</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3">
                        <div className="d-flex align-items-center mb-2">
                            <TimerOutlinedIcon />
                            <h5 className="ml-1 mb-0">Disassembly Details</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Check-in-date</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2024-03-15</span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Hours Operated</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2500</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Client</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">BuildCorp Inc.</span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3">
                        <div className="d-flex justify-content-between aloign-items-center">
                            <div className="d-flex align-items-center mb-2">
                                <svg id="Layer_1" width={16} height={16} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.5 122.88">
                                    <path
                                        class="cls-1"
                                        d="M17.88,22.75a2.19,2.19,0,0,1,3.05.6L22,24.66l3.84-4.87a2.2,2.2,0,1,1,3.4,2.78L23.6,29.66a2.74,2.74,0,0,1-.52.5A2.21,2.21,0,0,1,20,29.55L17.28,25.8a2.21,2.21,0,0,1,.6-3.05ZM81.13,59a27.86,27.86,0,0,1,23.31,43.1l12.06,13.14-8.31,7.6L96.56,110.09A27.86,27.86,0,1,1,81.13,59ZM38.47,71.54a3.07,3.07,0,0,1-2.9-3.17,3,3,0,0,1,2.9-3.17h9a3.07,3.07,0,0,1,2.9,3.17,3,3,0,0,1-2.9,3.17ZM93,44.89c-.56,2.11-5.31,2.43-6.38,0V7.43a1.06,1.06,0,0,0-.3-.76,1.08,1.08,0,0,0-.75-.3H7.39a1,1,0,0,0-1,1.06V95.74a1,1,0,0,0,1,1.05H37.72c3.21.34,3.3,5.88,0,6.38H7.43A7.48,7.48,0,0,1,0,95.74V7.43A7.3,7.3,0,0,1,2.19,2.19,7.35,7.35,0,0,1,7.43,0H85.6a7.32,7.32,0,0,1,5.24,2.19A7.39,7.39,0,0,1,93,7.43c0,36.56,0-18,0,37.46ZM38.44,27.47a3.07,3.07,0,0,1-2.91-3.17,3,3,0,0,1,2.91-3.17H68.21a3.07,3.07,0,0,1,2.91,3.17,3,3,0,0,1-2.91,3.17Zm0,22a3.06,3.06,0,0,1-2.91-3.16,3,3,0,0,1,2.91-3.17H68.21a3.07,3.07,0,0,1,2.91,3.17,3,3,0,0,1-2.91,3.16Zm32.19,40a3.4,3.4,0,0,1-.38-.49,3.71,3.71,0,0,1-.29-.56A3.54,3.54,0,0,1,75.05,84a2.78,2.78,0,0,1,.56.41l0,0c1,.93,1.28,1.12,2.36,2.08l.92.83,7.58-8.13c3.21-3.3,8.32,1.53,5.12,4.9L82.15,94.26l-.47.5a3.56,3.56,0,0,1-5,.22l0,0L76,94.28c-.58-.52-1.18-1-1.79-1.57-1.4-1.22-2.22-1.89-3.54-3.21ZM81.15,64.85A22.17,22.17,0,1,1,59,87,22.17,22.17,0,0,1,81.15,64.85ZM23.54,63.59a5.1,5.1,0,1,1-5.09,5.09,5.09,5.09,0,0,1,5.09-5.09ZM25.66,42a2.09,2.09,0,0,1,3,0,2.12,2.12,0,0,1,0,3l-2.07,2.13,2.07,2.13a2.1,2.1,0,0,1-3,3l-2.05-2.1-2.07,2.11a2.07,2.07,0,0,1-3,0,2.13,2.13,0,0,1,0-3l2.08-2.13L18.57,45a2.1,2.1,0,0,1,0-3,2.07,2.07,0,0,1,2.94,0l2.06,2.11L25.66,42Z"
                                    />
                                </svg>
                                <h5 className="ml-1 mb-0">Inspection Details</h5>
                            </div>
                            <div className="cursor" onClick={handleOpenInspectionDtlsModal}>
                                <Tooltip title="View Inspection Details">
                                    {/* <RemoveRedEyeIcon /> */}
                                    <svg id="Layer_1" width={16} height={16} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.5 122.88">
                                        <path
                                            class="cls-1"
                                            d="M17.88,22.75a2.19,2.19,0,0,1,3.05.6L22,24.66l3.84-4.87a2.2,2.2,0,1,1,3.4,2.78L23.6,29.66a2.74,2.74,0,0,1-.52.5A2.21,2.21,0,0,1,20,29.55L17.28,25.8a2.21,2.21,0,0,1,.6-3.05ZM81.13,59a27.86,27.86,0,0,1,23.31,43.1l12.06,13.14-8.31,7.6L96.56,110.09A27.86,27.86,0,1,1,81.13,59ZM38.47,71.54a3.07,3.07,0,0,1-2.9-3.17,3,3,0,0,1,2.9-3.17h9a3.07,3.07,0,0,1,2.9,3.17,3,3,0,0,1-2.9,3.17ZM93,44.89c-.56,2.11-5.31,2.43-6.38,0V7.43a1.06,1.06,0,0,0-.3-.76,1.08,1.08,0,0,0-.75-.3H7.39a1,1,0,0,0-1,1.06V95.74a1,1,0,0,0,1,1.05H37.72c3.21.34,3.3,5.88,0,6.38H7.43A7.48,7.48,0,0,1,0,95.74V7.43A7.3,7.3,0,0,1,2.19,2.19,7.35,7.35,0,0,1,7.43,0H85.6a7.32,7.32,0,0,1,5.24,2.19A7.39,7.39,0,0,1,93,7.43c0,36.56,0-18,0,37.46ZM38.44,27.47a3.07,3.07,0,0,1-2.91-3.17,3,3,0,0,1,2.91-3.17H68.21a3.07,3.07,0,0,1,2.91,3.17,3,3,0,0,1-2.91,3.17Zm0,22a3.06,3.06,0,0,1-2.91-3.16,3,3,0,0,1,2.91-3.17H68.21a3.07,3.07,0,0,1,2.91,3.17,3,3,0,0,1-2.91,3.16Zm32.19,40a3.4,3.4,0,0,1-.38-.49,3.71,3.71,0,0,1-.29-.56A3.54,3.54,0,0,1,75.05,84a2.78,2.78,0,0,1,.56.41l0,0c1,.93,1.28,1.12,2.36,2.08l.92.83,7.58-8.13c3.21-3.3,8.32,1.53,5.12,4.9L82.15,94.26l-.47.5a3.56,3.56,0,0,1-5,.22l0,0L76,94.28c-.58-.52-1.18-1-1.79-1.57-1.4-1.22-2.22-1.89-3.54-3.21ZM81.15,64.85A22.17,22.17,0,1,1,59,87,22.17,22.17,0,0,1,81.15,64.85ZM23.54,63.59a5.1,5.1,0,1,1-5.09,5.09,5.09,5.09,0,0,1,5.09-5.09ZM25.66,42a2.09,2.09,0,0,1,3,0,2.12,2.12,0,0,1,0,3l-2.07,2.13,2.07,2.13a2.1,2.1,0,0,1-3,3l-2.05-2.1-2.07,2.11a2.07,2.07,0,0,1-3,0,2.13,2.13,0,0,1,0-3l2.08-2.13L18.57,45a2.1,2.1,0,0,1,0-3,2.07,2.07,0,0,1,2.94,0l2.06,2.11L25.66,42Z"
                                        />
                                    </svg>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Check-in-date</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2024-03-15</span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Hours Operated</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2500</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Client</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">BuildCorp Inc.</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="card border px-3 py-3 mb-3">
                        <div className="d-flex align-items-center mb-2">
                            <TimerOutlinedIcon />
                            <h5 className="ml-1 mb-0">Diagnosis</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Check-in-date</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2024-03-15</span>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <span className="text-gray font-weight-600 font-size-14">Hours Operated</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">2500</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-gray font-weight-600 font-size-14">Client</span>
                            <span className="text-dark font-weight-600 font-size-14 ml-2">BuildCorp Inc.</span>
                        </div>
                    </div>
                </Grid>
            </Grid>

            {openInspectionDtlsModal && (
                <ShopReapirInspectionDetailsModal show={openInspectionDtlsModal} hideModal={() => setOpenInspectionDtlsModal(false)} handleSnack={handleSnack} />
            )}
        </>
    );
};

export default ShopRepairDisassembly;
