import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import { Divider, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FONT_STYLE_SELECT, GRID_STYLE } from "pages/Common/constants";
import {
  AddLaborItemToLabor,
  AddLaborToService,
  AddServiceHeader,
  FetchLaborforService,
  FetchLaborItems,
  RemoveLaborItem,
} from "services/repairBuilderServices";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { LABOR_PRICE_OPTIONS } from "../CONSTANTS";
import RepairBuilderLaborItemAddModal from "./RepairBuilderLaborItemAddModal";

const RepairBuilderLaborModal = (props) => {
  const {
    show,
    hideModal,
    operationIndex,
    segmentIndex,
    operationData,
    handleSnack,
    segmentItems,
    setSegmentItems,
    labourItems,
    setLabourItems,
    laborCodeList = [],
    chargeCodeList = [],
    laborTypeList = [],
    serviceTypeList = [],
    unitOfMeasureOptions = [],
    generalData,
    pricingData,
    isLaborItemEdit = false,
    isLaborItemEditRow,
    handleSaveLaborEditRow,
  } = props;

  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const initialLaborItemData = {
    isEditing: false,
    chargeCode: "",
    laborType: "",
    serviceType: "",
    unitOfMeasure: "",
    estimatedHours: 0,
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    // currency: operationData?.currency || "USD",
    currency: pricingData?.currency?.value || "USD",
    comment: "",
    travelIncluded: true,
    travelCharge: 0.0,
    inspectionIncluded: true,
    inspectionCharge: 0.0,
  };

  const [labourData, setLabourData] = useState({
    jobCode: operationData?.jobCode || "",
    jobCodeDescription: operationData?.jobCodeDescription || "",
    laborCode: "",
    pricingMethod: "",
    ratePerHourOrDay: 0,
    totalPrice: 0.0,
    payer: "",
    adjustedPrice: 0.0,
    flatRateIndicator: false,
    totalHours: 0,
  });
  const [laborItems, setLaborItems] = useState([]);
  const [laborViewOnly, setLaborViewOnly] = useState(false);
  const [labourItemData, setLabourItemData] = useState(initialLaborItemData);

  const [laborItemOpen, setLaborItemOpen] = useState(false);

  useEffect(() => {
    if (!isLaborItemEdit) {
      if (operationData?.serviceEstimateId) {
        if (operationData?.labourDetails) {
          FetchLaborforService(operationData?.serviceEstimateId)
            .then((resultLabour) => {
              if (resultLabour && resultLabour.id) {
                setLabourData({
                  ...resultLabour,
                  id: resultLabour.id,
                  pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === resultLabour.pricingMethod),
                  laborCode: laborCodeList.find((element) => element.value === resultLabour.laborCode),
                  totalPrice: resultLabour.totalPrice ? resultLabour.totalPrice : 0,
                });
                populateLaborItems(resultLabour);
                setLaborViewOnly(true);
              }
            })
            .catch((e) => {
              setLabourData({
                ...labourData,
                jobCode: operationData?.jobCode || "",
                jobCodeDescription: operationData.jobCodeDescription || "",
              });
            });
        }
      }
    } else {
      openLaborRow(isLaborItemEditRow);
    }
  }, [isLaborItemEdit, operationData?.serviceEstimateId, operationData?.labourDetails]);

  // populate labor Items
  const populateLaborItems = async () => {
    await FetchLaborItems(operationData?.labourDetails?.id).then((resultLabourItems) => {
      if (resultLabourItems && resultLabourItems.result?.length > 0) {
        // const operation?.labourDetails && operation?.labourDetails["labourItems"].length
        setLaborItems(resultLabourItems.result);
      } else {
        setLaborItems([]);
      }
    });
  };

  // add labor item
  const handleAddItemLabor = () => {
    if (labourData.id) setLaborItemOpen(true);
    else handleSnack("warning", "Please update the lobor details!");
  };

  // update the Labur estimation header
  const updateLabourEstHeader = () => {
    if (operationData?.serviceEstimateId) {
      let data = {
        ...(labourData.id && { id: labourData.id }),
        jobCode: labourData.jobCode,
        jobCodeDescription: labourData.jobCodeDescription,
        laborCode: labourData.laborCode?.value,
        totalHours: labourData.totalHours,
        // payer: labourData.payer,
      };

      AddLaborToService(operationData?.serviceEstimateId, data)
        .then((result) => {
          setLabourData({
            ...result,
            id: result.id,
            pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
            totalPrice: result.totalPrice ? result.totalPrice : 0,
            laborCode: laborCodeList.find((element) => element.value === result.laborCode),
          });

          const _segmentItems = { ...segmentItems };
          const _segments = _segmentItems["segments"];
          const selectedSegment = _segments[segmentIndex];
          const _operations = selectedSegment["operations"][operationIndex];
          if (!_operations["labourDetails"]) {
            _operations["labourDetails"] = { ...result, labourItems: [] };
            setSegmentItems({ ..._segmentItems });
          }

          handleSnack("success", "Labour details updated!");
          setLaborViewOnly(true);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating labor details!");
        });
    } else {
      let estimateData = {
        reference: segmentItems?.builderId || "RO000174",
        description: generalData?.description || "992K Warranty",
        segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        currency: pricingData?.currency?.value || "USD",
        jobCode: operationData?.jobCode || "JB0361",
        id: null,
        jobCodeDescription: operationData?.jobCodeDescription || "Description114",
        componentCode: operationData?.componentCode || "ABC123",
        componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
      };
      AddServiceHeader(operationData?.id, estimateData)
        .then((estimateResult) => {
          // setServiceEstimateData({
          //   ...result,
          //   id: result.id,
          // });
          let data = {
            ...(labourData.id && { id: labourData.id }),
            jobCode: labourData.jobCode,
            jobCodeDescription: labourData.jobCodeDescription,
            laborCode: labourData.laborCode?.value,
            totalHours: labourData.totalHours,
            // payer: labourData.payer,
          };

          AddLaborToService(estimateResult.id, data)
            .then((result) => {
              setLabourData({
                ...result,
                id: result.id,
                pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                totalPrice: result.totalPrice ? result.totalPrice : 0,
                laborCode: laborCodeList.find((element) => element.value === result.laborCode),
              });

              const _segmentItems = { ...segmentItems };
              const _segments = _segmentItems["segments"];
              const selectedSegment = _segments[segmentIndex];
              const _operations = selectedSegment["operations"][operationIndex];
              _operations["serviceEstimateId"] = estimateResult?.id;
              if (!_operations["labourDetails"]) {
                _operations["labourDetails"] = { ...result, labourItems: [] };
              }
              setSegmentItems({ ..._segmentItems });

              handleSnack("success", "Labour details updated!");
              setLaborViewOnly(true);
            })
            .catch((err) => {
              handleSnack("error", "Error occurred while updating labor details!");
            });
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating labor details!");
          // handleSnack("error", "Error occurred while updating service estimate header!");
        });
    }
  };

  //
  const handleLaborItemClose = () => {
    setLaborItemOpen(false);
    setLabourItemData(initialLaborItemData);
    if (isLaborItemEdit) {
      handleSaveLaborEditRow(labourData.id);
    }
  };

  // Add or Update Labor Item
  const addLaborItem = () => {
    let data = {
      // ...labourItemData,
      ...(labourItemData.id && { id: labourItemData.id }),
      chargeCode:
        labourItemData.chargeCode?.value === "MINIMUM_SERVICE_CHARGE"
          ? "MINIMUM_SERVICE_CALL"
          : labourItemData.chargeCode?.value === "ADDITIONAL_SERVICE_CHARGE"
          ? "ADDITIONAL_ITEMS"
          : labourItemData.chargeCode?.value || "",
      laborType: labourItemData.laborType?.value,
      serviceType: labourItemData.serviceType?.value,
      unitOfMeasure: labourItemData.unitOfMeasure?.value,
      estimatedHours: labourItemData.estimatedHours,
      comment: labourItemData.comment,
      travelIncluded: labourItemData.travelIncluded,
      travelCharge: labourItemData.travelCharge,
      inspectionIncluded: labourItemData.inspectionIncluded,
      inspectionCharge: labourItemData.inspectionCharge,
      currency: labourItemData.currency,
    };

    AddLaborItemToLabor(labourData.id, data)
      .then((result) => {
        const _laborItems = [...laborItems];
        const laborItemsIndex = _labourItems.findIndex((item) => item.id === result.id);
        if (laborItemsIndex !== -1) {
          _laborItems[laborItemsIndex] = result;
        } else {
          _laborItems.push(result);
        }
        setLaborItems([..._laborItems]);

        const _labourItems = [...labourItems["result"]];
        const labourItemsIndex = _labourItems.findIndex((item) => item.id === result.id);
        if (labourItemsIndex !== -1) {
          _labourItems[labourItemsIndex] = result;
        } else {
          _labourItems.push(result);
        }
        setLabourItems({ result: [..._labourItems], totalRows: _labourItems.length });

        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];
        const selectedSegment = _segments[segmentIndex];
        const _operations = selectedSegment["operations"][operationIndex];
        let optLabourItemIndex = _operations["labourDetails"]["labourItems"].findIndex((item) => item === result.id);
        if (optLabourItemIndex === -1) {
          _operations["labourDetails"]["labourItems"].push(result?.id);
        }
        setSegmentItems({ ..._segmentItems });

        setLabourItemData(initialLaborItemData);
        handleSnack("success", `${optLabourItemIndex === -1 ? "Added" : "Updated"} labor item successfully`);
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while adding labor item!");
      });
    handleLaborItemClose();
  };

  const populateLaborDetails = async () => {
    FetchLaborforService(operationData?.serviceEstimateId)
      .then((resultLabour) => {
        if (resultLabour && resultLabour.id) {
          setLabourData({
            ...resultLabour,
            id: resultLabour.id,
            pricingMethod: LABOR_PRICE_OPTIONS.find((element) => element.value === resultLabour.pricingMethod),
            laborCode: laborCodeList.find((element) => element.value === resultLabour.laborCode),
            totalPrice: resultLabour.totalPrice ? resultLabour.totalPrice : 0,
          });
          populateLaborItems(resultLabour);
          setLaborViewOnly(true);
        }
      })
      .catch((e) => {
        setLabourData({
          ...labourData,
          jobCode: operationData?.jobCode || "",
          jobCodeDescription: operationData.jobCodeDescription || "",
        });
      });
  };

  // Open Labor item to view or edit
  const openLaborRow = (row) => {
    if (isLaborItemEdit) {
      const _segmentItems = { ...segmentItems };
      const _segments = _segmentItems["segments"];
      const selectedSegment = _segments[segmentIndex];
      const _operations = selectedSegment["operations"][operationIndex];
      // _operations["labourDetails"]["labourItems"].push(result?.id);
      setLabourData({
        ...labourData,
        id: _operations["labourDetails"]["id"],
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData.jobCodeDescription || "",
      });
    }

    setLabourItemData({
      ...row,
      chargeCode: chargeCodeList.find((element) => element.value === row.chargeCode),
      laborType: laborTypeList.find((element) => element.value === row.laborType),
      serviceType: serviceTypeList.find((element) => element.value === row.serviceType),
      unitOfMeasure: unitOfMeasureOptions.find((element) => element.value === row.unitOfMeasure),
      unitPrice: row.unitPrice ? row.unitPrice : 0,
      extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
      totalPrice: row.totalPrice ? row.totalPrice : 0,
      isEditing: true,
    });
    setLaborItemOpen(true);
  };

  //Remove Labor Item
  const handleDeleteLaborItem = async (laborItemId) => {
    RemoveLaborItem(labourData.id, laborItemId)
      .then((res) => {
        handleSnack("success", res);

        const _laborItems = laborItems.filter((item) => item?.id !== laborItemId);
        setLaborItems(_laborItems);

        const _labourItems = labourItems["result"].filter((item) => item?.id !== laborItemId);
        setLabourItems({ result: [..._labourItems], totalRows: _labourItems.length });

        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];
        const selectedSegment = _segments[segmentIndex];
        const _operations = selectedSegment["operations"][operationIndex];
        _operations["labourDetails"]["labourItems"].filter((item) => item !== laborItemId);
        setSegmentItems({ ..._segmentItems });
      })
      .catch((e) => {
        console.log(e);
        handleSnack("error", "Error occurred while removing the labor item");
      });
  };

  const laborColumns = [
    { field: "chargeCode", headerName: "Charge Code", flex: 1, width: 70 },
    { field: "laborType", headerName: "Labor Type", flex: 1, width: 130 },
    { field: "serviceType", headerName: "Service Type", flex: 1, width: 130 },
    {
      field: "unitOfMeasure",
      headerName: "Unit of measure",
      flex: 1,
      width: 130,
    },
    {
      field: "estimatedHours",
      headerName: "Estimated hours",
      flex: 1,
      width: 130,
    },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    {
      field: "extendedPrice",
      headerName: "Extended Price",
      flex: 1,
      width: 130,
    },
    { field: "comment", headerName: "Comments", flex: 1, width: 130 },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    { field: "totalPrice", headerName: "Total Price", flex: 1, width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => openLaborRow(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            onClick={() => handleDeleteLaborItem(row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="d-flex align-items-center p-3">
          <h4 className="mb-0">{title}</h4>
          <a className="btn-sm cursor mx-2" onClick={() => setLaborViewOnly(false)}>
            <EditIcon sx={{ fontSize: 29 }} />
          </a>
        </div>
        <div className="px-3">
          <Divider />
        </div>
        <Modal.Body className="p-0 bg-white">
          <div className="px-3">
            {!laborViewOnly ? (
              <>
                <div className="row input-fields">
                  <div className="col-md-4 col-sm-4">
                    <div class="form-group mt-3">
                      <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                      <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.jobCode} />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div class="form-group mt-3">
                      <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                      <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.jobCodeDescription} />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4"></div>
                  <div className="col-md-4 col-sm-4">
                    <div className="form-group  mt-3">
                      <label className="text-light-dark font-size-12 font-weight-500">LABOR CODE</label>
                      <Select
                        onChange={(e) => setLabourData({ ...labourData, laborCode: e })}
                        options={laborCodeList}
                        value={labourData.laborCode}
                        styles={FONT_STYLE_SELECT}
                      />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                    <div class="form-group mt-3">
                      <label className="text-light-dark font-size-12 font-weight-600">TOTAL HOURS (PLANNED/RECOMMENDED)</label>
                      <input
                        type="text"
                        class="form-control border-radius-10 text-primary"
                        value={labourData.totalHours}
                        onChange={(e) => setLabourData({ ...labourData, totalHours: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div class="form-group mt-3">
                      <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                      <input type="text" disabled class="form-control border-radius-10 text-primary" value={labourData.totalPrice} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group mt-3 mb-0 text-right">
                      <button type="button" className="btn border-primary text-primary mr-2" onClick={hideModal}>
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-light bg-primary text-white"
                        onClick={updateLabourEstHeader}
                        disabled={!(labourData.laborCode && labourData.totalHours)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row mt-3">
                  <ReadOnlyField label="JOB CODE" value={labourData.jobCode} className="col-md-4 col-sm-4" />
                  <ReadOnlyField label="JOB CODE DESCRIPTION" value={labourData.jobCodeDescription} className="col-md-4 col-sm-4" />
                  <div className="col-md-4 col-sm-4"></div>
                  <ReadOnlyField label="LABOR CODE" value={labourData.laborCode?.label} className="col-md-4 col-sm-4" />
                  <ReadOnlyField label="TOTAL HOURS (PLANNED / RECOMMENDED)" value={labourData.totalHours} className="col-md-4 col-sm-4" />
                  <div className="col-md-4 col-sm-4"></div>
                  <ReadOnlyField label="PRICE METHOD" value={labourData.pricingMethod?.label} className="col-md-4 col-sm-4" />
                  <ReadOnlyField label="RATE PER HOUR / DAY" value={labourData.ratePerHourOrDay} className="col-md-4 col-sm-4" />
                  <ReadOnlyField label="NET PRICE" value={labourData.totalPrice} className="col-md-4 col-sm-4" />
                </div>
              </>
            )}
            <hr />
            <div className="mb-4">
              <div className="bg-primary px-3 mb-3 border-radius-6">
                <div className="row align-items-center">
                  <div className="col-11 mx-2">
                    <div className="d-flex align-items-center bg-primary w-100">
                      <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                        <h5 className="mr-2 mb-0 text-white">
                          <span>Labor</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-center border-left pl-2 py-3">
                      <Link onClick={handleAddItemLabor} to="#" className="p-1 text-white" data-toggle="modal" data-target="#Datatable">
                        <span className="ml-1">Add Items</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <DataGrid
                sx={GRID_STYLE}
                paginationMode="client"
                rows={laborItems}
                columns={laborColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                autoHeight
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <RepairBuilderLaborItemAddModal
        laborItemOpen={laborItemOpen}
        handleLaborItemClose={handleLaborItemClose}
        setLabourItemData={setLabourItemData}
        labourItemData={labourItemData}
        serviceEstimateData={operationData}
        unitOfMeasureOptions={unitOfMeasureOptions}
        chargeCodeList={chargeCodeList}
        addLaborItem={addLaborItem}
        laborTypeList={laborTypeList}
        serviceTypeList={serviceTypeList}
      />
    </>
  );
};

export default RepairBuilderLaborModal;
