import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Box, Checkbox, FormControlLabel, Typography, Paper, Tooltip, FormGroup, Switch, TextField } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Moment from "react-moment";

import { FONT_STYLE, FONT_STYLE_SELECT, GENERAL_TRANSPRINT_GRID_STYLE } from "pages/Common/constants";
import Select from "react-select";
// import ClaimProcessSattlement from "./ClaimProcessSattlement";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import ClaimProcessSattlement from "../ClaimsProcessing/ClaimProcessSattlement";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: "0.6rem",
    "&:(:last-child)": { marginBottom: 0 },
    "&::before": { display: "none" },
}));

const AccordionSummary = styled((props) => {
    const { expanded } = props;
    return <MuiAccordionSummary expandIcon={expanded ? <RemoveIcon sx={{ fontSize: "3rem" }} /> : <AddIcon sx={{ fontSize: "3rem" }} />} {...props} />;
})(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SupplierClaimApprovalClosure = (props) => {
    const {
        viewOnlyTab,
        setViewOnlyTab,
        supplierRecord,
        supplierItems,
        oemSupplierRecordData,
        setOemSupplierRecordData,
        questionsOptions,
        oemSupplierClaimStatusOptions,
        authorizationStatusRecords = [],
        authorizationStatus,
        setAuthorizationStatus,
        handleSnack,
    } = props;

    const [expended, setExpended] = useState("oemSupplierClaim");

    // expend sefment
    const handleExpand = (panel) => (event, isExpanded) => {
        setExpended(isExpanded ? panel : false);
    };

    // OEM/Supplier Input text change
    const handleOEMTextChange = (e) => {
        const { name, value } = e.target;
        setOemSupplierRecordData({ ...oemSupplierRecordData, [name]: value });
    };

    // save the final approval record
    const handleSaveFinalApprovalData = () => {
        handleSnack("success", "Final Approval changes saved successfully.");
        setViewOnlyTab({ ...viewOnlyTab, finalApprovalViewOnly: true });
    };

    const authStatusColumns = [
        {
            field: "authorizationStatus",
            headerName: "Status",
            sortable: false,
            width: 250,
            //   flex: 1,
            renderCell: ({ row }) => (
                <div className="d-flex align-items-center">
                    <span className={row?.authorizationStatus === "AUTHORIZED" ? "led-green" : row?.authorizationStatus === "DENIED" ? "led-red" : "led-orange"}></span>
                    <span>{row?.authorizationStatus === "AUTHORIZED" ? "Authorized" : row?.authorizationStatus === "DENIED" ? "Denied" : "Pending"}</span>
                </div>
            ),
        },
        {
            field: "details",
            headerName: "Details",
            sortable: false,
            width: 550,
            //   flex: 1,
            renderCell: ({ row }) => (
                <div className="d-block">
                    {`Serial #:- N/A | Customer #:-  N/A | Warranty Id:  N/A
                    }`}
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            width: 300,
            //   flex: 1,
            type: "actions",
            getActions: ({ row }) => {
                if (!row) return [];
                const actions = [];
                if (row.authorizationStatus === "PENDING") {
                    actions.push(
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Authorized">
                                    <CheckCircleOutlineIcon sx={{ fontSize: 28, color: "#67d11e" }} />
                                </Tooltip>
                            }
                            label="Authorized"
                            //   onClick={() => handleAcceptRejectAuthrization(row, "AUTHORIZED")}
                        />,
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Denied">
                                    <CancelOutlinedIcon sx={{ fontSize: 28, color: "#f84343" }} />
                                </Tooltip>
                            }
                            label="Denied"
                            //   onClick={() => handleAcceptRejectAuthrization(row, "DENIED")}
                        />
                    );
                }
                actions.push(
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditOutlinedIcon sx={{ fontSize: 28 }} />
                            </Tooltip>
                        }
                        label="Edit"
                        // onClick={() => handleOpenAuthDtlsModal(row)}
                    />
                );
                return actions;
            },
        },
    ];

    return (
        <>
            <DataGrid
                sx={GENERAL_TRANSPRINT_GRID_STYLE}
                rows={authorizationStatusRecords}
                columns={authStatusColumns.map((col) => {
                    return col;
                })}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20, 50]}
                hideFooter
                disableColumnMenu
                sortingOrder={["asc", "desc"]}
                autoHeight
                disableSelectionOnClick
            />

            <div className="row">
                <div className="col-md-7 col-sm-7">
                    <div className="card border p-3 mt-2">
                        <div className="d-flex">
                            <FormControlLabel sx={{ px: 0, marginRight: 0 }} control={<Checkbox defaultChecked />} />
                            <div className="ml-0">
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                                    Securely save my information for 1-click checkout
                                </Typography>
                                <Typography variant="body2" style={{ color: "#6c757d" }}>
                                    Pay faster on Skiff World, Inc. and everywhere Link is accepted
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Accordion expanded={expended === "oemSupplierClaim"} onChange={handleExpand("oemSupplierClaim")} key={"oemSupplierClaim"}>
                <AccordionSummary expanded={expended === "oemSupplierClaim" ? true : false}>
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                        <Typography>OEM/Supplier Claim</Typography>
                        {expended === "oemSupplierClaim" && (
                            <span
                                className="mr-1 cursor"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setViewOnlyTab({ ...viewOnlyTab, finalApprovalViewOnly: false });
                                }}
                            >
                                <Tooltip title="Edit">
                                    <i className="fa fa-pencil font-size-18" aria-hidden="true"></i>
                                </Tooltip>
                            </span>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {!viewOnlyTab?.finalApprovalViewOnly ? (
                        <>
                            <div className="row input-fields mt-2">
                                <div className="col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">OEM/SUPPLIER NAME</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="supplierName"
                                            value={supplierRecord?.supplierName}
                                            //   onChange={handleMachineDataChange}
                                            placeholder="Supplier Name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">
                                            <span className=" mr-2">CLAIM SUBMISSION DATE</span>
                                        </label>
                                        <div className="align-items-center date-box">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <MobileDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    className="form-controldate border-radius-10"
                                                    closeOnSelect
                                                    // value={supplierRecord?.createdAt}
                                                    // onChange={(e) => setOemSupplierRecordData({ ...oemSupplierRecordData, approvalDate: e })}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                                    )}
                                                    disabled
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row input-fields mt-2">
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">CLAIM STATUS</label>
                                        <Select
                                            onChange={(e) => setOemSupplierRecordData({ ...oemSupplierRecordData, claimStatus: e })}
                                            options={oemSupplierClaimStatusOptions}
                                            value={oemSupplierRecordData?.claimStatus}
                                            styles={FONT_STYLE_SELECT}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">APPROVED BY</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="approvedBy"
                                            value={oemSupplierRecordData?.approvedBy}
                                            onChange={handleOEMTextChange}
                                            placeholder="Approved By"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">
                                            <span className=" mr-2">APPROVAL DATE</span>
                                        </label>
                                        <div className="align-items-center date-box">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <MobileDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    className="form-controldate border-radius-10"
                                                    closeOnSelect
                                                    value={oemSupplierRecordData?.approvalDate}
                                                    onChange={(e) => setOemSupplierRecordData({ ...oemSupplierRecordData, approvalDate: e })}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">APPROVAL AMOUNT</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="approvalAmount"
                                            value={oemSupplierRecordData?.approvalAmount}
                                            onChange={handleOEMTextChange}
                                            placeholder="Approval Amount"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REJECTION REASON</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="rejectionReason"
                                            value={oemSupplierRecordData?.rejectionReason}
                                            onChange={handleOEMTextChange}
                                            placeholder="Rejection Reason"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REIMBURSED</label>
                                        <Select
                                            onChange={(e) => setOemSupplierRecordData({ ...oemSupplierRecordData, reimbursed: e })}
                                            options={questionsOptions}
                                            value={oemSupplierRecordData?.reimbursed}
                                            styles={FONT_STYLE_SELECT}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">PARTS</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="partsAmount"
                                            value={oemSupplierRecordData?.partsAmount}
                                            onChange={handleOEMTextChange}
                                            placeholder="Parts Amount"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">EXPENSES</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="expenseAmount"
                                            value={oemSupplierRecordData?.expensesAmount}
                                            onChange={handleOEMTextChange}
                                            placeholder="Expenses Amount"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">TOTAL</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="totalAmount"
                                            value={oemSupplierRecordData?.totalAmount}
                                            onChange={handleOEMTextChange}
                                            placeholder="Total Amount"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REIMBURSEMENT NOTES</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="reimbursementNotes"
                                            value={oemSupplierRecordData?.reimbursementNotes}
                                            onChange={handleOEMTextChange}
                                            placeholder="Reimbursement Notes"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row  px-3" style={{ justifyContent: "right" }}>
                                <button className="btn bg-primary text-white" onClick={handleSaveFinalApprovalData}>
                                    Save Changes
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row input-fields mt-2">
                                <ReadOnlyField label="OEM/SUPPLIER NAME" value={supplierRecord?.supplierName} className="col-md-4 col-sm-4" />
                                <ReadOnlyField
                                    label="CLAIM SUBMISSION DATE"
                                    value={<Moment format="DD/MM/YYYY">{supplierRecord?.createdAt}</Moment>}
                                    className="col-md-4 col-sm-4"
                                />
                            </div>
                            <div className="row input-fields mt-2">
                                <ReadOnlyField label="CLAIM STATUS" value={oemSupplierRecordData?.claimStatus?.label} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="APPROVED BY" value={oemSupplierRecordData?.approvedBy} className="col-md-3 col-sm-3" />
                                <ReadOnlyField
                                    label="APPROVAL DATE"
                                    value={<Moment format="DD/MM/YYYY">{oemSupplierRecordData?.approvalDate}</Moment>}
                                    className="col-md-3 col-sm-3"
                                />
                                <ReadOnlyField label="APPROVAL AMOUNT" value={oemSupplierRecordData?.approvalAmount} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="REJECTION REASON" value={oemSupplierRecordData?.rejectionReason} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="REIMBURSED" value={oemSupplierRecordData?.reimbursed?.label} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="PARTS" value={oemSupplierRecordData?.partsAmount} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="EXPENSES" value={oemSupplierRecordData?.expensesAmount} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="TOTAL" value={oemSupplierRecordData?.totalAmount} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="REIMBURSEMENT NOTES" value={oemSupplierRecordData?.reimbursementNotes} className="col-md-3 col-sm-3" />
                            </div>
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expended === "invoice"} onChange={handleExpand("invoice")} key={"invoice"}>
                <AccordionSummary expanded={expended === "invoice" ? true : false}>
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                        <Typography>{"Invoice"}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="row input-fields mt-2">
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">INVOICE NUMBER</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="invoiceNumber"
                                    //   onChange={handleMachineDataChange}
                                    // placeholder="Auto Filled"
                                    // disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">
                                    <span className=" mr-2">INVOICE DATE</span>
                                </label>
                                <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            className="form-controldate border-radius-10"
                                            closeOnSelect
                                            // value={yearlyWarrantyData?.warrantyStartDate}
                                            // onChange={(e) => setYearlyWarrantyData({ ...yearlyWarrantyData, warrantyStartDate: e })}
                                            renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">INVOICE AMOUNT</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="invoiceNumber"
                                    // onChange={handleMachineDataChange}
                                    // placeholder="Auto Filled"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="supplierName"
                                    value={supplierRecord?.supplierName}
                                    //   onChange={handleMachineDataChange}
                                    placeholder="Customer name"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">CONTACT INFORMATION</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="contractNumber"
                                    value={supplierRecord?.contractNumber}
                                    // onChange={handleMachineDataChange}
                                    // placeholder="Auto Filled"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row px-3" style={{ justifyContent: "right" }}>
                        <button className="btn bg-primary text-white">Generate Invoice</button>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SupplierClaimApprovalClosure;
