import React, { useState } from "react";

import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Grid, TextField } from "@mui/material";
import { FONT_STYLE } from "pages/Common/constants";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Moment from "react-moment";

const estimatedByUsers = [
  { name: "John", email: "john@gmail.com" },
  { name: "David", email: "david@gmail.com" },
  { name: "Michael", email: "michael@gmail.com" },
  { name: "Emma", email: "emma@gmail.com" },
  { name: "Robert", email: "robert@gmail.com" },
  { name: "Sarah", email: "sarah@gmail.com" },
  { name: "Daniel", email: "daniel@gmail.com" },
];

const MaintenanceBuilderApproval = ({ setActiveStep }) => {
  const [tabValue, setTabValue] = useState("general");

  // header tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // update the general data
  const handleUpdateGenData = () => {
    setTabValue("moreInfo");
  };

  // update the more info
  const handleUpdateMoreInfo = () => {
    setTabValue("finances");
  };
  return (
    <>
      <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList className="custom-tabs-div" onChange={handleChange}>
              <Tab label="GENERAL" value="general" />
              <Tab label="MORE INFO " value="moreInfo" />
              <Tab label="FINANCES" value="finances" />
            </TabList>
          </Box>
          <TabPanel value="general">
            <div className="card border px-3 py-3">
              <div className="row input-fields">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">COMPRISING ORDER NUMBER</label>
                    <input type="text" disabled className="form-control border-radius-10 text-primary" id="customer-src" />
                    {/* <div className="css-w8dmq8">*Mandatory</div> */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">TOTAL PRICE</label>
                    <input
                      type="text"
                      // value={customerData.customerName}
                      name="totalPrice"
                      // onChange={handleCustomerDataChange}
                      className="form-control border-radius-10 text-primary"
                      id="totalPrice"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">PROMISE DATE</label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          // minDate={estimationData.preparedOn}
                          maxDate={new Date()}
                          closeOnSelect
                          // value={estimationData.preparedOn}
                          // onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">START DATE</label>
                    <div className="align-items-center date-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          inputFormat="dd/MM/yyyy"
                          className="form-controldate border-radius-10"
                          // minDate={estimationData.preparedOn}
                          maxDate={new Date()}
                          closeOnSelect
                          // value={estimationData.preparedOn}
                          // onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SERVICE ORGANISATION</label>
                    <input
                      type="text"
                      // value={customerData.customerName}
                      name="totalPrice"
                      // onChange={handleCustomerDataChange}
                      className="form-control border-radius-10 text-primary"
                      id="totalPrice"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">SALES BRANCH</label>
                    <input
                      type="text"
                      // value={customerData.customerName}
                      name="totalPrice"
                      // onChange={handleCustomerDataChange}
                      className="form-control border-radius-10 text-primary"
                      id="totalPrice"
                    />
                  </div>
                </div>
              </div>
              <div className="row px-3" style={{ justifyContent: "right" }}>
                {/* <div className="col-md-6 col-sm-6"></div>
                <div className="col-md-6 col-sm-6"></div> */}
                <button
                  type="button"
                  className="btn border-primary text-primary mr-1"
                  // onClick={() => handleResetData("CANCEL")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn bg-primary text-white"
                  onClick={handleUpdateGenData}
                  //   disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
                >
                  Save & Next
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="moreInfo">
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="estimated-approved-div py-2 px-3 border-radius-10 mb-3">
                  <h5 className="d-flex align-items-center mb-0" style={{ color: "#2fa00a" }}>
                    <ThumbUpOutlinedIcon sx={{ fontSize: 32, marginRight: 1 }} /> Estimate Approved On {"  "}
                    <Moment format="MMMM DD, YYYY" style={{ marginLeft: "8px" }}>
                      {new Date()}
                    </Moment>
                  </h5>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="my-3">Approved By</h5>
              <Grid container spacing={2}>
                {estimatedByUsers.length !== 0 &&
                  estimatedByUsers.map((user) => (
                    <Grid item xs={3}>
                      <h6 className="mb-0 d-flex align-items-center">
                        <img
                          src="../../assets/images/user_profile.png"
                          alt="user-profile"
                          style={{ width: "25px", height: "25px", marginRight: "8px", border: "1px solid #B2B1B0", borderRadius: "50%" }}
                        />
                        {/* <Avatar
                          sx={{
                            width: 25,
                            height: 25,
                            marginRight: 1,
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            border: "1px solid #B2B1B0",
                          }}
                          src="../../assets/images/user_profile.png"
                        /> */}
                        {user?.name}
                      </h6>
                    </Grid>
                  ))}
              </Grid>
            </div>
            <div className="row px-3" style={{ justifyContent: "right" }}>
              {/* <div className="col-md-6 col-sm-6"></div>
                <div className="col-md-6 col-sm-6"></div> */}
              <button
                type="button"
                className="btn border-primary text-primary mr-1"
                // onClick={() => handleResetData("CANCEL")}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn bg-primary text-white"
                onClick={handleUpdateMoreInfo}
                //   disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
              >
                Save & Next
              </button>
            </div>
          </TabPanel>
          <TabPanel value="finances">
            <div className="row input-fields">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL COST</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="customer-src" />
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REVENUE</label>
                  <input type="text" className="form-control border-radius-10 text-primary" id="customer-src" />
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "right" }}>
              <button
                type="button"
                className="btn btn-light bg-primary text-white mr-1"
                // onClick={() => handleResetData("CANCEL")}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-light bg-primary text-white" onClick={() => setActiveStep((prev) => prev + 1)}>
                Save & Next
              </button>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default MaintenanceBuilderApproval;
