import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from "recharts";

const data = [
  { month: "Jan", value: 0.3 },
  { month: "Feb", value: 0.25 },
  { month: "Mar", value: 0.35 },
  { month: "Apr", value: 0.4, label: "$375,000" },
  { month: "May", value: 0.6 },
  { month: "Jun", value: 0.7 },
  { month: "Jul", value: 1.0, label: "$1,000,000" },
  { month: "Aug", value: 0.8 },
  { month: "Sep", value: 0.75 },
  { month: "Oct", value: 0.55 },
  { month: "Nov", value: 0.65, label: "$550,000" },
  { month: "Dec", value: 0.5 },
  { month: "Jan", value: 0.4 },
  { month: "Feb", value: 0.35 },
  { month: "Mar", value: 0.3, label: "$350,000" },
];

const CostTrend = () => {
  return (
    <>
      <div className="pl-3  text-muted text-uppercase font-size-14 font-weight-600">Total Warranty Cost by</div>
      <div className="pl-3 py-2 font-size-14 font-weight-700">Warranty Cost Trend</div>
      <ResponsiveContainer width="100%" height={310}>
        <LineChart data={data} margin={{ top: 20, right: 30, left: -10, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} strokeWidth={3}>
            <LabelList dataKey="label" position="top" />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default CostTrend;
