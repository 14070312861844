import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";

import copyIcon from "../../../assets/icons/svg/Copy.svg";
import shareIcon from "../../../assets/icons/svg/share.svg";
import deleteIcon from "../../../assets/icons/svg/delete.svg";
import uploadIcon from "../../../assets/icons/svg/upload.svg";
import folderaddIcon from "../../../assets/icons/svg/folder-add.svg";

import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/EditOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ReviewAddIcon from "@mui/icons-material/CreateNewFolderOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import TabContext from "@mui/lab/TabContext";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { TextField, Tooltip } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import LoadingProgress from "pages/Repair/components/Loader";
import SearchBox from "pages/Common/SearchBox";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import PriceMethodTable from "pages/Repair/components/PriceMethodTable";
import PriceSummaryTable from "pages/Repair/components/PriceSummaryTable";
import ServiceOrderAccordian from "./ServiceOrderAccordian";

const ServiceOrderEstimation = ({
    open,
    anchorEl,
    activeElement,
    setVersionOpen,
    setTemplateOpen,
    setOpenQuotePopup,
    STATUS_OPTIONS,
    selBuilderStatus,
    makeHeaderEditable,
    handleResetData,
    recentList,
    headerLoading,
    value,
    handleChange,
    viewOnlyTab,
    customerData,
    searchCustResults,
    handleCustSearch,
    handleCustSelect,
    noOptionsCust,
    handleCustomerDataChange,
    updateCustomerData,
    machineData,
    handleMachineDataChange,
    searchModelResults,
    handleModelSelect,
    noOptionsModel,
    noOptionsSerial,
    handleMachineSearch,
    searchSerialResults,
    updateMachineData,
    estimationData,
    setEstimationData,
    handleEstimationDataChange,
    salesOfficeOptions,
    updateEstData,
    generalData,
    setGeneralData,
    validityOptions,
    warrantyClaimStatusOption,
    updateGeneralData,
    pricingData,
    setPricingData,
    currencyOptions,
    updatePriceData,
    handleSnack,
    handleClick,
    handleClose,
    handleVersion,
    selectedVersion,
    handleBuilderStatus,
    disableStatusOptions,
    builderVersionOptions,
    bId,
    segmentItems,
    setSegmentItems,
    partListRecords,
    setPartListRecords,
    laborItemsRecords,
    setLaborItemsRecords,
    consumableItemsRecords,
    setConsumableItemsRecords,
    extWorkItemsRecords,
    setExtWorkItemsRecords,
}) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="d-flex justify-content-center align-items-center">
                    <h5 className="font-weight-600 mb-0">Service Order</h5>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ml-3">
                            {/* <span className="custom-legend mb-1">Version</span> */}
                            <Select className="customselectbtn" onChange={(e) => handleVersion(e)} options={builderVersionOptions} value={selectedVersion} />
                        </div>

                        <div className="ml-3">
                            {/* <span className="custom-legend mb-1">Version</span> */}
                            <Select
                                className="customselectbtn"
                                onChange={(e) => handleBuilderStatus(e)}
                                isOptionDisabled={(e) => disableStatusOptions(e)}
                                options={STATUS_OPTIONS}
                                value={selBuilderStatus}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div>
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <IconButton
                                    className="btn bg-primary text-white font-size-14 pr-0 ml-2"
                                    style={{ borderRadius: "5px" }}
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    <span className="convert mx-2">
                                        Convert to
                                        <span>
                                            <KeyboardArrowDownIcon />
                                        </span>
                                    </span>
                                </IconButton>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem className="custommenu ml-2 mr-4" onClick={() => setTemplateOpen(true)}>
                                    Standard Job
                                </MenuItem>
                                <Divider />
                                <MenuItem data-toggle="modal" className="custommenu ml-2 mr-4" onClick={() => setOpenQuotePopup(true)}>
                                    Quote
                                </MenuItem>
                                <Divider />
                                <MenuItem className="custommenu ml-2 mr-4">ERP Order</MenuItem>
                            </Menu>
                        </React.Fragment>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <a href="#" className="ml-3 font-size-14" title="Share">
                            <img src={shareIcon}></img>
                        </a>
                        <a href="#" className="ml-3 font-size-14" title="Items to Review">
                            <img src={folderaddIcon}></img>
                        </a>
                        <a href="#" className="ml-3 font-size-14" title="Upload">
                            <img src={uploadIcon}></img>
                        </a>
                        {/* <a href="#" className="ml-3 font-size-14"><img src={cpqIcon}></img></a> */}
                        <a href="#" className="ml-3 font-size-14" title="Delete">
                            <img src={deleteIcon}></img>
                        </a>
                        <a href="#" className="ml-3 font-size-14" title="Copy">
                            <img src={copyIcon}></img>
                        </a>
                        <DropdownButton className="customDropdown ml-2" id="dropdown-item-button">
                            <Dropdown.Item as="button" onClick={() => setVersionOpen(true)}>
                                New Versions
                            </Dropdown.Item>
                            <Dropdown.Item as="button">Show Errors</Dropdown.Item>
                            <Dropdown.Item as="button">Review</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </div>
            {activeElement.name === "header" && (
                <>
                    <div className="card p-4 mt-5">
                        <div className="row px-3 pb-1" style={{ justifyContent: "right" }}></div>
                        <h5 className="d-flex justify-content-between align-items-center mb-0 bg-primary p-2 border-radius-10">
                            <div className="d-flex" style={{ display: "contents" }}>
                                <span className="mr-3 ml-2 text-white" style={{ fontSize: "20px" }}>
                                    Header
                                </span>
                                <div className="btn-sm cursor text-white">
                                    <Tooltip title="Edit">
                                        <EditIcon
                                            onClick={() =>
                                                ["DRAFT", "REVISED"].indexOf(selBuilderStatus?.value) > -1
                                                    ? makeHeaderEditable()
                                                    : handleSnack("info", "Set revised status to modify active builders")
                                            }
                                        />
                                    </Tooltip>
                                </div>
                                <div className="btn-sm cursor text-white">
                                    <Tooltip title="Reset">
                                        <ReplayIcon onClick={() => handleResetData("RESET")} />
                                    </Tooltip>
                                </div>
                                <div className="btn-sm cursor text-white">
                                    <Tooltip title="Share">
                                        <ShareOutlinedIcon />
                                    </Tooltip>
                                </div>

                                <div className="btn-sm cursor text-white">
                                    <Tooltip title="Add to Review">
                                        <ReviewAddIcon />
                                    </Tooltip>
                                </div>
                            </div>
                            <button type="button" className="btn border-white bg-white text-primary" onClick={() => recentList()}>
                                Back
                            </button>
                        </h5>
                        <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
                            {headerLoading ? (
                                <LoadingProgress />
                            ) : (
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList className="custom-tabs-div" onChange={handleChange}>
                                            <Tab label="Customer" value="customer" />
                                            <Tab label="Machine " value="machine" />
                                            <Tab label="Estimation Details" value="estimation" />
                                            <Tab label="General Details" value="general" />
                                            <Tab label="Price" value="price" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="customer">
                                        {!viewOnlyTab.custViewOnly ? (
                                            <>
                                                <div className="row input-fields">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">SOURCE</label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control border-radius-10 text-primary"
                                                                id="customer-src"
                                                                value={customerData.source}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                                                            <SearchBox
                                                                value={customerData.customerID}
                                                                onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                                                type="customerId"
                                                                result={searchCustResults}
                                                                onSelect={handleCustSelect}
                                                                noOptions={noOptionsCust}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                                            <input
                                                                type="text"
                                                                value={customerData.customerName}
                                                                name="customerName"
                                                                onChange={handleCustomerDataChange}
                                                                className="form-control border-radius-10 text-primary"
                                                                id="customerNameid"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group w-100">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CONTACT NAME</label>
                                                            <input
                                                                type="text"
                                                                value={customerData.contactName}
                                                                name="contactName"
                                                                onChange={handleCustomerDataChange}
                                                                className="form-control border-radius-10 text-primary"
                                                                id="contactNameid"
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CONTACT EMAIL</label>
                                                            <input
                                                                type="email"
                                                                value={customerData.contactEmail}
                                                                name="contactEmail"
                                                                onChange={handleCustomerDataChange}
                                                                className="form-control border-radius-10 text-primary"
                                                                id="contatEmail"
                                                                aria-describedby="emailHelp"
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CONTACT PHONE</label>
                                                            <input
                                                                type="tel"
                                                                className="form-control border-radius-10 text-primary"
                                                                onChange={handleCustomerDataChange}
                                                                value={customerData.contactPhone}
                                                                name="contactPhone"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER GROUP</label>
                                                            <input
                                                                type="text"
                                                                value={customerData.customerGroup}
                                                                name="customerGroup"
                                                                onChange={handleCustomerDataChange}
                                                                className="form-control border-radius-10 text-primary"
                                                                id="custGroup"
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "right" }}>
                                                    <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => handleResetData("CANCEL")}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light bg-primary text-white"
                                                        disabled={
                                                            !(customerData.source && customerData.contactEmail && customerData.customerGroup && customerData.contactName) ||
                                                            noOptionsCust
                                                        }
                                                        onClick={updateCustomerData}
                                                    >
                                                        Save & Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row mt-3">
                                                <ReadOnlyField label="SOURCE" value={customerData.source} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CUSTOMER ID" value={customerData.customerID} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CUSTOMER NAME" value={customerData.customerName} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CUSTOMER EMAIL" value={customerData.contactEmail} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CONTACT NAME" value={customerData.contactName} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CONTACT PHONE" value={customerData.contactPhone} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CUSTOMER GROUP" value={customerData.customerGroup} className="col-md-4 col-sm-4" />
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value="machine">
                                        {!viewOnlyTab.machineViewOnly ? (
                                            <>
                                                <div className="row input-fields">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">Make</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="make-id"
                                                                name="make"
                                                                value={machineData.make}
                                                                onChange={handleMachineDataChange}
                                                                placeholder="Auto Filled"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">Family</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="family-id"
                                                                name="family"
                                                                value={machineData.family}
                                                                onChange={handleMachineDataChange}
                                                                placeholder="Auto Filled"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                                                            <SearchBox
                                                                value={machineData.model}
                                                                onChange={(e) => handleMachineSearch("model", e.target.value)}
                                                                type="model"
                                                                result={searchModelResults}
                                                                onSelect={handleModelSelect}
                                                                noOptions={noOptionsModel}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">SERIAL #</label>
                                                            <SearchBox
                                                                value={machineData.serialNo}
                                                                onChange={(e) => handleMachineSearch("serialNo", e.target.value)}
                                                                type="equipmentNumber"
                                                                result={searchSerialResults}
                                                                onSelect={handleModelSelect}
                                                                noOptions={noOptionsSerial}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">SMU (Service Meter Unit)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="smu-id"
                                                                name="smu"
                                                                value={machineData.smu}
                                                                onChange={handleMachineDataChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">UNIT NO / FLEET NO</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                onChange={handleMachineDataChange}
                                                                value={machineData.fleetNo}
                                                                name="fleetNo"
                                                                id="fleet-id"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">REGISTRATION NO</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                onChange={handleMachineDataChange}
                                                                value={machineData.registrationNo}
                                                                name="registrationNo"
                                                                id="registration-id"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CHASIS NO</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="chasis-id"
                                                                onChange={handleMachineDataChange}
                                                                value={machineData.chasisNo}
                                                                name="chasisNo"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "right" }}>
                                                    <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => handleResetData("CANCEL")}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light bg-primary text-white"
                                                        disabled={!(machineData.model && machineData.serialNo) || noOptionsModel || noOptionsSerial}
                                                        onClick={updateMachineData}
                                                    >
                                                        Save & Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row mt-3">
                                                <ReadOnlyField label="MAKE" value={machineData.make} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="FAMILY" value={machineData.family} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="MODEL" value={machineData.model} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="SERIAL NO" value={machineData.serialNo} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="SMU (Service Meter Unit)" value={machineData.smu} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="UNIT NO / FLEET NO" value={machineData.fleetNo} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="REGISTRATION NO" value={machineData.registrationNo} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="CHASSIS NO" value={machineData.chasisNo} className="col-md-4 col-sm-4" />
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value="estimation">
                                        {!viewOnlyTab.estViewOnly ? (
                                            <>
                                                <div className="row input-fields">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">PREPARED BY</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                value={estimationData.preparedBy}
                                                                name="preparedBy"
                                                                onChange={handleEstimationDataChange}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">APPROVED BY</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                value={estimationData.approvedBy}
                                                                name="approvedBy"
                                                                onChange={handleEstimationDataChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">PREPARED ON</label>
                                                            <div className="align-items-center date-box">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <MobileDatePicker
                                                                        inputFormat="dd/MM/yyyy"
                                                                        className="form-controldate border-radius-10"
                                                                        // sx={{
                                                                        //   "&& .MuiPickersDay-dayWithMargin": {color: '#fff !important'},
                                                                        //   }}
                                                                        // InputProps={{style: {...FONT_STYLE, color: '#fff'}}}
                                                                        minDate={estimationData.preparedOn}
                                                                        maxDate={new Date()}
                                                                        closeOnSelect
                                                                        value={estimationData.preparedOn}
                                                                        onChange={(e) => setEstimationData({ ...estimationData, preparedOn: e })}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                inputProps={{ ...params.inputProps, style: FONT_STYLE }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">REVISED BY</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                value={estimationData.revisedBy}
                                                                name="revisedBy"
                                                                onChange={handleEstimationDataChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">REVISED ON</label>
                                                            <div className="align-items-center date-box">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <MobileDatePicker
                                                                        inputFormat="dd/MM/yyyy"
                                                                        className="form-controldate border-radius-10"
                                                                        minDate={estimationData.revisedOn}
                                                                        maxDate={new Date()}
                                                                        closeOnSelect
                                                                        value={estimationData.revisedOn}
                                                                        onChange={(e) => setEstimationData({ ...estimationData, revisedOn: e })}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                inputProps={{ ...params.inputProps, style: FONT_STYLE }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">SALES OFFICE / BRANCH</label>
                                                            <Select
                                                                onChange={(e) => setEstimationData({ ...estimationData, salesOffice: e })}
                                                                options={salesOfficeOptions}
                                                                value={estimationData.salesOffice}
                                                                styles={FONT_STYLE_SELECT}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "right" }}>
                                                    <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => handleResetData("CANCEL")}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light bg-primary text-white"
                                                        onClick={updateEstData}
                                                        disabled={!estimationData.preparedBy || !estimationData.preparedOn || !estimationData.salesOffice}
                                                    >
                                                        Save & Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row mt-3">
                                                <ReadOnlyField label="PREPARED BY" value={estimationData.preparedBy} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="APPROVED BY" value={estimationData.approvedBy} className="col-md-4 col-sm-4" />

                                                <ReadOnlyField
                                                    label="PREPARED ON"
                                                    value={<Moment format="DD/MM/YYYY">{estimationData.preparedOn}</Moment>}
                                                    className="col-md-4 col-sm-4"
                                                />
                                                <ReadOnlyField label="REVISED BY" value={estimationData.revisedBy} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField
                                                    label="REVISED ON"
                                                    value={<Moment format="DD/MM/YYYY">{estimationData.revisedOn}</Moment>}
                                                    className="col-md-4 col-sm-4"
                                                />
                                                <ReadOnlyField label="SALES OFFICE / BRANCH" value={estimationData.salesOffice?.label} className="col-md-4 col-sm-4" />
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value="general">
                                        {!viewOnlyTab.generalViewOnly ? (
                                            <>
                                                <div className="row input-fields">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">
                                                                <span className=" mr-2">ESTIMATION DATE</span>
                                                            </label>
                                                            <div className="align-items-center date-box">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <MobileDatePicker
                                                                        inputFormat="dd/MM/yyyy"
                                                                        className="form-controldate border-radius-10"
                                                                        minDate={generalData.estimationDate}
                                                                        maxDate={new Date()}
                                                                        closeOnSelect
                                                                        value={generalData.estimationDate}
                                                                        onChange={(e) => setGeneralData({ ...generalData, estimationDate: e })}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                inputProps={{ ...params.inputProps, style: FONT_STYLE }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">ESTIMATION #</label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                className="form-control border-radius-10 text-primary"
                                                                id="estNoId"
                                                                value={generalData.estimationNo}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">DESCRIPTION</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="desc-id"
                                                                maxLength={140}
                                                                value={generalData.description}
                                                                onChange={(e) => setGeneralData({ ...generalData, description: e.target.value })}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                id="desc-id"
                                                                maxLength={140}
                                                                value={generalData.reference}
                                                                onChange={(e) => setGeneralData({ ...generalData, reference: e.target.value })}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">VALIDITY</label>
                                                            <Select
                                                                // defaultValue={selectedOption}
                                                                onChange={(e) => setGeneralData({ ...generalData, validity: e })}
                                                                options={validityOptions}
                                                                value={generalData.validity}
                                                                styles={FONT_STYLE_SELECT}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">VERSION</label>
                                                            <input
                                                                type="text"
                                                                className="form-control border-radius-10 text-primary"
                                                                disabled
                                                                value={parseFloat(selectedVersion.value).toFixed(1)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">WARRANTY CLAIM STATUS</label>
                                                            <Select
                                                                // defaultValue={selectedOption}
                                                                onChange={(e) => setGeneralData({ ...generalData, warrantyClaimStatus: e })}
                                                                options={warrantyClaimStatusOption}
                                                                value={generalData.warrantyClaimStatus}
                                                                styles={FONT_STYLE_SELECT}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "right" }}>
                                                    <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => handleResetData("CANCEL")}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light bg-primary text-white"
                                                        onClick={updateGeneralData}
                                                        disabled={
                                                            !generalData.estimationDate ||
                                                            !generalData.description ||
                                                            !generalData.estimationNo ||
                                                            !generalData.reference ||
                                                            !generalData.validity
                                                        }
                                                    >
                                                        Save & Next
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row mt-3">
                                                <ReadOnlyField
                                                    label="ESTIMATION DATE"
                                                    value={<Moment format="DD/MM/YYYY">{generalData.estimationDate}</Moment>}
                                                    className="col-md-4 col-sm-4"
                                                />
                                                <ReadOnlyField label="ESTIMATION #" value={generalData.estimationNo} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="DESCRIPTION" value={generalData.description} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="REFERENCE" value={generalData.reference} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="VALIDTITY (DAYs)" value={generalData.validity?.label} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="VERSION" value={parseFloat(selectedVersion.value).toFixed(1)} className="col-md-4 col-sm-4" />
                                                <ReadOnlyField label="WARRANTY CLAIM STATUS" value={generalData.warrantyClaimStatus?.label} className="col-md-4 col-sm-4" />
                                            </div>
                                        )}
                                    </TabPanel>
                                    <TabPanel value="price">
                                        {!viewOnlyTab.priceViewOnly ? (
                                            <>
                                                <div className="row input-fields">
                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">NET PRICE</label>
                                                            <input type="text" disabled className="form-control border-radius-10 text-primary" value={pricingData.netPrice} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">PRICE DATE</label>
                                                            <div className="align-items-center date-box">
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <MobileDatePicker
                                                                        inputFormat="dd/MM/yyyy"
                                                                        className="form-controldate border-radius-10"
                                                                        minDate={pricingData.priceDate}
                                                                        maxDate={new Date()}
                                                                        closeOnSelect
                                                                        value={pricingData.priceDate}
                                                                        onChange={(e) => setPricingData({ ...pricingData, priceDate: e })}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                inputProps={{ ...params.inputProps, style: FONT_STYLE }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">ADJUSTED PRICE</label>
                                                            <input
                                                                type="text"
                                                                disabled={!(pricingData.priceMethod?.value === "FLAT_RATE")}
                                                                className="form-control border-radius-10 text-primary"
                                                                value={pricingData.priceMethod?.value === "FLAT_RATE" ? pricingData.adjustedPrice : 0.0}
                                                                onChange={(e) => setPricingData({ ...pricingData, adjustedPrice: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                                                            <Select
                                                                onChange={(e) => setPricingData({ ...pricingData, currency: e })}
                                                                options={currencyOptions}
                                                                value={pricingData.currency}
                                                                styles={FONT_STYLE_SELECT}
                                                            />
                                                            <div className="css-w8dmq8">*Mandatory</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ justifyContent: "right" }}>
                                                    <button type="button" className="btn btn-light bg-primary text-white mr-1" onClick={() => handleResetData("CANCEL")}>
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light bg-primary text-white"
                                                        onClick={updatePriceData}
                                                        disabled={!(pricingData.priceDate && pricingData.currency)}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="row mt-3">
                                                    <ReadOnlyField label="NET PRICE" value={pricingData.netPrice} className="col-md-4 col-sm-4" />
                                                    <ReadOnlyField
                                                        label="PRICE DATE"
                                                        value={<Moment format="DD/MM/YYYY">{pricingData.priceDate}</Moment>}
                                                        className="col-md-4 col-sm-4"
                                                    />
                                                    <ReadOnlyField label="ADJUSTED PRICE" value={pricingData.adjustedPrice} className="col-md-4 col-sm-4" />
                                                    <ReadOnlyField label="CURRENCY" value={pricingData.currency?.label} className="col-md-4 col-sm-4" />
                                                </div>
                                                <hr />
                                                <div className="mb-5">
                                                    <PriceMethodTable
                                                        rows={pricingData.priceDetailDTO}
                                                        setRows={(rows) => {
                                                            console.log(rows);
                                                            setPricingData({ ...pricingData, priceDetailDTO: rows });
                                                        }}
                                                    />
                                                    <div className="row my-3 mr-2" style={{ justifyContent: "right" }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-light bg-primary text-white"
                                                            onClick={updatePriceData}
                                                            disabled={!(pricingData.priceDate && pricingData.currency)}
                                                        >
                                                            Save Price Methods
                                                        </button>
                                                    </div>
                                                    <PriceSummaryTable
                                                        rows={pricingData.priceEstimateDTO}
                                                        setRows={(rows) => setPricingData({ ...pricingData, priceEstimateDTO: rows })}
                                                    />
                                                    <div className="row my-3 mr-2" style={{ justifyContent: "right" }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-light bg-primary text-white"
                                                            onClick={updatePriceData}
                                                            disabled={!(pricingData.priceDate && pricingData.currency)}
                                                        >
                                                            Save Price Summary
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </TabPanel>
                                </TabContext>
                            )}
                        </Box>
                    </div>

                    {!headerLoading && (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4>Estimate Details</h4>
                            </div>
                            <div className="card border p-3">
                                <ServiceOrderAccordian
                                    segmentItems={segmentItems}
                                    setSegmentItems={setSegmentItems}
                                    partListRecords={partListRecords}
                                    setPartListRecords={setPartListRecords}
                                    laborItemsRecords={laborItemsRecords}
                                    setLaborItemsRecords={setLaborItemsRecords}
                                    consumableItemsRecords={consumableItemsRecords}
                                    setConsumableItemsRecords={setConsumableItemsRecords}
                                    extWorkItemsRecords={extWorkItemsRecords}
                                    setExtWorkItemsRecords={setExtWorkItemsRecords}
                                    handleSnack={handleSnack}
                                    activeElement={activeElement}
                                    bId={bId}
                                    generalData={generalData}
                                    pricingData={pricingData}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ServiceOrderEstimation;
