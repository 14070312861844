import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FONT_STYLE } from "../CONSTANTS";

const ShopRepairAssignmentDtlsModal = ({ show, hideModal, handleSanck }) => {
    // submit the data
    const handleSubmit = () => {
        handleSanck("success", "Submited successfully!");
        hideModal();
    };
    return (
        <Modal show={show} onHide={hideModal} size="lg">
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="border border-radius-6 p-1 cursor" style={{ background: "#F4F3F8" }}>
                        <DeleteOutlineIcon sx={{ fontSize: 30, color: "red" }} />
                    </div>
                    <div className="text-center">
                        <p className="mb-1">Id Number</p>
                        <h6 className="font-size-16 font-weight-500">432 YFV ZX12</h6>
                    </div>
                    <div className="border border-radius-6 p-1 cursor" style={{ background: "#F4F3F8" }} onClick={hideModal}>
                        <CloseIcon sx={{ fontSize: 30 }} />
                    </div>
                </div>
                <div className="card border px-3 mt-2 mb-3">
                    <div className="row input-fields">
                        <div className="col-md-6 col-sm-6">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">PHONE NUMBER</label>
                                <input type="text" disabled className="form-control border-radius-10 text-primary" name="phoneNumber" placeholder="Phone Number" />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <div className="form-group">
                                <label className="text-light-dark font-size-12 font-weight-500">JOB ID</label>
                                <input type="text" disabled className="form-control border-radius-10 text-primary" name="jobId" placeholder="Job Id" />
                            </div>
                        </div>
                    </div>
                    <fieldset className="my-4">
                        <legend>Personal Information</legend>
                        <div className="row input-fields">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">FIRST NAME</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="firstName" placeholder="First Name" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">LAST NAME</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="lastName" placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="my-4">
                        <legend>Address Information</legend>
                        <div className="row input-fields">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">STREET ADDRESS</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="streetAddress" placeholder="Street Address" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">BUILDING NUMBER</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="buildingNumber" placeholder="Building Number" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">APARTMENT NUMBER</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="apartmentNumber" placeholder="Apartment Number" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">ZIP CODE</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="zipCode" placeholder="Zip Code" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="my-4">
                        <legend>Job Details</legend>
                        <div className="row input-fields">
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">JOB TYPE</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="jobType" placeholder="Job Type" />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">JOB TITLE</label>
                                    <input type="text" disabled className="form-control border-radius-10 text-primary" name="buildingNumber" placeholder="Building Number" />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">JOB DETAILS</label>
                                    <textarea
                                        className="form-control border-radius-10 text-primary"
                                        name="jobDetails"
                                        cols="30"
                                        rows="2"
                                        // value={evaluationDetailsData?.question5}
                                        // onChange={(e) => setEvaluationDetailsData({ ...evaluationDetailsData, question5: e.target.value })}
                                        placeholder="Job Details"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">
                                        <span className=" mr-2">DATE</span>
                                    </label>
                                    <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                inputFormat="dd/MM/yyyy"
                                                className="form-controldate border-radius-10"
                                                closeOnSelect
                                                // value={pvPanelReqObj?.pvPanelPurchaseDate}
                                                // onChange={(e) => handlePvPanelSelectValueChange("pvPanelPurchaseDate", e)}
                                                renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="form-group">
                                    <label className="text-light-dark font-size-12 font-weight-500">
                                        <span className=" mr-2">TIME</span>
                                    </label>
                                    <div className="align-items-center date-box">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileTimePicker
                                                inputFormat="hh:mm a"
                                                className="form-controldate border-radius-10"
                                                closeOnSelect
                                                // value={pvPanelReqObj?.pvPanelPurchaseDate}
                                                // onChange={(e) => handlePvPanelSelectValueChange("pvPanelPurchaseDate", e)}
                                                renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary" onClick={hideModal}>
                        Close
                    </button>
                    <button className="btn bg-primary text-white ml-2" onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShopRepairAssignmentDtlsModal;
