import React, { useEffect, useState } from "react";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import EditIcon from "@mui/icons-material/Edit";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import $ from "jquery";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import SearchComponent from "../components/SearchComponent";
import { getExtWork, getVendors, sparePartSearch } from "services/searchServices";
import { CONS_EXT_PRICE_OPTIONS, EXTWORK_SEARCH_Q_OPTIONS, FONT_STYLE_SELECT, GRID_STYLE, MISC_PRICE_OPTIONS } from "../CONSTANTS";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Divider, Tooltip } from "@mui/material";
import {
  AddExtWorkItem,
  AddExtWorkToService,
  AddMiscToService,
  AddServiceHeader,
  FetchExtWorkforService,
  FetchExtWorkItems,
  FetchMiscforService,
  RemoveExtWorkItem,
} from "services/repairBuilderServices";
import MaintenanceBuilderOtherCostItemAddModal from "./MaintenanceBuilderOtherCostItemAddModal";

const initialExtWorkQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const initialPartsQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const initialExtWorkItemData = {
  activityId: "",
  activityName: "",
  description: "",
  supplyingVendorCode: "",
  supplyingVendorName: "",
  unitOfMeasure: "",
  unitPrice: 0.0,
  extendedPrice: 0.0,
  totalPrice: 0.0,
  estimatedHours: "",
  adjustedPrice: 0.0,
  dimensions: "",
};

const MaintenanceBuilderOtherCostModal = ({
  show,
  hideModal,
  operationIndex,
  segmentIndex,
  operationData,
  segmentItems,
  setSegmentItems,
  activeElement,
  otherCostItems,
  setOtherCostItems,
  miscTypeList = [],
  activityIdList = [],
  dimensionList = [],
  unitOfMeasureOptions = [],
  generalData,
  pricingData,
  isOtherCostItemEdit,
  isOtherCostItemEditRow,
  handleExtWorkEditRow,
  handleSnack,
}) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const [tabValue, setTabValue] = useState("extwork");

  const [extWorkViewOnly, setExtWorkViewOnly] = useState(false);
  const [miscViewOnly, setMiscViewOnly] = useState(false);

  // Ext Work Header
  const [extWorkData, setExtWorkData] = useState({
    jobCode: operationData?.jobCode || "",
    jobCodeDescription: operationData?.jobCodeDescription || "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    basePrice: 0.0,
    percentageOfBase: 0,
  });

  // Misc Header
  const [miscData, setMiscData] = useState({
    jobCode: operationData?.jobCode || "",
    jobCodeDescription: operationData?.jobCodeDescription || "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    type: "",
    basePrice: 0.0,
    percentageOfBase: 0,
  });

  const [queryExtSearchSelector, setQueryExtSearchSelector] = useState(initialExtWorkQuery);
  const [queryPartsSearchSelector, setQueryPartsSearchSelector] = useState(initialPartsQuery);

  const [searchResultPartsOpen, setSearchResultPartsOpen] = useState(false);
  const [searchResultExtWorkOpen, setSearchResultExtWorkOpen] = useState(false);

  let [extWorkItems, setExtWorkItems] = useState([]);

  const [masterData, setMasterData] = useState([]);

  const [extWorkItemData, setExtWorkItemData] = useState(initialExtWorkItemData);
  const [extWorkItemOpen, setExtWorkItemOpen] = useState(false);

  const [noOptionsVendor, setNoOptionsVendor] = useState(false);
  const [searchVenodrResults, setSearchVendorResults] = useState([]);

  useEffect(() => {
    if (operationData?.serviceEstimateId) {
      if (!isOtherCostItemEdit) {
        if (operationData?.externalWorkDetailsItems) {
          FetchExtWorkforService(operationData?.serviceEstimateId)
            .then((resultExtWork) => {
              if (resultExtWork && resultExtWork.id) {
                setExtWorkData({
                  ...resultExtWork,
                  id: resultExtWork.id,
                  pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === resultExtWork.pricingMethod),
                  totalPrice: resultExtWork.totalPrice ? resultExtWork.totalPrice : 0,
                });
                populateExtWorkItems(resultExtWork);
                setExtWorkViewOnly(true);
              }
            })
            .catch((e) => {
              setExtWorkData({
                ...extWorkData,
                jobCode: operationData?.jobCode || "",
                jobCodeDescription: operationData?.jobCodeDescription || "",
              });
            });
        }
      } else {
        openExtWorkRow(isOtherCostItemEditRow);
      }
      populateMiscData(operationData);
    }
  }, [isOtherCostItemEdit, operationData?.serviceEstimateId, operationData?.externalWorkDetailsItems]);

  function populateExtWorkItems(result) {
    FetchExtWorkItems(result.id)
      .then((resultExtWorkItems) => {
        if (resultExtWorkItems && resultExtWorkItems.result?.length > 0) {
          setExtWorkItems(resultExtWorkItems.result);
        }
      })
      .catch((e) => {
        handleSnack("error", "Error occurred while fetching external work items");
      });
  }

  function populateExtWorkItems(result) {
    FetchExtWorkItems(result.id)
      .then((resultExtWorkItems) => {
        if (resultExtWorkItems && resultExtWorkItems.result?.length > 0) {
          setExtWorkItems(resultExtWorkItems.result);
        }
      })
      .catch((e) => {
        handleSnack("error", "Error occurred while fetching external work items");
      });
  }

  // Populate misc header
  function populateMiscData(operationData) {
    FetchMiscforService(operationData.serviceEstimateId)
      .then((resultMisc) => {
        if (resultMisc && resultMisc.id) {
          setMiscData({
            ...resultMisc,
            id: resultMisc.id,
            pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === resultMisc.pricingMethod),
            type: miscTypeList.filter(function (element) {
              return resultMisc.type?.includes(element.value);
            }),
            totalPrice: resultMisc.totalPrice ? resultMisc.totalPrice : 0,
          });
          setMiscViewOnly(true);
        } else {
          setMiscData({
            ...miscData,
            jobCode: operationData?.jobCode || "",
            jobCodeDescription: operationData.jobCodeDescription || "",
          });
        }
      })
      .catch((e) => {
        setMiscData({
          ...miscData,
          jobCode: operationData?.jobCode || "",
          jobCodeDescription: operationData.jobCodeDescription || "",
        });
      });
  }

  // change the tab
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const makeDataEditable = (type) => {
    if (["DRAFT", "REVISED"].indexOf(activeElement?.builderStatus) > -1) {
      if (tabValue === "extwork" && extWorkViewOnly) setExtWorkViewOnly(false);
      else if (tabValue === "othrMisc" && miscViewOnly) setMiscViewOnly(false);
    } else {
      handleSnack("info", "Active Builder cannot be changed, change status to REVISE!");
    }
  };

  // Once parts are selected to add clear the search results
  const clearFilteredData = () => {
    setMasterData([]);
  };

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    if (extWorkData.id) {
      $(".scrollbar").css("display", "none");
      var searchStr = "";
      var querySearchSelector = type === "parts" ? queryPartsSearchSelector : queryExtSearchSelector;
      querySearchSelector.map(function (item, i) {
        if (i === 0 && item.selectCategory?.value && item.inputSearch) {
          searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        } else if (item.selectCategory?.value && item.inputSearch && item.selectOperator?.value) {
          searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        } else {
          searchStr = "";
        }
        return searchStr;
      });

      try {
        if (searchStr) {
          if (type === "extwork") {
            const res = await getExtWork(searchStr);
            setMasterData(res);
            setSearchResultExtWorkOpen(true);
          } else if (type === "parts") {
            const res = await sparePartSearch(searchStr);
            setMasterData(res);
            setSearchResultPartsOpen(true);
          }
        } else {
          handleSnack("info", "Please fill the search criteria!");
        }
      } catch (err) {
        handleSnack("error", "Error occurred while fetching results!");
      }
    } else {
      handleSnack("warning", "Please update the External Work details!");
    }
  };

  // Add or Update misc data
  const updateMiscHeader = () => {
    if (operationData?.serviceEstimateId) {
      let miscTypes = [];
      miscData.type?.map((element) => miscTypes.push(element.value));
      let data = {
        ...(miscData.id && { id: miscData.id }),
        jobCode: miscData.jobCode,
        jobCodeDescription: miscData.jobCodeDescription,
        payer: miscData.payer,
        type: miscTypes,
      };
      AddMiscToService(operationData?.serviceEstimateId, data)
        .then((result) => {
          setMiscData({
            ...result,
            id: result.id,
            pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
            type: miscTypeList.find((element) => element.value === result.type),
          });
          // populateServiceEstimation("othrMisc");
          handleSnack("success", "Misc details updated!");
          setMiscViewOnly(true);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating misc data!");
        });
    } else {
      let estimateData = {
        reference: segmentItems?.builderId || "RO000174",
        description: generalData?.description || "992K Warranty",
        segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        currency: pricingData?.currency?.value || "USD",
        jobCode: operationData?.jobCode || "JB0361",
        id: null,
        jobCodeDescription: operationData?.jobCodeDescription || "Description114",
        componentCode: operationData?.componentCode || "ABC123",
        componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
      };
      AddServiceHeader(operationData?.id, estimateData)
        .then((estimateResult) => {
          let miscTypes = [];
          miscData.type?.map((element) => miscTypes.push(element.value));
          let data = {
            ...(miscData.id && { id: miscData.id }),
            jobCode: miscData.jobCode,
            jobCodeDescription: miscData.jobCodeDescription,
            payer: miscData.payer,
            type: miscTypes,
          };

          AddMiscToService(estimateResult.id, data)
            .then((result) => {
              setMiscData({
                ...result,
                id: result.id,
                pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                type: miscTypeList.find((element) => element.value === result.type),
              });
              const _segmentItems = { ...segmentItems };
              const _segments = _segmentItems["segments"];
              const selectedSegment = _segments[segmentIndex];
              const _operations = selectedSegment["operations"][operationIndex];
              _operations["serviceEstimateId"] = estimateResult?.id;
              setSegmentItems({ ..._segmentItems });

              // populateServiceEstimation("othrMisc");
              handleSnack("success", "Misc details updated!");
              setMiscViewOnly(true);
            })
            .catch((err) => {
              handleSnack("error", "Error occurred while updating misc data!");
            });
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating misc data!");
          // handleSnack("error", "Error occurred while updating service estimate header!");
        });
    }
  };

  // Close ext work search modal
  const handleSearchResExtClose = () => {
    setSearchResultExtWorkOpen(false);
  };

  // exte work Item Add Modal close
  const handleExtWorkItemClose = () => {
    setExtWorkItemOpen(false);
    setSearchVendorResults([]);
    setExtWorkItemData(initialExtWorkItemData);
    if (isOtherCostItemEdit) {
      handleExtWorkEditRow(extWorkData?.id);
    }
  };

  // Select the external work item
  const selectExtWorkItem = async (selectedData) => {
    setSearchResultExtWorkOpen(false);
    setExtWorkItemData({
      ...extWorkItemData,
      activityId: activityIdList.find((element) => element.value === selectedData.activityId),
      description: selectedData.longDescription,
      activityName: selectedData.activityDescription,
      supplyingVendorCode: selectedData.supplyingVendorCode,
      supplyingVendorName: selectedData.supplyingVendorName,
    });
    setExtWorkItemOpen(true);
  };

  // Add or Update Consumable Item
  const addExtWorkItem = () => {
    let data = {
      // ...extWorkItemData,
      ...(extWorkItemData.id && { id: extWorkItemData.id }),
      activityId: extWorkItemData.activityId?.value,
      activityName: extWorkItemData.activityName,
      description: extWorkItemData.description,
      supplyingVendorCode: extWorkItemData.supplyingVendorCode,
      supplyingVendorName: extWorkItemData.supplyingVendorName,
      estimatedHours: extWorkItemData.estimatedHours,
      dimensions: extWorkItemData.dimensions?.value,
      ...(extWorkItemData.adjustedPrice && {
        adjustedPrice: extWorkItemData.adjustedPrice,
      }),
      unitOfMeasure: extWorkItemData.unitOfMeasure?.value,
    };

    AddExtWorkItem(extWorkData.id, data)
      .then((result) => {
        setExtWorkItemData(initialExtWorkItemData);

        const _extWorkItems = [...extWorkItems];
        const extWorkItemsIndex = _extWorkItems.findIndex((item) => item.id === result.id);
        if (extWorkItemsIndex !== -1) {
          _extWorkItems[extWorkItemsIndex] = result;
        } else {
          _extWorkItems.push(result);
        }
        setExtWorkItems([..._extWorkItems]);

        const _otherCostItems = [...otherCostItems["result"]];
        const otherCostItemsIndex = _otherCostItems.findIndex((item) => item.id === result.id);
        if (otherCostItemsIndex !== -1) {
          _otherCostItems[otherCostItemsIndex] = result;
        } else {
          _otherCostItems.push(result);
        }
        setOtherCostItems({ result: [..._otherCostItems], totalRows: _otherCostItems.length });

        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];
        const selectedSegment = _segments[segmentIndex];
        const _operations = selectedSegment["operations"][operationIndex];
        let optOtherCostItemIndex = _operations["externalWorkDetailsItems"]["externalWorkItems"].findIndex((item) => item === result.id);
        if (optOtherCostItemIndex === -1) {
          _operations["externalWorkDetailsItems"]["externalWorkItems"].push(result?.id);
        }
        setSegmentItems({ ..._segmentItems });
        handleSnack("success", `${optOtherCostItemIndex === -1 ? "Added" : "Updated"} ext work item successfully`);
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while adding external work item!");
      });
    setQueryExtSearchSelector(initialExtWorkQuery);
    handleExtWorkItemClose();
  };

  // Search Vendors
  const handleVendorSearch = async (searchVendorfieldName, searchText) => {
    setSearchVendorResults([]);
    // if (searchVendorfieldName === "consVendor") {
    //   consumableItemData.supplyingVendorName = searchText;
    // } else {
    // }
    extWorkItemData.supplyingVendorName = searchText;
    if (searchText) {
      await getVendors("fullName~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchVendorResults(result);
            setNoOptionsVendor(false);
          } else {
            setNoOptionsVendor(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the vendor!");
        });
    }
  };

  // Select the vendor from search result
  const handleVendorSelect = (type, currentItem) => {
    setExtWorkItemData({
      ...extWorkItemData,
      supplyingVendorName: currentItem.fullName,
      supplyingVendorCode: currentItem.customerId,
    });
    setSearchVendorResults([]);
  };

  // Add or Update ext work data
  const updateExtWorkHeader = () => {
    if (operationData?.serviceEstimateId) {
      let data = {
        // ...extWorkData,
        ...(extWorkData.id && { id: extWorkData.id }),
        jobCode: extWorkData.jobCode,
        jobCodeDescription: extWorkData.jobCodeDescription,
        payer: extWorkData.payer,
      };
      AddExtWorkToService(operationData?.serviceEstimateId, data)
        .then((result) => {
          setExtWorkData({
            ...result,
            id: result.id,
            pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
          });

          const _segmentItems = { ...segmentItems };
          const _segments = _segmentItems["segments"];
          const selectedSegment = _segments[segmentIndex];
          const _operations = selectedSegment["operations"][operationIndex];
          if (!_operations["externalWorkDetailsItems"]) {
            _operations["externalWorkDetailsItems"] = { ...result, externalWorkItems: [] };
            setSegmentItems({ ..._segmentItems });
          }

          handleSnack("success", "External work details updated!");
          setExtWorkViewOnly(true);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating external work data!");
        });
    } else {
      let estimateData = {
        reference: segmentItems?.builderId || "RO000174",
        description: generalData?.description || "992K Warranty",
        segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        currency: pricingData?.currency?.value || "USD",
        jobCode: operationData?.jobCode || "JB0361",
        id: null,
        jobCodeDescription: operationData?.jobCodeDescription || "Description114",
        componentCode: operationData?.componentCode || "ABC123",
        componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
      };
      AddServiceHeader(operationData?.Id, estimateData)
        .then((estimateResult) => {
          let data = {
            // ...extWorkData,
            ...(extWorkData.id && { id: extWorkData.id }),
            jobCode: extWorkData.jobCode,
            jobCodeDescription: extWorkData.jobCodeDescription,
            payer: extWorkData.payer,
          };
          AddExtWorkToService(estimateResult?.id, data)
            .then((result) => {
              setExtWorkData({
                ...result,
                id: result.id,
                pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
              });

              const _segmentItems = { ...segmentItems };
              const _segments = _segmentItems["segments"];
              const selectedSegment = _segments[segmentIndex];
              const _operations = selectedSegment["operations"][operationIndex];
              _operations["serviceEstimateId"] = estimateResult?.id;
              if (!_operations["externalWorkDetailsItems"]) {
                _operations["externalWorkDetailsItems"] = { ...result, externalWorkItems: [] };
              }
              setSegmentItems({ ..._segmentItems });

              handleSnack("success", "External work details updated!");
              setExtWorkViewOnly(true);
            })
            .catch((err) => {
              handleSnack("error", "Error occurred while updating external work data!");
            });
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating external work data!");
          // handleSnack("error", "Error occurred while updating service estimate header!");
        });
    }
  };

  // Open ext work item to view or edit
  const openExtWorkRow = (row) => {
    if (isOtherCostItemEdit) {
      const _segmentItems = { ...segmentItems };
      const _segments = _segmentItems["segments"];
      const selectedSegment = _segments[segmentIndex];
      const _operations = selectedSegment["operations"][operationIndex];
      setExtWorkData({
        ...extWorkData,
        id: _operations["externalWorkDetailsItems"]["id"],
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData?.jobCodeDescription || "",
      });
    }
    setExtWorkItemData({
      ...row,
      activityId: activityIdList.find((element) => element.value === row.activityId),
      dimensions: dimensionList.find((element) => element.value === row.dimensions),
      unitOfMeasure: unitOfMeasureOptions.find((element) => element.value === row.unitOfMeasure),
      unitPrice: row.unitPrice ? row.unitPrice : 0,
      extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
      totalPrice: row.totalPrice ? row.totalPrice : 0,
    });
    setExtWorkItemOpen(true);
  };

  //Remove Ext work Item
  const handleDeleteExtWorkItem = (extWorkItemId) => {
    RemoveExtWorkItem(extWorkData.id, extWorkItemId)
      .then((res) => {
        handleSnack("success", res);

        const _extWorkItems = extWorkItems.filter((item) => item?.id !== extWorkItemId);
        setExtWorkItems(_extWorkItems);

        const _otherCostItems = otherCostItems["result"].filter((item) => item?.id !== extWorkItemId);
        setOtherCostItems({ result: [..._otherCostItems], totalRows: _otherCostItems.length });

        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];
        const selectedSegment = _segments[segmentIndex];
        const _operations = selectedSegment["operations"][operationIndex];
        _operations["externalWorkDetailsItems"]["externalWorkItems"].filter((item) => item !== extWorkItemId);
        setSegmentItems({ ..._segmentItems });
      })
      .catch((e) => {
        handleSnack("error", "Error occurred while removing the ext work item!");
      });
  };

  const columnsExternal = [
    { field: "activityId", headerName: "Activity ID", flex: 1, width: 70 },
    { field: "activityName", headerName: "Activity Name", flex: 1, width: 70 },
    { field: "description", headerName: "Description", flex: 1, width: 70 },
    { field: "estimatedHours", headerName: "Estimated Effort", flex: 1, width: 130 },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    { field: "extendedPrice", headerName: "Extended price", flex: 1, width: 130 },
    { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
    { field: "dimensions", headerName: "Dimension", flex: 1, width: 130 },
    { field: "supplyingVendorName", headerName: "Supplying Vendor", flex: 1, width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => openExtWorkRow(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            onClick={() => handleDeleteExtWorkItem(row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  // Search table column for consumable
  const columnsExtWorkSearch = [
    { headerName: "Activity Id", field: "activityId", flex: 1, width: 70 },
    { headerName: "Activity Name", field: "activityDescription", flex: 1, width: 130 },
    { headerName: "Supplying Vendor", field: "supplyingVendorName", flex: 1, width: 130 },
  ];

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <div className="d-flex align-items-center p-3">
          <h4 className="mb-0">{title}</h4>
          <a className="btn-sm cursor mx-2" onClick={() => makeDataEditable()}>
            <EditIcon sx={{ fontSize: 29 }} />
          </a>
        </div>
        <div className="px-3">
          <Divider />
        </div>
        <Modal.Body className="p-0 bg-white">
          <Box sx={{ width: "100%", typography: "body1", paddingX: 2 }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }} display="flex" alignItems="center" justifyContent="space-between">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList className="custom-tabs-div" onChange={handleTabChange}>
                    <Tab label="External Work" value="extwork" />
                    <Tab label="Other misc." value="othrMisc" />
                  </TabList>
                </Box>
                {/* <a className="btn-sm cursor" onClick={() => makeDataEditable()}>
                    <EditIcon sx={{ fontSize: 32 }} />
                  </a> */}
              </Box>
              <TabPanel value="extwork">
                {!extWorkViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCodeDescription} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4"></div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                        <input
                          type="text"
                          class="form-control border-radius-10 text-primary"
                          value={extWorkData.payer}
                          onChange={(e) => setExtWorkData({ ...extWorkData, payer: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.totalPrice} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button type="button" className="btn btn-light bg-primary text-white" onClick={updateExtWorkHeader}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <ReadOnlyField label="JOB CODE" value={extWorkData.jobCode} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="JOB CODE DESCRIPTION" value={extWorkData.jobCodeDescription} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PAYER" value={extWorkData.payer} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PRICE METHOD" value={extWorkData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="NET PRICE" value={extWorkData.totalPrice} className="col-md-4 col-sm-4" />
                  </div>
                )}
                <hr />

                <div className="">
                  <div className="bg-primary px-3 mb-3 border-radius-6">
                    <div className="row align-items-center">
                      <div className="col-10 mr-5">
                        <div className="d-flex align-items-center bg-primary w-100">
                          <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                            <h5 className="mr-2 mb-0 text-white">
                              <span>External Work</span>
                            </h5>
                          </div>
                          <SearchComponent
                            querySearchSelector={queryExtSearchSelector}
                            setQuerySearchSelector={setQueryExtSearchSelector}
                            clearFilteredData={clearFilteredData}
                            handleSnack={handleSnack}
                            searchAPI={getExtWork}
                            type={"extwork"}
                            searchClick={handleQuerySearchClick}
                            options={EXTWORK_SEARCH_Q_OPTIONS}
                            color={"white"}
                            buttonText="ADD ITEM"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <DataGrid sx={GRID_STYLE} rows={extWorkItems} columns={columnsExternal} pageSize={5} autoHeight rowsPerPageOptions={[5]} />
                </div>
              </TabPanel>
              <TabPanel value="othrMisc">
                {!miscViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCodeDescription} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">TYPE OF MISC.</label>
                        <Select
                          onChange={(e) => setMiscData({ ...miscData, type: e })}
                          // closeMenuOnSelect={false}
                          options={miscTypeList}
                          value={miscData.type}
                          isMulti
                          styles={FONT_STYLE_SELECT}
                        />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.totalPrice} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                        <input
                          type="text"
                          class="form-control border-radius-10 text-primary"
                          value={miscData.payer}
                          onChange={(e) => setMiscData({ ...miscData, payer: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button type="button" className="btn btn-light bg-primary text-white" onClick={updateMiscHeader}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-4">
                    <ReadOnlyField label="JOB CODE" value={miscData.jobCode} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="JOB CODE DESCRIPTION" value={miscData.jobCodeDescription} className="col-md-4 col-sm-4" />
                    <ReadOnlyField label="PAYER" value={miscData.payer} className="col-md-4 col-sm-4" />
                    <ReadOnlyField
                      label="TYPE OF MISC."
                      value={
                        <>
                          {miscData.type?.map((element) => (
                            <div>{element?.label}</div>
                          ))}
                        </>
                      }
                      className="col-md-4 col-sm-4"
                    />
                    <ReadOnlyField label="PRICE METHOD" value={miscData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                    <ReadOnlyField label="NET PRICE" value={miscData.totalPrice} className="col-md-4 col-sm-4" />
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Modal.Body>
      </Modal>

      {searchResultExtWorkOpen && (
        <Modal show={searchResultExtWorkOpen} onHide={handleSearchResExtClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="modal-header-border">
            <Modal.Title>Search Results</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 bg-white">
            <div className="card w-100 p-2">
              <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
                <DataGrid
                  sx={GRID_STYLE}
                  rows={masterData}
                  columns={columnsExtWorkSearch}
                  pageSize={5}
                  autoHeight
                  rowsPerPageOptions={[5]}
                  onRowClick={(e) => selectExtWorkItem(e.row)}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {extWorkItemOpen && (
        <MaintenanceBuilderOtherCostItemAddModal
          extWorkItemOpen={extWorkItemOpen}
          handleExtWorkItemClose={handleExtWorkItemClose}
          setExtWorkItemData={setExtWorkItemData}
          searchVenodrResults={searchVenodrResults}
          handleVendorSelect={handleVendorSelect}
          noOptionsVendor={noOptionsVendor}
          extWorkItemData={extWorkItemData}
          serviceEstimateData={operationData}
          handleVendorSearch={handleVendorSearch}
          dimensionList={dimensionList}
          addExtWorkItem={addExtWorkItem}
          activityIdList={activityIdList}
          unitOfMeasureOptions={unitOfMeasureOptions}
        />
      )}
    </>
  );
};

export default MaintenanceBuilderOtherCostModal;
