import React, { useState } from "react";

import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";

import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";

const AuthorizationDtlsModal = ({
  show,
  hideModal,
  rowData,
  authorizationStatusOptions,
  authorizationStatusRecords,
  setAuthorizationStatusRecords,
  setAuthorizationStatus,
  handleSnack,
}) => {
  const [recordData, setRecordData] = useState({
    ...rowData,
    authorizationStatus: authorizationStatusOptions.find((item) => item?.value === rowData?.authorizationStatus) || "",
  });

  // text value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  const handleSubmit = () => {
    const updatedRecords = authorizationStatusRecords.map((item) =>
      item?.id === rowData?.id ? { ...recordData, authorizationStatus: recordData?.authorizationStatus?.value } : item
    );
    handleSnack("success", `Authorization Details Updated Successfully`);
    setAuthorizationStatus(recordData?.authorizationStatus?.value);
    setAuthorizationStatusRecords(updatedRecords);
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg" centered>
        <Modal.Body>
          <h4>Authorization Details</h4>
          <div className="card border px-3 py-2 mb-3">
            <div className="row input-fields mt-2">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">REQUEST TYPE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="requestType"
                    value={recordData.requestType}
                    onChange={handleInputTextChange}
                    placeholder="Request Type"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZATION STATUS</label>
                  <Select
                    onChange={(e) => setRecordData({ ...recordData, authorizationStatus: e })}
                    options={authorizationStatusOptions}
                    value={recordData?.authorizationStatus}
                    styles={FONT_STYLE_SELECT}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZATION CODE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="authorizationCode"
                    value={recordData.authorizationCode}
                    onChange={handleInputTextChange}
                    placeholder="Authorization Code"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZED BY</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="authorizedBy"
                    value={recordData?.authorizedBy}
                    onChange={handleInputTextChange}
                    placeholder="Authorized By"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">AUTHORIZED ON</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        closeOnSelect
                        value={recordData?.authorizedOn}
                        onChange={(e) => setRecordData({ ...recordData, authorizedOn: e })}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">APPROVAL/REJECTION NOTES</label>
                  <textarea
                    className="form-control border-radius-10 text-primary"
                    name="acceptRejectMessage"
                    rows={3}
                    value={recordData?.acceptRejectMessage}
                    onChange={handleInputTextChange}
                    placeholder="Approval/Rejection Notes"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-3" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary" onClick={hideModal}>
              Close
            </button>
            <button className="btn bg-primary text-white ml-2" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthorizationDtlsModal;
