import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { Box, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";
import { GRID_STYLE } from "../CONSTANTS";
import { fetchPartlistFromOperation } from "services/repairBuilderServices";

const initialRows = [
    { id: 1, part: "Bendix DB1782-EURO +", supplier: "CarCare", qty: 2, listPrice: 10, net: "$10.23" },
    { id: 2, part: "Titan Sintopoid SAE 75W-85", supplier: "Wix", qty: 5, listPrice: 9, net: "$9.11" },
    { id: 3, part: "DEV.DOT WWE 4516-12", supplier: "SWF", qty: 2, listPrice: 8, net: "$8.93" },
    { id: 4, part: "Sintopoid 12DAW", supplier: "Drive", qty: 1, listPrice: 19, net: "$19.20" },
    { id: 5, part: "Titan SAE_75W-85", supplier: "Shall", qty: 3, listPrice: 33, net: "$99.35" },
    { id: 6, part: "Bendix DB1782", supplier: "DEV.CAR", qty: 4, listPrice: 30, net: "$60.10" },
    { id: 7, part: "Bord - EURO +", supplier: "SWF", qty: 1, listPrice: 25.2, net: "$25.20" },
];

const ShopRepairOrderPartsModal = ({ show, hideModal, recordId, rowData, handleSnack }) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (rowData) {
            getOrderParts(rowData);
        }
    }, [rowData]);

    // get order parts
    const getOrderParts = async (rowData) => {
        setLoading(true);
        const partsRecord = [];
        for (let operation of rowData["operations"]) {
            const res = await fetchPartlistFromOperation(operation?.id);
            if (res) {
                partsRecord.push(...res);
            }
        }
        const allSpareParts = partsRecord.flatMap((record) => record.spareparts);
        setRows(allSpareParts);
        setLoading(false);
    };

    // Qty Change
    const handleQtyChange = (id, delta) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id
                    ? { ...row, quantity: Math.max(0, row.quantity + delta) } // Ensure qty doesn't go below 0
                    : row
            )
        );
    };

    const columns = [
        {
            field: "partNumber",
            headerName: "PART",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "custom-cell",
        },
        {
            field: "branchPlant",
            headerName: "SUPPLIER",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "custom-cell",
        },
        {
            field: "quantity",
            headerName: "QTY",
            flex: 0.5,
            headerClassName: "custom-header",
            cellClassName: "custom-cell",
            renderCell: (params) => (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton size="small" onClick={() => handleQtyChange(params.row.id, -1)} sx={{ backgroundColor: "rgba(255,255,255,0.2)" }}>
                        <RemoveIcon />
                    </IconButton>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {params.row.quantity}
                    </Typography>
                    <IconButton size="small" onClick={() => handleQtyChange(params.row.id, 1)} sx={{ backgroundColor: "rgba(255,255,255,0.2)" }}>
                        <AddIcon />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "unitPrice",
            headerName: "NET",
            flex: 0.5,
            headerClassName: "custom-header",
            cellClassName: "custom-cell",
            renderCell: ({ row }) => `$${(row?.unitPrice || 0).toFixed(2)}`,
        },
        {
            field: "totalPrice",
            headerName: "NET",
            flex: 0.5,
            headerClassName: "custom-header",
            cellClassName: "custom-cell",
            renderCell: ({ row }) => `$${((row?.quantity || 0) * (row?.unitPrice || 0)).toFixed(2)}`,
        },
    ];

    // order Parts
    const handleOrderParts = () => {
        handleSnack("success", "Parts Ordered successfully and send to ERP.");
        hideModal();
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    <h4>Added Parts</h4>
                    <div className="card border mb-2">
                        <DataGrid
                            loading={loading}
                            sx={GRID_STYLE}
                            rows={rows}
                            columns={columns}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[10, 15, 20, 30]}
                            disableSelectionOnClick
                        />
                    </div>
                    <div className="row px-3" style={{ justifyContent: "right" }}>
                        <button className="btn bg-primary text-white" onClick={handleOrderParts}>
                            Order Parts
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShopRepairOrderPartsModal;
