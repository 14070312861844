import React, { useState } from "react";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import LoadingProgress from "pages/Repair/components/Loader";
import { Divider } from "@mui/material";

const LocationTypeOptions = [
  { label: "Service Workshop", value: "serviceworkshop" },
  { label: "Service Centre", value: "servicecenter" },
  { label: "Workshop & Service centre", value: "workshopservicecentre" },
];

const ServiceGroupOptions = [
  { label: "Engineers", value: "Engineers" },
  { label: "Admin", value: "Admin" },
  { label: "Coordinators", value: "Coordinators" },
];

const EmployeeSearchModal = ({ show, hideModal, handleSnack, selectedLocationRow, locationRecords, setLocationRecords }) => {
  const [newLocation, setNewLocation] = useState({
    locationName: "",
    address: "",
    locationType: "",
    serviceGroup: "",
    totalEmployees: 0,
  });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false);

  // Handle input change
  const handleInputChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setNewLocation((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  // Handle form submission
  const handleSubmit = () => {
    const _locationRecords = locationRecords.map((item) =>
      item?.id === selectedLocationRow?.id
        ? {
            ...item,
            totalEmployees: item["employees"].length + 1,
            employees: [...item["employees"], { ...newLocation, id: `E0${item["employees"].length + 1}` }],
          }
        : item
    );
    setLocationRecords(_locationRecords);
    setNewLocation({ locationName: "", address: "", locationType: "", serviceGroup: "", totalEmployees: 15 });
    hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Search & Employee</h4>
          </div>
          <Divider />
          <div className="row input-fields mt-2">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Location Name</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="locationName"
                  placeholder="Location Name"
                  value={newLocation?.locationName}
                  onChange={(e) => setNewLocation({ ...newLocation, locationName: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Location Type</label>
                <Select
                  className="text-primary"
                  name="locationType"
                  value={LocationTypeOptions.find((option) => option.value === newLocation.locationType)}
                  onChange={(option, actionMeta) => handleInputChange(option, actionMeta)}
                  options={LocationTypeOptions}
                  styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Service Group</label>
                <Select
                  className="text-primary"
                  name="serviceGroup"
                  value={ServiceGroupOptions.find((option) => option.value === newLocation.ServiceGroupOptions)}
                  onChange={(option, actionMeta) => handleInputChange(option, actionMeta)}
                  options={ServiceGroupOptions}
                  styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Total Employee</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="totalEmployees"
                  placeholder="Total Employee"
                  value={newLocation.totalEmployees}
                  disabled
                  onChange={(e) => setNewLocation({ ...newLocation, totalEmployees: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Address</label>
                <textarea
                  name="address"
                  cols="20"
                  rows="1"
                  placeholder="Address"
                  className="form-control border-radius-10 text-primary"
                  value={newLocation.address}
                  onChange={(e) => setNewLocation({ ...newLocation, address: e.target.value })}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Employee ID</label>
                <input type="text" className="form-control border-radius-10 text-primary" name="employeeid" placeholder="Employee Id" />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">Employee Name</label>
                <input type="text" className="form-control border-radius-10 text-primary" name="employeeName" placeholder="Employee Name" />
              </div>
            </div>
          </div>
          <div className="row px-4 mt-4" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn bg-primary text-white" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmployeeSearchModal;
