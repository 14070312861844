import React, { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { TextField } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Moment from "react-moment";

import { isEmpty } from "pages/Common/textUtilities";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE } from "pages/Common/constants";
import { SETTLEMENT_VALUE_MASTER_URL } from "services/CONSTANTS";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { customerSearch, machineSearch } from "services/searchServices";
import SolutionQuotePayerGridTable from "pages/components/SolutionQuotePayerGridTable";

const priceTypeOptions = [{ label: "Settled", value: "SETTLED" }];

const ClaimProcessSattlement = (props) => {
  const {
    handleSnack,
    handleBack,
    settlementValueId,
    setSettlementValueId,
    claimOrderId,
    handleViewAndAddNotes,
    claimSettlementRecord,
    setClaimSettlementRecord,
    claimStatus,
    handleUpdateClaimOrder,
    claimValueRecordData,
    setClaimStatus,
    setSettlementCount,
    handleShowClaimErrorsModal,
  } = props;

  const [addPayerData, setAddPayerData] = useState([]);
  const [quoteIdIs, setQuoteIdIs] = useState(0);
  const [viewOnly, setViewOnly] = useState(false);
  const [isNewCreated, setIsNewCreated] = useState(false);

  useEffect(() => {
    if (settlementValueId && !isNewCreated) {
      setViewOnly(true);
      callGetApi(`${SETTLEMENT_VALUE_MASTER_URL}/${settlementValueId}`, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          const _type = priceTypeOptions.find((obj) => obj.value === responseData.type);
          setClaimSettlementRecord({
            ...responseData,
            type: _type || priceTypeOptions[0],
          });
        }
      });
    }
  }, [settlementValueId, isNewCreated]);

  // get price data in decimal
  const getFlotingValue = (value) => {
    if (value) {
      let priceValue = parseFloat(Number(value));
      const priceWitDecimal = priceValue.toFixed(2);
      return priceWitDecimal;
    }
    return 0;
  };

  // input field value chaange
  const handleInputFiledChange = (e) => {
    const { name, value } = e.target;
    setClaimSettlementRecord({ ...claimSettlementRecord, [name]: value });
    setSettlementCount((pre) => pre + 1);
  };

  // save settlement record
  const handleAddUpdateSettlement = () => {
    const rUrl = SETTLEMENT_VALUE_MASTER_URL;
    const rObj = {
      ...claimSettlementRecord,
      type: claimSettlementRecord.type?.value || priceTypeOptions[0]?.value,
      totalAmountAllowed: getFlotingValue(
        parseFloat(Number(claimSettlementRecord.totalPartsAllowed || 0)) +
          parseFloat(Number(claimSettlementRecord.totalLaborAllowed || 0)) +
          parseFloat(Number(claimSettlementRecord.travelAllowed || 0)) +
          parseFloat(Number(claimSettlementRecord.miscAllowed || 0)) +
          parseFloat(Number(claimSettlementRecord?.productPrice || 0))
      ),
    };
    if (settlementValueId) {
      callPutApi(null, `${rUrl}/${settlementValueId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          handleUpdateClaimOrder({
            claimOrderStatus: "SETTLED",
            settlementValueId: responseData.settlementValueId,
          });
          setClaimStatus({ label: "Settled", value: "SETTLED" });
          setViewOnly(true);
          handleSnack("success", "Settlement updated successfully.");
          handleShowClaimErrorsModal();
        }
      });
    } else {
      callPostApi(null, `${rUrl}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setIsNewCreated(true);
          setSettlementValueId(responseData.settlementValueId);
          setClaimStatus({ label: "Settled", value: "SETTLED" });
          handleUpdateClaimOrder({ claimOrderStatus: "SETTLED", settlementValueId: responseData.settlementValueId });
          setViewOnly(true);
          handleShowClaimErrorsModal();
          handleSnack("success", "Settlement saved successfully.");
        }
      });
    }
  };
  // create ERP Order >> Claim Tab
  const handleCreateERPOrder = () => {
    if (claimOrderId) {
      handleSnack("success", `${claimOrderId} has been transmitted to your ERP system`);
    } else {
      handleSnack("info", `Create claim order first.`);
    }
  };

  return (
    <>
      {/* <div className={`row d-flex justify-content-between align-items-center py-2 ${claimStatus?.value === "ARCHIVED" ? "mx-2" : ""}`}>
        <h4 className="mx-3">Settlement</h4>
        <div className="d-flex">
          {viewOnly && claimStatus?.value !== "ARCHIVED" && (
            <button className="btn btn-light bg-primary text-white" onClick={() => setViewOnly(false)}>
              Edit
            </button>
          )}
          <button className="btn border-primary mx-3" onClick={handleBack}>
            <ArrowBackIcon /> Back
          </button>
        </div>
      </div> */}
      <div
        className={`card border px-3 py-2 ${claimStatus?.value === "ARCHIVED" ? "mx-3" : ""}`}
        style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
      >
        {viewOnly || claimStatus?.value === "ARCHIVED" ? (
          <>
            <div className="row mt-2">
              <ReadOnlyField label="TYPE" value={claimSettlementRecord.type?.label} className="col-md-3 col-sm-3" />
              <ReadOnlyField
                label="TOTAL AMOUNT ALLOWED"
                value={getFlotingValue(claimSettlementRecord.totalAmountAllowed)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="TOTAL HOURS ALLOWED"
                value={getFlotingValue(claimSettlementRecord.totalHoursAllowed)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="SETTLEMENT NUMBER"
                value={getFlotingValue(claimSettlementRecord.settlementValueId)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="SETTLEMENT DATE"
                value={<Moment format="DD/MM/YYYY">{claimSettlementRecord.settlementDate}</Moment>}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField label="TOTAL INSURANCE" value={getFlotingValue(claimSettlementRecord.totalInsurance)} className="col-md-3 col-sm-3" />
              <ReadOnlyField
                label="SERVICE DEDUCATION"
                value={getFlotingValue(claimSettlementRecord.serviceDeduction)}
                className="col-md-3 col-sm-3"
              />
            </div>
          </>
        ) : (
          <>
            <div className="row input-fields">
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TYPE</label>
                  <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    value={claimSettlementRecord.type?.label}
                    name={"type"}
                    disabled
                    // onChange={handleInputFiledChange}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL AMOUNT ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={getFlotingValue(claimSettlementRecord.totalAmountAllowed)}
                      name={"totalAmountAllowed"}
                      onChange={handleInputFiledChange}
                      disabled
                    />
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL HOURS ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={claimSettlementRecord.totalHoursAllowed}
                      name={"totalHoursAllowed"}
                      onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">Hr</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Hours Claimed {getFlotingValue(claimValueRecordData?.totalHoursClaimed || 0)} Hr</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SETTLEMENT NUMBER</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    disabled
                    value={claimSettlementRecord.settlementValueId}
                    name={"settlementValueId"}
                    onChange={handleInputFiledChange}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SETTLEMENT DATE</label>
                  <div className="align-items-center date-box">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat="dd/MM/yyyy"
                        className="form-controldate border-radius-10"
                        // maxDate={new Date()}
                        closeOnSelect
                        value={claimSettlementRecord.settlementDate}
                        onChange={(e) => setClaimSettlementRecord({ ...claimSettlementRecord, settlementDate: e })}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL INSURANCE</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    value={claimSettlementRecord.totalInsurance}
                    name={"totalInsurance"}
                    onChange={handleInputFiledChange}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">SERVICE DEDUCATION</label>
                  <input
                    type="number"
                    className="form-control border-radius-10 text-primary"
                    value={claimSettlementRecord.serviceDeduction}
                    name={"serviceDeduction"}
                    onChange={handleInputFiledChange}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <h5 className="mb-2 mt-2">Expense Approval</h5>
        <hr className="mt-0" />
        {viewOnly ? (
          <>
            <div className="row mt-2">
              <ReadOnlyField
                label="TOTAL PARTS ALLOWED"
                value={getFlotingValue(claimSettlementRecord.totalPartsAllowed)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="TOTAL LABOUR ALLOWED"
                value={getFlotingValue(claimSettlementRecord.totalLaborAllowed)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField
                label="VEHICLE KM ALLOWED"
                value={getFlotingValue(claimSettlementRecord.vehicleKMAllowed)}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField label="TRAVEL ALLOWED" value={getFlotingValue(claimSettlementRecord.travelAllowed)} className="col-md-3 col-sm-3" />
              <ReadOnlyField label="MISC. ALLOWED" value={getFlotingValue(claimSettlementRecord.miscAllowed)} className="col-md-3 col-sm-3" />
              <ReadOnlyField
                label="PROFUCT PRICE"
                value={getFlotingValue(claimSettlementRecord?.productPrice) || "0.00"}
                className="col-md-3 col-sm-3"
              />
              <ReadOnlyField label="TAX (GST 10%)" value={getFlotingValue(claimSettlementRecord.tax)} className="col-md-3 col-sm-3" />
            </div>
          </>
        ) : (
          <>
            <div className="row input-fields mt-2">
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL PARTS ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={claimSettlementRecord.totalPartsAllowed}
                      name={"totalPartsAllowed"}
                      onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Parts Claimed ${getFlotingValue(claimValueRecordData?.totalPartsClaimed || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TOTAL LABOUR ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={claimSettlementRecord.totalLaborAllowed}
                      name={"totalLaborAllowed"}
                      onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Total Labour Claimed ${getFlotingValue(claimValueRecordData?.totalLaborAmountClaimed || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">VEHICLE KM ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control border-radius-10 text-primary"
                      value={claimSettlementRecord.vehicleKMAllowed}
                      name={"vehicleKMAllowed"}
                      onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">Hr</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Vehicle Claimed ${getFlotingValue(claimValueRecordData?.vehicleKMClaimed || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">TRAVEL ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control border-radius-10 text-primary"
                      value={claimSettlementRecord.travelAllowed}
                      name={"travelAllowed"}
                      onChange={handleInputFiledChange}
                    />{" "}
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Travel Claimed ${getFlotingValue(claimValueRecordData?.travelClaimed || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500">MISC. ALLOWED</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control border-radius-10 text-primary"
                      value={claimSettlementRecord.miscAllowed}
                      name={"miscAllowed"}
                      onChange={handleInputFiledChange}
                    />{" "}
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Misc. Claimed ${getFlotingValue(claimValueRecordData?.miscClaimed || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">PRODUCT PRICE</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={claimSettlementRecord?.productPrice}
                      name={"productPrice"}
                      onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                    <span className="settlement-price-dot mx-2"></span>{" "}
                    <span>Product Price ${getFlotingValue(claimValueRecordData?.productPrice || 0)}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500 text-uppercase">TAX (GST 10%)</label>
                  <div className="d-flex form-control-date" style={{ overflow: "hidden" }}>
                    <input
                      type="number"
                      className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                      value={getFlotingValue(claimSettlementRecord.tax)}
                      name={"productPrice"}
                      disabled
                      // onChange={handleInputFiledChange}
                    />
                    <span className="hours-div text-primary">AUD</span>
                  </div>
                  {/* <div className="price-info-settlement d-flex align-items-center px-1 py-1">
                                        <span className="settlement-price-dot mx-2"></span>{" "}
                                        <span>Product Price ${getFlotingValue(claimValueRecordData?.productPrice || 0)}</span>
                                    </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`mt-3 mb-2 ${claimStatus?.value === "ARCHIVED" ? "mx-3" : ""}`}>
        <SolutionQuotePayerGridTable handleSnack={handleSnack} dataRows={addPayerData} quoteId={quoteIdIs} claimOrderId={claimOrderId} />
      </div>
      <div className={`row mb-2 ${claimStatus?.value === "ARCHIVED" ? "mx-3" : "mx-0"}`} style={{ justifyContent: "right" }}>
        <button type="button" className="btn bg-primary text-white mx-0" onClick={handleAddUpdateSettlement}>
          Validate Claim
        </button>
        <button type="button" className="btn border-primary mx-2" onClick={handleCreateERPOrder}>
          Create ERP Order
        </button>
        {/* <button
                    type="button"
                    className="btn btn-ligh save the parts hours datat bg-primary text-white mx-0"
                    onClick={() => handleViewAndAddNotes("addNotes")}
                >
                    View & Add Notes
                </button> */}
      </div>
    </>
  );
};

export default ClaimProcessSattlement;
