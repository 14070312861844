import React, { useCallback, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";

import { Modal } from "react-bootstrap";
import $ from "jquery";

import { GRID_STYLE } from "pages/Common/constants";
import { API_SUCCESS } from "services/ResponseCode";
import { getLabourPrice, sparePartSearch } from "services/searchServices";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import SearchComponent from "pages/components/SearchComponent";
import { SPAREPART_SEARCH_Q_OPTIONS } from "pages/Repair/CONSTANTS";

const HoursOnJobDetails = ({
  show,
  hideModal,
  jobHoursRecords = [],
  setJobHoursRecords,
  claimRelateHERecordData,
  setClaimRelateHERecordData,
  setClaimValueRecordData,
  handleInputFieldChange,
  viewOnlyTab,
  claimStatus,
  setSettlementCount,
  setClaimValueCount,
  handleSnack,
}) => {
  const [rowModesModel, setRowModesModel] = useState({});

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  const [masterData, setMasterData] = useState([]);
  const [searchResultOpen, setSearchResultOpen] = useState(false);

  // clear filter
  const clearFilteredData = () => {
    setMasterData([]);
  };

  // add new row
  const handleAddNewRow = () => {
    const id = jobHoursRecords.length + 1;
    const _jobHoursRecords = [...jobHoursRecords];
    _jobHoursRecords.push({
      id: id,
      chargeCode: "",
      // extraApprovedTime: "",
      totalHours: "",
      unitOfMeasurement: "HOURS",
      unitPrice: "",
      totalPrice: 0,
      comment: "",
      labourType: "",
    });
    setJobHoursRecords(_jobHoursRecords);
  };

  const getUnitPrice = async (row) => {
    if (row.chargeCode && row.jobCode) {
      const res = await getLabourPrice(`chargeCode:${row.chargeCode} %26%26 serviceType:${row.jobCode}`);
      if (res.status === API_SUCCESS) {
        const responseData = res.data;
        return { unitPrice: responseData[0].price, laborType: responseData[0].laborType, serviceType: responseData[0].serviceType };
      }
      return { unitPrice: 0, laborType: "", serviceType: "" };
    }
    return { unitPrice: 0, laborType: "", serviceType: "" };
  };

  const getTotalTime = (row) => {
    if (row.unitOfMeasurement === "MINUTES") {
      return row.totalHours ? (row.totalHours / 60).toFixed(2) : 0;
    }
    return row.totalHours || 0;
  };

  const jobHoursColumns = [
    {
      field: "chargeCode",
      headerName: "Charge Code",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        // { value: "MINIMUM_SERVICE_CHARGE", label: "Minimum Service Charge" },
        // { value: "ADDITIONAL_SERVICE_CHARGE", label: "Additional service charge" },
        // { label: "Minimum service call", value: "MINIMUM_SERVICE_CALL" },
        // { label: "Additional items", value: "ADDITIONAL_ITEMS" },
        { label: "Minimum Service Charge", value: "MINIMUM_SERVICE_CALL" },
        { label: "Additional Service Charge", value: "ADDITIONAL_ITEMS" },
        { label: "Non-Scheduled additional Charges", value: "NON_SCHEDULED_ADDITIONAL_ITEMS" },
        { label: "Extra Approved Charges", value: "EXTRA_APPROVED_TIME" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "jobCode",
      headerName: "Job Code",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Ground - clean", value: "GROUND_CLEAN" },
        { label: "Adjust", value: "ADJUST" },
        { label: "Inspect", value: "INSPECT" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "totalHours",
      headerName: "Total Time",
      flex: 1,
      editable: true,
    },
    {
      field: "unitOfMeasurement",
      headerName: "Unit of Measure",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Hours", value: "HOURS" },
        { label: "Minutes", value: "MINUTES" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      flex: 1,
      // editable: true,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      // editable: true,
    },
    {
      field: "comment",
      headerName: "Comments",
      flex: 1,
      editable: true,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      //   width: 150,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditOutlinedIcon />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDeleteClick(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // row edit stop
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // change table row edit|delete mode change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // click on row edit button
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // click on row save button
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // click on row delete button
  const handleDeleteClick = (id) => () => {
    // if (relatedPartsId) {
    //     callDeleteApi(null, `${RELATED_PARTS_MASTER_URL}/${relatedPartsId}`, (response) => {
    //         if (response.status === API_SUCCESS) {
    //             setJobHoursRecords(jobHoursRecords.filter((row) => row.relatedPartsId !== relatedPartsId));
    //         }
    //     });
    // } else {
    // }
    setJobHoursRecords(jobHoursRecords.filter((row) => row.id !== id));
  };

  // click on row cancel button
  const handleCancelClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    const editedRow = jobHoursRecords.find((row) => row.id === id);
    if (editedRow.isNew) {
      setJobHoursRecords(jobHoursRecords.filter((row) => row.id !== id));
    }
  };

  // row update process >> put api call
  const processRowUpdate = useCallback(
    async (newRow, oldRow) =>
      new Promise(async (resolve, reject) => {
        const updatedRow = { ...newRow, isNew: true };

        const _unitPrice = await getUnitPrice(newRow);
        const _totalPrice = getTotalTime(newRow) * _unitPrice?.unitPrice;
        setJobHoursRecords(
          jobHoursRecords.map((row) =>
            row.id === updatedRow.id
              ? {
                  ...updatedRow,
                  isNew: undefined,
                  unitPrice: _unitPrice?.unitPrice,
                  totalPrice: _totalPrice,
                  labourType: _unitPrice?.laborType,
                  serviceType: _unitPrice?.serviceType,
                }
              : row
          )
        );
        const _jobHoursRecords = jobHoursRecords.map((row) =>
          row.id === updatedRow.id
            ? {
                ...updatedRow,
                isNew: undefined,
                unitPrice: _unitPrice?.unitPrice,
                totalPrice: _totalPrice,
                labourType: _unitPrice?.laborType,
                serviceType: _unitPrice?.serviceType,
              }
            : row
        );

        setClaimValueRecordData((prev) => ({
          ...prev,
          totalHoursClaimed: _jobHoursRecords.reduceRight((total, item) => {
            const timeInHours = item.unitOfMeasurement === "MINUTES" ? Number(item.totalHours) / 60 : Number(item.totalHours);
            return total + timeInHours;
          }, 0),
        }));

        setSettlementCount((pre) => pre + 1);
        setClaimValueCount((pre) => pre + 1);

        resolve({ ...updatedRow, isNew: undefined, unitPrice: _unitPrice?.unitPrice, totalPrice: _totalPrice });
      }),
    [jobHoursRecords]
  );

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await sparePartSearch(searchStr);
        // console.log("search Query Result :", res);
        setMasterData(res);
        setSearchResultOpen(true);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  return (
    <>
      <div className="card border px-3 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center w-100">
            <SearchComponent
              querySearchSelector={querySearchSelector}
              setQuerySearchSelector={setQuerySearchSelector}
              clearFilteredData={clearFilteredData}
              handleSnack={handleSnack}
              searchAPI={sparePartSearch}
              searchClick={handleQuerySearchClick}
              options={SPAREPART_SEARCH_Q_OPTIONS}
              background={"white"}
              type=""
              buttonText="SEARCH"
            />
          </div>
          <div>
            <button type="button" className="btn border-primary text-primary" onClick={handleAddNewRow}>
              + Add New
            </button>
          </div>
        </div>
        <DataGrid
          sx={GRID_STYLE}
          getRowId={(row) => row.id}
          rows={jobHoursRecords}
          autoHeight
          columns={jobHoursColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          experimentalFeatures={{ newEditingApi: true }}
          onProcessRowUpdateError={(error) => console.log(error)}
          processRowUpdate={processRowUpdate}
        />
      </div>
    </>
  );
};

export default HoursOnJobDetails;
