import React, { useContext, useEffect, useState } from "react";

import deleteIcon from "../../../assets/icons/svg/delete.svg";
import copyIcon from "../../../assets/icons/svg/Copy.svg";

import DateRangeIcon from "@mui/icons-material/DateRange";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import Select from "react-select";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";

import {
  claimAssessmentRequestObj,
  claimOrderRequestObj,
  claimRelatedHERequestObj,
  claimRequestObj,
  claimRequestTypeOptions,
  claimSettlementRequestObj,
  claimStatusOptions,
  claimValueRequestObj,
  claimVersionOptions,
  evaluationRequestObj,
  failureCodeOptions,
  filesRecords,
  questionsOptions,
  rmaResonOptions,
  rmaTypeOptions,
  settledClaimOptions,
  underWarrantyOptions,
  warrantyTypeOptions,
  assessmentClaimCategory,
  assessmentCategoryType,
  claimObject,
  pvPanelObject,
  pvInverterObject,
  pvOptimiserObject,
  pvBatteryObject,
  pvOtherObject,
  warrantyBasisOptions,
} from "../warrantyManagementConstants";

import { isEmpty } from "pages/Common/textUtilities";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { customerSearch, equipmentSearch, machineSearch } from "services/searchServices";
import SearchBox from "pages/Common/SearchBox";

import ClaimOrderDetails from "./ClaimOrderDetails";
import AssessmentDetails from "./AssessmentDetails";
import EvaluationDetails from "./EvaluationDetails";
import {
  CLAIM_MASTER_URL,
  CLAIM_ORDER_MASTER_URL,
  CLAIM_VALUE_MASTER_URL,
  EVALUATION_PARTS_MASTER_URL,
  RELATED_HOURS_EXPENSES_MASTER_URL,
  RELATED_LABOUR_DETAILS_MASTER_URL,
  RELATED_PARTS_MASTER_URL,
  SETTLEMENT_VALUE_MASTER_URL,
  WARRANTY_ASSESSMENT_MASTER_URL,
  WARRANTY_EVALUATION_GET_ALL_QUESTIONS_ANSWER_URL,
  WARRANTY_EVALUATION_MASTER_URL,
  WARRANTY_MASTER_URL,
  YEARLY_WARRANTY_MASTER_URL,
} from "services/CONSTANTS";
import RelatedPartsAndExpenses from "./RelatedPartsAndExpenses";
import AdjustClaimValue from "./AdjustClaimValue";
import Settlement from "./Settlement";
import ClaimAddNotes from "../claimMaster/ClaimAddNotes";
import ClaimPartCreateModal from "../claimMaster/ClaimPartCreateModal";
import ValidateClaimModal from "../claimMaster/ValidateClaimModal";
import Swal from "sweetalert2";
import HoursOnJobModal from "./HoursOnJobModal";
import ClaimErrorsModal from "./ClaimErrorsModal";
import ClaimFilesModal from "./ClaimFilesModal";
import UploadFilesModal from "../UploadFilesModal";
import ClaimImageViewModal from "./ClaimImageViewModal";
import ClaimReturnRequester from "../claimMaster/ClaimReturnRequester";
import HoursOnJobModalOld from "./HoursOnJobModalOld";

const climentOpt = [{ label: "Partner", value: "CHANNEL_PARTNER" }];

const coverageTypeOptions = [
  { label: "All Covered", value: "CT_04" },
  { label: "Parts & Labour", value: "CT_01" },
  { label: "Only Parts", value: "CT_02" },
  { label: "Part & Labour & Misc.", value: "CT_03" },
];

const priceTypeOptions = [
  { label: "Claimed", value: "CLAIMED" },
  { label: "Settled", value: "SETTLED" },
];

const defautFiles = [
  {
    id: 1,
    fileName: "certificate.png",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "CERTIFICATE",
    category: "CERTIFICATE",
  },
  {
    id: 2,
    fileName: "faultyPannel.png",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "FAULTY",
    category: "FAULTY",
  },
];
const ClaimOrderRequestModal = (props) => {
  const {
    show,
    hideModal,
    handleSnack,
    claimRecordId,
    setClaimRecordId,
    claimOrderId,
    setClaimOrderId,
    evaluationId,
    setEvaluationId,
    assesstmentId,
    setAssesstmentId,
    openPartCreateModal,
    handleShowPartCreateModal,
    handleShowReturnRequetrModal,
    showJobHoursModal,
    handleShowOrderJobHoursModal,
    showClaimErrorModal,
    handleShowClaimErrorsModal,
    showFilesModal,
    handleShowFilesModal,
    showClaimFileUploadModal,
    handleShowClaimFileUploadModal,
    showViewFileModal,
    handleShowViewFileModal,
    openReturnRequsterModal,
    partsRecords = [],
    countryOptions = [],
    byAuthCode = true,
    equipmentRowData = null,
    isEquipment = false,
    contWithNo = false,
    claimentSource = "PARTNER",
    requesterDetails = "",
  } = props;

  const [openActionMenu, setOpenActionMenu] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [openAttachmentMenu, setOpenAttachmentMenu] = useState(false);
  const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] = useState(null);
  const [activeTopTab, setActiveTopTab] = useState("");
  const [requestTab, setRequestTab] = useState("returnDetails");
  const [returnDetailsTab, setReturnDetailsTab] = useState("general");

  const [auth, setAuth] = useState({ customerId: "", customerName: "" });

  const [claimRecordData, setClaimRecordData] = useState({
    ...claimRequestObj,
  });
  const [claimOrderData, setClaimOrderData] = useState({
    ...claimOrderRequestObj,
    claiment: claimentSource || "PARTNER",
  });
  const [failureCode, setFailureCode] = useState(failureCodeOptions[0]);
  const [claimOrderVersion, setClaimOrderVersion] = useState(claimVersionOptions[0]);
  const [viewOnlyTab, setViewOnlyTab] = useState({
    generalViewOnly: false,
    estViewOnly: false,
    custViewOnly: false,
    machineViewOnly: false,
    assesstViewOnly: false,
    evaluViewOnly: false,
    claimViewOnly: false,
    relatedPartsExpensesViewOnly: false,
  });
  const [assesstTabViewOnly, setAssesstTabViewOnly] = useState(false);
  const [evaluTabViewOnly, setEvaluTabViewOnly] = useState(false);

  const [settledClaimType, setSettledClaimType] = useState(settledClaimOptions[0]);
  const [claimStatus, setClaimStatus] = useState(claimStatusOptions[0]);
  const [claimType, setClaimType] = useState(claimRequestTypeOptions[0]);
  const [claimNumber, setClaimNumber] = useState(null);

  const [warrantyRequestClaimData, setWarrantyRequestClaimData] = useState(null);
  const [claimApprover, setClaimApprover] = useState(null);

  const [settlementCount, setSettlementCount] = useState(0);

  const [jobHoursRecords, setJobHoursRecords] = useState([]);
  const [claimFilesRecords, setClaimFilesRecords] = useState([...defautFiles]);
  const [claimFileType, setClaimFileType] = useState("");
  const [isNewCreated, setIsNewCreated] = useState(false);

  const [generalData, setGeneralData] = useState({
    claimRequestDate: new Date(),
    estimationNo: "",
    description: "",
    reference: "",
    validity: null,
    version: "",
    warrantyClaimStatus: "",
  });

  const [estimationData, setEstimationData] = useState({
    preparedBy: "user1",
    approvedBy: "user1",
    preparedOn: new Date(),
    revisedBy: "user1",
    revisedOn: new Date(),
    salesOffice: null,
  });

  const [customerData, setCustomerData] = useState({
    customerID: "",
    customerName: "",
    contactEmail: "",
    contactPhone: "",
  });

  const [machineData, setMachineData] = useState({
    make: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    equipmentNumber: "",
    family: "",
  });

  const [yearlyWarrantyData, setYearlyWarrantyData] = useState(null);

  const [assesstementData, setAssesstementData] = useState({
    ...claimAssessmentRequestObj,
    warrantyId: 0,
    warrantyTitle: "STD-18",
    claimId: 0,
  });
  const [assessClaimCategory, setAssessClaimCategory] = useState("");
  const [assessCategoryType, setAssessCategoryType] = useState("");

  // ! PV Panel Request Object

  const [pvPanelReqObj, setPvPanelReqObj] = useState({ ...pvPanelObject });
  const [pvInversterReqObj, setPvInversterReqObj] = useState({ ...pvInverterObject });
  const [pvOptimiserReqObj, setPvOptimiserReqObj] = useState({ ...pvOptimiserObject });
  const [pvBatteryReqObj, setPvBatteryReqObj] = useState({ ...pvBatteryObject });
  const [pvOtherReqObj, setPvOtherReqObj] = useState({ ...pvOtherObject });

  // ! PV Panel Request Object

  const [warrantyId, setWarrantyId] = useState(null);
  const [warrantyTitle, setWarrantyTitle] = useState("");
  const [authorizationCode, setAuthorizationCode] = useState(null);
  const [replacement, setReplacement] = useState(false);
  const [reference, setReference] = useState("");

  const [warrantyData, setWarrantyData] = useState({
    warrantyId: 0,
    warrantyTitle: "",
  });

  const [evaluationTabValue, setEvaluationTabValue] = useState("evaluationDetails");
  const [evaluationDetailsData, setEvaluationDetailsData] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
  });
  const [evaluatedByData, setEvaluatedByData] = useState({
    ...evaluationRequestObj,
  });
  const [partsFailedRecord, setPartsFailedRecord] = useState([]);
  const [partsCausalRecord, setPartsCausalRecord] = useState([]);

  const [relatedHEId, setRelatedHEId] = useState(null);
  const [relatedPartsId, setRelatedPartsId] = useState(null);
  const [coverageTypeValue, setCoverageTypeValue] = useState(coverageTypeOptions[0]);
  const [relatedPartsRecords, setRelatedPartsRecords] = useState([]);

  const [claimValueId, setClaimValueId] = useState(null);
  const [settlementValueId, setSettlementValueId] = useState(null);

  const [claimRelateHERecordData, setClaimRelateHERecordData] = useState({
    ...claimRelatedHERequestObj,
    type: claimentSource === "PARTNER" ? climentOpt[0] : claimentSource === "OEM" ? { label: "OEM", value: "OEM" } : climentOpt[0],
    name: auth?.customerName ? auth?.customerName : "",
    claimOrderId: claimOrderId,
    coverageType: coverageTypeValue,
  });

  const [claimValueRecordData, setClaimValueRecordData] = useState({
    ...claimValueRequestObj,
    coverageType: coverageTypeOptions[0],
    type: priceTypeOptions[0],
    costPerHours: 70,
  });
  const [claimValueCount, setClaimValueCount] = useState(0);
  const [refreshSettlemtData, setRefreshSettlemtData] = useState(0);

  const [claimSettlementRecord, setClaimSettlementRecord] = useState({
    ...claimSettlementRequestObj,
    type: priceTypeOptions[0],
    claimOrderId: claimOrderId,
  });

  const [reportTypeCausal, setReportTypeCausal] = useState(false);
  const [openValidateClaimErrModal, setOpenValidateClaimErrModal] = useState(false);

  const [warrantyReturnId, setWarrantyReturnId] = useState(null);

  // Helper functions for calculating values
  const calculateTotalAmountAllowed = (records) => {
    const { totalPartsAllowed = 0, totalLaborAllowed = 0, vehicleKMAllowed = 0, travelAllowed = 0, miscAllowed = 0, productPrice = 0 } = records;

    const totalAmount = totalPartsAllowed + totalLaborAllowed + vehicleKMAllowed + travelAllowed + miscAllowed + productPrice;
    const tax = totalAmount * 0.1;

    return { totalAmount, tax };
  };

  useEffect(() => {
    const _totalPartsAllowed = claimSettlementRecord?.totalPartsAllowed || claimValueRecordData?.totalPartsClaimed || 0;
    const _totalLaborAllowed = claimSettlementRecord?.totalLaborAllowed || claimValueRecordData?.totalLaborAmountClaimed || 0;
    const _vehicleKMAllowed = claimSettlementRecord?.vehicleKMAllowed || claimValueRecordData?.vehicleKMClaimed || 0;
    const _travelAllowed = claimSettlementRecord?.travelAllowed || claimValueRecordData?.travelClaimed || 0;
    const _miscAllowed = claimSettlementRecord?.miscAllowed || claimValueRecordData?.miscClaimed || 0;
    const _productPrice = claimSettlementRecord?.productPrice || claimValueRecordData?.productPrice || 0;
    const _totlaHoursAllowed = claimSettlementRecord?.totalHoursAllowed || claimValueRecordData?.totalHoursClaimed || 0;
    const _totalAmountAllowed =
      parseInt(Number(_totalPartsAllowed || 0)) +
      parseInt(Number(_totalLaborAllowed || 0)) +
      parseInt(Number(_vehicleKMAllowed || 0)) +
      parseInt(Number(_travelAllowed || 0)) +
      parseInt(Number(_miscAllowed || 0)) +
      parseInt(Number(_productPrice || 0));

    setClaimSettlementRecord({
      ...claimSettlementRecord,
      totalPartsAllowed: _totalPartsAllowed || 0,
      totalLaborAllowed: _totalLaborAllowed || 0,
      vehicleKMAllowed: _vehicleKMAllowed || 0,
      travelAllowed: _travelAllowed || 0,
      miscAllowed: _miscAllowed || 0,
      productPrice: _productPrice || 0,
      totalAmountAllowed: _totalAmountAllowed + (_totalAmountAllowed * 10) / 100 || 0,
      tax: (_totalAmountAllowed * 10) / 100,
      claimOrderId: claimOrderId,
      totalHoursAllowed: _totlaHoursAllowed || 0,
    });
  }, [settlementCount, claimOrderId, refreshSettlemtData]);

  useEffect(() => {
    const _jobHours = jobHoursRecords.reduceRight((total, item) => {
      const timeInHours = item.unitOfMeasurement === "MINUTES" ? Number(item.totalHours) / 60 : Number(item.totalHours);
      return total + timeInHours;
    }, 0);

    // const _totalHoursClaimed = _jobHours;
    setClaimRelateHERecordData({ ...claimRelateHERecordData, jobHours: Number(_jobHours) });
  }, [jobHoursRecords]);

  useEffect(() => {
    const _totalPartsClaimed = relatedPartsRecords.reduceRight((total, item) => {
      if (item.taskType !== "PRODUCT") {
        return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
        // return total + item.unitPrice * item.quantity;
      } else {
        return total;
      }
    }, 0);

    console.log("_totalPartsClaimed :::: ", _totalPartsClaimed, relatedPartsRecords);

    const _productPrice = relatedPartsRecords.reduceRight((total, item) => {
      if (item.taskType === "PRODUCT") {
        return total + item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity;
        // return total + item.unitPrice * item.quantity;
      } else {
        return total;
      }
    }, 0);

    // const _totalLaborAmountClaimed = Number(Number(claimRelateHERecordData.jobHours) * Number(70));
    const _totalLaborAmountClaimed = Number(Number(claimValueRecordData?.totalHoursClaimed) * Number(70));
    const _totalAmountClaimed =
      claimValueRecordData.coverageType?.value === "CT_02"
        ? parseFloat(Number(_totalPartsClaimed || 0)) + parseFloat(Number(claimValueRecordData?.productPrice || 0))
        : claimValueRecordData.coverageType?.value === "CT_01"
        ? parseFloat(Number(_totalPartsClaimed || 0)) +
          parseFloat(Number(_totalLaborAmountClaimed || 0)) +
          parseFloat(Number(claimValueRecordData?.productPrice || 0))
        : claimValueRecordData.coverageType?.value === "CT_03"
        ? parseFloat(Number(_totalPartsClaimed || 0)) +
          parseFloat(Number(_totalLaborAmountClaimed || 0)) +
          parseFloat(Number(claimValueRecordData.miscClaimed || 0)) +
          parseFloat(Number(claimValueRecordData?.productPrice || 0))
        : claimValueRecordData.coverageType?.value === "CT_04"
        ? parseFloat(Number(_totalPartsClaimed || 0)) +
          parseFloat(Number(_totalLaborAmountClaimed || 0)) +
          parseFloat(Number(claimValueRecordData.miscClaimed || 0)) +
          parseFloat(Number(claimValueRecordData.travelClaimed || 0)) +
          parseFloat(Number(claimValueRecordData?.productPrice || 0))
        : 0;

    setClaimValueRecordData({
      ...claimValueRecordData,
      costPerHours: 70,
      claimOrderId: claimOrderId,
      totalPartsClaimed: Number(_totalPartsClaimed),
      productPrice: Number(_productPrice),
      totalLaborAmountClaimed: _totalLaborAmountClaimed,
      totalAmountClaimed: Number(Number(_totalAmountClaimed) + Number((_totalAmountClaimed * 10) / 100)),
      tax: Number(_totalAmountClaimed * 10) / 100,
      totalHoursClaimed: Number(claimValueRecordData?.totalHoursClaimed) || Number(claimRelateHERecordData.jobHours) || 0,
      // totalHoursClaimed: Number(claimRelateHERecordData.jobHours) || 0,
    });
    setRefreshSettlemtData((pre) => pre + 1);
  }, [relatedPartsRecords.length, claimRelateHERecordData.jobHours, claimValueCount, claimOrderId]);

  useEffect(() => {
    if (claimStatus?.value === "ARCHIVED") {
      setViewOnlyTab({
        ...viewOnlyTab,
        generalViewOnly: true,
        estViewOnly: true,
        custViewOnly: true,
        machineViewOnly: true,
        assesstViewOnly: true,
        evaluViewOnly: true,
        claimViewOnly: true,
        relatedPartsExpensesViewOnly: true,
      });
      setAssesstTabViewOnly(true);
      setEvaluTabViewOnly(true);
    }
  }, [claimStatus]);

  useEffect(() => {
    if (contWithNo) {
      setMachineData({
        make: equipmentRowData?.maker,
        model: equipmentRowData?.model,
        serialNo: equipmentRowData?.makerSerialNumber,
        smu: equipmentRowData?.smu,
        fleetNo: equipmentRowData?.fleetNo,
        equipmentNumber: equipmentRowData?.equipmentNumber,
        family: equipmentRowData?.market,
      });
      setWarrantyId(equipmentRowData?.warrantyId);
      setWarrantyTitle(equipmentRowData?.warrantyTitle);

      // get the warranty details
      if (equipmentRowData?.warrantyId) {
        getWarrantyDetails(equipmentRowData?.warrantyId);
      }

      setCustomerData({
        // customerID: equipmentRowData?.customerId,
        customerID: equipmentRowData?.currentClient,
        customerName: equipmentRowData?.customer,
        contactEmail: equipmentRowData?.emailId,
        contactPhone: equipmentRowData?.contact,
      });
    }
    if (claimOrderId && !byAuthCode && !isNewCreated) {
      const rUrl = `${CLAIM_ORDER_MASTER_URL}/${claimOrderId}`;
      callGetApi(rUrl, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;

          // get the warranty details
          if (responseData?.warrantyId) {
            getWarrantyDetails(responseData?.warrantyId);
          }

          if (responseData.relatedPartsIds && responseData.relatedPartsIds.length !== 0) {
            getRelatedPartDetails(responseData.relatedPartsIds);
          }

          if (responseData.claimOrderNumber) {
            setClaimNumber(responseData.claimOrderNumber);
          }

          setAuth({ customerId: responseData.partnerCode, customerName: responseData.partnerName });

          // claim verion value set
          const _claimOrderVersion = claimVersionOptions.find((obj) => obj.value === responseData.version);
          setClaimOrderVersion(_claimOrderVersion || claimVersionOptions[0]);

          // rma type value set
          const _rmaType = rmaTypeOptions.find((obj) => obj.value === responseData.rmaType);

          // rma type value set
          const _rmaReason = rmaResonOptions.find((obj) => obj.value === responseData.rmaReason);

          // claim status
          const _claimStatus = claimStatusOptions.find((obj) => obj.value === responseData?.claimOrderStatus);
          setClaimStatus(_claimStatus);

          // claim type
          const _claimType = claimRequestTypeOptions.find((obj) => obj.value === responseData?.claimType);
          setClaimType(_claimType);

          setClaimOrderData({
            ...responseData,
            claimType: _claimType || "",
            claimOrderStatus: _claimStatus || "",
            rmaType: _rmaType || "",
            rmaReason: _rmaReason || "",
            // rmaNumber: responseData.rmaNumber,
          });

          setGeneralData({
            ...generalData,
            // estimationNo: claimRecordDetail?.equipmentNumber,
            description: responseData.description,
            reference: responseData.reference,
            warrantyClaimStatus: _claimStatus,
            claimRequestDate: responseData?.claimRequestDate,
          });

          setReference(responseData.reference);

          setEstimationData({
            ...estimationData,
            preparedBy: responseData.preparedBy,
            preparedOn: responseData.preparedOn,
            revisedBy: responseData.revisedBy,
            revisedOn: responseData.revisedOn,
          });

          setCustomerData({
            customerID: responseData.customerNumber,
            customerName: responseData.customerName,
            contactEmail: responseData.emailId,
            contactPhone: responseData.contactNumber,
          });

          setMachineData({
            make: responseData.make,
            model: responseData.model,
            serialNo: responseData.serialNumber,
            smu: responseData.smu,
            fleetNo: responseData.unitNumber,
            equipmentNumber: responseData.equipmentNumber,
            family: responseData.family,
          });

          setWarrantyData({
            warrantyId: responseData.warrantyId,
            warrantyTitle: responseData.warrantyTitle,
          });
          setWarrantyId(responseData.warrantyId);
          setWarrantyTitle(responseData.warrantyTitle);

          setAuthorizationCode(responseData.authorizationCode);
          setReplacement(responseData.replacement);

          setRelatedHEId(responseData.relatedHEId);
          if (responseData.relatedHEId) {
            getRelatedHEDetails(responseData.relatedHEId, responseData?.claiment);
          } else {
            setClaimRelateHERecordData({
              ...claimRelatedHERequestObj,
              type:
                responseData?.claiment === "PARTNER"
                  ? climentOpt[0]
                  : responseData?.claiment === "OEM"
                  ? { label: "OEM", value: "OEM" }
                  : climentOpt[0],
              name: responseData?.claiment === "PARTNER" ? responseData?.partnerName : "",
              claimOrderId: claimOrderId,
              coverageType: coverageTypeValue,
            });
          }

          setClaimValueId(responseData.claimValueId);
          if (responseData.claimValueId) {
            getClaimValueDetails(responseData.claimValueId);
          }

          if (responseData?.settlementValueId) {
            getSettlementValueDetails(responseData?.settlementValueId);
          }
          setSettlementValueId(responseData?.settlementValueId);

          setViewOnlyTab({
            ...viewOnlyTab,
            generalViewOnly: true,
            estViewOnly: true,
            custViewOnly: true,
            machineViewOnly: true,
            assesstViewOnly: true,
            evaluViewOnly: true,
            claimViewOnly: true,
            relatedPartsExpensesViewOnly: responseData.relatedHEId ? true : false,
          });

          if (responseData.claimId) {
            getClaimDetails(responseData.claimId, responseData);
          }
        }
      });
    }
    if (claimRecordId && byAuthCode && !isNewCreated) {
      getClaimDetails(claimRecordId);
    }
  }, [claimOrderId, isNewCreated]);

  // get the Warranty details
  const getWarrantyDetails = (warrantyId) => {
    if (warrantyId) {
      callGetApi(`${WARRANTY_MASTER_URL}/${warrantyId}`, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          if (responseData?.yearlyWarrantyIds && responseData?.yearlyWarrantyIds.length > 0) {
            getYearlyWarrantyDetails(responseData?.yearlyWarrantyIds[0]);
          }
        } else {
        }
      });
    }
  };

  //
  const getYearlyWarrantyDetails = (yearlyWarrantyId) => {
    if (yearlyWarrantyId) {
      callGetApi(`${YEARLY_WARRANTY_MASTER_URL}/${yearlyWarrantyId}`, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _basis = warrantyBasisOptions.find((item) => item?.value === responseData?.basis);
          setYearlyWarrantyData({ ...responseData, year: { label: "Year 1", value: "1" }, basis: _basis || "" });
        } else {
        }
      });
    }
  };

  // get Related Hours and Expenses details
  const getRelatedHEDetails = (relatedHEId, claiment) => {
    callGetApi(`${RELATED_HOURS_EXPENSES_MASTER_URL}/${relatedHEId}`, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        let codeOptions = [
          { label: "User-defined", value: "USER_DRIVED" },
          { label: "Auto-derived", value: "AUTO_DERIVED" },
        ];

        // set code value
        const _code = codeOptions.find((obj) => obj.value === responseData.code);

        // set alternate code value
        const _coverageType = coverageTypeOptions.find((obj) => obj.value === responseData.coverageType);

        const _jobHours = jobHoursRecords.reduceRight((total, item) => {
          const timeInHours = item.unitOfMeasurement === "MINUTES" ? Number(item.totalHours) / 60 : Number(item.totalHours);
          return total + timeInHours;
        }, 0);

        setClaimRelateHERecordData({
          ...responseData,
          type: claiment === "PARTNER" ? climentOpt[0] : claiment === "OEM" ? { label: "OEM", value: "OEM" } : climentOpt[0],
          code: _code || "",
          coverageType: _coverageType || "",
          jobHours: jobHoursRecords.length !== 0 ? Number(_jobHours).toFixed(2) : responseData.jobHours || 0,
        });
        if (responseData.relatedLabors) {
          handleGetRelateLabours(responseData.relatedLabors);
        }
      }
    });
  };

  const handleGetRelateLabours = (relatedLabors = []) => {
    if (relatedLabors.length > 0) {
      const rUrl = `${RELATED_LABOUR_DETAILS_MASTER_URL}/by-ids?` + relatedLabors.map((id) => `ids=${id}`).join("&");
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            const _responseData = responseData.map((item, i) => ({ ...item, id: i + 1 }));
            setJobHoursRecords(_responseData);
          } else {
            setJobHoursRecords([]);
          }
        },
        (error) => {
          setJobHoursRecords([]);
        }
      );
    }
  };

  // get claim value Detial
  const getClaimValueDetails = (claimValueId) => {
    callGetApi(`${CLAIM_VALUE_MASTER_URL}/${claimValueId}`, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        // type
        const _type = priceTypeOptions.find((obj) => obj.value === responseData.type);

        // coverage type
        const _coverageType = coverageTypeOptions.find((obj) => obj.value === responseData.coverageType);

        setClaimValueRecordData({
          ...responseData,
          type: _type || priceTypeOptions[0],
          coverageType: _coverageType || coverageTypeOptions[0],
          costPerHours: 70,
          totalLaborAmountClaimed: Number(Number(responseData.totalHoursClaimed) * 70),
        });

        setClaimValueCount((pre) => pre + 1);
      }
    });
  };

  // get Settlement value details
  const getSettlementValueDetails = (settlementValueId) => {
    callGetApi(`${SETTLEMENT_VALUE_MASTER_URL}/${settlementValueId}`, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        const _type = priceTypeOptions.find((obj) => obj.value === responseData.type);
        setClaimSettlementRecord({
          ...responseData,
          type: _type || priceTypeOptions[0],
        });
      }
    });
  };

  // get claim details
  const getClaimDetails = (claimId, claimOrderRes = null) => {
    const rUrlClaim = `${CLAIM_MASTER_URL}/${claimId}`;
    callGetApi(rUrlClaim, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        if (isEquipment && byAuthCode) {
          setMachineData({
            make: equipmentRowData?.maker,
            model: equipmentRowData?.model,
            serialNo: equipmentRowData?.makerSerialNumber,
            smu: equipmentRowData?.sensorId,
            fleetNo: equipmentRowData?.stockNumber,
            equipmentNumber: equipmentRowData?.equipmentNumber,
            family: equipmentRowData?.market,
          });
          getEquipmentDetails(equipmentRowData?.makerSerialNumber);
        }
        if (!isEquipment && byAuthCode) {
          setMachineData({
            make: responseData?.make,
            model: responseData.modelNumber,
            serialNo: responseData?.serialNumber,
            smu: responseData?.smu,
            fleetNo: responseData.fleetNo,
            equipmentNumber: responseData?.equipmentNumber,
            family: responseData?.family,
          });

          getEquipmentDetails(responseData?.serialNumber);
        }
        if (byAuthCode) {
          // claim status
          const _claimStatus = claimStatusOptions.find((obj) => obj.value === responseData.claimStatus);
          setClaimStatus(_claimStatus);

          // claim status
          const _claimType = claimRequestTypeOptions.find((obj) => obj.value === responseData.claimType);
          setClaimType(_claimType);

          setWarrantyId(responseData?.warrantyId);
          setWarrantyTitle(responseData?.warrantyTitle || "Standard");

          setAuthorizationCode(responseData.authorizationCode);
          setReplacement(responseData.replacement);

          setReference(responseData.reference);

          setCustomerData({
            customerID: responseData.customerNumber,
            customerName: responseData.customerName,
            contactEmail: responseData.emailId,
            contactPhone: responseData.contactNumber,
          });
        }
        if (responseData.assessmentId) {
          getAssessmentDetails(responseData.assessmentId);
          setAssesstmentId(responseData.assessmentId);
          setAssesstTabViewOnly(true);
        }
        if (responseData.evaluationId) {
          setEvaluationId(responseData.evaluationId);
          getEvaluationDetails(responseData.evaluationId);
          setEvaluTabViewOnly(true);
        }
        setClaimApprover(responseData.claimApprover);
        setWarrantyRequestClaimData({ ...responseData });
      }
    });
  };

  // get assessment details
  const getAssessmentDetails = (assessmentId) => {
    callGetApi(`${WARRANTY_ASSESSMENT_MASTER_URL}/${assessmentId}`, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        // machine under warranty value set
        const _machineUnderWarranty = underWarrantyOptions.find((obj) => obj.value === responseData.machineUnderWarranty);

        // assessment type value set
        const _assessmentType = warrantyTypeOptions.find((obj) => obj.value === responseData.assessmentType);

        setAssesstementData({
          ...responseData,
          machineUnderWarranty: _machineUnderWarranty,
          assessmentType: _assessmentType,
        });
      }
    });
  };

  // get evaluation details
  const getEvaluationDetails = (id) => {
    const rUrl = `${WARRANTY_EVALUATION_MASTER_URL}/${id}`;
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;

        getEvaluationQuestionsAnswer(responseData.evaluationId);

        let _evaluationPartIds = [];
        if (responseData.evaluationPartIds && responseData.evaluationPartIds.length !== 0) {
          _evaluationPartIds = responseData.evaluationPartIds;
          getEvaluationPartsIdsDetails(_evaluationPartIds);
        }

        // setEvaluationDetailsData({
        //     question1: { label: "Yes", label: "YES" },
        //     question2: { label: "Yes", label: "YES" },
        //     question3: { label: "Yes", label: "YES" },
        //     question4: { label: "Yes", label: "YES" },
        //     question5: "Heating Element failure",
        //     question6: "Heating element replacement",
        // });
        setEvaluatedByData({ ...responseData, evaluationPartIds: _evaluationPartIds });
      }
    });
  };

  // get evaluation question answer
  const getEvaluationQuestionsAnswer = (evaluationId) => {
    const rUrl = `${WARRANTY_EVALUATION_GET_ALL_QUESTIONS_ANSWER_URL}/${evaluationId}`;
    callGetApi(rUrl, (response) => {
      if (response.status === 200) {
        const responseData = response.data;
        const _question1 = questionsOptions.find((obj) => obj.value === responseData[0].answer);
        const _question2 = questionsOptions.find((obj) => obj.value === responseData[1].answer);
        const _question3 = questionsOptions.find((obj) => obj.value === responseData[2].answer);
        const _question4 = questionsOptions.find((obj) => obj.value === responseData[3].answer);
        const _question5 = responseData[4].answer;
        const _question6 = responseData[5].answer;

        setEvaluationDetailsData({
          question1: _question1 || "",
          question2: _question2 || "",
          question3: _question3 || "",
          question4: _question4 || "",
          question5: _question5 || "",
          question6: _question6 || "",
        });
      }
    });
  };

  // all evaluation parts details main function
  const getEvaluationPartsIdsDetails = async (evaluationPartIds) => {
    let failureParts = [];
    let causalParts = [];
    for (let row of evaluationPartIds) {
      const result = await getEvalautionPartIdDatail(row);
      if (result.success) {
        if (result.data?.partsType === "FAILURE_PARTS") {
          failureParts.push(result.data);
        } else if (result.data?.partsType === "CAUSAL_PARTS") causalParts.push(result.data);
      }
    }
    setPartsFailedRecord(failureParts);
    setPartsCausalRecord(causalParts);
  };

  // get evluation parts id details
  const getEvalautionPartIdDatail = (id) => {
    return new Promise((resolve) => {
      callGetApi(
        `${EVALUATION_PARTS_MASTER_URL}/${id}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            resolve({
              success: true,
              data: { ...response["data"], supplier: "SP0023" },
            });
          } else {
            resolve({ success: false });
          }
        },
        (error) => {
          resolve({ success: false });
        }
      );
    });
  };

  // get related Part details
  const getRelatedPartDetails = (partIds = []) => {
    let rUrl = `${RELATED_PARTS_MASTER_URL}/by-ids?` + partIds.map((id) => `ids=${id}`).join("&");
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        let result = responseData.map((item) => ({ ...item, supplier: "SP0023", suppliedBy: "NO" }));
        setRelatedPartsRecords([...result]);
      }
    });
  };

  // get replaced equipment Number
  const getEquipmentDetails = async (serialNumber) => {
    const res = await equipmentSearch(`makerSerialNumber:${serialNumber}&pageNumber=0&pageSize=10`);
    if (res.length > 0) {
      if (res[0].replacedEquipmentNumber) {
        getSelectEquReplacementCode(res[0].replacedEquipmentNumber);
      }
    }
  };

  // get replacement equipment number
  const getSelectEquReplacementCode = async (replacedEquipmentNumber) => {
    // const res = await equipmentSearch(`equipmentNumber:${replacedEquipmentNumber}`);
    const res = await equipmentSearch(`replacedEquipmentNumber:${replacedEquipmentNumber}&pageNumber=0&pageSize=10`);
    if (res.length > 0) {
      setClaimOrderData({
        ...claimOrderData,
        componentCode: res[0].equipmentNumber,
        // componentCode: res[0].replacedEquipmentNumber,
        componentSerialNumber: res[0].makerSerialNumber,
      });
    }
  };

  // Individual Claim record input field value change
  const handleClaimRecordDataChange = (e) => {
    const { name, value } = e.target;
    setClaimRecordData({ ...claimRecordData, [name]: value });
  };

  // claim tab input text change
  const handleClaimTabInputTextChange = (e) => {
    const { name, value } = e.target;
    setClaimOrderData({ ...claimOrderData, [name]: value });
  };

  // Individual claim record Select & date field value change
  const handleClaimRecordSelectDataChange = (e, keyName) => {
    // const { name, value } = e.;
    setClaimRecordData({ ...claimRecordData, [keyName]: e });
  };

  // claim tab select & Date value change
  const handleClaimTabSelectValueChange = (e, keyName) => {
    setClaimOrderData({ ...claimOrderData, [keyName]: e });
  };

  // open Action Menu
  const handleOpenActionMenu = (e) => {
    setActionMenuAnchorEl(e.currentTarget);
    setOpenActionMenu(true);
  };

  // open attachment Menu
  const handleOpenAttachmentMenu = (e) => {
    setAttachmentMenuAnchorEl(e.currentTarget);
    setOpenAttachmentMenu(true);
  };

  // close convert to menu items list view
  const handleCloseActionMenu = () => setOpenActionMenu(false);

  // close convert to menu items list view
  const handleCloseAttachmentMenu = () => setOpenAttachmentMenu(false);

  // make editable able to Return Details tab data
  const handleMakeTabEditable = () => {
    if (claimStatus?.value === "ARCHIVED") {
      return;
    }
    if (isEmpty(activeTopTab)) {
      let viewOnlyTabName = "";
      if (requestTab === "returnDetails") {
        if (returnDetailsTab === "general") {
          viewOnlyTabName = "generalViewOnly";
        } else if (returnDetailsTab === "estimation") {
          viewOnlyTabName = "estViewOnly";
        } else if (returnDetailsTab === "warranty") {
          viewOnlyTabName = "warrantyViewOnly";
        } else if (returnDetailsTab === "customer") {
          viewOnlyTabName = "custViewOnly";
        } else if (returnDetailsTab === "machine") {
          viewOnlyTabName = "machineViewOnly";
        }
      } else if (requestTab === "assesstement") {
        viewOnlyTabName = "assesstViewOnly";
        setAssesstTabViewOnly(false);
      } else if (requestTab === "evaluation") {
        viewOnlyTabName = "evaluViewOnly";
        setEvaluTabViewOnly(false);
      } else if (requestTab === "claim") {
        viewOnlyTabName = "claimViewOnly";
      } else if (requestTab === "relatedPartsAndExpenses") {
        viewOnlyTabName = "relatedPartsExpensesViewOnly";
      }
      setViewOnlyTab({ ...viewOnlyTab, [viewOnlyTabName]: false });
    }
  };

  // change upper tabs value & make Active to them
  const handleChangeAndActiveTopTab = (tabName) => {
    if (claimOrderData?.claiment === "OEM" && tabName === "settlement") {
      return;
    }
    // if (
    //     (tabName === "settlement" && !(claimStatus?.value === "CLAIM_REJECTED")) ||
    //     claimStatus?.value === "SETTLED" ||
    //     claimStatus?.value === "CONTESTED"
    // ) {
    //     return;
    // }
    setActiveTopTab(tabName);
  };

  // change claim request active tab
  const handleTabChange = (e, value) => {
    setRequestTab(value);
  };

  // back to request tab
  const handleBackToRequestTab = () => {
    setActiveTopTab("");
  };

  // go to evaluation return scrren data
  const handleEvaluationPartsReturn = (row) => {
    handleShowReturnRequetrModal(row);
  };

  // add|update Claim Order
  const handleAddUpdateClaimOrder = (e) => {
    if (returnDetailsTab === "general" && viewOnlyTab?.generalViewOnly) {
      setReturnDetailsTab("estimation");
      return;
    } else if (returnDetailsTab === "estimation" && viewOnlyTab?.estViewOnly) {
      setReturnDetailsTab("customer");
      return;
    } else if (returnDetailsTab === "customer" && viewOnlyTab?.custViewOnly) {
      setReturnDetailsTab("machine");
      return;
    } else if (returnDetailsTab === "machine" && viewOnlyTab?.machineViewOnly) {
      setRequestTab("assesstement");
      return;
    } else if (returnDetailsTab === "warranty" && viewOnlyTab?.warrantyViewOnly) {
      setReturnDetailsTab("warranty");
      return;
    }

    const _claimNumber = Math.floor(Math.random() * 9000) + 1000;

    const reqObj = {
      ...claimOrderData,
      customerNumber: customerData.customerID,
      customerName: customerData.customerName || "",
      emailId: customerData.contactEmail || "",
      contactNumber: customerData.contactPhone || "",
      make: machineData.make || "",
      model: machineData.model || "",
      serialNumber: machineData.serialNo || "",
      smu: machineData.smu || "",
      unitNumber: machineData.fleetNo || "",
      preparedBy: estimationData.preparedBy || "",
      preparedOn: estimationData.preparedOn || new Date(),
      revisedBy: estimationData.revisedBy || "",
      revisedOn: estimationData.revisedOn || new Date(),
      // claimType: claimRecordDetail?.claimType || "EMPTY",
      description: generalData.description,
      claimOrderNumber: claimNumber || _claimNumber,
      reference: reference || "",
      // reference: generalData.reference || "",
      rmaType: claimOrderData.rmaType?.value || "",
      rmaReason: claimOrderData.rmaReason?.value || "",
      rmaNumber: claimOrderData.rmaNumber || "",
      equipmentNumber: machineData.equipmentNumber || "",
      version: claimOrderVersion?.value || "VERSION_1",
      replacement: replacement,
      // replacement: replacement || true,
      warrantyId: warrantyId || 0,
      warrantyTitle: warrantyTitle || "STANDARD",
      claimId: claimRecordId || 0,
      authorizationCode: authorizationCode || "",
      claimValueId: claimValueId || 0,
      settlementId: settlementValueId || 0,
      relatedHEId: relatedHEId || 0,
      claimType: claimType?.value || "STANDARD",
      claimOrderStatus: claimStatus?.value || "REGISTERED",
      // claiment: "PARTNER",
      partnerName: auth?.customerName || "",
      partnerCode: auth?.customerId || "",
      address: "",
      location: "",
    };

    if (contWithNo || !reqObj.authorizationCode) {
      delete reqObj.authorizationCode;
      delete reqObj.claimId;
    }

    if (claimOrderId) {
      callPutApi(null, `${CLAIM_ORDER_MASTER_URL}/${claimOrderId}`, reqObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setClaimNumber(responseData.claimOrderNumber);
          if (returnDetailsTab === "general") {
            setViewOnlyTab({ ...viewOnlyTab, generalViewOnly: true });
            setReturnDetailsTab("estimation");
          } else if (returnDetailsTab === "estimation") {
            setViewOnlyTab({ ...viewOnlyTab, estViewOnly: true });
            setReturnDetailsTab("customer");
          } else if (returnDetailsTab === "customer") {
            setViewOnlyTab({ ...viewOnlyTab, custViewOnly: true });
            setReturnDetailsTab("machine");
          } else if (returnDetailsTab === "machine") {
            setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: true });
            setRequestTab("assesstement");
          }
          handleSnack("success", "Claim Request Updated Sucessfully");
        }
      });
    } else {
      callPostApi(null, CLAIM_ORDER_MASTER_URL, reqObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setIsNewCreated(true);
          setClaimNumber(_claimNumber);
          if (returnDetailsTab === "general") {
            setViewOnlyTab({ ...viewOnlyTab, generalViewOnly: true });
            setReturnDetailsTab("estimation");
          } else if (returnDetailsTab === "estimation") {
            setViewOnlyTab({ ...viewOnlyTab, estViewOnly: true });
            setReturnDetailsTab("customer");
          } else if (returnDetailsTab === "customer") {
            setViewOnlyTab({ ...viewOnlyTab, custViewOnly: true });
            setReturnDetailsTab("machine");
          } else if (returnDetailsTab === "machine") {
            setViewOnlyTab({ ...viewOnlyTab, machineViewOnly: true });
            setRequestTab("assesstement");
          }
          setClaimOrderId(responseData["claimOrderId"]);
          // setClaimRecordData({
          //     ...claimRecordData,
          //     claimOrderId: responseData["claimOrderId"],
          // });
          // handleUpdateClaimOrder(
          //     "claimRequest",
          //     responseData["claimOrderId"]
          // );
          handleSnack("success", "Claim Request Created Successfully");
        } else {
          handleSnack("error", "Somthing Went wrong");
        }
      });
    }
  };

  // update claim (Warranty Request)
  const handleUpdateWarrantyRequestData = (type, valueId) => {
    const rUrl = CLAIM_MASTER_URL;
    let _claimOrderId = claimOrderId;
    let _assessmentId = assesstmentId;
    let _evaluationId = evaluationId;
    if (type === "claimRequest") {
      _claimOrderId = valueId;
    } else if (type === "assessment") {
      _assessmentId = valueId;
    } else if (type === "evaluation") {
      _evaluationId = valueId;
    }
    if (claimRecordId) {
      const rObj = {
        ...warrantyRequestClaimData,
        claimOrderId: _claimOrderId || 0,
        assessmentId: _assessmentId || 0,
        evaluationId: _evaluationId || 0,
      };

      if (!warrantyRequestClaimData?.authorizationCode) {
        delete warrantyRequestClaimData?.authorizationCode;
      }

      callPutApi(null, `${rUrl}/${claimRecordId}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setWarrantyRequestClaimData({
            ...warrantyRequestClaimData,
            claimOrderId: responseData.claimOrderId || 0,
            assessmentId: responseData.assessmentId || 0,
            evaluationId: responseData.evaluationId || 0,
          });
        }
      });
    } else {
      const newReqObj = {
        ...claimObject,
        claimNumber: claimNumber,
        modelNumber: machineData?.model,
        equipmentNumber: machineData?.equipmentNumber,
        serialNumber: machineData?.serialNo,
        componentCode: claimOrderData.componentCode,
        claimStatus: claimStatus?.value || "REGISTERED",
        claimType: "STANDARD",
        payer: "CUSTOMER",
        smu: machineData?.smu,
        fleetNo: machineData?.fleetNo,
        claimApprover: claimApprover,
        customerNumber: customerData?.customerID,
        customerName: customerData?.customerName,
        make: machineData?.make,
        family: machineData?.family,
        replacement: replacement,
        claimOrderId: _claimOrderId || 0,
        assessmentId: _assessmentId || 0,
        evaluationId: _evaluationId || 0,
      };
      callPostApi(null, `${rUrl}`, newReqObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setWarrantyRequestClaimData({
            ...responseData,
            claimOrderId: responseData.claimOrderId || 0,
            assessmentId: responseData.assessmentId || 0,
            evaluationId: responseData.evaluationId || 0,
          });
          setClaimRecordId(responseData.claimId);
        }
      });
    }
  };

  // update Claim Order
  const handleSaveClaimOrderChanges = (rObj = {}, tabClick = false) => {
    const _relatedPartsIds = [];
    if (relatedPartsRecords.length !== 0) {
      relatedPartsRecords.map((obj) => _relatedPartsIds.push(obj.relatedPartsId));
    }
    const reqObj = {
      ...claimOrderData,
      customerNumber: customerData.customerID,
      customerName: customerData.customerName || "",
      emailId: customerData.contactEmail || "",
      contactNumber: customerData.contactPhone || "",
      make: machineData.make || "",
      model: machineData.model || "",
      serialNumber: machineData.serialNo || "",
      smu: machineData.smu || "",
      unitNumber: machineData.fleetNo || "",
      preparedBy: estimationData.preparedBy || "",
      preparedOn: estimationData.preparedOn || new Date(),
      revisedBy: estimationData.revisedBy || "",
      revisedOn: estimationData.revisedOn || new Date(),
      // claimType: claimRecordDetail?.claimType || "EMPTY",
      description: generalData.description,
      claimOrderNumber: claimNumber,
      reference: reference || "",
      // reference: generalData.reference || "",
      rmaType: claimOrderData.rmaType?.value || "",
      rmaReason: claimOrderData.rmaReason?.value || "",
      rmaNumber: claimOrderData.rmaNumber || "",
      equipmentNumber: machineData.equipmentNumber || "",
      version: claimOrderVersion?.value || "VERSION_1",
      replacement: replacement,
      // replacement: replacement || true,
      warrantyId: warrantyId || 0,
      warrantyTitle: warrantyTitle || "STANDARD",
      claimId: claimRecordId || 0,
      authorizationCode: authorizationCode || "",
      claimValueId: claimValueId || 0,
      settlementId: settlementValueId || 0,
      relatedHEId: relatedHEId || 0,
      claimType: claimType?.value || "EMPTY",
      claimOrderStatus: claimStatus?.value || "REGISTERED",
      // claiment: "PARTNER",
      partnerName: auth?.customerName || "",
      partnerCode: auth?.customerId || "",
      address: "",
      location: "",
      relatedPartsIds: _relatedPartsIds,
      ...rObj,
    };

    if (!reqObj.authorizationCode) {
      delete reqObj.claimId;
      delete reqObj.authorizationCode;
    }

    if (claimOrderId) {
      callPutApi(null, `${CLAIM_ORDER_MASTER_URL}/${claimOrderId}`, reqObj, (response) => {
        if (response.status === API_SUCCESS) {
          if (tabClick) {
            setViewOnlyTab({
              ...viewOnlyTab,
              claimViewOnly: true,
            });
          }
          handleSnack("success", "Claim Request Updated Sucessfully");
        }
      });
    }
  };

  // delete claim order
  const handleDeleteClaimOrder = () => {
    if (claimOrderId) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this claim",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setClaimStatus({ label: "Archived", value: "ARCHIVED" });
          handleSaveClaimOrderChanges({
            claimOrderStatus: "ARCHIVED",
          });
        }
      });
    }
  };

  // copy claim
  const handleCopyClaim = () => {
    if (claimOrderId) {
      handleSnack("success", "claim is copied successfully");
    }
  };

  return (
    <>
      {!openValidateClaimErrModal && show && (
        <Modal show={show} onHide={hideModal} size="xl">
          <Modal.Body
            style={{
              backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}`,
            }}
          >
            <div className="card border my-2 px-3">
              <div className="row mt-2 py-3">
                <div className="col-md-7 col-sm-7 d-flex claim-requester-info">
                  <img src="../assets/images/member/2.jpg" alt="" />
                  <div className="mx-2">
                    <h2 className="mb-0">Ashok Mohanty</h2>
                    <h6>Warranty Analyst</h6>
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 d-flex">
                  <PeopleAltOutlinedIcon fontSize="large" />
                  <div className="mx-2">
                    <h5 className="mb-0">
                      <span>
                        {<Tooltip title="Customer Name">{customerData?.customerName}</Tooltip>}{" "}
                        {customerData?.customerName && customerData?.customerID && " - "}{" "}
                        {<Tooltip title="Customer Number">{customerData?.customerID}</Tooltip>}
                      </span>
                    </h5>
                    <h6>
                      <div className="d-flex flex-column">
                        {machineData?.model && !machineData?.serialNo ? (
                          <span className="">
                            {<Tooltip title="Model Number">{machineData?.model}</Tooltip>} {machineData?.model}
                          </span>
                        ) : !machineData?.model && machineData?.serialNo ? (
                          <span className="">{<Tooltip title="Serial Number">{machineData?.serialNo}</Tooltip>}</span>
                        ) : (
                          <span className="">
                            {<Tooltip title="Model Number">{machineData?.model}</Tooltip>} {machineData?.model && machineData?.serialNo && " - "}
                            {<Tooltip title="Serial Number">{machineData?.serialNo}</Tooltip>}
                          </span>
                        )}
                      </div>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="d-flex justify-content-center align-items-center">
                <h5 className="font-weight-600 mb-0">Warranty Claim {claimNumber && ` - ${claimNumber}`}</h5>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="ml-3">
                    <span class="claim-version-span">{claimOrderVersion?.label}</span>
                  </div>
                  <div className="ml-3">
                    <Select
                      value={claimStatus}
                      onChange={(e) => {
                        setClaimStatus(e);
                        handleSaveClaimOrderChanges({
                          claimOrderStatus: e?.value,
                        });
                      }}
                      className="custom-claimStatus-selectbtn"
                      options={claimStatusOptions}
                      isDisabled={claimStatus?.value === "SETTLED"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center px-3">
                <div>
                  {claimStatus?.value !== "SETTLED" && (
                    <span className={`px-3 py-1 mx-2 settlement-status`}>
                      {claimStatus?.value === "REGISTERED" ||
                      claimStatus?.value === "CLAIM_SUBMITTED" ||
                      claimStatus?.value === "CLAIM_ACKNOWLEDGED" ||
                      claimStatus?.value === "CLAIM_ACCEPTED" ||
                      claimStatus?.value === "CONTESTED"
                        ? "Settlement Pending"
                        : claimStatus?.value === "CLAIM_REJECTED"
                        ? "Claim Rejected"
                        : claimStatus?.value === "SETTLED"
                        ? "Settlement Received"
                        : claimStatus?.value === "ARCHIVED" || claimStatus?.value === "CLOSED"
                        ? "Settlement Closed"
                        : ""}
                    </span>
                  )}

                  {claimStatus?.value === "SETTLED" && (
                    <Select
                      value={settledClaimType}
                      onChange={(e) => setSettledClaimType(e)}
                      className="custom-claimStatus-selectbtn"
                      options={settledClaimOptions}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <a href="#" className="ml-3 font-size-14" title="Delete" onClick={handleDeleteClaimOrder}>
                    <Tooltip title="Delete">
                      <img src={deleteIcon}></img>
                    </Tooltip>
                  </a>
                  <a href={undefined} className="ml-3 font-size-14" title="Copy" onClick={handleCopyClaim}>
                    <Tooltip title="Copy">
                      <img src={copyIcon}></img>
                    </Tooltip>
                  </a>
                </div>
              </div>
            </div>
            <Divider className="my-2" />
            <div className="ligt-greey-bg py-3 pl-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  {claimStatus?.value !== "CLAIM_SUBMITTED" && (
                    <span className="mr-3 cursor" onClick={handleMakeTabEditable}>
                      <i className="fa fa-pencil font-size-12" aria-hidden="true"></i>
                      <span className="ml-2">Edit</span>
                    </span>
                  )}
                  {claimOrderId && (
                    <>
                      <span
                        className={`d-flex align-items-center cursor ${activeTopTab === "addNotes" ? "active-span" : ""}`}
                        onClick={() => handleChangeAndActiveTopTab("addNotes")}
                      >
                        <DescriptionOutlinedIcon className="font-size-24" />
                        <span className="ml-2">Add Notes</span>
                      </span>
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center px-0">
                  {/* <span
                    className={`mr-3 d-flex align-items-center border-radius-10 px-2 py-1 ${
                      claimOrderId
                        ? activeTopTab === "adjustClaimValue"
                          ? "cursor active-span claim-order-upper-tab-active"
                          : "cursor claim-order-upper-tab"
                        : "claim-order-upper-tab-disabled"
                      // : "claim-order-upper-tab-disabled"
                    }`}
                    onClick={() => handleChangeAndActiveTopTab("adjustClaimValue")}
                  >
                    <MonetizationOnOutlinedIcon className=" font-size-24" />
                    <span className="ml-2">Adjust Claim Value</span>
                  </span> */}
                  <span
                    className={`mr-3 d-flex align-items-center border-radius-10 px-2 py-1 ${
                      claimOrderData?.claiment !== "OEM"
                        ? claimOrderId
                          ? activeTopTab === "settlement"
                            ? "cursor active-span claim-order-upper-tab-active"
                            : "cursor claim-order-upper-tab"
                          : "claim-order-upper-tab-disabled"
                        : "claim-order-upper-tab-disabled"
                    }`}
                    onClick={() => handleChangeAndActiveTopTab("settlement")}
                  >
                    <SellOutlinedIcon className=" font-size-24" />
                    <span className="ml-2">Settlement</span>
                  </span>
                </div>
              </div>
            </div>
            <div className={`card border my-2 ${claimStatus?.value === "ARCHIVED" ? (activeTopTab !== "" ? "" : "") : "px-3"}`}>
              <Box
                className="mt-0"
                sx={{ width: "100%", typography: "body1", backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
              >
                {activeTopTab === "" && (
                  <TabContext value={requestTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList className="custom-tabs-div" onChange={handleTabChange}>
                        <Tab label="REQUEST DETAILS" value="returnDetails" />
                        <Tab label="ASSESSMENT " value="assesstement" />
                        <Tab label="EVALUATION " value="evaluation" />
                        <Tab label="CLAIM" value="claim" disabled={!claimOrderId} />
                        <Tab label="RELATED PARTS & EXPENSES" value="relatedPartsAndExpenses" disabled={!claimOrderId} />
                      </TabList>
                    </Box>
                    <TabPanel value="returnDetails" sx={{ paddingX: 1.4 }}>
                      <ClaimOrderDetails
                        claimOrderId={claimOrderId}
                        returnDetailsTab={returnDetailsTab}
                        setReturnDetailsTab={setReturnDetailsTab}
                        viewOnlyTab={viewOnlyTab}
                        generalData={generalData}
                        setGeneralData={setGeneralData}
                        reference={reference}
                        setReference={setReference}
                        estimationData={estimationData}
                        setEstimationData={setEstimationData}
                        customerData={customerData}
                        setCustomerData={setCustomerData}
                        machineData={machineData}
                        setMachineData={setMachineData}
                        yearlyWarrantyData={yearlyWarrantyData}
                        setYearlyWarrantyData={setYearlyWarrantyData}
                        warrantyBasisOptions={warrantyBasisOptions}
                        handleSnack={handleSnack}
                        claimStatus={claimStatus}
                        handleAddUpdateClaimOrder={handleAddUpdateClaimOrder}
                        warrantyId={warrantyId}
                        authorizationCode={authorizationCode}
                        getSelectEquReplacementCode={getSelectEquReplacementCode}
                      />
                    </TabPanel>
                    <TabPanel value="assesstement">
                      <AssessmentDetails
                        viewOnlyTab={viewOnlyTab}
                        setViewOnlyTab={setViewOnlyTab}
                        setRequestTab={setRequestTab}
                        handleSnack={handleSnack}
                        assesstementData={assesstementData}
                        setAssesstementData={setAssesstementData}
                        underWarrantyOptions={underWarrantyOptions}
                        warrantyTypeOptions={warrantyTypeOptions}
                        assessmentClaimCategory={assessmentClaimCategory}
                        assessmentCategoryType={assessmentCategoryType}
                        warrantyData={warrantyData}
                        assesstTabViewOnly={assesstTabViewOnly}
                        setAssesstTabViewOnly={setAssesstTabViewOnly}
                        assesstmentId={assesstmentId}
                        setAssesstmentId={setAssesstmentId}
                        claimRecordId={claimRecordId}
                        warrantyId={warrantyId}
                        warrantyTitle={warrantyTitle}
                        claimStatus={claimStatus}
                        handleUpdateWarrantyRequest={handleUpdateWarrantyRequestData}
                        assessClaimCategory={assessClaimCategory}
                        setAssessClaimCategory={setAssessClaimCategory}
                        assessCategoryType={assessCategoryType}
                        setAssessCategoryType={setAssessCategoryType}
                        pvPanelReqObj={pvPanelReqObj}
                        setPvPanelReqObj={setPvPanelReqObj}
                        pvInversterReqObj={pvInversterReqObj}
                        setPvInversterReqObj={setPvInversterReqObj}
                        pvOptimiserReqObj={pvOptimiserReqObj}
                        setPvOptimiserReqObj={setPvOptimiserReqObj}
                        pvBatteryReqObj={pvBatteryReqObj}
                        setPvBatteryReqObj={setPvBatteryReqObj}
                        pvOtherReqObj={pvOtherReqObj}
                        setPvOtherReqObj={setPvOtherReqObj}
                      />
                    </TabPanel>
                    <TabPanel value="evaluation">
                      <EvaluationDetails
                        viewOnlyTab={viewOnlyTab}
                        setViewOnlyTab={setViewOnlyTab}
                        handleSnack={handleSnack}
                        assesstmentId={assesstmentId}
                        claimRecordId={claimRecordId}
                        evaluationTabValue={evaluationTabValue}
                        setEvaluationTabValue={setEvaluationTabValue}
                        evaluationDetailsData={evaluationDetailsData}
                        setEvaluationDetailsData={setEvaluationDetailsData}
                        evaluatedByData={evaluatedByData}
                        setEvaluatedByData={setEvaluatedByData}
                        questionsOptions={questionsOptions}
                        partsFailedRecord={partsFailedRecord}
                        partsCausalRecord={partsCausalRecord}
                        setPartsFailedRecord={setPartsFailedRecord}
                        setPartsCausalRecord={setPartsCausalRecord}
                        claimOrderId={claimOrderId}
                        evaluationId={evaluationId}
                        setEvaluationId={setEvaluationId}
                        setRequestTab={setRequestTab}
                        handleEvaluationPartsReturn={handleEvaluationPartsReturn}
                        claimStatus={claimStatus}
                        handleUpdateWarrantyRequest={handleUpdateWarrantyRequestData}
                        assessClaimCategory={assessClaimCategory}
                      />
                    </TabPanel>
                    <TabPanel value="claim">
                      {!viewOnlyTab.claimViewOnly ? (
                        <>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-2">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLAIMENT</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimOrderData?.claiment}
                                    disabled
                                    placeholder="Claiment"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY REQUEST NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimRecordId}
                                    placeholder="Claim Request Id"
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">PARTNER NAME</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimOrderData?.claiment === "PARTNER" ? claimOrderData?.claiment : ""}
                                    disabled
                                    name="partnerName"
                                    placeholder="Partner Name"
                                    onChange={handleClaimRecordDataChange}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM ORDER NUMBER</label>
                                  <input
                                    type="text"
                                    disabled
                                    className="form-control border-radius-10 text-primary"
                                    value={claimNumber}
                                    name="claimNumber"
                                    placeholder="Claim Number"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    disabled
                                    value={machineData?.serialNo}
                                    name="serialNumber"
                                    placeholder="Serial Number"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">WARRANTY ID</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={warrantyId}
                                    name="warrantyId"
                                    placeholder="Warranty Id"
                                    onChange={handleClaimRecordDataChange}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">MODEL NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    disabled
                                    value={machineData?.model}
                                    name="modelNumber"
                                    placeholder="Model Number"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-2">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <FormGroup>
                                    <FormControlLabel
                                      style={{ alignItems: "start", marginLeft: 0 }}
                                      control={<Switch checked={replacement} onChange={(e) => setReplacement(e.target.checked)} />}
                                      labelPlacement="top"
                                      label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">PRODUCT CODE</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimOrderData.componentCode}
                                    name="componentCode"
                                    placeholder="Component Code"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">PRODUCT SERIAL NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimOrderData?.componentSerialNumber}
                                    name="componentSerialNumber"
                                    placeholder="Component Serial Number"
                                    onChange={handleClaimTabInputTextChange}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">PART NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimOrderData?.partNumber}
                                    name="partNumber"
                                    placeholder="Part Number"
                                    onChange={handleClaimTabInputTextChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-2">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM TYPE</label>
                                  <Select
                                    className="text-primary"
                                    isDisabled
                                    options={claimRequestTypeOptions}
                                    // value={claimOrderData.claimType}
                                    value={claimType}
                                    styles={FONT_STYLE_SELECT}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM STATUS</label>
                                  <Select
                                    className="text-primary"
                                    isDisabled
                                    options={claimStatusOptions}
                                    value={claimStatus}
                                    styles={FONT_STYLE_SELECT}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLAIM APPROVER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    value={claimApprover}
                                    name="claimApprover"
                                    disabled
                                    placeholder="Claim Approver"
                                    onChange={(e) => setClaimApprover(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">RMA TYPE</label>
                                  <Select
                                    className="text-primary"
                                    options={rmaTypeOptions}
                                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaType: e })}
                                    value={claimOrderData.rmaType}
                                    styles={FONT_STYLE_SELECT}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">RMA REASON</label>
                                  <Select
                                    className="text-primary"
                                    options={rmaResonOptions}
                                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaReason: e })}
                                    value={claimOrderData.rmaReason}
                                    styles={FONT_STYLE_SELECT}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">RMA NUMBER</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="rmaNumber"
                                    placeholder="RMA Number"
                                    value={claimOrderData.rmaNumber}
                                    onChange={(e) => setClaimOrderData({ ...claimOrderData, rmaNumber: e.target.value })}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE CODE</label>
                                  <Select
                                    className="text-primary"
                                    options={failureCodeOptions}
                                    onChange={(e) => setFailureCode(e)}
                                    value={failureCode}
                                    styles={FONT_STYLE_SELECT}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-4">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                                  <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <MobileDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        className="form-controldate border-radius-10"
                                        closeOnSelect
                                        value={claimRecordData.failDate}
                                        onChange={(e) => handleClaimRecordSelectDataChange(e, "failDate")}
                                        renderInput={(params) => (
                                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">REPAIR DATE</label>
                                  <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <MobileDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        className="form-controldate border-radius-10"
                                        closeOnSelect
                                        value={claimRecordData?.repairDate}
                                        onChange={(e) => handleClaimRecordSelectDataChange(e, "repairDate")}
                                        renderInput={(params) => (
                                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CREATED DATE</label>
                                  <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <MobileDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        className="form-controldate border-radius-10"
                                        closeOnSelect
                                        value={claimRecordData.createdDate}
                                        onChange={(e) => handleClaimRecordSelectDataChange(e, "createdDate")}
                                        renderInput={(params) => (
                                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">CLOSED DATE</label>
                                  <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <MobileDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        className="form-controldate border-radius-10"
                                        closeOnSelect
                                        value={claimRecordData.closedDate}
                                        onChange={(e) => handleClaimRecordSelectDataChange(e, "closedDate")}
                                        renderInput={(params) => (
                                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">APPROVED/REJECTED ON</label>
                                  <div className="align-items-center date-box">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <MobileDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        className="form-controldate border-radius-10"
                                        closeOnSelect
                                        value={claimRecordData.appoverRejectedOn}
                                        onChange={(e) => handleClaimRecordSelectDataChange(e, "appoverRejectedOn")}
                                        renderInput={(params) => (
                                          <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-4">
                              <ReadOnlyField label="CLAIMENT" value={claimOrderData?.claiment} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="WARRANTY REQUEST NUMBER" value={claimRecordId} className="col-md-3 col-sm-3" />
                              <ReadOnlyField
                                label="PARTNER NAME"
                                value={`${claimOrderData?.claiment === "PARTNER" ? claimOrderData?.claiment : ""}`}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField label="CLAIM NUMBER" value={claimNumber} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="SERIAL NUMBER" value={machineData?.serialNo} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="WARRANTY ID" value={warrantyId} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="MODEL NUMBER" value={machineData?.model} className="col-md-3 col-sm-3" />
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-4">
                              <ReadOnlyField label="REPLACEMENT" value={replacement ? "YES" : "NO"} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="PRODUCT CODE" value={claimOrderData.componentCode} className="col-md-3 col-sm-3" />
                              <ReadOnlyField
                                label="PRODUCT SERIAL NUMBER"
                                value={claimOrderData?.componentSerialNumber}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField label="PART NUMBER" value={claimOrderData?.partNumber} className="col-md-3 col-sm-3" />
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-4">
                              <ReadOnlyField label="CLAIM TYPE" value={claimType?.label} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="CLAIM STATUS" value={claimStatus?.label} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="CLAIM APPROVER" value={claimApprover} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="RMA TYPE" value={claimOrderData.rmaType?.label} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="RMA REASON" value={claimOrderData.rmaReason?.label} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="RMA NUMBER" value={claimOrderData.claimNumber} className="col-md-3 col-sm-3" />
                              <ReadOnlyField label="FAILURE CODE" value={failureCode?.label} className="col-md-3 col-sm-3" />
                            </div>
                          </div>
                          <div
                            className="card border px-3 py-2 mb-3"
                            style={{ backgroundColor: `${claimStatus?.value === "ARCHIVED" ? "#f3eafe" : ""}` }}
                          >
                            <div className="row input-fields mt-4">
                              <ReadOnlyField
                                label="FAILURE DATE"
                                value={<Moment format="DD/MM/YYYY">{claimRecordData.failDate}</Moment>}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField
                                label="REPAIR DATE"
                                value={<Moment format="DD/MM/YYYY">{claimRecordData?.repairDate}</Moment>}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField
                                label="CREATED DATE"
                                value={<Moment format="DD/MM/YYYY">{claimRecordData.createdDate}</Moment>}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField
                                label="CLOSED DATE"
                                value={<Moment format="DD/MM/YYYY">{claimRecordData.closedDate}</Moment>}
                                className="col-md-3 col-sm-3"
                              />
                              <ReadOnlyField
                                label="APPROVED/REJECTED ON"
                                value={<Moment format="DD/MM/YYYY">{claimRecordData.appoverRejectedOn}</Moment>}
                                className="col-md-3 col-sm-3"
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="Add-new-segment-div p-3 border-radius-10 mt-2">
                        <div class="repairbtn-dropdown">
                          {viewOnlyTab.claimViewOnly ? (
                            <div className="d-flex align-items-center">
                              <button
                                className="attachment-dropdown bg-primary text-white ml-2 dropbtn"
                                onClick={() => setRequestTab("relatedPartsAndExpenses")}
                              >
                                Next
                              </button>
                              <div>
                                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", marginX: 2 }}>
                                  <IconButton
                                    className="btn border-primary text-black font-size-14 pr-0 ml-2 attachment-dropdown"
                                    style={{ borderRadius: "5px" }}
                                    onClick={handleOpenAttachmentMenu}
                                    size="medium"
                                    aria-controls={openAttachmentMenu ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAttachmentMenu ? "true" : undefined}
                                  >
                                    <span className="mx-2">
                                      Attachment
                                      <span>
                                        <KeyboardArrowDownIcon />
                                      </span>
                                    </span>
                                  </IconButton>
                                </Box>
                                <Menu
                                  className="convert-top-left"
                                  anchorEl={attachmentMenuAnchorEl}
                                  id="account-menu"
                                  open={openAttachmentMenu}
                                  onClose={handleCloseAttachmentMenu}
                                  onClick={handleCloseAttachmentMenu}
                                  PaperProps={{
                                    elevation: 0,
                                    sx: {
                                      overflow: "visible",
                                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                      mt: 1.5,
                                      "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                                      "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                      },
                                    },
                                  }}
                                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                >
                                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                                    Certificate
                                  </MenuItem>
                                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                                    Proof
                                  </MenuItem>
                                  <MenuItem className="custommenu" onClick={handleShowFilesModal}>
                                    Faulty
                                  </MenuItem>
                                  <Divider />
                                </Menu>
                              </div>
                            </div>
                          ) : (
                            <button className="btn bg-primary text-white ml-2 dropbtn" onClick={() => handleSaveClaimOrderChanges({}, true)}>
                              Save Changes
                            </button>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="relatedPartsAndExpenses">
                      <RelatedPartsAndExpenses
                        handleBack={handleBackToRequestTab}
                        handleSnack={handleSnack}
                        relatedHEId={relatedHEId}
                        setRelatedHEId={setRelatedHEId}
                        claimOrderId={claimOrderId}
                        claimNumber={claimNumber}
                        coverageTypeValue={coverageTypeValue}
                        relatedPartsId={relatedPartsId}
                        records={[...partsFailedRecord, ...partsCausalRecord]}
                        relatedPartsRecords={relatedPartsRecords}
                        setRelatedPartsRecords={setRelatedPartsRecords}
                        setRelatedPartsId={setRelatedPartsId}
                        handleViewClaimValue={handleChangeAndActiveTopTab}
                        claimRelateHERecordData={claimRelateHERecordData}
                        setClaimRelateHERecordData={setClaimRelateHERecordData}
                        jobHoursRecords={jobHoursRecords}
                        setJobHoursRecords={setJobHoursRecords}
                        climentOpt={climentOpt}
                        auth={auth}
                        handleUpdateClaimOrder={handleSaveClaimOrderChanges}
                        claimStatus={claimStatus}
                        machineData={machineData}
                        viewOnlyTab={viewOnlyTab}
                        setViewOnlyTab={setViewOnlyTab}
                        handleShowOrderJobHoursModal={handleShowOrderJobHoursModal}
                        warrantyRequestClaimData={warrantyRequestClaimData}
                        questionsOptions={questionsOptions}
                        partsFailedRecord={partsFailedRecord}
                        partsCausalRecord={partsCausalRecord}
                        setPartsFailedRecord={setPartsFailedRecord}
                        setPartsCausalRecord={setPartsCausalRecord}
                        evaluationId={evaluationId}
                        setEvaluationId={setEvaluationId}
                        handleEvaluationPartsReturn={handleEvaluationPartsReturn}
                        claimValueRecordData={claimValueRecordData}
                        setClaimValueRecordData={setClaimValueRecordData}
                        setCoverageTypeValue={setCoverageTypeValue}
                        claimValueId={claimValueId}
                        setClaimValueId={setClaimValueId}
                        priceTypeOptions={priceTypeOptions}
                        setSettlementCount={setSettlementCount}
                        setClaimValueCount={setClaimValueCount}
                        setClaimStatus={setClaimStatus}
                        claimOrderData={claimOrderData}
                        evaluatedByData={evaluatedByData}
                        claimRecordId={claimRecordId}
                        hideModal={hideModal}
                        setRefreshSettlemtData={setRefreshSettlemtData}
                        claimValueCount={claimValueCount}
                      />
                    </TabPanel>
                  </TabContext>
                )}

                {activeTopTab === "realtedPartsExpenses" && (
                  <RelatedPartsAndExpenses
                    handleBack={handleBackToRequestTab}
                    handleSnack={handleSnack}
                    relatedHEId={relatedHEId}
                    setRelatedHEId={setRelatedHEId}
                    claimOrderId={claimOrderId}
                    claimNumber={claimNumber}
                    coverageTypeValue={coverageTypeValue}
                    relatedPartsId={relatedPartsId}
                    records={[...partsFailedRecord, ...partsCausalRecord]}
                    relatedPartsRecords={relatedPartsRecords}
                    setRelatedPartsRecords={setRelatedPartsRecords}
                    setRelatedPartsId={setRelatedPartsId}
                    handleViewClaimValue={handleChangeAndActiveTopTab}
                    claimRelateHERecordData={claimRelateHERecordData}
                    setClaimRelateHERecordData={setClaimRelateHERecordData}
                    climentOpt={climentOpt}
                    auth={auth}
                    handleUpdateClaimOrder={handleSaveClaimOrderChanges}
                    claimStatus={claimStatus}
                    machineData={machineData}
                  />
                )}

                {activeTopTab === "adjustClaimValue" && (
                  <AdjustClaimValue
                    handleSnack={handleSnack}
                    handleBack={handleBackToRequestTab}
                    claimValueId={claimValueId}
                    setClaimValueId={setClaimValueId}
                    claimOrderId={claimOrderId}
                    relatedPartsRecords={relatedPartsRecords}
                    setCoverageTypeValue={setCoverageTypeValue}
                    handleViewSettlement={handleChangeAndActiveTopTab}
                    claimValueRecordData={claimValueRecordData}
                    setClaimValueRecordData={setClaimValueRecordData}
                    handleUpdateClaimOrder={handleSaveClaimOrderChanges}
                    setClaimStatus={setClaimStatus}
                    jobHours={claimRelateHERecordData.jobHours}
                    claimStatus={claimStatus}
                    coverageTypeOptions={coverageTypeOptions}
                    priceTypeOptions={priceTypeOptions}
                    setSettlementCount={setSettlementCount}
                    setClaimValueCount={setClaimValueCount}
                    handleShowClaimErrorsModal={handleShowClaimErrorsModal}
                  />
                )}

                {activeTopTab === "settlement" && (
                  <Settlement
                    handleBack={handleBackToRequestTab}
                    handleSnack={handleSnack}
                    settlementValueId={settlementValueId}
                    setSettlementValueId={setSettlementValueId}
                    claimOrderId={claimOrderId}
                    // claimNumber={claimNumber}
                    handleViewAndAddNotes={handleChangeAndActiveTopTab}
                    claimSettlementRecord={claimSettlementRecord}
                    setClaimSettlementRecord={setClaimSettlementRecord}
                    claimStatus={claimStatus}
                    handleUpdateClaimOrder={handleSaveClaimOrderChanges}
                    claimValueRecordData={claimValueRecordData}
                    setClaimStatus={setClaimStatus}
                    setSettlementCount={setSettlementCount}
                    handleShowClaimErrorsModal={handleShowClaimErrorsModal}
                  />
                )}
                {activeTopTab === "addNotes" && (
                  <ClaimAddNotes handleBack={handleBackToRequestTab} handleSnack={handleSnack} claimStatus={claimStatus} claimNumber={claimNumber} />
                )}
              </Box>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {openPartCreateModal && (
        <ClaimPartCreateModal
          show={openPartCreateModal}
          hideModal={handleShowPartCreateModal}
          handleSnack={handleSnack}
          setNewPartRecord={reportTypeCausal ? setPartsCausalRecord : setPartsFailedRecord}
        />
      )}
      {openValidateClaimErrModal && (
        <ValidateClaimModal show={openValidateClaimErrModal} hideModal={() => setOpenValidateClaimErrModal(false)} handleSnack={handleSnack} />
      )}
      {showJobHoursModal && (
        <HoursOnJobModalOld
          show={showJobHoursModal}
          hideModal={handleShowOrderJobHoursModal}
          jobHoursRecords={jobHoursRecords}
          setJobHoursRecords={setJobHoursRecords}
        />
      )}
      {/* {showJobHoursModal && (
        <HoursOnJobModal
          show={showJobHoursModal}
          hideModal={handleShowOrderJobHoursModal}
          jobHoursRecords={jobHoursRecords}
          setJobHoursRecords={setJobHoursRecords}
        />
      )} */}

      {showClaimErrorModal && <ClaimErrorsModal show={showClaimErrorModal} hideModal={handleShowClaimErrorsModal} />}

      {showFilesModal && (
        <ClaimFilesModal
          show={showFilesModal}
          hideModal={handleShowFilesModal}
          claimFilesRecords={claimFilesRecords}
          setClaimFilesRecords={setClaimFilesRecords}
          // filesRecords={[]}
          handleShowFileUploadModal={handleShowClaimFileUploadModal}
          handleViewFileModal={handleShowViewFileModal}
          setFileType={setClaimFileType}
        />
      )}

      {showClaimFileUploadModal && <UploadFilesModal show={showClaimFileUploadModal} hideModal={handleShowClaimFileUploadModal} />}

      {showViewFileModal && <ClaimImageViewModal show={showViewFileModal} hideModal={handleShowViewFileModal} fileType={claimFileType} />}

      {openReturnRequsterModal && (
        <ClaimReturnRequester
          show={openReturnRequsterModal}
          hideModal={handleShowReturnRequetrModal}
          handleSnack={handleSnack}
          countryOptions={countryOptions}
          partsRecords={partsRecords}
          warrantyReturnId={warrantyReturnId}
          setWarrantyReturnId={setWarrantyReturnId}
        />
      )}
    </>
  );
};

export default ClaimOrderRequestModal;
