import { Fragment, useState } from "react";

import NorthIcon from "@mui/icons-material/North";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import Propensity from "./Propensity";
import { Card, Grid, Typography, Box } from "@mui/material";
import AutoFixHighSharpIcon from "@mui/icons-material/AutoFixHighSharp";
import GapToEntitlement from "./GapToEntitlement";
import SparepartSegment from "./SparepartSegment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import {
  faChartBar,
  faChartLine,
  faChartPie,
  faClock,
  faDollarSign,
  faPercent,
  faWrench,
  faFont,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import ServiceRecommend from "./ServiceRecommend";
import JobhourRecommend from "./JobhourRecommend";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import DiscountGuidance from "./DiscountGuidance";
import MarginRecommendation from "./MarginRecommendation";
import Select from "react-select";

// import AiCustomization from "./AiCustomization";

const lightenColor = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function Insights(props) {
  const [insightType, setInsightType] = useState("");
  const [prompts, setPrompts] = useState([]);
  const insightTypes = [
    {
      type: "propensity",
      label: "Find The Propensity To Buy",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartLine} />,
      color: "#6AD5F9",
      subheading: "Prompts related to predicting customer purchase behaviour",
      prompt: [
        "Find customers with [Product Model] who are likely to need [Specific Part] within the next [Timeframe, e.g., 30 days] based on their maintenance history and usage patterns.",
        "Identify customers with a high propensity to purchase a new [Product Category] based on their existing product ownership and service history.",
      ],
    },
    {
      type: "entitlement",
      label: "Find Gap To Entitlement",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartBar} />,
      color: "#FFB301",
      subheading: "Prompts to identify customers who are eligible for benefits but haven't claimed them",
      prompt: [
        "Find customers with [Product Model] who are eligible for warranty coverage but have not yet submitted a claim",
        "Identify customers with service contracts who have not utilized all their entitlements within the contract period",
      ],
    },
    {
      type: "product-segment",
      label: "Recommend Spare Parts",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faChartPie} />,
      color: "#F8356C",
      subheading: "Prompts for recommending spare parts and prices for customers",
      prompt: [
        "Find spare parts with the specified [Part Number] that have a high transaction value and frequent purchase history, based on past transaction data and buying patterns.",
      ],
    },
    {
      type: "job-hr-recommend",
      label: "Recommend Service Hours",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faClock} />,
      color: "#4FD660",
      subheading: "Prompts for estimating service times",
      prompt: [
        "Estimate the average service time required for [Specific Maintenance Task] on [Product Model] based on historical service records",
        "dentify factors that significantly influence service duration for [Product Model], such as equipment age and usage intensity",
      ],
    },
    {
      type: "service-recommend",
      label: "Recommend Services",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faWrench} />,
      color: "#FE7E10",
      subheading: "Prompts for suggesting relevant services to customers",
      prompt: [
        "Recommend preventive maintenance services for customers with [Product Model] based on their equipment usage and service history",
        "Analyze customer feedback to identify common service issues for [Product Model] and recommend proactive service solutions.",
      ],
    },
    {
      type: "margin",
      label: "Margin Recommendation",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faDollarSign} />,
      color: "#1292FD",
      subheading: "Prompts related to pricing and discounting strategies",
      prompt: [
        "Recommend optimal pricing for [Specific Part] considering its cost, demand, and competitor pricing.",
        "Analyze the impact of different discount levels on the profitability of [Specific Part] sales",
      ],
    },
    {
      type: "discount",
      label: "Discount Guidance",
      icon: <FontAwesomeIcon className="font-size-18 text-white" icon={faPercent} />,
      // color: "#536E79",
      // color: "#3848AA",
      color: "#0C9A8A",
      subheading: "Prompts related to discounting stratergies",
      prompt: [
        "Recommend optimal discount for [Specific Part] considering its cost, demand, and competitor pricing.",
        "Analyze the impact of different discount levels on the profitability of [Specific Part] sales.",
      ],
    },
  ];
  const handleChange = (value, prompt = []) => {
    setInsightType(value);
    setPrompts(prompt);
  };
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [inputText, setInputText] = useState("");
  const [text, setText] = useState("");
  const maxCharacters = 2000;

  // input text value chnage
  const handleInputTextChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= maxCharacters || newValue.length < inputText.length) {
      setInputText(newValue);
    } else {
      handleSnack("warning", `Only ${maxCharacters} charcters you can type`);
    }
  };

  const handleTextChange = (e) => {
    setText(e.target.textContent);
  };
  return (
    <Fragment>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid ">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h4" sx={{ fontWeight: "bold", marginTop: 4 }}>
              Talk Data To Me
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              Ask Your Data Anything
              {/* Choose a prompt below or write your own to */}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              start chatting
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "semibold", marginBlock: 4, fontSize: "1.2rem" }}>
              Ask about:
            </Typography>
          </Grid>
          {/* <Box
            display="flex"
            flexDirection="column"
            // justifyContent="center"
            alignItems="center"
            style={{ gap: "16px" }} // Adjust gap as needed
          >
            {insightTypes
              .reduce((rows, insight, index) => {
                if (index % 3 === 0) rows.push([]); // Start a new row every 3 items
                rows[rows.length - 1].push(insight);
                return rows;
              }, [])
              .map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  display="flex"
                  justifyContent="center"
                  gap="16px" // Adjust gap between cards as needed
                >
                  {row.map((insight, cardIndex) => (
                    <Card
                      key={cardIndex}
                      sx={{
                        borderRadius: 5,
                        padding: 0.5,
                        margin: 0,
                        width: "33.33%", // Ensures 3 cards per row
                        cursor: "pointer",
                        backgroundColor: insight.type === insightType ? "#872ff715" : "",
                        border: insight.type === insightType ? "1px solid #872ff785" : "",
                        boxSizing: "border-box",
                      }}
                      variant="outlined"
                      onClick={() => handleChange(insight.type)}
                    >
                      <Box display="flex" alignItems="center">
                        <Box
                          sx={{ borderRadius: 4, padding: 0.5, display: "flex", justifyContent: "center", backgroundColor: "#872ff7" }}
                          variant="outlined"
                        >
                          {insight.icon}
                        </Box>
                        <Typography fontSize={"0.9rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                          {insight.label}
                        </Typography>
                      </Box>
                    </Card>
                  ))}
                </Box>
              ))}
          </Box> */}

          <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Box display="flex" justifyContent="center" width="60%" gap="16px" marginBottom={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                {insightTypes.slice(0, 1).map((insight, index) => (
                  <Grid item xs={4}>
                    <Card
                      key={index}
                      sx={{
                        borderRadius: 3,
                        padding: 1.8,
                        margin: 0,
                        height: "11.3rem",
                        // width: "40%", // Ensures 3 cards per row
                        cursor: "pointer",
                        backgroundColor: insight.type === insightType ? "#872ff715" : "",
                        border: insight.type === insightType ? "1px solid #872ff785" : "",
                        boxSizing: "border-box", // Ensures no space around the cards
                        "&:hover": {
                          transform: "scale(1.05)", // Slightly enlarges the card
                          boxShadow: "0px 2px 15px rgba(162, 103, 239, 0.52)", // Adds shadow on hover
                          border: "1px solid rgba(162, 103, 239, 0.52)",
                        },
                      }}
                      variant="outlined"
                      onClick={() => handleChange(insight.type)}
                    >
                      <Box display="column" alignItems="start" sx={{ marginTop: "4px" }}>
                        <Box
                          sx={{
                            borderRadius: 4,
                            padding: 0.8,
                            width: "12%",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#872ff7",
                          }}
                          variant="outlined"
                        >
                          {insight.icon}
                        </Box>
                        <Typography fontSize={"1rem"} textAlign={"start"} style={{ fontWeight: "bold", marginTop: "14px" }}>
                          {insight.label}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "semibold", fontSize: "1rem", color: "#76838F", marginTop: "4px" }}>
                          {insight.subheading}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={4}></Grid>
              </Grid>
            </Box> */}
            <Box display="flex" justifyContent="center" width="60%" gap="16px" marginBottom={2}>
              <Grid container spacing={2}>
                {insightTypes.slice(0, 7).map((insight, index) => (
                  <Grid item xs={4}>
                    <Card
                      key={index}
                      sx={{
                        borderRadius: 3,
                        padding: 2.1,
                        margin: 0,
                        height: "11.3rem",
                        // width: "40%", // Ensures 3 cards per row
                        cursor: "pointer",
                        // backgroundColor: insight.type === insightType ? "#872ff715" : "",
                        // border: insight.type === insightType ? "1px solid #872ff785" : "",
                        backgroundColor: insight.type === insightType ? `${lightenColor(insight.color, 0.2)}` : "",
                        border: insight.type === insightType ? `1px solid ${insight.color}` : "",
                        boxSizing: "border-box", // Ensures no space around the cards
                        "&:hover": {
                          transform: "scale(1.05)",
                          // boxShadow: "0px 2px 15px rgba(162, 103, 239, 0.52)",
                          boxShadow: `0px 2px 10px ${lightenColor(insight.color, 0.5)}`,
                          border: "1px solid rgba(162, 103, 239, 0.52)",
                        },
                      }}
                      variant="outlined"
                      onClick={() => handleChange(insight.type, insight.prompt)}
                    >
                      <Box display="column" alignItems="start">
                        <Box
                          sx={{
                            borderRadius: 2,
                            padding: 1,
                            width: "14%",
                            height: "14%",
                            display: "flex",
                            justifyContent: "center",
                            // backgroundColor: "#872ff7",
                            backgroundColor: `${insight.color}`,
                          }}
                          variant="outlined"
                        >
                          {insight.icon}
                        </Box>
                        <Typography fontSize={"1rem"} textAlign={"start"} style={{ fontWeight: "bold", marginTop: "14px" }}>
                          {insight.label}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: "semibold", fontSize: "1rem", color: "#76838F", marginTop: "4px" }}>
                          {insight.subheading}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          {/* <Box display="flex" flexDirection="column" alignItems="center"> */}
          {/* <Box display="flex" justifyContent="center" width="60%" gap="16px" marginBottom={2}>
              {insightTypes.slice(0, 3).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 1,
                    margin: 0,
                    width: "40%", // Ensures 3 cards per row
                    cursor: "pointer",
                    backgroundColor: insight.type === insightType ? "#872ff715" : "",
                    border: insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box", // Ensures no space around the cards
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.8,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography fontSize={"1rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box> */}

          {/* <Box display="flex" justifyContent="center" width="70%" gap="16px" marginBottom={2}>
              {insightTypes.slice(3, 5).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 1,
                    // padding: 0.5,
                    margin: 0,
                    width: "40%",
                    // width: "33.33%",
                    cursor: "pointer",
                    backgroundColor: insight.type === insightType ? "#872ff715" : "",
                    border: insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box",
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.8,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography fontSize={"1rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box> */}
          {/* <Box display="flex" justifyContent="center" width="70%" gap="16px" marginBottom={2}>
              {insightTypes.slice(5, 7).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 1,
                    margin: 0,
                    width: "40%",
                    cursor: "pointer",
                    backgroundColor: insight.type === insightType ? "#872ff715" : "",
                    border: insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box",
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.8,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography fontSize={"1rem"} textAlign={"center"} style={{ marginLeft: "8px" }}>
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box> */}

          {/* <Box display="flex" justifyContent="center" width="60%" gap="16px">
              {insightTypes.slice(6).map((insight, index) => (
                <Card
                  key={index}
                  sx={{
                    borderRadius: 5,
                    padding: 0.5,
                    margin: 0,
                    width: "33.33%",
                    cursor: "pointer",
                    backgroundColor:
                      insight.type === insightType ? "#872ff715" : "",
                    border:
                      insight.type === insightType ? "1px solid #872ff785" : "",
                    boxSizing: "border-box",
                  }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}
                >
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        borderRadius: 4,
                        padding: 0.5,
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#872ff7",
                      }}
                      variant="outlined"
                    >
                      {insight.icon}
                    </Box>
                    <Typography
                      fontSize={"0.9rem"}
                      textAlign={"center"}
                      style={{ marginLeft: "8px" }}
                    >
                      {insight.label}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Box> */}
          {/* </Box> */}
          {/* <Grid container columnSpacing={3}> */}
          {/* <Grid container justifyContent="center" alignItems="center" spacing={0} >
            {insightTypes.map(insight =>
              <Grid item  lg={4} md={4} xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                  sx={{ borderRadius: 5, padding: 0.5, marginBlock: 1, display: "flex",width:"60%" , cursor: 'pointer', backgroundColor: insight.type === insightType ? "#872ff715" : "", border: insight.type === insightType ? "1px solid #872ff785" : "" }}
                  variant="outlined"
                  onClick={() => handleChange(insight.type)}

                >
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Card sx={{ borderRadius: 4, padding: 0.5, display: 'flex', justifyContent: 'center', backgroundColor: '#872ff7' }} variant="outlined">
                        {insight.icon}
                      </Card>
                    </Grid>
                    <Grid item container xs={10}  sx={{ paddingLeft: 1 }}>
                      {/* <Box> */}
          {/* <Typography fontSize={'0.9rem'} textAlign={'center'}>{insight.label}</Typography>
                      {/* </Box> */}
          {/* </Grid> */}
          {/* </Grid> */}
          {/* </Card> */}
          {/* </Grid> */}
          {/* )} */}
          {/* </Grid>  */}

          <div className="px-4 mb-5">
            <Box sx={{ width: "100%", backgroundColor: "#E5E5E5" }}>
              <div className="flex-column border shadow-sm">
                {prompts.length > 0 && (
                  <div className="px-2 mt-2">
                    <Typography sx={{ fontSize: 16, fontWeight: 500, mb: 1, paddingX: 1 }}>Suggested Prompts</Typography>
                    {prompts.map((message, index) => (
                      <div className="card border p-2 mb-2 cursor">
                        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                          <ArrowRightAltIcon style={{ fontSize: "40px", marginRight: "3px" }} />
                          {message}
                        </Typography>
                      </div>
                    ))}
                  </div>
                )}
                <div className="d-flex align-items-baseline mb-2">
                  <span className="mx-2">
                    <AutoFixHighSharpIcon style={{ fontSize: "35px" }} />
                  </span>
                  <textarea
                    className="form-control text-secondary insights-input-textarea border-0 font-size-16"
                    style={{ backgroundColor: "#E5E5E5", resize: "none" }}
                    placeholder="Type your request here..."
                    cols="30"
                    rows="6"
                    value={inputText}
                    onChange={handleInputTextChange}
                  ></textarea>
                </div>
                <div className="d-flex justify-content-between align-items-center px-3 py-2">
                  <Select
                    className="border-primary text-primary"
                    placeholder="Select Source"
                    menuPlacement="top"
                    options={[
                      { label: "Parts & Service Sales Data", value: "PARTS_SERVICE_SALES" },
                      { label: "Service or Work Order History", value: "SERVICE_WORK_ORDER" },
                      { label: "Service Sales Data", value: "SERVICE_SALES" },
                      { label: "Parts Sales Data", value: "PARTS+SALES_DATA" },
                    ]}
                  />
                  <div className="d-flex align-items-center">
                    <span className="mx-2 font-weight-500 font-size-16">
                      {inputText.length}/{maxCharacters}
                    </span>
                    <button
                      className="d-flex justify-content-center align-items-center btn btn-light ms-3 p-0 font-weight-500 font-size-16 insight-send-button"
                      disabled={inputText.length == 0}
                    >
                      <NorthIcon sx={{ fontSize: 35 }} />
                    </button>
                    {/* <span className="font-weight-500">
                      <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: "18px" }} />
                    </span> */}
                  </div>
                </div>
              </div>
            </Box>
          </div>

          {insightType === "propensity" && <Propensity handleSnack={handleSnack} />}
          {insightType === "entitlement" && <GapToEntitlement handleSnack={handleSnack} />}
          {insightType === "product-segment" && <SparepartSegment handleSnack={handleSnack} />}
          {insightType === "service-recommend" && <ServiceRecommend handleSnack={handleSnack} />}
          {insightType === "job-hr-recommend" && <JobhourRecommend handleSnack={handleSnack} />}
          {insightType === "discount" && <DiscountGuidance handleSnack={handleSnack} />}
          {insightType === "margin" && <MarginRecommendation handleSnack={handleSnack} />}
        </div>
      </div>
    </Fragment>
  );
}
