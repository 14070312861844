import React, { useCallback, useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Stack, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import Select from "react-select";

import LoadingProgress from "pages/Repair/components/Loader";
import EquipmentSearchMaster from "./EquipmentSearchMaster";
import SearchListMaster from "./SearchListMaster";
import WithoutSearchDataTable from "./WithoutSearchDataTable";
import LabourAndServiceReport from "./LabourAndServiceMaster/LabourAndServiceReport";
import ServiceMasterSearchList from "./LabourAndServiceMaster/ServiceMasterSearchList";
import {
  service_search_uri,
  External_PRICE,
  SERACH_MASTER_LABOUR,
  DATA_SVC_LABOUR_CONTROLLER,
  LABOR_DETAILS,
  PRICING_LABOUR_PRICE_SEARCH,
  GET_LABOUR_PRICE,
} from "services/CONSTANTS";
import { API_SUCCESS } from "services/ResponseCode";
import { callDeleteApi, callGetApi } from "services/ApiCaller";
import {
  LABOUR_AND_SERVICE_ERP_DETAILS,
  LABOUR_AND_SERVICE_PRICE_DETAILS,
  SEARCH_FLAG_LABOUR,
  SEARCH_FLAG_SERVICE,
  SERVICE_PRICE_DETAILS,
  SEARCH_FLAG_EMPLOYEE,
} from "./equipmentMasterConstants";
import { isEmpty } from "pages/PortfolioAndBundle/newCreatePortfolioData/utilities/textUtilities";
import { ReadOnlyField } from "pages/Repair/components/ReadOnlyField";
import { GRID_STYLE } from "pages/Common/constants";
import LaborMasterSearchList from "./LabourAndServiceMaster/LaborMasterSearchList";
import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import LaborMasterAddUpdateModal from "./LabourAndServiceMaster/LaborMasterAddUpdateModal";
import ServiceMasterAddUpdateModal from "./LabourAndServiceMaster/ServiceMasterAddUpdateModal";
import Moment from "react-moment";
import LaborPriceAddUpdate from "./LabourAndServiceMaster/LaborPriceAddUpdate";
import EmployeeAddModal from "./LabourAndServiceMaster/EmployeeAddModal";
import { serviceLocationSearchOptions } from "pages/Warehouse/warehouseConstents";
import LocationNewAddModal from "./LabourAndServiceMaster/LocationNewAddModal";
import { useDispatch } from "react-redux";
import {
  repairActions,
  selectChargeCodeList,
  selectDropdownOption,
  selectLaborCodeList,
  selectLaborTypeList,
  selectServiceTypeList,
} from "pages/Repair/dropdowns/repairSlice";
import { useAppSelector } from "app/hooks";
import EmployeeSearchModal from "./LabourAndServiceMaster/EmployeeSearchModal";
import EmployeeMasterSearchList from "./LabourAndServiceMaster/EmployeeMaster/EmployeeMasterSearchList";

const expendTableCustomStyle = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#872ff7",
      color: "#fff",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};

const inventoryStatusBgColor = {
  operational: "#dfffc9", // Light green background
  "not operational": "#c14e567a", // Light red background
};

const inventoryStatusColor = {
  operational: "#00b8b0", // Dark green text
  "not operational": "#c14e56", // Dark red text
};

const tempdata = [
  {
    itemName: "2276044",
    itemDescription: "New",
    itemHeaderStrategy: "SEAL-O-RING",
    taskType: "1757896",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "9.92",
    bundleFlag: "9.92",
  },
  {
    itemName: "3734828",
    itemDescription: "Reman",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R4469",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "94886.38",
    bundleFlag: "94886.38",
  },
  {
    itemName: "3620656",
    itemDescription: "New",
    itemHeaderStrategy: "Spacer",
    taskType: "6I6123",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "40.69",
    bundleFlag: "40.69",
  },
  {
    itemName: "3598761",
    itemDescription: "Refurbish",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R5474",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "5879.24",
    bundleFlag: "5879.24",
  },
];
const erpservicedata = [
  {
    itemName: "2276044",
    itemDescription: "New",
    itemHeaderStrategy: "1757896",
    taskType: "PC",
    quantity: "9.92",
  },
  {
    itemName: "3734828",
    itemDescription: "Reman",
    itemHeaderStrategy: "10R4469",
    taskType: "PC",
    quantity: "94886.38",
  },
  {
    itemName: "3620656",
    itemDescription: "New",
    itemHeaderStrategy: "6I6123",
    taskType: "PC",
    quantity: "40.69",
  },
  {
    itemName: "3598761",
    itemDescription: "Refurbish",
    itemHeaderStrategy: "10R5474",
    taskType: "PC",
    quantity: "5879.24",
  },
];
const dummySearchLaborList = [
  {
    id: 1,
    A: "S012",
    B: "Internal",
    C: "Inspection",
    D: "Field Service",
    active: true,
  },
  {
    id: 2,
    A: "S006",
    B: "Internal",
    C: "Analysis",
    D: "Field Service",
    active: false,
  },
  {
    id: 3,
    A: "S014",
    B: "External",
    C: "Inspection",
    D: "Special Service",
    active: false,
  },
  {
    id: 4,
    A: "3J0634",
    B: "3620656",
    C: "SEAL",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 5,
    A: "F198300020130",
    B: "3620656",
    C: "PRESSURE WASHER",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 6,
    A: "2A4429",
    B: "3620656",
    C: "LOCK",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 7,
    A: "3681P053",
    B: "3620656",
    C: "MANIFOLD COVER GASKET",
    D: "CATERPILLAR",
    active: false,
  },
];
const dummySearchServiceList = [
  {
    id: 1,
    A: "AC01",
    B: "Description 1",
    C: "Chroming",
    D: "Test Agency 1",
    active: true,
  },
  {
    id: 2,
    A: "AC02",
    B: "Description 2",
    C: "Electrical Work",
    D: "Test Agency 2",
    active: false,
  },
  {
    id: 3,
    A: "AC03",
    B: "Description 3",
    C: "Machining",
    D: "Test Agency 3",
    active: false,
  },
  {
    id: 4,
    A: "AC04",
    B: "Description 4",
    C: "Tooling",
    D: "Test Agency 4",
    active: false,
  },
  {
    id: 5,
    A: "AC05",
    B: "Description 5",
    C: "Crane Service",
    D: "Test Agency 5",
    active: false,
  },
  {
    id: 6,
    A: "AC06",
    B: "Description 6",
    C: "Welding",
    D: "Test Agency 6",
    active: false,
  },
  {
    id: 7,
    A: "AC07",
    B: "Description 7",
    C: "Drlling",
    D: "Test Agency 7",
    active: false,
  },
];

const dummyPriceDeatilsData = [
  {
    chargeCode: "Level I",
    labourCode: "L3 - Inspetion + Travel",
    labourType: "Field",
    serviceType: "Normal",
    unitOfMeasure: "Hours",
    unitPrice: "53",
    currency: "",
    startDate: "12-10-2020",
    endDate: "31-12-2023",
    effectiveFrom: "",
    lastUpdated: "",
  },
  {
    chargeCode: "Level II",
    labourCode: "L3 - Inspetion + Travel",
    labourType: "Field",
    serviceType: "Normal",
    unitOfMeasure: "Hours",
    unitPrice: "78",
    currency: "",
    startDate: "12-10-2020",
    endDate: "31-12-2023",
    effectiveFrom: "",
    lastUpdated: "",
  },
  {
    chargeCode: "Level III",
    labourCode: "L3 - Inspetion + Travel",
    labourType: "Field",
    serviceType: "Normal",
    unitOfMeasure: "Hours",
    unitPrice: "102",
    currency: "",
    startDate: "12-10-2020",
    endDate: "31-12-2023",
    effectiveFrom: "",
    lastUpdated: "",
  },
];
const dummyLaborData = {
  id: 1,
  tenantId: 74,
  laborCode: "LABOR_FLD",
  jobCode: "Job123",
  chargeCode: "LEVEL_III",
  chargeCode: "LEVEL_III",
  componentCodes: [],
  country: "India",
  createdAt: "2024-11-15T18:26:21.641485",
  description: "Hello price",
  id: 58,
  jobCode: "Job123",
  laborCode: "LABOR_FLD",
  laborDescription: "Labour description",
  laborType: "SHOP",
  margin: 0.9,
  masterJobCodes: [],
  plannedDuration: 10,
  priceType: "FIXED",
  recommendedDuration: 10,
  region: "India",
  serviceType: "WEEKEND",
  tenantId: 74,
  totalPrice: 100.9,
  unitOfMeasure: "HOURS",
  unitPrice: 100,
  updatedAt: "2024-11-15T18:26:21.641485",
  vendorId: "12",
};

const dummyServiceData = {
  id: 1,
  activityId: "AC01",
  activityDescription: "Chroming",
  activityType: "Purchase",
  longDescription: "Description 1",
  dimension1: "4 X 5 X 6 Chromes",
  dimension2: "",
  supplyingVendorCode: "103456",
  supplyingVendorName: "Test Agency -1",
  contractedPrice: 600,
  validFrom: "2021-01-01",
  validTo: "2024-01-01",
  createdAt: null,
  updatedAt: null,
  createdBy: null,
  updatedBy: null,
};

const ExpandedComponent = ({ data }) => {
  const employeeColumns = [
    { name: "Employee ID", selector: (row) => row.id },
    { name: "Name", selector: (row) => row.name },
    { name: "Address", selector: (row) => row.address },
  ];

  return (
    <div style={{ padding: "10px", background: "#f8f9fa" }}>
      <DataTable title="Employee Details" columns={employeeColumns} data={data.employees} noHeader striped highlightOnHover pagination />
    </div>
  );
};

const locationRecords = [
  {
    id: 1,
    locationName: "Visakhapatnam",
    address: "Auto Nagar, IDA, Visakhapatnam",
    locationType: "Service Workshop & Centre",
    serviceGroup: "Engineers, Admin, Coordinators",
    totalEmployees: 15,
    available: true,
    employees: [
      { id: "E001", name: "Ravi Kumar", address: "RK Beach, Visakhapatnam" },
      { id: "E002", name: "Sneha Sharma", address: "Gajuwaka, Visakhapatnam" },
      { id: "E003", name: "Arjun Reddy", address: "MVP Colony, Visakhapatnam" },
      { id: "E004", name: "Meera Nair", address: "Dwaraka Nagar, Visakhapatnam" },
      { id: "E005", name: "Anil Kumar", address: "Steel Plant, Visakhapatnam" },
      { id: "E006", name: "Priya Menon", address: "Lawsons Bay, Visakhapatnam" },
      { id: "E007", name: "Vikram Joshi", address: "Seethammadhara, Visakhapatnam" },
      { id: "E008", name: "Lakshmi Iyer", address: "Jagadamba Junction, Visakhapatnam" },
      { id: "E009", name: "Naveen Patil", address: "NAD Junction, Visakhapatnam" },
      { id: "E010", name: "Rajesh Rao", address: "Sagar Nagar, Visakhapatnam" },
      { id: "E011", name: "Pooja Desai", address: "VUDA Colony, Visakhapatnam" },
      { id: "E012", name: "Manoj Gupta", address: "Chinna Waltair, Visakhapatnam" },
      { id: "E013", name: "Rekha Yadav", address: "Pendurthi, Visakhapatnam" },
      { id: "E014", name: "Karan Singh", address: "Simhachalam, Visakhapatnam" },
      { id: "E015", name: "Anjali Verma", address: "Gopalapatnam, Visakhapatnam" },
    ],
  },
  {
    id: 2,
    locationName: "Hyderabad",
    address: "Banjara Hills, Hyderabad",
    locationType: "Service Workshop",
    serviceGroup: "Engineers, Admin",
    totalEmployees: 25,
    available: false,
    employees: [
      { id: "E003", name: "Michael Brown", address: "Street 3, Hyderabad" },
      { id: "E004", name: "Emily Davis", address: "Street 4, Hyderabad" },
    ],
  },
  {
    id: 3,
    locationName: "Chennai",
    address: "Guindy Industrial Estate, Chennai",
    locationType: "Service Centre",
    serviceGroup: "Engineers, Coordinators",
    totalEmployees: 20,
    available: false,
  },
];
const ServiceMaster = () => {
  const [bundleItems, setBundleItems] = useState([...tempdata]);
  const [showViewLocationModal, setShowViewLocationModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  let dispatch = useDispatch();

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };
  const [locationRecords, setLocationRecords] = useState([
    {
      id: 1,
      locationName: "Visakhapatnam",
      address: "Auto Nagar, IDA, Visakhapatnam",
      locationType: "Service Workshop & Centre",
      serviceGroup: "Engineers, Admin, Coordinators",
      totalEmployees: 15,
      available: true,
      employees: [
        { id: "E001", name: "Ravi Kumar", address: "RK Beach, Visakhapatnam" },
        { id: "E002", name: "Sneha Sharma", address: "Gajuwaka, Visakhapatnam" },
        { id: "E003", name: "Arjun Reddy", address: "MVP Colony, Visakhapatnam" },
        { id: "E004", name: "Meera Nair", address: "Dwaraka Nagar, Visakhapatnam" },
        { id: "E005", name: "Anil Kumar", address: "Steel Plant, Visakhapatnam" },
        { id: "E006", name: "Priya Menon", address: "Lawsons Bay, Visakhapatnam" },
        { id: "E007", name: "Vikram Joshi", address: "Seethammadhara, Visakhapatnam" },
        { id: "E008", name: "Lakshmi Iyer", address: "Jagadamba Junction, Visakhapatnam" },
        { id: "E009", name: "Naveen Patil", address: "NAD Junction, Visakhapatnam" },
        { id: "E010", name: "Rajesh Rao", address: "Sagar Nagar, Visakhapatnam" },
        { id: "E011", name: "Pooja Desai", address: "VUDA Colony, Visakhapatnam" },
        { id: "E012", name: "Manoj Gupta", address: "Chinna Waltair, Visakhapatnam" },
        { id: "E013", name: "Rekha Yadav", address: "Pendurthi, Visakhapatnam" },
        { id: "E014", name: "Karan Singh", address: "Simhachalam, Visakhapatnam" },
        { id: "E015", name: "Anjali Verma", address: "Gopalapatnam, Visakhapatnam" },
      ],
    },
    {
      id: 2,
      locationName: "Hyderabad",
      address: "Banjara Hills, Hyderabad",
      locationType: "Service Workshop",
      serviceGroup: "Engineers, Admin",
      totalEmployees: 25,
      available: false,
      // employees: [
      //   { id: "E003", name: "Michael Brown", address: "Street 3, Hyderabad" },
      //   { id: "E004", name: "Emily Davis", address: "Street 4, Hyderabad" },
      // ],
    },
    {
      id: 3,
      locationName: "Chennai",
      address: "Guindy Industrial Estate, Chennai",
      locationType: "Service Centre",
      serviceGroup: "Engineers, Coordinators",
      totalEmployees: 20,
      available: false,
    },
  ]);
  // Function to handle adding a new location
  const addNewLocation = (newLocation) => {
    const newLocationWithId = {
      ...newLocation,
      id: locationRecords.length + 1, // Generate a new unique ID
    };
    setLocationRecords([...locationRecords, newLocationWithId]);
  };

  const [laborERPPrices, setLaborERPPrices] = useState([
    {
      id: 1,
      activityName: "Labor Price",
      activityCost: "50",
      currency: "USD",
      period: "1",
      fiscalYear: "2024",
      costType: "Fixed",
    },
  ]);

  const [labourPriceDetails, setlabourPriceDetails] = useState([...dummyPriceDeatilsData]);
  const [showModal, setShowModal] = useState(false);
  const [modelHeaderTitle, setModelHeaderTitle] = useState("");
  const [modelContentReportType, setModelContentReportType] = useState("");
  const [modelContentReportObj, setModelContentReportObj] = useState(null);

  const [openLaborModal, setOpenLaborModal] = useState(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [laborRecordId, setLaborRecordId] = useState(null);

  const [openEmployeeSearchModal, setOpenEmployeeSearchModal] = useState(false);
  const [selectedLocationRow, setSelectedLocationRow] = useState(null);

  const [openLaborPriceModal, setOpenLaborPriceModal] = useState(false);
  const [laborPriceRecordId, setLaborPriceRecordId] = useState(null);
  const [openLocationModal, setOpenLocationModal] = useState(false);

  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [serviceRecordId, setServiceRecordId] = useState(null);

  const [selectedEmp, setSelectedEmp] = useState(null);
  const [empSearchList, setEmpSearchList] = useState([]);
  const [searchedEmp, setSearchedEmp] = useState(null);
  const [empRecordId, setEmpRecordId] = useState(null);

  const [laborPageNo, setLaborPageNo] = useState(1);
  const [servicePageNo, setServicePageNo] = useState(1);
  const [globalLaborList, setGlobalLaborList] = useState([]);
  const [globalServiceList, setGlobalServiceList] = useState([...dummySearchServiceList]);
  const [erpItemsService, setErpItemsService] = useState([...erpservicedata]);
  const [value, setValue] = React.useState("1");
  const [searchList, setSearchList] = useState([]);
  const [searchedService, setSearchedService] = useState({
    ...dummyServiceData,
  });
  const [searchedLabour, setSearchedLabour] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [searchedServicePriceDetails, setSearchedServicePriceDetails] = useState([]);
  const [searchedLaborPriceDetails, setSearchedLaborPriceDetails] = useState([]);
  const [refreshLaborTable, setRefreshLaborTable] = useState(0);
  const [selectedLaborId, setSelectedLaborId] = useState(null);

  const [searchParts, setSearchParts] = useState([]);
  const [partPageNo, setPartPageNo] = useState(1);
  const [partsPerPage, setPartsPerPage] = useState(5);
  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleExpandToggle = (row) => {
    setExpandedRowId(expandedRowId === row.id ? null : row.id);
  };

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    dispatch(repairActions.fetchDropdowns());
  }, []);

  // Retrieve charge codes
  const chargeCodeList = useAppSelector(selectDropdownOption(selectChargeCodeList));

  // Retrieve labor types
  const laborTypeList = useAppSelector(selectDropdownOption(selectLaborTypeList));

  // Retrieve service types
  const serviceTypeList = useAppSelector(selectDropdownOption(selectServiceTypeList));

  // Retrieve labor codes
  const laborCodeList = useAppSelector(selectDropdownOption(selectLaborCodeList));

  // view location details
  const handleView = (row) => {
    setSelectedRow(row);
    setShowViewLocationModal(true);
  };

  // viewLocationModal close
  const handleClose = () => {
    setShowViewLocationModal(false);
    setSelectedRow(null);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    if (!showModal) {
      setModelHeaderTitle("");
      setModelContentReportType("");
      setModelContentReportObj(null);
    }
  }, [showModal]);

  useEffect(() => {
    setSelectedServiceId(null);
  }, [searchList]);

  useEffect(() => {
    setSelectedLaborId(null);
  }, [globalLaborList]);

  //Labor Page Change
  const handleLaborPageChange = (event, value) => {
    setLaborPageNo(value);
  };

  const handleServicePageChange = (event, value) => {
    setServicePageNo(value);
  };

  // modal component function
  const handleShowReportDetails = (title, reportType, row) => {
    setModelHeaderTitle(title);
    setModelContentReportType(reportType);
    setModelContentReportObj(row);
    setShowModal(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setGlobalLaborList([]);
    setSearchList([]);
  };

  const [labormasterpagination, setLabormasterpagination] = React.useState(1);
  const laborPaginationChange = (event, value) => {
    setLabormasterpagination(value);
  };

  // edit the labor price data
  const hanleEditOpenLaborPriceDtls = (row) => {
    setLaborPriceRecordId(row?.id);
    setOpenLaborPriceModal(true);
  };

  // delete the labor price
  const handleDeleteLaborPrice = async (row) => {
    const rUrl = `${GET_LABOUR_PRICE}/${row?.id}`;
    callDeleteApi(null, rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        const _priceData = searchedLaborPriceDetails.filter((item) => item?.id !== responseData?.id);
        setRefreshLaborTable((pre) => pre + 1);
        setSearchedLaborPriceDetails([..._priceData]);
        handleSnack("success", "Price Record deleted successfully");
      } else {
        handleSnack("error", "Something went wrong.");
      }
    });
  };

  //
  const handleOpenEmpSearchModal = (row) => {
    setSelectedLocationRow(row);
    setOpenEmployeeSearchModal(true);
  };

  const locationColumns = [
    {
      name: "Location Name",
      selector: (row) => row.locationName,
      sortable: true,
      wrap: true,
      minWidth: 100,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      wrap: true,
      minWidth: 100,
    },
    {
      name: "Location Type",
      selector: (row) => row.locationType,
      sortable: true,
      wrap: true,
      minWidth: 100,
    },
    {
      name: "Service Group",
      selector: (row) => row.serviceGroup,
      sortable: false,
      wrap: true,
      minWidth: 100,
    },
    {
      name: "Total Employees",
      selector: (row) => row.totalEmployees,
      sortable: true,
      wrap: true,
      minWidth: 100,
      cell: (row) => (
        <div className="d-flex justify-content-between align-items-center w-100">
          <span>{row.totalEmployees}</span>
          <button
            className={`border px-2 py-1 border-radius-10 ${row.id === expandedRowId ? "active-filter-warehouse" : ""}`}
            onClick={() => handleExpandToggle(row)}
          >
            {row.id === expandedRowId ? (
              <>
                Hide <ExpandLessIcon className="font-size-32 mx-1" />
              </>
            ) : (
              <>
                Expand <ExpandMoreIcon className="font-size-32 mx-1" />
              </>
            )}
          </button>
        </div>
      ),
    },
    {
      id: "stockStatus",
      name: "Status",
      selector: (row) => row.stockStatus,
      wrap: true,
      minWidth: 100,
      cell: (row) => (
        <>
          <div
            className="d-flex justify-content-between align-items-center py-2"
            style={{
              fontSize: 15,
              backgroundColor: row.available ? inventoryStatusBgColor["operational"] : inventoryStatusBgColor["not operational"],
              paddingInline: 5,
              paddingBlock: 2,
              borderRadius: 6,
              textTransform: "capitalize",
              color: row.available ? inventoryStatusColor["operational"] : inventoryStatusColor["not operational"],
            }}
          >
            <span
              style={{
                borderRadius: "50%",
                display: "inline-block",
                marginRight: 8,
                height: 10,
                width: 10,
                backgroundColor: row.available ? inventoryStatusColor["operational"] : inventoryStatusColor["not operational"],
              }}
            ></span>
            {row.available ? "Operational" : "Not operational"}
          </div>
        </>
      ),
    },
    {
      id: "actions",
      name: "Actions",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Tooltip title="View">
            <button
              className="btn btn p-0 mx-2"
              onClick={() => handleView(row)}
              // onClick={() => handleOpenEmpSearchModal(row)}
            >
              <VisibilityIcon />
            </button>
          </Tooltip>
          <Tooltip title="Search Employee">
            <button
              className="btn btn p-0 mx-2"
              onClick={() => handleOpenEmpSearchModal(row)}
              // onClick={() => handleView(row)}
            >
              <SearchIcon />
            </button>
          </Tooltip>
        </div>
      ),
      minWidth: 100,
      wrap: true,
    },
  ];

  // price labor Columns
  const priceLaborColumns = [
    { field: "priceMethod", headerName: "Price Method", flex: 1 },
    { field: "chargeCode", headerName: "Charge Code", flex: 1 },
    { field: "serviceType", headerName: "Service Type", flex: 1 },
    { field: "laborType", headerName: "Labor Type", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "unit", headerName: "Unit", flex: 1, renderCell: ({ row }) => row?.unit || "HOURS" },
    { field: "costPrice", headerName: "Cost Price", flex: 1 },
    { field: "price", headerName: "Price", flex: 1 },
    {
      field: "validFrom",
      headerName: "Valid From",
      flex: 1,
      type: "date",
      renderCell: ({ row }) => (row?.validFrom ? <Moment format="DD/MM/YYYY">{row?.validFrom}</Moment> : "-"),
    },
    {
      field: "validTo",
      headerName: "Valid To",
      flex: 1,
      type: "date",
      renderCell: ({ row }) => (row?.validTo ? <Moment format="DD/MM/YYYY">{row?.validTo}</Moment> : "-"),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => hanleEditOpenLaborPriceDtls(row)}>
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleDeleteLaborPrice(row)}>
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const priceServiceColumns = [
    {
      field: "code",
      headerName: "Activity ID",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Activity Name",
      flex: 1,
    },
    {
      field: "vendorName",
      headerName: "Supplying Vendor",
      flex: 1,
    },
    {
      field: "unit",
      headerName: "Unit Of Measure",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Unit Price",
      flex: 1,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "validFrom",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "validTo",
      headerName: "End Date",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleShowReportDetails("Price Details", SERVICE_PRICE_DETAILS, params.row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];
  const erpLaborColumns = [
    {
      field: "activityName",
      headerName: "Activity Name",
      flex: 1,
    },
    {
      field: "activityCost",
      headerName: "Activity Cost",
      flex: 1,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "period",
      headerName: "Period ",
      flex: 1,
    },
    {
      field: "fiscalYear",
      headerName: "Fiscal Year",
      flex: 1,
    },
    {
      field: "costType",
      headerName: "Average/Fixed Cost",
      flex: 1,
    },
  ];

  // view Labor Details
  const viewlaborDetails = (id) => {
    const searchedLaborDetailsUrl = `${LABOR_DETAILS}/${id}`;
    setSelectedLaborId(id);
    setLaborPageNo(1);
    setLoading(true);

    callGetApi(
      searchedLaborDetailsUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSearchedLabour(responseData);

          getLabourPriceData(responseData);

          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  //
  const getLabourPriceData = (row) => {
    const _chargeCode =
      row?.chargeCode === "MINIMUM_SERVICE_CHARGE"
        ? "MINIMUM_SERVICE_CALL"
        : row?.chargeCode === "ADDITIONAL_SERVICE_CHARGE"
        ? "ADDITIONAL_ITEMS"
        : row?.chargeCode || "";
    // const query = `chargeCode:${row?.chargeCode} && serviceType:${row?.serviceType} && laborType:${row?.laborType}`;
    let query = `laborType:"${row?.laborType}" AND chargeCode:"${_chargeCode}" AND serviceType:"${row?.serviceType}"`;
    if (row?.jobCode) {
      query += ` AND jobCode:"${row?.jobCode}"`;
    }
    const encodedQuery = query;
    // const encodedQuery = encodeURIComponent(query);
    const rUrl = `${PRICING_LABOUR_PRICE_SEARCH(encodedQuery)}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSearchedLaborPriceDetails(responseData);
          setRefreshLaborTable((pre) => pre + 1);
        } else {
          setSearchedLaborPriceDetails([]);
          setRefreshLaborTable((pre) => pre + 1);
        }
      },
      (error) => {
        setSearchedLaborPriceDetails([]);
        setRefreshLaborTable((pre) => pre + 1);
      }
    );
  };

  //
  const viewEmployeeDetails = (row) => {
    setSelectedEmp(row?.id);
    setSearchedEmp(row);
  };

  // View Service Details
  const viewServiceDetails = (id) => {
    setSelectedServiceId(id);
    setServicePageNo(1);
    setLoading(true);

    // service details
    getServiceDetails(id);
  };

  // get Service Details
  const getServiceDetails = (id) => {
    const rUrl = `${service_search_uri}${id}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSearchedService(responseData);
          // service Price Details
          getServicePriceDetails(id, responseData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  // get Service Price Details
  const getServicePriceDetails = (id, serviceData) => {
    const rUrl = `${External_PRICE}${id}`;
    callGetApi(
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          console.log("responsse data :::", responseData);
          // setSearchedServicePriceDetails(responseData);
          // if (searchedService) {
          //   console.log(searchedService);
          //   setSearchedServicePriceDetails((prevState) => ({
          //     ...prevState,
          //     vendorName: searchedService.supplyingVendorName,
          //   }));
          // }
        } else {
        }
      },
      (error) => {}
    );
  };

  // open Labor Add Modal
  const handleOpenLaborAddModal = () => {
    setLaborRecordId(null);
    setOpenLaborModal(true);
  };

  // open Employee Add Modal
  const handleOpenEmployeeAddModal = () => {
    setOpenEmployeeModal(true);
    setEmpRecordId(null);
  };
  // open Employee Edit Modal
  const handleOpenEmployeeEditModal = (row) => {
    setOpenEmployeeModal(true);
    setEmpRecordId(row?.id);
  };

  // open Labor Add Modal
  const handleOpenLaborViewModal = (row) => {
    setLaborRecordId(row?.id);
    setOpenLaborModal(true);
  };

  // open Service Add Modal
  const handleOpenServiceAddModal = () => {
    setServiceRecordId(null);
    setOpenServiceModal(true);
  };

  // open Service View Modal
  const handleOpenServiceViewModal = (row) => {
    setServiceRecordId(row?.id);
    setOpenServiceModal(true);
  };

  // select the value from the dropdown list
  const handleSelectDropdownItem = (currentItem, id) => {
    let _searchParts = [...searchParts];
    let obj = _searchParts[id];
    obj.inputSearch = currentItem[obj.fieldName?.value];
    obj.selectedOption = currentItem[obj.fieldName?.value];
    obj.dropdownOptions = [];
    _searchParts[id] = obj;
    setSearchParts([..._searchParts]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // search by fields Options filter
  const searchOptions = useCallback(() => {
    return serviceLocationSearchOptions.filter((item) => {
      return !searchParts.some((partItem) => item.value === partItem?.fieldName?.value);
    });
  }, [searchParts.length, serviceLocationSearchOptions]);

  //  select Seach By Field Name
  const handleSecltSeachType = (e) => {
    let tempArray = [...searchParts];
    let obj = {
      id: 0,
      fieldName: e,
      operator: "",
      inputSearch: "",
      selectedOption: "",
      dropdownOptions: [],
    };
    tempArray.push(obj);
    setSearchParts(tempArray);
  };

  // Reset the Seach filter
  const handleResetSearchFilter = () => {
    setSearchParts([]);
    // setRemoveFilter((pre) => pre + 1);
  };

  // Labor page 1 details
  const viewLaborPage_1 = () => {
    return (
      <>
        <div className="bg-white p-3 border-radius-10 overflow-hidden br-t">
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">JOB CODE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedLabour?.jobCode) ? "N/A" : searchedLabour?.jobCode}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICE DESCRIPTION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {/* {isEmpty(searchedLabour.description) ? "N/A" : searchedLabour?.description} */}
                  {isEmpty(searchedLabour.laborDescription) ? "N/A" : searchedLabour?.laborDescription}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICE TYPE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedLabour.serviceType) ? "N/A" : searchedLabour?.serviceType}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">LABOR TYPE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedLabour.laborType) ? "N/A" : searchedLabour?.laborType}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">CHARGE CODE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {isEmpty(searchedLabour.chargeCode) ? "N/A" : searchedLabour?.chargeCode}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">LABOR CODE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedLabour.laborCode) ? "N/A" : searchedLabour?.laborCode}
                  {/* {searchedLabour?.laborCode} */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">RECOMMENDED DURATION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedLabour.recommendedDuration) ? "N/A" : searchedLabour?.recommendedDuration}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">ACTUAL DURATION</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {isEmpty(searchedLabour.plannedDuration) ? "N/A" : searchedLabour?.plannedDuration}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ESTIMATED DURATION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedLabour.plannedDuration) ? "N/A" : searchedLabour?.plannedDuration}
                  {/* {searchedLabour?.plannedDuration} */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 border-radius-10 mt-4 mb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">MODEL</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {/* {isEmpty(searchedLabour.description) ? "N/A" : searchedLabour?.description} */}
                992K
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">FAMILY</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {/* {isEmpty(searchedLabour.description) ? "N/A" : searchedLabour?.description} */}
                TRACK EXCAVETER
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">CUSTOMER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedLabour.vendorId) ? "N/A" : searchedLabour?.vendorId}</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">RELATED JOB CODE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedLabour.jobCode) ? "N/A" : searchedLabour?.jobCode}</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">RELATED COMPONENT CODE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {searchedLabour.componentCodes.length === 0 ? "N/A" : searchedLabour.componentCodes[0].componentCode}
                {/* {isEmpty(searchedLabour.componentCodes) ? "N/A" : searchedLabour?.componentCodes} */}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const viewLaborPriceTable = useCallback(() => {
    return <DataGrid sx={GRID_STYLE} rows={searchedLaborPriceDetails} getRowId={(row) => row.id} columns={priceLaborColumns} pageSize={5} />;
  }, [refreshLaborTable, searchedLaborPriceDetails.length]);

  // Labor page 2 details
  const viewLaborPage_2 = () => {
    return (
      <>
        <h6 className="font-weight-500 pl-2 mt-5">Price</h6>
        <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6 className="font-weight-600 mb-0 mr-3">Price Details</h6>
            <a className="add-btn-a" onClick={() => setOpenLaborPriceModal(true)}>
              + Add New
            </a>
          </div>
          <div style={{ height: 280, width: "100%" }}>{viewLaborPriceTable()}</div>
        </div>
        <h6 className="font-weight-500 pl-2 mt-5">ERP Price</h6>
        <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6 className="font-weight-600 mb-0 mr-3">ERP Details</h6>
            <a className="add-btn-a">+ Add New</a>
          </div>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid sx={GRID_STYLE} rows={laborERPPrices} columns={erpLaborColumns} getRowId={(row) => row.id} pageSize={5} />
          </div>
        </div>
      </>
    );
  };

  // Service page 1 details
  const viewServicePage_1 = () => {
    return (
      <>
        <div className="bg-white p-3 border-radius-10 overflow-hidden br-t">
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ACTIVITY ID</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedService.activityId) ? "N/A" : searchedService.activityId.toUpperCase()}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ACTIVITY NAME</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedService.activityDescription) ? "N/A" : searchedService.activityDescription.toUpperCase()}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ACTIVITY DESCRIPTION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedService.longDescription) ? "N/A" : searchedService.longDescription.toUpperCase()}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SUPPLYING VENDOR</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedService.supplyingVendorName) ? "N/A" : searchedService.supplyingVendorName.toUpperCase()}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">UNIT OF MEASURE</p>
              {/* <p className="text-primary font-size-12 mt-1 font-weight-500">336D2 L</p> */}
              <p className="text-primary font-size-12 mt-1 font-weight-500">Time</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ESTIMATED HOURS/DAYS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">15 Hours</p>
                {/* <p className="text-primary font-size-12 mt-1 font-weight-500">3620656</p> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ACTIVITY DIMENSIONS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedService.dimension1) ? "N/A" : searchedService.dimension1.toUpperCase()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 border-radius-10 mt-4 mb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">MODEL</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">AA: 0S1619</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">FAMILY</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">AA: 0S1619</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">CUSTOMER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">AA: 0S1619</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">RELATED JOB CODE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">$ 90534</p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">RELATED COMPONENT CODE</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">Stock</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Service page 2 details
  const viewServicePage_2 = () => {
    return (
      <>
        <h6 className="font-weight-500 pl-2 mt-5">Price</h6>
        <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6 className="font-weight-600 mb-0 mr-3">Price Details</h6>
            <a className="add-btn-a">+ Add New</a>
          </div>
          <div style={{ height: 180, width: "100%" }}>
            <DataGrid sx={GRID_STYLE} rows={searchedServicePriceDetails} columns={priceServiceColumns} getRowId={(row) => row.id} pageSize={5} />
          </div>
        </div>
        <h6 className="font-weight-500 pl-2 mt-5">ERP Price</h6>
        <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h6 className="font-weight-600 mb-0 mr-3">ERP Details</h6>
            <a className="add-btn-a">+ Add New</a>
          </div>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid sx={GRID_STYLE} rows={erpItemsService} columns={erpLaborColumns} getRowId={(row) => row.itemName} pageSize={5} />
          </div>
        </div>
      </>
    );
  };
  // employe detials Page
  const emplpyeDtilsPage = () => {
    return (
      <>
        <div className="bg-white p-3 border-radius-10 overflow-hidden br-t">
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">HR NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.hrNumber) ? "N/A" : searchedEmp?.hrNumber}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">GENDER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.gender) ? "N/A" : searchedEmp?.gender === "M" ? "Male" : searchedEmp?.gender === "F" ? "Female" : "N/A"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">AGE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.age) ? "N/A" : searchedEmp?.age}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">DL NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.dlNumber) ? "N/A" : searchedEmp?.dlNumber}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">IDENTIFICATION NUMBER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.idNumber) ? "N/A" : searchedEmp?.idNumber}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">CONTACT NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.contactNumber) ? "N/A" : searchedEmp?.contactNumber}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ALTERNATIVE CONTACT NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.altContactNumber) ? "N/A" : searchedEmp?.altContactNumber}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">ADDRESS</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {isEmpty(searchedEmp?.plannedDuration) ? "N/A" : searchedEmp?.plannedDuration}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EMAIL</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.email) ? "N/A" : searchedEmp?.email}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">PREFERRED SERVICE AREA</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.preferedServiceArea) ? "N/A" : searchedEmp?.preferedServiceArea}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EXPERTISE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.expertise) ? "N/A" : searchedEmp?.expertise}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EXPERTISE LEVEL</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.expertiseLevel) ? "N/A" : searchedEmp?.expertiseLevel}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICE LOCATION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.serviceLevel) ? "N/A" : searchedEmp?.serviceLevel}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ONBOARD STATUS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.onboardStatus) ? "N/A" : searchedEmp?.onboardStatus}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">START DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.startDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.startDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">END DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.endDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.endDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">RETIRE DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.retriveDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.retriveDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">COMMENTS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.comments) ? "N/A" : searchedEmp?.comments}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />

      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h5 className="font-weight-600 mb-0 mt-3">Labor & Service Master</h5>
          <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Labor" value="1" />
                  <Tab label="Service" value="2" />
                  <Tab label="Employee" value="3" />
                  <Tab label="Service Location" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1" className="px-0">
                <p className="mb-1 mt-4 font-size-12">Select the search criteria for equipment</p>
                <EquipmentSearchMaster
                  falgType="labor"
                  searchFlag={SEARCH_FLAG_LABOUR}
                  setSearchList={setGlobalLaborList}
                  showBtn={true}
                  handleShowAddModal={handleOpenLaborAddModal}
                  chargeCodeList={chargeCodeList}
                  laborTypeList={laborTypeList}
                  serviceTypeList={serviceTypeList}
                  laborCodeList={laborCodeList}
                />
                <div className="row mt-3">
                  {globalLaborList.length != 0 && (
                    <LaborMasterSearchList searchList={globalLaborList} viewlaborDetails={viewlaborDetails} selectedLaborId={selectedLaborId} />
                  )}
                  {loading ? (
                    <LoadingProgress />
                  ) : (
                    <>
                      {selectedLaborId && (
                        <>
                          <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
                            <div className="">
                              <div className="bg-white p-3 border-radius-10 ">
                                <div className="d-flex align-items-center justify-content-between equipment-pagination">
                                  <h5 className="font-weight-600 mb-0">{searchedLabour?.description || "No Description"}</h5>
                                  <Stack spacing={2}>
                                    <Pagination
                                      boundaryCount={0}
                                      siblingCount={0}
                                      shape="rounded"
                                      hidePrevButton={laborPageNo === 1 && true}
                                      hideNextButton={laborPageNo === 2 && true}
                                      count={2}
                                      page={laborPageNo}
                                      onChange={handleLaborPageChange}
                                    />
                                  </Stack>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-block mt-3">
                                    <h6 className="text-primary font-weight-600">{searchedLabour?.chargeCode}</h6>
                                    <p className="text-light-60 font-size-12 mb-0">
                                      {searchedLabour?.laborType} - {searchedLabour?.serviceType}
                                    </p>
                                  </div>
                                  {laborPageNo === 1 && (
                                    <div className="d-flex align-items-center mx-1">
                                      <Tooltip title="Edit">
                                        <span className="upload-icon-button cursor" onClick={() => handleOpenLaborViewModal(searchedLabour)}>
                                          <EditOutlinedIcon sx={{ fontSize: 40 }} />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {laborPageNo === 1 && viewLaborPage_1()}
                              {laborPageNo === 2 && viewLaborPage_2()}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="2" className="px-0">
                <p className="mb-1 mt-4 font-size-12">Select the search criteria for equipment</p>
                <EquipmentSearchMaster
                  falgType="service"
                  searchFlag={SEARCH_FLAG_SERVICE}
                  setSearchList={setSearchList}
                  showBtn={true}
                  handleShowAddModal={handleOpenServiceAddModal}
                />
                <div className="row mt-3">
                  {searchList.length !== 0 && (
                    <ServiceMasterSearchList searchList={searchList} selectedServiceId={selectedServiceId} viewServiceDetails={viewServiceDetails} />
                  )}
                  <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
                    {loading ? (
                      <LoadingProgress />
                    ) : (
                      <>
                        {selectedServiceId && (
                          <>
                            <div className="">
                              <div className="bg-white p-3 border-radius-10 ">
                                <div className="d-flex align-items-center justify-content-between equipment-pagination">
                                  <h5 className="font-weight-600 mb-0">{searchedService?.activityDescription}</h5>
                                  <Stack spacing={2}>
                                    <Pagination
                                      boundaryCount={0}
                                      siblingCount={0}
                                      shape="rounded"
                                      hidePrevButton={servicePageNo === 1 && true}
                                      hideNextButton={servicePageNo === 2 && true}
                                      count={2}
                                      page={servicePageNo}
                                      onChange={handleServicePageChange}
                                    />
                                  </Stack>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-block mt-3">
                                    <h6 className="text-primary font-weight-600">{searchedService.activityId.toUpperCase()}</h6>
                                    <p className="text-light-60 font-size-12 mb-0">
                                      {searchedService.longDescription.toUpperCase()} - {searchedService.supplyingVendorName.toUpperCase()}
                                    </p>
                                  </div>
                                  {servicePageNo === 1 && (
                                    <div className="d-flex align-items-center mx-1">
                                      <Tooltip title="Edit">
                                        <span className="upload-icon-button cursor" onClick={() => handleOpenServiceViewModal(searchedService)}>
                                          <EditOutlinedIcon sx={{ fontSize: 40 }} />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {servicePageNo === 1 && viewServicePage_1()}
                              {servicePageNo === 2 && viewServicePage_2()}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3" className="px-0">
                <p className="mb-1 mt-4 font-size-12">Select the search criteria for employee</p>
                <EquipmentSearchMaster
                  falgType="employee"
                  searchFlag={SEARCH_FLAG_EMPLOYEE}
                  setSearchList={setEmpSearchList}
                  showBtn={true}
                  handleShowAddModal={handleOpenEmployeeAddModal}
                />
                <div className="row mt-3">
                  {empSearchList.length != 0 && (
                    <EmployeeMasterSearchList searchList={empSearchList} viewEmployeeDetails={viewEmployeeDetails} selectedEmp={selectedEmp} />
                  )}
                  {loading ? (
                    <LoadingProgress />
                  ) : (
                    <>
                      {selectedEmp && (
                        <>
                          <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
                            <div className="">
                              <div className="bg-white p-3 border-radius-10 ">
                                <div className="d-flex align-items-center justify-content-between equipment-pagination">
                                  <h5 className="font-weight-600 mb-0">{searchedEmp?.name}</h5>
                                  {/* <Stack spacing={2}>
                                    <Pagination
                                      boundaryCount={0}
                                      siblingCount={0}
                                      shape="rounded"
                                      hidePrevButton={laborPageNo === 1 && true}
                                      hideNextButton={laborPageNo === 2 && true}
                                      count={2}
                                      page={laborPageNo}
                                      onChange={handleLaborPageChange}
                                    />
                                  </Stack> */}
                                  <div className="d-flex align-items-center mx-1">
                                    <Tooltip title="Edit">
                                      <span className="upload-icon-button cursor" onClick={() => handleOpenEmployeeEditModal(searchedEmp)}>
                                        <EditOutlinedIcon sx={{ fontSize: 40 }} />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-block mt-3">
                                    <h6 className="text-primary font-weight-600">{searchedEmp?.empolyeeId}</h6>
                                    <p className="text-light-60 font-size-12 mb-0">
                                      {searchedEmp?.employeNumber} - {searchedEmp?.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {emplpyeDtilsPage()}
                              {/* {laborPageNo === 1 && viewLaborPage_1()}
                              {laborPageNo === 2 && viewLaborPage_2()} */}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="4" className="px-0">
                <div className="d-flex align-items-center justify-content-between my-4">
                  <h6 className="font-weight-600 font-size-16 mb-0 mr-3">Location Details</h6>
                  <a className="add-btn-a" onClick={() => setOpenLocationModal(true)}>
                    + Add New
                  </a>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12 col-sm-12">
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex align-items-center bg-primary border-radius-10 w-100 p-2">
                        <div className="d-flex mr-2" style={{ whiteSpace: "pre" }}>
                          <h5 className="mr-2 text-white mb-0">
                            <span>Search</span>
                          </h5>
                        </div>
                        <div className="d-flex align-items-center w-100 mr-4">
                          {searchParts.map((obj, i) => (
                            <div className="row align-items-center m-0">
                              <div
                                className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                                style={{ position: "relative", zIndex: 20 - i }}
                                key={"query" + i}
                              >
                                <div className="text-center p-2">
                                  <p className="my-0">{obj?.fieldName?.label}</p>
                                </div>
                                <div className="customselectsearch">
                                  <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder={`Search ${obj?.fieldName?.label}`}
                                    id={"inputSearch-" + i}
                                    value={obj.inputSearch}
                                    autoComplete="off"
                                  />

                                  {
                                    <ul className={`list-group customselectsearch-list scrollbar scrollbar-${i} style`} id="style">
                                      {obj.inputSearch.length !== 0 && obj.dropdownOptions.length === 0 && (
                                        <li className="list-group-item">No Result found</li>
                                      )}
                                      {obj.dropdownOptions.map((currentItem, j) => (
                                        <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItem(currentItem, i)}>
                                          {currentItem[obj.fieldName?.value]}
                                        </li>
                                      ))}
                                    </ul>
                                  }
                                </div>
                              </div>
                            </div>
                          ))}
                          {searchParts.length < 4 && (
                            <div className="mr-2">
                              <Select placeholder="Search By" options={searchOptions()} onChange={(e) => handleSecltSeachType(e)} value="Search By" />
                            </div>
                          )}
                          <div
                            className="btn border text-white cursor"
                            // onClick={handleSearchParts}
                          >
                            <span className="mr-2">
                              <SearchIcon />
                            </span>
                            SEARCH
                          </div>
                          <div onClick={handleResetSearchFilter}>
                            <a className="btn-sm text-white border mx-2 cursor" style={{ border: "1px solid #872FF7" }}>
                              <Tooltip title="Reset">
                                <CachedIcon />
                              </Tooltip>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DataTable
                  data={locationRecords}
                  columns={locationColumns}
                  customStyles={{
                    ...expendTableCustomStyle,
                    expanderCell: { style: { display: "none" } },
                    expanderButton: { style: { display: "none" } },
                  }}
                  expandableRows={true}
                  expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
                  expandableRowExpanded={(row) => row.id === expandedRowId}
                  onChangePage={(e) => setPartPageNo(e)}
                  onChangeRowsPerPage={(e) => {
                    setPartPageNo(1);
                    setPartsPerPage(e);
                  }}
                  paginationRowsPerPageOptions={[5, 10]}
                  paginationDefaultPage={partPageNo}
                  progressComponent={<LoadingProgress />}
                  persistTableHead
                  pagination
                />

                <Modal show={showViewLocationModal} onHide={handleClose} size="xl">
                  <Modal.Body>
                    <div className="row align-items-center text-end mb-2">
                      <div className="col-lg-12 d-flex justify-content-between align-items-center ">
                        <h5>Location Details</h5>
                        <button className="btn btn-primary float-end " onClick={toggleEditMode}>
                          {isEditing ? "View" : "Edit"}
                        </button>
                      </div>
                    </div>
                    {selectedRow && (
                      <div className="card border px-3 mb-2">
                        {isEditing ? (
                          <>
                            <div className="row input-fields mt-2">
                              {/* Location Name */}
                              <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">Location Name</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="locationName"
                                    placeholder="Location Name"
                                  />
                                </div>
                              </div>

                              {/* Location Type */}
                              <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">Location Type</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="locationType"
                                    placeholder="Location Type"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row input-fields mt-3">
                              {/* Service Group */}
                              <div className="col-md-4 col-sm-4">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">Service Group</label>
                                  <input
                                    type="text"
                                    className="form-control border-radius-10 text-primary"
                                    name="serviceGroup"
                                    placeholder="Service Group"
                                  />
                                </div>
                              </div>

                              {/* Address */}
                              <div className="col-md-8 col-sm-8">
                                <div className="form-group">
                                  <label className="text-light-dark font-size-12 font-weight-500">Address</label>
                                  <input type="text" className="form-control border-radius-10 text-primary" name="address" placeholder="Address" />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row mt-3">
                              <ReadOnlyField label="Location Name" value={selectedRow.locationName} className="col-md-6 col-sm-6" />

                              <ReadOnlyField label="Service Group" value={selectedRow.serviceGroup} className="col-md-6 col-sm-6" />
                              <ReadOnlyField label="Location Type" value={selectedRow.locationType} className="col-md-6 col-sm-6" />
                              <ReadOnlyField label="Address" value={selectedRow.address} className="col-md-6 col-sm-6" />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div className="row px-3" style={{ justifyContent: "right" }}>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>

      {openLocationModal && <LocationNewAddModal show={openLocationModal} hideModal={() => setOpenLocationModal(false)} onSubmit={addNewLocation} />}
      {showModal && (
        <LabourAndServiceReport
          show={showModal}
          hideModal={() => setShowModal(false)}
          headerTitle={modelHeaderTitle}
          contentReportType={modelContentReportType}
          contentReportObj={modelContentReportObj}
        />
      )}

      {openEmployeeModal && <EmployeeAddModal show={openEmployeeModal} hideModal={() => setOpenEmployeeModal(false)} recordId={empRecordId} />}

      {openEmployeeSearchModal && (
        <EmployeeSearchModal
          show={openEmployeeSearchModal}
          hideModal={() => setOpenEmployeeSearchModal(false)}
          handleSnack={handleSnack}
          selectedLocationRow={selectedLocationRow}
          locationRecords={locationRecords}
          setLocationRecords={setLocationRecords}
        />
      )}

      {openLaborModal && (
        <LaborMasterAddUpdateModal
          show={openLaborModal}
          hideModal={() => setOpenLaborModal(false)}
          recordId={laborRecordId}
          chargeCodeList={chargeCodeList}
          laborTypeList={laborTypeList}
          serviceTypeList={serviceTypeList}
          laborCodeList={laborCodeList}
        />
      )}

      {openLaborPriceModal && (
        <LaborPriceAddUpdate
          show={openLaborPriceModal}
          hideModal={() => {
            setOpenLaborPriceModal(false);
            setLaborPriceRecordId(null);
          }}
          recordId={laborPriceRecordId}
          laborRecord={searchedLabour}
          priceData={searchedLaborPriceDetails}
          setPriceData={setSearchedLaborPriceDetails}
          handleSnack={handleSnack}
        />
      )}

      {openServiceModal && (
        <ServiceMasterAddUpdateModal show={openServiceModal} hideModal={() => setOpenServiceModal(false)} recordId={serviceRecordId} />
      )}

      {/* {showModal && (
        <EquipmentReportDetails
          show={showModal}
          hideModel={() => setShowModal(false)}
          header={reportModalHeader}
          reportType={reportType}
        />
      )} */}
    </>
  );
};

export default ServiceMaster;
