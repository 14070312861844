import axios from "axios";
import { SYSTEM_ERROR } from "config/CONSTANTS";
import Cookies from "js-cookie";
import {
  CLAIM_VALUE_MASTER_URL,
  ITEM_DETAILS_SEARCH,
  PRICING_LABOUR_PRICE_SEARCH,
  SEARCH_COMPONENT_CODE,
  SEARCH_CONSUMABLE,
  SEARCH_CUSTOMER,
  SEARCH_EMPLOYEE_MASTER,
  SEARCH_EQUIPMENT,
  SEARCH_EXTWORK,
  SEARCH_JOB_CODE,
  SEARCH_MACHINE,
  SEARCH_SERVICE_LOCATION,
  SEARCH_SPAREPART,
  SEARCH_SPAREPART_MARGIN,
  SEARCH_VEHICLES_MASTER,
  SEARCH_VENDOR,
  SETTLEMENT_VALUE_MASTER_URL,
  validate_Coverage_Get_Url,
} from "./CONSTANTS";

/* ----------------- Authorization ------------------- */

var accessToken = localStorage.getItem("access_token");
var CookiesSetData = Cookies.get("loginTenantDtl");
var getCookiesJsonData;
if (CookiesSetData != undefined) {
  getCookiesJsonData = JSON.parse(CookiesSetData);
}
//  else {
//   getCookiesJsonData = {
//     access_token: "Bearer null",
//   }
// }
// var getCookiesJsonData = JSON.parse(CookiesSetData);
const headersData = {
  "content-type": "application/json",
  Accept: "application/json",
  // 'Authorization': accessToken != undefined ? accessToken : ''
  Authorization: CookiesSetData != undefined ? getCookiesJsonData?.access_token : "",
  // 'Authorization': url.Auth_Token
};

/* ------------------------------------------------------------ */

//Search Customer based on the search criteria to fill the header
export const customerSearch = (searchStr) => {
  console.log("RepairBuilder > customerSearch called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_CUSTOMER(searchStr), { headers: headersData })
        .then((res) => {
          console.log("customerSearch > axios res=", res);
          if (res.status === 200) resolve(res.data);
          else reject("Error occurred while fetching customer data");
        })
        .catch((err) => {
          console.log("customerSearch > axios err=", err);
          reject("Error in customerSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > customerSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search machine from equipment master based on the search criteria
export const machineSearch = (searchStr) => {
  console.log("RepairBuilder > machineSearch called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_MACHINE(searchStr), { headers: headersData })
        .then((res) => {
          console.log("machineSearch > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("machineSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > machineSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Spare Part based on the search criteria
export const sparePartSearch = async (searchStr) => {
  console.log("RepairBuilder > sparePartSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_SPAREPART(searchStr), { headers: headersData })
        .then((res) => {
          console.log("sparePartSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("sparePartSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > sparePartSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
//Search Item Details based on the search criteria

export const itemDetailsSearch = async (searchStr) => {
  console.log("RepairBuilder > sparePartSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(ITEM_DETAILS_SEARCH(searchStr), { headers: headersData })
        .then((res) => {
          console.log("sparePartSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("sparePartSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > sparePartSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Spare Part with margin based on the search criteria (Remove this after actual API is created)
export const sparePartSearchMargin = async (searchStr) => {
  console.log("RepairBuilder > sparePartSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_SPAREPART_MARGIN(searchStr), { headers: headersData })
        .then((res) => {
          console.log("sparePartSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("sparePartSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > sparePartSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search list of jobcodes based on entered jobcode
export const jobCodeSearch = (searchStr) => {
  console.log("RepairBuilder > jobCodeSearch called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_JOB_CODE(searchStr), { headers: headersData })
        .then((res) => {
          console.log("jobCodeSearch > axios res=", res);
          if (res.status === 200) resolve(res.data);
          else reject("Error occurred while fetching job codes");
        })
        .catch((err) => {
          console.log("jobCodeSearch > axios err=", err);
          reject("Error in jobCodeSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > jobCodeSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Function to get suggetions for component Codes
 */
export const getComponentCodeSuggetions = (query) => {
  console.log("coverageService > getComponentCodeSuggetions called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_COMPONENT_CODE(query), { headers: headersData })
        .then((res) => {
          console.log("getComponentCodeSuggetions > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getComponentCodeSuggetions > axios err=", err);
          reject("Error in getComponentCodeSuggetions axios!");
        });
    } catch (error) {
      console.error("in coverageService > getComponentCodeSuggetions, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

/**
 * Function to get suggetions for vendors
 */
export const getVendors = (query) => {
  console.log("SearchService > getVendors called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_VENDOR(query), { headers: headersData })
        .then((res) => {
          console.log("getVendors > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getVendors > axios err=", err);
          reject("Error in getVendors axios!");
        });
    } catch (error) {
      console.error("in SearchService > getVendors, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getConsumables = (query) => {
  console.log("SearchService > getConsumables called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_CONSUMABLE(query), { headers: headersData })
        .then((res) => {
          console.log("getConsumables > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getConsumables > axios err=", err);
          reject("Error in getConsumables axios!");
        });
    } catch (error) {
      console.error("in SearchService > getConsumables, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getExtWork = (query) => {
  console.log("SearchService > getExtWork called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_EXTWORK(query), { headers: headersData })
        .then((res) => {
          console.log("getExtWork > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getExtWork > axios err=", err);
          reject("Error in getExtWork axios!");
        });
    } catch (error) {
      console.error("in SearchService > getExtWork, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getValidateCoverage = (rObj) => {
  console.log("SearchService > getValidateCoverage called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(validate_Coverage_Get_Url, { params: rObj, headers: headersData })
        .then((res) => {
          console.log("getValidateCoverage > axios res=", res);
          resolve(res);
        })
        .catch((err) => {
          console.log("getValidateCoverage > axios err=", err);
          reject("Error in getValidateCoverage axios!");
        });
    } catch (error) {
      console.error("in SearchService > getValidateCoverage, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Equipment based on the search criteria
export const equipmentSearch = async (searchStr) => {
  console.log("searchServices > equipmentSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_EQUIPMENT(searchStr), { headers: headersData })
        .then((res) => {
          console.log("equipmentSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("equipmentSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > equipmentSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Claim value by claim value id
export const claimValueSearchById = async (id) => {
  console.log("searchServices > claimValueSearchById called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(`${CLAIM_VALUE_MASTER_URL}/${id}`, { headers: headersData })
        .then((res) => {
          console.log("claimValueSearchById > axios res=", res);
          resolve(res);
          // if (res.status === 200) {
          //     resolve(res.data);
          // } else {
          //     console.log("Status:", res.status);
          //     reject("Error in Search claim Value By Id axios!");
          // }
        })
        .catch((err) => {
          console.log("claimValueSearchById > axios err=", err);
          reject("Error in claim Value Search By Id axios!");
        });
    } catch (error) {
      console.error("in searchServices > claimValueSearchById, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search settlement value by claim value id
export const settlementValueSearchById = async (id) => {
  console.log("searchServices > settlementValueSearchById called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(`${SETTLEMENT_VALUE_MASTER_URL}/${id}`, { headers: headersData })
        .then((res) => {
          console.log("settlementValueSearchById > axios res=", res);
          resolve(res);
          // if (res.status === 200) {
          //     resolve(res.data);
          // } else {
          //     console.log("Status:", res.status);
          //     reject("Error in Search settlement Value By Id axios!");
          // }
        })
        .catch((err) => {
          console.log("settlementValueSearchById > axios err=", err);
          reject("Error in settlement Value Search By Id axios!");
        });
    } catch (error) {
      console.error("in searchServices > settlementValueSearchById, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search labour price
export const getLabourPrice = async (query) => {
  console.log("searchServices > getLabourPrice called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(PRICING_LABOUR_PRICE_SEARCH(query), { headers: headersData })
        .then((res) => {
          console.log("getLabourPrice > axios res=", res);
          resolve(res);
          // if (res.status === 200) {
          //     resolve(res.data);
          // } else {
          //     console.log("Status:", res.status);
          //     reject("Error in Search settlement Value By Id axios!");
          // }
        })
        .catch((err) => {
          console.log("getLabourPrice > axios err=", err);
          reject("Error in settlement Value Search By Id axios!");
        });
    } catch (error) {
      console.error("in searchServices > getLabourPrice, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Service Location based on the search criteria
export const serviceLocationSearch = async (searchStr) => {
  console.log("RepairBuilder > serviceLocationSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_SERVICE_LOCATION(searchStr), { headers: headersData })
        .then((res) => {
          console.log("serviceLocationSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("serviceLocationSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > serviceLocationSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Employee based on the search criteria
export const employeeSearch = async (searchStr) => {
  console.log("RepairBuilder > employeeSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_EMPLOYEE_MASTER(searchStr), { headers: headersData })
        .then((res) => {
          console.log("employeeSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("employeeSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > employeeSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
//Search Employee based on the search criteria
export const vehicleSearch = async (searchStr) => {
  console.log("RepairBuilder > vehicleSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_VEHICLES_MASTER(searchStr), { headers: headersData })
        .then((res) => {
          console.log("vehicleSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("vehicleSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > vehicleSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
