import React, { useState, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";

import $ from "jquery";
import Select from "react-select";
import { ProgressBar } from "react-bootstrap";

import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList, BarChart, Legend, PieChart, Pie, Cell, Label } from "recharts";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CardContent, Divider, Grid, LinearProgress, Tab, Typography, linearProgressClasses, styled } from "@mui/material";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import SelectBox from "pages/Common/SelectBox";
import {
    dataForInsights,
    dataForModels,
    dataForParts,
    dataTopCity,
    eachModelTop5,
    failureModeLines,
    failureOptions,
    modelOptions,
    partGroupsOptions,
    partsFailureLines,
    productFamilyOptions,
    regionLines,
    regionOptions,
    timePeriodOptions,
} from "../../warrantyManagement/warrantyMaster/warrantyConstants";

import {
    getCityDetails,
    getTop10FailedModes,
    getTop5ClaimedModel,
    getTop5ClaimedParts,
    getTop5PartsDetailsForCity,
    getTop5RegionData,
} from "../../warrantyManagement/warrantyMaster/WarrantyServices";

import ServiceCallNewModal from "../ServiceCall/ServiceCallNewModal";

import LoadingProgress from "pages/Repair/components/Loader";
import ProgressPie from "../../warrantyManagement/Analytics/ProgressPie";
import ShowInsights from "../../warrantyManagement/Analytics/ShowInsights";
import CostTrend from "../../warrantyManagement/Analytics/CostTrend";
import InsightLanding from "../../warrantyManagement/Analytics/InsightLanding";
import ClaimsTrend from "../../warrantyManagement/Analytics/ClaimsTrend";
import InputBoxSearchLoader from "pages/SolutionModules/use-case-4/useCase4Common/InputBoxSearchLoader";

const colors = ["#896dfe", "#6fa7ff", "#dd96ff", "#6fd4ff"];

const dataFor1 = [
    { name: "Rheem", value: 600000 },
    { name: "Solahart", value: 500000 },
    { name: "Paloma", value: 400000 },
    { name: "Vulcan", value: 300000 },
];
const dataFor2 = [
    { name: "Rheem", value: 3582 },
    { name: "Solahart", value: 2686 },
    { name: "Paloma", value: 1791 },
    { name: "Vulcan", value: 895 },
];

const ServiceHomeMaster = () => {
    const [activeTab, setActiveTab] = useState("general");
    const [models, setModels] = useState("");
    const [regions, setRegions] = useState("");
    const [timePeriod, setTimePeriod] = useState("");
    const [partGroups, setPartGroups] = useState("");
    const [productFamily, setProductFamily] = useState("");
    const [failureTypes, setFailureTypes] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [dataForTop10FailedModes, setDataForTop10FailedModes] = useState(null);
    const [dataForTop5Regions, setDataForTop5Regions] = useState(null);
    const [dataForTop5Parts, setDataForTop5Parts] = useState(null);
    const [dataForTop5Models, setDataForTop5Models] = useState(null);

    const [inputFailedPartSearch, setInputFailedPartSearch] = useState("");
    const [selctedOptionsForPart, setSelctedOptionsForPart] = useState("");
    const [searchedDropDownParts, setSearchedDropDownParts] = useState(null);
    const [searchedParts, setsearchedParts] = useState("");
    const [searchedPartsGraph, setSearchedPartsGraph] = useState("");

    const [inputRegion, setInputRegion] = useState("");
    const [searchedRegion, setsearchedRegion] = useState("");
    const [searchedDropDownRegion, setSearchedDropDownRegion] = useState(null);
    const [searchedDropDownRegionLoading, setSearchedDropDownRegionLoading] = useState(false);
    const [searchedRegionLoading, setSearchedRegionLoading] = useState(false);
    const [searchedRegionGraphData, setsearchedRegionGraphData] = useState("");

    const [inputInsightSearch, setInputInsightSearch] = useState("");
    const [searchedDropdownInsight, setSearchedDropdownInsight] = useState("");
    const [searchedInsight, setSearchedInsight] = useState("");

    const [openRequestCreateModal, setOpenRequestCreateModal] = useState(false);

    const renderLabel = (entry) => `${entry.value}`;

    // Snack Bar State
    const [severity, setSeverity] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    const handleSnackBarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnack(false);
    };

    const handleSnack = (snackSeverity, snackMessage) => {
        setSnackMessage(snackMessage);
        setSeverity(snackSeverity);
        setOpenSnack(true);
    };

    useEffect(() => {
        getFailureModeData();
        getRegionsData();
        getPartsData();
        getModelsData();
    }, []);

    // get top 10 failure modes data
    const getFailureModeData = () => {
        setIsLoading(true);
        getTop10FailedModes()
            .then((res) => {
                setDataForTop10FailedModes(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop10FailedModes([]);
                setIsLoading(false);
            });
    };

    // get top 5 regions data
    const getRegionsData = () => {
        setIsLoading(true);
        getTop5RegionData()
            .then((res) => {
                setDataForTop5Regions(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Regions([]);
                setIsLoading(false);
            });
    };

    // get top 5 Parts data
    const getPartsData = () => {
        setIsLoading(true);
        getTop5ClaimedParts()
            .then((res) => {
                setDataForTop5Parts(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Parts([]);
                setIsLoading(false);
            });
    };

    // get top 5 models
    const getModelsData = () => {
        setIsLoading(true);
        getTop5ClaimedModel()
            .then((res) => {
                setDataForTop5Models(res);
                setIsLoading(false);
            })
            .catch((err) => {
                setDataForTop5Models([]);
                setIsLoading(false);
            });
    };

    // get the Item for region from Selected Dropdown
    const handleSelectDropdownItemForRegion = (currentItem) => {
        setInputRegion(currentItem.city);
        $(`.scrollbar-${0}`).css("display", "none");
    };

    // get the Item for region Dropdown
    const handleDropDownSearchForRegion = (e) => {
        setSearchedDropDownRegionLoading(true);
        const inputValue = e.target.value;
        setInputRegion(inputValue);

        getCityDetails(inputValue)
            .then((res) => {
                setSearchedDropDownRegion(res);
                setSearchedDropDownRegionLoading(false);
                $(`.scrollbar-${0}`).css("display", "block");
            })
            .catch((err) => {
                setSearchedDropDownRegion([]);
                setSearchedDropDownRegionLoading(false);
                $(`.scrollbar-${0}`).css("display", "block");
            });
    };

    // search the region
    const handleSearchForRegion = () => {
        if (inputRegion) {
            setSearchedRegionLoading(true);
            const query = inputRegion;
            getCityDetails(query)
                .then((res) => {
                    setsearchedRegion(res[0]);
                })
                .catch((err) => {
                    setsearchedRegion([]);
                });
            getTop5PartsDetailsForCity(query)
                .then((res) => {
                    setsearchedRegionGraphData(res);
                })
                .catch((err) => {
                    setSearchedDropDownRegion([]);
                })
                .finally(() => {
                    setSearchedRegionLoading(false);
                });
        } else {
            setsearchedRegion(null);
            setsearchedRegionGraphData([]);
        }
    };

    // select option for part
    const handleSelectedOptionForPart = (selectedOption) => {
        setSelctedOptionsForPart(selectedOption);
        setInputFailedPartSearch("");
        setsearchedParts("");
    };

    // select the dropdown item for Parts
    const handleSelectDropdownItemForParts = (currentItem) => {
        if (selctedOptionsForPart.value === "partNumber") setInputFailedPartSearch(currentItem["part_no"]);
        else setInputFailedPartSearch(currentItem["model"]);
        $(`.scrollbar-${0}`).css("display", "none");
    };

    // dropdown search parts
    const handleDropDownSearchForParts = (e) => {
        const inputValue = e.target.value;
        setInputFailedPartSearch(inputValue);
        if (selctedOptionsForPart.value === "partNumber") {
            const searchedPartsList = dataForParts.filter((parts) => parts.part_no.toLowerCase().includes(inputValue.toLowerCase()));
            setSearchedDropDownParts(searchedPartsList);
        } else {
            const searchedModelList = dataForModels.filter((models) => models.model.toLowerCase().includes(inputValue.toLowerCase()));
            setSearchedDropDownParts(searchedModelList);
        }
        $(`.scrollbar-${0}`).css("display", "block");
    };

    // search for failed part
    const handleSearchForFailedPart = () => {
        if (inputFailedPartSearch) {
            const query = inputFailedPartSearch.toLowerCase();
            if (selctedOptionsForPart.value === "partNumber") {
                let filtered = dataForParts.filter((parts) => parts.part_no.toLowerCase().includes(query.toLowerCase()));
                setsearchedParts(filtered[0]);
            } else {
                let filtered = dataForModels.filter((models) => models.model.toLowerCase().includes(query.toLowerCase()));
                setsearchedParts(filtered[0]);
                filtered = eachModelTop5.filter((models) => models.model.toLowerCase().includes(query.toLowerCase()));
                setSearchedPartsGraph(filtered[0].data);
            }
        } else {
            setsearchedParts(null);
        }
    };

    //insght functions

    const handleDropDownSearchForInsights = (e) => {
        const inputValue = e.target.value;
        setInputInsightSearch(inputValue);

        const searchedInsghtList = dataForInsights.filter((insight) => insight.ques.toLowerCase().includes(inputValue.toLowerCase()));
        setSearchedDropdownInsight(searchedInsghtList);
        $(`.scrollbar-${0}`).css("display", "block");
    };

    const handleSelectDropdownItemForInsight = (currentItem) => {
        setInputInsightSearch(currentItem.ques);
        $(`.scrollbar-${0}`).css("display", "none");
    };

    const handleSearchForInsights = () => {
        if (inputInsightSearch) {
            const query = inputInsightSearch.toLowerCase();
            let filtered = dataForInsights.filter((insight) => insight.ques.toLowerCase().includes(query));
            setSearchedInsight(filtered);
        } else {
            setSearchedInsight(null);
        }
    };

    // view the general tab details
    const viewGeneralTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">General</h3>
                </div>
                <div style={{ minHeight: "884px" }}>
                    <div className="container-fluid">
                        <Box sx={{ mx: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <SelectBox
                                label={"Time Period"}
                                value={timePeriod}
                                options={timePeriodOptions}
                                handleChange={(e) => setTimePeriod(e.target.value)}
                                handleUnselect={() => setTimePeriod("")}
                                size={150}
                            />

                            <SelectBox
                                label={"All Models"}
                                value={models}
                                options={modelOptions}
                                handleChange={(e) => setModels(e.target.value)}
                                handleUnselect={() => setModels("")}
                                size={150}
                            />

                            <SelectBox
                                label={"Failure Classifications"}
                                value={failureTypes}
                                options={failureOptions}
                                handleChange={(e) => setFailureTypes(e.target.value)}
                                handleUnselect={() => setFailureTypes("")}
                                size={200}
                            />
                            <SelectBox
                                label={"Product Family"}
                                value={productFamily}
                                options={productFamilyOptions}
                                handleChange={(e) => setProductFamily(e.target.value)}
                                handleUnselect={() => setProductFamily("")}
                                size={150}
                            />
                            <SelectBox
                                label={"Part Group"}
                                value={partGroups}
                                options={partGroupsOptions}
                                handleChange={(e) => setPartGroups(e.target.value)}
                                handleUnselect={() => setPartGroups("")}
                                size={150}
                            />
                            <SelectBox
                                label={"Region"}
                                value={regions}
                                options={regionOptions}
                                handleChange={(e) => setRegions(e.target.value)}
                                handleUnselect={() => setRegions("")}
                                size={150}
                            />
                        </Box>

                        <Grid container spacing={3} sx={{ width: "100%", borderRadius: 5, marginBlock: 0 }}>
                            <Grid item lg={3} xs={12}>
                                <div
                                    className="card border"
                                    style={{ background: "#efefef" }} //#1F0D35 #543A8F
                                >
                                    <div className="ml-3 my-2 font-weight-600 font-size-14 text-uppercase text-muted">Total Claims Paid (CP)</div>
                                    <div className="ml-3 mb-2  font-weight-700 font-size-14">$1,800,000</div>
                                    <Divider style={{ background: "#CCC", width: "90%", margin: "auto" }} />
                                    <div className="ml-3 m-3 text-secondary font-weight-500 font-size-13">CP COVERAGE BY BRANDS</div>
                                    <PieChart width={400} height={268}>
                                        <Pie
                                            data={dataFor1}
                                            cx={140}
                                            cy={100}
                                            innerRadius={55}
                                            outerRadius={75}
                                            labelLine={false}
                                            label={renderLabel}
                                            paddingAngle={0}
                                            fill="#8884d8"
                                            dataKey="value"
                                            // cornerRadius={10}
                                        >
                                            {dataFor1.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend align="left" wrapperStyle={{ transform: "translate(0, -50%)", padding: "10px", borderRadius: "10px" }} />
                                    </PieChart>
                                </div>
                                <div className="card border my-2" style={{ background: "#efefef" }}>
                                    <div className="ml-3 my-2 font-weight-600 font-size-14 text-uppercase text-muted">Total Warranty Claims</div>
                                    <div className="ml-3 mb-2 font-weight-700 font-size-14">8954</div>
                                    <Divider style={{ background: "#CCC", width: "90%", margin: "auto" }} />
                                    <div className="ml-3 m-3 text-secondary font-weight-500 font-size-13 text-uppercase">Total Claims by Brand</div>
                                    <PieChart width={400} height={275}>
                                        <Pie
                                            data={dataFor2}
                                            cx={140}
                                            cy={100}
                                            innerRadius={55}
                                            outerRadius={75}
                                            fill="#8884d8"
                                            labelLine={false}
                                            paddingAngle={0}
                                            dataKey="value"
                                            label={renderLabel}
                                        >
                                            {dataFor2.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend align="left" wrapperStyle={{ transform: "translate(0, -50%)", padding: "10px", borderRadius: "10px" }} />
                                    </PieChart>
                                </div>
                            </Grid>
                            <Grid item lg={5} xs={12}>
                                <div className="card border" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
                                    <div className="my-2">
                                        <CostTrend />
                                    </div>
                                </div>
                                <div className="card border" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
                                    <div className="my-2">
                                        <ClaimsTrend />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item lg={4} xs={12}>
                                <div className="card border px-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
                                    <div className="my-2 text-muted text-uppercase">
                                        <span className="font-weight-600 font-size-12">Total warranty Costs Paid </span>
                                        <span className="font-weight-600" style={{ fontSize: "10" }}>
                                            {" "}
                                            (in millions AUD)
                                        </span>
                                    </div>
                                    <div className=" font-weight-700 font-size-14"> Part Causing Failures</div>
                                    {partsFailureLines.length !== 0 &&
                                        partsFailureLines.map((parts, i) => (
                                            <div className="row my-2" key={`${parts.partName}-${i}`}>
                                                <div className="col-md-4">
                                                    <span className="text-muted font-size-12 font-weight-500">{parts.partName}</span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Box sx={{ width: "100%", mr: 1 }}>
                                                            <ProgressBar variant="primary" now={parts.percentage * 100} style={{ height: 10, borderRadius: 5 }} />
                                                        </Box>
                                                        <span className="mx-2">{parts.percentage}m</span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">
                                            {"Show All >"}
                                        </a>
                                    </div>
                                </div>
                                <div className="card border px-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
                                    <div className="my-2 text-muted text-uppercase">
                                        <span className="font-weight-600 font-size-12">Total warranty Costs Paid </span>
                                        <span className="font-weight-600" style={{ fontSize: "10" }}>
                                            {" "}
                                            (in millions AUD)
                                        </span>
                                    </div>
                                    <div className="font-weight-700 font-size-14">Region</div>
                                    {regionLines.length !== 0 &&
                                        regionLines.map((region, i) => (
                                            <div className="row my-2" key={`${region.cityName}-${i}`}>
                                                <div className="col-md-4">
                                                    <span className="text-muted font-size-12 font-weight-500">{region.cityName}</span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Box sx={{ width: "100%", mr: 1 }}>
                                                            <ProgressBar variant="primary" now={region.percentage * 100} style={{ height: 10, borderRadius: 5 }} />
                                                        </Box>
                                                        <span className="mx-2">{region.percentage}m</span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">
                                            {"Show All >"}
                                        </a>
                                    </div>
                                </div>
                                <div className="card border px-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}>
                                    <div className="my-2 text-muted text-uppercase">
                                        <span className="font-weight-600 font-size-12">Total warranty Costs Paid </span>
                                        <span className="font-weight-600" style={{ fontSize: "10" }}>
                                            {" "}
                                            (in millions AUD)
                                        </span>
                                    </div>
                                    <div className="font-weight-700 font-size-14">Failure Mode</div>
                                    {failureModeLines.length !== 0 &&
                                        failureModeLines.map((modes, i) => (
                                            <div className="row my-2" key={`${modes.modeName}-${i}`}>
                                                <div className="col-md-4">
                                                    <span className="text-muted font-size-12 font-weight-500">{modes.modeName}</span>
                                                </div>
                                                <div className="col-md-8">
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Box sx={{ width: "100%", mr: 1 }}>
                                                            <ProgressBar variant="primary" now={modes.percentage * 100} style={{ height: 10, borderRadius: 5 }} />
                                                        </Box>
                                                        <span className="mx-2">{modes.percentage}m</span>
                                                    </Box>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="text-light font-size-12 font-weight-700 mb-1">
                                            {"Show All >"}
                                        </a>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    };

    // view Failure Mode Tab Content
    const viewFailureModesTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">Failure Modes</h3>
                </div>
                <div className="d-flex justify-content-center my-4">
                    <h5 className="font-weight-600 mt-3 mb-0 ">Top 10 Failure Modes</h5>
                </div>
                {isLoading ? (
                    <LoadingProgress />
                ) : (
                    <div className="d-flex justify-content-center align-items-center  mb-3">
                        <Card sx={{ borderRadius: 4, backgroundColor: "rgba(255,255,255,0.3)", margin: 5, padding: 4 }} variant="outlined">
                            <BarChart layout="vertical" width={900} height={550} data={dataForTop10FailedModes} margin={{ top: 20, right: 30, left: 50, bottom: 50 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis type="category" dataKey="failure_mode" width={150} tick={{ fontSize: 13, wordWrap: "break-word", whiteSpace: "normal" }} interval={0}>
                                    <Label
                                        value="Modes of Failure"
                                        position="insideLeft"
                                        angle={-90}
                                        offset={-25}
                                        fill="#333"
                                        className="font-size-16 font-weight-600"
                                        style={{ textAnchor: "middle" }}
                                    />
                                </YAxis>
                                <XAxis type="number" domain={[0, 60]} tickCount={5} tickFormatter={(value) => `${value}%`}>
                                    <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                </XAxis>
                                <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, "Count"]} labelStyle={{ display: "none" }} />
                                <Bar dataKey="percentage" fill="#6454EA" barSize={20}>
                                    <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                </Bar>
                            </BarChart>
                        </Card>
                    </div>
                )}
            </>
        );
    };

    const viewRegionTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">Region or Partners</h3>
                    {searchedRegion && (
                        <div
                            className="btn btn-primary"
                            onClick={() => {
                                setsearchedRegion("");
                                setInputRegion("");
                            }}
                        >
                            Back
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-center align-items-center  mr-4">
                    <div className=" align-items-center m-0">
                        <div className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder="Search by Region or Partners"
                                    value={inputRegion}
                                    autoComplete="off"
                                    style={{ width: "300px" }}
                                    onChange={handleDropDownSearchForRegion}
                                />
                                <div className="btn bg-primary text-white cursor" onClick={handleSearchForRegion}>
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {searchedDropDownRegionLoading ? (
                                    <InputBoxSearchLoader />
                                ) : (
                                    <ul className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`} id="style">
                                        {inputRegion.length !== 0 && searchedDropDownRegion.length === 0 && <li className="list-group-item">No Result found</li>}
                                        {inputRegion.length !== 0 &&
                                            searchedDropDownRegion.length !== 0 &&
                                            searchedDropDownRegion.map((currentItem, j) => (
                                                <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForRegion(currentItem)}>
                                                    {currentItem["city"]}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!searchedRegion ? (
                    <>
                        <div className="d-flex justify-content-center my-4">
                            <h5 className="font-weight-600 mt-3 mb-0 ">Top 5 Regions</h5>
                        </div>
                        {isLoading ? (
                            <LoadingProgress />
                        ) : (
                            <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
                                <Card sx={{ borderRadius: 4, backgroundColor: "rgba(255,255,255,0.3)", padding: 4 }} variant="outlined">
                                    <BarChart layout="vertical" width={800} height={450} data={dataForTop5Regions} margin={{ top: 20, right: 30, left: 30, bottom: 20 }}>
                                        <YAxis type="category" dataKey="city" width={100}>
                                            <Label value="Region" position="insideLeft" angle={-90} offset={-10} fill="#333" className="font-size-16 font-weight-600" />
                                        </YAxis>
                                        <XAxis type="number" domain={[0, 60]} tickCount={5} tickFormatter={(value) => `${value}%`}>
                                            <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                        </XAxis>
                                        <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, "Count"]} labelStyle={{ display: "none" }} />
                                        <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                            <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                        </Bar>
                                    </BarChart>
                                </Card>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {searchedRegionLoading ? (
                            <LoadingProgress />
                        ) : (
                            <div className="container my-4">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Card
                                            sx={{ borderRadius: 4, width: "100%", backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                                            variant="outlined"
                                        >
                                            <div className="text-light font-size-18 font-weight-600 my-2">{searchedRegion.city}</div>
                                            <div className="d-flex justify-content-center">
                                                <ProgressPie percentage={parseFloat(searchedRegion.percentage)} />
                                            </div>
                                            <div className="d-flex justify-content-center font-size-18 text-light font-weight-600">
                                                Claim Counts : {searchedRegion.claim_count}
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Card
                                            sx={{ borderRadius: 4, width: "100%", backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                                            variant="outlined"
                                        >
                                            <div className="text-light font-size-18 font-weight-600 my-2 ">{searchedRegion.city}</div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <BarChart
                                                    layout="vertical"
                                                    width={480}
                                                    height={275}
                                                    data={searchedRegionGraphData}
                                                    margin={{ top: 20, right: 0, left: -50, bottom: 10 }}
                                                >
                                                    <YAxis
                                                        type="category"
                                                        dataKey="part_name"
                                                        width={200}
                                                        tick={{ fontSize: 12, wordWrap: "break-word", whiteSpace: "normal", lineHeight: "15px" }}
                                                        interval={0}
                                                        axisLine={false}
                                                    />
                                                    <XAxis type="number" domain={[0, 50]} tickCount={3} tickFormatter={(value) => `${value}%`} width={300} />
                                                    <Tooltip
                                                        formatter={(value, name, props) => [`${props.payload.total_claims_in_city}`, "Count"]}
                                                        labelStyle={{ display: "none" }}
                                                    />
                                                    <Bar dataKey="claim_perc_in_city" fill="#6454EA" barSize={20}>
                                                        <LabelList dataKey="claim_perc_in_city" position="right" formatter={(value) => `${value}%`} />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };

    // view failed Parts Tab
    const viewFailedPartsTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">Failed Parts & Models</h3>
                    {searchedParts && (
                        <div
                            className="btn btn-primary"
                            onClick={() => {
                                setsearchedParts("");
                                setInputFailedPartSearch("");
                                setSelctedOptionsForPart("");
                            }}
                        >
                            Back
                        </div>
                    )}
                </div>

                <div className="d-flex justify-content-center align-items-center  mr-4">
                    <div className="row align-items-center m-0">
                        <div className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
                            <div>
                                <Select
                                    options={[
                                        { label: "Part No", value: "partNumber" },
                                        { label: "Model No", value: "modelNumber" },
                                    ]}
                                    onChange={handleSelectedOptionForPart}
                                    value={selctedOptionsForPart}
                                    placeholder="Search By"
                                />
                            </div>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder={`Search ${selctedOptionsForPart.label}`}
                                    value={inputFailedPartSearch}
                                    autoComplete="off"
                                    style={{ width: "250px" }}
                                    onChange={handleDropDownSearchForParts}
                                    disabled={selctedOptionsForPart === ""}
                                />
                                <div className="btn btn-md bg-primary text-white cursor" onClick={handleSearchForFailedPart}>
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {
                                    <ul className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`} id="style">
                                        {inputFailedPartSearch.length !== 0 && searchedDropDownParts.length === 0 && <li className="list-group-item">No Result found</li>}
                                        {inputFailedPartSearch.length !== 0 &&
                                            searchedDropDownParts.length !== 0 &&
                                            searchedDropDownParts.map((currentItem, j) => (
                                                <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForParts(currentItem)}>
                                                    {selctedOptionsForPart.value === "partNumber" ? currentItem["part_no"] : currentItem["model"]}
                                                </li>
                                            ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {!searchedParts ? (
                    isLoading ? (
                        <LoadingProgress />
                    ) : (
                        <div className="my-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: "rgba(255,255,255,0.3)" }} variant="outlined">
                                        <div className="d-flex justify-content-center my-4">
                                            <h5 className="font-weight-600 mt-3 mb-0 ">Top 5 Failed Parts</h5>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center  mb-3">
                                            <BarChart layout="vertical" width={500} height={350} data={dataForTop5Parts} margin={{ right: 30, left: 25, bottom: 20 }}>
                                                <YAxis
                                                    type="category"
                                                    dataKey="part_name"
                                                    width={100}
                                                    tick={{ fontSize: 13, wordWrap: "break-word", whiteSpace: "normal" }}
                                                    interval={0}
                                                >
                                                    <Label
                                                        value="Part Names"
                                                        position="insideLeft"
                                                        angle={-90}
                                                        offset={-20}
                                                        fill="#333"
                                                        className="font-size-16 font-weight-600"
                                                        textAnchor="center"
                                                    />
                                                </YAxis>
                                                <XAxis type="number" domain={[0, 60]} tickCount={5} tickFormatter={(value) => `${value}%`}>
                                                    <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                                </XAxis>
                                                <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, "Count"]} labelStyle={{ display: "none" }} />
                                                <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                                    <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-6">
                                    <Card sx={{ borderRadius: 4, width: "100%", backgroundColor: "rgba(255,255,255,0.3)" }} variant="outlined">
                                        <div className="d-flex justify-content-center my-4">
                                            <h5 className="font-weight-600 mt-3 mb-0 ">Top 5 Failed Models</h5>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center  mb-3">
                                            <BarChart layout="vertical" width={500} height={350} data={dataForTop5Models} margin={{ right: 30, left: 25, bottom: 20 }}>
                                                <YAxis
                                                    type="category"
                                                    dataKey="model"
                                                    width={100}
                                                    tick={{ fontSize: 13, wordWrap: "break-word", whiteSpace: "normal" }}
                                                    interval={0}
                                                >
                                                    <Label value="Model" position="insideLeft" angle={-90} offset={-20} fill="#333" className="font-size-16 font-weight-600" />
                                                </YAxis>
                                                <XAxis type="number" domain={[0, 30]} tickCount={4} tickFormatter={(value) => `${value}%`}>
                                                    <Label value="Percentage" position="insideBottom" offset={-15} fill="#333" className="font-size-16 font-weight-600" />
                                                </XAxis>
                                                <Tooltip formatter={(value, name, props) => [`${props.payload.claim_count}`, "Count"]} labelStyle={{ display: "none" }} />
                                                <Bar dataKey="percentage" fill="#6454EA" barSize={20} radius={[0, 5, 5, 0]}>
                                                    <LabelList dataKey="percentage" position="right" formatter={(value) => `${value}%`} />
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <>
                        <div className="container my-5">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <Card
                                        sx={{ borderRadius: 4, width: "100%", backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                                        style={{ width: 525, height: 328 }}
                                        variant="outlined"
                                    >
                                        <div className="text-light font-size-18 font-weight-600 mt-3 mb-2">
                                            {selctedOptionsForPart.value === "partNumber" ? "Mostly Failed At" : "Most Failed Parts"}
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <BarChart
                                                layout="vertical"
                                                width={480}
                                                height={275}
                                                data={selctedOptionsForPart.value === "partNumber" ? dataTopCity : searchedPartsGraph}
                                                margin={{ top: 20, right: 0, left: -70, bottom: 10 }}
                                            >
                                                <YAxis
                                                    type="category"
                                                    dataKey={selctedOptionsForPart.value === "partNumber" ? "city" : "part_name"}
                                                    width={220}
                                                    tick={{ fontSize: 12, wordWrap: "break-word", whiteSpace: "normal", lineHeight: "15px" }}
                                                    interval={0}
                                                    axisLine={false}
                                                />
                                                <XAxis type="number" domain={[0, 50]} tickCount={3} tickFormatter={(value) => `${value}%`} width={300} />
                                                <Tooltip
                                                    formatter={(value, name, props) => [
                                                        `${selctedOptionsForPart.value === "partNumber" ? props.payload.claim_count : props.payload.total_claims}`,
                                                        "Count",
                                                    ]}
                                                    labelStyle={{ display: "none" }}
                                                />
                                                <Bar dataKey="perc" fill="#6454EA" barSize={20}>
                                                    <LabelList dataKey="perc" position="right" formatter={(value) => `${value}%`} />
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Card
                                        sx={{ borderRadius: 4, backgroundColor: "rgba(255,255,255,0.3)", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                                        style={{ width: 525, height: 328 }}
                                        variant="outlined"
                                    >
                                        <div className="d-flex align-items-center mt-3">
                                            <span className="badge bg-danger rounded-circle" style={{ width: "10px", height: "10px" }}></span>
                                            <span className="ml-2 font-weight-500 font-size-18">Total Warranty Claimed</span>
                                            <span className="ml-auto mr-2 font-size-18 font-weight-500">
                                                {selctedOptionsForPart.value === "partNumber" ? `Part No : ${searchedParts.part_no}` : `Model: ${searchedParts.model}`}
                                            </span>
                                        </div>
                                        <CardContent className="d-flex flex-column justify-content-center h-100">
                                            <Typography variant="h1" component="div" className="display-4 font-weight-bold text-center text-light">
                                                {searchedParts.claim_count}
                                            </Typography>
                                            <div className="d-flex justify-content-center align-items-center my-3">
                                                <span className="mr-2 font-size-16 font-weight-500">Percentage:</span>
                                                <span className="font-size-16 font-weight-500" style={{ color: "#6C70FE" }}>
                                                    {searchedParts.perc}%
                                                </span>
                                            </div>
                                            {selctedOptionsForPart.value === "partNumber" && (
                                                <div className="text-center font-size-16 font-weight-500">
                                                    PartName: <span className="text-light">{searchedParts.part_name}</span>
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    // view Action insights tab contetnt
    const viewActionableInsightsTab = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                    <h3 className="font-weight-600 mb-0 ">Actionable Insights</h3>
                    {searchedInsight && (
                        <div
                            className="btn btn-primary"
                            onClick={() => {
                                setSearchedInsight("");
                                setInputInsightSearch("");
                            }}
                        >
                            Back
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-center align-items-center mr-4">
                    <div className="row align-items-center m-0">
                        <div className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`} style={{ position: "relative" }}>
                            <div className="customselectsearch customize">
                                <input
                                    className="custom-input-sleact pr-1"
                                    type="text"
                                    placeholder="Search by Symptoms"
                                    value={inputInsightSearch}
                                    autoComplete="off"
                                    style={{ width: "300px" }}
                                    onChange={handleDropDownSearchForInsights}
                                />
                                <div className="btn btn-md bg-primary text-white cursor" onClick={handleSearchForInsights}>
                                    <span className="mr-2">
                                        <SearchIcon />
                                    </span>
                                    SEARCH
                                </div>
                                {
                                    <ul className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`} id="style">
                                        {inputInsightSearch.length !== 0 && searchedDropdownInsight.length === 0 && <li className="list-group-item">No Result found</li>}
                                        {inputInsightSearch.length !== 0 &&
                                            searchedDropdownInsight.length !== 0 &&
                                            searchedDropdownInsight.map((currentItem, j) => (
                                                <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForInsight(currentItem)}>
                                                    {currentItem["ques"]}
                                                </li>
                                            ))}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Card
                    sx={{
                        borderRadius: 4,
                        height: "auto",
                        width: "93%",
                        margin: 4,
                        marginTop: 15,
                        backgroundColor: "rgba(255,255,255,0.3)",
                    }}
                    variant="outlined"
                >
                    <div>{searchedInsight ? <ShowInsights searchedInsight={searchedInsight} /> : <InsightLanding />}</div>
                </Card>
            </>
        );
    };

    return (
        <>
            <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
            <div className="content-body" style={{ minHeight: "884px" }}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
                        <h4 className="font-weight-600 mb-0">Service Home</h4>
                        <button className="btn bg-primary text-white" onClick={() => setOpenRequestCreateModal(true)}>
                            + New Request
                        </button>
                    </div>
                    <Card sx={{ borderRadius: 4, height: "auto", width: "100%", marginBottom: 2 }} variant="outlined">
                        <Box sx={{ width: "100%", height: "auto", marginTop: 2, minHeight: "884px" }}>
                            <TabContext value={activeTab}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList onChange={(e, tabValue) => setActiveTab(tabValue)} centered>
                                        <Tab label={`GENERAL`} value="general" />
                                        <Tab label={`FAILED PARTS & MODELS`} value="failedParts" />
                                        <Tab label={`REGION OR PARTNERS`} value="region" />
                                        <Tab label={`FAILURE MODES`} value="failureModes" />
                                        <Tab label={`ACTIONABLE INSIGHTS`} value="actionableInsights" />
                                    </TabList>
                                </Box>
                                <TabPanel value={activeTab} sx={{ minHeight: "884px", marginX: -2 }} className="mt-2 blue-white-gradient">
                                    {activeTab === "general" && viewGeneralTab()}
                                    {activeTab === "failureModes" && viewFailureModesTab()}
                                    {activeTab === "region" && viewRegionTab()}
                                    {activeTab === "failedParts" && viewFailedPartsTab()}
                                    {activeTab === "actionableInsights" && viewActionableInsightsTab()}
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Card>
                </div>
            </div>

            {openRequestCreateModal && <ServiceCallNewModal show={openRequestCreateModal} hideModal={() => setOpenRequestCreateModal(false)} handleSnack={handleSnack} />}
        </>
    );
};

export default ServiceHomeMaster;
