import React, { useCallback, useEffect, useMemo, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import $ from "jquery";
import Select from "react-select";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { EMPLOYEE_MASTER_URL, SERVICE_LOCATION_MASTER_URL } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import DataTable from "react-data-table-component";
import LoadingProgress from "pages/Repair/components/Loader";
import {
  expendTableCustomStyle,
  FILL_DATA_PROPERLY_ERROR_MESSAGE,
  INPUT_SEARCH_API_ERROR_MESSAGE,
  INPUT_SEARCH_ERROR_MESSAGE,
  serviceLocationSearchOptions,
} from "pages/Common/constants";
import { Tooltip } from "@mui/material";
import { isEmpty } from "pages/Common/textUtilities";
import { serviceLocationSearch } from "services/searchServices";
import LocationAddUpdateModal from "./LocationAddUpdateModal";
import ServiceEmployeeSearchModal from "./ServiceEmployeeSearchModal";
import EquipmentSearchMaster from "pages/MasterData/EquipmentSearchMaster";
import { SEARCH_FLAG_EMPLOYEE } from "pages/MasterData/equipmentMasterConstants";
import EmployeesSearchList from "./EmployeesSearchList";
import EmployeeAddUpdateModal from "./EmployeeAddUpdateModal";
import Moment from "react-moment";

// Define colors for the status
const inventoryStatusBgColor = {
  operational: "#dfffc9", // Light green background
  "not operational": "#c14e567a", // Light red background
};

const inventoryStatusColor = {
  operational: "#00b8b0", // Dark green text
  "not operational": "#c14e56", // Dark red text
};

const LocationAndEmployeeMaster = () => {
  const [tabValue, setTabValue] = useState("location");
  const [locationRecords, setLocationRecords] = useState([]);

  const [locationRow, setLocationRow] = useState(null);
  const [openEmployeeSearchModal, setOpenEmployeeSearchModal] = useState(false);

  const [searchLocations, setSearchLocations] = useState([]);

  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [locationPageNo, setLocationPageNo] = useState(1);
  const [locationPerPage, setLocationPerPage] = useState(5);
  const [locationRecordId, setLocationRecordId] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(0);

  const [empLoading, setEmpLoading] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [empSearchList, setEmpSearchList] = useState([]);
  const [searchedEmp, setSearchedEmp] = useState(null);

  const [empRecordId, setEmpRecordId] = useState(null);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getServiceLocations();
  }, [refreshFlag]);

  const handleRefresh = () => {
    setRefreshFlag(refreshFlag + 1);
  };

  // Fetch the service locations
  const getServiceLocations = useCallback(() => {
    const rUrl = `${SERVICE_LOCATION_MASTER_URL}?pageNumber=${0}&pageSize=${10}`;
    callGetApi(rUrl, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setLocationRecords(responseData);
      }
    });
  }, [refreshFlag]);

  // Tab Change Handler
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // select the value from the dropdown list
  const handleSelectDropdownItem = (currentItem, id) => {
    let _searchLocations = [...searchLocations];
    let obj = _searchLocations[id];
    obj.inputSearch = currentItem[obj.fieldName?.value];
    obj.selectedOption = currentItem[obj.fieldName?.value];
    obj.dropdownOptions = [];
    _searchLocations[id] = obj;
    setSearchLocations([..._searchLocations]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // search by fields Options filter
  const searchOptions = useCallback(() => {
    return serviceLocationSearchOptions.filter((item) => {
      return !searchLocations.some((partItem) => item.value === partItem?.fieldName?.value);
    });
  }, [searchLocations.length, serviceLocationSearchOptions]);

  //  select Seach By Field Name
  const handleSecltSeachType = (e) => {
    let tempArray = [...searchLocations];
    let obj = {
      id: 0,
      fieldName: e,
      operator: "",
      inputSearch: "",
      selectedOption: "",
      dropdownOptions: [],
    };
    tempArray.push(obj);
    setSearchLocations(tempArray);
  };

  // Reset the Seach filter
  const handleResetSearchFilter = () => {
    setSearchLocations([]);
  };

  // inpiut search for dropdown
  const handlePartsInputSearch = async (e, id) => {
    try {
      const { value } = e.target;
      let _searchLocations = [...searchLocations];
      let obj = _searchLocations[id];
      let dropdownResult = [];
      if (!isEmpty(obj.fieldName.value)) {
        if (e.target.value.length > 0) {
          let searchString = obj.fieldName.value + "~" + e.target.value;
          await serviceLocationSearch(searchString)
            .then((res) => {
              obj.dropdownOptions = res;
              obj.inputSearch = value;
              obj.selectedOption = "";
              _searchLocations[id] = obj;
              setSearchLocations([..._searchLocations]);
              $(`.scrollbar-${id}`).css("display", "block");
            })
            .catch((err) => {
              handleSnack("error", INPUT_SEARCH_API_ERROR_MESSAGE);
            });
        } else {
          obj.inputSearch = e.target.value;
          obj.selectedOption = "";
          obj.dropdownOptions = [];
          setSearchLocations([..._searchLocations]);
        }
      } else {
        handleSnack("info", INPUT_SEARCH_ERROR_MESSAGE);
      }

      obj.inputSearch = value;
      obj.selectedOption = "";
    } catch (error) {
      return;
    }
  };

  // search parts on click on search button
  const handleSearchLocation = async () => {
    try {
      if (searchLocations.length === 0) {
        handleSnack("info", "Please choose the Search by Filed first.");
        return;
      }
      let searchUrl = "";
      for (let i = 0; i < searchLocations.length; i++) {
        if (isEmpty(searchLocations[i].fieldName?.value) || isEmpty(searchLocations[i]?.dropdownOptions)) {
          handleSnack("info", FILL_DATA_PROPERLY_ERROR_MESSAGE);
          return;
        } else if (searchLocations[i]?.inputSearch.length > 0 && searchLocations[i]?.dropdownOptions.length > 0) {
          handleSnack("info", `Please select value from the dropdown list ${searchLocations[i]?.fieldName?.value}`);
          return;
        }
        searchUrl = searchUrl + `${searchLocations[i]?.fieldName?.value}:${searchLocations[i]?.inputSearch} AND `;
      }

      if (searchUrl.endsWith(" AND ")) {
        searchUrl = searchUrl.slice(0, -3).trim();
      }

      // setPartsLoading(true);
      await serviceLocationSearch(searchUrl)
        .then((res) => {
          if (res && res.length > 0) {
            // setPartsTotalRows(res.length);
            // setPartsRecords(res);
            // setPartsLoading(false);
            // setExpandedPartRowId(null);
            // setPartStockWareHouses([]);
            // setWareHouserPartsLoading(false);
          } else {
            // setPartsTotalRows(0);
            // setPartsRecords([]);
            // setPartsLoading(false);
            // setExpandedPartRowId(null);
            // setPartStockWareHouses([]);
            // setWareHouserPartsLoading(false);
          }
        })
        .catch((err) => {
          // setPartsTotalRows(0);
          // setPartsRecords([]);
          // setPartsLoading(false);
          // setExpandedPartRowId(null);
          // setPartStockWareHouses([]);
          // setWareHouserPartsLoading(false);
        });
    } catch (error) {}
  };

  // Toggle expand for Service location records
  const handleExpandToggle = (row) => {
    setExpandedRowId(expandedRowId === row.id ? null : row.id);
  };

  // View Location details
  const handleViewLocation = (row) => {
    setLocationRecordId(row?.id);
    setOpenLocationModal(true);
  };

  //
  const handleOpenEmpSearchModal = (row) => {
    setLocationRow(row);
    setOpenEmployeeSearchModal(true);
  };

  // Define columns for the location table
  const locationColumns = useMemo(
    () => [
      {
        name: "Location Name",
        selector: (row) => row.locationName,
        sortable: true,
        wrap: true,
        minWidth: 100,
      },
      {
        name: "Address",
        selector: (row) => row.address,
        sortable: true,
        wrap: true,
        minWidth: 100,
      },
      {
        name: "Location Type",
        selector: (row) => row.locationType.replace(/_/g, " "),
        sortable: true,
        wrap: true,
        minWidth: 100,
      },
      {
        name: "Service Group",
        selector: (row) => row.serviceGroup,
        sortable: false,
        wrap: true,
        minWidth: 100,
      },
      {
        name: "Total Employees",
        selector: (row) => row.totalEmployees,
        sortable: true,
        wrap: true,
        minWidth: 100,
        cell: (row) => (
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>{row.totalEmployees}</span>
            <button
              className={`border px-2 py-1 border-radius-10 ${row.id === expandedRowId ? "active-filter-warehouse" : ""}`}
              onClick={() => handleExpandToggle(row)}
            >
              {row.id === expandedRowId ? (
                <>
                  Hide <ExpandLessIcon className="font-size-32 mx-1" />
                </>
              ) : (
                <>
                  Expand <ExpandMoreIcon className="font-size-32 mx-1" />
                </>
              )}
            </button>
          </div>
        ),
      },
      {
        id: "stockStatus",
        name: "Status",
        selector: (row) => row.stockStatus,
        wrap: true,
        minWidth: 100,
        cell: (row) => (
          <div
            className="d-flex justify-content-between align-items-center py-2"
            style={{
              fontSize: 15,
              backgroundColor: row.available ? inventoryStatusBgColor["operational"] : inventoryStatusBgColor["not operational"],
              paddingInline: 5,
              paddingBlock: 2,
              borderRadius: 6,
              textTransform: "capitalize",
              color: row.available ? inventoryStatusColor["operational"] : inventoryStatusColor["not operational"],
            }}
          >
            <span
              style={{
                borderRadius: "50%",
                display: "inline-block",
                marginRight: 8,
                height: 10,
                width: 10,
                backgroundColor: row.available ? inventoryStatusColor["operational"] : inventoryStatusColor["not operational"],
              }}
            ></span>
            {row.available ? "Operational" : "Not operational"}
          </div>
        ),
      },
      {
        id: "actions",
        name: "Actions",
        cell: (row) => (
          <div className="d-flex align-items-center">
            <Tooltip title="View">
              <button className="btn btn p-0 mx-2" onClick={() => handleViewLocation(row)}>
                <VisibilityIcon />
              </button>
            </Tooltip>
            <Tooltip title="Search Employee">
              <button className="btn btn p-0 mx-2" onClick={() => handleOpenEmpSearchModal(row)}>
                <SearchIcon />
              </button>
            </Tooltip>
          </div>
        ),
        minWidth: 100,
        wrap: true,
      },
    ],
    [expandedRowId]
  );

  // Expanded row component for employee details
  const ExpandedComponent = ({ data }) => {
    const employeeColumns = [
      { name: "Employee ID", selector: (row) => row.employeeNumber },
      { name: "Name", selector: (row) => row.employeeName },
      { name: "Address", selector: (row) => row.address },
    ];

    return (
      <div style={{ padding: "10px", background: "#f8f9fa" }}>
        <DataTable title="Employee Details" columns={employeeColumns} data={data.masterEmployees} noHeader striped highlightOnHover pagination />
      </div>
    );
  };

  // open Employee Add Modal
  const handleOpenEmployeeAddModal = () => {
    setOpenEmployeeModal(true);
    // setEmpRecordId(null);
  };

  // open employee details modal
  const handleOpenEmpDtlsModal = (row) => {
    setOpenEmployeeModal(true);
    setEmpRecordId(row?.id);
  };

  // view employee details
  const viewEmployeeDetails = async (row) => {
    setSelectedEmp(row?.id);

    setEmpLoading(true);
    callGetApi(`${EMPLOYEE_MASTER_URL}/${row?.id}`, (response) => {
      if (response.status === API_SUCCESS) {
        const responseData = response.data;
        setSearchedEmp(responseData);
        setEmpLoading(false);
      } else {
        setSearchedEmp(null);
        setEmpLoading(false);
      }
    });
  };

  // view Service Location tab Content
  const viewLocationContent = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between my-4">
          <h6 className="font-weight-600 font-size-16 mb-0 mr-3">Location Details</h6>
          <a className="add-btn-a" onClick={() => setOpenLocationModal(true)}>
            + Add New
          </a>
        </div>
        <div className="row mb-4">
          <div className="col-md-12 col-sm-12">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex align-items-center bg-primary border-radius-10 w-100 p-2">
                <div className="d-flex mr-2" style={{ whiteSpace: "pre" }}>
                  <h5 className="mr-2 text-white mb-0">
                    <span>Search</span>
                  </h5>
                </div>
                <div className="d-flex align-items-center w-100 mr-4">
                  {searchLocations.map((obj, i) => (
                    <div className="row align-items-center m-0">
                      <div
                        className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                        style={{ position: "relative", zIndex: 20 - i }}
                        key={"query" + i}
                      >
                        <div className="text-center p-2">
                          <p className="my-0">{obj?.fieldName?.label}</p>
                        </div>
                        <div className="customselectsearch">
                          <input
                            className="custom-input-sleact pr-1"
                            type="text"
                            placeholder={`Search ${obj?.fieldName?.label}`}
                            id={"inputSearch-" + i}
                            value={obj.inputSearch}
                            autoComplete="off"
                            onChange={(e) => handlePartsInputSearch(e, i)}
                          />
                          {
                            <ul className={`list-group customselectsearch-list scrollbar scrollbar-${i} style`} id="style">
                              {obj.inputSearch.length !== 0 && obj.dropdownOptions.length === 0 && (
                                <li className="list-group-item">No Result found</li>
                              )}
                              {obj.dropdownOptions.map((currentItem, j) => (
                                <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItem(currentItem, i)}>
                                  {currentItem[obj.fieldName?.value]}
                                </li>
                              ))}
                            </ul>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                  {searchLocations.length < 4 && (
                    <div className="mr-2">
                      <Select placeholder="Search By" options={searchOptions()} onChange={(e) => handleSecltSeachType(e)} value="Search By" />
                    </div>
                  )}
                  <div className="btn border text-white cursor" onClick={handleSearchLocation}>
                    <span className="mr-2">
                      <SearchIcon />
                    </span>
                    SEARCH
                  </div>
                  <div onClick={handleResetSearchFilter}>
                    <a className="btn-sm text-white border mx-2 cursor" style={{ border: "1px solid #872FF7" }}>
                      <Tooltip title="Reset">
                        <CachedIcon />
                      </Tooltip>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={locationRecords}
          columns={locationColumns}
          customStyles={{
            ...expendTableCustomStyle,
            expanderCell: { style: { display: "none" } },
            expanderButton: { style: { display: "none" } },
          }}
          expandableRows={true}
          expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
          expandableRowExpanded={(row) => row.id === expandedRowId}
          onChangePage={(e) => setLocationPageNo(e)}
          onChangeRowsPerPage={(e) => {
            setLocationPageNo(1);
            setLocationPerPage(e);
          }}
          paginationRowsPerPageOptions={[5, 10]}
          paginationDefaultPage={locationPageNo}
          progressComponent={<LoadingProgress />}
          persistTableHead
          pagination
        />
      </>
    );
  };

  // employe detials Page
  const emplpyeDtilsPage = () => {
    return (
      <>
        <div className="bg-white p-3 border-radius-10 overflow-hidden br-t">
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">HR NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.hrNumber) ? "N/A" : searchedEmp?.hrNumber}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">GENDER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.gender) ? "N/A" : searchedEmp?.gender}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">AGE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.age) ? "N/A" : searchedEmp?.age}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">DL NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.dlNumber) ? "N/A" : searchedEmp?.dlNumber}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">IDENTIFICATION NUMBER</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">
                {isEmpty(searchedEmp?.identificationNumber) ? "N/A" : searchedEmp?.identificationNumber}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">CONTACT NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.contactNo) ? "N/A" : searchedEmp?.contactNo}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ALTERNATIVE CONTACT NUMBER</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.alternateContactNo) ? "N/A" : searchedEmp?.alternateContactNo}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <p className="text-light-60 font-size-12 m-0 font-weight-500">ADDRESS</p>
              <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.address) ? "N/A" : searchedEmp?.address}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EMAIL</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.email) ? "N/A" : searchedEmp?.email}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">PREFERRED SERVICE AREA</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.preferServiceArea) ? "N/A" : searchedEmp?.preferServiceArea}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EXPERTISE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.expertise) ? "N/A" : searchedEmp?.expertise}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">EXPERTISE LEVEL</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.expertLevel) ? "N/A" : searchedEmp?.expertLevel}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">SERVICE LOCATION</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.serviceLocation) ? "N/A" : searchedEmp?.serviceLocation}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">ONBOARD STATUS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {isEmpty(searchedEmp?.onBoardStatus) ? "N/A" : searchedEmp?.onBoardStatus}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">START DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.hireDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.hireDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">END DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.endDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.endDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">RETIRE DATE</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">
                  {searchedEmp?.retireDate ? <Moment format="DD/MM/YYYY">{searchedEmp?.retireDate}</Moment> : "NA"}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
              <div className="d-block">
                <p className="text-light-60 font-size-12 m-0 font-weight-500">COMMENTS</p>
                <p className="text-primary font-size-12 mt-1 font-weight-500">{isEmpty(searchedEmp?.comments) ? "N/A" : searchedEmp?.comments}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // view Employee tab Content
  const viewEmployeeContent = () => {
    return (
      <>
        <p className="mb-1 mt-4 font-size-12">Select the search criteria for employee</p>
        <EquipmentSearchMaster
          falgType="employee"
          searchFlag={SEARCH_FLAG_EMPLOYEE}
          setSearchList={setEmpSearchList}
          showBtn={true}
          handleShowAddModal={handleOpenEmployeeAddModal}
        />
        <div className="row mt-3">
          {empSearchList.length != 0 && (
            <EmployeesSearchList searchList={empSearchList} viewEmployeeDetails={viewEmployeeDetails} selectedEmp={selectedEmp} />
          )}
          <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
            {empLoading ? (
              <LoadingProgress />
            ) : (
              <>
                {selectedEmp && (
                  <>
                    <div className="">
                      <div className="bg-white p-3 border-radius-10 ">
                        <div className="d-flex align-items-center justify-content-between equipment-pagination">
                          <h5 className="font-weight-600 mb-0">{searchedEmp?.employeeName}</h5>
                          <div className="d-flex align-items-center mx-1">
                            <Tooltip title="Edit">
                              <span className="upload-icon-button cursor" onClick={() => handleOpenEmpDtlsModal(searchedEmp)}>
                                <EditOutlinedIcon sx={{ fontSize: 40 }} />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-block mt-3">
                            <h6 className="text-primary font-weight-600">{searchedEmp?.employeeNumber}</h6>
                            <p className="text-light-60 font-size-12 mb-0">
                              {searchedEmp?.employeeNumber} - {searchedEmp?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                      {emplpyeDtilsPage()}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-baseline mt-4">
            <h5 className="font-weight-600 mb-0">Location & Employee</h5>
          </div>
          <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label="Service Location" value="location" />
                  <Tab label="Employee" value="employee" />
                </TabList>
              </Box>
              <TabPanel value="location" className="px-0">
                {viewLocationContent()}
              </TabPanel>
              <TabPanel value="employee" className="px-0">
                {viewEmployeeContent()}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      {openLocationModal && (
        <LocationAddUpdateModal
          show={openLocationModal}
          hideModal={() => {
            setOpenLocationModal(false);
            setLocationRecordId(null);
          }}
          handleSnack={handleSnack}
          recordId={locationRecordId}
          handleRefresh={handleRefresh}
        />
      )}

      {openEmployeeSearchModal && (
        <ServiceEmployeeSearchModal
          show={openEmployeeSearchModal}
          hideModal={() => {
            setOpenEmployeeSearchModal(false);
            setLocationRow(null);
          }}
          handleSnack={handleSnack}
          locationRow={locationRow}
          locationRecords={locationRecords}
          handleRefresh={handleRefresh}
          // setLocationRecords={setLocationRecords}
        />
      )}

      {openEmployeeModal && <EmployeeAddUpdateModal show={openEmployeeModal} hideModal={() => setOpenEmployeeModal(false)} recordId={empRecordId} />}
    </>
  );
};

export default LocationAndEmployeeMaster;
