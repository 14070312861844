import React, { useEffect, useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { Divider, FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import SearchBox from "pages/Common/SearchBox";
import { customerSearch, machineSearch } from "services/searchServices";
import { FONT_STYLE } from "pages/Common/constants";
import { Switch_label_Object } from "../equipmentMasterConstants";
import { equipmentMasterRequestObj } from "../masterConstents";
import { ca } from "date-fns/locale";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { DATA_SVC_EQUIPMENT } from "services/CONSTANTS";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import Moment from "react-moment";
import LoadingProgress from "pages/Repair/components/Loader";

const addressDTOObj = {
  id: 0,
  addressId: 0,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  createdBy: "",
  updatedBy: "",
};

const EquipmentMasterAddUpdateModal = ({ show, hideModal, recordId = null, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...equipmentMasterRequestObj });

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);
  const [searchModelResults, setSearchModelResults] = useState([]);
  const [noOptionsModel, setNoOptionsModel] = useState(false);
  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  const [machineData, setMachineData] = useState({
    maker: "",
    model: "",
    serialNo: "",
    smu: "",
    fleetNo: "",
    equipmentNumber: "",
    family: "",
  });

  const [customerData, setCustomerData] = useState({
    customerId: "",
    customerName: "",
    contactEmail: "",
    contactPhone: "",
  });

  const [addressRecord, setAddressRecord] = useState({ ...addressDTOObj });

  useEffect(() => {
    if (recordId) {
      setViewModeOn(true);
      setLoading(true);
      const rUrl = `${DATA_SVC_EQUIPMENT()}/${recordId}`;
      callGetApi(
        rUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            console.log("response data ::: ", responseData);
            setMachineData({
              maker: responseData.maker,
              model: responseData.model,
              serialNo: responseData.makerSerialNumber,
              smu: responseData.sensorId,
              fleetNo: responseData.stockNumber,
              equipmentNumber: responseData.equipmentNumber,
            });

            setAddressRecord({ ...responseData["addressDTO"] });

            if (responseData?.currentClient) {
              getCustomerDetails(responseData?.currentClient);
            }

            setRecordData({ ...responseData });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // get the customer details
  const getCustomerDetails = async (customerId) => {
    await customerSearch(`customerId` + ":" + customerId).then((result) => {
      if (result && result.length > 0) {
        setCustomerData({
          ...customerData,
          customerId: result[0].customerId,
          contactEmail: result[0].email,
          contactName: result[0].contactName,
          customerGroup: result[0].customerGroup,
          customerName: result[0].fullName,
          customerSegment: result[0].customerSegment,
          country: result[0].addressDTO?.country,
          regionOrState: result[0].addressDTO?.regionOrState,
        });
      } else {
      }
    });
  };

  // Machine search based on model and serial number
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchModelResults([]);
    setSearchSerialResults([]);

    if (searchMachinefieldName === "model") {
      machineData.model = searchText;
      searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
    } else if (searchMachinefieldName === "serialNo") {
      machineData.serialNo = searchText;
      searchQueryMachine = searchText
        ? machineData.model
          ? `model:${machineData.model} %26%26 makerSerialNumber~` + searchText
          : "makerSerialNumber~" + searchText
        : "";
    }
    if (searchQueryMachine) {
      await machineSearch(searchQueryMachine)
        .then((result) => {
          if (result) {
            if (searchMachinefieldName === "model") {
              if (result && result.length > 0) {
                setSearchModelResults(result);
                setNoOptionsModel(false);
              } else {
                setNoOptionsModel(true);
              }
            } else if (searchMachinefieldName === "serialNo") {
              if (result && result.length > 0) {
                setSearchSerialResults(result);
                setNoOptionsSerial(false);
              } else {
                setNoOptionsSerial(true);
              }
            }
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the machine!");
        });
    } else {
      searchMachinefieldName === "model" ? setSearchModelResults([]) : setSearchSerialResults([]);
    }
  };

  // Select machine from the search result
  const handleModelSelect = (type, currentItem) => {
    if (type === "model") {
      setMachineData({
        ...machineData,
        maker: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        equipmentNumber: currentItem.equipmentNumber,
      });

      setRecordData({
        ...recordData,
        maker: currentItem?.maker,
        model: currentItem?.model,
        engineModal: currentItem?.engineModal,
        geocode: currentItem?.geocode,
        movedInDate: currentItem?.movedInDate || new Date(),
        previousLocation: currentItem?.previousLocation,
        newLocation: currentItem?.newLocation,
        movedInOrOutFlag: currentItem?.movedInOrOutFlag,
      });

      setSearchModelResults([]);
    } else if (type === "makerSerialNumber") {
      setMachineData({
        ...machineData,
        maker: currentItem.maker,
        model: currentItem.model,
        serialNo: currentItem.makerSerialNumber,
        smu: currentItem.sensorId,
        fleetNo: currentItem.stockNumber,
        // warrantyId: currentItem.warrantyId,
        equipmentNumber: currentItem.equipmentNumber,
      });

      setCustomerData({ ...customerData, customerId: currentItem.currentClient, customerName: currentItem.customer });
      setSearchSerialResults([]);
    }
  };

  // change the input text field value
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  // customer fields text change
  const handleCustomerTextChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  // change the input text field value
  const handleSelectValueChange = (e, keyName) => {
    setRecordData({ ...recordData, [keyName]: e });
  };

  // Search Customer with customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    customerData.customerId = searchText;
    customerData.id = "";
    customerData.firstName = "";
    customerData.lastName = "";
    customerData.fullName = "";
    customerData.email = "";
    customerData.primaryContact = "";

    setAddressRecord({ ...addressDTOObj });

    // setAddressRecord({ ...addressDTOObj });

    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // select customer
  const handleCustSelect = (type, currentItem) => {
    setCustomerData({
      ...customerData,
      customerId: currentItem.customerId,
      contactEmail: currentItem.email,
      contactName: currentItem.contactName,
      customerGroup: currentItem.customerGroup,
      customerName: currentItem.fullName,
      customerSegment: currentItem.customerSegment,
      country: currentItem.addressDTO?.country,
      regionOrState: currentItem.addressDTO?.regionOrState,
    });

    setAddressRecord({ ...currentItem?.addressDTO });
    setSearchCustResults([]);
  };

  // click on submit button
  const handleSubmit = () => {
    const rObj = { ...recordData, ...customerData, addressDTO: { ...addressRecord } };
    // const rObj = {
    //   ...recordData,
    //   ...customerData,
    //   addressDTO: {
    //     id: 3,
    //     addressId: 3,
    //     addressLine1: "",
    //     addressLine2: "",
    //     addressLine3: "",
    //     fullAddress: "L 12, Kings Park Rd, West Perth, WESTERN AUSTRALIA, 6005 Australia ",
    //     district: "Kings Park Rd",
    //     regionOrState: "Perth",
    //     country: "AU",
    //     createdAt: "2022-07-12T01:46:16.129711",
    //     updatedAt: "2022-07-12T01:46:16.129711",
    //     createdBy: "Admin",
    //     updatedBy: "Admin",
    //   },
    // };

    const rUrl = DATA_SVC_EQUIPMENT();

    if (recordData?.id) {
      callPutApi(null, `${rUrl}/${recordData?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          handleSnack("success", "Equipment Updated Successfully!");
          hideModal();
        } else {
          handleSnack("error", "Something went wrong!");
        }
      });
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData.id });
            handleSnack("success", "Equipment Created Successfully!");
            hideModal();
          } else {
            handleSnack("error", "Something went wrong!");
          }
        },
        (error) => {}
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4>{recordId ? "Update" : "Add"} Equipment</h4>
            {recordData?.id && (
              <Tooltip title="Edit">
                <span className="upload-icon-button cursor" onClick={() => setViewModeOn(false)}>
                  <EditOutlinedIcon sx={{ fontSize: 40 }} />
                </span>
              </Tooltip>
            )}
          </div>
          {loading ? (
            <LoadingProgress />
          ) : (
            <>
              <div className="card border px-3">
                {!viewModeOn ? (
                  <div className="row input-fields mt-3">
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="maker"
                          placeholder="Manufacturer"
                          value={machineData?.maker}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                        <SearchBox
                          value={machineData.model}
                          onChange={(e) => handleMachineSearch("model", e.target.value)}
                          type="model"
                          result={searchModelResults}
                          onSelect={handleModelSelect}
                          noOptions={noOptionsModel}
                          placeholder="Model"
                        />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ENGINE MODEL</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="engineModal"
                          placeholder="Engine Model"
                          value={recordData?.engineModal}
                          onChange={handleInputTextChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">OPERATUNG WEIGHT</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="operatingWeight"
                          placeholder="Operating Weight"
                          value={recordData?.operatingWeight}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">NET FLYWHEEL POWER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="netFlyWheelPower"
                          placeholder="Net Flywheel Power"
                          value={recordData?.netFlyWheelPower}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <ReadOnlyField className="col-md-4 col-sm-4" label="MANUFACTURER" value={machineData?.maker} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="MODEL" value={machineData?.model} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="ENGINE MODEL" value={recordData?.engineModal} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="OPERATUNG WEIGHT" value={recordData?.operatingWeight} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="NET FLYWHEEL POWER" value={recordData?.netFlyWheelPower} />
                  </div>
                )}
              </div>
              <h5>Customer Details</h5>
              <div className="card border px-3">
                {!viewModeOn ? (
                  <div className="row input-fields mt-3">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group w-100">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER ID</label>
                        <SearchBox
                          value={customerData.customerId}
                          onChange={(e) => handleCustSearch("customerId", e.target.value)}
                          type="customerId"
                          result={searchCustResults}
                          onSelect={handleCustSelect}
                          noOptions={noOptionsCust}
                          placeholder="Customer Id"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="customerName"
                          value={customerData?.customerName}
                          placeholder="Customer Name"
                          onChange={handleCustomerTextChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONTACT PERSON</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="contactName"
                          value={customerData?.contactName}
                          placeholder="Contact Person"
                          onChange={handleCustomerTextChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER GROUP</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="customerGroup"
                          value={customerData?.customerGroup}
                          placeholder="Customer Group"
                          onChange={handleCustomerTextChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER SEGMENT</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="customerSegment"
                          value={customerData?.customerSegment}
                          placeholder="Customer Segment"
                          onChange={handleCustomerTextChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">LAST OWNER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="lastOwner"
                          value={recordData?.lastOwner}
                          onChange={handleInputTextChange}
                          placeholder="Last Owner"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CUSTOMER ID" value={customerData?.customerId} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CUSTOMER NAME" value={customerData?.customerName} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CONTACT PERSON" value={customerData?.contactName} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CUSTOMER GROUP" value={customerData?.customerGroup} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CUSTOMER SEGMENT" value={customerData?.customerSegment} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="LAST OWNER" value={recordData?.lastOwner} />
                  </div>
                )}
              </div>
              <h5>Site Details</h5>
              <div className="card border px-3 mb-3">
                {!viewModeOn ? (
                  <div className="row input-fields mt-3">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group w-100">
                        <label className="text-light-dark font-size-12 font-weight-500">FLEET NUMBER</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="fleetNumber"
                          placeholder="Fleet Number"
                          value={recordData?.fleetNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONTACT ADDRESS</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="contactAddress"
                          placeholder="Contact Address"
                          value={recordData?.contactAddress}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">GEO CODES</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="geocode"
                          placeholder="GEO Codes"
                          value={recordData?.geocode}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PRIMARY CONTACT</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="contact"
                          placeholder="Primary Contact"
                          value={recordData?.contact}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">
                          <span className=" mr-2">MOVED IN DATE</span>
                        </label>
                        <div className="align-items-center date-box">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              inputFormat="dd/MM/yyyy"
                              className="form-controldate border-radius-10"
                              // minDate={generalData.claimRequestDate}
                              // maxDate={new Date()}
                              closeOnSelect
                              value={recordData.movedInDate}
                              onChange={(e) => handleSelectValueChange(e, "movedInDate")}
                              renderInput={(params) => (
                                <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">PREVIOUS LOCATION</label>
                        <FormGroup>
                          <FormControlLabel
                            style={{ alignItems: "start", marginLeft: 0 }}
                            control={
                              <Switch
                                checked={recordData.previousLocation ? true : false}
                                onChange={(e) => setRecordData({ ...recordData, previousLocation: e.target.checked })}
                              />
                            }
                            labelPlacement="top"
                            // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                          />
                        </FormGroup>
                        {/* <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="previousLocation"
                    placeholder="Previous Location"
                    value={recordData?.previousLocation}
                    onChange={handleInputTextChange}
                  /> */}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">NEW LOCATION</label>
                        <FormGroup>
                          <FormControlLabel
                            style={{ alignItems: "start", marginLeft: 0 }}
                            control={
                              <Switch
                                checked={recordData.newLocation ? true : false}
                                onChange={(e) => setRecordData({ ...recordData, newLocation: e.target.checked })}
                              />
                            }
                            labelPlacement="top"
                            // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                          />
                        </FormGroup>
                        {/* <input
                    type="text"
                    className="form-control border-radius-10 text-primary"
                    name="newLocation"
                    placeholder="New Location"
                    value={recordData?.newLocation}
                    onChange={handleInputTextChange}
                  /> */}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">
                          <span className=" mr-2">MOVED IN/OUT</span>
                        </label>
                        <FormGroup>
                          <FormControlLabel
                            style={{ alignItems: "start", marginLeft: 0 }}
                            control={
                              <Switch
                                checked={recordData.movedInOrOutFlag ? true : false}
                                onChange={(e) => setRecordData({ ...recordData, movedInOrOutFlag: e.target.checked })}
                              />
                            }
                            labelPlacement="top"
                            // label={<span className="text-light-dark font-size-12 font-weight-500">REPLACEMENT</span>}
                          />
                        </FormGroup>
                        {/* <div className="equipment-switch">
                    <Switch
                      // {...Switch_label_Object}
                      checked={recordData.movedInOrOutFlag ? true : false}
                      onChange={(e) => setRecordData({ ...recordData, movedInOrOutFlag: e.target.checked })}
                    />
                  </div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <ReadOnlyField className="col-md-4 col-sm-4" label="FLEET NUMBER" value={recordData?.fleetNumber} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="CONTACT ADDRESS" value={recordData?.contactAddress} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="GEO CODES" value={customerData?.geocode} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="PRIMARY CONTACT" value={customerData?.contact} />
                    <ReadOnlyField
                      className="col-md-4 col-sm-4"
                      label="MOVED IN DATE"
                      value={customerData?.movedInDate ? <Moment format="DD/MM/YYYY">{customerData?.movedInDate}</Moment> : "NA"}
                    />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="PREVIOUS LOCATION" value={customerData?.previousLocation ? "YES" : "NO"} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="NEW LOCATION" value={customerData?.newLocation ? "YES" : "NO"} />
                    <ReadOnlyField className="col-md-4 col-sm-4" label="MOVED IN/OUT" value={customerData?.movedInOrOutFlag ? "YES" : "NO"} />
                  </div>
                )}
              </div>
              {!viewModeOn ? (
                <div className="row px-3" style={{ justifyContent: "right" }}>
                  <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                    Cancel
                  </button>
                  <button className="btn bg-primary text-white" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              ) : (
                <div className="row px-3" style={{ justifyContent: "right" }}>
                  <button className="btn bg-primary text-white" onClick={hideModal}>
                    Close
                  </button>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EquipmentMasterAddUpdateModal;
