import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import { Divider, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { CONS_EXT_PRICE_OPTIONS, CONSUMABLE_SEARCH_Q_OPTIONS, GRID_STYLE } from "../CONSTANTS";
import {
  AddConsumableItem,
  AddConsumableToService,
  AddServiceHeader,
  FetchConsumableforService,
  FetchConsumableItems,
  RemoveConsumableItem,
} from "services/repairBuilderServices";
import SearchComponent from "../components/SearchComponent";
import { getConsumables, getVendors } from "services/searchServices";
import MaintenanceBuilderConsItemAddModal from "./MaintenanceBuilderConsItemAddModal";

const initialConsQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const MaintenanceBuilderConsModal = ({
  show,
  hideModal,
  operationIndex,
  segmentIndex,
  operationData,
  segmentItems,
  setSegmentItems,
  materialItems,
  setMaterialItems,
  consumableTypeList,
  generalData,
  pricingData,
  isMaterialItemEdit = false,
  isMaterialItemEditRow,
  handleSaveMaterialEditRow,
  handleSnack,
}) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const initialConsumableItemData = {
    consumableType: "",
    consumableCode: "",
    description: "",
    quantity: "",
    unitOfMeasure: "",
    supplyingVendorCode: "",
    supplyingVendorName: "",
    vendor: "",
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    usagePercentage: 100,
    // currency: operationData?.currency || "USD",
    currency: pricingData?.currency?.value || "USD",
  };

  // Consumable Header
  const [consumableData, setConsumableData] = useState({
    jobCode: operationData?.jobCode || "",
    jobCodeDescription: operationData?.jobCodeDescription || "",
    pricingMethod: "",
    totalPrice: 0.0,
    payer: "",
    flatRateIndicator: false,
    adjustedPrice: 0.0,
    basePrice: 0.0,
    percentageOfBase: 0,
  });
  const [consumableViewOnly, setConsumableViewOnly] = useState(false);
  let [consumableItems, setConsumableItems] = useState([]);

  const [masterData, setMasterData] = useState([]);
  const [searchResultConsOpen, setSearchResultConsOpen] = useState(false);
  const [queryConsSearchSelector, setQueryConsSearchSelector] = useState(initialConsQuery);

  const [consumableItemData, setConsumableItemData] = useState(initialConsumableItemData);
  const [consumableItemOpen, setConsumableItemOpen] = useState(false);

  const [noOptionsConsumable, setNoOptionsConsumable] = useState(false);
  const [searchConsumableResult, setSearchConsumableResult] = useState([]);

  const [noOptionsVendor, setNoOptionsVendor] = useState(false);
  const [searchVenodrResults, setSearchVendorResults] = useState([]);

  useEffect(() => {
    if (!isMaterialItemEdit) {
      if (operationData?.serviceEstimateId) {
        if (operationData?.consumableDetails) {
          FetchConsumableforService(operationData?.serviceEstimateId)
            .then((resultConsumable) => {
              if (resultConsumable && resultConsumable.id) {
                setConsumableData({
                  ...resultConsumable,
                  id: resultConsumable.id,
                  pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === resultConsumable.pricingMethod),
                  totalPrice: resultConsumable.totalPrice ? resultConsumable.totalPrice : 0,
                });
                populateConsItems(resultConsumable);
                setConsumableViewOnly(true);
              }
            })
            .catch((e) => {
              setConsumableData({
                ...consumableData,
                jobCode: operationData?.jobCode || "",
                jobCodeDescription: operationData.jobCodeDescription || "",
              });
            });
        }
      }
    } else {
      openConsumableRow(isMaterialItemEditRow);
    }
  }, [isMaterialItemEdit, operationData?.serviceEstimateId, operationData?.consumableDetails]);

  function populateConsItems(result) {
    FetchConsumableItems(result.id)
      .then((resultConsumableItems) => {
        if (resultConsumableItems && resultConsumableItems.result?.length > 0) {
          setConsumableItems(resultConsumableItems.result);
          //   const _segmentItems = { ...segmentItems };
          //   const _segments = _segmentItems["segments"];
          //   const selectedSegment = _segments[segmentIndex];
          //   const _operations = selectedSegment["operations"][operationIndex];

          //   const ids = resultConsumableItems.result.map((item) => item.id);
          //   _operations["consumableDetails"]["consumableItems"] = ids;
          //   setSegmentItems({ ..._segmentItems });
        }
      })
      .catch((e) => {
        handleSnack("error", "Error occurred while fetching consumable items");
      });
  }

  // Add or Update consumable data
  const updateConsumableHeader = () => {
    if (operationData?.serviceEstimateId) {
      let data = {
        ...(consumableData.id && { id: consumableData.id }),
        jobCode: consumableData.jobCode,
        jobCodeDescription: consumableData.jobCodeDescription,
        payer: consumableData.payer,
      };
      AddConsumableToService(operationData?.serviceEstimateId, data)
        .then((result) => {
          setConsumableData({
            ...result,
            id: result.id,
            pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
          });

          const _segmentItems = { ...segmentItems };
          const _segments = _segmentItems["segments"];
          const selectedSegment = _segments[segmentIndex];
          const _operations = selectedSegment["operations"][operationIndex];
          if (!_operations["consumableDetails"]) {
            _operations["consumableDetails"] = { ...result, consumableItems: [] };
            // _operations["consumableDetails"]["consumableItems"].push(result?.id);
            setSegmentItems({ ..._segmentItems });
          }
          handleSnack("success", "Consumable details updated!");
          setConsumableViewOnly(true);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating consumable data!");
        });
    } else {
      let estimateData = {
        reference: segmentItems?.builderId || "RO000174",
        description: generalData?.description || "992K Warranty",
        segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
        currency: pricingData?.currency?.value || "USD",
        jobCode: operationData?.jobCode || "JB0361",
        id: null,
        jobCodeDescription: operationData?.jobCodeDescription || "Description114",
        componentCode: operationData?.componentCode || "ABC123",
        componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
      };
      AddServiceHeader(operationData?.id, estimateData)
        .then((estimateResult) => {
          let data = {
            ...(consumableData.id && { id: consumableData.id }),
            jobCode: consumableData.jobCode,
            jobCodeDescription: consumableData.jobCodeDescription,
            payer: consumableData.payer,
          };
          AddConsumableToService(estimateResult?.id, data)
            .then((result) => {
              setConsumableData({
                ...result,
                id: result.id,
                pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
              });

              const _segmentItems = { ...segmentItems };
              const _segments = _segmentItems["segments"];
              const selectedSegment = _segments[segmentIndex];
              const _operations = selectedSegment["operations"][operationIndex];
              _operations["serviceEstimateId"] = estimateResult?.id;
              if (!_operations["consumableDetails"]) {
                _operations["consumableDetails"] = { ...result, consumableItems: [] };
              }
              setSegmentItems({ ..._segmentItems });
              handleSnack("success", "Consumable details updated!");
              setConsumableViewOnly(true);
            })
            .catch((err) => {
              handleSnack("error", "Error occurred while updating consumable data!");
            });
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while updating consumable data!");
          // handleSnack("error", "Error occurred while updating service estimate header!");
        });
    }
  };

  // Close consumable search modal
  const handleSearchResConsClose = () => {
    setSearchResultConsOpen(false);
  };

  // Once parts are selected to add clear the search results
  const clearFilteredData = () => {
    setMasterData([]);
  };

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    if (consumableData.id) {
      $(".scrollbar").css("display", "none");
      var searchStr = "";
      var querySearchSelector = queryConsSearchSelector;
      querySearchSelector.map(function (item, i) {
        if (i === 0 && item.selectCategory?.value && item.inputSearch) {
          searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        } else if (item.selectCategory?.value && item.inputSearch && item.selectOperator?.value) {
          searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
        } else {
          searchStr = "";
        }
        return searchStr;
      });

      try {
        if (searchStr) {
          if (type === "consumables") {
            const res = await getConsumables(searchStr);
            setMasterData(res);
            setSearchResultConsOpen(true);
          }
        } else {
          handleSnack("info", "Please fill the search criteria!");
        }
      } catch (err) {
        handleSnack("error", "Error occurred while fetching results!");
      }
    } else {
      handleSnack("warning", "Please update the Material details!");
    }
  };

  // Add the selected parts from search result to consumable
  const selectConsumableItem = async (selectedData) => {
    setSearchResultConsOpen(false);
    setConsumableItemData({
      ...consumableItemData,
      consumableCode: selectedData.consumableId,
      description: selectedData.name,
      consumableType: consumableTypeList.find((element) => element.value === selectedData.stockItem),
      supplyingVendorCode: selectedData.sourceOrVendor,
      supplyingVendorName: selectedData.sourceOrVendor,
      unitOfMeasure: selectedData.unit,
    });
    setConsumableItemOpen(true);
  };

  // close the consumable item Create Modal
  const handleConsumableItemClose = () => {
    setConsumableItemOpen(false);
    setSearchVendorResults([]);
    setConsumableItemData(initialConsumableItemData);
    if (isMaterialItemEdit) {
      handleSaveMaterialEditRow(consumableData.id);
    }
  };

  // Open consumable item to view or edit
  const openConsumableRow = (row) => {
    if (isMaterialItemEdit) {
      const _segmentItems = { ...segmentItems };
      const _segments = _segmentItems["segments"];
      const selectedSegment = _segments[segmentIndex];
      const _operations = selectedSegment["operations"][operationIndex];
      setConsumableData({
        ...consumableData,
        id: _operations["consumableDetails"]["id"],
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData.jobCodeDescription || "",
      });
    }
    setConsumableItemData({
      ...row,
      consumableType: consumableTypeList.find((element) => element.value === row.consumableType),
      unitPrice: row.unitPrice ? row.unitPrice : 0,
      extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
      totalPrice: row.totalPrice ? row.totalPrice : 0,
    });
    setConsumableItemOpen(true);
  };

  //Remove Consumable Item
  const handleDeleteConsumableItem = (consItemId) => {
    RemoveConsumableItem(consumableData.id, consItemId)
      .then((res) => {
        handleSnack("success", res);

        const _consumableItems = consumableItems.filter((item) => item?.id !== consItemId);
        setConsumableItems(_consumableItems);

        const _materialItems = materialItems["result"].filter((item) => item?.id !== consItemId);
        setMaterialItems({ result: [..._materialItems], totalRows: _materialItems.length });

        const _segmentItems = { ...segmentItems };
        const _segments = _segmentItems["segments"];
        const selectedSegment = _segments[segmentIndex];
        const _operations = selectedSegment["operations"][operationIndex];
        _operations["consumableDetails"]["consumableItems"].filter((item) => item !== consItemId);
        setSegmentItems({ ..._segmentItems });
      })
      .catch((e) => {
        console.log(e);
        handleSnack("error", "Error occurred while removing the consumable item");
      });
  };

  const columnsConsumables = [
    {
      field: "consumableCode",
      headerName: "Consumable ID",
      flex: 1,
      width: 70,
    },
    {
      field: "consumableType",
      headerName: "Type",
      flex: 1,
      width: 70,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      width: 130,
    },
    { field: "quantity", headerName: " Quantity", flex: 1, width: 130 },
    {
      field: "unitOfMeasure",
      headerName: "Unit of measure",
      flex: 1,
      width: 130,
    },
    { field: "supplyingVendorCode", headerName: "Vendor", flex: 1, width: 130 },
    { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
    {
      field: "extendedPrice",
      headerName: "Extended price",
      flex: 1,
      width: 130,
    },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    { field: "usagePercentage", headerName: "% Usage", flex: 1, width: 130 },
    { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => openConsumableRow(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            onClick={() => handleDeleteConsumableItem(row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  // Search table column for consumable
  const columnsConsumableSearch = [
    { headerName: "Consumable Id", field: "consumableId", flex: 1, width: 70 },
    { headerName: "Name", field: "name", flex: 1, width: 130 },
    {
      headerName: "Supplying Vendor",
      field: "sourceOrVendor",
      flex: 1,
      width: 130,
    },
  ];

  // Search Vendors
  const handleVendorSearch = async (searchVendorfieldName, searchText) => {
    setSearchVendorResults([]);
    if (searchVendorfieldName === "consVendor") {
      consumableItemData.supplyingVendorName = searchText;
    }
    if (searchText) {
      await getVendors("fullName~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchVendorResults(result);
            setNoOptionsVendor(false);
          } else {
            setNoOptionsVendor(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the vendor!");
        });
    }
  };

  // Select the vendor from search result
  const handleVendorConsSelect = (type, currentItem) => {
    setConsumableItemData({
      ...consumableItemData,
      supplyingVendorName: currentItem.fullName,
      supplyingVendorCode: currentItem.customerId,
    });
    setSearchVendorResults([]);
  };

  // Search Consumable
  const handleConsumableSearch = async (searchConsumablefieldName, searchText) => {
    setSearchConsumableResult([]);
    consumableItemData.consumableCode = searchText;
    if (searchText) {
      await getConsumables("consumableId~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchConsumableResult(result);
            setNoOptionsConsumable(false);
          } else {
            setNoOptionsConsumable(true);
          }
        })
        .catch((e) => {
          console.log(e);
          handleSnack("error", "Error occurred while searching the Consumable!");
        });
    }
  };

  // Select the consumable from search result
  const handleConsumableSelect = (type, currentItem) => {
    setConsumableItemData({
      ...consumableItemData,
      consumableCode: currentItem.consumableId,
      description: currentItem.name,
      unitOfMeasure: currentItem.unit,
    });
    setSearchConsumableResult([]);
  };

  // Add or Update Consumable Item
  const addConsumableItem = () => {
    if (consumableData.id) {
      let data = {
        ...(consumableItemData.id && { id: consumableItemData.id }),
        consumableType: consumableItemData.consumableType?.value,
        supplyingVendorCode: consumableItemData.supplyingVendorCode,
        supplyingVendorName: consumableItemData.supplyingVendorName,
        consumableCode: consumableItemData.consumableCode,
        description: consumableItemData.description,
        quantity: consumableItemData.quantity,
        unitOfMeasure: consumableItemData.unitOfMeasure,
        usagePercentage: consumableItemData.usagePercentage,
        currency: consumableItemData.currency,
      };

      AddConsumableItem(consumableData.id, data)
        .then((result) => {
          setConsumableItemData(initialConsumableItemData);

          const _consumableItems = [...consumableItems];
          const conItemsIndex = consumableItems.findIndex((item) => item.id === result.id);
          if (conItemsIndex !== -1) {
            _consumableItems[conItemsIndex] = result;
          } else {
            _consumableItems.push(result);
          }
          setConsumableItems((pre) => [..._consumableItems]);

          const _materialItems = [...materialItems["result"]];
          const materialItemsIndex = _materialItems.findIndex((item) => item.id === result.id);
          if (materialItemsIndex !== -1) {
            _materialItems[materialItemsIndex] = result;
          } else {
            _materialItems.push(result);
          }
          setMaterialItems({ result: [..._materialItems], totalRows: _materialItems.length });

          const _segmentItems = { ...segmentItems };
          const _segments = _segmentItems["segments"];
          const selectedSegment = _segments[segmentIndex];
          const _operations = selectedSegment["operations"][operationIndex];
          let optconsItemIndex = _operations["consumableDetails"]["consumableItems"].findIndex((item) => item === result.id);
          if (optconsItemIndex === -1) {
            _operations["consumableDetails"]["consumableItems"].push(result?.id);
          }
          setSegmentItems({ ..._segmentItems });

          handleSnack("success", `${optconsItemIndex === -1 ? "Added" : "Updated"} consumable item successfully`);
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while adding consumable item!");
        });
      handleConsumableItemClose();
      setQueryConsSearchSelector(initialConsQuery);
    } else {
      handleSnack("warning", "Please update the consumable header details!");
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="d-flex align-items-center p-3">
          <h4 className="mb-0">{title}</h4>
          <a className="btn-sm cursor mx-2" onClick={() => setConsumableViewOnly(false)}>
            <EditIcon sx={{ fontSize: 29 }} />
          </a>
        </div>
        <div className="px-3">
          <Divider />
        </div>
        <Modal.Body className="p-0 bg-white">
          <div className="px-3">
            {!consumableViewOnly ? (
              <div className="row mt-2 input-fields">
                <div className="col-md-4 col-sm-4">
                  <div class="form-group mt-3">
                    <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCode} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div class="form-group mt-3">
                    <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCodeDescription} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4"></div>
                <div className="col-md-4 col-sm-4">
                  <div class="form-group mt-3">
                    <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                    <input
                      type="text"
                      className="form-control border-radius-10 text-primary"
                      value={consumableData.payer}
                      onChange={(e) => setConsumableData({ ...consumableData, payer: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div class="form-group mt-3">
                    <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                    <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.totalPrice} />
                    <div className="css-w8dmq8">*Mandatory</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div class="form-group mt-3 mb-0 text-right">
                    <button type="button" className="btn btn-light bg-primary text-white" onClick={updateConsumableHeader}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mt-4">
                <ReadOnlyField label="JOB CODE" value={consumableData.jobCode} className="col-md-4 col-sm-4" />
                <ReadOnlyField label="JOB CODE DESCRIPTION" value={consumableData.jobCodeDescription} className="col-md-4 col-sm-4" />
                <ReadOnlyField label="PAYER" value={consumableData.payer} className="col-md-4 col-sm-4" />

                <ReadOnlyField label="PRICE METHOD" value={consumableData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                <ReadOnlyField label="NET PRICE" value={consumableData.totalPrice} className="col-md-4 col-sm-4" />
              </div>
            )}

            <div className="mt-2 mb-4">
              <div className="bg-primary px-3 mb-3 border-radius-6">
                <div className="row align-items-center">
                  <div className="col-10 mr-5">
                    <div className="d-flex align-items-center bg-primary w-100">
                      <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                        <h5 className="mr-2 mb-0 text-white">
                          <span>Consumables</span>
                        </h5>
                      </div>
                      <SearchComponent
                        querySearchSelector={queryConsSearchSelector}
                        setQuerySearchSelector={setQueryConsSearchSelector}
                        clearFilteredData={clearFilteredData}
                        handleSnack={handleSnack}
                        searchAPI={getConsumables}
                        type={"consumables"}
                        searchClick={handleQuerySearchClick}
                        options={CONSUMABLE_SEARCH_Q_OPTIONS}
                        color={"white"}
                        buttonText={"ADD ITEM"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <DataGrid sx={GRID_STYLE} rows={consumableItems} columns={columnsConsumables} pageSize={5} rowsPerPageOptions={[5]} autoHeight />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {searchResultConsOpen && (
        <Modal show={searchResultConsOpen} onHide={handleSearchResConsClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="modal-header-border">
            <Modal.Title>Search Results</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 bg-white">
            <div className="card w-100 p-2">
              <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
                <DataGrid
                  sx={GRID_STYLE}
                  rows={masterData}
                  columns={columnsConsumableSearch}
                  pageSize={5}
                  autoHeight
                  rowsPerPageOptions={[5]}
                  onRowClick={(e) => selectConsumableItem(e.row)}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <MaintenanceBuilderConsItemAddModal
        consumableItemOpen={consumableItemOpen}
        handleConsumableItemClose={handleConsumableItemClose}
        setConsumableItemData={setConsumableItemData}
        consumableItemData={consumableItemData}
        consumableTypeList={consumableTypeList}
        handleConsumableSearch={handleConsumableSearch}
        searchConsumableResult={searchConsumableResult}
        handleConsumableSelect={handleConsumableSelect}
        noOptionsConsumable={noOptionsConsumable}
        addConsumableItem={addConsumableItem}
        serviceEstimateData={operationData}
        handleVendorSearch={handleVendorSearch}
        searchVenodrResults={searchVenodrResults}
        handleVendorSelect={handleVendorConsSelect}
        noOptionsVendor={noOptionsVendor}
      />
    </>
  );
};

export default MaintenanceBuilderConsModal;
