import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import shearchIcon from "../../../assets/icons/svg/search.svg";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { TextField, Tooltip, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import Moment from "react-moment";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { componentMasterRequestObj, laborMasterRequestObj } from "../masterConstents";
import LoadingProgress from "pages/Repair/components/Loader";

import { API_SUCCESS } from "services/ResponseCode";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { masterstatusOptions } from "../equipmentConstant";
import { DATA_SVC_COMPONENT_DATA_MASTER_URL } from "services/CONSTANTS";

const ComponentMasterAddUpdateModal = ({ show, hideModal, recordId, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...componentMasterRequestObj });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  // input text change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  const handleSubmit = () => {
    const rUrl = `${DATA_SVC_COMPONENT_DATA_MASTER_URL}`;
    const rObj = { ...recordData, status: recordData?.status?.value || "" };
    if (recordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${recordData.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Component updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    } else {
      callPostApi(
        null,
        rUrl,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setRecordData({ ...recordData, id: responseData.id });
            handleSnack("success", "Component Created Successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Something went wrong");
          }
        },
        (error) => {
          handleSnack("error", "Something went wrong");
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <h5 className="d-flex align-items-center mb-0">
            <div className="" style={{ display: "contents" }}>
              <span className="mr-3">Component</span>
              <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
                <EditIcon />
              </a>
              <a className="btn-sm cursor">
                <BookmarkBorderIcon />
              </a>
              <a className="btn-sm cursor">
                <CreateNewFolderIcon />
              </a>
            </div>
            <div className="input-group icons border-radius-10 border">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                  <img src={shearchIcon} />
                </span>
              </div>
              <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
            </div>
          </h5>
          <hr className="mb-0" />
          {loading ? (
            <LoadingProgress />
          ) : (
            <div className="row mt-3">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMPONENT ID</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="id"
                            value={recordData?.id}
                            disabled
                            placeholder="Auto Generated"
                            //   onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">STATUS</label>
                          <Select
                            className="text-primary"
                            value={recordData?.status}
                            onChange={(e) => setRecordData({ ...recordData, status: e })}
                            options={masterstatusOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMPONENT NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="componentCode"
                            value={recordData?.componentCode}
                            placeholder="Component Number"
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMPONENT NAME</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="featureCode"
                            value={recordData?.featureCode}
                            placeholder="Component Name"
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="serialNumber"
                            value={recordData?.serialNumber}
                            placeholder="Serial Number"
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">MANUFACTURER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="manufacturer"
                            value={recordData?.manufacturer}
                            placeholder="Manufacturer"
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">AVAILABLE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="available"
                            value={recordData?.available}
                            placeholder="Available"
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">REFERENCE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="reference"
                            placeholder="Reference"
                            value={recordData?.reference}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SUPPPLIER NAME</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="supplierName"
                            placeholder="Supplier Name"
                            value={recordData?.supplierName}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">FEATURE CODE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="featureCode"
                            placeholder="Feature Code"
                            value={recordData?.featureCode}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">FEATURE DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="featureDescription"
                            placeholder="Feature Description"
                            value={recordData?.featureDescription}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">IN WARRANTY</label>
                          <FormGroup>
                            <FormControlLabel
                              style={{ alignItems: "start", marginLeft: 0 }}
                              control={
                                <Switch
                                  checked={recordData.warranty ? true : false}
                                  onChange={(e) => setRecordData({ ...recordData, warranty: e.target.checked })}
                                />
                              }
                              labelPlacement="top"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 input-fields">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">WARRANTY START DATE</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                value={recordData?.warrantyStartDate}
                                onChange={(e) => setRecordData({ ...recordData, warrantyStartDate: e })}
                                renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">WARRANTY END DATE</label>
                          <div className="align-items-center date-box">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileDatePicker
                                inputFormat="dd/MM/yyyy"
                                className="form-controldate border-radius-10"
                                closeOnSelect
                                value={recordData?.warrantyEndDate}
                                onChange={(e) => setRecordData({ ...recordData, warrantyEndDate: e })}
                                renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="COMPONENT ID" value={recordData?.id} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="STATUS" value={recordData?.status?.label} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="COMPONENT NUMBER" value={recordData?.componentCode} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="COMPONENT NAME" value={recordData?.featureCode} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SERIAL NUMBER" value={recordData?.serialNumber} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="MANUFACTURER" value={recordData?.manufacturer} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="AVAILABLE" value={recordData?.available} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="REFERENCE" value={recordData?.reference} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="SUPPLIER NAME" value={recordData?.supplierName} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField className="col-md-4 col-sm-4" label="FEATURE CODE" value={recordData?.featureCode} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="FEATURE DESCRIPTION" value={recordData?.featureDescription} />
                      <ReadOnlyField className="col-md-4 col-sm-4" label="IN WARRANTY" value={recordData?.warranty ? "ACTIVE" : "INACTIVE"} />
                    </div>
                    <div className="row mt-3 ">
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="WARRANTY START DATE"
                        value={recordData?.warrantyStartDate ? <Moment format="DD/MM/YYYY">{recordData?.warrantyStartDate}</Moment> : "NA"}
                      />
                      <ReadOnlyField
                        className="col-md-4 col-sm-4"
                        label="WARRANTY END DATE"
                        value={recordData?.warrantyEndDate ? <Moment format="DD/MM/YYYY">{recordData?.warrantyEndDate}</Moment> : "NA"}
                      />
                    </div>
                  </>
                )}
                {!viewModeOn ? (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Cancel
                    </button>
                    <button
                      className="btn bg-primary text-white"
                      onClick={handleSubmit}
                      //   disabled={
                      //     !recordData?.serviceType?.value ||
                      //     !recordData?.laborType?.value ||
                      //     !recordData?.chargeCode?.value ||
                      //     !recordData?.laborCode?.value
                      //   }
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={hideModal}>
                      Close
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Component Version</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      5 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className="">
                        {/* <MuiMenuComponent options={activityOptions} /> */}
                      </a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small>SERVICE DESCRIPTION</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Engine repair
                  </p>
                  <p className="text-black mb-2">Engine Overhaul</p>
                  <div className="mt-3">
                    <small>CHARGE CODE</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Minimum service charge
                  </p>
                  <p className="text-black mb-2">Additional Service Charge</p>
                  <div className="mt-3">
                    <small>STATUS</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Saved
                  </p>
                  <p className="text-black mb-2">Active</p>
                  {/* <div className="card border">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <td>365-1234</td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <MoreHorizOutlinedIcon />
                          </td>
                          <td>
                            <div className="d-flex justify-content-between">
                              <div className="mr-3">
                                <small style={{ textDecoration: "line-through" }}>$80</small>
                                <p className="mb-0 mt-2">$100</p>
                              </div>
                              <div>
                                <span className="c-btn" style={{ position: "unset" }}>
                                  J
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ComponentMasterAddUpdateModal;
