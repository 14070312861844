import React, { useCallback, useEffect, useState } from "react";

import deleteIcon from "../../../assets/icons/svg/delete.svg";
import copyIcon from "../../../assets/icons/svg/Copy.svg";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAlt";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { Stack } from "@mui/material";
import AntSwitch from "@mui/material/Switch";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Tooltip, TextField, Divider, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import $ from "jquery";
import Swal from "sweetalert2";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { currencyFormatter } from "pages/Common/utils/currencyFormatter";

import { API_SUCCESS } from "services/ResponseCode";
import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { customerSearch, itemDetailsSearch, machineSearch, sparePartSearch } from "services/searchServices";
import { Get_supplier_claim, SUPPLIER_CLAIM_MASTER_URL, SUPPLIER_ITEM_DETAILS_URL } from "services/CONSTANTS";

import SearchBox from "pages/Common/SearchBox";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { isEmpty } from "pages/Common/textUtilities";
import { FONT_STYLE, FONT_STYLE_SELECT, GRID_STYLE, SPAREPART_SEARCH_Q_OPTIONS } from "pages/Common/constants";
import {
    claimRequestObj,
    claimVersionOptions,
    supplierActionOptions,
    supplierClaimOptions,
    supplierDecisionOptions,
    oemSupplierClaimStatusOptions,
    questionsOptions,
    supplierClaimRequestObj,
} from "pages/warrantyManagement/warrantyManagementConstants";

import ClaimsProcessingEvaluation from "pages/Warranty_Management/ClaimsProcessing/ClaimsProcessingEvaluation";
import SupplierClaimRelatedParts from "./SupplierClaimRelatedParts";
import SupplierClaimEvaluation from "./SupplierClaimEvaluation";
import SupplierClaimApprovalClosure from "./SupplierClaimApprovalClosure";
import Moment from "react-moment";
import SupplierClaimItemTableSearchModal from "./SupplierClaimItemTableSearchModal";
// import SearchTableOpenModal from "./SearchTableOpenModal";

const supplierClaimTypeOptions = [
    { label: "Settlement", value: "SETTLEMENT" },
    { label: "Product Replacement", value: "PRODUCT_REPLACEMENT" },
];

const supplierStatusOptions = [
    { label: "Draft", value: "DRAFT" },
    { label: "Sent to Supplier", value: "SENT_TO_SUPPLIER" },
    { label: "Receieved from Supplier ", value: "RECEIVED_FROM_SUPPLIER" },
    { label: "Replaced", value: "REPLACED" },
    { label: "Settled", value: "SETTLED" },
    { label: "Closed", value: "CLOSED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Contest", value: "CONTEST" },
];

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: "0.6rem",
    "&:(:last-child)": { marginBottom: 0 },
    "&::before": { display: "none" },
}));

const AccordionSummary = styled((props) => {
    const { expanded } = props; // Get the expanded state
    return <MuiAccordionSummary expandIcon={expanded ? <RemoveIcon sx={{ fontSize: "3rem" }} /> : <AddIcon sx={{ fontSize: "3rem" }} />} {...props} />;
})(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {},
    "& .MuiAccordionSummary-content": { marginLeft: theme.spacing(1) },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SupplierClaimRiseModal = ({ show, hideModal, handleSnack, recordId, supplierDtls = {}, mode, claimRecord, setClaimRecord }) => {
    const [tabValue, setTabValue] = useState("supplierDetails");
    const [claimOrderVersion, setClaimOrderVersion] = useState(claimVersionOptions[0]);
    const [decisionStatus, setDecisionStatus] = useState(supplierDecisionOptions[0]);
    const [expanded, setExpanded] = useState("supplierClaimSummary");
    const [supplierItems, setSupplierItems] = useState([]);
    const [supplierRecord, setSupplierRecord] = useState({ ...supplierClaimRequestObj, ...supplierDtls });
    const [masterData, setMasterData] = useState([]);

    const [searchResultOpen, setSearchResultOpen] = useState(false);
    const [relatedPartsRecord, setRelatedPartsRecord] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [claimValueCount, setClaimValueCount] = useState(0);

    const [querySearchSelector, setQuerySearchSelector] = useState([
        {
            id: 0,
            preSelector: { label: "Parts", value: "PARTS" },
            selectCategory: "",
            selectOperator: "",
            inputSearch: "",
            selectOptions: [],
            selectedOption: "",
        },
    ]);

    const [searchSerialResults, setSearchSerialResults] = useState([]);
    const [noOptionsSerial, setNoOptionsSerial] = useState(false);

    const [noOptionsCust, setNoOptionsCust] = useState(false);
    const [searchCustResults, setSearchCustResults] = useState([]);

    const [viewOnlyTab, setViewOnlyTab] = useState({
        supplierDtlsViewOnly: false,
        failureDtlsViewOnly: false,
        estimationdtlsViewOnly: false,
        supplierClaimSummaryViewOnly: false,
        supplierItemsDtlsViewOnly: false,
        evaluationViewOnly: false,
        finalApprovalViewOnly: false,
        settlementViewOnly: false,
        invoiceViewOnly: false,
    });

    const [authorizationStatusRecords, setAuthorizationStatusRecords] = useState([
        {
            id: 1,
            requestType: "",
            authorizationStatus: "PENDING",
            authorizationCode: "",
            authorizedBy: "",
            authorizedOn: new Date(),
            status: "",
        },
    ]);
    const [authorizationStatus, setAuthorizationStatus] = useState("PENDING");
    const [settlementIntgrate, setSettlementIntgrate] = useState(false);

    const [oemSupplierRecordData, setOemSupplierRecordData] = useState({
        supplierName: "",
        claimSubmissionDate: new Date(),
        claimStatus: "",
        approvedBy: "",
        approvalDate: new Date(),
        approvalAmount: "",
        rejectionReason: "",
        reimbursed: "",
        partsAmount: "",
        expensesAmount: "",
        totalAmount: "",
        reimbursementNotes: "",
    });

    useEffect(() => {
        if (recordId) {
            setViewOnlyTab({
                supplierDtlsViewOnly: true,
                failureDtlsViewOnly: true,
                estimationdtlsViewOnly: true,
                supplierClaimSummaryViewOnly: true,
                supplierItemsDtlsViewOnly: true,
                evaluationViewOnly: true,
                finalApprovalViewOnly: true,
                settlementViewOnly: true,
                invoiceViewOnly: true,
            });
            const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}/${recordId}`;
            callGetApi(rUrl, (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;
                    setSupplierRecord(responseData);
                    if (responseData["supplierItemDTOS"] && responseData["supplierItemDTOS"].length > 0) {
                        setSupplierItems([...responseData["supplierItemDTOS"]]);
                    }
                }
            });
        } else {
            setViewOnlyTab({
                supplierDtlsViewOnly: false,
                failureDtlsViewOnly: false,
                estimationdtlsViewOnly: false,
                supplierClaimSummaryViewOnly: false,
                supplierItemsDtlsViewOnly: false,
                evaluationViewOnly: false,
                finalApprovalViewOnly: false,
                settlementViewOnly: false,
                invoiceViewOnly: false,
            });
        }
    }, [recordId]);

    // Calculate total price whenever supplierItems change
    useEffect(() => {
        const total = calculateTotalPrice(supplierItems);
        setTotalPrice(total);
    }, [supplierItems]);

    // Function to calculate the total price
    const calculateTotalPrice = (rows) => {
        return rows.reduce((total, row) => {
            const quantity = row.quantity ?? 0; // Fallback to 0 if undefined
            const unitPrice = row.unitPrice ?? 0; // Fallback to 0 if undefined
            return total + quantity * unitPrice;
        }, 0);
    };

    // make editable to Return Details tab data
    const handleMakeTabEditable = () => {
        let viewOnlyTabName = "";
        if (tabValue === "supplierDetails") {
            viewOnlyTabName = "supplierDtlsViewOnly";
        } else if (tabValue === "failureDetails") {
            viewOnlyTabName = "failureDtlsViewOnly";
        } else if (tabValue === "estimation") {
            viewOnlyTabName = "estimationdtlsViewOnly";
            // } else if (tabValue === "relatedparts") {
            //     setTabValue("failureDetails");
        }
        setViewOnlyTab({ ...viewOnlyTab, [viewOnlyTabName]: false });
    };

    // Accorian Expend or Collescape
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // input text value change
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        setSupplierRecord({ ...supplierRecord, [name]: value });
    };

    // Select value change
    const handleSelectValueChange = (e, keyName) => {
        setSupplierRecord({ ...supplierRecord, [keyName]: e });
    };

    // ! Suppler Items Functions

    // close Item Search Modal
    const handleSearchResClose = () => {
        setSearchResultOpen(false);
    };

    // clear filter
    const clearFilteredData = () => {
        setMasterData([]);
    };

    // get related parts table data
    const getRelatedPartsTableData = (claimRecord) => {
        if (querySearchSelector[0].preSelector?.value === "PRODUCT") {
            const filteredParts = claimRecord.filter((obj) => obj.taskType === "PRODUCT");
            return filteredParts;
        } else {
            const filteredParts = claimRecord.filter((obj) => obj.taskType !== "PRODUCT");
            return filteredParts;
        }
    };

    // Click on Supplier item Searhc button
    const handleQuerySearchClick = async (type) => {
        // if (claimRecord.length !== 0 && !relatedPartsRecords[relatedPartsRecords.length - 1]?.relatedPartsId) {
        //     handleSnack("info", "Please Update Last Row Record then you and add more data.");
        //     return;
        // }
        if (querySearchSelector[0].preSelector?.value === "PRODUCT") {
            const isUserIdPresent = setClaimRecord.some((parts) => parts.rows === 0);
            if (isUserIdPresent) {
                handleSnack("info", "Please Update Last Row Record then you and add more data.");
                return;
            }
            setClaimRecord([
                ...claimRecord,
                {
                    relatedPartsId: 0,
                    segment: "",
                    jobCode: "",
                    title: "",
                    compCode: "",
                    description: "",
                    portfolioId: "",
                    subDescription: "",
                    version: "",
                    taskType: "PRODUCT",
                    suppliedBy: "YES",
                    quantity: 1,
                    model: "",
                    serialNo: "",
                    validFrom: new Date(),
                    validTo: new Date(),
                    unitPrice: 0,
                    extendedPrice: 0,
                    discount: 0,
                    totalPrice: 0,
                    // claimOrderId: claimOrderId,
                    isNew: true,
                },
            ]);
        } else {
            $(".scrollbar").css("display", "none");
            var searchStr = "";
            querySearchSelector.map(function (item, i) {
                if (i === 0 && item.selectCategory.value && item.inputSearch) {
                    searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
                } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
                    searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
                }
                return searchStr;
            });

            try {
                if (searchStr) {
                    let exits = false;

                    if (getRelatedPartsTableData(supplierItems).length > 0) {
                        exits = getRelatedPartsTableData(supplierItems).some((row) => row["serialNo"] === querySearchSelector[0].inputSearch);
                    }

                    if (!exits) {
                        const res = await sparePartSearch(searchStr);
                        setMasterData(res);
                        setSearchResultOpen(true);
                    } else {
                        handleSnack("info", "Already exits, change the input search!");
                    }
                } else {
                    handleSnack("info", "Please fill the search criteria!");
                }
            } catch (err) {
                handleSnack("error", "Error occurred while fetching spare parts!");
            }
        }
    };

    const tabs = ["supplierDetails", "failureDetails", "estimation", "relatedparts", "evaluation", "approvalClosure"];

    // add update supplier claim
    const handleUpdateSupplierClaim = (resObj = {}) => {
        const rUrl = `${SUPPLIER_CLAIM_MASTER_URL}/${supplierRecord?.supplierClaimId}`;

        const rObj = { ...supplierRecord, ...resObj };
        callPutApi(null, rUrl, rObj, (response) => {
            if (response.status === API_SUCCESS) {
                const responseData = response.data;
            }
        });
    };

    // add update Supplier claim details
    const handleAddUpdateSupplierClaim = () => {
        if (tabValue === "supplierDetails" && viewOnlyTab?.supplierDtlsViewOnly) {
            setTabValue("failureDetails");
            return;
        } else if (tabValue === "failureDetails" && viewOnlyTab?.failureDtlsViewOnly) {
            setTabValue("estimation");
            return;
        } else if (tabValue === "estimation" && viewOnlyTab?.estimationdtlsViewOnly) {
            setTabValue("relatedparts");
            return;
        }

        const _supplierItemDTOS = supplierItems.map((item) => item.supplierItemId);
        const rUrl = SUPPLIER_CLAIM_MASTER_URL;
        const rObj = { ...supplierRecord, supplierItemDTOS: [..._supplierItemDTOS] };
        if (supplierRecord?.supplierClaimId) {
            callPutApi(
                null,
                `${rUrl}/${supplierRecord?.supplierClaimId}`,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        handleSnack("success", "Data updated successfully!");

                        let viewOnlyText = "";
                        if (tabValue === "supplierDetails") {
                            setTabValue("failureDetails");
                            viewOnlyText = "supplierDtlsViewOnly";
                        } else if (tabValue === "failureDetails") {
                            setTabValue("estimation");
                            viewOnlyText = "failureDtlsViewOnly";
                        } else if (tabValue === "estimation") {
                            setTabValue("relatedparts");
                            viewOnlyText = "estimationdtlsViewOnly";
                            // } else if (tabValue === "relatedparts") {
                            //     setTabValue("failureDetails");
                        }

                        setViewOnlyTab({ ...viewOnlyTab, [viewOnlyText]: true });
                    } else {
                        handleSnack("error", "Error occurred while updating data!");
                    }
                },
                (error) => {
                    handleSnack("error", "Error occurred while updating data!");
                }
            );
        } else {
            callPostApi(
                null,
                `${rUrl}`,
                rObj,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        setSupplierRecord({ ...supplierRecord, supplierClaimNumber: responseData?.supplierClaimNumber, supplierClaimId: responseData.supplierClaimId });
                        handleSnack("success", "Data saved successfully!");

                        let viewOnlyText = "";
                        if (tabValue === "supplierDetails") {
                            setTabValue("failureDetails");
                            viewOnlyText = "supplierDtlsViewOnly";
                        } else if (tabValue === "failureDetails") {
                            setTabValue("estimation");
                            viewOnlyText = "failureDtlsViewOnly";
                        } else if (tabValue === "estimation") {
                            setTabValue("relatedparts");
                            viewOnlyText = "estimationdtlsViewOnly";
                            // } else if (tabValue === "relatedparts") {
                            //     setTabValue("failureDetails");
                        }

                        setViewOnlyTab({ ...viewOnlyTab, [viewOnlyText]: true });
                    } else {
                        handleSnack("error", "Error occurred while creating data!");
                    }
                },
                (error) => {
                    handleSnack("error", "Error occurred while creating data!");
                }
            );
        }
    };

    // Handle Next button click
    const handleNext = () => {
        const currentTabIndex = tabs.indexOf(tabValue);
        if (currentTabIndex < tabs.length - 1) {
            setTabValue(tabs[currentTabIndex + 1]); // Move to the next tab
        } else {
            // Handle the Save action on the last tab
            console.log("Saving data...");
        }
    };

    // Handles starting row edit
    const handleEditClick = (supplierItemId) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [supplierItemId]: { mode: GridRowModes.Edit },
        }));
    };

    // cange table row edit|delete mode change
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                const updatedRow = {
                    ...newRow,
                    totalPrice: newRow.quantity * newRow.unitPrice,
                    isNew: true,
                };

                if (newRow.supplierItemId) {
                    // Perform PUT API call to update the existing supplier item
                    callPutApi(null, `${SUPPLIER_ITEM_DETAILS_URL}/${newRow.supplierItemId}`, updatedRow, (response) => {
                        if (response.status === API_SUCCESS) {
                            // Successful update
                            handleSnack("success", "Parts updated successfully");

                            // Map supplierRecord and update the specific row
                            const updatedSupplierRecord = supplierRecord.map((row) =>
                                row.supplierItemId === updatedRow.supplierItemId ? { ...updatedRow, isNew: undefined } : row
                            );

                            // Calculate total parts and product claimed
                            const _totalPartsClaimed = updatedSupplierRecord.reduceRight((total, item) => {
                                if (item.taskType !== "PRODUCT") {
                                    return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * Number(item.quantity));
                                } else {
                                    return total;
                                }
                            }, 0);

                            const _totalProductClaimed = updatedSupplierRecord.reduceRight((total, item) => {
                                if (item.taskType === "PRODUCT") {
                                    return total + (item.suppliedBy === "YES" ? 0 : item.unitPrice * item.quantity);
                                } else {
                                    return total;
                                }
                            }, 0);

                            // Update state with new supplier records and claim values
                            setSupplierRecord(updatedSupplierRecord);
                            setClaimRecord((prev) => ({
                                ...prev,
                                totalPartsClaimed: _totalPartsClaimed,
                                totalProductClaimed: _totalProductClaimed,
                            }));

                            // Increment claim value count
                            setClaimValueCount((prev) => prev + 1);

                            // Resolve with the updated row
                            resolve(updatedRow);
                        } else {
                            // Handle failed update
                            handleSnack("error", "Parts details could not be updated");
                            // Resolve with the old row to revert the changes
                            resolve(oldRow);
                        }
                    });
                } else {
                    // If no supplierItemId (maybe it's a new row), just resolve the new row
                    resolve(updatedRow);
                }
            }),
        [supplierRecord, setSupplierRecord, setClaimRecord, setClaimValueCount]
    );

    const handleDeleteClick = (supplierItemId) => {
        if (supplierItemId) {
            // Show SweetAlert confirmation dialog
            Swal.fire({
                title: "Are you sure?",
                text: "Do you want to delete this item?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Make API call to delete item
                    callDeleteApi(null, `${SUPPLIER_ITEM_DETAILS_URL}/${supplierItemId}`, (response) => {
                        if (response.status === API_SUCCESS) {
                            // Update claimRecord state by filtering out the deleted item
                            setSupplierItems((prevSupplierItems) => prevSupplierItems.filter((item) => item.supplierItemId !== supplierItemId));

                            // Optional: Show success message
                            Swal.fire("Deleted!", "The item has been deleted.", "success");
                        } else {
                            // Handle error case
                            Swal.fire("Error!", "The item could not be deleted.", "error");
                        }
                    });
                }
            });
        }
    };

    // Handles saving the row data
    const handleSaveClick = (supplierItemId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [supplierItemId]: { mode: GridRowModes.View },
        });
    };

    // Handles canceling edit mode
    const handleCancelClick = (supplierItemId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [supplierItemId]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    // row edit start
    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // row edit stop
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // toggle switch On/Off
    const handleToggleSwitch = (event, id) => {
        const newValue = event.target.checked;
        // Update the row's data in your state based on `id` and `newValue`
        // Example:
        setSupplierItems((prevItems) => prevItems.map((item) => (item.supplierItemId === id ? { ...item, partCauseDamage: newValue } : item)));
    };

    // Machine search based on model and serial number
    const handleMachineSearch = async (searchMachinefieldName, searchText) => {
        let searchQueryMachine = "";
        setSearchSerialResults([]);

        if (searchMachinefieldName === "model") {
            supplierRecord["model"] = searchText;
            searchQueryMachine = searchText ? searchMachinefieldName + "~" + searchText : "";
        } else if (searchMachinefieldName === "makerSerialNumber") {
            setSupplierRecord({ ...supplierRecord, ["equipmentNumber"]: searchText });
            supplierRecord["equipmentNumber"] = searchText;
            searchQueryMachine = searchText
                ? supplierRecord["model"]
                    ? `model:${supplierRecord["model"]} %26%26 makerSerialNumber~` + searchText
                    : "makerSerialNumber~" + searchText
                : "";
        }

        if (searchQueryMachine) {
            await machineSearch(searchQueryMachine)
                .then((result) => {
                    if (result) {
                        if (searchMachinefieldName === "makerSerialNumber") {
                            if (result && result.length > 0) {
                                setSearchSerialResults(result);
                                setNoOptionsSerial(false);
                            } else {
                                setNoOptionsSerial(true);
                            }
                        }
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the machine!");
                });
        } else {
            setSearchSerialResults([]);
        }
    };

    // Select machine from the search result
    const handleModelSelect = (type, currentItem) => {
        if (type === "makerSerialNumber") {
            setSupplierRecord({
                ...supplierRecord,
                // maker: currentItem.maker,
                // model: currentItem.model,
                // serialNo: currentItem.makerSerialNumber,
                // smu: currentItem.sensorId,
                // fleetNo: currentItem.stockNumber,
                // equipmentNumber: currentItem.equipmentNumber,
                equipmentNumber: currentItem.makerSerialNumber,
            });
            setSearchSerialResults([]);
        }
    };

    // Search Customer with customer ID
    const handleCustSearch = async (searchCustfieldName, searchText) => {
        setSearchCustResults([]);
        setSupplierRecord({ ...supplierRecord, ["supplierId"]: searchText, customerName: "" });
        // customerData.customerId = searchText;
        // customerData.id = "";
        // customerData.firstName = "";
        // customerData.lastName = "";
        // customerData.fullName = "";
        // customerData.email = "";
        // customerData.primaryContact = "";

        // setAddressRecord({ ...addressDTOObj });

        if (searchText) {
            await customerSearch(searchCustfieldName + "~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchCustResults(result);
                        setNoOptionsCust(false);
                    } else {
                        setNoOptionsCust(true);
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the customer!");
                });
        }
    };

    // select customer
    const handleCustSelect = (type, currentItem) => {
        setSupplierRecord({
            ...supplierRecord,
            // ...customerData,
            supplierId: currentItem.customerId,
            contactEmail: currentItem.email,
            contactName: currentItem.contactName,
            customerGroup: currentItem.customerGroup,
            customerName: currentItem.fullName,
            customerSegment: currentItem.customerSegment,
            country: currentItem.addressDTO?.country,
            regionOrState: currentItem.addressDTO?.regionOrState,
        });

        // setAddressRecord({ ...currentItem?.addressDTO });
        setSearchCustResults([]);
    };

    const columns = [
        { field: "materialNumber", headerName: "Part Number", width: 110 },
        {
            field: "materialDescription",
            headerName: "Part Description",
            width: 200,
        },
        { field: "quantity", headerName: "Quantity", width: 100, editable: true },
        { field: "unitPrice", headerName: "Unit Price", width: 100 },
        {
            field: "totalprice",
            headerName: "Total Price",
            width: 150,
            renderCell: ({ row }) => row?.quantity * row?.unitPrice,
        },

        {
            field: "supplierReturnPartStatus",
            headerName: "Return Status",
            width: 200,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                { label: "PARTS_MISSING ", value: "PARTS_MISSING " },
                { label: "RECEIVED", value: "RECEIVED" },
                { label: "WRONG_PART", value: "WRONG_PART" },
            ],
            valueGetter: (params) => {
                // If the API returns 'EMPTY', we return an empty string, else the actual value
                return params.row.supplierReturnPartStatus === "EMPTY" ? "" : params.row.supplierReturnPartStatus;
            },
        },

        {
            field: "partCauseDamage",
            headerName: "PartCause Damage",
            width: 150,
            renderCell: (params) => (
                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                    <AntSwitch
                        checked={params.row.partCauseDamage}
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(event) => handleToggleSwitch(event, params.row.supplierItemId)}
                    />
                </Stack>
            ),
        },

        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            // width: 200,
            cellClassName: "actions",
            getActions: ({ row }) => {
                const isInEditMode = rowModesModel[row.supplierItemId]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(row.supplierItemId)} />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(row.supplierItemId)} />,
                    ];
                }

                return [
                    <GridActionsCellItem icon={<EditOutlinedIcon />} label="Edit" onClick={handleEditClick(row.supplierItemId)} />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Remove">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={() => handleDeleteClick(row.supplierItemId)}
                        // onClick={handleDeleteClick(row.supplierItemId)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    // view supplier details tab content
    const viewSupplierDetails = () => {
        return (
            <>
                {!viewOnlyTab?.supplierDtlsViewOnly ? (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row input-fields">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER NAME</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord?.supplierName}
                                            name="supplierName"
                                            readOnly
                                            placeholder="Supplier Name"
                                            // onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER ID</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord?.supplierId}
                                            name="supplierId"
                                            readOnly
                                            placeholder="Supplier Id"
                                            // onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER EMAIL</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord.supplierEmail}
                                            name="supplierEmail"
                                            placeholder="Supplier Email"
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">SUPPLIER ADDRESS</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord.supplierAddress}
                                            name="supplierAddress"
                                            placeholder="Supplier Address"
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">PURCHASE CONTRACT NUMBER</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord.purchaseContractNumber}
                                            name="purchaseContractNumber"
                                            placeholder="Purchase contract number"
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row input-fields">
                                <div className="row m-2">
                                    <ReadOnlyField label="SUPPLIER NAME" value={supplierRecord?.supplierName} className="col-md-4 col-sm-4" />
                                    <ReadOnlyField label="SUPPLIER ID" value={supplierRecord?.supplierId} className="col-md-4 col-sm-4" />
                                    <ReadOnlyField label="SUPPLIER EMAIL" value={supplierRecord?.supplierEmail} className="col-md-4 col-sm-4" />
                                    <ReadOnlyField label="SUPPLIER ADDRESS" value={supplierRecord?.supplierAddress} className="col-md-4 col-sm-4" />
                                    <ReadOnlyField label=" PURCHASE CONTRACT NUMBER" value={supplierRecord?.purchaseContractNumber} className="col-md-4 col-sm-4" />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={handleAddUpdateSupplierClaim}>
                        {viewOnlyTab?.supplierDtlsViewOnly ? "Next" : "Save & Next"}
                    </button>
                </div>
            </>
        );
    };

    // view failure details tab content
    const viewFailureDetails = () => {
        return (
            <>
                {!viewOnlyTab?.failureDtlsViewOnly ? (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row input-fields">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">EQUIPMENT NUMBER</label>
                                        <SearchBox
                                            value={supplierRecord?.equipmentNumber}
                                            onChange={(e) => handleMachineSearch("makerSerialNumber", e.target.value)}
                                            type="makerSerialNumber"
                                            result={searchSerialResults}
                                            onSelect={handleModelSelect}
                                            noOptions={noOptionsSerial}
                                            placeholder="Equipment Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">CONTRACT NUMBER</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="contractNumber"
                                            placeholder="Contarct Number"
                                            value={supplierRecord?.contractNumber}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">WORK ORDER NUMBER</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="workOrderNumber"
                                            placeholder="Work order Number"
                                            value={supplierRecord?.workOrderNumber}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NUMBER</label>
                                        <SearchBox
                                            value={supplierRecord?.supplierId}
                                            onChange={(e) => handleCustSearch("customerId", e.target.value)}
                                            type="customerId"
                                            result={searchCustResults}
                                            onSelect={handleCustSelect}
                                            noOptions={noOptionsCust}
                                            placeholder="Customer Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            value={supplierRecord?.customerName}
                                            name="customerName"
                                            placeholder="Customer Name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">SITE NAME</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="siteName"
                                            placeholder="Site Name"
                                            value={supplierRecord?.siteName}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">FAILURE CODE</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="failureCode"
                                            placeholder="Failure Code"
                                            value={supplierRecord?.failureCode}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">FAILURE DESCRIPTION</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="failureDescription"
                                            placeholder="Failure Description"
                                            value={supplierRecord?.failureDescription}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">FAILURE DATE</label>
                                        <div className="align-items-center date-box">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <MobileDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    className="form-controldate border-radius-10"
                                                    // maxDate={new Date()}
                                                    closeOnSelect
                                                    value={supplierRecord?.failureDate}
                                                    onChange={(e) => handleSelectValueChange(e, "failureDate")}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REPAIR DATE</label>
                                        <div className="align-items-center date-box">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <MobileDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    className="form-controldate border-radius-10"
                                                    // maxDate={new Date()}
                                                    closeOnSelect
                                                    value={supplierRecord?.repairDate}
                                                    onChange={(e) => handleSelectValueChange(e, "repairDate")}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">DEALER CODE</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="dealerCode"
                                            placeholder="Dealer Code"
                                            value={supplierRecord?.dealerCode}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">ATTACH DOCUMENT/PHOTO</p>
                                        <div
                                            className="file-upload-input bg-white border-radius-10 d-flex align-items-center justify-content-between cursor"
                                            // onClick={handleShowFileUploadModal}
                                        >
                                            <h6 className="text-primary m-0 font-size-16 font-weight-500">
                                                {isEmpty(supplierRecord.attachDocument) ? "Upload File" : supplierRecord.attachDocument}
                                            </h6>
                                            <img className="mx-1 cursor" src="../../assets/images/fileUploadIcon.png" alt="File Upload icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row m-2">
                                <ReadOnlyField label="SUPPLIER NAME" value={supplierRecord?.supplierName} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="EQUIPMENT NUMBER:" value={supplierRecord?.equipmentNumber} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="CONTRACT NUMBER" value={supplierRecord?.contractNumber} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="WORK ORDER NUMBER" value={supplierRecord?.workOrderNumber} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="SITE NAME " value={supplierRecord?.siteName} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="FAILURE CODE" value={supplierRecord?.failureCode} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="FAILURE DESCRIPTION" value={supplierRecord?.failureDescription} className="col-md-3 col-sm-3" />
                                <ReadOnlyField
                                    label="FAILURE DATE"
                                    value={supplierRecord?.failureDate ? <Moment format="DD/MM/YYYY">{supplierRecord?.failureDate}</Moment> : "NA"}
                                    className="col-md-3 col-sm-3"
                                />
                                <ReadOnlyField
                                    label="REPAIR DATE"
                                    value={supplierRecord?.repairDate ? <Moment format="DD/MM/YYYY">{supplierRecord?.repairDate}</Moment> : "NA"}
                                    className="col-md-3 col-sm-3"
                                />
                                <ReadOnlyField label="DEALER CODE" value={supplierRecord?.dealerCode} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="ATTACH DOCUMENT/PHOTO" value={supplierRecord?.attachDocument} className="col-md-3 col-sm-3" />
                            </div>
                        </div>
                    </>
                )}

                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={handleAddUpdateSupplierClaim}>
                        {viewOnlyTab?.failureDtlsViewOnly ? "Next" : "Save & Next"}
                    </button>
                </div>
            </>
        );
    };

    // view estimation details tab content
    const viewEstimationDetails = () => {
        return (
            <>
                {!viewOnlyTab?.estimationdtlsViewOnly ? (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row input-fields">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REQUESTED DATE</label>
                                        <div className="align-items-center date-box">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <MobileDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    className="form-controldate border-radius-10"
                                                    // maxDate={new Date()}
                                                    closeOnSelect
                                                    value={supplierRecord?.requestDate}
                                                    onChange={(e) => handleSelectValueChange(e, "requestDate")}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REQUESTED NAME</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="requestName"
                                            placeholder="Requested Name"
                                            value={supplierRecord?.requestName}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REQUESTED EMAIL</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="requestEmail"
                                            placeholder="Requested Email"
                                            value={supplierRecord?.requestEmail}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                    <div className="form-group">
                                        <label className="text-light-dark font-size-12 font-weight-500">REQUESTED CONTACT</label>
                                        <input
                                            type="text"
                                            className="form-control border-radius-10 text-primary"
                                            name="requestContact"
                                            placeholder="Requested Contact"
                                            value={supplierRecord?.requestContact}
                                            onChange={handleInputTextChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="card border mb-3 mt-2 px-3 py-3">
                            <div className="row m-2">
                                <ReadOnlyField
                                    label="REQUESTED DATE"
                                    value={supplierRecord?.requestDate ? <Moment format="DD/MM/YYYY">{supplierRecord?.requestDate}</Moment> : "NA"}
                                    className="col-md-3 col-sm-3"
                                />
                                <ReadOnlyField label="REQUESTED NAME" value={supplierRecord?.requestName} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="REQUESTED EMAIL" value={supplierRecord?.requestEmail} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="REQUESTED CONTACT" value={supplierRecord?.requestContact} className="col-md-3 col-sm-3" />
                            </div>
                        </div>
                    </>
                )}

                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={handleAddUpdateSupplierClaim}>
                        {viewOnlyTab?.estimationdtlsViewOnly ? "Next" : "Save & Next"}
                    </button>
                </div>
            </>
        );
    };

    // view Related Parts
    const viewRelatedParts = () => {
        return (
            <>
                <div className="card border px-3 py-3 mb-2 ">
                    <Accordion expanded={expanded === "supplierClaimSummary"} onChange={handleChange("supplierClaimSummary")} key={"supplierClaimSummary"}>
                        <AccordionSummary expanded={expanded === "supplierClaimSummary" ? true : false}>
                            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                                <Typography>{"Supplier Claim Summary"}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row mt-3">
                                <ReadOnlyField label="REQUESTED AMOUNT" value={supplierRecord?.requestedNetAmount} className="col-md-3 col-sm-3" />
                                <ReadOnlyField label="APPROVED AMOUNT" value={supplierRecord?.approvedNetAmount} className="col-md-3 col-sm-3" />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === "material"} onChange={handleChange("material")} key={"material"}>
                        <AccordionSummary expanded={expanded === "material" ? true : false}>
                            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                                <Typography>
                                    {"Supplier Item Details"}
                                    <span className="ml-2">{currencyFormatter.format(totalPrice)}</span>
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <div className="d-flex align-items-center w-100">
                                        <SupplierClaimRelatedParts
                                            querySearchSelector={querySearchSelector}
                                            setQuerySearchSelector={setQuerySearchSelector}
                                            clearFilteredData={clearFilteredData}
                                            handleSnack={handleSnack}
                                            searchAPI={sparePartSearch}
                                            searchClick={handleQuerySearchClick}
                                            options={SPAREPART_SEARCH_Q_OPTIONS}
                                            background={"white"}
                                            type=""
                                            buttonText="ADD"
                                            tableRecords={getRelatedPartsTableData(claimRecord)}
                                            // buttonText="ADD PART"
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataGrid
                                rows={supplierItems} // This is the mapped data from supplierItemDTOS
                                columns={columns}
                                sx={GRID_STYLE}
                                pageSize={5}
                                autoHeight
                                rowsPerPageOptions={[5, 10, 20]}
                                editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStart={handleRowEditStart}
                                onRowEditStop={handleRowEditStop}
                                experimentalFeatures={{ newEditingApi: true }}
                                onProcessRowUpdateError={(error) => console.log(error)}
                                processRowUpdate={processRowUpdate}
                                // checkboxSelection
                                getRowId={(row) => row.supplierItemId}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={() => setTabValue("evaluation")}>
                        {viewOnlyTab?.supplierClaimSummaryViewOnly ? "Next" : "Save & Next"}
                    </button>
                </div>
            </>
        );
    };

    // view Evaluation
    const viewEvalution = () => {
        return (
            <>
                {/* <ClaimsProcessingEvaluation /> */}
                <SupplierClaimEvaluation />
                <div className="row px-3" style={{ justifyContent: "right" }}>
                    <button className="btn bg-primary text-white" onClick={handleNext}>
                        {tabValue === "relatedparts" ? "Save" : "Next"}
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    <div className="card border my-2 px-3">
                        <div className="row mt-2 py-3">
                            <div className="col-md-7 col-sm-7 d-flex claim-requester-info">
                                <img src="../assets/images/member/2.jpg" alt="" />
                                <div className="mx-2">
                                    <h2 className="mb-0">Ashok Mohanty</h2>
                                    <h6>Warranty Analyst</h6>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-5 d-flex">
                                <PeopleAltOutlinedIcon fontSize="large" />
                                <div className="mx-2">
                                    <h5 className="mt-2">
                                        <span>
                                            {<Tooltip title="Customer Name">{supplierDtls?.supplierName}</Tooltip>}{" "}
                                            {supplierDtls?.supplierName && supplierDtls?.supplierId && " - "}{" "}
                                            {<Tooltip title="Customer Number">{supplierDtls?.supplierId}</Tooltip>}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <h5 className="font-weight-600 mb-0">Supplier Claim -{supplierDtls?.supplierId} </h5>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="ml-3">
                                    <span class="claim-version-span">{claimOrderVersion?.label}</span>
                                </div>
                                <div className="ml-3">
                                    <Select
                                        value={decisionStatus}
                                        // onChange={(e) => {
                                        //   setDecisionStatus(e);
                                        //   handleSaveClaimOrderChanges({
                                        //     claimOrderStatus: e?.value,
                                        //   });
                                        // }}
                                        className="custom-claimStatus-selectbtn"
                                        options={supplierDecisionOptions}
                                        // isDisabled={claimStatus?.value === "SETTLED"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center px-3">
                            <div>
                                <Select
                                    // value={settledClaimType}
                                    // onChange={(e) => setSettledClaimType(e)}
                                    className="custom-claimStatus-selectbtn pr-3"
                                    options={supplierClaimOptions}
                                />
                            </div>
                            <div>
                                <Select
                                    // value={settledClaimType}
                                    // onChange={(e) => setSettledClaimType(e)}
                                    className="custom-claimStatus-selectbtn"
                                    options={supplierActionOptions}
                                />
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <a href="#" className="ml-3 font-size-14" title="Delete">
                                    <Tooltip title="Delete">
                                        <img src={deleteIcon}></img>
                                    </Tooltip>
                                </a>
                                <a href={undefined} className="ml-3 font-size-14" title="Copy">
                                    <Tooltip title="Copy">
                                        <img src={copyIcon}></img>
                                    </Tooltip>
                                </a>
                            </div>
                        </div>
                    </div>
                    <Divider className="my-2" />
                    <div className="ligt-greey-bg py-3 pl-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <span className="mr-3 cursor" onClick={handleMakeTabEditable}>
                                    <i className="fa fa-pencil font-size-12" aria-hidden="true"></i>
                                    <span className="ml-2">Edit</span>
                                </span>
                                <>
                                    <span className="d-flex align-items-center cursor">
                                        <DescriptionOutlinedIcon className="font-size-24" />
                                        <span className="ml-2">Add Notes</span>
                                    </span>
                                </>
                            </div>
                            <span className="mr-3 d-flex align-items-center border-radius-10 px-2 py-1">
                                <SellOutlinedIcon className=" font-size-24" />
                                <span className="ml-2">Settlement</span>
                            </span>
                        </div>
                    </div>
                    <div className="card border my-2">
                        <Box className="mt-0" sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList className="custom-tabs-div" aria-label="lab API tabs example" onChange={(e, newTabValue) => setTabValue(newTabValue)}>
                                        <Tab label={`SUPPLIER DETAILS`} value="supplierDetails" />
                                        <Tab label={`FAILURE DETAILS`} value="failureDetails" />
                                        <Tab label={`ESTIMATION DETAILS`} value="estimation" />
                                        <Tab label={`RELATED PARTS`} value="relatedparts" />
                                        <Tab label={`EVALUATION`} value="evaluation" />
                                        <Tab label={`APPROVAL & CLOSURE`} value="approvalClosure" />
                                    </TabList>
                                </Box>
                                <TabPanel value={tabValue}>
                                    {tabValue === "supplierDetails" && viewSupplierDetails()}
                                    {tabValue === "failureDetails" && viewFailureDetails()}
                                    {tabValue === "estimation" && viewEstimationDetails()}
                                    {tabValue === "relatedparts" && viewRelatedParts()}
                                    {tabValue === "evaluation" && viewEvalution()}
                                    {tabValue === "approvalClosure" && (
                                        <SupplierClaimApprovalClosure
                                            viewOnlyTab={viewOnlyTab}
                                            setViewOnlyTab={setViewOnlyTab}
                                            supplierRecord={supplierRecord}
                                            supplierItems={supplierItems}
                                            oemSupplierRecordData={oemSupplierRecordData}
                                            setOemSupplierRecordData={setOemSupplierRecordData}
                                            questionsOptions={questionsOptions}
                                            oemSupplierClaimStatusOptions={oemSupplierClaimStatusOptions}
                                            authorizationStatusRecords={authorizationStatusRecords}
                                            setAuthorizationStatusRecords={setAuthorizationStatusRecords}
                                            authorizationStatus={authorizationStatus}
                                            handleSnack={handleSnack}
                                            // finalApprovalViewOnly={finalApprovalViewOnly}
                                            // setFinalApprovalViewOnly={setFinalApprovalViewOnly}
                                        />
                                    )}
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </Modal.Body>
            </Modal>
            {searchResultOpen && (
                <SupplierClaimItemTableSearchModal
                    show={searchResultOpen}
                    hideModal={handleSearchResClose}
                    masterData={masterData}
                    supplierRecord={supplierRecord}
                    handleSnack={handleSnack}
                    handleUpdateSupplierClaim={handleUpdateSupplierClaim}
                    setSupplierItems={setSupplierItems}
                    supplierItems={supplierItems}
                />
            )}
        </>
    );
};

export default SupplierClaimRiseModal;
