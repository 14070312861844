import React, { useCallback, useState } from "react";

import penIcon from "../../../assets/images/pen.png";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { Button, Grid, TextField, Tooltip } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import { DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer } from "@mui/x-data-grid";

import Moment from "react-moment";
import PropTypes from "prop-types";

import { GENERAL_TRANSPRINT_GRID_STYLE, GRID_STYLE } from "pages/Common/constants";

const estimatedByUsers = [
  { name: "John", email: "john@gmail.com" },
  { name: "David", email: "david@gmail.com" },
  { name: "Michael", email: "michael@gmail.com" },
  { name: "Emma", email: "emma@gmail.com" },
  { name: "Robert", email: "robert@gmail.com" },
  { name: "Sarah", email: "sarah@gmail.com" },
  { name: "Daniel", email: "daniel@gmail.com" },
];

const dummyApprovalRecords = [
  {
    id: 1,
    status: "Waiting for Travel Approval",
    costCode: "Travel",
    jobDescription: "description 1",
    price: 26,
    createdAt: new Date(),
    updatedStatus: "Travel Approved",
    isUpdated: false,
  },
  {
    id: 2,
    status: "Advance Payment Requested",
    jobDescription: "description 2",
    costCode: "Advance Payment",
    price: 30,
    createdAt: new Date(),
    updatedStatus: "Advance Payment Received",
    isUpdated: false,
  },
  {
    id: 3,
    status: "Waiting for Warranty Approval",
    costCode: "Warranty",
    jobDescription: "description 3",
    price: 120,
    createdAt: new Date(),
    updatedStatus: "Warranty Approved",
    isUpdated: false,
  },
  {
    id: 4,
    status: "Waiting for Rework/Goodwill/Policy Approval",
    costCode: "Rework/Goodwill/Policy",
    jobDescription: "description 4",
    price: 90,
    createdAt: new Date(),
    updatedStatus: " Goodwill Approved",
    isUpdated: false,
  },
];

function EditToolbar(props) {
  const { setOrderApprovalPayers, setRowModesModel, orderApprovalPayers } = props;

  const handleClick = () => {
    const payerId = "new";
    setOrderApprovalPayers((oldRows) => [...oldRows, { payerId, payerName: "", billingSplit: "", price: 0, isNew: true }]);
    setRowModesModel((oldModel) => ({ ...oldModel, [payerId]: { mode: GridRowModes.Edit, fieldToFocus: "payerName" } }));
  };

  return (
    <GridToolbarContainer className="row">
      <div className="col-md-6 col-lg-8 font-weight-500">PAYERS</div>
      <div className="row col-md-6 col-lg-4" style={{ justifyContent: "right" }}>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Payer
        </Button>
      </div>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setOrderApprovalPayers: PropTypes.func.isRequired,
};

const ServiceOrderApproval = ({
  orderApprovalPayers,
  setOrderApprovalPayers,
  orderApprovalRecords,
  setOrderApprovalRecords,
  setActiveStep,
  handleSnack,
}) => {
  const [tabValue, setTabValue] = useState("costing");

  const [loading, setLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});

  // header tab change
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // update the general data
  const handleUpdateGenData = () => {
    setTabValue("moreInfo");
  };

  // update the more info
  const handleUpdateMoreInfo = () => {
    setTabValue("finances");
  };

  // row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // row edit end
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // click on edit icons
  const handleEditClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.Edit } });
  };

  // click on save icon
  const handleSaveClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.View } });
  };

  // click on delete icon
  const handleDeleteClick = (payerId) => () => {
    // removePayer(payerId)
    //   .then((success) => {
    //     handleSnack("success", "Payer has been removed!");
    //     setOrderApprovalPayers(orderApprovalPayers.filter((row) => row.payerId !== payerId));
    //   })
    //   .catch((e) => {
    //     handleSnack("error", "Payer couldn't be removed!");
    //     throw e;
    //   });
  };

  // click on cancel icon
  const handleCancelClick = (payerId) => () => {
    setRowModesModel({ ...rowModesModel, [payerId]: { mode: GridRowModes.View, ignoreModifications: true } });

    const editedRow = orderApprovalPayers.find((row) => row.payerId === payerId);
    if (editedRow.isNew) {
      setOrderApprovalPayers(orderApprovalPayers.filter((row) => row.payerId !== payerId));
    }
  };

  // order Approval Payers data update
  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: false };
        if (updatedRow.payerId === "new") {
          console.log(oldRow, newRow, orderApprovalPayers);
          //   addQuotePayer(props.quoteId, { ...updatedRow, payerId: undefined, isNew: undefined })
          //     .then((savedPayer) => {
          //       props.handleSnack("success", `Payer has been added!`);
          //       setOrderApprovalPayers(orderApprovalPayers.map((row) => (row.payerId === newRow.payerId ? savedPayer : row)));
          //       resolve(savedPayer);
          //     })
          //     .catch((e) => {
          //       props.handleSnack("error", "Payer details could not be added");
          //       resolve(oldRow);
          //     });
        } else {
          //   updatePayerData(newRow.payerId, updatedRow)
          //     .then((savedPayer) => {
          //       props.handleSnack("success", "Payer has been updated!");
          //       setOrderApprovalPayers(orderApprovalPayers.map((row) => (row.payerId === savedPayer.data.payerId ? savedPayer.data : row)));
          //       resolve(savedPayer.data);
          //     })
          //     .catch((e) => {
          //       props.handleSnack("error", "Payer details could not be updated");
          //       resolve(oldRow);
          //     });
        }
      }),
    [orderApprovalPayers]
  );

  // handle row mode model change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      headerName: "Cost Code",
      field: "costCode",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Chargeable", value: "CHARGEABLE" },
        { label: "Warranty", value: "WARRANTY" },
        { label: "Goodwill", value: "GOODWILL" },
        { label: "Rework", value: "REWORK" },
        { label: "Policy", value: "POLICY" },
        { label: "Project", value: "PROJECT" },
        { label: "General", value: "GENERAL" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Payers",
      field: "payerName",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Customer", value: "CUSTOMER" },
        { label: "OEM", value: "OEM" },
        { label: "Own", value: "OWN" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Cost Receiver",
      field: "costReceiver",
      flex: 1,
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Warranty Expense", value: "WARRANTY_EXPENSE" },
        { label: "Cost Centre", value: "COST_CENTRE" },
        { label: "Profit Centre", value: "PROFIT_CENTRE" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      headerName: "Receiver Code",
      field: "receiverCode",
      editable: true,
      flex: 1,
    },
    {
      headerName: "Billing Split",
      field: "billingSplit",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.payerId]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.payerId)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.payerId)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <img className="m-1" src={penIcon} alt="Edit" />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.payerId)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Remove">
                <DeleteIcon />
              </Tooltip>
            }
            label="Delete"
            onClick={handleDeleteClick(row.payerId)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  // consting tab content
  const costingTab = () => {
    return (
      <>
        <DataGrid
          sx={GRID_STYLE}
          loading={loading}
          rows={orderApprovalPayers}
          getRowId={(row) => row.payerId}
          autoHeight
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          experimentalFeatures={{ newEditingApi: true }}
          onProcessRowUpdateError={(error) => console.log(error)}
          processRowUpdate={processRowUpdate}
          components={{ Toolbar: EditToolbar }}
          componentsProps={{ toolbar: { setOrderApprovalPayers, setRowModesModel, orderApprovalPayers } }}
        />
      </>
    );
  };

  // update the status
  const handleUpdateApprovalStatus = (row) => {
    if (!row?.isUpdated) {
      const updatedRecords = orderApprovalRecords.map((record) => (record.id === row.id ? { ...record, isUpdated: !record.completed } : record));
      setOrderApprovalRecords(updatedRecords);
      handleSnack("success", `this task is status ${row?.updatedStatus}`);
    } else {
      handleSnack("info", `this task is already approved`);
    }
  };

  const approvalTabColumns = [
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      width: 200,
      sortable: false,
      cellClassName: "status-column-cell",
      headerClassName: "status-column-header-class",
      renderCell: ({ row }) => (
        <div
          className="d-flex justify-content-between align-items-center py-2"
          style={{ fontSize: 15, paddingInline: 5, paddingBlock: 2, borderRadius: 6, color: row?.isUpdated ? "#67d11e" : "#f84343" }}
        >
          <span
            style={{
              borderRadius: 10,
              content: '" "',
              display: "block",
              marginRight: 8,
              height: 10,
              width: 10,
              backgroundColor: row?.isUpdated ? "#67d11e" : "#f84343",
            }}
          />
          {row?.isUpdated ? row?.updatedStatus : row.status}
        </div>
      ),
    },
    {
      field: "costCode",
      headerName: "COST CODE",
      sortable: false,
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="">{row?.costCode}</span>
        </div>
      ),
    },
    {
      field: "jobDescription",
      headerName: "JOB DESCRIPTION",
      sortable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="">{row?.jobDescription}</span>
        </div>
      ),
    },
    {
      field: "orderNo",
      headerName: "DETAILS",
      sortable: false,
      flex: 1,
      width: 150,
      renderCell: ({ row }) => (
        <div className="d-flex align-items-center">
          {/* <span>{currencyFormatter.format(row?.price || 0)}</span> */}
          <span className="mx-2">
            <Moment format="DD MMMM YYYY, HH:MM a">{row?.orderDate}</Moment>
          </span>
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "ADDITIONALS/ACTION",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div
                className={`d-flex align-items-center px-2 py-1 cursor`}
                style={{ color: row.isUpdated ? "#67d11e" : "#f84343", border: `1px solid ${row.isUpdated ? "#67d11e" : "#f84343"}` }}
                onClick={() => handleUpdateApprovalStatus(row)}
              >
                <span className=" font-size-16">Approved</span>
                <span className="font-size-18 ml-2">{row?.isUpdated ? <CheckCircleOutlineIcon /> : <CancelOutlinedIcon />}</span>
              </div>
            }
            label="Assign"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // consting tab content
  const approvalTab = useCallback(() => {
    return (
      <>
        <DataGrid
          sx={GENERAL_TRANSPRINT_GRID_STYLE}
          rows={orderApprovalRecords}
          columns={approvalTabColumns.map((col) => {
            return col;
          })}
          pageSize={11}
          rowsPerPageOptions={[5, 10, 20, 50]}
          hideFooter
          disableColumnMenu
          sortingOrder={["asc", "desc"]}
          autoHeight
          disableSelectionOnClick
        />
      </>
    );
  }, [orderApprovalRecords]);

  return (
    <>
      <Box className="mt-2" sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList className="custom-tabs-div" onChange={handleChange}>
              <Tab label="COSTING" value="costing" />
              <Tab label="APPROVAL" value="approval" />
            </TabList>
          </Box>
          <TabPanel value={tabValue}>
            {tabValue === "costing" && costingTab()}
            {tabValue === "approval" && approvalTab()}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default ServiceOrderApproval;
