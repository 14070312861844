import React, { useState, useCallback } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import { Box, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import { Modal } from "react-bootstrap";

import { GRID_STYLE } from "pages/Common/constants";

const ClaimProcessFilesModal = ({
  show,
  hideModal,
  claimFilesRecords,
  setClaimFilesRecords,
  handleShowFileUploadModal,
  handleViewFileModal,
  setFileType,
}) => {
  const [rowModesModel, setRowModesModel] = useState({});

  // view file upload
  const handleViewFileImage = (row) => {
    setFileType(row.fileType);
    handleViewFileModal();
  };

  // upload new Row
  const handleUploadNewRow = () => {
    const _claimFilesRecords = [...claimFilesRecords];
    _claimFilesRecords.push({
      id: _claimFilesRecords.length + 1,
      fileName: "faultyPannel.png",
      uploadDate: "01/01/2024",
      author: "Rheem",
      fileType: "",
      category: "",
    });

    setClaimFilesRecords(_claimFilesRecords);
  };

  // change table row edit|delete mode change
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // row edit start
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // row edit stop
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  // click on row edit button
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // click on row save button
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // click on row delete button
  const handleDeleteClick = (id) => () => {
    setClaimFilesRecords(claimFilesRecords.filter((row) => row.id !== id));
  };

  // click on row cancel button
  const handleCancelClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

    const editedRow = claimFilesRecords.find((row) => row.id === id);
    if (editedRow.isNew) {
      setClaimFilesRecords(claimFilesRecords.filter((row) => row.id !== id));
    }
  };

  // row update process >> put api call
  const processRowUpdate = useCallback(
    async (newRow, oldRow) =>
      new Promise(async (resolve, reject) => {
        const updatedRow = { ...newRow, isNew: true };

        setClaimFilesRecords(claimFilesRecords.map((row) => (row.id === updatedRow.id ? { ...updatedRow, isNew: undefined } : row)));

        resolve({ ...updatedRow, isNew: undefined });
      }),
    [claimFilesRecords]
  );

  const filesColumns = [
    {
      field: "fileType",
      headerName: "File Type",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Certificate", value: "CERTIFICATE" },
        { label: "Proof", value: "PROOF" },
        { label: "Faulty", value: "FAULTY" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { label: "Certificate", value: "CERTIFICATE" },
        { label: "Proof", value: "PROOF" },
        { label: "Faulty", value: "FAULTY" },
      ],
      valueFormatter: ({ api, field, value }) => {
        const options = api.getColumn(field).valueOptions;
        const option = options.find(({ value: optionValue }) => value === optionValue);

        if (option) return option.label;
      },
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
    },
    {
      field: "uploadDate",
      headerName: "Uplaod Date",
      flex: 1,
    },
    {
      field: "author",
      headerName: "Upload By",
      flex: 1,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
            onClick={handleEditClick(row.id)}
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Upload">
                  <img
                    className="mx-1 cursor"
                    style={{ filter: "grayscale(100%) brightness(0%)" }}
                    src="../../assets/images/fileUploadIcon.png"
                    alt="File Upload icon"
                  />
                </Tooltip>
              </div>
            }
            label="Upload"
            className="textPrimary"
            color="inherit"
            onClick={handleShowFileUploadModal}
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="View File">
                  <InsertPhotoIcon />
                </Tooltip>
              </div>
            }
            label="View File"
            className="textPrimary"
            color="inherit"
            onClick={() => handleViewFileImage(row)}
          />,
        ];
      },
    },
  ];

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <>
          <div className="card px-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Document Upload</h4>
              <div className="row" style={{ justifyContent: "right" }}>
                <button type="button" className="btn btn-light bg-primary text-white mx-3 mb-2" onClick={handleUploadNewRow}>
                  + Upload New
                </button>
              </div>
            </div>
            <DataGrid
              rows={claimFilesRecords}
              columns={filesColumns}
              sx={GRID_STYLE}
              pageSizeOptions={[5]}
              autoHeight
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              experimentalFeatures={{ newEditingApi: true }}
              onProcessRowUpdateError={(error) => console.log(error)}
              processRowUpdate={processRowUpdate}
              // disableRowSelectionOnClick
            />
          </div>
          <div className="row" style={{ justifyContent: "right" }}>
            <button type="button" className="btn btn-light bg-primary text-white mx-3" onClick={hideModal}>
              Close
            </button>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimProcessFilesModal;
