import React, { useState } from "react";

import EditIcon from "@mui/icons-material/Edit";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import { Modal } from "react-bootstrap";
import Select from "react-select";

// import { FONT_STYLE_SELECT } from "../common/constants";
import { FONT_STYLE_SELECT, paymentTermsOptions } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

const ViewOrderBillingDetails = ({
  show,
  hideModal,
  handleSnack,
  orderRecord,
  setOrderRecord,
  selectAddressType,
  setSelectAddressType,
  currencyOptions,
  billingTypeOptions,
  billingFrequencyOptions,
}) => {
  const [viewData, setViewData] = useState(true);

  const [billingObj, setBillingObj] = useState({
    paymentTerms: orderRecord?.paymentTerms,
    currency: orderRecord?.currency,
    billingType: orderRecord?.billingType,
    billingFrequency: orderRecord?.billingFrequency,
    billingAddress: orderRecord?.billingAddress,
  });

  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(selectAddressType === "shippingaddress" ? true : false);

  // change select value
  const handleSelectOptionChange = (e, keyName) => {
    setBillingObj({ ...billingObj, [keyName]: e });
  };

  // change the input fields text
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setBillingObj({ ...billingObj, [name]: value });
  };

  // same as shipping address
  const handleCheckSameAsShippingAddress = (e) => {
    const { checked } = e.target;
    setSameAsShippingAddress(checked);
    if (checked) {
      let _shippingAddress = orderRecord?.shippingCondition?.value === "SHIP_TO_ME" ? orderRecord?.shippingAddress : orderRecord?.pickUpInst;
      setBillingObj({ ...billingObj, billingAddress: _shippingAddress });
    } else {
      setBillingObj({ ...billingObj });
    }
  };

  // save the billing details chnages
  const handleSaveChanges = () => {
    setSelectAddressType(sameAsShippingAddress ? "shippingaddress" : "");
    setOrderRecord({ ...orderRecord, ...billingObj });
    hideModal();
  };

  // cancel the billing details chnages
  const handleCancelChanges = () => {
    hideModal();
  };

  return (
    <Modal show={show} size="lg" centered>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h4>Billing Details</h4>
          <a className="btn-sm cursor" onClick={() => setViewData(false)}>
            <EditIcon sx={{ fontSize: 30 }} />
          </a>
        </div>
        <div className="card border px-2 py-2 mb-2">
          {viewData ? (
            <>
              <div className="row">
                <ReadOnlyField label="PAYMENT TERMS" value={billingObj?.paymentTerms?.label || "Immediate"} className="col-md-6 col-sm-6" />
                <ReadOnlyField label="CURRENCY" value={billingObj?.currency?.label || "USD"} className="col-md-6 col-sm-6" />
                <ReadOnlyField label="BILLING TYPE" value={billingObj?.billingType?.label || "Pay Fixed Price"} className="col-md-6 col-sm-6" />
                <ReadOnlyField label="BILLING FREQUENCY" value={billingObj?.billingFrequency?.label || "One Time"} className="col-md-6 col-sm-6" />
                <ReadOnlyField label="BILLING ADDRESS" value={billingObj?.billingAddress || ""} className="col-md-12 col-sm-12" />
              </div>
              {sameAsShippingAddress && (
                <FormGroup sx={{ marginTop: 0 }}>
                  <FormControlLabel
                    control={<Checkbox checked={sameAsShippingAddress} />}
                    sx={{ fontSize: 13 }}
                    // onChange={handleCheckSameAsShippingAddress}
                    label={"Same as Shipping Address"}
                  />
                </FormGroup>
              )}
            </>
          ) : (
            <>
              <div className="row input-fields">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">PAYMENT TERMS</label>
                    <Select
                      value={billingObj?.paymentTerms}
                      onChange={(e) => handleSelectOptionChange(e, "paymentTerms")}
                      options={paymentTermsOptions}
                      placeholder="Select..."
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                    <Select
                      value={billingObj?.currency}
                      onChange={(e) => handleSelectOptionChange(e, "currency")}
                      options={currencyOptions}
                      placeholder="Select..."
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">BILLING TYPE</label>
                    <Select
                      value={billingObj?.billingType}
                      onChange={(e) => handleSelectOptionChange(e, "billingType")}
                      options={billingTypeOptions}
                      placeholder="Select..."
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label className="text-light-dark font-size-12 font-weight-500">BILLING FREQUENCY</label>
                    <Select
                      value={billingObj?.billingFrequency}
                      onChange={(e) => handleSelectOptionChange(e, "billingFrequency")}
                      options={billingFrequencyOptions}
                      placeholder="Select..."
                      styles={FONT_STYLE_SELECT}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row input-fields mb-0">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">BILLING ADDRESS</label>
                      <textarea
                        id="serviceRecipentAddress"
                        name="billingAddress"
                        className="form-control border-radius-10 text-primary"
                        onChange={handleInputTextChange}
                        value={billingObj?.billingAddress}
                        placeholder="Billing Address"
                        cols="30"
                        rows="2"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <FormGroup sx={{ marginTop: 0 }}>
                  <FormControlLabel
                    control={<Checkbox checked={sameAsShippingAddress} />}
                    sx={{ fontSize: 13 }}
                    onChange={handleCheckSameAsShippingAddress}
                    label={"Same as Shipping Address"}
                  />
                </FormGroup>
              </div>
            </>
          )}
        </div>
        <div className="row px-3" style={{ justifyContent: "right" }}>
          {!viewData ? (
            <>
              <button className="btn border-primary text-primary mx-2" onClick={handleCancelChanges}>
                Cancel
              </button>
              <button className="btn bg-primary text-white" onClick={handleSaveChanges}>
                Save
              </button>
            </>
          ) : (
            <>
              <button className="btn border-primary text-primary" onClick={handleCancelChanges}>
                Cancel
              </button>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderBillingDetails;
