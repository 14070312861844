import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link } from "react-router-dom";

import CustomizedSnackbar from "pages/Common/CustomSnackBar";
import { GRID_STYLE } from "pages/Common/constants";
import { DATA_SVC_COMPONENT_DATA_MASTER_URL } from "services/CONSTANTS";
import { callGetApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import Moment from "react-moment";
import ComponentOverViewModal from "./ServiceProgressMaster/ComponentOverViewModal";
import { getComponentCodeSuggetions } from "services/searchServices";
import ComponentMasterAddUpdateModal from "./ComponentMaster/ComponentMasterAddUpdateModal";

const ComponentMaster = () => {
  const [componentRows, setComponentRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);

  const [openCompAddModal, setOpenCompAddModal] = useState(false);

  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);

  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    getComponents();
  }, []);

  // get the components
  const getComponents = () => {
    const rUrl = DATA_SVC_COMPONENT_DATA_MASTER_URL;
    setLoading(true);
    callGetApi(
      `${rUrl}?pageNumber=${1}&pageSize=${10}`,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setComponentRows(responseData);
          setLoading(false);
        } else {
          setComponentRows([]);
          setLoading(false);
        }
      },
      (error) => {
        setComponentRows([]);
        setLoading(false);
      }
    );
  };

  //  handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // vendor input search list click
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete vendor search query list
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  // reset table and search data
  const handleResetTable = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
    getComponents();
  };

  // evendor quipment input search
  const handleInputSearch = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    let searchString = tempArray[id].selectCategory.value + "~" + e.target.value;
    if (tempArray[id].selectCategory.value && e.target.value) {
      getComponentCodeSuggetions(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[id] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${id}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // select search vendor
  const handleSelectCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    // setShowSupplierDtls(false);
    // setSearchSupplierDetails(null);
  };

  // click on search
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory.value && item.inputSearch && item.selectOperator.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        await getComponentCodeSuggetions(`${searchStr}`)
          .then((res) => {
            if (res && res.length > 0) {
              setComponentRows(res);
            } else {
              setComponentRows([]);
            }
          })
          .catch((err) => {
            setComponentRows([]);
          });
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // view Component Details
  const handleViewComponetDtls = (row) => {
    setRecordId(row?.id);
    setOpenViewModal(true);
  };

  const columns = [
    {
      field: "componentNumber",
      headerName: "Component Number",
      flex: 1,
      renderCell: ({ row }) => row.componentCode || "N90058041",
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      renderCell: ({ row }) => row.serialNumber || "ZMX00507",
    },
    {
      field: "warrantyCategory",
      headerName: "Warranty Category",
      flex: 1,
      renderCell: ({ row }) => row.warrantyCategory || "Supplier Warranty",
    },
    {
      field: "warrantyStartDate",
      headerName: "Warranty Start Date",
      flex: 1,
      renderCell: ({ row }) => <Moment format="DD/MM/YYYY">{row.warrantyStartDate || "2024-03-05"}</Moment>,
    },
    {
      field: "warrantyEndDate",
      headerName: "Warranty End Date",
      flex: 1,
      renderCell: ({ row }) => <Moment format="DD/MM/YYYY">{row.warrantyEndDate || "2025-03-05"}</Moment>,
    },
    {
      field: "supplierId",
      headerName: "Supplier ID",
      flex: 1,
      renderCell: ({ row }) => row.supplierId || "SP746",
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      flex: 1,
      renderCell: ({ row }) => row.supplierName || "Caterpillar",
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={() => handleViewComponetDtls(row)}>
                <Tooltip title="View">
                  <VisibilityIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-baseline mt-3 mb-3">
            <h5 className="font-weight-600 mb-0 ">Component</h5>
            <span className="font-size-16 font-weight-400 cursor create-new-btn-span" onClick={() => setOpenCompAddModal(true)}>
              + Add New
            </span>
          </div>
          <div className="bg-primary px-3 mb-3 border-radius-6">
            <div className="row align-items-center">
              <div className="col-12 mx-2">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center bg-primary w-100">
                    <div className="d-flex mr-3 py-3" style={{ whiteSpace: "pre" }}>
                      <h5 className="mr-2 mb-0 text-white">
                        <span>Search</span>
                      </h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mr-4">
                      <div className="row align-items-center m-0">
                        {querySearchSelector.map((obj, i) => (
                          <div
                            className={`customselectPortfolio d-flex align-items-center mr-3 my-2 border-radius-6`}
                            style={{ position: "relative", zIndex: 20 - i }}
                            key={"query" + i}
                          >
                            {i > 0 && (
                              <SelectFilter
                                isClearable={true}
                                defaultValue={{ label: "And", value: "AND" }}
                                options={[
                                  { label: "And", value: "AND", id: i },
                                  { label: "OR", value: "OR", id: i },
                                ]}
                                placeholder="And/Or"
                                onChange={(e) => handleOperator(e, i)}
                                value={obj.selectOperator}
                              />
                            )}

                            <div>
                              <SelectFilter
                                options={[
                                  { label: "Component Code", value: "componentCode" },
                                  { label: "Serial Number", value: "serialNumber" },
                                ]}
                                onChange={(e) => handleSelectCategory(e, i)}
                                value={obj.selectCategory}
                              />
                            </div>
                            <div className="customselectsearch customize">
                              <input
                                className="custom-input-sleact pr-1"
                                style={{ position: "relative" }}
                                type="text"
                                placeholder="Search string"
                                value={obj.inputSearch}
                                onChange={(e) => handleInputSearch(e, i)}
                                id={"inputSearch-" + i}
                                autoComplete="off"
                              />
                              {querySearchSelector.length - 1 === i && (
                                <div className="btn bg-primary text-white" onClick={() => handleQuerySearchClick()}>
                                  <span className="mr-2">
                                    <SearchIcon />
                                  </span>
                                  SEARCH
                                </div>
                              )}
                              {obj.selectOptions && obj.selectOptions.length > 0 && (
                                <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete scrollbar-${i} style`} id="style">
                                  {obj.selectOptions.map((currentItem, j) => (
                                    <li className="list-group-item" key={j} onClick={(e) => handleSearchListClick(e, currentItem, obj, i)}>
                                      {`${currentItem[`${obj.selectCategory?.value}`]}`}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        ))}
                        <div onClick={handleResetTable}>
                          <a className="btn-sm text-white border mr-2 cursor" style={{ border: "1px solid #872FF7" }}>
                            <Tooltip title="Reset">
                              <CachedIcon />
                            </Tooltip>
                          </a>
                        </div>
                        <div>
                          <Link to="#" className="btn-sm text-black border mr-2" style={{ border: "1px solid #872FF7" }}>
                            <span style={{ color: "white" }}>+</span>
                          </Link>
                        </div>

                        <div onClick={handleDeleteQuerySearch}>
                          <Link to="#" className="btn-sm border mr-2">
                            <i className="fa fa-trash fa-lg" style={{ color: "white" }}></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <DataGrid
              loading={loading}
              rows={componentRows}
              columns={columns}
              sx={GRID_STYLE}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              rowsPerPageOptions={[5, 10, 20, 50]}
              getRowId={(row) => row.id}
              autoHeight
            />
          </div>
        </div>
      </div>

      {openViewModal && (
        <ComponentOverViewModal show={openViewModal} hideModal={() => setOpenViewModal(false)} recordId={recordId} handleSnack={handleSnack} />
      )}

      {openCompAddModal && (
        <ComponentMasterAddUpdateModal show={openCompAddModal} hideModal={() => setOpenCompAddModal(false)} handleSnack={handleSnack} />
      )}
    </>
  );
};

export default ComponentMaster;
