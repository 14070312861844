import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { TextField, Tooltip } from "@mui/material";
import { FONT_STYLE } from "pages/Common/constants";
import { vi } from "faker/lib/locales";

const EquipmentServiceReportModal = ({ show, hideModal, rowData }) => {
  const [recordData, setRecordData] = useState({ ...rowData });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);

  // input value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center p-2">
            <h4>Service Report</h4>
            {recordData?.id && (
              <Tooltip title="Edit">
                <span className="upload-icon-button cursor" onClick={() => setViewModeOn(false)}>
                  <EditOutlinedIcon sx={{ fontSize: 40 }} />
                </span>
              </Tooltip>
            )}
          </div>
          <div className="px-2 mb-3">
            <div className="card border px-3 mb-3">
              {!viewModeOn ? (
                <>
                  <div className="row input-fields mt-3">
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">REPORT NO.</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">DATE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">JOB NO.</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Job No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">SMR</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">M/C SL. NO</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">M/C MODEL NO.</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ENGINE MODEL NO.</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ENGINE SL NO.</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">ATTACHMENTS</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONDITION OF MACHINE</label>
                        <input
                          type="text"
                          className="form-control border-radius-10 text-primary"
                          name="partNumber"
                          placeholder="Report No."
                          value={recordData?.partNumber}
                          onChange={handleInputTextChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">CONDITION OF MACHINE</label>
                        <textarea
                          name="customercomplaints"
                          cols="30"
                          rows="2"
                          placeholder="Customer Complaints"
                          className="form-control border-radius-10 text-primary"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label className="text-light-dark font-size-12 font-weight-500">JOBS ATTENDED</label>
                        <textarea
                          name="customercomplaints"
                          cols="30"
                          rows="2"
                          placeholder="Jobs Attended"
                          className="form-control border-radius-10 text-primary"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EquipmentServiceReportModal;
