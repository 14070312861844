import React, { useState } from "react";
import { CheckCircle, ArrowForward } from "@mui/icons-material"; // MUI icons
import "./Stepper.css"; // Make sure to create this CSS file
import { Box, Grid } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// const steps = [
//   { label: "Estimation", completed: true },
//   { label: "Approval", completed: false },
//   { label: "Order", completed: false },
//   { label: "Invoice", completed: false },
//   // { label: "Project delivered", completed: false },
// ];

function SparePartsStepper({ activeStep, setActiveStep, steps = [] }) {
  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <div className="d-flex justify-content-between align-items-center">
              {steps.map((step, index) => (
                <>
                  <div key={index} className={`order-steper ${index <= activeStep ? "active" : ""}`} onClick={() => handleClick(index)}>
                    {step.completed || index <= activeStep ? (
                      <CheckCircleIcon className="check-icon" sx={{ fontSize: 30 }} />
                    ) : (
                      <span className="step-number">{index + 1}</span>
                    )}
                    <span className="label">{step.label}</span>
                  </div>
                  {index < steps.length - 1 && <ArrowForwardIosIcon className="arrow-icon" />}
                </>
              ))}
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default SparePartsStepper;
