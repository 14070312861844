import React, { useState } from "react";

import { Divider } from "@mui/material";

import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE_SELECT } from "pages/Common/constants";
import SearchBox from "pages/Common/SearchBox";
import { serviceGroupOptions, serviceLocationTypeOptions } from "../FieldServiceConstants";
import { employeeSearch } from "services/searchServices";

const LocationTypeOptions = [
  { label: "Service Workshop", value: "serviceworkshop" },
  { label: "Service Centre", value: "servicecenter" },
  { label: "Workshop & Service centre", value: "workshopservicecentre" },
];

const ServiceEmployeeSearchModal = ({ show, hideModal, handleSnack, locationRow, locationRecords, setLocationRecords }) => {
  const [recordData, setRecordData] = useState({
    employeeNumber: "",
    employeeName: "",
  });

  const [locationRecord, setLocationRecord] = useState({
    ...locationRow,
    locationType: serviceLocationTypeOptions.find((item) => item?.value === locationRow?.locationType) || serviceLocationTypeOptions[0],
    serviceGroup: serviceGroupOptions.find((item) => item?.value === locationRow?.serviceGroup) || serviceGroupOptions[0],
  });

  const [newLocation, setNewLocation] = useState({
    locationName: "",
    address: "",
    locationType: "",
    serviceGroup: "",
    totalEmployees: 0,
  });
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInputFields, setShowInputFields] = useState(false);

  const [searchEmpResults, setSearchEmpResults] = useState([]);
  const [noOptionsEmp, setNoOptionsEmp] = useState(false);

  // Handle input change
  const handleInputChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setNewLocation((prev) => ({
      ...prev,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  // Search Customer2ith customer ID
  const handleEmpSearch = async (searchCustfieldName, searchText) => {
    setSearchEmpResults([]);
    recordData.employeeNumber = searchText;
    if (searchText) {
      await employeeSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchEmpResults(result);
            setNoOptionsEmp(false);
          } else {
            setNoOptionsEmp(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    } else {
      recordData.customerId = "";
      setSearchEmpResults([]);
    }
  };

  // Select the customer from search result
  const handleEmpSelect = (type, currentItem) => {
    setRecordData({ ...recordData, employeeNumber: currentItem.employeeNumber, employeeName: currentItem.employeeName });
    setSearchEmpResults([]);
  };

  // Handle form submission
  const handleSubmit = () => {
    // const _locationRecords = locationRecords.map((item) =>
    //   item?.id === locationRow?.id
    //     ? {
    //         ...item,
    //         totalEmployees: item["employees"].length + 1,
    //         employees: [...item["employees"], { ...newLocation, id: `E0${item["employees"].length + 1}` }],
    //       }
    //     : item
    // );
    // setLocationRecords(_locationRecords);
    // setNewLocation({ locationName: "", address: "", locationType: "", serviceGroup: "", totalEmployees: 15 });
    // hideModal();
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Search & Employee</h4>
          </div>
          <Divider />
          <div className="row input-fields mt-2">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">LOCATION NAME</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="locationName"
                  placeholder="Location Name"
                  value={locationRecord?.locationName}
                  //   onChange={(e) => setNewLocation({ ...newLocation, locationName: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">LOCATION TYPE</label>
                <Select
                  className="text-primary"
                  name="locationType"
                  value={locationRecord?.locationType}
                  options={serviceLocationTypeOptions}
                  styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SERVICE GROUP</label>
                <Select
                  className="text-primary"
                  name="serviceGroup"
                  value={locationRecord?.serviceGroup}
                  options={serviceGroupOptions}
                  styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">TOTAL EMPLOYEE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="totalEmployees"
                  placeholder="Total Employee"
                  value={locationRecord.totalEmployees}
                  disabled
                  //   onChange={(e) => setNewLocation({ ...newLocation, totalEmployees: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">ADDRESS</label>
                <textarea
                  name="address"
                  cols="20"
                  rows="1"
                  placeholder="Address"
                  className="form-control border-radius-10 text-primary"
                  value={newLocation.address}
                  onChange={(e) => setNewLocation({ ...newLocation, address: e.target.value })}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row input-fields mt-3">
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">EMPLOYEE ID</label>
                <SearchBox
                  value={recordData?.employeeNumber}
                  onChange={(e) => handleEmpSearch("employeeNumber", e.target.value)}
                  type="employeeNumber"
                  result={searchEmpResults}
                  onSelect={handleEmpSelect}
                  noOptions={noOptionsEmp}
                  placeholder="Employee Id"
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">EMPLOYEE NAME</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="employeeName"
                  placeholder="Employee Name"
                  value={recordData?.employeeName}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row px-4 mt-4" style={{ justifyContent: "right" }}>
            <button className="btn border-primary text-primary mx-3" onClick={hideModal}>
              Cancel
            </button>
            <button className="btn bg-primary text-white" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ServiceEmployeeSearchModal;
