export const timePeriodOptions = ["Last 2 Years", "1 Year", "6 Months", "1 Month", "1 Week"];

export const modelOptions = ["Rheem", "Solahart", "Paloma", "Vulcan"];

export const failureOptions = [
  "Shut down",
  "Not operating correctly",
  "Flame out",
  "Ignition failure",
  "Compressor failure",
  "Lock-out condition",
  "Evaporator frosted over",
  "Air pressure switch closed",
  "Discharge temperature above the limit",
  "Air pressure switch open",
];

export const productFamilyOptions = ["Water Heater", "Gas Heater", "Solar Panel", "Inverter"];

export const partGroupsOptions = ["Thermostat", "Heating element", "Sensors", "PV Panels"];

export const regionOptions = ["Melbourne", "Perth", "Brisbane", "Sydney", "Darwin", "Gold Coast", "Canberra", "Hobart"];

export const partsFailureLines = [
  { partName: "Valve", percentage: 0.97 },
  { partName: "Anode rod", percentage: 0.63 },
  { partName: "Inlet", percentage: 0.19 },
  { partName: "Outlet", percentage: 0.15 },
  { partName: "Expansion Tank", percentage: 0.05 },
];

export const regionLines = [
  { cityName: "Brisbane", percentage: 0.63 },
  { cityName: "Sydney", percentage: 0.42 },
  { cityName: "Perth", percentage: 0.31 },
  { cityName: "Melbourne", percentage: 0.19 },
  { cityName: "Canberra", percentage: 0.12 },
];

export const failureModeLines = [
  { modeName: "Shut down", percentage: 0.49 },
  { modeName: "Contamination", percentage: 0.47 },
  { modeName: "Water leak", percentage: 0.22 },
  { modeName: "Flame out", percentage: 0.11 },
  { modeName: "Ignition failure", percentage: 0.11 },
];

export const dataTopCity = [
  { city: "Melbourne", claim_count: 2699, perc: "29.99" },
  { city: "Perth", claim_count: 1759, perc: "19.54" },
  { city: "Brisbane", claim_count: 1686, perc: "18.73" },
  { city: "Sydney", claim_count: 914, perc: "10.16" },
  { city: "Darwin", claim_count: 675, perc: "7.50" },
];

export const dataForParts = [
  { part_name: "Thermal Expansion Valve", part_no: "N98000008", claim_count: 2676, perc: 29.73 },
  { part_name: "Power Control Board", part_no: "0R6478", claim_count: 1876, perc: 20.84 },
  { part_name: "Thermistor", part_no: "1757896", claim_count: 939, perc: 10.43 },
  { part_name: "Fan Motor", part_no: "N98001005", claim_count: 246, perc: 2.73 },
  { part_name: "T3a Sensor", part_no: "6I6371", claim_count: 237, perc: 2.63 },
  { part_name: "Regeneration Valve", part_no: "N88409026", claim_count: 236, perc: 2.62 },
  { part_name: "Clam Cylinder", part_no: "001706", claim_count: 226, perc: 2.51 },
  { part_name: "Cab Riser Cylinder", part_no: "N88409029", claim_count: 225, perc: 2.5 },
  { part_name: "Heating Element", part_no: "0S1619", claim_count: 225, perc: 2.5 },
  { part_name: "Drive Shaft Hub", part_no: "003397", claim_count: 224, perc: 2.49 },
  { part_name: "Platform Cylinder", part_no: "0R5217", claim_count: 224, perc: 2.49 },
  { part_name: "Lower Heating Element", part_no: "001708", claim_count: 221, perc: 2.46 },
  { part_name: "Hyd Oil Temp Sensor", part_no: "N88452100", claim_count: 220, perc: 2.44 },
  { part_name: "Travel Sheave", part_no: "N90058041", claim_count: 217, perc: 2.41 },
  { part_name: "T4 Sensor", part_no: "3681P053", claim_count: 214, perc: 2.38 },
  { part_name: "Swing Pinion", part_no: "N90198054", claim_count: 209, perc: 2.32 },
  { part_name: "Control Board", part_no: "2453804", claim_count: 200, perc: 2.22 },
  { part_name: "Gas Control Valve", part_no: "0R6333", claim_count: 196, perc: 2.18 },
  { part_name: "Pilot Hydrolic Control", part_no: "001707", claim_count: 189, perc: 2.1 },
];

export const dataForModels = [
  { model: "CB10", claim_count: 350, perc: 3.89 },
  { model: "TL642E", claim_count: 340, perc: 3.78 },
  { model: "994K", claim_count: 323, perc: 3.59 },
  { model: "966K", claim_count: 259, perc: 2.88 },
  { model: "CS64B", claim_count: 257, perc: 2.86 },
  { model: "C32", claim_count: 204, perc: 2.27 },
  { model: "C27", claim_count: 177, perc: 1.97 },
  { model: "140 GC", claim_count: 174, perc: 1.93 },
  { model: "3516C", claim_count: 166, perc: 1.84 },
  { model: "C15", claim_count: 156, perc: 1.73 },
  { model: "C12", claim_count: 111, perc: 1.23 },
  { model: "794 AC", claim_count: 109, perc: 1.21 },
  { model: "262C", claim_count: 94, perc: 1.04 },
];

export const eachModelTop5 = [
  {
    model: "140 GC",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 46, perc: 26.44 },
      { part_name: "Power Control Board", total_claims: 44, perc: 25.29 },
      { part_name: "Thermistor", total_claims: 22, perc: 12.64 },
      { part_name: "Cab Riser Cylinder", total_claims: 8, perc: 4.6 },
      { part_name: "Hyd Oil Temp Sensor", total_claims: 6, perc: 3.45 },
    ],
  },
  {
    model: "262C",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 27, perc: 28.72 },
      { part_name: "Power Control Board", total_claims: 13, perc: 13.83 },
      { part_name: "Thermistor", total_claims: 9, perc: 9.57 },
      { part_name: "Cab Riser Cylinder", total_claims: 5, perc: 5.32 },
      { part_name: "Regeneration Valve", total_claims: 5, perc: 5.32 },
    ],
  },
  {
    model: "3516C",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 48, perc: 28.92 },
      { part_name: "Power Control Board", total_claims: 32, perc: 19.28 },
      { part_name: "Thermistor", total_claims: 20, perc: 12.05 },
      { part_name: "Drive Shaft Hub", total_claims: 8, perc: 4.82 },
      { part_name: "T4 Sensor", total_claims: 7, perc: 4.22 },
    ],
  },
  {
    model: "794 AC",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 30, perc: 27.52 },
      { part_name: "Power Control Board", total_claims: 22, perc: 20.18 },
      { part_name: "Thermistor", total_claims: 13, perc: 11.93 },
      { part_name: "Cab Riser Cylinder", total_claims: 5, perc: 4.59 },
      { part_name: "Drive Shaft Hub", total_claims: 5, perc: 4.59 },
    ],
  },
  {
    model: "C12",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 44, perc: 39.64 },
      { part_name: "Power Control Board", total_claims: 18, perc: 16.22 },
      { part_name: "Thermistor", total_claims: 11, perc: 9.91 },
      { part_name: "Fan Motor", total_claims: 6, perc: 5.41 },
      { part_name: "Lower Heating Element", total_claims: 5, perc: 4.5 },
    ],
  },
  {
    model: "C15",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 45, perc: 28.85 },
      { part_name: "Power Control Board", total_claims: 30, perc: 19.23 },
      { part_name: "Thermistor", total_claims: 17, perc: 10.9 },
      { part_name: "Platform Cylinder", total_claims: 8, perc: 5.13 },
      { part_name: "Fan Motor", total_claims: 5, perc: 3.21 },
    ],
  },
  {
    model: "C27",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 55, perc: 31.07 },
      { part_name: "Power Control Board", total_claims: 37, perc: 20.9 },
      { part_name: "Thermistor", total_claims: 25, perc: 14.12 },
      { part_name: "Fan Motor", total_claims: 7, perc: 3.95 },
      { part_name: "Regeneration Valve", total_claims: 7, perc: 3.95 },
    ],
  },
  {
    model: "C32",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 54, perc: 26.47 },
      { part_name: "Power Control Board", total_claims: 49, perc: 24.02 },
      { part_name: "Thermistor", total_claims: 24, perc: 11.76 },
      { part_name: "Clam Cylinder", total_claims: 9, perc: 4.41 },
      { part_name: "Fan Motor", total_claims: 8, perc: 3.92 },
    ],
  },
  {
    model: "CB10",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 107, perc: 30.57 },
      { part_name: "Power Control Board", total_claims: 71, perc: 20.29 },
      { part_name: "Thermistor", total_claims: 28, perc: 8.0 },
      { part_name: "Lower Heating Element", total_claims: 18, perc: 5.14 },
      { part_name: "Heating Element", total_claims: 14, perc: 4.0 },
    ],
  },
  {
    model: "CS64B",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 87, perc: 33.85 },
      { part_name: "Power Control Board", total_claims: 53, perc: 20.62 },
      { part_name: "Thermistor", total_claims: 19, perc: 7.39 },
      { part_name: "Fan Motor", total_claims: 13, perc: 5.06 },
      { part_name: "Cab Riser Cylinder", total_claims: 10, perc: 3.89 },
    ],
  },
  {
    model: "966K",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 73, perc: 28.19 },
      { part_name: "Power Control Board", total_claims: 62, perc: 23.94 },
      { part_name: "Thermistor", total_claims: 24, perc: 9.27 },
      { part_name: "Clam Cylinder", total_claims: 10, perc: 3.86 },
      { part_name: "Control Board", total_claims: 8, perc: 3.09 },
    ],
  },
  {
    model: "994K",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 94, perc: 29.1 },
      { part_name: "Power Control Board", total_claims: 76, perc: 23.53 },
      { part_name: "Thermistor", total_claims: 32, perc: 9.91 },
      { part_name: "Cab Riser Cylinder", total_claims: 12, perc: 3.72 },
      { part_name: "Lower Heating Element", total_claims: 11, perc: 3.41 },
    ],
  },
  {
    model: "TL642E",
    data: [
      { part_name: "Thermal Expansion Valve", total_claims: 101, perc: 29.71 },
      { part_name: "Power Control Board", total_claims: 75, perc: 22.06 },
      { part_name: "Thermistor", total_claims: 37, perc: 10.88 },
      { part_name: "Drive Shaft Hub", total_claims: 11, perc: 3.24 },
      { part_name: "Fan Motor", total_claims: 10, perc: 2.94 },
    ],
  },
];

export const dataForInsights = [
  {
    ques: "Hot water runs out quickly",
    ans: "Failed heating element. High voltage could be damaging the heating elements. Make sure that the heating element is bad before you replace it.",
    recommended_parts: "Heating element, Thermistor, Control board",
  },
  {
    ques: "Replace a water heater thermostat",
    ans: "Shut off the electricity. Remove the heating element’s access cover. Remove the plastic shield. Remove the thermostat. Install the new thermostat.",
    recommended_parts: "Thermistor, Control board",
  },
  {
    ques: "Water heater's pressure relief valve dripping water",
    ans: "Overheating tripped the valve. Shut off the circuit breakers, check the thermostats and heating elements. A failed thermostat can cause the element to heat constantly, resulting in overheating. Replace failed thermostats and shorted heating elements.",
    recommended_parts: "Temperature-pressure (T&P) relief valve, Heating element",
  },
  {
    ques: "Heating late",
    ans: "If your electric water heater runs out of hot water quickly, or if the water isn’t as hot as it should be, the heating element could be the problem.",
    recommended_parts: "Heating element, Control board",
  },
  {
    ques: "Hot water smells like rotten eggs",
    ans: "Certain water conditions cause a reaction between a water heater's anode rod and supply water, causing hydrogen sulfide gas to dissolve in the tank water. Installing a less-reactive aluminum alloy anode rod and chlorinating the water supply system eliminates the rotten-egg smell in hot water.",
    recommended_parts: "Less-reactive zinc-aluminum anode rod",
  },
  {
    ques: "Making the water hot enough",
    ans: "Carefully adjust the water heater's thermostat setting if it’s set below 120 degrees Fahrenheit. Adjust the temperature setting in small increments to avoid producing scalding hot water.",
    recommended_parts: "Heating element, Thermostat",
  },
  {
    ques: "Water in drain pan",
    ans: "Check for leaks at the water pipe fittings. Tighten the water pipe connections if necessary. Check the drain valve and temperature-pressure relief valve for leaks. Replace the drain valve or relief valve if they leak.",
    recommended_parts: "Drain valve, Temperature-pressure relief valve",
  },
  {
    ques: "Water heater is not heating water",
    ans: "Check both house circuit breakers. Reset the circuit breakers by shutting them off and then flipping them back on.",
    recommended_parts: "Heating element, Thermostat",
  },
  {
    ques: "Heating element fails often",
    ans: "There may be too much sediment inside the tank. Disconnect power and shut off the water supply, and then drain the tank to flush out sediment. Refill the tank before restoring electrical power to avoid damaging the heating element.",
    recommended_parts: "Heating element, Control board",
  },
  {
    ques: "Temperature fluctuates",
    ans: "The upper and lower thermostats must fit snug against the side of the tank to sense and regulate the temperature correctly. Turn off the power to the water heater and remove the access covers to check the thermostats’ positions. Adjust the thermostat brackets if necessary to keep the thermostats snug against the tank.",
    recommended_parts: "Thermostat, Sensor",
  },
];

export const warrantySearchOptions = [
  { label: "Serial Number", value: "serialNumber" },
  { label: "Component Code", value: "componentCode" },
  { label: "Equipment Id", value: "equipmentNumber" },
  { label: "Model Number", value: "ModelNo" },
];

export const warrantyCategoryOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Extended", value: "EXTENDED" },
  { label: "Service Letter", value: "SERVICE_LETTER" },
];

export const warrantyBasisOptions = [
  { label: "Time", value: "TIME" },
  { label: "Usage", value: "USAGE" },
  { label: "Both", value: "BOTH" },
];

export const warrantyUnitOptions = [
  { label: "Months", value: "MONTHS" },
  { label: "Hours", value: "HOURS" },
  { label: "Miles", value: "MILES" },
  { label: "Km", value: "KM" },
];

export const warrantyStatusOptions = [
  { label: "In Warranty", value: "IN_WARRANTY" },
  { label: "Out of warranty", value: "OUT_OF_WARRANTY" },
  { label: "Denied warranty", value: "DENIED_WARRANTY" },
];

export const installerTypeOptions = [
  { label: "OEM", value: "OEM" },
  { label: "Dealer", value: "DEALER" },
  { label: "Distributor", value: "DISTRIBUTOR" },
];

export const machineComponentOptions = [
  { label: "Machine", value: "MACHINE" },
  { label: "Component", value: "COMPONENT" },
];

export const warrantyTypeOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Extended", value: "EXTENDED" },
  { label: "PDI", value: "PDI" },
  { label: "Goodwill", value: "GOODWILL" },
  { label: "Late Warranty", value: "LATE_WARRANTY" },
];

export const makeOptions = [
  { label: "Rheem", value: "Rheem" },
  { label: "Ruud", value: "Ruud" },
  { label: "Friedrich", value: "Friedrich" },
  { label: "Raypak", value: "Raypak" },
  { label: "Sure Comfort", value: "Sure Comfort" },
  { label: "WeatherKing", value: "WeatherKing" },
  { label: "Eemax", value: "Eemax" },
  { label: "Richmond", value: "Richmond" },
  { label: "IBC", value: "IBC" },
  { label: "Intergas", value: "Intergas" },
  { label: "Splendid", value: "Splendid" },
  { label: "Solahart", value: "Solahart" },
];

export const familyOptions = [
  { label: "Heating element", value: "Heating element" },
  { label: "Thermistor", value: "Thermistor" },
  { label: "Control board", value: "Control board" },
  { label: "Lower heating element", value: "Lower heating element" },
  { label: "T4 sensor", value: "T4 sensor" },
  { label: "Thermal expansion valve", value: "Thermal expansion valve" },
  { label: "T3a sensor", value: "T3a sensor" },
  { label: "Power control board", value: "Power control board" },
  { label: "Fan motor", value: "Fan motor" },
];

export const claimStatusOptions = [
  { label: "Registered", value: "REGISTERED" },
  { label: "Acknowledged", value: "CLAIM_ACKNOWLEDGED" },
  { label: "Accepted", value: "CLAIM_ACCEPTED" },
  { label: "Rejected", value: "CLAIM_REJECTED" },
  { label: "Submitted", value: "CLAIM_SUBMITTED" },
  { label: "Canceled", value: "CANCELED" },
  { label: "Settled", value: "SETTLED" },
  { label: "Contested", value: "CONTESTED" },
  { label: "Archived", value: "ARCHIVED" },
  { label: "Closed", value: "CLOSED" },
];

export const claimTypeOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Extended", value: "EXTENDED" },
  { label: "Service Letter", value: "SERVICE_LETTER" },
  { label: "Goodwill", value: "GOODWILL" },
];

export const payerOptions = [
  { label: "Customer", value: "CUSTOMER" },
  { label: "Warranty", value: "WARRANTY" },
  { label: "GoodWill", value: "GOODWILL" },
  { label: "Insurer", value: "INSURER" },
];

export const warrantyNotesList = [
  {
    title: "Warranty Coverage",
    subTitle: "",
    contentList: [
      "The company warranty that the product(s) manufactured, sold, and delivered by the company to the customer shall be free from material defects and workmanship, conform to applicable specifications and perform by the product documentation under normal usage for 12 months or 2000 hours, whichever is earlier.",
    ],
  },
  {
    title: "Warranty Exclusions",
    subTitle: "The warranty does not cover:",
    contentList: [
      "a). Consumables include filters, engine oil, and batteries, unless product damage occurs due to materials or workmanship defects.",
      "b). Damage caused by accident, abuse, misuse, fire, earthquake, and other external causes.",
      "c). Damage caused by operating outside the company’s published guidelines.",
      "d). Defects caused by normal wear and tear or due to normal product ageing",
    ],
  },
  {
    title: "Claim Procedures",
    subTitle: "",
    contentList: ["The customer must notify within 10 days of the discovery of any claimed defect, specifying the nature of the claimed defect."],
  },
];

export const filesRecords = [
  {
    id: 1,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
  {
    id: 2,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
  {
    id: 3,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
  {
    id: 4,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
  {
    id: 5,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
  {
    id: 6,
    fileName: "abcd tech.pdf",
    uploadDate: "01/01/2024",
    author: "Rheem",
    fileType: "",
    category: "",
  },
];

export const warrantyRequestObj = {
  warrantyId: 0,
  title: "",
  category: "",
  basis: "",
  unit: "",
  warrantyStartDate: "",
  warrantyEndDate: "",
  warrantyStartUsage: 0,
  warrantyEndUsage: 0,
  modelNumber: "",
  make: "",
  machineSerialNumber: "",
  componentCode: "",
  serialNumber: "",
  dateOfInstall: "",
  warrantyCertificate: "",
  proofOfInstall: "",
  warrantyStatus: "",
  notes: "",
  dateOfSale: "",
  manufactureDate: "",
  installerDetails: null,
  customerDetails: null,
  claimDetails: null,
  warrantyAgreement: "",
  replacement: false,
  machine: false,
};

export const customerRequestObj = {
  customerId: 0,
  customerName: "",
  email: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
};

export const installerRequestObj = {
  installerId: 0,
  installerType: "",
  companyName: "",
  email: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  phoneNumber: "",
};

export const yearlyWarrantyObj = {
  title: "",
  warrantyType: "",
  basis: "",
  warrantyStartDate: "",
  warrantyEndDate: "",
  // warrantyStartDate: new Date(),
  // warrantyEndDate: new Date(),
  warrantyStartUsage: 0,
  warrantyEndUsage: 0,
  warrantyExpAccount: "",
  amount: 0,
  componentIds: [],
  costCoverageId: 0,
  warrantyIds: [],
};

// equipment Request obj
export const equipmentRequestObj = {
  id: 0,
  equipmentNumber: "",
  description: "",
  status: "",
  stockNumber: "",
  currentClient: 0,
  maker: "",
  makerSerialNumber: "",
  technicalIdendificationNumber: "",
  motorBrand: "",
  engineModel: "",
  motorSerialNumber: "",
  typeOfApplication: "",
  unitOfMeasurement: "",
  mainWork: "",
  modelPrefix: "",
  model: "",
  brand: "",
  market: "",
  productLine: "",
  productSegment: "",
  productGroup: "",
  customer: "",
  owner: "",
  geocode: "",
  sensorId: "",
  usageType: "",
  usageDescription: "",
  serviceLetter: "",
  warranty: "",
  serviceLetterId: "",
  installationDate: new Date(),
  endOfLife: 0,
  endOfLifeUnit: "",
  plannedUsage: "",
  unit: "",
  operator: "",
  contact: "",
  warrantyAvailability: "",
  yearOfManufacture: "",
  lastOwner: "",
  fleetOwner: "",
  movedInOrOutFlag: true,
  previousLocation: true,
  newLocation: true,
  movedInDate: new Date(),
  addressDTO: null,
  customerId: "",
  installer: "",
  purchaseDate: new Date(),
  placeOfPurchase: "",
  usedFor: "",
  usageCondition: "",
  warrantyStatus: "",
  warrantyId: "",
  createdBy: "",
  updatedBy: "",
  // warrantyRecords: [
  //   {
  //     id: 0,
  //     warrantyId: "string",
  //     title: "string",
  //     category: "string",
  //     basis: "string",
  //     unitOfMeasure: "string",
  //     startDate: "2024-03-16",
  //     endDate: "2024-03-16",
  //     validFor: "string",
  //     startUsage: "string",
  //     endUsage: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
  // contractRecords: [
  //   {
  //     id: 0,
  //     entitlementId: "string",
  //     title: "string",
  //     category: "string",
  //     basis: "string",
  //     amount: 0,
  //     currency: "string",
  //     validFor: "string",
  //     unitOfMeasure: "string",
  //     startDate: "2024-03-16",
  //     endDate: "2024-03-16",
  //     startUsage: "string",
  //     endUsage: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
  // usageRecords: [
  //   {
  //     id: 0,
  //     currentUsage: "string",
  //     averageUsage: "string",
  //     sensorId: "string",
  //     smuId: "string",
  //     smuType: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
  // sensorRecords: [
  //   {
  //     id: 0,
  //     sensorId: "string",
  //     smuType: "string",
  //     usageId: "string",
  //     readingDate: "2024-03-16",
  //     unit: "string",
  //     readingDescription: "string",
  //     overwrite: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
  // failureRecords: [
  //   {
  //     id: 0,
  //     partNumber: "string",
  //     amount: 0,
  //     subAssembly: "string",
  //     warranty: "string",
  //     failureDate: "2024-03-16",
  //     repairDate: "2024-03-16",
  //     usage: "string",
  //     complaint: "string",
  //     cause: "string",
  //     correction: "string",
  //     specificInformation: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
  // serviceRecords: [
  //   {
  //     id: 0,
  //     reportNumber: "string",
  //     jobNumber: "string",
  //     engineModelNumber: "string",
  //     engineSerialNumber: "string",
  //     usage: "string",
  //     repairDate: "2024-03-16",
  //     complaint: "string",
  //     action: "string",
  //     condition: "string",
  //     customerRequest: "string",
  //     engineerRemark: "string",
  //     customer: "string",
  //     siteAddress: "string",
  //     createdAt: "2024-03-16",
  //     updatedAt: "2024-03-16",
  //     createdBy: "string",
  //     updatedBy: "string",
  //   },
  // ],
};

// Claim api  response json data
export const claimRequestObj = {
  claimNumber: 0,
  modelNumber: "",
  equipmentNumber: "",
  serialNumber: "",
  componentCode: "",
  claimStatus: "",
  claimType: "",
  failDate: new Date(),
  failurePartNumber: "",
  smu: "",
  fleetNo: "",
  hourOnMachine: 0,
  hoursOnFailedPart: 0,
  uploadPhoto: "",
  partList: "",
  repairTime: 0,
  claimStory: "",
  claimNotes: "",
  claimQuestionnaire: "",
  payer: "",
  claimApprover: "",
  claimReceiptDate: new Date(),
  createdOn: new Date(),
  updatedOn: new Date(),
  createdDate: new Date(),
  closedDate: new Date(),
  appoverRejectedOn: new Date(),
  warrantyId: 0,
  assessmentId: 0,
  evaluationId: 0,
  claimOrderId: 0,
  replacement: false,
  customerNumber: "",
  customerName: "",
};

// claim order request object
export const claimOrderRequestObj = {
  customerNumber: 0,
  customerName: "",
  emailId: "",
  address: "",
  contactNumber: "",
  make: "",
  model: "",
  serialNumber: "",
  location: "",
  smu: "",
  unitNumber: "",
  repairFromDate: new Date(),
  repairToDate: new Date(),
  preparedBy: "",
  preparedOn: new Date(),
  revisedBy: "",
  revisedOn: new Date(),
  claimRequestDate: new Date(),
  claimType: "",
  description: "",
  reference: "",
  rmaType: "",
  rmaReason: "",
  rmaNumber: "",
  claimOrderStatus: "",
  claimApprover: "",
  claimOrderNumber: "",
  claiment: "",
  partnerName: "",
  warrantyId: "",
  warrantyTitle: "",
  componentCode: "",
  componentSerialNumber: "",
  partNumber: "",
  replacement: true,
  contestedTimes: "",
  version: "",
  relatedPartsIds: [],
  relatedHEId: 0,
  payerIds: [],
  settlementId: 0,
  claimValueId: 0,
  claimId: 0,
  // authorizationCode: "",
};

// assessment Request Object
export const claimAssessmentRequestObj = {
  warrantyTitle: "",
  warrantyRequestDate: new Date(),
  warrantyEndDate: new Date(),
  warrantyId: 0,
  assessmentDate: new Date(),
  machineUnderWarranty: "",
  assessmentType: "",
  complainRow1: "",
  complainRow2: "",
  complainRow3: "",
  warrantyNotes: "",
  uploadPhoto: "",
  uploadDocument: "",
  assignToFirstName: "",
  assignToLastName: "",
  assignToEmail: "",
  assignToRole: "",
  assignToPosition: "",
  claimId: 0,
};

// claim value request object
export const claimValueRequestObj = {
  type: "",
  coverageType: "",
  totalAmountClaimed: 0,
  totalPartsClaimed: 0,
  totalHoursClaimed: 0,
  totalLaborAmountClaimed: 0,
  travelClaimed: 0,
  miscClaimed: 0,
  vehicleKMClaimed: 0,
  claimOrderId: 0,
};

export const claimRelatedHERequestObj = {
  type: "",
  code: "",
  name: "",
  alternateCode: "",
  claimNumber: "",
  coverageType: "",
  repairDate: new Date(),
  workOrder: "",
  jobHours: 0,
  travelHours: 0,
  vehicleKM: 0,
  miscDetails: "",
  claimOrderId: 0,
};

export const claimSettlementRequestObj = {
  type: "",
  totalAmountAllowed: 0,
  totalPartsAllowed: 0,
  totalHoursAllowed: 0,
  totalLaborAllowed: 0,
  travelAllowed: 0,
  miscAllowed: 0,
  vehicleKMAllowed: 0,
  totalInsurance: 0,
  serviceDeduction: 0,
  settlement: "",
  settlementDate: new Date(),
  claimOrderId: 0,
};

// evaluation request object
export const evaluationRequestObj = {
  evaluatedByFirstName: "",
  evaluatedByLastName: "",
  evaluatedByEmail: "",
  evaluatedByRole: "",
  evaluatedByPosition: "",
  evaluatedOn: new Date(),
  evaluationChangedBy: "",
  evaluationChangedOn: new Date(),
  evaluationPartIds: [],
  evaluated: true,
  claimId: 0,
};

// evaluation parts request object
export const evaluationPartsRequestObj = {
  partNumber: "",
  partDescription: "",
  cylinderPack: "",
  quantity: 0,
  analysis: "KNOWN_TO_BE_FAULTY",
  returnType: "INTRA_COMPANY",
  cost: 0,
  partsType: "FAILURE_PARTS",
  evaluationId: 0,
  partsHeaderId: 0,
};
