import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tab, TextField, Tooltip } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import shareIcon from "../../assets/icons/svg/share.svg";
import folderaddIcon from "../../assets/icons/svg/folder-add.svg";
import uploadIcon from "../../assets/icons/svg/upload.svg";
import cpqIcon from "../../assets/icons/svg/CPQ.svg";
import deleteIcon from "../../assets/icons/svg/delete.svg";
import copyIcon from "../../assets/icons/svg/Copy.svg";
import editIcon from "../../assets/icons/svg/edit.svg";
import SearchIcon from "@mui/icons-material/Search";
import { MuiMenuComponent } from "pages/Operational";

import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { GET_LABOUR_PRICE, GET_SPAREPART_PRICE, PRICING_LABOUR_PRICE_SEARCH, PRICING_PARTS_PRICE_SEARCH } from "services/CONSTANTS";
import $ from "jquery";
import InputBoxSearchLoader from "pages/SolutionModules/use-case-4/useCase4Common/InputBoxSearchLoader";
import { FONT_STYLE } from "pages/Repair/CONSTANTS";
import LoadingProgress from "pages/Repair/components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import SearchBox from "pages/Common/SearchBox";
import { jobCodeSearch, sparePartSearch } from "services/searchServices";
import { useDispatch } from "react-redux";
import {
  repairActions,
  selectChargeCodeList,
  selectDropdownOption,
  selectLaborCodeList,
  selectLaborTypeList,
  selectServiceTypeList,
} from "pages/Repair/dropdowns/repairSlice";
import { useAppSelector } from "app/hooks";
const dataForPartsDummy = [
  {
    id: 1,
    country: "India",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 2,
    country: "USA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Dollar",
  },
  {
    id: 3,
    country: "AUSTRALIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "AUD",
  },
  {
    id: 4,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 5,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 6,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 7,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 8,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 9,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 10,
    country: "INDIA",
    productModel: "BS31",
    partId: 35,
    description: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
];

const dataForLaborDummy = [
  {
    id: 1,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 2,
    country: "USA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Dollar",
  },
  {
    id: 3,
    country: "AUSTRALIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "AUD",
  },
  {
    id: 4,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 5,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 6,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 7,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 8,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 9,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
  {
    id: 10,
    country: "INDIA",
    chargeCode: "BS31",
    code: 35,
    serviceType: "Motor 50HP",
    unit: "50",
    price: "200$",
    validFrom: "01/01/2024",
    validTo: "01/01/2030",
    Status: "Available",
    currency: "Rupees",
  },
];

const taxDummyRecords = [
  {
    id: 1,
    country: "India",
    taxCode: "GST",
    hsnCode: "HSN1",
    taxPercentage: "18",
    validFrom: "12/01/2024",
    validTo: "12/01/2999",
    createdAt: "01/01/2024",
    createdBy: "System",
  },
  {
    id: 2,
    country: "Germany",
    taxCode: "VAT",
    hsnCode: "HSN7",
    taxPercentage: "4",
    validFrom: "12/01/2024",
    validTo: "12/01/2999",
    createdAt: "01/01/2024",
    createdBy: "System",
  },
];

const shippingDummyRecords = [
  {
    id: 1,
    deliveryType: "Standard",
    deliveryPriority: "",
    shippingChargeType: "%",
    amount: "",
    currency: "",
    percentage: "3",
    validFrom: "12/01/2024",
    validTo: "12/01/2999",
    createdBy: "System",
    createdAt: "01/01/2024",
  },
  {
    id: 2,
    deliveryType: "Standard",
    deliveryPriority: "",
    shippingChargeType: "Amount",
    amount: "100",
    currency: "Dollar",
    percentage: "",
    validFrom: "12/01/2025",
    validTo: "12/01/2999",
    createdBy: "",
    createdAt: "01/01/2024",
  },
  {
    id: 3,
    deliveryType: "Express",
    deliveryPriority: "Urgent",
    shippingChargeType: "Amount",
    amount: "300",
    currency: "Dollar",
    percentage: "",
    validFrom: "12/01/2025",
    validTo: "12/01/2999",
    createdBy: "",
    createdAt: "01/01/2024",
  },
];

const initPartPriceData = {
  id: 0,
  priceMethod: "LIST",
  partId: "",
  description: "",
  unit: "",
  quanity: 1,
  price: 0,
  costPrice: 0,
  currency: "",
  validFrom: new Date(),
  validTo: new Date(),
  customerId: "",
  country: "",
  region: "",
  customerSegment: "",
  productSegment: "",
  productGroup: "",
  productModel: "",
  productLifecycle: "",
  demandFrequency: "",
  orderType: "",
  deliveryPriority: "",
  substitutionOption: "",
  customerGroup: "",
  //   tenantId: 74,
};

const initLaborPriceData = {
  id: 0,
  priceMethod: "LIST",
  code: "",
  description: "",
  laborType: "",
  chargeCode: "",
  serviceType: "",
  contractNumber: "",
  region: "",
  country: "",
  customerId: "",
  vendorId: "",
  costCentre: "",
  price: 0,
  costPrice: 0,
  quantity: 1,
  unit: "",
  jobCode: "",
  currency: "",
  validFrom: new Date(),
  validTo: new Date(),

  //   tenantId: 74,
};

const intShippingPriceData = {
  id: 0,
  deliveryType: "",
  deliveryPriority: "",
  shippingChargeType: "",
  amount: "",
  currency: "",
  percentage: "",
  validFrom: new Date(),
  validTo: new Date(),
  createdBy: "",
  createdAt: new Date(),
};

const iniTaxPriceData = {
  id: 0,
  country: "",
  taxCode: "",
  hsnCode: "",
  taxPercentage: "",
  validFrom: new Date(),
  validTo: new Date(),
  createdAt: new Date(),
  createdBy: "",
};

const unitOfMeasureOptions = [
  { label: "Hours", value: "HOURS" },
  { label: "Days", value: "DAYS" },
];

const customStyles = {
  control: (provided) => ({ ...provided, borderColor: "#872ff9" }),
};

function PriceMaintenance() {
  const activityOptions = ["None", "Atria", "Callisto"];
  const countryoptions = [
    { value: "usa", label: "USA" },
    { value: "india", label: "INDIA" },
    { value: "australia", label: "AUSTRALIA" },
    { value: "uk", label: "UK" },
  ];
  const modeloptions = [
    { value: "6FGV3", label: "6FGV3" },
    { value: "RTG-95XLN", label: "RTG-95XLN" },
  ];
  const joboptions = [
    { value: "GROUND_CLEAN", label: "GROUND-CLEAN" },
    { value: "ADJUST", label: "ADJUST" },
    { value: "INSPECT", label: "INSPECT" },
  ];
  const serviceoptions = [
    { value: "WARRANTY PRICE,  ", label: "WEEKEND" },
    { value: "SELL Price", label: "WEEKDAYS" },
    { value: "OVERTIME", label: "OVERTIME" },
    { value: "NORMAL", label: "NORMAL" },
  ];
  const laborpriceoptions = [
    // { value: "WARRANTY PRICE", label: "WARRANTY PRICE" },
    { value: "SELL PRICE", label: "SELL PRICE" },
  ];
  const partspriceoptions = [
    { value: "warrantyprice", label: "Warranty Price" },
    { value: "listprice", label: "List Price" },
    { value: "costprice", label: "Cost Price" },
  ];

  const taxtPriceTypeOptions = [{ value: "taxCode", label: "Tax Code" }];
  const shippingPriceOptions = [{ value: "shippingPrice", label: "Shipping Price" }];

  const chargeCodeOptions = [
    { value: "MINIMUM_SERVICE_CALL", label: "Minimum Service Call" },
    { value: "ADDITIONAL_ITEMS", label: "Additional Items" },
    {
      value: "NON_SCHEDULED_ADDITIONAL_ITEMS",
      label: "Non-Scheduled Additional Items",
    },
    { value: "EXTRA_APPROVED_TIME", label: "Extra Approved Time" },
  ];

  const [partPriceViewMode, setPartPriceViewMode] = useState(false);
  const [selcetedPartRow, setSelcetedPartRow] = useState(null);
  const [partPriceRecordData, setPartPriceRecordData] = useState(initPartPriceData);

  const [laborPriceViewMode, setLaborPriceViewMode] = useState(false);
  const [selectedLaborRow, setSelectedLaborRow] = useState(null);
  const [laborPriceRecordData, setLaborPriceRecordData] = useState(initLaborPriceData);

  const [showEditModalTax, setShowEditModalTax] = useState(false);
  const [taxPriceRecordData, setTaxPriceRecordData] = useState(iniTaxPriceData);
  const [taxPricViewMode, setTaxPricViewMode] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const [showPartsModal, setShowPartsModal] = React.useState(false);
  const [showLaborsModal, setShowLaborsModal] = React.useState(false);
  const [showEditModalShipping, setShowEditModalShipping] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [file, setFile] = useState(null);

  const [shipingPricViewMode, setShipingPricViewMode] = useState(false);
  const [shippingPriceRecordData, setshippingPriceRecordData] = useState(intShippingPriceData);

  const [searchPartsResult, setSearchPartsResult] = useState([]);
  const [noOptionsParts, setNoOptionsParts] = useState(false);
  const [searchJobCodeResult, setSearchJobCodeResult] = useState([]);
  const [noOptionsJobCode, setNoOptionsJobCode] = useState(false);

  const [activeTab, setActiveTab] = useState("parts");

  const handleOpenPartsModal = () => setShowPartsModal(true);
  const handleOpenLaborsModal = () => setShowLaborsModal(true);

  const handleOpenEditModalShipping = (rowData) => {
    setSelectedRowData(rowData);
    const _deliveryType = [
      { label: "Standard", value: "Standard" },
      { label: "Express", value: "Express" },
    ].find((item) => item?.value === rowData?.deliveryType);
    const _deliveryPriority = [{ label: "Urgent", value: "Urgent" }].find((item) => item?.value === rowData?.deliveryPriority);
    const _shippingChargeType = [
      { label: "Percentage", value: "%" },
      { label: "Amount", value: "Amount" },
    ].find((item) => item?.value === rowData?.shippingChargeType);
    const _currency = [
      { label: "USD", value: "USD" },
      { label: "INR", value: "INR" },
      { label: "EUR", value: "EUR" },
    ].find((item) => item?.label === rowData?.currency);
    setshippingPriceRecordData({
      ...rowData,
      deliveryType: _deliveryType || "",
      deliveryPriority: _deliveryPriority || "",
      shippingChargeType: _shippingChargeType || "",
      currency: _currency || "",
    });
    setShipingPricViewMode(true);
    setShowEditModalShipping(true);
  };
  const handleOpenEditModalTax = (rowData) => {
    setShowEditModalTax(true);
    setTaxPricViewMode(true);
    setSelectedRowData(rowData);
    const _country = [
      { label: "India", value: "India" },
      { label: "Germany", value: "Germany" },
      { label: "USA", value: "USA" },
    ].find((row) => row?.value === rowData?.country);
    setTaxPriceRecordData({ ...rowData, country: _country || "" });
  };

  const [dataForSpareParts, setDataForSpareParts] = useState([...dataForPartsDummy]);
  const [taxRecords, setTaxRecords] = useState([...taxDummyRecords]);
  const [shippingRecords, setShippingRecords] = useState([...shippingDummyRecords]);
  const [pageNoParts, setpageNoParts] = useState(0);
  const [pageSizeParts, setpageSizeParts] = useState(5);
  const [dataForLabour, setDataForLabour] = useState([...dataForLaborDummy]);
  const [pageNoLabor, setpageNoLabor] = useState(0);
  const [pageSizeLabor, setpageSizeLabor] = useState(5);

  const [taxPriceType, setTaxPriceType] = useState({ value: "taxCode", label: "Tax Code" });
  const [shippingPriceType, setShippingPriceType] = useState({ value: "shippingPrice", label: "Shipping Price" });

  const [sparePartsPriceType, setSparePartsPriceType] = useState({ value: "listprice", label: "List Price" });
  const [sparePartsModelType, setSparePartsModelType] = useState("");
  const [laborPriceType, setLaborPriceType] = useState({ value: "SELL PRICE", label: "SELL PRICE" });
  const [laborJobCode, setLaborJobCode] = useState("");
  const [laborChargeCode, setLaborChargeCode] = useState("");
  const [partIdInput, setPartIdInput] = useState("");
  const [partDescriptionInput, setPartDescriptionInput] = useState("");
  const [partNumberSuggestions, setPartNumberSuggestions] = useState([]);
  const [partDescriptionSuggestions, setPartDescriptionSuggestions] = useState("");
  const [loadingPartsId, setloadingPartsId] = useState(false);
  const [loadingPartsDescriptions, setloadingPartsDescriptions] = useState(false);
  const [partIdSelected, setPartIdSelected] = useState(false);
  const [partDescriptionSelected, setPartDescriptionSelected] = useState(false);

  const [partSearched, setPartSearched] = useState(false);
  const [laborSearched, setLaborSearched] = useState(false);

  const [searchedParts, setSearchedParts] = useState([]);
  const [searchedLabors, setSearchedLabors] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [selectedShippingData, setSelectedShippingData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedRowData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = () => {
    setDataForSpareParts((prevRows) => prevRows.map((row) => (row.partId === selectedRowData.partId ? selectedRowData : row)));

    handleEditModalShipp();
  };

  const handleSaveChangesTax = () => {
    setDataForSpareParts((prevRows) => prevRows.map((row) => (row.partId === selectedRowData.partId ? selectedRowData : row)));

    handleEditModalTx();
  };

  const handleLaborsModal = () => {
    setShowLaborsModal(false);
    setLaborPriceRecordData(initPartPriceData);
    setSelectedLaborRow(null);
    setLaborPriceViewMode(false);
  };

  const handleEditModalShipp = () => {
    setShowEditModalShipping(false);
    setShipingPricViewMode(false);
    setshippingPriceRecordData(intShippingPriceData);
    setSelectedRowData(null);
  };

  const handleEditModalTx = () => {
    setShowEditModalTax(false);
    setSelectedRowData(null);
    setTaxPriceRecordData(iniTaxPriceData);
    setTaxPricViewMode(false);
  };

  //
  const handleEditPartPrice = (rowData) => {
    const _country = [
      { label: "India", value: "INDIA" },
      { label: "US", value: "US" },
      { label: "Australia", value: "AUSTRALIA" },
      { label: "Germany", value: "GERMANY" },
    ].find((item) => item?.value === rowData?.country);
    const _currence = [
      { label: "USD", value: "USD" },
      { label: "INR", value: "INR" },
      { label: "AUD", value: "AUD" },
      { label: "EUR", value: "EUR" },
    ].find((item) => item?.value === rowData?.currency);
    setShowPartsModal(true);
    setPartPriceRecordData({ ...rowData, country: _country || "", currency: _currence || "" });
    setSelcetedPartRow(rowData);
    setPartPriceViewMode(true);
  };

  //
  const handleOpenEditModalLabor = (rowData) => {
    const _country = [
      { label: "India", value: "INDIA" },
      { label: "USA", value: "USA" },
      { label: "Australia", value: "AUSTRALIA" },
      { label: "Germany", value: "GERMANY" },
    ].find((item) => item?.value === rowData?.country);
    const _currence = [
      { label: "USD", value: "USD" },
      { label: "INR", value: "INR" },
      { label: "AUD", value: "AUD" },
      { label: "EUR", value: "EUR" },
    ].find((item) => item?.value === rowData?.currency);

    const _chargeCode = chargeCodeList.find((item) => item?.value === rowData?.chargeCode);
    const _serviceType = serviceTypeList.find((item) => item?.value === rowData?.serviceType);
    const _unit = unitOfMeasureOptions.find((item) => item?.value === rowData?.unit);

    setShowLaborsModal(true);
    setLaborPriceRecordData({
      ...rowData,
      country: _country || { label: "USA", value: "USA" },
      currency: _currence || "",
      chargeCode: _chargeCode || "",
      serviceType: _serviceType || "",
      unit: _unit || "",
    });
    setSelectedLaborRow(rowData);
    setLaborPriceViewMode(true);
  };

  const handlePartsModal = () => {
    setShowPartsModal(false);
    setPartPriceRecordData(initPartPriceData);
    setSelcetedPartRow(null);
    setPartPriceViewMode(false);
  };

  const handleModelChangeParts = (selectedOption) => {
    setSparePartsModelType(selectedOption);
  };
  const handleJobChangeLabor = (selectedjobOption) => {
    setLaborJobCode(selectedjobOption);
  };
  const handlePriceChangeLabor = (selectedServiceOption) => {
    setLaborPriceType(selectedServiceOption);
  };
  const handleChargeCodeChangeLabor = (selectedCountryOption) => {
    setLaborChargeCode(selectedCountryOption);
  };

  const handlePriceChangeParts = (selectedPriceOption) => {
    setSparePartsPriceType(selectedPriceOption);
  };

  const handleReadFile = (file) => {
    if (file) {
      setFile(file);
    }
  };
  const handleUploadFile = async () => {
    // console.log("Upload");
    const form = new FormData();
    form.append("file", file);
    // await uploadPartsToPartlist(partListNo, form)
    //   .then((result) => {
    //     // fetchPartsOfPartlist(partListNo, page, pageSize);
    //     handleSnack(
    //       "success",
    //       `New parts have been uploaded to the partlist: ${partListId}`
    //     );
    //     if (result) {
    //       fetchAllDetails(builderId, generalData.version);
    //     }
    //   })
    //   .catch((err) => {
    //     handleSnack("error", `Failed to upload the parts!`);
    //   });
    setFileUploadOpen(false);
  };

  const handlePartIdInput = (e) => {
    setPartNumberSuggestions([]);
    let input = e.target.value;
    setPartIdInput(input);
    setPartIdSelected(false);
    if (input) {
      setloadingPartsId(true);
      let partIdUrl = PRICING_PARTS_PRICE_SEARCH(`partId~"${input}"`);
      callGetApi(
        partIdUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setPartNumberSuggestions(responseData);
          } else {
            setPartNumberSuggestions([]);
          }
          setloadingPartsId(false); // Set loading state to false after API call completes
        },
        (error) => {
          setPartNumberSuggestions([]);
          setloadingPartsId(false); // Set loading state to false if there's an error
        }
      );
      $(`.scrollbar-${0}`).css("display", "block");
    }
  };

  const handlePartDescriptionInput = (e) => {
    setPartDescriptionSuggestions([]);
    let input = e.target.value;
    setPartDescriptionInput(input);
    setPartDescriptionSelected(false);
    if (input) {
      setloadingPartsDescriptions(true);
      let partDescUrl = PRICING_PARTS_PRICE_SEARCH(`description~"${input}"`);
      callGetApi(
        partDescUrl,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setPartDescriptionSuggestions(responseData);
          } else {
            setPartDescriptionSuggestions([]);
          }
          setloadingPartsDescriptions(false); // Set loading state to false after API call completes
        },
        (error) => {
          setPartDescriptionSuggestions([]);
          setloadingPartsDescriptions(false); // Set loading state to false if there's an error
        }
      );
      $(`.scrollbar-${1}`).css("display", "block");
    }
  };

  const handleSelectDropdownItemForpartId = (currentItem) => {
    setPartIdInput(currentItem.partId);
    $(`.scrollbar-${0}`).css("display", "none");
    setPartIdSelected(true);
    handleDataForParts();
    setPartNumberSuggestions([]);
  };

  const handleSelectDropdownItemForpartDescription = (currentItem) => {
    setPartDescriptionInput(currentItem.description);
    $(`.scrollbar-${1}`).css("display", "none");
    setPartDescriptionSelected(true);
    handleDataForParts();
    setPartDescriptionSuggestions([]);
  };

  const [loadingParts, setLoadingParts] = useState(false);
  const [loadingLabors, setLoadingLabors] = useState(false);

  const handleDataForParts = () => {
    setSearchedParts([]);
    let fullSearchUrl = PRICING_PARTS_PRICE_SEARCH("");
    let flag = false;

    if (sparePartsModelType) {
      flag = true;
      fullSearchUrl += `productModel:"${sparePartsModelType.value}"`;
    }
    if (partIdSelected) {
      fullSearchUrl += flag ? ` AND partId:"${partIdInput}"` : `partId:"${partIdInput}"`;
      flag = true;
    }
    if (partDescriptionSelected) {
      fullSearchUrl += flag ? ` AND description:"${partDescriptionInput}"` : `description:"${partDescriptionInput}"`;
      flag = true;
    }

    if (!flag) return;

    setLoadingParts(true); // Set loading to true before the API call

    callGetApi(
      fullSearchUrl,
      (response) => {
        setLoadingParts(false); // Set loading to false after the API call is successful
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSearchedParts(responseData);
          setPartSearched(true);
        } else {
          setSearchedParts([]);
        }
      },
      (error) => {
        setLoadingParts(false); // Set loading to false after the API call fails
        setSearchedParts([]);
      }
    );
  };

  const handleDataForLabors = () => {
    setSearchedLabors([]);
    let fullSearchUrl = PRICING_LABOUR_PRICE_SEARCH("");
    let flag = false;
    if (laborChargeCode) {
      flag = true;
      fullSearchUrl = fullSearchUrl + `chargeCode:"${laborChargeCode.value}"`;
    }
    if (laborJobCode) {
      fullSearchUrl = flag ? fullSearchUrl + ` AND serviceType:"${laborJobCode.value}"` : fullSearchUrl + `serviceType:"${laborJobCode.value}"`;
      flag = true;
    }
    if (!flag) return;
    setLoadingLabors(true);
    callGetApi(
      fullSearchUrl,
      (response) => {
        setLoadingLabors(false);
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setSearchedLabors(responseData);
          setLaborSearched(true);
        } else {
          setSearchedParts([]);
        }
      },
      (error) => {
        setLoadingLabors(false);
        setSearchedLabors([]);
      }
    );
  };

  const columnsforSpareParts = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      width: 70,
      valueGetter: (params) => params.row.country || "USA",
    },
    {
      field: "productModel",
      headerName: "Model",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.productModel || "992K",
    },
    { field: "partId", headerName: "Part Number", flex: 1, width: 130 },
    {
      field: "description",
      headerName: "Part Description ",
      flex: 1,
      width: 130,
    },
    { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
    { field: "price", headerName: "Unit Price", flex: 1, width: 130 },
    { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
    { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.status || "Active",
    },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleEditPartPrice(row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const columnsforShippingPrice = [
    { field: "deliveryType", headerName: "Delivery Type", flex: 1, width: 130 },
    { field: "deliveryPriority", headerName: "Delivery Priority", flex: 1, width: 130, renderCell: ({ row }) => row?.deliveryPriority || "NA" },
    {
      field: "shippingChargeType",
      headerName: "Shipping Charge Type",
      flex: 1,
      width: 130,
      renderCell: ({ row }) => (row?.shippingChargeType === "%" ? "%" : row?.shippingChargeType),
    },
    { field: "amount", headerName: "Amount", flex: 1, width: 130, renderCell: ({ row }) => (row?.amount ? `$${row?.amount}` : "") },
    { field: "Currency", headerName: "Currency", flex: 1, width: 130 },
    { field: "percentage", headerName: "%", flex: 1, width: 130, renderCell: ({ row }) => (row?.percentage === "%" ? `${row?.percentage}%` : "") },
    { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
    { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
    { field: "createdAt", headerName: "Created On", flex: 1, width: 130 },
    { field: "createdBy", headerName: "Created By", flex: 1, width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <IconButton
          // color="primary"
          onClick={() => handleOpenEditModalShipping(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const columnsforTax = [
    { field: "country", headerName: "Country", flex: 1, width: 130 },
    { field: "taxCode", headerName: "Tax Code", flex: 1, width: 130 },
    { field: "hsnCode", headerName: "HSN Code", flex: 1, width: 130 },
    { field: "taxPercentage", headerName: "Tax %", flex: 1, width: 130 },
    { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
    { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
    { field: "createdAt", headerName: "Created On", flex: 1, width: 130 },
    { field: "createdBy", headerName: "Created By", flex: 1, width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 100,
      renderCell: (params) => (
        <IconButton
          // color="primary"
          onClick={() => handleOpenEditModalTax(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const columnsforLabor = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      width: 70,
      valueGetter: (params) => params.row.country || "USA",
    },
    { field: "chargeCode", headerName: "Charge Code", flex: 1, width: 130 },
    {
      field: "jobCode",
      headerName: "Job Code",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.jobCode || laborJobCode.value || "GROUND-CLEAN",
    },
    {
      field: "serviceType",
      headerName: " Service Type ",
      flex: 1,
      width: 130,
    },
    { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
    { field: "price", headerName: "Unit Price", flex: 1, width: 130 },
    { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
    { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.status || "Active",
    },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleOpenEditModalLabor(row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const columnsforDiscount = [
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      width: 70,
      valueGetter: (params) => params.row.country || "USA",
    },
    { field: "chargeCode", headerName: "Charge Code", flex: 1, width: 130 },
    {
      field: "jobCode",
      headerName: "Job Code",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.jobCode || laborJobCode.value || "GROUND-CLEAN",
    },
    {
      field: "serviceType",
      headerName: " Service Type ",
      flex: 1,
      width: 130,
    },
    { field: "unit", headerName: "Sales Unit", flex: 1, width: 130 },
    { field: "price", headerName: "Unit Price", flex: 1, width: 130 },
    { field: "validFrom", headerName: "Valid From", flex: 1, width: 130 },
    { field: "validTo", headerName: "Valid To", flex: 1, width: 130 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      width: 130,
      valueGetter: (params) => params.row.status || "Active",
    },
    { field: "currency", headerName: "Currency", flex: 1, width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 100,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleOpenLaborsModal(row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const handleSparePartData = async () => {
    callGetApi(
      `${GET_SPAREPART_PRICE}?pageNumber=${pageNoParts}&pageSize=${pageSizeParts}`,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setDataForSpareParts(responseData);
        } else {
        }
      },
      (error) => {}
    );
  };
  const handleLabordata = async () => {
    const labor_price_url = `${GET_LABOUR_PRICE}?pageNumber=${pageNoLabor}&pageSize=${pageSizeLabor}`;
    callGetApi(
      labor_price_url,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setDataForLabour(responseData);
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleClearParts = () => {
    setSearchedParts([]);
    setPartSearched(false);
    setSparePartsModelType("");
    setPartIdInput("");
    setPartDescriptionInput("");
    setPartIdSelected(false);
    setPartDescriptionSelected("");
  };
  const handleClearLabor = () => {
    setSearchedLabors([]);
    setLaborSearched(false);
    setLaborChargeCode("");
    setLaborJobCode("");
  };

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(repairActions.fetchDropdowns());
  }, []);

  // Retrieve charge codes
  const chargeCodeList = useAppSelector(selectDropdownOption(selectChargeCodeList));

  // Retrieve labor types
  const laborTypeList = useAppSelector(selectDropdownOption(selectLaborTypeList));

  // Retrieve service types
  const serviceTypeList = useAppSelector(selectDropdownOption(selectServiceTypeList));

  // Retrieve labor codes
  const laborCodeList = useAppSelector(selectDropdownOption(selectLaborCodeList));

  useEffect(() => {
    handleDataForParts();
  }, [partIdSelected, partDescriptionSelected, sparePartsModelType]);

  useEffect(() => {
    handleDataForLabors();
  }, [laborJobCode, laborChargeCode]);

  useEffect(() => {
    handleSparePartData();
  }, [pageNoParts, pageSizeParts]);

  useEffect(() => {
    handleLabordata();
  }, [pageSizeLabor, pageNoLabor]);

  const viewPartsTab = () => {
    return (
      <>
        <div className="card p-4 border-blue">
          <div className=" mt-4 border card p-3">
            <div className="d-flex justify-content-between mb-2">
              <h4 className="text-light">Select From Options Below</h4>
              <div>
                <button className="btn btn-primary" onClick={handleClearParts}>
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PRICE TYPE
                  </label>
                  <Select
                    defaultValue={sparePartsPriceType}
                    onChange={handlePriceChangeParts}
                    options={partspriceoptions}
                    placeholder="Spare Parts"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group ">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    MODEL
                  </label>
                  <Select
                    value={sparePartsModelType}
                    onChange={handleModelChangeParts}
                    options={modeloptions}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PART NUMBER
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partIdInput}
                      onChange={handlePartIdInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                      id="style"
                      style={{ maxHeight: partNumberSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsId ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partIdInput.length != 0 && partNumberSuggestions.length === 0 && <li className="list-group-item">No Result found</li>}
                          {partIdInput.length != 0 &&
                            partNumberSuggestions.length !== 0 &&
                            partNumberSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartId(currentItem)}>
                                {currentItem.partId}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PARTS DESCRIPTION
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9", position: "relative" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partDescriptionInput}
                      onChange={handlePartDescriptionInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                      id="style"
                      style={{ maxHeight: partDescriptionSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsDescriptions ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partDescriptionInput.length != 0 && partDescriptionSuggestions.length === 0 && (
                            <li className="list-group-item">No Result found</li>
                          )}
                          {partDescriptionInput.length != 0 &&
                            partDescriptionSuggestions.length !== 0 &&
                            partDescriptionSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartDescription(currentItem)}>
                                {currentItem.description}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card border">
            <div className="d-flex align-items-center justify-content-between px-3">
              <div className="">
                <div className="d-flex ">
                  <h5 className=" mb-0">
                    <span>{sparePartsPriceType?.label || "Warranty Price"}</span>
                  </h5>
                  <p className=" mb-0">
                    <a href="#" className="ml-3 ">
                      <img src={editIcon}></img>
                    </a>
                    <a href="#" className="ml-3 ">
                      <img src={shareIcon}></img>
                    </a>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className=" text-center border-left py-4 pl-3">
                  <a href="#" className="mr-3 font-size-14 cursor" title="Upload" onClick={() => setFileUploadOpen(true)}>
                    <img src={uploadIcon}></img>
                  </a>
                  <a onClick={() => handleOpenPartsModal()} className="cursor">
                    + Add Price
                  </a>
                </div>
              </div>
            </div>
            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
              {loadingParts && <LoadingProgress />}
              {!loadingParts &&
                (!partSearched ? (
                  <DataGrid
                    sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" } }}
                    page={pageNoParts}
                    rows={dataForSpareParts}
                    columns={columnsforSpareParts}
                    pageSize={pageSizeParts}
                    checkboxSelection
                    paginationMode="server"
                    onPageChange={(newPage) => setpageNoParts(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setpageSizeParts(newPageSize);
                      setpageNoParts(0);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={100}
                  />
                ) : (
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#872ff9",
                        color: "#fff",
                      },
                    }}
                    rows={searchedParts}
                    columns={columnsforSpareParts}
                    checkboxSelection
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={searchedParts.length}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  const viewLabourTab = () => {
    return (
      <>
        <div className="card p-4 border-blue">
          <div className=" mt-4  card border p-3">
            <div className="d-flex justify-content-between mb-2">
              <h4 className="text-light">Select From Options Below</h4>
              <div>
                <button className="btn btn-primary" onClick={handleClearLabor}>
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PRICE TYPE
                  </label>
                  <Select
                    defaultValue={laborPriceType}
                    onChange={handlePriceChangeLabor}
                    options={laborpriceoptions}
                    placeholder="Select Pricetype"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    CHARGE CODE
                  </label>
                  <Select
                    value={laborChargeCode}
                    onChange={handleChargeCodeChangeLabor}
                    options={chargeCodeList}
                    placeholder="Select Charge Code"
                    isClearable
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    JOB CODE
                  </label>
                  <Select value={laborJobCode} onChange={handleJobChangeLabor} options={joboptions} placeholder="Job Code" styles={customStyles} />
                </div>
              </div>
            </div>
          </div>
          <div className="card border mt-4">
            <div className="d-flex align-items-center justify-content-between px-3">
              <div className="">
                <div className="d-flex ">
                  <h5 className=" mb-0">
                    <span>Service Price</span>
                  </h5>
                  <p className=" mb-0">
                    <a href="#" className="ml-3 ">
                      <img src={editIcon}></img>
                    </a>
                    <a href="#" className="ml-3 ">
                      <img src={shareIcon}></img>
                    </a>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className=" text-center border-left py-4 pl-3">
                  <a href="#" className="mr-3 font-size-14 cursor" title="Upload" onClick={() => setFileUploadOpen(true)}>
                    <img src={uploadIcon}></img>
                  </a>
                  <a href="#" className="cursor" onClick={handleOpenLaborsModal}>
                    + Add Labors
                  </a>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                height: 400,
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              {loadingLabors && <LoadingProgress />}
              {!loadingLabors &&
                (!laborSearched ? (
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#872ff9",
                        color: "#fff",
                      },
                    }}
                    page={pageNoLabor}
                    rows={dataForLabour}
                    columns={columnsforLabor}
                    pageSize={pageSizeLabor}
                    checkboxSelection
                    paginationMode="server"
                    onPageChange={(newPage) => setpageNoLabor(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setpageSizeLabor(newPageSize);
                      setpageNoLabor(0);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={100}
                  />
                ) : (
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#872ff9",
                        color: "#fff",
                      },
                    }}
                    rows={searchedLabors}
                    columns={columnsforLabor}
                    checkboxSelection
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={searchedLabors.length}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  const viewDiscountTab = () => {
    return (
      <>
        <div className="">
          <div className=" mt-4 border-blue card p-3">
            {/* <div className="row"> */}
            {/* <div className="col-md-6 col-sm-6"> */}
            <div className="d-flex justify-content-between mb-2">
              <h4 className="text-light">Select From Options Below</h4>
              <div>
                <button className="btn btn-primary">Clear Filters</button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PRICE TYPE
                  </label>
                  <Select defaultValue={selectedOption} onChange={setSelectedOption} options={joboptions} placeholder="Job Code" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    SERVICE TYPE
                  </label>
                  <Select defaultValue={selectedOption} onChange={setSelectedOption} options={serviceoptions} placeholder="Material/Customer" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    COUNTRY
                  </label>
                  <Select defaultValue={selectedOption} onChange={setSelectedOption} options={countryoptions} placeholder="Select Country" />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="card border mt-4">
              <div className="d-flex align-items-center justify-content-between px-3">
                <div className="">
                  <div className="d-flex ">
                    <h5 className=" mb-0">
                      <span>DISCOUNT</span>
                    </h5>
                    <p className=" mb-0">
                      <a href="#" className="ml-3 ">
                        <img src={editIcon}></img>
                      </a>
                      <a href="#" className="ml-3 ">
                        <img src={shareIcon}></img>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <div className=" text-center border-left py-4 pl-3">
                    <a href="#" className="mr-3 font-size-14 cursor" title="Upload" onClick={() => setFileUploadOpen(true)}>
                      <img src={uploadIcon}></img>
                    </a>
                    <a href="#" className="cursor" onClick={handleOpenLaborsModal}>
                      + Add Discounts
                    </a>
                  </div>
                </div>
              </div>
              <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
                <DataGrid
                  sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" } }}
                  rows={dataForLaborDummy}
                  columns={columnsforDiscount}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const viewShippingPriceTab = () => {
    return (
      <>
        <div className="card p-4 border-blue">
          <div className=" mt-4 border card p-3">
            <div className="d-flex justify-content-between mb-2">
              <h4 className="text-light">Select From Options Below</h4>
              <div>
                <button className="btn btn-primary" onClick={handleClearParts}>
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PRICE TYPE
                  </label>
                  <Select
                    defaultValue={shippingPriceType}
                    onChange={(e) => setShippingPriceType(e)}
                    options={shippingPriceOptions}
                    placeholder="Price Type"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group ">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    MODEL
                  </label>
                  <Select
                    value={sparePartsModelType}
                    onChange={handleModelChangeParts}
                    options={modeloptions}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PART NUMBER
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partIdInput}
                      onChange={handlePartIdInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                      id="style"
                      style={{ maxHeight: partNumberSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsId ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partIdInput.length != 0 && partNumberSuggestions.length === 0 && <li className="list-group-item">No Result found</li>}
                          {partIdInput.length != 0 &&
                            partNumberSuggestions.length !== 0 &&
                            partNumberSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartId(currentItem)}>
                                {currentItem.partId}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PARTS DESCRIPTION
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9", position: "relative" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partDescriptionInput}
                      onChange={handlePartDescriptionInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                      id="style"
                      style={{ maxHeight: partDescriptionSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsDescriptions ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partDescriptionInput.length != 0 && partDescriptionSuggestions.length === 0 && (
                            <li className="list-group-item">No Result found</li>
                          )}
                          {partDescriptionInput.length != 0 &&
                            partDescriptionSuggestions.length !== 0 &&
                            partDescriptionSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartDescription(currentItem)}>
                                {currentItem.description}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card border">
            <div className="d-flex align-items-center justify-content-between px-3">
              <div className="">
                <div className="d-flex ">
                  <h5 className=" mb-0">
                    <span>Shipping Price</span>
                  </h5>
                  <p className=" mb-0">
                    <a href="#" className="ml-3 ">
                      <img src={editIcon}></img>
                    </a>
                    <a href="#" className="ml-3 ">
                      <img src={shareIcon}></img>
                    </a>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className=" text-center border-left py-4 pl-3">
                  <a href="#" className="mr-3 font-size-14 cursor" title="Upload" onClick={() => setFileUploadOpen(true)}>
                    <img src={uploadIcon}></img>
                  </a>
                  {/* <a onClick={() => handleOpenPartsModal()} className="cursor"> */}
                  <a onClick={() => setShowEditModalShipping(true)} className="cursor">
                    + Add Price
                  </a>
                </div>
              </div>
            </div>

            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
              {loadingParts && <LoadingProgress />}
              {!loadingParts &&
                (!partSearched ? (
                  <DataGrid
                    sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" } }}
                    page={pageNoParts}
                    rows={shippingRecords}
                    columns={columnsforShippingPrice}
                    pageSize={pageSizeParts}
                    checkboxSelection
                    paginationMode="server"
                    onPageChange={(newPage) => setpageNoParts(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setpageSizeParts(newPageSize);
                      setpageNoParts(0);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={100}
                  />
                ) : (
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" },
                    }}
                    rows={searchedParts}
                    columns={columnsforShippingPrice}
                    checkboxSelection
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={searchedParts.length}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  // view the tax tab
  const viewTaxTab = () => {
    return (
      <>
        <div className="card p-4 border-blue">
          <div className=" mt-4 border card p-3">
            <div className="d-flex justify-content-between mb-2">
              <h4 className="text-light">Select From Options Below</h4>
              <div>
                <button className="btn btn-primary" onClick={handleClearParts}>
                  Clear Filters
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PRICE TYPE
                  </label>
                  <Select
                    defaultValue={taxPriceType}
                    onChange={(e) => setTaxPriceType(e)}
                    options={taxtPriceTypeOptions}
                    placeholder="Price Type"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group ">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    MODEL
                  </label>
                  <Select
                    value={sparePartsModelType}
                    onChange={handleModelChangeParts}
                    options={modeloptions}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PART NUMBER
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partIdInput}
                      onChange={handlePartIdInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${0} style`}
                      id="style"
                      style={{ maxHeight: partNumberSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsId ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partIdInput.length != 0 && partNumberSuggestions.length === 0 && <li className="list-group-item">No Result found</li>}
                          {partIdInput.length != 0 &&
                            partNumberSuggestions.length !== 0 &&
                            partNumberSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartId(currentItem)}>
                                {currentItem.partId}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <div className="form-group">
                  <label className="text-light-dark font-size-12 font-weight-500" for="exampleInputEmail1">
                    PARTS DESCRIPTION
                  </label>
                  <div class="input-group icons border-radius-10 " style={{ overflow: "hidden", border: "1px solid #872ff9", position: "relative" }}>
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                        <SearchIcon />
                      </span>
                    </div>
                    <input
                      type="search"
                      class="form-control search-form-control"
                      aria-label="Search Dashboard"
                      value={partDescriptionInput}
                      onChange={handlePartDescriptionInput}
                    />
                    <ul
                      className={`list-group customselectsearch-list scrollbar scrollbar-${1} style`}
                      id="style"
                      style={{ maxHeight: partDescriptionSuggestions.length > 5 ? "200px" : "auto", overflowY: "auto" }}
                    >
                      {loadingPartsDescriptions ? (
                        <InputBoxSearchLoader />
                      ) : (
                        <>
                          {partDescriptionInput.length != 0 && partDescriptionSuggestions.length === 0 && (
                            <li className="list-group-item">No Result found</li>
                          )}
                          {partDescriptionInput.length != 0 &&
                            partDescriptionSuggestions.length !== 0 &&
                            partDescriptionSuggestions.map((currentItem, j) => (
                              <li className="list-group-item" key={j} onClick={() => handleSelectDropdownItemForpartDescription(currentItem)}>
                                {currentItem.description}
                              </li>
                            ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card border">
            <div className="d-flex align-items-center justify-content-between px-3">
              <div className="">
                <div className="d-flex ">
                  <h5 className=" mb-0">
                    <span>TAX</span>
                  </h5>
                  <p className=" mb-0">
                    <a href="#" className="ml-3 ">
                      <img src={editIcon}></img>
                    </a>
                    <a href="#" className="ml-3 ">
                      <img src={shareIcon}></img>
                    </a>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className=" text-center border-left py-4 pl-3">
                  <a href="#" className="mr-3 font-size-14 cursor" title="Upload" onClick={() => setFileUploadOpen(true)}>
                    <img src={uploadIcon}></img>
                  </a>
                  {/* <a onClick={() => handleOpenPartsModal()} className="cursor"> */}
                  <a onClick={() => setShowEditModalTax(true)} className="cursor">
                    + Add Price
                  </a>
                </div>
              </div>
            </div>

            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
              {loadingParts && <LoadingProgress />}
              {!loadingParts &&
                (!partSearched ? (
                  <DataGrid
                    sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" } }}
                    page={pageNoParts}
                    rows={taxRecords}
                    columns={columnsforTax}
                    pageSize={pageSizeParts}
                    checkboxSelection
                    paginationMode="server"
                    onPageChange={(newPage) => setpageNoParts(newPage)}
                    onPageSizeChange={(newPageSize) => {
                      setpageSizeParts(newPageSize);
                      setpageNoParts(0);
                    }}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={100}
                  />
                ) : (
                  <DataGrid
                    sx={{ "& .MuiDataGrid-columnHeaders": { backgroundColor: "#872ff9", color: "#fff" } }}
                    rows={searchedParts}
                    columns={columnsforTax}
                    checkboxSelection
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={searchedParts.length}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  const fileUploadModal = () => {
    return (
      <>
        <Modal show={fileUploadOpen} onHide={() => setFileUploadOpen(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="modal-header-border">
            <Modal.Title>Import Files</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="p-3">
              <div className="add-new-recod">
                <div>
                  <FontAwesomeIcon className="cloudupload" icon={faCloudUploadAlt} />
                  <h6 className="font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <FileUploader
                    handleChange={handleReadFile}
                    name="file"
                    types={["xls", "xlsx"]}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                </div>
              </div>
              <p className="mt-3">Single upload file should not be more than 10MB. Only the .xls, .xlsx file types are allowed</p>
            </div>
          </Modal.Body>
          <div className="row m-0 p-3">
            <div className="col-md-6 col-sm-6">
              <button className="btn border w-100 bg-white" onClick={() => setFileUploadOpen(false)}>
                Cancel
              </button>
            </div>
            <div className="col-md-6 col-sm-6">
              <button className="btn btn-primary w-100" onClick={handleUploadFile} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                Upload
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  const handleChangeShpiingPriceData = (e) => {
    const { name, value } = e.target;
    setshippingPriceRecordData({ ...shippingPriceRecordData, [name]: value });
  };
  const handleChangeShpiingPriceSelectData = (keyName, e) => {
    if (keyName === "shippingChargeType") {
      setshippingPriceRecordData({ ...shippingPriceRecordData, [keyName]: e, amount: "", currency: "", percentage: "" });
    } else {
      setshippingPriceRecordData({ ...shippingPriceRecordData, [keyName]: e });
    }
  };

  const handleSaveShippinfPriceChanges = () => {
    if (shippingPriceRecordData?.id) {
      const _shippingRecords = shippingRecords.map((item) =>
        item?.id === shippingPriceRecordData?.id
          ? {
              ...shippingPriceRecordData,
              deliveryType: shippingPriceRecordData?.deliveryType?.value || "",
              deliveryPriority: shippingPriceRecordData?.deliveryPriority?.value || "",
              shippingChargeType: shippingPriceRecordData?.shippingChargeType?.value || "",
              currency: shippingPriceRecordData?.currency?.value || "",
            }
          : item
      );
      setShippingRecords(_shippingRecords);
    } else {
      const _shippingRecords = shippingRecords.push({
        ...shippingPriceRecordData,
        deliveryType: shippingPriceRecordData?.deliveryType?.value || "",
        deliveryPriority: shippingPriceRecordData?.deliveryPriority?.value || "",
        shippingChargeType: shippingPriceRecordData?.shippingChargeType?.value || "",
        currency: shippingPriceRecordData?.currency?.value || "",
        createdBy: "user",
        id: shippingRecords.length + 1,
      });
      setShippingRecords(_shippingRecords);
    }
    handleEditModalShipp();
  };

  const EditModalForShipping = () => {
    return (
      <>
        <Modal
          show={handleOpenEditModalShipping}
          onHide={() => handleEditModalShipp()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4>{shippingPriceRecordData?.id ? "Edit" : "Add"} Shipping Price</h4>
              {!shippingPriceRecordData?.id ? (
                <></>
              ) : (
                <Tooltip title="Edit">
                  <span className="upload-icon-button cursor" onClick={() => setShipingPricViewMode(false)}>
                    <EditIcon sx={{ fontSize: 30 }} />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="card border px-3 py-2 mb-0">
              {!shipingPricViewMode ? (
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">DELIVERY TYPE</label>
                      <Select
                        className="text-primary"
                        value={shippingPriceRecordData?.deliveryType}
                        onChange={(e) => handleChangeShpiingPriceSelectData("deliveryType", e)}
                        options={[
                          { label: "Standard", value: "Standard" },
                          { label: "Express", value: "Express" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">DELIVERY PRIORITY</label>
                      <Select
                        className="text-primary"
                        value={shippingPriceRecordData?.deliveryPriority}
                        onChange={(e) => handleChangeShpiingPriceSelectData("deliveryPriority", e)}
                        options={[
                          { label: "Urgent", value: "Urgent" },
                          // { label: "Express", value: "EXPRESS" },
                        ]}
                        isClearable
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SHIPPING CHARGE TYPE</label>
                      <Select
                        className="text-primary"
                        value={shippingPriceRecordData?.shippingChargeType}
                        onChange={(e) => handleChangeShpiingPriceSelectData("shippingChargeType", e)}
                        options={[
                          { label: "Percentage", value: "%" },
                          { label: "Amount", value: "Amount" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">PERCENTAGE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="percentage"
                        placeholder="Percentage"
                        onChange={handleChangeShpiingPriceData}
                        value={shippingPriceRecordData?.percentage}
                        disabled={shippingPriceRecordData?.shippingChargeType?.value !== "%"}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CURRENCY</label>
                      <Select
                        className="text-primary"
                        value={shippingPriceRecordData?.currency}
                        onChange={(e) => handleChangeShpiingPriceSelectData("currency", e)}
                        options={[
                          { label: "USD", value: "USD" },
                          { label: "INR", value: "INR" },
                          { label: "EUR", value: "EUR" },
                        ]}
                        isClearable
                        isDisabled={shippingPriceRecordData?.shippingChargeType?.value !== "Amount"}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">AMOUNT</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="amount"
                        placeholder="Amount"
                        onChange={handleChangeShpiingPriceData}
                        value={shippingPriceRecordData?.amount}
                        disabled={shippingPriceRecordData?.shippingChargeType?.value !== "Amount"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">VALID FROM</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={shippingPriceRecordData?.validFrom}
                            onChange={(e) => handleChangeShpiingPriceSelectData("validFrom", e)}
                            renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">VALID TO</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={shippingPriceRecordData?.validTo}
                            onChange={(e) => handleChangeShpiingPriceSelectData("validTo", e)}
                            renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <ReadOnlyField className="col-md-6 col-sm-6" label="DELIVERY TYPE" value={shippingPriceRecordData?.deliveryType?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="DELIVERY PRIORITY" value={shippingPriceRecordData?.deliveryPriority?.label} />
                  <ReadOnlyField
                    className="col-md-6 col-sm-6"
                    label="SHIPPING CHARGE TYPE"
                    value={shippingPriceRecordData?.shippingChargeType?.label}
                  />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="PERCENTAGE" value={shippingPriceRecordData?.percentage} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="CURRENCY" value={shippingPriceRecordData?.currency?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="AMOUNT" value={shippingPriceRecordData?.amount} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID FROM" value={shippingPriceRecordData?.validFrom} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID TO" value={shippingPriceRecordData?.validTo} />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!shipingPricViewMode ? (
              <>
                <Button variant="secondary" onClick={() => handleEditModalShipp()}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSaveShippinfPriceChanges}>
                  Save Changes
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={() => handleEditModalShipp()}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const handleTaxInputChange = (e) => {
    const { name, value } = e.target;
    setTaxPriceRecordData({ ...taxPriceRecordData, [name]: value });
  };

  const handleTaxSelectChange = (keyName, e) => {
    setTaxPriceRecordData({ ...taxPriceRecordData, [keyName]: e });
  };

  const handleSaveTaxChanges = () => {
    if (taxPriceRecordData?.id) {
      const _taxRecords = taxRecords.map((item) =>
        item?.id === taxPriceRecordData?.id ? { ...taxPriceRecordData, country: taxPriceRecordData?.country?.value || "" } : item
      );
      setTaxRecords(_taxRecords);
    } else {
      const _taxRecords = taxRecords.push({
        ...shippingPriceRecordData,
        country: taxPriceRecordData?.country?.value || "",
        createdBy: "user",
        id: taxRecords.length + 1,
      });
      setTaxRecords(_taxRecords);
    }
    handleEditModalTx();
  };

  const EditModalForTax = () => {
    return (
      <>
        <Modal show={handleOpenEditModalTax} onHide={() => handleEditModalTx()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4>{taxPriceRecordData?.id ? "Edit" : "Add"} Tax</h4>
              {!taxPriceRecordData?.id ? (
                <></>
              ) : (
                <Tooltip title="Edit">
                  <span className="upload-icon-button cursor" onClick={() => setTaxPricViewMode(false)}>
                    <EditIcon sx={{ fontSize: 30 }} />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="card border px-3 py-2 mb-0">
              {!taxPricViewMode ? (
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">COUNTRY</label>
                      <Select
                        className="text-primary"
                        value={taxPriceRecordData?.country}
                        onChange={(e) => handleTaxSelectChange("country", e)}
                        options={[
                          { label: "India", value: "India" },
                          { label: "Germany", value: "Germany" },
                          { label: "USA", value: "USA" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">TAX CODE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="taxCode"
                        placeholder="Tax Code"
                        onChange={handleChangeShpiingPriceData}
                        value={taxPriceRecordData?.taxCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">HSN CODE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="hsnCode"
                        placeholder="HSN Code"
                        onChange={handleChangeShpiingPriceData}
                        value={taxPriceRecordData?.hsnCode}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">TAX PERCENTAGE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="taxPercentage"
                        placeholder="Tax PErcentage"
                        onChange={handleChangeShpiingPriceData}
                        value={taxPriceRecordData?.taxPercentage}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">VALID FROM</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={taxPriceRecordData?.validFrom}
                            onChange={(e) => handleTaxSelectChange("validFrom", e)}
                            renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">VALID TO</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={taxPriceRecordData?.validTo}
                            onChange={(e) => handleTaxSelectChange("validTo", e)}
                            renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps }} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <ReadOnlyField className="col-md-6 col-sm-6" label="COUNTRY" value={taxPriceRecordData?.country?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="TAX CODE" value={taxPriceRecordData?.taxCode} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="HSN CODE" value={taxPriceRecordData?.hsnCode} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="TAX PERCENTAGE" value={taxPriceRecordData?.taxPercentage} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID FROM" value={taxPriceRecordData?.validFrom} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID TO" value={taxPriceRecordData?.validTo} />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!taxPricViewMode ? (
              <>
                <Button variant="secondary" onClick={() => handleEditModalTx()}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSaveTaxChanges}>
                  Save Changes
                </Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => handleEditModalTx()}>
                  Close
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  // Labor Price text change
  const handleLaborPriceInputChange = (e) => {
    const { name, value } = e.target;
    setLaborPriceRecordData({ ...laborPriceRecordData, [name]: value });
  };

  // Labor Price Select Option Value change
  const handleLaborPriceSelectChange = (keyName, e) => {
    setLaborPriceRecordData({ ...laborPriceRecordData, [keyName]: e });
  };

  const handleJobCodeSearch = async (searchCustfieldName, searchText) => {
    setSearchJobCodeResult([]);
    laborPriceRecordData.jobCode = searchText;

    if (searchText) {
      await jobCodeSearch(`jobCode~${searchText}`)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchJobCodeResult(result);
            setNoOptionsJobCode(false);
          } else {
            setNoOptionsJobCode(true);
          }
        })
        .catch((e) => {
          //   handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  const handleJobCodeSelect = (type, currentItem) => {
    setLaborPriceRecordData({ ...laborPriceRecordData, jobCode: currentItem.jobCode });
    setSearchJobCodeResult([]);
  };

  const handleSaveLaborPriceData = () => {
    const rUrl = GET_LABOUR_PRICE;
    const rObj = {
      ...laborPriceRecordData,
      chargeCode: laborPriceRecordData?.chargeCode?.value || "EMPTY",
      serviceType: laborPriceRecordData?.serviceType?.value || "EMPTY",
      unit: laborPriceRecordData?.unit?.value || "HOURS",
      country: laborPriceRecordData?.country?.value || "INDIA",
      currency: laborPriceRecordData?.currency?.value || "INR",
    };

    if (laborPriceRecordData?.id) {
      callPutApi(null, `${rUrl}/${laborPriceRecordData?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _dataForLabour = dataForLabour.map((item) => (item?.id === responseData?.id ? { ...responseData } : item));
          setDataForLabour(_dataForLabour);
        } else {
        }
      });
    } else {
      callPostApi(null, `${rUrl}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _dataForLabour = [...dataForLabour];
          _dataForLabour.unshift(responseData);
          setDataForLabour(_dataForLabour);
        } else {
        }
      });
    }
    handleLaborsModal();
  };

  const LaborsModal = () => {
    return (
      <>
        <Modal show={showLaborsModal} onHide={() => handleLaborsModal()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="bg-white">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4>{laborPriceRecordData?.id ? "EDIT" : "ADD"} LABOUR PRICE</h4>
              {!laborPriceRecordData?.id ? (
                <></>
              ) : (
                <Tooltip title="Edit">
                  <span className="upload-icon-button cursor" onClick={() => setLaborPriceViewMode(false)}>
                    <EditIcon sx={{ fontSize: 30 }} />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="card border px-3 py-2 mb-0">
              {!laborPriceViewMode ? (
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">COUNTRY</label>
                      <Select
                        className="text-primary"
                        value={laborPriceRecordData?.country}
                        onChange={(e) => handleLaborPriceSelectChange("country", e)}
                        options={[
                          { label: "India", value: "INDIA" },
                          { label: "USA", value: "USA" },
                          { label: "Australia", value: "AUSTRALIA" },
                          { label: "Germany", value: "GERMANY" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CHARGE CODE</label>
                      <Select
                        className="text-primary"
                        value={laborPriceRecordData?.chargeCode}
                        onChange={(e) => handleLaborPriceSelectChange("chargeCode", e)}
                        options={chargeCodeList}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-14 font-weight-500">JOB CODE</label>
                      <SearchBox
                        value={laborPriceRecordData?.jobCode}
                        onChange={(e) => handleJobCodeSearch("jobCode", e.target.value)}
                        type="jobCode"
                        result={searchJobCodeResult}
                        onSelect={handleJobCodeSelect}
                        noOptions={noOptionsJobCode}
                        placeholder="Job Code"
                      />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SERVICE TYPE</label>
                      <Select
                        className="text-primary"
                        value={laborPriceRecordData?.serviceType}
                        onChange={(e) => handleLaborPriceSelectChange("serviceType", e)}
                        options={serviceTypeList}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                      <div className="css-w8dmq8">*Mandatory</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SALES UNIT</label>
                      <Select
                        className="text-primary"
                        value={laborPriceRecordData?.unit}
                        onChange={(e) => handleLaborPriceSelectChange("unit", e)}
                        options={unitOfMeasureOptions}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">UNIT PRICE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="price"
                        placeholder="Unit Price"
                        value={laborPriceRecordData?.price}
                        onChange={handleLaborPriceInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">VALID FROM</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={laborPriceRecordData?.validFrom}
                            onChange={(e) => handleLaborPriceSelectChange("validFrom", e)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">VALID TO</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            // maxDate={new Date()}
                            closeOnSelect
                            value={laborPriceRecordData?.validTo}
                            onChange={(e) => handleLaborPriceSelectChange("validTo", e)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">STATUS</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="status"
                        value={laborPriceRecordData?.status}
                        onChange={handleLaborPriceInputChange}
                        placeholder="Status"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">CURRENCY</label>
                      <Select
                        className="text-primary"
                        value={laborPriceRecordData?.currency}
                        onChange={(e) => handleLaborPriceSelectChange("currency", e)}
                        options={[
                          { label: "USD", value: "USD" },
                          { label: "INR", value: "INR" },
                          { label: "AUD", value: "AUD" },
                          { label: "EUR", value: "EUR" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <ReadOnlyField className="col-md-6 col-sm-6" label="COUNTRY" value={laborPriceRecordData?.country?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="CHARGE CODE" value={laborPriceRecordData?.chargeCode?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="JOB CODE" value={laborPriceRecordData?.jobCode} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="SERVICE TYPE" value={laborPriceRecordData?.serviceType?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="SALES UNIT" value={laborPriceRecordData?.unit?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="UNIT PRICE" value={laborPriceRecordData?.price} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID FROM" value={laborPriceRecordData?.validFrom} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID TO" value={laborPriceRecordData?.validTo} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="STATUS" value={laborPriceRecordData?.status} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="CURRENCY" value={laborPriceRecordData?.currency?.label} />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!laborPriceViewMode ? (
              <>
                <Button variant="secondary" onClick={() => handleLaborsModal()}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSaveLaborPriceData}>
                  Save Changes
                </Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => handleLaborsModal()}>
                  Close
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const handlePartSearch = async (searchCustfieldName, searchText) => {
    setSearchPartsResult([]);
    partPriceRecordData.partId = searchText;
    partPriceRecordData.productModel = "";
    partPriceRecordData.description = "";

    if (searchText) {
      await sparePartSearch(`partNumber~${searchText}`)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchPartsResult(result);
            setNoOptionsParts(false);
          } else {
            setNoOptionsParts(true);
          }
        })
        .catch((e) => {
          //   handleSnack("error", "Error occurred while searching the customer!");
        });
    }
  };

  // Select the part number from search result
  const handlePartSelect = (type, currentItem) => {
    setPartPriceRecordData({
      ...partPriceRecordData,
      partId: currentItem.partNumber,
      productModel: currentItem.model,
      description: currentItem.partDescription,
    });
    setSearchPartsResult([]);
  };

  const handleChangePartPriceData = (e) => {
    const { name, value } = e.target;
    setPartPriceRecordData({ ...partPriceRecordData, [name]: value });
  };
  const handleChangePartPriceSelectData = (keyName, e) => {
    setPartPriceRecordData({ ...partPriceRecordData, [keyName]: e });
  };

  const handleSavePartPriceData = () => {
    const rUrl = GET_SPAREPART_PRICE;
    const rObj = {
      ...partPriceRecordData,
      country: partPriceRecordData?.country?.value || "INDIA",
      currency: partPriceRecordData?.currency?.value || "INR",
    };
    if (partPriceRecordData?.id) {
      callPutApi(null, `${rUrl}/${partPriceRecordData?.id}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _dataForSpareParts = dataForSpareParts.map((item) => (item?.id === responseData?.id ? { ...responseData } : item));
          setDataForSpareParts(_dataForSpareParts);
        } else {
        }
      });
    } else {
      callPostApi(null, `${rUrl}`, rObj, (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          const _dataForSpareParts = [...dataForSpareParts];
          _dataForSpareParts.unshift(responseData);
          setDataForSpareParts(_dataForSpareParts);
        } else {
        }
      });
    }
    handlePartsModal();
  };

  const SparePartsModal = () => {
    return (
      <>
        <Modal show={showPartsModal} onHide={() => handlePartsModal()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="bg-white">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4>{partPriceRecordData?.id ? "EDIT" : "ADD"} SPARE PARTS PRICE</h4>
              {!partPriceRecordData?.id ? (
                <></>
              ) : (
                <Tooltip title="Edit">
                  <span className="upload-icon-button cursor" onClick={() => setPartPriceViewMode(false)}>
                    <EditIcon sx={{ fontSize: 30 }} />
                  </span>
                </Tooltip>
              )}
            </div>

            <div className="card border px-3 py-2 mb-0">
              {!partPriceViewMode ? (
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500" htmlFor="countryInput">
                        COUNTRY
                      </label>
                      <Select
                        className="text-primary"
                        value={partPriceRecordData?.country}
                        onChange={(e) => handleChangePartPriceSelectData("country", e)}
                        options={[
                          { label: "India", value: "INDIA" },
                          { label: "US", value: "US" },
                          { label: "Australia", value: "AUSTRALIA" },
                          { label: "Germany", value: "GERMANY" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">MODAL</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        id="model"
                        placeholder="Modal"
                        value={partPriceRecordData?.productModel}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">PART NUMBER</label>
                      <SearchBox
                        value={partPriceRecordData?.partId}
                        onChange={(e) => handlePartSearch("partNumber", e.target.value)}
                        type="partNumber"
                        result={searchPartsResult}
                        onSelect={handlePartSelect}
                        noOptions={noOptionsParts}
                        placeholder="Part Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">PART DESCRIPTION</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="description"
                        value={partPriceRecordData?.description}
                        disabled
                        placeholder="Part Description"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">SALES UNIT</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="unit"
                        value={partPriceRecordData?.unit}
                        onChange={handleChangePartPriceData}
                        placeholder="Sales Unit"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">UNIT PRICE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="price"
                        value={partPriceRecordData?.price}
                        onChange={handleChangePartPriceData}
                        placeholder="Unit Price"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">VALID FROM</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            // maxDate={new Date()}
                            closeOnSelect
                            value={partPriceRecordData?.validFrom}
                            onChange={(e) => handleChangePartPriceSelectData("validFrom", e)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">VALID TO</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            // maxDate={new Date()}
                            closeOnSelect
                            value={partPriceRecordData?.validTo}
                            onChange={(e) => handleChangePartPriceSelectData("validTo", e)}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">STATUS</label>
                      <input
                        type="text"
                        className="form-control border-radius-10"
                        name="status"
                        value={partPriceRecordData?.status}
                        onChange={handleChangePartPriceData}
                        placeholder="Status"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group w-100">
                      <label className="text-light-dark font-size-14 font-weight-500">CURRENCY</label>
                      <Select
                        className="text-primary"
                        value={partPriceRecordData?.currency}
                        onChange={(e) => handleChangePartPriceSelectData("currency", e)}
                        options={[
                          { label: "USD", value: "USD" },
                          { label: "INR", value: "INR" },
                          { label: "AUD", value: "AUD" },
                          { label: "EUR", value: "EUR" },
                        ]}
                        styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                      />
                      {/* <input type="text" className="form-control border-radius-10" id="currencyInput" placeholder="Currency" /> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <ReadOnlyField className="col-md-6 col-sm-6" label="COUNTRY" value={partPriceRecordData?.country?.label} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="MODAL" value={partPriceRecordData?.productModel} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="PART NUMBER" value={partPriceRecordData?.partId} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="PART DESCRIPTION" value={partPriceRecordData?.description} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="SALES UNIT" value={partPriceRecordData?.unit} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="UNIT PRICE" value={partPriceRecordData?.price} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID FROM" value={partPriceRecordData?.validFrom} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="VALID TO" value={partPriceRecordData?.validTo} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="STATUS" value={partPriceRecordData?.status} />
                  <ReadOnlyField className="col-md-6 col-sm-6" label="CURRENCY" value={partPriceRecordData?.currency?.label} />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!partPriceViewMode ? (
              <>
                <Button variant="secondary" onClick={() => handlePartsModal()}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSavePartPriceData}>
                  Save Changes
                </Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => handlePartsModal()}>
                  Close
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return (
    <>
      {/* <CommanComponents/> */}
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid mt-3">
          <div className="d-flex align-items-center justify-content-between mt-2">
            <h5 className="font-weight-600 mb-0">Price Maintenance</h5>
            {/* <div className="d-flex justify-content-center align-items-center">
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Share"
                            >
                                <img src={shareIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Item to Review"
                            >
                                <img src={folderaddIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Upload"
                            >
                                <img src={uploadIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Delete"
                            >
                                <img src={deleteIcon}></img>
                            </a>
                            <a
                                href="#"
                                className="ml-3 font-size-14"
                                title="Copy"
                            >
                                <img src={copyIcon}></img>
                            </a>
                            <a href="#" className="ml-2">
                                <MuiMenuComponent options={activityOptions} />
                            </a>
                        </div> */}
          </div>
          <Box className="mt-4" sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={(e, tabValue) => setActiveTab(tabValue)}>
                  <Tab label={`SPARE PARTS`} value="parts" />
                  <Tab label={`LABOUR`} value="labour" />
                  <Tab label={`DISCOUNT`} value="discount" />
                  <Tab label={`SHIPPING PRICE`} value="shippingPrice" />
                  <Tab label={`TAX`} value="tax" />
                </TabList>
              </Box>
              <TabPanel value={activeTab}>
                {activeTab === "parts" && viewPartsTab()}
                {activeTab === "labour" && viewLabourTab()}
                {activeTab === "discount" && viewDiscountTab()}
                {activeTab === "shippingPrice" && viewShippingPriceTab()}
                {activeTab === "tax" && viewTaxTab()}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {showPartsModal && SparePartsModal()}
        {showLaborsModal && LaborsModal()}
        {fileUploadOpen && fileUploadModal()}
        {showEditModalShipping && EditModalForShipping()}
        {showEditModalTax && EditModalForTax()}
      </div>
    </>
  );
}

export default PriceMaintenance;
