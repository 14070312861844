import React, { useState } from "react";

import { TextField } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Select from "react-select";

import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";

import SearchBox from "pages/Common/SearchBox";
import { customerSearch, machineSearch } from "services/searchServices";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import Moment from "react-moment";

const options = [
  { value: "Machine", label: "Machine" },
  { value: "Component", label: "Component" },
];

const ShopReapirRequestCard = ({ item, index, machineCompos, setMachineCompos, handleSnack }) => {
  const [recordData, setRecordData] = useState({ ...item });

  const [searchSerialResults, setSearchSerialResults] = useState([]);
  const [noOptionsSerial, setNoOptionsSerial] = useState(false);

  const [searchCustResults, setSearchCustResults] = useState([]);
  const [noOptionsCust, setNoOptionsCust] = useState(false);

  // change the machine components text change
  const handleInputTextChange = (e, index) => {
    const { name, value } = e.target;
    setRecordData({ ...recordData, [name]: value });
    // let _machineCompos = [...machineCompos];
    // let obj = _machineCompos[index];
    // obj[name] = value;
    // setMachineCompos(_machineCompos);
  };

  // change the machine components text change
  const handleSelectChange = (e, keyName, index) => {
    setRecordData({ ...recordData, [keyName]: e });

    // let _machineCompos = [...machineCompos];
    // let obj = _machineCompos[index];
    // obj[keyName] = e;
    // setMachineCompos(_machineCompos);
  };

  // serial number search
  const handleMachineSearch = async (searchMachinefieldName, searchText) => {
    let searchQueryMachine = "";
    setSearchSerialResults([]);
    if (searchText) {
      //   item.serialNo = searchText;
      //   item["model"] = "";
      //   item["make"] = "";
      //   item["customerId"] = "";
      //   item["customerName"] = "";

      recordData.serialNo = searchText;
      recordData["model"] = "";
      recordData["make"] = "";
      recordData["customerId"] = "";
      recordData["customerName"] = "";
      searchQueryMachine = "makerSerialNumber~" + searchText;
      if (searchQueryMachine) {
        await machineSearch(searchQueryMachine)
          .then((result) => {
            if (result && result.length > 0) {
              setSearchSerialResults(result);
              setNoOptionsSerial(false);
            } else {
              setNoOptionsSerial(true);
            }
          })
          .catch((e) => {
            handleSnack("error", "Error occurred while searching the machine!");
          });
      }
    } else {
      recordData.serialNo = "";
      setSearchSerialResults([]);
    }
  };

  // Select serial number from the search result
  const handlSerailNoSelect = (type, currentItem) => {
    setRecordData({
      ...recordData,
      model: currentItem.model,
      make: currentItem.maker,
      //   serialNo: currentItem.equipmentNumber,
      serialNo: currentItem.makerSerialNumber,
      customerId: currentItem.currentClient,
      customerName: currentItem.customer,
    });
    // let _machineCompos = [...machineCompos];
    // let obj = _machineCompos[index];
    // obj["model"] = currentItem.model;
    // obj["make"] = currentItem.maker;
    // obj["serialNo"] = currentItem.equipmentNumber;
    // obj["customerId"] = currentItem.customerId;
    // obj["customerName"] = currentItem.customer;
    // setMachineCompos(_machineCompos);
    setSearchSerialResults([]);
  };

  // Search Customer2ith customer ID
  const handleCustSearch = async (searchCustfieldName, searchText) => {
    setSearchCustResults([]);
    // item.customerId = searchText;
    recordData.customerId = searchText;
    if (searchText) {
      await customerSearch(searchCustfieldName + "~" + searchText)
        .then((result) => {
          if (result && result.length > 0) {
            setSearchCustResults(result);
            setNoOptionsCust(false);
          } else {
            setNoOptionsCust(true);
          }
        })
        .catch((e) => {
          handleSnack("error", "Error occurred while searching the customer!");
        });
    } else {
      recordData.customerId = "";
      setSearchCustResults([]);
    }
  };

  // Select the customer from search result
  const handleCustSelect = (type, currentItem) => {
    // let _machineCompos = [...machineCompos];
    // let obj = _machineCompos[index];
    // obj["customerId"] = currentItem.customerId;
    // obj["customerName"] = currentItem.fullName;
    // setMachineCompos(_machineCompos);

    setRecordData({
      ...recordData,
      customerId: currentItem.customerId,
      customerName: currentItem.fullName,
    });
    setSearchCustResults([]);
  };

  const handleSubmit = () => {
    let _machineCompos = [...machineCompos];
    // let obj = _machineCompos[index];
    _machineCompos[index] = { ...recordData, submited: true, viewMode: true };
    // obj = recordData;
    setMachineCompos(_machineCompos);
  };

  return (
    <>
      <div className="card border px-3 py-2 mb-3">
        {!item?.viewMode ? (
          <div className="row input-fields mt-2">
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">LOCATION</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  // value={item?.location}
                  value={recordData?.location}
                  name="location"
                  placeholder="Location"
                  onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">MACHINE/COMPONENT</label>
                <Select
                  className="text-primary"
                  // value={item?.machineComponent}
                  value={recordData?.machineComponent}
                  onChange={(e) => handleSelectChange(e, "machineComponent", index)}
                  options={options}
                  styles={FONT_STYLE_SELECT}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">SERIAL #</label>
                <SearchBox
                  // value={item?.serialNo}
                  value={recordData?.serialNo}
                  onChange={(e) => handleMachineSearch("makerSerialNumber", e.target.value)}
                  type="makerSerialNumber"
                  result={searchSerialResults}
                  onSelect={handlSerailNoSelect}
                  noOptions={noOptionsSerial}
                  placeholder="Serial Number"
                  index={index}
                />
                {/* <div className="css-w8dmq8">*Mandatory</div> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                <input
                  type="text"
                  // value={item?.model}
                  value={recordData?.model}
                  className="form-control border-radius-10 text-primary"
                  name="model"
                  placeholder="Model"
                  disabled
                  // onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  // value={item?.make}
                  value={recordData?.make}
                  name="make"
                  placeholder="Make"
                  disabled
                  // onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">DATE OF REQUEST</label>
                <div className="align-items-center date-box">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      className="form-controldate border-radius-10"
                      closeOnSelect
                      // value={item?.requestDate}
                      value={recordData?.requestDate}
                      onChange={(e) => handleSelectChange(e, "requestDate", index)}
                      renderInput={(params) => <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">FIND SERVICE ORDER #(if any)</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  value={recordData?.serviceOrder}
                  // value={item?.serviceOrder}
                  name="serviceOrder"
                  placeholder="Service Order"
                  onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">REQUEST NUMBER</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="requestNumber"
                  placeholder="Request Number"
                  // value={item?.requestNumber}
                  value={recordData?.requestNumber}
                  onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER #</label>
                <SearchBox
                  // value={item?.customerId}
                  value={recordData?.customerId}
                  onChange={(e) => handleCustSearch("customerId", e.target.value)}
                  type="customerId"
                  result={searchCustResults}
                  onSelect={handleCustSelect}
                  noOptions={noOptionsCust}
                  placeholder="Customer Id"
                  index={index}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
              <div className="form-group">
                <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                <input
                  type="text"
                  className="form-control border-radius-10 text-primary"
                  name="customerName"
                  placeholder="Customer Name"
                  // value={item?.customerName}
                  value={recordData?.customerName}
                  disabled
                  // onChange={(e) => handleInputTextChange(e, index)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-2">
            <ReadOnlyField label="LOCATION" value={recordData?.location} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="MACHINE/COMPONENT" value={recordData?.machineComponent?.label} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="SERIAL #" value={recordData?.serialNo} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="MODEL" value={recordData?.model} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="MAKE" value={recordData?.make} className="col-md-4 col-sm-4" />
            <ReadOnlyField
              label="DATE OF REQUEST"
              value={<Moment format="DD/MM/YYYY">{recordData?.requestDate}</Moment>}
              className="col-md-4 col-sm-4"
            />
            <ReadOnlyField label="FIND SERVICE ORDER #(if any)" value={recordData?.serviceOrder} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="REQUEST NUMBER" value={recordData?.requestNumber} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="CUSTOMER #" value={recordData?.customerId} className="col-md-4 col-sm-4" />
            <ReadOnlyField label="CUSTOMER NAME" value={recordData?.customerName} className="col-md-4 col-sm-4" />
          </div>
        )}
      </div>
      {!item?.viewMode && (
        <div className="row px-3" style={{ justifyContent: "right" }}>
          <button type="button" className="btn bg-primary text-white" disabled={!recordData.location || !recordData.serialNo} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}
    </>
  );
};

export default ShopReapirRequestCard;
