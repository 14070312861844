import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { GRID_STYLE } from "pages/Common/constants";
import { Tooltip } from "@mui/material";
import EquipmentSearchComp from "./EquipmentSearchComp";
import {
  EQUIPMENT_CONTRACT_DETAILS,
  EQUIPMENT_FAILURE_REPORT_DETAILS,
  EQUIPMENT_SERVICE_REPORT_DETAILS,
  EQUIPMENT_USAGE_SMU_REPORT_DETAILS,
  EQUIPMNT_USAGE_REPORT_DETAILS,
} from "../equipmentMasterConstants";
import EquipmentAddUpdateModal from "./EquipmentAddUpdateModal";
import EquipmentServiceReportModal from "./EquipmentServiceReportModal";

const EquipmentTableMaster = ({ data, setData, searchFor, btnText, type, title }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openServiceReportModal, setOpenServiceReportModal] = useState(false);

  // open View Details Modal
  const handleOpenDtlsModal = (row) => {
    setSelectedRow(row);
    setOpenDetailsModal(true);
  };

  // close View Details Modal
  const handleCloseDtlsModal = () => {
    setSelectedRow(null);
    setOpenDetailsModal(false);
  };

  // open View Service Report Modal
  const handleOpenServiceModal = (row) => {
    setSelectedRow(row);
    setOpenServiceReportModal(true);
  };

  // close View Details Modal
  const handleCloseServiceModal = () => {
    setSelectedRow(null);
    setOpenServiceReportModal(false);
  };

  // contract details Column
  const contractDtlsColumns = [
    {
      field: "entitlementId",
      headerName: "Entitlement #",
      flex: 1,
      width: 100,
    },
    {
      field: "title",
      headerName: "Entitlement Name",
      flex: 1,
      width: 100,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      width: 100,
    },
    {
      field: "basis",
      headerName: "Basis",
      flex: 1,
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      width: 100,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      width: 100,
    },
    {
      field: "validFor",
      headerName: "Valid For",
      flex: 1,
      width: 100,
    },
    {
      field: "unitOfMeasure",
      headerName: "Unit",
      flex: 1,
      width: 100,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      width: 100,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      width: 100,
    },
    {
      field: "startUsage",
      headerName: "Start Usage",
      flex: 1,
      width: 100,
    },
    {
      field: "endUsage",
      headerName: "End Usage",
      flex: 1,
      width: 100,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // usage column
  const usageColumns = [
    {
      field: "currentUsage",
      headerName: "Current Usage",
      flex: 1,
      width: 100,
    },
    {
      field: "averageUsage",
      headerName: "Average Usage",
      flex: 1,
      width: 100,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated Date",
      flex: 1,
      width: 100,
    },
    {
      field: "sensorId",
      headerName: "Sesnor ID",
      flex: 1,
      width: 100,
    },
    {
      field: "smuId",
      headerName: "SMU ID",
      flex: 1,
      width: 100,
    },
    {
      field: "smuType",
      headerName: "SMU Type",
      flex: 1,
      width: 100,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // smu items column
  const SMUItemsColumns = [
    {
      field: "smuId",
      headerName: "SMU ID/ Sensor ID",
      flex: 1,
      width: 100,
    },
    {
      field: "smuType",
      headerName: "SMU Type",
      flex: 1,
      width: 100,
    },
    {
      field: "usageId",
      headerName: "Usage ID",
      flex: 1,
      width: 100,
    },
    {
      field: "readingDate",
      headerName: "Reading Date",
      flex: 1,
      width: 100,
    },
    {
      field: "unitOfMeasure",
      headerName: "Unit",
      flex: 1,
      width: 100,
    },
    {
      field: "readingDescription",
      headerName: "Reading Description",
      flex: 1,
      width: 100,
    },
    {
      field: "overWritenError",
      headerName: "Overwrite/Error",
      flex: 1,
      width: 100,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // service report columns
  const serviceReportColumns = [
    {
      field: "reportNumber",
      headerName: "Report #",
      flex: 1,
      width: 100,
    },
    {
      field: "jobNumber",
      headerName: "Job #",
      flex: 1,
      width: 100,
    },
    {
      field: "engineModelNumber",
      headerName: "Engine Model #",
      flex: 1,
      width: 100,
    },
    {
      field: "engineSerialNumber",
      headerName: "Engine Serial #",
      flex: 1,
      width: 100,
    },
    {
      field: "usage",
      headerName: "Usage #",
      flex: 1,
      width: 100,
    },
    {
      field: "repairDate",
      headerName: "Repair Date",
      flex: 1,
      width: 100,
    },
    {
      field: "complaint",
      headerName: "Complaints",
      flex: 1,
      width: 100,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenServiceModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  // failure report columns
  const failureReportColumns = [
    {
      field: "partNumber",
      headerName: "Part #",
      flex: 1,
      width: 100,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      width: 100,
    },
    {
      field: "subAssembly",
      headerName: "Sub-Assembly",
      flex: 1,
      width: 100,
    },
    {
      field: "warranty",
      headerName: "Warranty",
      width: 100,
    },
    {
      field: "failureDate",
      headerName: "Failure Date",
      flex: 1,
      width: 100,
    },
    {
      field: "repairDate",
      headerName: "Repair Date",
      flex: 1,
      width: 100,
    },
    {
      field: "hoursOnPart",
      headerName: "Hours On Part",
      flex: 1,
      width: 100,
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className="cursor" onClick={() => handleOpenDtlsModal(row)}>
                <Tooltip title="Edit">
                  <EditOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className="cursor">
                <Tooltip title="Delete">
                  <DeleteOutlineOutlinedIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <div className="bg-white p-3 border-radius-10 mt-3 overflow-hidden">
        <EquipmentSearchComp searchFor={searchFor} btnText={btnText} />
        <DataGrid
          sx={GRID_STYLE}
          rows={data}
          columns={
            type === EQUIPMENT_CONTRACT_DETAILS
              ? contractDtlsColumns
              : type === EQUIPMNT_USAGE_REPORT_DETAILS
              ? usageColumns
              : type === EQUIPMENT_USAGE_SMU_REPORT_DETAILS
              ? SMUItemsColumns
              : type === EQUIPMENT_FAILURE_REPORT_DETAILS
              ? failureReportColumns
              : type === EQUIPMENT_SERVICE_REPORT_DETAILS
              ? serviceReportColumns
              : []
          }
          autoHeight
        />
      </div>

      {openDetailsModal && (
        <EquipmentAddUpdateModal show={openDetailsModal} hideModal={handleCloseDtlsModal} rowData={selectedRow} setData={setData} type={type} />
      )}

      {openServiceReportModal && (
        <EquipmentServiceReportModal
          show={openServiceReportModal}
          hideModal={handleCloseServiceModal}
          rowData={selectedRow}
          setData={setData}
          type={type}
        />
      )}
    </>
  );
};

export default EquipmentTableMaster;
