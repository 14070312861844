export const serviceLocationObj = {
    locationName: "",
    locationType: "",
    totalEmployees: 0,
    address: "",
    masterEmployeeIds: [],
};

export const masterEmployeeObj = {
    employeeName: "",
    hrNumber: "",
    age: 0,
    gender: "",
    hireDate: new Date(),
    endDate: new Date(),
    dlNumber: "",
    contactNo: "",
    alternateContactNo: "",
    email: "",
    identificationNumber: "",
    address: "",
    onBoardStatus: "",
    retireDate: new Date(),
    preferServiceArea: "",
    expertise: "",
    expertLevel: "",
    designation: "",
    serviceLocation: 0,
    comments: "",
};

export const serviceLocationTypeOptions = [
    { label: "Service Workshop", value: "SERVICE_WORKSHOP" },
    { label: "Service Center", value: "SERVICE_CENTER" },
    { label: "Coordinates", value: "COORDINATES" },
];

export const serviceGroupOptions = [
    { label: "Engineers", value: "Engineers" },
    { label: "Admin", value: "Admin" },
    { label: "Coordinators", value: "Coordinators" },
];

export const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
];

export const expertiseLevelOptions = [
    { label: "Level I", value: "LEVEL_I" },
    { label: "Level II", value: "LEVEL_II" },
    { label: "Level III", value: "LEVEL_III" },
];

export const locationTypeOptions = [
    { label: "Kolkata", value: "Kolkata" },
    { label: "Mumbai", value: "Mumbai" },
    { label: "New Delhi", value: "Newdelhi" },
    { label: "Bhubaneswar", value: "Bhubaneswar" },
    { label: "Chennai", value: "Chennai" },
    // { value: "Visakhapatnam", label: "Visakhapatnam" },
];

export const onboardStatusOptions = [
    { label: "Onboard", value: "ON_BOARD" },
    { label: "Retire", value: "RETIRE" },
    { label: "Terminate", value: "TERMINATE" },
];

export const vehicleStatusOptions = [
    { label: "In Operation", value: "IN_OPERATION" },
    { label: "Breakdown", value: "BREAK_DOWN" },
    { label: "In Transit", value: "IN_TRANSIT" },
];

export const seniorityLevelOptions = [
    { label: "Level I", value: 1 },
    { label: "Level II", value: 2 },
    { label: "Level III", value: 3 },
];

export const requestTypeOptions = [
    { label: "Customer Call", value: "CUSTOMER_CALL" },
    { label: "Service Branch", value: "SERVICE_BRANCH" },
    { label: "Account Manager", value: "ACCOUNT_MANAGER" },
];

export const tagOptions = [
    { label: "Field Service", value: "FIELD_SERVICE" },
    { label: "Warranty", value: "WARRANTY" },
    { label: "Shop Request", value: "SHOP_REQUEST" },
    { label: "Contract", value: "CONTRACT" },
    { label: "Part Leads", value: "PART_LEADS" },
    { label: "Service Leads", value: "SERVICE_LEADS" },
];

export const priorityOptions = ["LOW", "MEDIUM", "HIGH"];

export const priorityTypeOptions = [
    { label: "Low", value: "LOW " },
    { label: "Medium", value: "MEDIUM" },
    { label: "High", value: "HIGH" },
];

export const serviceRequestObj = {
    requestType: "",
    requestDescription: "",
    customerId: "",
    customerName: "",
    customerPhone: "",
    customerEmail: "",
    customerCity: "",
    postalCode: "",
    state: "",
    country: "",
    managerName: "",
    managerPhone: "",
    managerEmail: "",
    seniorityLevel: "",
    serviceBranch: "",
    serialNumber: "",
    make: "",
    model: "",
    city: "",
    machinePostalCode: "",
    machineState: "",
    machineCountry: "",
    priority: "",
    description: "",
    requesterName: "",
    assigneeName: "",
    assignTo: "",
    longDescription: "",
    tags: [],
};
