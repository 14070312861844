export const addressDTO = {
  id: 0,
  addressId: 0,
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  fullAddress: "",
  district: "",
  regionOrState: "",
  country: "",
  createdBy: "",
  updatedBy: "",
};

export const equipmentMasterRequestObj = {
  id: 0,
  equipmentNumber: "",
  replacedEquipmentNumber: "",
  description: "",
  status: "",
  stockNumber: "",
  currentClient: 0,
  maker: "",
  makerSerialNumber: "",
  technicalIdendificationNumber: "",
  motorBrand: "",
  engineModel: "",
  motorSerialNumber: "",
  typeOfApplication: "",
  unitOfMeasurement: "",
  mainWork: "",
  modelPrefix: "",
  model: "",
  brand: "",
  market: "",
  productLine: "",
  productSegment: "",
  productGroup: "",
  customer: "",
  owner: "",
  geocode: "",
  sensorId: "",
  usageType: "",
  usageDescription: "",
  serviceLetter: "",
  warrantyType: "",
  serviceLetterId: "",
  installationDate: new Date(),
  endOfLife: 0,
  endOfLifeUnit: "",
  plannedUsage: "",
  unit: "",
  operator: "",
  contact: "",
  warrantyAvailability: "",
  yearOfManufacture: "",
  lastOwner: "",
  fleetOwner: "",
  movedInOrOutFlag: false,
  previousLocation: false,
  newLocation: false,
  movedInDate: new Date(),
  addressDTO: { ...addressDTO },
  customerId: "",
  installer: "",
  purchaseDate: new Date(),
  placeOfPurchase: "",
  usedFor: "",
  usageCondition: "",
  warrantyStatus: "",
  warrantyId: 0,
  createdBy: "",
  updatedBy: "",
  masterComponentIds: [],
  equipmentImage: "",
};

export const partsMasterRequestObj = {
  partNumber: "",
  replacedByPartNumber: "",
  replacesPartNumber: "",
  manufacturer: "",
  model: "",
  groupNumber: "",
  partDescription: "",
  partsGroup: "",
  modelGroupDescription: "",
  partGroupDescription: "",
  yearOfManufacture: "",
  salesUnit: "",
  listPrice: 0,
  costPrice: 0,
  currency: "",
  partType: "",
  usageArea: "",
  status: "",
  partComplexity: "",
  partUsage: "",
  demand: "",
  application: "",
  erpMaterialNumber: "",
  legacyMaterial: "",
  alternativePart: "",
  materialGroup: "",
  materialGroupDescription: "",
  becCode: "",
  becCodeDescription: "",
  warehouseInventories: [],
  totalStock: 0,
  availability: 0,
};

export const laborMasterRequestObj = {
  // id: 0,
  // tenantId: 0,
  laborCode: "",
  jobCode: "",
  chargeCode: "",
  serviceType: "",
  description: "",
  vendorId: "",
  laborType: "",
  customerId: "",
  laborDescription: "",
  priceDate: new Date(),
  region: "",
  country: "",
  priceMethod: "",
  unitPrice: 0,
  unitOfMeasure: "",
  recommendedDuration: "",
  plannedDuration: "",
  priceType: "",
  margin: 0,
  totalPrice: 0,
  masterJobCodeIds: [],
  componentCodeIds: [],
};

export const componentMasterRequestObj = {
  // id: 0,
  componentCode: "",
  description: "",
  featureCode: "",
  featureDescription: "",
  reference: "",
  quantity: 0,
  serialNumber: "",
  model: "",
  keyNumber: "",
  supplierId: "",
  supplierName: "",
  warrantyCategory: "",
  warrantyStartDate: new Date(),
  warrantyEndDate: new Date(),

  createdBy: "",
  updatedBy: "",
  equipmentIds: [],
  superComponentCode: 0,
  groupNumber: "",
  // sparePartsQuantities: {
  //   additionalProp1: 0,
  //   additionalProp2: 0,
  //   additionalProp3: 0,
  // },
  sparePartsQuantities: null,
  componentImage: "",
  warranty: false,
};

export const LaborTypeOptions = [
  { label: "Shop", value: "SHOP" },
  { label: "Field", value: "FIELD" },
  { label: "Contract", value: "CONTRACT" },
  { label: "External", value: "EXTERNAL" },
];

export const LocationTypeOptions = [
  { value: "Kolkata", label: "Kolkata" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Newdelhi", label: "New Delhi" },
  { value: "Bhubaneswar", label: "Bhubaneswar" },
  { value: "Chennai", label: "Chennai" },
  // { value: "Visakhapatnam", label: "Visakhapatnam" },
];

export const OnboardStatusOptions = [
  { value: "Onboard", label: "Onboard" },
  { value: "retired", label: "Retired" },
  { value: "terminated", label: "Terminated" },
];

export const ExpertiseLevelOptions = [
  { value: "levelI", label: "Level I" },
  { value: "levelII", label: "Level II" },
  { value: "levelIII", label: "Level III" },
];

export const GenderOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

export const serviceMasterRequestObj = {
  // id: 0,
  activityId: "",
  activityDescription: "",
  activityType: "",
  longDescription: "",
  dimension1: "",
  dimension2: "",
  supplyingVendorCode: "",
  supplyingVendorName: "",
  contractedPrice: 0,
  validFrom: new Date(),
  validTo: new Date(),
  createdBy: "",
  updatedBy: "",
};

export const laborPriceRequestObj = {
  // id: 0,
  priceMethod: "",
  code: "",
  description: "",
  laborType: "",
  chargeCode: "",
  serviceType: "",
  contractNumber: "",
  region: "",
  country: "",
  customerId: "",
  vendorId: "",
  costCentre: "",
  price: 0,
  costPrice: 0,
  quantity: 0,
  unit: "",
  jobCode: "",
  currency: "USD",
  validFrom: new Date(),
  validTo: new Date(),
  // tenantId: 0,
};

export const consumableRequestobj = {
  // id: 0,
  consumableId: "",
  name: "",
  sourceOrVendor: "",
  unit: "",
  unitPrice: 0,
  currency: "",
  planningRelevant: "",
  stockItem: "",
  pricingMethod: "",
  erpMaterialNumber: "",
  contractedPrice: 0,
  avgContractedPrice: 0,
  costPrice: 0,
  movingAvgCost: 0,
  createdBy: "",
  updatedBy: "",
};
