import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import shearchIcon from "../../../assets/icons/svg/search.svg";
import LoadingProgress from "pages/Repair/components/Loader";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Select from "react-select";
import { FONT_STYLE_SELECT } from "pages/Common/constants";
import { serviceAreaOptions, serviceLocationOptions } from "pages/MasterData/equipmentMasterConstants";
import { vehicleOptions } from "pages/MasterData/equipmentConstant";
import { callGetApi, callPostApi, callPutApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { VEHICLES_MASTER_URL } from "services/CONSTANTS";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import { vehicleStatusOptions } from "../FieldServiceConstants";

const VehicleAddModal = ({ show, hideModal, recordId, handleSnack }) => {
  const [viewModeOn, setViewModeOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicleRecordData, setVehicleRecordData] = useState({});

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      setViewModeOn(true);

      callGetApi(
        `${VEHICLES_MASTER_URL}/${recordId}`,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;

            const _serviceLocation = serviceLocationOptions.find((item) => item?.value === responseData?.serviceLocation);
            const _servicablearea = serviceAreaOptions.find((item) => item?.value === responseData?.servicablearea);
            const _status = vehicleStatusOptions.find((item) => item?.value === responseData?.status);

            setVehicleRecordData({
              ...responseData,
              serviceLocation: _serviceLocation || "",
              servicablearea: _servicablearea || "",
              status: _status || "",
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  }, [recordId]);

  // input text value change
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setVehicleRecordData({ ...vehicleRecordData, [name]: value });
  };

  // select option value change
  const handleSelectValueChange = (e, keyName) => {
    setVehicleRecordData({ ...vehicleRecordData, [keyName]: e });
  };

  // submit the vehicle records
  const handleSubmit = () => {
    const rUrl = `${VEHICLES_MASTER_URL}`;
    const rObj = {
      ...vehicleRecordData,
      serviceLocation: vehicleRecordData?.serviceLocation?.value || "",
      servicablearea: vehicleRecordData?.servicablearea?.value || "",
      status: vehicleRecordData?.status?.value || "IN_OPERATION",
    };
    if (vehicleRecordData?.id) {
      callPutApi(
        null,
        `${rUrl}/${vehicleRecordData?.id}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            handleSnack("success", "Vehicle details updated successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Error occurred while updating the vehicle details!");
          }
        },
        (err) => {
          handleSnack("error", "Error occurred while updating the vehicle details!");
        }
      );
    } else {
      callPostApi(
        null,
        `${rUrl}`,
        rObj,
        (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setVehicleRecordData({ ...vehicleRecordData, id: responseData?.id });
            handleSnack("success", "Vehicle details created successfully!");
            setViewModeOn(true);
          } else {
            handleSnack("error", "Error occurred while creating the vehicle!");
          }
        },
        (error) => {
          handleSnack("error", "Error occurred while creating the vehicle!");
        }
      );
    }
  };

  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        <h5 className="d-flex align-items-center mb-0">
          <div className="" style={{ display: "contents" }}>
            <span className="mr-3">VEHICLE</span>
            <a className="btn-sm cursor" onClick={() => setViewModeOn(false)}>
              <EditIcon />
            </a>
            <a className="btn-sm cursor">
              <BookmarkBorderIcon />
            </a>
            <a className="btn-sm cursor">
              <CreateNewFolderIcon />
            </a>
          </div>
          <div className="input-group icons border-radius-10 border">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0 pr-0 " id="basic-addon1">
                <img src={shearchIcon} />
              </span>
            </div>
            <input type="search" className="form-control search-form-control-input" aria-label="Search Dashboard" />
          </div>
        </h5>
        <hr className="mb-0" />
        {loading ? (
          <LoadingProgress />
        ) : (
          <>
            <div className="row">
              <div className="col-md-8 col-sm-8 border-right-1">
                {!viewModeOn ? (
                  <>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500"> ID</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="id"
                            placeholder="Id"
                            value={vehicleRecordData?.id}
                            onChange={handleInputTextChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">VEHICLE NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="vehicleNumber"
                            placeholder="Vehicle Number"
                            value={vehicleRecordData?.vehicleNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="serialNumber"
                            placeholder="Serial Number"
                            value={vehicleRecordData?.serialNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">CHASSIS NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="chasisNumber"
                            placeholder="Chassis Number"
                            value={vehicleRecordData?.chasisNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">REGISTRATION NUMBER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="registractionNumber"
                            placeholder="Registration Number"
                            value={vehicleRecordData?.registractionNumber}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="make"
                            placeholder="Make"
                            value={vehicleRecordData?.make}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="model"
                            placeholder="Model"
                            value={vehicleRecordData?.model}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">OWNER</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="ownerName"
                            placeholder="Owner"
                            value={vehicleRecordData?.ownerName}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-8">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">OWNER ADDRESS</label>
                          <textarea
                            name="ownerAddress"
                            cols="20"
                            rows="1"
                            placeholder="Address"
                            className="form-control border-radius-10 text-primary"
                            value={vehicleRecordData?.ownerAddress}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500"> SERVICE LOCATION </label>
                          <Select
                            className="text-primary"
                            name="serviceLocation"
                            value={vehicleRecordData?.serviceLocation}
                            onChange={(e) => handleSelectValueChange(e, "serviceLocation")}
                            options={serviceLocationOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">SERVICEABLE AREAS</label>
                          <Select
                            className="text-primary"
                            name="servicablearea"
                            value={vehicleRecordData?.servicablearea}
                            onChange={(e) => handleSelectValueChange(e, "servicablearea")}
                            options={serviceAreaOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-2">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">EMPLOYEE IN CHARGE</label>
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            name="employeeIncharge"
                            placeholder="Employee in charge"
                            value={vehicleRecordData?.employeeIncharge}
                            onChange={handleInputTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">VEHICLE OPERATIONAL STATUS</label>
                          <Select
                            className="text-primary"
                            name="status"
                            value={vehicleRecordData?.status}
                            onChange={(e) => handleSelectValueChange(e, "status")}
                            options={vehicleStatusOptions}
                            styles={{ ...FONT_STYLE_SELECT, menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                          <label className="text-light-dark font-size-12 font-weight-500">COMMENTS</label>
                          <textarea
                            name="comments"
                            cols="30"
                            rows="2"
                            placeholder="Comments"
                            className="form-control border-radius-10 text-primary"
                            onChange={handleInputTextChange}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row input-fields mt-3">
                      <div className="col-md-6 col-sm-6">
                        <span className="cursor font-size-16 font-weight-500">+ ADD A FIELD TO THIS TABLE</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-3">
                      <ReadOnlyField label="ID" value={vehicleRecordData?.id} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="VEHICLE NUMBER" value={vehicleRecordData?.vehicleNumber} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="SERIAL NUMBER" value={vehicleRecordData?.serialNumber} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="CHASSIS NUMBER" value={vehicleRecordData?.chasisNumber} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="REGISTRATION NUMBER" value={vehicleRecordData?.registractionNumber} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="MAKE" value={vehicleRecordData?.make} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="MODEL" value={vehicleRecordData?.model} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="OWNER" value={vehicleRecordData?.ownerName} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="OWNER ADDRESS" value={vehicleRecordData?.ownerAddress} className="col-md-8 col-sm-8" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="SERVICE LOCATION" value={vehicleRecordData?.serviceLocation?.label} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="SERVICEABLE AREAS" value={vehicleRecordData?.servicablearea?.label} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="EMPLOYEE IN CHARGE" value={vehicleRecordData?.employeeIncharge} className="col-md-4 col-sm-4" />
                      <ReadOnlyField label="VEHICLE OPERATIONAL STATUS" value={vehicleRecordData?.status?.label} className="col-md-4 col-sm-4" />
                    </div>
                    <div className="row mt-3">
                      <ReadOnlyField label="COMMENTS" value={vehicleRecordData?.comments} className="col-md-4 col-sm-4" />
                    </div>
                  </>
                )}
                <div className="row px-4" style={{ justifyContent: "right" }}>
                  {!viewModeOn ? (
                    <>
                      <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                        Cancel
                      </button>
                      <button className="btn bg-primary text-white" onClick={handleSubmit}>
                        Save
                      </button>
                    </>
                  ) : (
                    <button className="btn border-primary text-primary mx-2" onClick={hideModal}>
                      Close
                    </button>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-4 mt-2">
                <div className="card border p-3 bg-primary ">
                  <h5 className="d-flex align-items-center justify-content-between mb-0">
                    <div className="text-white" style={{ display: "contents" }}>
                      <span className="mr-3">Vehicle Version</span>
                      <div>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                        </a>
                        <a href="#" className="btn-sm text-white">
                          <i className="fa fa-folder-o" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </h5>
                </div>
                <div className="card border p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">
                      7 Aug 2021, 1:38 pm
                      <a href="#" className="p-1 more-btn " style={{ marginLeft: "35px" }}>
                        + 2<span className="c-btn">J</span>
                      </a>
                    </p>
                    <p className="mb-0">
                      <a href="#" className=""></a>
                    </p>
                  </div>

                  <div className="mt-3">
                    <small> ID</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    E002
                  </p>
                  <p className="text-black mb-2">E005</p>
                  <div className="mt-3">
                    <small>VEHICLE NUMBER</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    MH 12 AB 3456
                  </p>
                  <p className="text-black mb-2">KA 05 CD 7890</p>
                  <div className="mt-3">
                    <small>VEHICLE OPERATIONAL STATUS</small>
                  </div>
                  <p className="text-black mb-2" style={{ textDecoration: "line-through" }}>
                    Breakdown
                  </p>
                  <p className="text-black mb-2">In Operation</p>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VehicleAddModal;
