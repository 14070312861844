// Error occurred while fetching spare parts!
export const INPUT_SEARCH_ERROR_MESSAGE = "Please fill current search criteria";
export const INPUT_SEARCH_API_ERROR_MESSAGE = "Somthing went wrong";
export const INPUT_SEARCH_NO_RESULT_FOUND_ERROR_MESSAGE = "No information is found for your search, change the search criteria";
export const EMPTY_SEARCH_FIELDS_ERROR_MESSAGE = "Please fill the search criteria!";
export const FILL_DATA_PROPERLY_ERROR_MESSAGE = "Please fill the data Properly";

export const WITH_PARTS = "BUILDER_WITH_SPAREPART";
export const WITHOUT_PARTS = "BUILDER_WITHOUT_SPAREPART";
export const NEW_SEGMENT = "New Segment";
export const NEW_OPERATION = "New Operation";
export const SERVICE_TEMPLATE = "SERVICE_TEMPLATE";
export const PART_AND_SERVICE_TEMPLATE = "PART_AND_SERVICE_TEMPLATE";
export const INITIAL_PAGE_NO = 0;
export const INITIAL_PAGE_SIZE = 5;

export const ERROR_MAX_VERSIONS = "Three versions already exist, if  you want another version then version 3.0 must be archieved";

export const GRID_STYLE = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#872ff7",
    color: "#fff",
    fontSize: 12,
  },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": {
    marginTop: "1.5em",
    marginBottom: "1.5em",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
};

export const FONT_STYLE = {
  color: "#872ff7",
  fontSize: "17px",
  fontWeight: "500",
  padding: 0,
};

export const GENERAL_TRANSPRINT_GRID_STYLE = {
  "& .MuiIconButton-root:hover": {
    backgroundColor: "transparent", // Removes the background color on hover
  },
  "& .MuiDataGrid-columnHeaders": { fontSize: 14 },
  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
    marginTop: "1em",
    marginBottom: "1em",
  },
  "& .MuiTablePagination-select": { marginTop: "1.5em", marginBottom: "1.5em" },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    // borderRight: `1px solid rgba(0,0,0,.12)`,
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "72px",
    whiteSpace: "normal !important",
    wordWrap: "break-word !important",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  // minHeight: 300,
  "& .MuiDataGrid-cellContent": {
    fontSize: 12,
  },
  "& .MuiInputBase-root": {
    fontSize: 12,
    marginInline: 2,
    paddingInline: 1,
  },
  "& .super-app-value": {
    backgroundColor: "#dabffd",
    fontWeight: "600",
  },
  "& .disable-value": {
    backgroundColor: "#f2f2f2",
  },
  marginInline: "auto",
  width: "100%",
  backgroundColor: "#fff",
};

export const expendTableCustomStyle = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      backgroundColor: "#872ff7",
      color: "#fff",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};

export const FONT_STYLE_SELECT = {
  placeholder: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 10,
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    ...FONT_STYLE,
  }),
};

export const SPAREPART_SEARCH_Q_OPTIONS = [
  {
    label: "Part No",
    value: "partNumber",
  },
  {
    label: "Description",
    value: "partDescription",
  },
  { label: "Model", value: "model" },
  {
    label: "Group No",
    value: "groupNumber",
  },
  {
    label: "Bec Code",
    value: "becCode",
  },
  { label: "Type", value: "partType" },
];

export const CONS_EXT_PRICE_OPTIONS = [
  {
    value: "LIST",
    label: "List Price",
  },
  {
    value: "PER_ON_TOTAL",
    label: "Percentage on Total",
  },
  {
    value: "PER_ON_LABOR",
    label: "Percentage on Labour",
  },
];

export const CONSUMABLE_SEARCH_Q_OPTIONS = [
  {
    label: "Id",
    value: "consumableId",
  },
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Vendor",
    value: "sourceOrVendor",
  },
];

export const EXTWORK_SEARCH_Q_OPTIONS = [
  {
    label: "Id",
    value: "activityId",
  },
  {
    label: "Activity Name",
    value: "activityDescription",
  },
  {
    label: "Supplying Vendor",
    value: "supplyingVendorName",
  },
];

export const LABOR_PRICE_OPTIONS = [
  { value: "LIST", label: "List Price" },
  { value: "COST_PLUS", label: "Cost Plus" },
];

export const MISC_PRICE_OPTIONS = [
  { value: "PER_ON_TOTAL", label: "Percentage on Total" },
  {
    value: "PER_ON_LABOR",
    label: "Percentage on Labour",
  },
];

export const BUILDER_SEARCH_Q_OPTIONS = [
  { label: "ID", value: "builderId" },
  { label: "Description", value: "description" },
  { label: "Customer ID", value: "customerId" },
  { label: "Model", value: "model" },
  { label: "Make", value: "make" },
  { label: "Family", value: "family" },
  { label: "SerialNo", value: "serialNo" },
  { label: "Status", value: "status" },
];

export const orderTypeOptions = [
  { label: "Emmergency", value: "EMMERGENCY" },
  { label: "Stock Order", value: "STOCK_ORDER" },
];

export const storeOptions = [
  { label: "Store 1", value: "STORE_1" },
  { label: "Store 2", value: "STORE_2" },
  { label: "Store 3", value: "STORE_3" },
  { label: "Store 4", value: "STORE_4" },
];

export const paymentTermsOptions = [
  { label: "Immediate", value: "IMMEDIATE" },
  { label: "Credit", value: "CREDIT" },
];

export const billingTypesOptions = [
  { label: "Pay Fixed Price", value: "PAY_FIXED_PRICE" },
  { label: "Pay Support", value: "PAY_SUPPORT" },
  { label: "Pay Fixed Price Fixed Time", value: "PAY_FIXED_PRICE_FIXED_TIME" },
  { label: "Fixed Price Cost Model", value: "FIXED_PRICE_COST_MODEL" },
  { label: "Performance Based", value: "PERFORMANCE_BASED" },
  { label: "Pay Service Used", value: "PAY_SERVICE_USED" },
];

export const deliveryPriorityOptions = [
  { label: "Normal", value: "NORMAL" },
  { label: "Urgent", value: "URGENT" },
  //   { label: "Emergency", value: "EMERGENCY" },
  { label: "Very Urgent", value: "VERY_URGENT" },
];

export const shippingCondition = [
  { label: "Ship to Me", value: "SHIP_TO_ME" },
  { label: "Pickup", value: "PICKUP" },
];

export const shippingPointOptions = [
  // { label: "Shipping Point 1", value: "SHIPPING_POINT_1" },
  // { label: "Shipping Point 2", value: "SHIPPING_POINT_2" },
  // { label: "Shipping Point 3", value: "SHIPPING_POINT_3" },
  // { label: "Shipping Point 4", value: "SHIPPING_POINT_4" },
  // { label: "Shipping Point 5", value: "SHIPPING_POINT_5" },
  { label: "Van - DL 12 A 2947", value: "Van - DL 12 A 2947" },
  { label: "Van - MU 01 AB 1232", value: "Van - MU 01 AB 1232" },
  { label: "Van - KA 02 BW 1284", value: "Van - KA 02 BW 1284" },
  { label: "Van - DU 01 AB 3847", value: "Van - DU 01 AB 3847" },
  { label: "Van - MU 13 AX 1283", value: "Van - MU 13 AX 1283" },
];

// Sales Office Location Key Value Pair List
export const locationOptions = [
  { value: "Location1", label: "Location1" },
  { value: "Location2", label: "Location2" },
  { value: "Location3", label: "Location3" },
  { value: "Location4", label: "Location4" },
];

export const accountAssignmentOptions = [
  { value: "SAP Internal Order", label: "SAP_INTERNAL_ORDER" },
  { value: "WBS", label: "WBS" },
  { value: "Other ERP", label: "OTHER_ERP" },
];

export const validityOptions = [
  { value: 15, label: "15 days" },
  { value: 30, label: "1 month" },
  { value: 45, label: "45 days" },
  { value: 60, label: "2 months" },
];

export const warrantyClaimStatusOption = [
  { label: "Registered", value: "REGISTERED" },
  { label: "Acknowledged", value: "ACKNOWLEDGED" },
  { label: "Accepted", value: "ACCEPTED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Closed", value: "CLOSED" },
];

export const salesOfficeOptions = [
  { value: "Location1", label: "Location1" },
  { value: "Location2", label: "Location2" },
  { value: "Location3", label: "Location3" },
  { value: "Location4", label: "Location4" },
];

export const STATUS_OPTIONS = [
  { value: "DRAFT", label: "Draft" },
  { value: "ACTIVE", label: "Active" },
  { value: "REVISED", label: "Revised" },
  { value: "ARCHIVED", label: "Archived" },
];

export const orderPlanJobStatusDummyData = [
  {
    id: 1,
    status: "Not Assigned",
    price: 26,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 2,
    status: "Engineer Assigned",
    price: 30,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 3,
    status: "Waiting for Parts/Resources",
    price: 120,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 4,
    status: "Ready for Execution",
    price: 90,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 5,
    status: "In Progress",
    price: 76,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 6,
    status: "Prepare Service Report",
    price: 109,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 7,
    status: "Submit Service Report",
    price: 23,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 8,
    status: "Prepare & Send Quote",
    price: 98,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 9,
    status: "Quote Accepted",
    price: 120,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 10,
    status: "Ready for Subsequent Visit",
    price: 65,
    orderDate: new Date(),
    completed: false,
  },
  {
    id: 11,
    status: "Job Done",
    price: 119,
    orderDate: new Date(),
    completed: false,
  },
];

export const orderApprovalDummyRecords = [
  {
    id: 1,
    status: "Waiting for Travel Approval",
    costCode: "Travel",
    jobDescription: "description 1",
    price: 26,
    createdAt: new Date(),
    updatedStatus: "Travel Approved",
    isUpdated: false,
  },
  {
    id: 2,
    status: "Advance Payment Requested",
    jobDescription: "description 2",
    costCode: "Advance Payment",
    price: 30,
    createdAt: new Date(),
    updatedStatus: "Advance Payment Received",
    isUpdated: false,
  },
  {
    id: 3,
    status: "Waiting for Warranty Approval",
    costCode: "Warranty",
    jobDescription: "description 3",
    price: 120,
    createdAt: new Date(),
    updatedStatus: "Warranty Approved",
    isUpdated: false,
  },
  {
    id: 4,
    status: "Waiting for Rework/Goodwill/Policy Approval",
    costCode: "Rework/Goodwill/Policy",
    jobDescription: "description 4",
    price: 90,
    createdAt: new Date(),
    updatedStatus: " Goodwill Approved",
    isUpdated: false,
  },
];

export const serviceLocationSearchOptions = [
  { label: "Name", value: "name" },
  { label: "Employee", value: "employee" },
  { label: "Location Type", value: "locationtype" },
];
