export const searchOptions = [
  { value: "A", label: "Serial No" },
  { value: "B", label: "Model" },
  { value: "C", label: "Family" },
  { value: "D", label: "Equipment Id" },
  { value: "E", label: "Manufacturer" },
  { value: "F", label: "Description" },
];

export const warrantySearchOptions = [
  { label: "Serial Number", value: "serialNumber" },
  { label: "Component Code", value: "componentCode" },
  { label: "Equipment Number", value: "equipmentNumber" },
  { label: "Model Number", value: "ModelNo" },
];

export const defaultContactData = [
  {
    itemName: "2276044",
    itemDescription: "New",
    itemHeaderStrategy: "SEAL-O-RING",
    taskType: "1757896",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "9.92",
    bundleFlag: "9.92",
  },
  {
    itemName: "3734828",
    itemDescription: "Reman",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R4469",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "94886.38",
    bundleFlag: "94886.38",
  },
  {
    itemName: "3620656",
    itemDescription: "New",
    itemHeaderStrategy: "Spacer",
    taskType: "6I6123",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "40.69",
    bundleFlag: "40.69",
  },
  {
    itemName: "3598761",
    itemDescription: "Refurbish",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R5474",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "5879.24",
    bundleFlag: "5879.24",
  },
];

export const defaultWarrentyData = [
  {
    itemName: "2276044",
    itemDescription: "New",
    itemHeaderStrategy: "SEAL-O-RING",
    taskType: "1757896",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "9.92",
  },
  {
    itemName: "3734828",
    itemDescription: "Reman",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R4469",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "94886.38",
  },
  {
    itemName: "3620656",
    itemDescription: "New",
    itemHeaderStrategy: "Spacer",
    taskType: "6I6123",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "40.69",
  },
  {
    itemName: "3598761",
    itemDescription: "Refurbish",
    itemHeaderStrategy: "Full Core Deposit",
    taskType: "10R5474",
    quantity: "1",
    recommendedValue: "PC",
    servicePrice: "5879.24",
  },
];

export const defaultSearchList = [
  {
    id: 1,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: true,
  },
  {
    id: 2,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 3,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 4,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 5,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 6,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
  {
    id: 7,
    A: "ZCT01096",
    B: "CHAIN EXCAVATOR - 336D2 L",
    C: "336D2 L",
    D: "CATERPILLAR",
    active: false,
  },
];

export const dataTableStyle = {
  rows: {
    style: {
      minHeight: "72px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      backgroundColor: "#872ff7",
      color: "#fff",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRight: "1px solid rgba(0,0,0,.12)",
    },
  },
};

export const eqipmentOptions = [
  { value: "A", label: "Serial No" },
  { value: "B", label: "Model" },
  { value: "C", label: "Family" },
  { value: "D", label: "Equipment Id" },
  { value: "E", label: "Manufacturer" },
  { value: "F", label: "Description" },
];

export const partsSearch = [
  { value: "A", label: "Part Number" },
  { value: "B", label: "Group Number" },
  { value: "C", label: "Part Group" },
  { value: "D", label: "BEC Code" },
  { value: "E", label: "ERP Material Group" },
  { value: "F", label: "ERP Material Number" },
];

export const laborOptions = [
  { value: "description", label: "Job Description" },
  { value: "jobCode", label: "Job code" },
  { value: "laborType", label: "Labor Type" },
  { value: "chargeCode", label: "Charge code" },
  { value: "serviceType", label: "Service Type" },
  // { value: "jobCode", label: "Job code" },
  // { value: "laborType", label: "Labor Type" },
  // { value: "chargeCode", label: "Charge code" },
  // { value: "serviceType", label: "Service type" },
];

export const serviceOptions = [
  // { value: "A", label: "By internal or external" },
  { value: "activityId", label: "Service code" },
  { value: "activityDescription", label: "Description" },
  // { value: "D", label: "Labor type" },
];

export const empolyeeOptions = [
  // { value: "empolyeeId", label: "Employee Id" },
  { value: "employeeNumber", label: "Employee Id" },
  { value: "employeeName", label: "Name" },
  { value: "address", label: "Location" },
  // { value: "employee", label: "Employee" },
];

export const vehicleOptions = [
  { value: "vehicleNumber", label: "Vehicle Number" },
  { value: "VehicleName", label: "Vehicle Name" },
];

export const customerSearchOptions = [
  { value: "A", label: "Customer Number" },
  { value: "B", label: "Description" },
  { value: "C", label: "Email" },
  { value: "D", label: "Type" },
  { value: "E", label: "Group" },
  { value: "F", label: "Segment" },
];

export const consumableSearchOptions = [
  { value: "A", label: "Consumable Number" },
  { value: "B", label: "Description" },
  { value: "C", label: "Supplier" },
];

export const defaultCustomerSearchList = [
  {
    id: 1,
    A: "110346",
    B: "Gloria Mining Services S.A.C.",
    C: "Large Enterprise",
    D: "Retail",
    active: true,
  },
  {
    id: 2,
    A: "1022061",
    B: "Investments John Mic Sac",
    C: "Medium Entrprise",
    D: "Corporate",
    active: false,
  },
  {
    id: 3,
    A: "1011453",
    B: "Provincial Municipality of Chanchamayo",
    C: "Small Retail",
    D: "Energy",
    active: false,
  },
  {
    id: 4,
    A: "1006646",
    B: "Satipo Provincial Municipality",
    C: "Medium Entrprise",
    D: "A Class",
    active: false,
  },
  {
    id: 5,
    A: "1018516",
    B: "2050 Vargaya Sucari Froilan",
    C: "Large Enterprise",
    D: "Corporate",
    active: false,
  },
  {
    id: 6,
    A: "1048850",
    B: "Civil Engineers and Generating Contractors",
    C: "Small Retail",
    D: "C Class",
    active: false,
  },
  {
    id: 7,
    A: "1012124",
    B: "Vias Engineering and Constructions S.R.L Saw",
    C: "Revenue > $ 1 Mil",
    D: "Energy",
    active: false,
  },
];

export const defaultConsumableSearchList = [
  {
    id: 1,
    A: "110346",
    B: "Materials Procesivos",
    C: "MC",
    D: "Stockable",
    active: true,
  },
  {
    id: 2,
    A: "111141",
    B: "Miscelanos",
    C: "EX",
    D: "Stockable",
    active: false,
  },
  {
    id: 3,
    A: "101093",
    B: "Trapo Industrial",
    C: "AA",
    D: "Non Stockable",
    active: false,
  },
  {
    id: 4,
    A: "110528",
    B: "Set of mirrors for retro view mirrors",
    C: "OL",
    D: "Stockable",
    active: false,
  },
  {
    id: 5,
    A: "111141",
    B: "Absorbent Cloth",
    C: "PK",
    D: "Stockable",
    active: false,
  },
  {
    id: 6,
    A: "110549",
    B: "Miscelanos",
    C: "LN",
    D: "Stockable",
    active: false,
  },
  {
    id: 7,
    A: "110528",
    B: "Set of mirrors for retro view mirrors",
    C: "EX",
    D: "Stockable",
    active: false,
  },
];

export const equipmentComponentRecords = [
  {
    partNumber: "N90058041",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    subcomponents: [
      {
        subComponentNumber: 293745,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 846573,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "10R4469",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    subcomponents: [
      {
        subComponentNumber: 938401,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 384723,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "039631T1",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    subcomponents: [
      {
        subComponentNumber: 675865,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 989576,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "039720N2",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    subcomponents: [
      {
        subComponentNumber: 238456,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 894763,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
  {
    partNumber: "5788987",
    family: "Track Excavator",
    model: "992K",
    serialNumber: "ZMX00507 ",
    warrantyCategory: "Supplier Warranty",
    warrantyStartDate: "2024-03-05",
    warrantyEndDate: "2025-03-05",
    supplierId: "SP746",
    supplierName: "Caterpillar",
    subcomponents: [
      {
        subComponentNumber: 893847,
        family: "Track Excavator",
        model: "992k",
        serialNumber: "ZMX00507",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
      {
        subComponentNumber: 948676,
        family: "Track Excavator",
        model: "992K",
        serialNumber: "ZMX00507 ",
        warrantyCategory: "Supplier Warranty",
        warrantyStartDate: "2024-03-05",
        warrantyEndDate: "2025-03-05",
        supplierId: "SP746",
        supplierName: "Caterpillar",
      },
    ],
  },
];

// labor Price Method Options
export const laborPriceMethodOptions = [
  { label: "List", value: "LIST" },
  { label: "Cost Plus", value: "COST_PLUS" },
  { label: "Option", value: "OPTION" },
  { label: "Special", value: "SPECIAL" },
  { label: "Flate Rate", value: "FLAT_RATE" },
  { label: "Per on Total", value: "PER_ON_TOTAL" },
  { label: "Per on Labor", value: "PER_ON_LABOR" },
];

// Charge Type  Options
export const chargeCodeOptions = [
  { label: "Level 1", value: "LEVEL_I" },
  { label: "Level 2", value: "LEVEL_II" },
  { label: "Lavel 3", value: "LEVEL_III" },
  { label: "Apprenice", value: "APPRENTICE" },
  { label: "Minimum Service Call", value: "MINIMUM_SERVICE_CALL" },
  { label: "Additional Items", value: "ADDITIONAL_ITEMS" },
  { label: "Non Scheduled Additional Items", value: "NON_SCHEDULED_ADDITIONAL_ITEMS" },
  { label: "Extra Approved Time", value: "EXTRA_APPROVED_TIME" },
];

// labor Price Method Options
export const serviceTypeOptions = [
  { label: "Weekend", value: "WEEKEND" },
  { label: "Week days", value: "WEEKDAYS" },
  { label: "Overtime", value: "OVERTIME" },
  { label: "Adjust", value: "ADJUST" },
  { label: "Normal", value: "NORMAL" },
  { label: "Ground Clean", value: "GROUND_CLEAN" },
  { label: "Inspect", value: "INSPECT" },
];

//
export const laborTypeOptions = [
  { label: "Shop", value: "SHOP" },
  { label: "Field", value: "FIELD" },
  { label: "Contract", value: "CONTRACT" },
  { label: "External", value: "EXTERNAL" },
];

export const laborCodeOptions = [
  { label: "External", value: "EXTERNAL" },
  { label: "External", value: "EXTERNAL" },
  { label: "External", value: "EXTERNAL" },
  { label: "External", value: "EXTERNAL" },
];

export const masterstatusOptions = [
  { label: "New", value: "NEW" },
  { label: "Processing", value: "PROCESSING" },
  { label: "Saved", value: "SAVED" },
  { label: "Active", value: "ACTIVE" },
];

export const employeeRecords = [
  {
    id: 1,
    empolyeeId: "E0001",
    name: "Ravi Kumar",
    location: "RK Beach, Visakhapatnam",
    employeNumber: "10001",
    gender: "M",
    employee: "",
    hrNumber: "HR2645",
    age: "30",
    dlNumber: "Dl4593PD274",
    idNumber: "RN0001",
    contactNumber: "+91 9874561234",
    altContactNumber: "+91 2321456997",
    plannedDuration: "RK Beach, Visakhapatnam",
    email: " ravi.kumar@gmail.com",
    preferedServiceArea: "Chennai",
    expertise: "Sales",
    expertiseLevel: "levelIII",
    serviceLevel: "Chennai",
    onboardStatus: "Onboard",
    startDate: "12/26/2021",
    endDate: "01/01/2030 ",
    retriveDate: "01/01/2030",
    comments: "",
  },
  {
    id: 2,
    empolyeeId: "E0002",
    name: "Sneha Sharma",
    location: "Gajuwaka, Visakhapatnam",
    employeNumber: "1234",
    gender: "F",
    employee: "",
    hrNumber: "HR2645",
    age: "30",
    dlNumber: "Dl4593PD274",
    idNumber: "RN0001",
    contactNumber: "+91 9874561234",
    altContactNumber: "+91 2321456997",
    plannedDuration: "RK Beach, Visakhapatnam",
    email: " ravi.kumar@gmail.com",
    preferedServiceArea: "Chennai",
    expertise: "Sales",
    expertiseLevel: "Level III",
    serviceLevel: "Chennai",
    onboardStatus: "Onboard",
    startDate: "12/26/2021",
    endDate: "01/01/2030 ",
    retriveDate: "01/01/2030",
    comments: "",
  },
  {
    id: 3,
    empolyeeId: "E0003",
    name: "Arjun Reddy",
    location: "MVP Colony, Visakhapatnam",
    employeNumber: "2345",
    gender: "M",
    employee: "",
  },
  {
    id: 4,
    empolyeeId: "E0004",
    name: "Meera Nair",
    location: "Dwaraka Nagar, Visakhapatnam",
    employeNumber: "3123",
    gender: "F",
    employee: "",
  },
  // {
  //   empolyeeId: "E0005",
  //   name: "",
  //   location: "",
  //   gender: "",
  //   employee: "",
  // },
  // {
  //   empolyeeId: "E0006",
  //   name: "",
  //   location: "",
  //   gender: "",
  //   employee: "",
  // },
];
