import React, { useEffect, useState } from "react";

import "react-date-range/dist/styles.css"; // Main CSS file
import "react-date-range/dist/theme/default.css"; // Theme CSS fil

import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { Box, TextField, Button, IconButton, Typography, Select, MenuItem } from "@mui/material";

import { enUS } from "date-fns/locale"; // Import locale

import dayjs from "dayjs";
import { DateRange } from "react-date-range";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Modal } from "react-bootstrap";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { callGetApi, callPostApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { SLOTS_EMPLOYEE_DETAILS, BOOKINGS_MASTER_URL } from "services/CONSTANTS";

const theme = createTheme({
    components: {
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f8f9fc", // Light background for the header
                    padding: "10px 0",
                },
                labelContainer: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#007bff", // Blue text for the month and year
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                root: {
                    color: "#007bff", // Blue color for the arrows
                    "& button": {
                        color: "#007bff", // Blue color for the buttons inside the arrow switcher
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50%",
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#007bff", // Blue text for unselected dates
                    backgroundColor: "#f5faff", // Very light blue for unselected dates
                    transition: "0.3s ease-in-out",
                    "&.Mui-selected": {
                        backgroundColor: "#cfe8ff", // Lighter blue for selected dates
                        color: "#004a99", // Darker blue text for selected dates
                    },
                    "&:hover": {
                        backgroundColor: "#eaf4ff", // Slightly darker light blue for hover on unselected dates
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#bddbff", // Slightly darker blue for hover on selected dates
                    },
                },
            },
        },
    },
});

const timeSlots = [
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
];

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

const AssignCalander = ({ show, hideModal, recordId, handleSnack }) => {
    const [loading, setLoading] = useState(false);

    const [recordData, setRecordData] = useState({
        bookingName: "",
        bookingDescription: "",
        bookingType: "HOURS",
        startDate: "",
        endDate: "",
        bookingDate: "",
        startTime: null,
        endTime: null,
        orderAssignmentId: 0,
        employeeId: 0,
    });

    const [dateRange, setDateRange] = useState([null, null]); // Start and end dates
    const [bookingDate, setBookingDate] = useState(null);
    const [taskTitle, setTaskTitle] = useState("");
    const [taskList, setTaskList] = useState("DAYS");
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [selectedTime, setSelectedTime] = useState("");
    const [disabledDates, setDisabledDates] = useState([]);

    const [timeRange, setTimeRange] = useState({ start: null, end: null });

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    useEffect(() => {
        if (recordId) {
            setLoading(true);
            let _date = new Date();
            if (bookingDate) {
                _date = bookingDate;
            }
            const formattedDate = `${(_date.getMonth() + 1).toString().padStart(2, "0")}-${_date.getFullYear()}`;
            const rUrl = `${SLOTS_EMPLOYEE_DETAILS(recordId)}&month=${formattedDate}`;
            callGetApi(
                rUrl,
                (response) => {
                    if (response.status === API_SUCCESS) {
                        const responseData = response.data;
                        const _disabledDates = [...responseData.absentDays, ...responseData.bookedDays].map((date) => dayjs(date));
                        setDisabledDates(_disabledDates);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                },
                (error) => {
                    setLoading(false);
                }
            );
        }
    }, [recordId]);

    const handleTimeClick = (time) => {
        if (!timeRange.start) {
            setTimeRange({ start: time, end: null }); // Set start time
        } else if (!timeRange.end) {
            setTimeRange((prev) => ({
                ...prev,
                end: time,
            })); // Set end time
        } else {
            setTimeRange({ start: time, end: null }); // Reset range if both start and end are already set
        }
    };

    const isTimeInRange = (time) => {
        if (!timeRange.start || !timeRange.end) return false;
        const startIndex = timeSlots.indexOf(timeRange.start);
        const endIndex = timeSlots.indexOf(timeRange.end);
        const currentIndex = timeSlots.indexOf(time);
        return currentIndex >= startIndex && currentIndex <= endIndex;
    };

    // Function to check if a date should be disabled
    const shouldDisableDate = (date) => {
        return disabledDates.some((disabledDate) => disabledDate.isSame(date, "day"));
    };

    // disable weekends
    const disableWeekends = (date) => {
        const day = date.getDay(); // Get the day of the week
        const isWeekend = day === 0 || day === 6; // Check if it's Saturday or Sunday

        // Check if the date matches any of the `disabledDates`
        const isDisabled = disabledDates.some((disabledDate) => disabledDate.isSame(date, "day"));

        // Disable if it's a weekend or in the disabled dates list
        return isWeekend || isDisabled;
    };

    // assign time solts
    const handleAssignTimeSlots = async () => {
        if (taskList === "DAYS") {
            if (!state[0]?.startDate) {
                handleSnack("error", "Please select the start Date");
                return;
            } else if (!state[0]?.endDate) {
                handleSnack("error", "Please select the end Date");
                return;
            }
        } else {
            if (!showTimePicker) {
                setShowTimePicker(true);
                return;
            } else if (showTimePicker && !timeRange?.start) {
                handleSnack("error", "Please select the start time");
                return;
            } else if (showTimePicker && !timeRange?.end) {
                handleSnack("error", "Please select the end time");
                return;
            }
        }

        const rUrl = `${BOOKINGS_MASTER_URL}`;

        let rObj = {};

        if (taskList === "DAYS") {
            rObj = {
                bookingName: taskTitle || "",
                bookingDescription: "",
                bookingType: "DAYS",
                startDate: state[0].startDate,
                endDate: state[0].endDate,
                orderAssignmentId: 13,
                employeeId: recordId,
            };
        } else {
            rObj = {
                bookingName: taskTitle || "",
                bookingDescription: "",
                bookingType: "HOURS",
                bookingDate: bookingDate,
                startTime: timeRange?.start,
                endTime: timeRange?.end,
                orderAssignmentId: 13,
                employeeId: recordId,
            };
        }

        callPostApi(
            null,
            rUrl,
            rObj,
            (response) => {
                if (response.status === API_SUCCESS) {
                    const responseData = response.data;

                    if (taskList === "DAYS") {
                        handleSnack("success", `Slot booked from ${formatDate(state[0].startDate)} to ${formatDate(state[0].endDate)} successfully.`);
                    } else {
                        handleSnack("success", `Slot booked from ${timeRange?.start} to ${timeRange?.end} on ${formatDate(bookingDate)} successfully.`);
                    }
                    hideModal();
                } else {
                    handleSnack("error", response?.data?.message);
                }
            },
            (error) => {
                handleSnack("error", "Failed to assign time slots");
            }
        );
        // handleSnack("success", `Task: ${taskTitle}\nDate: ${bookingDate.toDateString()}\nTime: ${timeRange?.start}-${timeRange?.end}\nList: ${taskList}`);
    };

    return (
        <>
            <Modal show={show} onHide={hideModal} size="75">
                <Modal.Body className="px-2">
                    {/* <div style={{ maxWidth: "400px", margin: "50px auto" }}></div> */}
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box
                                sx={{
                                    margin: "20px auto",
                                    padding: 3,
                                    borderRadius: 4,
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                                    fontFamily: "'Roboto', sans-serif",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mb: 3,
                                        backgroundColor: "#f9f9f9",
                                        padding: "10px 15px",
                                        borderRadius: "20px",
                                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        placeholder="Create new task"
                                        value={taskTitle}
                                        onChange={(e) => setTaskTitle(e.target.value)}
                                        size="small"
                                        // fullWidth
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                border: "none",
                                                backgroundColor: "transparent",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#495057",
                                                padding: "0 10px",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                            "& .MuiInputBase-input": {
                                                padding: 0, // Remove extra padding
                                            },
                                        }}
                                    />

                                    {/* Date Display */}
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            color: "#6c757d",
                                            marginLeft: "15px",
                                            whiteSpace: "nowrap", // Force single-row display
                                            overflow: "hidden",
                                            textOverflow: "ellipsis", // Handle long text gracefully
                                        }}
                                    >
                                        {(bookingDate || new Date()).toLocaleDateString("en-IN", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                        })}
                                    </Typography>
                                </Box>

                                {/* Task List Dropdown */}
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 3 }}>
                                    <Select
                                        value={taskList}
                                        onChange={(e) => setTaskList(e.target.value)}
                                        displayEmpty
                                        size="small"
                                        fullWidth
                                        sx={{
                                            borderRadius: "20px",
                                            backgroundColor: "#f9f9f9",
                                            "& .MuiSelect-select": { padding: "10px 15px", borderRadius: "20px", color: taskList ? "#000" : "#6c757d" },
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                        }}
                                    >
                                        <MenuItem value="DAYS">Days</MenuItem>
                                        <MenuItem value="HOURS">Hours</MenuItem>
                                    </Select>
                                    <IconButton>
                                        {taskList === "DAYS" ? <CalendarTodayIcon style={{ color: "#6c757d" }} /> : <WatchLaterIcon style={{ color: "#6c757d" }} />}
                                    </IconButton>
                                </Box>

                                {taskList === "DAYS" ? (
                                    <>
                                        <div className="d-flex justify-content-center">
                                            <DateRange
                                                editableDateInputs={false}
                                                onChange={(item) => setState([item.selection])}
                                                moveRangeOnFirstSelection={true}
                                                ranges={state}
                                                locale={enUS} // Ensure locale is properly set
                                                disabledDay={disableWeekends}
                                                showDateDisplay={false}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* Main UI: Toggle between Date Picker and Time Picker */}
                                        {!showTimePicker ? (
                                            // Date Picker View
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                value={bookingDate}
                                                onChange={(newDate) => setBookingDate(newDate)}
                                                shouldDisableDate={shouldDisableDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        ) : (
                                            // Time Picker View
                                            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 2, mb: 3 }}>
                                                {timeSlots.map((time) => (
                                                    <Button
                                                        key={time}
                                                        variant={
                                                            time === timeRange.start || time === timeRange.end ? "contained" : isTimeInRange(time) ? "contained" : "outlined"
                                                        }
                                                        onClick={() => handleTimeClick(time)}
                                                        sx={{
                                                            borderRadius: "20px",
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            backgroundColor:
                                                                time === timeRange.start || time === timeRange.end ? "#007bff" : isTimeInRange(time) ? "#d0e8ff" : "#f9f9f9",
                                                            color: time === timeRange.start || time === timeRange.end ? "#fff" : isTimeInRange(time) ? "#004a99" : "#000",
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    time === timeRange.start || time === timeRange.end
                                                                        ? "#0056b3"
                                                                        : isTimeInRange(time)
                                                                        ? "#cfe8ff"
                                                                        : "#eaeaea",
                                                            },
                                                        }}
                                                    >
                                                        {time}
                                                    </Button>
                                                ))}
                                            </Box>
                                        )}
                                    </>
                                )}

                                {/* Set Time */}
                                <Button
                                    //   variant="contained"
                                    fullWidth
                                    sx={{
                                        mt: 2,
                                        padding: "10px 15px",
                                        backgroundColor: "#007bff",
                                        color: "#286FF3",
                                        borderRadius: "20px",
                                        textTransform: "none",
                                        fontWeight: "600",
                                        boxShadow: "0 4px 10px rgba(0, 123, 255, 0.3)",
                                        backgroundColor: "#F3F5FD",
                                        borderColor: "#F3F5FE",
                                        "&:hover": { backgroundColor: "#286FF3", color: "#ffffff" },
                                    }}
                                    onClick={() => setShowTimePicker((prev) => !prev)}
                                    disable={taskList === "DAYS"}
                                >
                                    {taskList === "DAYS" ? "Selected for whole day" : showTimePicker ? "Go back to calendar" : "Set time"}
                                </Button>

                                {/* Save Changes Button */}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        mt: 2,
                                        padding: "10px 15px",
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        borderRadius: "20px",
                                        textTransform: "none",
                                        fontWeight: "600",
                                        boxShadow: "0 4px 10px rgba(0, 123, 255, 0.3)",
                                        "&:hover": { backgroundColor: "#0056b3" },
                                    }}
                                    onClick={handleAssignTimeSlots}
                                >
                                    Save Changes
                                </Button>
                            </Box>
                        </LocalizationProvider>
                    </ThemeProvider>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AssignCalander;
