import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import { DataGrid } from "@mui/x-data-grid";
import { Divider, LinearProgress } from "@mui/material";

import Moment from "react-moment";
import { GRID_STYLE } from "pages/Common/constants";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";

// import OrderInfoCard from "./OrderInfoCard";
// import OrderShippingDtlsCard from "./OrderShippingDtlsCard";
// import OrderBillingDtlsCard from "./OrderBillingDtlsCard";

const ProgressBar = ({ progress }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{ height: 10, borderRadius: 5, backgroundColor: "#e0e0e0" }}
      sx={{ "& .MuiLinearProgress-bar": { borderRadius: 5, backgroundColor: "#872ff7" } }}
    />
  );
};

const MaintenanceBuilderOrder = ({ setActiveStep }) => {
  const rowData = {
    partsOrderId: 154,
    partOrderNumber: "PO000154",
    source: "N9JTVA3L7Y4V",
    customerId: "101211",
    customerName: "101211 - KOOLAN IRON ORE PTY LTD",
    contactEmail: "",
    contactPhone: "",
    requester: "",
    customerGroup: "",
    customerSegment: "",
    make: "",
    model: "",
    serialNumber: "",
    family: "",
    smu: "",
    fleetNo: "",
    registrationNo: "",
    chasisNo: "",
    preparedBy: "",
    approvedBy: "",
    preparedOn: "2024-11-04",
    revisedBy: "",
    revisedOn: "2024-11-04",
    salesOffice: "",
    partsOrderDate: "2024-11-04",
    description: "",
    reference: "",
    validity: "FIFTEEN_DAYS",
    version: "VERSION_1",
    paymentTerms: "IMMEDIATE",
    billingFrequency: "ONE_TIME",
    billingType: "PAY_FIXED_PRICE",
    deliveryType: "STANDARD",
    deliveryPriority: "NORMAL",
    leadTime: "20",
    serviceRecipientAddress: "",
    priceDate: "2024-11-04",
    priceMethod: "LIST_PRICE",
    currency: "INR",
    partsPrice: 100.9,
    servicePrice: 0,
    laborPrice: 0,
    miscPrice: 0,
    environmentalPrice: 0,
    tax: 10.09,
    costPrice: 0,
    deliveryPrice: 0,
    totalPrice: 108.97,
    margin: 0,
    discount: 2.0180000000000002,
    netPrice: 0,
    adjustedPrice: 0,
    status: "PENDING_ACTIVE",
    builderId: "",
    standardJobId: "",
    kitId: "",
    builderType: "BUILDER_WITH_SPAREPART",
    dealerStore: "",
    accountName: "101211 - KOOLAN IRON ORE PTY LTD",
    machineSerialNumber: "",
    orderType: "EMMERGENCY",
    orderedBy: "",
    emailAddress: "",
    phoneNumber: "",
    shippingCondition: "SHIP_TO_ME",
    pickUpInst: "",
    shippingAddress: "1 Alan St, Rydalmere NSW 2116, Australia",
    billingAddress: "",
    shippingPoint: "SHIPPING_POINT_1",
    demandIndicator: false,
    partialShipment: false,
    machineBreakdown: false,
    needInstallationHelp: false,
    orderAsFavorite: false,
    partsQty: [
      {
        partsId: 191,
        partNumber: "0L1351",
        partDescription: "CAP SCREW",
        quantity: 1,
        allocatedQuantity: 0,
        listPrice: 100.9,
        totalPrice: 100.9,
        sparepartImages: [],
        fullyAllocated: false,
      },
    ],
    totalAllocatedQuantity: 0,
    orderingPlantName: null,
    deliveringPlantName: null,
    cartId: 10,
    createdAt: "2024-11-04T07:39:47.999398",
    updatedAt: "2024-11-04T07:39:48.038207",
    createdBy: 91,
    updatedBy: 91,
    tenantId: 91,
    sendToERP: false,
    discountApproved: false,
    deleted: false,
    updatedViaLink: false,
    orderRequisition: false,
    delivered: false,
    review: false,
    favorite: false,
    otherItemPrice: 0,
  };

  const columns = [
    {
      field: "partsId",
      headerName: "ID",
      flex: 1,
      // renderCell: (params) => params.id,
    },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "partDescription", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    {
      field: "listPrice",
      headerName: "Price",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format(row.listPrice),
    },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row.listPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format((row.listPrice + (row.listPrice * 10) / 100) * row?.quantity)}</div>
      ),
    },
  ];
  return (
    <>
      <div className="d-flex justify-content-between my-2 mt-2 ">
        <div>
          <h3 className="text-light">#{"PO000150" || "NA"}</h3>
          <h5 className="text-muted">
            Order History / Order Details / {""} - <Moment format="DD-MM-YYYY hh:mm A">{new Date()}</Moment>
          </h5>
        </div>
        <div>
          <button className="btn btn-danger mr-2">
            <DeleteIcon /> Delete Order
          </button>
          <button className="btn btn-info mr-2 ">
            <TrackChangesIcon /> Track Order
          </button>
          <button className="btn bg-primary text-white mr-2 ">
            <DriveFileRenameOutlineIcon /> Edit Order
          </button>
        </div>
      </div>
      <div className="card border px-3 py-2">
        <h4 className="mb-1">Progress</h4>
        <h6 className="text-muted">Current Order Status</h6>
        <div className="d-flex">
          <div className="card border p-3 m-2" style={{ width: "20%" }}>
            <div>
              <AllInboxIcon />
            </div>
            <h5 className="mt-2 mb-3">Order Confirming</h5>
            <ProgressBar progress={100} />
          </div>
          <div className="card border p-3 m-2" style={{ width: "20%" }}>
            <div>
              <PaymentIcon />
            </div>
            <h5 className="mt-2 mb-3">Payment Pending</h5>
            <ProgressBar progress={100} />
          </div>
          <div className="card border p-3 m-2" style={{ width: "20%" }}>
            <div>
              <AccountTreeIcon />
            </div>
            <h5 className="mt-2 mb-3">Processing</h5>
            <ProgressBar progress={50} />
          </div>
          <div className="card border p-3 m-2" style={{ width: "20%" }}>
            <div>
              <LocalShippingIcon />
            </div>
            <h5 className="mt-2 mb-3">Shipping</h5>
            <ProgressBar progress={0} />
          </div>
          <div className="card border p-3 m-2" style={{ width: "20%" }}>
            <div>
              <CheckCircleRoundedIcon />
            </div>
            <h5 className="mt-2 mb-3">Delivered</h5>
            <ProgressBar progress={0} />
          </div>
        </div>
      </div>
      <div className="card border px-3 py-2">
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-0">Customer</h4>
            <h6 className="text-muted">Information Details</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="card border" style={{ overflow: "hidden" }}>
              <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                <h6 className="bg-green text-white mb-0">Order Info </h6>
                {/* <Tooltip title="View Order info">
                  <ControlPointIcon className="cursor" onClick={() => setOpenOrderInfo(true)} />
                </Tooltip> */}
              </div>
              <div className={"p-2"}>
                <div className="row py-2">
                  <div className="col-md-3 col-sm-3">
                    <p className="mb-0">Account</p>
                  </div>
                  <div className="col-md-9 col-sm-9 text-right px-3">
                    <h6 className="mb-0 ">
                      <b>{rowData?.accountName || "-"}</b>
                    </h6>
                  </div>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Store</p>
                  <h6 className="mb-0">
                    <b>{rowData?.dealerStore?.label || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Order Type</p>
                  <h6 className="mb-0">
                    <b>{rowData?.orderType?.label || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Order By</p>
                  <h6 className="mb-0">
                    <b>{rowData?.orderedBy || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Email</p>
                  <h6 className="mb-0">
                    <b>{rowData?.emailAddress || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Contact</p>
                  <h6 className="mb-0">
                    <b>{rowData?.phoneNumber || "-"}</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="card border" style={{ overflow: "hidden", height: "17.4rem" }}>
              <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                <h6 className="bg-green text-white mb-0">Shipping Details</h6>
                {/* <Tooltip title="View Shipping Details">
                  <AddLocationAltIcon className="cursor" onClick={() => setOpenShippingDtls(true)} />
                </Tooltip> */}
              </div>
              <div className={"p-2"}>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Delivery Type</p>
                  <h6 className="mb-0">
                    <b>{rowData?.deliveryType?.label || "Standard"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Delivery Priority</p>
                  <h6 className="mb-0">
                    <b>{rowData?.deliveryPriority?.label || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Lead Time</p>
                  <h6 className="mb-0">
                    <b>{"$" + (rowData?.leadTime || 0) + " Days" || "-"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-block py-2">
                  <p className="mb-0">Address</p>
                  <h6 className="mb-0">
                    <b>{rowData?.shippingAddress || rowData?.pickUpInst || "-"}</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="card border" style={{ overflow: "hidden", height: "17.4rem" }}>
              <div className={`d-flex justify-content-between align-items-center bg-green p-2 text-white`}>
                <h6 className="bg-green text-white mb-0">Billing Details</h6>
                {/* <Tooltip title="View Billing Details">
                  <ControlPointIcon className="cursor" onClick={() => setOpenBillinfDtls(true)} />
                </Tooltip> */}
              </div>
              <div className={"p-2"}>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Payment Terms</p>
                  <h6 className="mb-0">
                    <b>{rowData?.paymentTerms?.label || "Immediate"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Currency</p>
                  <h6 className="mb-0">
                    <b>{rowData?.currency?.label || "USD"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Billing Type</p>
                  <h6 className="mb-0">
                    <b>{rowData?.billingType?.label || "Pay Fixed Price"}</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="mb-0">Billing Frequency</p>
                  <h6 className="mb-0">
                    <b>{rowData?.billingFrequency?.label || "One Time"}</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-sm-8">
          <div className="card border p-2">
            <div className="d-flex justify-content-between align-items-baseline">
              <div>
                <h4 className="mb-1">Product</h4>
                <h6 className="text-muted">Your Shipment</h6>
              </div>
              <div>
                <DescriptionIcon /> Download CSV
              </div>
            </div>
            <Divider className="mb-2" />
            <div className="order-summary-items">
              <DataGrid
                rows={(rowData && rowData["partsQty"]) || []}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => row.partsId}
                sx={GRID_STYLE}
                hideFooter={true}
                autoHeight
                pagination={false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="card border p-2 mb-3">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Payment</h4>
                <h6 className="text-muted">Final Payment Amount</h6>
              </div>
              <div>
                <SimCardDownloadIcon /> Download Invoice
              </div>
            </div>
            <div className="hr my-0"></div>
            <div className="py-2">
              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Sub Total</p>
                <h6 className="mb-0">
                  <b>
                    {currencyFormatter.format(
                      (rowData &&
                        rowData["partsQty"] &&
                        rowData["partsQty"].length > 0 &&
                        rowData["partsQty"].reduce((sum, partItem) => {
                          return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                        }, 0)) ||
                        0
                    )}
                  </b>
                </h6>
              </div>
              <div className="hr my-0"></div>
              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Tax (10%)</p>
                <h6 className="mb-0">
                  <b>
                    {currencyFormatter.format(
                      (((rowData &&
                        rowData["partsQty"] &&
                        rowData["partsQty"].length > 0 &&
                        rowData["partsQty"].reduce((sum, partItem) => {
                          return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                        }, 0)) ||
                        0) *
                        10) /
                        100
                    )}
                  </b>
                </h6>
              </div>
              <div className="hr my-0"></div>
              <div className="d-flex justify-content-between py-2">
                <div className="d-flex align-items-center mb-0">
                  <p className="mb-0">Discount</p>
                  <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                </div>
                <h6 className="mb-0">
                  <b>
                    -{" "}
                    {currencyFormatter.format(
                      (((rowData &&
                        rowData["partsQty"] &&
                        rowData["partsQty"].length > 0 &&
                        rowData["partsQty"].reduce((sum, partItem) => {
                          return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                        }, 0)) ||
                        0) *
                        2) /
                        100
                    )}
                  </b>
                </h6>
              </div>
              <div className="hr my-0"></div>
              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Shipping Cost</p>
                <h6 className="mb-0">
                  <b>{currencyFormatter.format(500)}</b>
                </h6>
              </div>
              <div className="hr my-0"></div>
              <div className="d-flex justify-content-between py-2">
                <h6 className="mb-0">
                  <b>Total</b>
                </h6>
                <h6 className="mb-0">
                  <b>
                    {currencyFormatter.format(
                      ((rowData &&
                        rowData["partsQty"] &&
                        rowData["partsQty"].length > 0 &&
                        rowData["partsQty"].reduce((sum, partItem) => {
                          return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                        }, 0)) ||
                        0) +
                        (((rowData &&
                          rowData["partsQty"] &&
                          rowData["partsQty"].length > 0 &&
                          rowData["partsQty"].reduce((sum, partItem) => {
                            return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                          }, 0)) ||
                          0) *
                          10) /
                          100 -
                        (((rowData &&
                          rowData["partsQty"] &&
                          rowData["partsQty"].length > 0 &&
                          rowData["partsQty"].reduce((sum, partItem) => {
                            return sum + (partItem.listPrice + (partItem.listPrice * 10) / 100) * partItem?.quantity;
                          }, 0)) ||
                          0) *
                          2) /
                          100 +
                        500
                    )}
                  </b>
                </h6>
              </div>
            </div>
          </div>
          <div className="row p-2">
            <button className="btn bg-primary text-white w-100" onClick={() => setActiveStep((prev) => prev + 1)}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaintenanceBuilderOrder;
