import { Tooltip } from "@mui/material";

const WarehouseSearch = (props) => {
  return (
    <>
      <div className="input-group">
        <input
          type="text"
          id="warehouseName"
          className="form-control border-right-0"
          value={props.value}
          autoComplete="off"
          onChange={props.onChange}
          disabled={props.disabled}
          placeholder={props.placeholder || ""}
          aria-label="Warehouse Name"
        />
        <span className="input-group-text rounded-0 border-left-0 bg-transparent text-light-gray font-size-14" style={{ background: "transparent" }}>
          <span class="fa fa-search fa-lg " />
        </span>
        <Tooltip title={`Add New ${props.title}`}>
          <button className="warehouse-add-btn border-left-0 text-light-gray" type="button">
            <i className="fa fa-plus"></i>
          </button>
        </Tooltip>
        {props.result && props.result.length > 0 && (
          <ul className={`list-group warehouse-search-list scrollbar-repair-autocomplete`} id="style">
            {props.result.map((currentItem, index) => (
              <li key={index} className="list-group-item" onClick={(e) => props.onSelect(props.type, currentItem)}>
                {props.type === "warehouse"
                  ? currentItem["warehouseNumber"] + " " + currentItem["warehouseName"]
                  : props.type === "partNumber"
                  ? currentItem[props.type] + " - " + currentItem["partDescription"]
                  : currentItem[props.type]}
              </li>
            ))}
          </ul>
        )}
        <span style={{ color: "red", fontSize: 12, height: 2 }}>{props.noOptions && "No Options Found!"}</span>
      </div>
      {/* <div className="customselectsearch">
        <div className={`warehouse-form-control-search ${props.className}`}>
          <span class="fa fa-search fa-lg " />
          <Tooltip title="Add New">
            <button className="warehouse-add-btn cursor px-2 ml-2 font-size-18" type="button">
              +
            </button>
          </Tooltip>
        </div>
        <input
          type="text"
          className="form-control text-primary"
          value={props.value}
          autoComplete="off"
          onChange={props.onChange}
          disabled={props.disabled}
          placeholder={props.placeholder || ""}
        />
        {props.result && props.result.length > 0 && (
          <ul className={`list-group customselectsearch-list scrollbar-repair-autocomplete`} id="style">
            {props.result.map((currentItem, index) => (
              <li key={index} className="list-group-item" onClick={(e) => props.onSelect(props.type, currentItem)}>
                {props.type === "warehouse"
                  ? currentItem["warehouseNumber"] + " " + currentItem["warehouseName"]
                  : props.type === "partNumber"
                  ? currentItem[props.type] + " - " + currentItem["partDescription"]
                  : currentItem[props.type]}
              </li>
            ))}
          </ul>
        )}
      </div> */}
    </>
  );
};

export default WarehouseSearch;
