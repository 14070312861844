import React, { useCallback, useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import EYEIcon from "@mui/icons-material/VisibilityOutlined";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "react-drag-drop-files";

import { faCloudUploadAlt, faFileAlt, faFolderPlus, faShareAlt, faUpload } from "@fortawesome/free-solid-svg-icons";

import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Box, debounce, Divider, TextareaAutosize, Tooltip, FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { DataGrid, getGridStringOperators, GridActionsCellItem, useGridApiContext } from "@mui/x-data-grid";
import { GRID_STYLE } from "pages/Common/constants";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";
import SearchComponent from "../components/SearchComponent";
import { sparePartSearch } from "services/searchServices";
import { ERROR_MAX_VERSIONS, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE, SPAREPART_SEARCH_Q_OPTIONS } from "../CONSTANTS";
import {
  addMultiPartsToPartList,
  addPartlistToOperation,
  addPartToPartList,
  createPartlistVersion,
  fetchPartlistFromOperation,
  fetchPartsFromPartlist,
  uploadPartsToPartlist,
} from "services/repairBuilderServices";
import { RenderConfirmDialog } from "../components/ConfirmationBox";
import AddNewSparepartModal from "../components/AddNewSparePart";
import LoadingProgress from "../components/Loader";

const initialPartsQuery = [
  {
    id: 0,
    selectCategory: "",
    selectOperator: "",
    inputSearch: "",
    selectOptions: [],
    selectedOption: "",
  },
];

const initialSparePart = {
  groupNumber: "",
  partType: "",
  partNumber: "",
  quantity: "",
  unitPrice: 0.0,
  extendedPrice: 0.0,
  unitOfMeasure: "",
  currency: "USD",
  usagePercentage: 0,
  totalPrice: 0.0,
  comment: "",
  description: "",
};

function CommentEditInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleCommentChange = async (event) => {
    // Explore debounce option
    apiRef.current.setEditCellValue({ id, field, value: event.target.value }, event);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextareaAutosize
        // ref={handleRef}
        name="comment"
        style={{ width: "100%" }}
        value={value}
        onChange={handleCommentChange}
      />
    </Box>
  );
}

const RepairBuilderPartsModal = ({
  show,
  hideModal,
  operationIndex,
  segmentIndex,
  operationData,
  handleSnack,
  segmentItems,
  setSegmentItems,
  partsItems,
  setPartsItems,
  activeElement,
  generalData,
  pricingData,
  priceMethodOptions = [],
  isPartItemEdit = false,
  isPartItemEditRow,
}) => {
  const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

  const [partsViewOnly, setPartsViewOnly] = useState(false);
  const [partsLoading, setPartsLoading] = useState(false);
  const [partLists, setPartLists] = useState([]);
  const [showParts, setShowParts] = useState(false);
  const [confirmationVersionOpen, setConfirmationVersionOpen] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState("Version 1");

  const [spareparts, setSpareparts] = useState([]);

  const [bulkUpdateProgress, setBulkUpdateProgress] = useState(false);

  const [queryPartsSearchSelector, setQueryPartsSearchSelector] = useState(initialPartsQuery);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const [partListId, setPartListId] = useState("");

  const [rowsToUpdate, setRowsToUpdate] = useState([]);

  const [searchResultPartsOpen, setSearchResultPartsOpen] = useState(false);
  const [sortDetail, setSortDetail] = useState({ sortColumn: "", orderBy: "" });
  const [partsFilterQuery, setPartsFilterQuery] = useState("");

  const [selectedPartsMasterData, setSelectedPartsMasterData] = useState([]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [totalPartsCount, setTotalPartsCount] = useState(0);
  const [file, setFile] = useState(null);

  const [sparePart, setSparePart] = useState(initialSparePart);
  const [addPartModalTitle, setAddPartModalTitle] = useState("Add Part");
  const [partFieldViewonly, setPartFieldViewonly] = useState(false);
  const [addPartOpen, setAddPartOpen] = useState(false);

  const [partsData, setPartsData] = useState({
    id: "",
    jobCode: operationData?.jobCode || "",
    description: operationData.description || "",
    jobOperation: operationData.jobCodeDescription || "",
    componentCode: operationData.componentCode || "",
    pricingMethod: "",
    user: "USER1",
  });

  const [masterData, setMasterData] = useState([]);

  const filterOperators = getGridStringOperators().filter(({ value }) => ["equals", "contains"].includes(value));

  useEffect(() => {
    if (!isPartItemEdit) {
      if (operationData?.id) {
        populatePartsData(operationData, false);
      }
    } else {
      setPartsData({
        ...isPartItemEditRow,
        id: isPartItemEditRow.id,
        pricingMethod: priceMethodOptions.find((element) => element.value === isPartItemEditRow.pricingMethod),
      });
      setPartListId(isPartItemEditRow.id);
      fetchPartsOfPartlist(isPartItemEditRow.id, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
      setPartsLoading(false);
    }
  }, [isPartItemEdit, operationData?.id]);

  //
  function populatePartsData(result, isDelete) {
    setPartsLoading(true);
    if (partListId && !isDelete) {
      let partListDetails = partLists.filter((partlist) => partlist.id === partListId);
      setPartsData({
        ...partListDetails[0],
        id: partListDetails[0].id,
        pricingMethod: priceMethodOptions.find((element) => element.value === partListDetails[0].pricingMethod),
      });
      setPartListId(partListDetails[0].id);
      // fetchPartsOfPartlist(partListDetails[0].id, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
      setPartsViewOnly(true);
    } else {
      fetchPartlistFromOperation(operationData.id)
        .then((resultPartLists) => {
          if (resultPartLists && resultPartLists.length > 0) {
            setPartLists(resultPartLists);
            if (resultPartLists.length === 1) {
              setPartsData({
                ...resultPartLists[0],
                id: resultPartLists[0].id,
                pricingMethod: priceMethodOptions.find((element) => element.value === resultPartLists[0].pricingMethod),
              });
              setPartListId(resultPartLists[0].id);
              fetchPartsOfPartlist(resultPartLists[0].id, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
            }
            setPartsViewOnly(true);
          } else {
            setPartsData({
              ...partsData,
              jobCode: result.jobCode,
              description: result.description,
              jobOperation: result.jobCodeDescription,
              componentCode: result.componentCode,
            });
          }
        })
        .catch((e) => {
          setPartsData({
            ...partsData,
            jobCode: result.jobCode,
            description: result.description,
            jobOperation: result.jobCodeDescription,
            componentCode: result.componentCode,
          });
        });
    }
    setPartsLoading(false);
  }

  // Once parts are selected to add clear the search results
  const clearFilteredData = () => {
    setMasterData([]);
  };

  // Consumable Search
  const handleQuerySearchClick = async (type) => {
    $(".scrollbar").css("display", "none");
    var searchStr = "";
    var querySearchSelector = queryPartsSearchSelector;
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory?.value && item.inputSearch) {
        searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else if (item.selectCategory?.value && item.inputSearch && item.selectOperator?.value) {
        searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
      } else {
        searchStr = "";
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        if (type === "parts") {
          const res = await sparePartSearch(searchStr);
          setMasterData(res);
          setSearchResultPartsOpen(true);
          setQueryPartsSearchSelector(initialPartsQuery);
        }
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching results!");
    }
  };

  const fetchPartsOfPartlist = async (partlistId, pageNo, rowsPerPage) => {
    setPartsLoading(true);
    setPage(pageNo);
    setPageSize(rowsPerPage);
    let sort = sortDetail.sortColumn ? `&sortColumn=${sortDetail.sortColumn}&orderBY=${sortDetail.orderBy}` : "";
    let filter = partsFilterQuery ? `&search=${partsFilterQuery}` : "";
    const query = `pageNumber=${pageNo}&pageSize=${rowsPerPage}${sort}${filter}`;
    await fetchPartsFromPartlist(partlistId, query)
      .then((partsResult) => {
        setTotalPartsCount(partsResult.totalRows);
        setSpareparts(partsResult.result);
      })
      .catch((err) => {
        handleSnack("error", "Error occured while fetching parts");
      });
    setPartsLoading(false);
  };

  function sortPartsTable(sortEvent) {
    if (sortEvent.length > 0) {
      setSortDetail({
        sortColumn: sortEvent[0].field,
        orderBy: sortEvent[0].sort === "asc" ? "ASC" : "DESC",
      });
    } else {
      setSortDetail({ sortColumn: "", orderBy: "" });
    }
  }

  const onPartsFilterChange = useCallback((filterModel) => {
    filterModel.items.map((indFilter) => {
      if (indFilter.operatorValue === "equals") debounce(setPartsFilterQuery(indFilter.columnField + ":" + indFilter.value), 200);
      else if (indFilter.operatorValue === "contains") setPartsFilterQuery(indFilter.columnField + "~" + indFilter.value);
    });
  }, []);

  // Add the sparepart edited rows to the state variable to update later
  const processRowUpdate = React.useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        if (newRow.usagePercentage > 0 && newRow.usagePercentage <= 100 && newRow.unitPrice > 0) {
          if (newRow.quantity !== oldRow.quantity || newRow.usagePercentage !== oldRow.usagePercentage || newRow.comment !== oldRow.comment) {
            const index = rowsToUpdate.findIndex((object) => object.id === newRow.id);
            newRow.extendedPrice = parseFloat(newRow.quantity * newRow.unitPrice).toFixed(2);
            newRow.totalPrice =
              newRow.usagePercentage > 0
                ? parseFloat(newRow.extendedPrice * 0.01 * newRow.usagePercentage).toFixed(2)
                : parseFloat(newRow.extendedPrice).toFixed(2);
            if (index === -1) {
              setRowsToUpdate((prevRows) => [...prevRows, newRow]);
            } else {
              rowsToUpdate[index] = newRow;
            }

            // Save the arguments to resolve or reject the promise later
            resolve(newRow);
          } else {
            resolve(oldRow); // Nothing was changed
          }
        } else {
          handleSnack("warning", "Usage percentage should be a valid value!");
          resolve(oldRow);
        }
      }),
    []
  );

  // Open partlist view or edit
  const loadPartlist = (row) => {
    setPartsData({ ...row, pricingMethod: priceMethodOptions.find((element) => element.value === row.pricingMethod) });
    setShowParts(true);
    setPartListId(row.id);
    setSelectedVersion("Version " + row.versionNumber);

    fetchPartsOfPartlist(row.id, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
  };

  // Updates the bulk edits
  const bulkUpdateParts = async () => {
    setConfirmationOpen(false);
    if (rowsToUpdate.length === 0) {
      handleSnack("info", `😐 No modifications to update!`);
    } else {
      await addMultiPartsToPartList(partListId, rowsToUpdate)
        .then((result) => {
          handleSnack("success", `👏 Parts have been updated!`);
          setRowsToUpdate([]);
          if (result) {
            fetchPartsOfPartlist(partListId, page, pageSize);
          }
        })
        .catch((err) => {
          setRowsToUpdate([]);
          handleSnack("error", `😐 Error occurred while adding the parts!`);
        });
    }
  };

  //Close Add part modal
  const handleAddPartClose = () => {
    setAddPartOpen(false);
    setSparePart(initialSparePart);
    setPartFieldViewonly(false);
    setAddPartModalTitle("Add Part");
  };

  const handleIndPartAdd = () => {
    let data = {
      ...(sparePart.id && { id: sparePart.id }),
      groupNumber: sparePart.groupNumber,
      partNumber: sparePart.partNumber,
      partType: sparePart.partType,
      quantity: sparePart.quantity,
      // unitPrice: sparePart.unitPrice,
      // extendedPrice: sparePart.extendedPrice,
      currency: sparePart.currency,
      usagePercentage: sparePart.usagePercentage,
      // totalPrice: sparePart.totalPrice,
      comment: sparePart.comment,
      description: sparePart.description,
      unitOfMeasure: sparePart.unitOfMeasure,
    };
    addPartToPartList(partListId, data)
      .then((result) => {
        handleAddPartClose();
        if (addPartModalTitle === "Add Part") handleSnack("success", `👏 New Spare Part has been added!`);
        else handleSnack("success", `👏 Selected part detail has been updated!`);
        // fetchPartsOfPartlist(partListNo, page, pageSize);
      })
      .catch((err) => {
        handleSnack("error", `😐 Error occurred while adding spare part`);
      });
  };

  // Close SparePart search modal
  const handleSearchResClose = () => {
    setSearchResultPartsOpen(false);
    setSelectedPartsMasterData([]);
  };

  // Select parts to add
  const onRowsSelectionHandler = (ids) => {
    setSelectedPartsMasterData([]);
    const selectedRowsData = ids.map((id) => masterData.find((row) => row.id === id));
    setSelectedPartsMasterData(selectedRowsData);
  };

  // Add the selected parts from search result to partlist
  const addSelectedPartsToPartList = async () => {
    setPartsLoading(true);
    handleSearchResClose();
    if (partsViewOnly) {
      const parts = [];
      selectedPartsMasterData.map((item) => {
        let data = {
          partlistId: partListId,
          groupNumber: item.groupNumber,
          partNumber: item.partNumber,
          partType: item.partType,
          quantity: 1,
          // currency: serviceEstimateData.currency,
          currency: pricingData?.currency?.value || "USD",
          comment: "",
          description: item.partDescription,
          unitOfMeasure: item.salesUnit,
        };
        parts.push(data);
      });

      await addMultiPartsToPartList(partListId, parts)
        .then((result) => {
          handleSnack("success", `New parts have been added with default quantity as 1 successfully!`);
          fetchPartsOfPartlist(partListId, page, pageSize);
        })
        .catch((err) => {
          if (err && err.message === "Price not found") {
            handleSnack("error", `😐 ${err.message}!`);
          } else {
            handleSnack("error", `😐 Error occurred while adding the parts!`);
          }
        });
    } else {
      handleSnack("info", "Please save all the header details!");
    }
    setPartsLoading(false);
  };

  // Search table column for spareparts
  const columnsPartListSearch = [
    { headerName: "Group Number", field: "groupNumber", flex: 1, width: 70 },
    { headerName: "Type", field: "partType", flex: 1, width: 130 },
    { headerName: "Part Number", field: "partNumber", flex: 1, width: 130 },
    { headerName: "Description", field: "partDescription", flex: 1, width: 130 },
    { headerName: "Currency", field: "currency", flex: 1, width: 130 },
    { headerName: "Status", field: "status", flex: 1, width: 130 },
  ];

  const partlistColumns = [
    {
      flex: 1,
      field: "partlistId",
      headerName: "Partlist Id",
      renderCell: (params) => (
        <div>
          <span style={{ fontSize: 12 }}>{params.value} </span>
          <span style={{ fontSize: 9 }}>{"  " + params.row.versionNumber + ".0"}</span>{" "}
        </div>
      ),
    },
    {
      field: "jobCode",
      headerName: "Job Code",
      flex: 3,
      renderCell: (params) => <span style={{ fontSize: 12 }}>{params.value + " - " + params.row.jobOperation}</span>,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
    {
      field: "activeVersion",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.value && <span style={{ backgroundColor: "#00b8b0", color: "white", fontSize: 12, borderRadius: 7, padding: 5 }}>ACTIVE</span>}
        </div>
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      flex: 2,
      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="View">
                  <EYEIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            onClick={() => loadPartlist(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Remove">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            // onClick={() => handleDeletePartlist(params.row.id)}
            color="inherit"
          />,
        ];
      },
      flex: 1,
      width: 130,
    },
  ];

  //Columns to display spare parts for the partlist
  const columnsPartListSpareParts = [
    // { headerName: 'Sl#', field: 'rowNum', flex: 1, },
    { headerName: "Group Number", field: "groupNumber", flex: 1 },
    { headerName: "Type", field: "partType", flex: 1 },
    { headerName: "Desc", field: "description", flex: 1 },
    { headerName: "Part Number", field: "partNumber", flex: 1 },
    {
      headerName: "Qty",
      field: "quantity",
      flex: 1,
      editable: true,
      filterable: false,
    },
    {
      headerName: "Sales Unit",
      field: "unitOfMeasure",
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Unit Price",
      field: "unitPrice",
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Extended Price",
      field: "extendedPrice",
      flex: 1,
      filterable: false,
    },
    { headerName: "Currency", field: "currency", flex: 1, filterable: false },
    {
      headerName: "% Usage",
      field: "usagePercentage",
      flex: 1,
      editable: true,
      filterable: false,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      flex: 1,
      filterable: false,
    },
    {
      headerName: "Comment",
      field: "comment",
      flex: 1,
      editable: true,
      renderEditCell: CommentEditInputCell,
      filterable: false,
    },
    // {
    //   headerName: "Tag",
    //   field: "tag",
    //   flex: 1,
    //   editable: true,
    //   renderCell: renderTag,
    //   renderEditCell: TagComponent
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={penIcon} alt="Edit" />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            // onClick={() => openSparePartRow(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <Tooltip title="Edit">
                  <img className="m-1" src={deleteIcon} alt="Delete" />
                </Tooltip>
              </div>
            }
            label="Delete"
            // onClick={() => handleDeleteSparePart(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleCancelPartlistAddUpdate = () => {
    if (!partsViewOnly) {
      populatePartsData(operationData, false);
    } else {
      hideModal();
    }
  };

  // create new Part List
  const createPartlistAndUpdate = async () => {
    await addPartlistToOperation(operationData.id, {
      ...(partListId && { id: partListId }),
      activeVersion: true,
      jobOperation: partsData.jobOperation,
      componentCode: partsData.componentCode,
      user: partsData.user,
      // currency: serviceEstimateData.currency,
      jobCode: partsData.jobCode,
      versionNumber: 1,
      description: partsData.description,
      // pricingMethod: partsData.pricingMethod?.value,
    })
      .then(async (newPartlist) => {
        await fetchPartlistFromOperation(operationData?.id).then((resultPartLists) => {
          if (resultPartLists && resultPartLists.length > 0) {
            const _partsItems = [...partsItems];

            const partItemIndex = _partsItems.findIndex((item) => item.id === newPartlist.id);
            if (partItemIndex !== -1) {
              _partsItems[partItemIndex] = newPartlist;
            } else {
              _partsItems.push(newPartlist);
            }
            setPartsItems([..._partsItems]);

            const _segmentItems = { ...segmentItems };
            const _segments = _segmentItems["segments"];
            const selectedSegment = _segments[segmentIndex];
            const _operations = selectedSegment["operations"][operationIndex];

            let optconsItemIndex = _operations["partListIds"].findIndex((item) => item === newPartlist.id);
            if (optconsItemIndex === -1) {
              _operations["partListIds"].push(newPartlist?.id);
            }
            setSegmentItems({ ..._segmentItems });

            setPartLists(resultPartLists);
          }
        });
        setPartListId(newPartlist.id);
        setShowParts(true);
        setSpareparts([]);
        setPartsViewOnly(true);
        handleSnack("success", `Partlist updated successfully`);
      })
      .catch((err) => {
        handleSnack("error", "Error occurred while updating partlist!");
      });
  };

  const loadNewPartList = () => {
    setPartListId("");
    setPartsData({
      id: "",
      jobCode: operationData.jobCode,
      description: operationData.description,
      jobOperation: operationData.jobCodeDescription,
      componentCode: operationData.componentCode,
      pricingMethod: "",
      user: "USER 1",
    });
    setPartsViewOnly(false);
  };

  const handleReadFile = (file) => {
    if (file) {
      setFile(file);
    }
  };

  //Uplaod spare parts through excel sheet
  const handleUploadFile = async () => {
    const form = new FormData();
    form.append("file", file);
    await uploadPartsToPartlist(partListId, form)
      .then((result) => {
        handleSnack("success", `New parts have been uploaded to the partlist`);
      })
      .catch((err) => {
        handleSnack("error", `Failed to upload the parts!`);
      });
    setFileUploadOpen(false);
  };

  const createPartsTableVersion = async () => {
    await createPartlistVersion(partListId).then((result) => {
      setConfirmationVersionOpen(false);
      setSelectedVersion("Version " + result.versionNumber);
      fetchPartlistFromOperation(operationData.id)
        .then((resultPartLists) => {
          if (resultPartLists && resultPartLists.length > 0) {
            setPartLists(resultPartLists);
            setPartsData({ ...result, id: result.id, pricingMethod: priceMethodOptions.find((element) => element.value === result.pricingMethod) });
            setPartListId(result.id);
            fetchPartsOfPartlist(result.id, INITIAL_PAGE_NO, INITIAL_PAGE_SIZE);
            setPartsViewOnly(true);
            handleSnack("success", `Version ${result.versionNumber} created successfully`);
          }
        })
        .catch((err) => {
          setConfirmationVersionOpen(false);

          if (err.message === "Not Allowed") handleSnack("warning", ERROR_MAX_VERSIONS);
          else handleSnack("error", "Error occurred while creating partlist version");
        });
    });
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="d-flex align-items-center">
            <h4 className="mb-0">{title}</h4>
            <a className="btn-sm cursor mx-2" onClick={() => setPartsViewOnly(false)}>
              <EditIcon sx={{ fontSize: 29 }} />
            </a>
          </div>
          <div className="text-right">
            {partLists && partLists.length > 1 && showParts && (
              <button type="button" className="btn btn-light bg-primary text-white mr-2" onClick={() => setShowParts(false)}>
                Back To Partlists
              </button>
            )}
            {!isPartItemEdit && (
              <button className="btn btn-light bg-primary text-white" onClick={() => loadNewPartList()}>
                + New Partlist
              </button>
            )}
          </div>
        </div>
        <div className="px-3">
          <Divider />
        </div>
        <Modal.Body className="p-0 bg-white">
          {partsLoading ? (
            <LoadingProgress />
          ) : (
            <>
              <div className="px-3 mb-3">
                {!partsViewOnly ? (
                  <div className="row mt-2 input-fields">
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.jobCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">JOB OPERATION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.jobOperation} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">DESCRIPTION</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.description} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">COMPONENT CODE</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.componentCode} />
                        <div className="css-w8dmq8">*Mandatory</div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                      <div class="form-group mt-3">
                        <label className="text-light-dark font-size-12 font-weight-600">USER</label>
                        <input type="text" disabled class="form-control border-radius-10 text-primary" value={partsData.user} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group mt-3 mb-0 text-right">
                        <button type="button" className="btn btn-light bg-primary text-white" onClick={handleCancelPartlistAddUpdate}>
                          Cancel
                        </button>
                        <button type="button" className="btn btn-light bg-primary text-white" onClick={createPartlistAndUpdate}>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {partLists && partLists.length > 1 && !showParts ? (
                      <div>
                        <DataGrid
                          sx={{ ...GRID_STYLE, marginInline: "auto" }}
                          paginationMode="client"
                          rows={partLists}
                          columns={partlistColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          autoHeight
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="text-right pl-3 py-3">
                          <div className="d-inline-flex ml-3">
                            <DropdownButton className="customDropdown ml-2" id="dropdown-item-button">
                              <Dropdown.Item as="button" onClick={() => setConfirmationVersionOpen(true)}>
                                New Version
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <ReadOnlyField label="JOB CODE" value={partsData.jobCode} className="col-md-4 col-sm-4" />
                          <ReadOnlyField label="JOB OPERATION" value={partsData.jobOperation} className="col-md-4 col-sm-4" />
                          <ReadOnlyField label="DESCRIPTION" value={partsData.description} className="col-md-4 col-sm-4" />
                          <ReadOnlyField label="COMPONENT CODE" value={partsData.componentCode} className="col-md-4 col-sm-4" />
                          <ReadOnlyField label="PRICE METHOD" value={partsData.pricingMethod?.label} className="col-md-4 col-sm-4" />
                          <ReadOnlyField label="USER" value={partsData.user} className="col-md-4 col-sm-4" />
                        </div>
                        <RenderConfirmDialog
                          confimationOpen={confirmationVersionOpen}
                          message={`Pressing 'Yes' will create another version of this partlist`}
                          handleNo={() => setConfirmationVersionOpen(false)}
                          handleYes={createPartsTableVersion}
                        />
                        <div className="card border mt-4 px-4">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex mr-3 col-auto pl-0" style={{ whiteSpace: "pre" }}>
                                  <h5 className="mr-2 mb-0 text-black">
                                    <span>Parts Table</span>
                                  </h5>
                                  <span>{selectedVersion}</span>
                                </div>
                                <SearchComponent
                                  querySearchSelector={queryPartsSearchSelector}
                                  setQuerySearchSelector={setQueryPartsSearchSelector}
                                  clearFilteredData={clearFilteredData}
                                  handleSnack={handleSnack}
                                  searchAPI={sparePartSearch}
                                  searchClick={() => handleQuerySearchClick("parts")}
                                  options={SPAREPART_SEARCH_Q_OPTIONS}
                                  background={"white"}
                                  type=""
                                  buttonText="ADD PART"
                                />
                              </div>
                            </div>
                            {["DRAFT", "REVISED"].indexOf(activeElement?.builderStatus) > -1 && (
                              <div className="col-4">
                                <div className="text-right pl-3 py-3">
                                  <button
                                    onClick={() => setFileUploadOpen(true)}
                                    style={{ cursor: "pointer" }}
                                    className="btn bg-primary text-white mx-2"
                                  >
                                    Upload
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          <DataGrid
                            sx={GRID_STYLE}
                            rows={spareparts}
                            autoHeight
                            columns={columnsPartListSpareParts.map((column) => ({ ...column, filterOperators }))}
                            editMode="row"
                            page={page}
                            pageSize={pageSize}
                            onPageChange={(newPage) => fetchPartsOfPartlist(partListId, newPage, pageSize)}
                            onPageSizeChange={(newPageSize) => fetchPartsOfPartlist(partListId, page, newPageSize)}
                            onRowEditStart={(e) => setBulkUpdateProgress(true)}
                            sortingMode="server"
                            onSortModelChange={(e) => sortPartsTable(e)}
                            filterMode="server"
                            onFilterModelChange={onPartsFilterChange}
                            onRowEditStop={(e) => setBulkUpdateProgress(false)}
                            paginationMode="server"
                            loading={partsLoading}
                            rowsPerPageOptions={[5, 10, 20]}
                            pagination
                            rowCount={totalPartsCount}
                            experimentalFeatures={{ newEditingApi: true }}
                            processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow)}
                            // getEstimatedRowHeight={() => 200}
                            // getRowHeight={() => "auto"}
                            onProcessRowUpdateError={(error) => console.log(error)}
                          />
                          <div className=" my-3 text-right">
                            {["DRAFT", "REVISED"].indexOf(activeElement?.builderStatus) > -1 && (
                              <button className="btn text-white bg-primary" onClick={() => setConfirmationOpen(true)} disabled={bulkUpdateProgress}>
                                Save
                              </button>
                            )}
                          </div>
                        </div>
                        <RenderConfirmDialog
                          confimationOpen={confirmationOpen}
                          message={`Pressing 'Yes' will save all the changes to partlist`}
                          handleNo={() => setConfirmationOpen(false)}
                          handleYes={bulkUpdateParts}
                        />
                        {/* Open Modal to add individual spare part to the part list */}
                        <AddNewSparepartModal
                          sparePart={sparePart}
                          setSparePart={setSparePart}
                          handleIndPartAdd={handleIndPartAdd}
                          searchAPI={sparePartSearch}
                          addPartOpen={addPartOpen}
                          handleAddPartClose={handleAddPartClose}
                          title={addPartModalTitle}
                          partFieldViewonly={partFieldViewonly}
                          setPartFieldViewonly={setPartFieldViewonly}
                          handleSnack={handleSnack}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      {fileUploadOpen && (
        <Modal show={fileUploadOpen} onHide={() => setFileUploadOpen(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="modal-header-border">
            <Modal.Title>Import Files</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="p-3">
              <div className="add-new-recod">
                <div>
                  <FontAwesomeIcon className="cloudupload" icon={faCloudUploadAlt} />
                  <h6 className="font-weight-500 mt-3">
                    Drag and drop files to upload <br /> or
                  </h6>
                  <FileUploader
                    handleChange={handleReadFile}
                    name="file"
                    types={["xls", "xlsx"]}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                </div>
              </div>
              <p className="mt-3">Single upload file should not be more than 10MB. Only the .xls, .xlsx file types are allowed</p>
            </div>
            <div className="recent-div p-3">
              <h6 className="font-weight-600 text-grey mb-0">RECENT</h6>
              <div className="recent-items mt-3">
                <div className="d-flex justify-content-between align-items-center ">
                  <p className="mb-0 ">
                    <FontAwesomeIcon className=" font-size-14" icon={faFileAlt} />
                    <span className="font-weight-500 ml-2">Engine Partlist</span>
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="white-space custom-checkbox">
                      <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                      </FormGroup>
                    </div>
                    <a href="#" className="ml-3 font-size-14">
                      <FontAwesomeIcon icon={faShareAlt} />
                    </a>
                    <a href="#" className="ml-3 font-size-14">
                      <FontAwesomeIcon icon={faFolderPlus} />
                    </a>
                    <a href="#" className="ml-3 font-size-14">
                      <FontAwesomeIcon icon={faUpload} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <p className="font-size-12 mb-0">2:38pm, 19 Aug 21 </p>
                <p className="font-size-12 mb-0">Part List </p>
              </div>
            </div>
          </Modal.Body>
          <div className="row m-0 p-3">
            <div className="col-md-6 col-sm-6">
              <button className="btn border w-100 bg-white" onClick={() => setFileUploadOpen(false)}>
                Cancel
              </button>
            </div>
            <div className="col-md-6 col-sm-6">
              <button className="btn btn-primary w-100" onClick={handleUploadFile} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                Upload
              </button>
            </div>
          </div>
        </Modal>
      )}

      {searchResultPartsOpen && (
        <Modal show={searchResultPartsOpen} onHide={handleSearchResClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header className="modal-header-border">
            <Modal.Title>Search Results</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 bg-white">
            <div className="card w-100 p-2">
              <div
                className=""
                style={{
                  height: 400,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#872ff7",
                      color: "#fff",
                    },
                  }}
                  rows={masterData}
                  columns={columnsPartListSearch}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                  // onCellClick={(e) => handleRowClick(e)}
                />
              </div>
            </div>
            <div className="m-2 text-right">
              <button className="btn text-white bg-primary mr-2" onClick={handleSearchResClose}>
                Cancel
              </button>
              <button className="btn text-white bg-primary" onClick={addSelectedPartsToPartList}>
                + Add Selected
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default RepairBuilderPartsModal;
