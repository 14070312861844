import React from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Box, Typography, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from "@mui/material";

import { Modal } from "react-bootstrap";

const rows = [
    {
        productName: "Brackets beheh",
        sku: "213-2311",
        quantity: "30 pcs",
        cost: "$90.00",
        total: "$2700.00",
    },
    {
        productName: "Charmflex Bite",
        sku: "213-2311",
        quantity: "20 pcs",
        cost: "$75.00",
        total: "$1500.00",
    },
];

const ShopRepairOrderReceiveStockModal = ({ show, hideModal, rowData, handleSnack }) => {
    return (
        <>
            <Modal show={show} onhide={hideModal} size="xl">
                <Modal.Body>
                    <Box>
                        {/* Header Section */}
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 3 }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <ArrowBackIosIcon sx={{ cursor: "pointer", mr: 2 }} />
                                <Typography variant="h6" fontWeight="bold">
                                    #OS12KOS
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ ml: 2, px: 1.5, py: 0.5, backgroundColor: "#E0F2F1", color: "#00695C", borderRadius: "12px", fontWeight: "bold" }}
                                >
                                    PENDING
                                </Typography>
                            </Box>
                            <Button variant="contained" sx={{ backgroundColor: "#3f51b5", color: "white", textTransform: "none", fontWeight: "bold", borderRadius: "8px" }}>
                                Receive Stock
                            </Button>
                        </Box>

                        {/* Edited Info */}
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                            Edited May 31 2022, 1:04pm by aku kamu
                        </Typography>

                        {/* Vendor and Order Info */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                p: 2,
                                border: "1px solid #E0E0E0",
                                borderRadius: "8px",
                                backgroundColor: "#fafafa",
                                mb: 3,
                            }}
                        >
                            <Box>
                                <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                                    Vendor
                                </Typography>
                                <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.5 }}>
                                    Acme Co.
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    1901 Thornridge Cir. Shiloh, Hawaii 81063
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" fontWeight="bold" sx={{ mb: 1, textAlign: "right" }}>
                                    Created On
                                </Typography>
                                <Typography variant="h6" fontWeight="bold" sx={{ textAlign: "right" }}>
                                    31 May 2022
                                </Typography>
                            </Box>
                        </Box>

                        {/* Product Table */}
                        <TableContainer component={Paper} elevation={0} sx={{ border: "1px solid #E0E0E0", borderRadius: "8px" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold", color: "#9E9E9E" }}>PRODUCT NAME</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: "#9E9E9E" }}>SKU</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: "#9E9E9E" }}>QUANTITY</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: "#9E9E9E" }}>COST</TableCell>
                                        <TableCell sx={{ fontWeight: "bold", color: "#9E9E9E" }}>TOTAL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                    <Avatar src={row.image} alt={row.productName} />
                                                    <Typography>{row.productName}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{row.sku}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                            <TableCell>{row.cost}</TableCell>
                                            <TableCell>{row.total}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <div className="row px-3 mt-2" style={{ justifyContent: "right" }}>
                        <button className="btn bg-primary text-white" onClick={hideModal}>
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShopRepairOrderReceiveStockModal;
