import React, { useEffect, useState } from "react";

import penIcon from "../../../assets/images/pen.png";
import deleteIcon from "../../../assets/icons/svg/delete.svg";

import EditIcon from "@mui/icons-material/Edit";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import { Divider, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import $ from "jquery";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { ReadOnlyField } from "pages/Common/ReadOnlyField";

import { getConsumables, getExtWork, getVendors, sparePartSearch } from "services/searchServices";
import {
    AddConsumableItem,
    AddConsumableToService,
    AddExtWorkItem,
    AddExtWorkToService,
    AddMiscToService,
    AddServiceHeader,
    FetchConsumableforService,
    FetchConsumableItems,
    FetchExtWorkforService,
    FetchExtWorkItems,
    FetchMiscforService,
    RemoveConsumableItem,
    RemoveExtWorkItem,
} from "services/repairBuilderServices";
import { CONS_EXT_PRICE_OPTIONS, CONSUMABLE_SEARCH_Q_OPTIONS, EXTWORK_SEARCH_Q_OPTIONS, FONT_STYLE_SELECT, GRID_STYLE, MISC_PRICE_OPTIONS } from "pages/Common/constants";
import SearchComponent from "pages/Repair/components/SearchComponent";
import ShopRepairConstItemAddModal from "./ShopRepairConstItemAddModal";
import ShopRepairExtWorkItemAddModal from "./ShopRepairExtWorkItemAddModal";

const initialConsQuery = [
    {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
    },
];

const initialExtWorkQuery = [
    {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
    },
];

const initialPartsQuery = [
    {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
    },
];

const initialExtWorkItemData = {
    activityId: "",
    activityName: "",
    description: "",
    supplyingVendorCode: "",
    supplyingVendorName: "",
    unitOfMeasure: "",
    unitPrice: 0.0,
    extendedPrice: 0.0,
    totalPrice: 0.0,
    estimatedHours: "",
    adjustedPrice: 0.0,
    dimensions: "",
};

const ShopRepairOtherCostModal = ({
    show,
    hideModal,
    operationIndex,
    segmentIndex,
    operationData,
    segmentItems,
    setSegmentItems,
    activeElement,
    materialItems,
    setMaterialItems,
    otherCostItems,
    setOtherCostItems,
    miscTypeList = [],
    dimensionList = [],
    activityIdList = [],
    consumableTypeList = [],
    unitOfMeasureOptions = [],
    generalData,
    pricingData,
    isMaterialItemEdit = false,
    isMaterialItemEditRow,
    handleSaveMaterialEditRow,
    isOtherCostItemEdit = false,
    isOtherCostItemEditRow,
    handleExtWorkEditRow,
    handleSnack,
    consumableItemsRecords,
    setConsumableItemsRecords,
    extWorkItemsRecords,
    setExtWorkItemsRecords,
}) => {
    const title = operationData?.componentCode + "-" + operationData?.componentCodeDescription;

    const initialConsumableItemData = {
        consumableType: "",
        consumableCode: "",
        description: "",
        quantity: "",
        unitOfMeasure: "",
        supplyingVendorCode: "",
        supplyingVendorName: "",
        vendor: "",
        unitPrice: 0.0,
        extendedPrice: 0.0,
        totalPrice: 0.0,
        usagePercentage: 100,
        // currency: operationData?.currency || "USD",
        currency: pricingData?.currency?.value || "USD",
    };

    const [tabValue, setTabValue] = useState("consumables");

    const [consumableViewOnly, setConsumableViewOnly] = useState(false);
    const [extWorkViewOnly, setExtWorkViewOnly] = useState(false);
    const [miscViewOnly, setMiscViewOnly] = useState(false);

    // Consumable Header
    const [consumableData, setConsumableData] = useState({
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData?.jobCodeDescription || "",
        pricingMethod: "",
        totalPrice: 0.0,
        payer: "",
        flatRateIndicator: false,
        adjustedPrice: 0.0,
        basePrice: 0.0,
        percentageOfBase: 0,
    });

    // Ext Work Header
    const [extWorkData, setExtWorkData] = useState({
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData?.jobCodeDescription || "",
        pricingMethod: "",
        totalPrice: 0.0,
        payer: "",
        flatRateIndicator: false,
        adjustedPrice: 0.0,
        basePrice: 0.0,
        percentageOfBase: 0,
    });

    // Misc Header
    const [miscData, setMiscData] = useState({
        jobCode: operationData?.jobCode || "",
        jobCodeDescription: operationData?.jobCodeDescription || "",
        pricingMethod: "",
        totalPrice: 0.0,
        payer: "",
        flatRateIndicator: false,
        adjustedPrice: 0.0,
        type: "",
        basePrice: 0.0,
        percentageOfBase: 0,
    });

    const [queryExtSearchSelector, setQueryExtSearchSelector] = useState(initialExtWorkQuery);
    const [queryPartsSearchSelector, setQueryPartsSearchSelector] = useState(initialPartsQuery);

    const [searchResultPartsOpen, setSearchResultPartsOpen] = useState(false);
    const [searchResultExtWorkOpen, setSearchResultExtWorkOpen] = useState(false);

    let [consumableItems, setConsumableItems] = useState([]);
    let [extWorkItems, setExtWorkItems] = useState([]);

    const [masterData, setMasterData] = useState([]);

    const [searchResultConsOpen, setSearchResultConsOpen] = useState(false);
    const [queryConsSearchSelector, setQueryConsSearchSelector] = useState(initialConsQuery);

    const [consumableItemData, setConsumableItemData] = useState(initialConsumableItemData);
    const [consumableItemOpen, setConsumableItemOpen] = useState(false);

    const [noOptionsConsumable, setNoOptionsConsumable] = useState(false);
    const [searchConsumableResult, setSearchConsumableResult] = useState([]);

    const [extWorkItemData, setExtWorkItemData] = useState(initialExtWorkItemData);
    const [extWorkItemOpen, setExtWorkItemOpen] = useState(false);

    const [noOptionsVendor, setNoOptionsVendor] = useState(false);
    const [searchVenodrResults, setSearchVendorResults] = useState([]);

    useEffect(() => {
        if (operationData?.serviceEstimateId) {
            if (!isMaterialItemEdit) {
                if (operationData?.consumableDetails) {
                    FetchConsumableforService(operationData?.serviceEstimateId)
                        .then((resultConsumable) => {
                            if (resultConsumable && resultConsumable.id) {
                                setConsumableData({
                                    ...resultConsumable,
                                    id: resultConsumable.id,
                                    pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === resultConsumable.pricingMethod),
                                    totalPrice: resultConsumable.totalPrice ? resultConsumable.totalPrice : 0,
                                });
                                populateConsItems(resultConsumable);
                                setConsumableViewOnly(true);
                            }
                        })
                        .catch((e) => {
                            setConsumableData({
                                ...consumableData,
                                jobCode: operationData?.jobCode || "",
                                jobCodeDescription: operationData.jobCodeDescription || "",
                            });
                        });
                }
            } else {
                openConsumableRow(isMaterialItemEditRow);
            }

            if (!isOtherCostItemEdit) {
                if (operationData?.externalWorkDetailsItems) {
                    FetchExtWorkforService(operationData?.serviceEstimateId)
                        .then((resultExtWork) => {
                            if (resultExtWork && resultExtWork.id) {
                                setExtWorkData({
                                    ...resultExtWork,
                                    id: resultExtWork.id,
                                    pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === resultExtWork.pricingMethod),
                                    totalPrice: resultExtWork.totalPrice ? resultExtWork.totalPrice : 0,
                                });
                                populateExtWorkItems(resultExtWork);
                                setExtWorkViewOnly(true);
                            }
                        })
                        .catch((e) => {
                            setExtWorkData({
                                ...extWorkData,
                                jobCode: operationData?.jobCode || "",
                                jobCodeDescription: operationData?.jobCodeDescription || "",
                            });
                        });
                }
            } else {
                openExtWorkRow(isOtherCostItemEditRow);
            }

            populateMiscData(operationData);
        }
    }, [isMaterialItemEdit, isOtherCostItemEdit, operationData?.serviceEstimateId, operationData?.consumableDetails, operationData?.externalWorkDetailsItems]);

    // get the consumable items
    function populateConsItems(result) {
        FetchConsumableItems(result.id)
            .then((resultConsumableItems) => {
                if (resultConsumableItems && resultConsumableItems.result?.length > 0) {
                    setConsumableItems(resultConsumableItems.result);
                    //   const _segmentItems = { ...segmentItems };
                    //   const _segments = _segmentItems["segments"];
                    //   const selectedSegment = _segments[segmentIndex];
                    //   const _operations = selectedSegment["operations"][operationIndex];

                    //   const ids = resultConsumableItems.result.map((item) => item.id);
                    //   _operations["consumableDetails"]["consumableItems"] = ids;
                    //   setSegmentItems({ ..._segmentItems });
                }
            })
            .catch((e) => {
                handleSnack("error", "Error occurred while fetching consumable items");
            });
    }

    // get the Exteranl work items
    function populateExtWorkItems(result) {
        FetchExtWorkItems(result.id)
            .then((resultExtWorkItems) => {
                if (resultExtWorkItems && resultExtWorkItems.result?.length > 0) {
                    setExtWorkItems(resultExtWorkItems.result);
                }
            })
            .catch((e) => {
                handleSnack("error", "Error occurred while fetching external work items");
            });
    }

    function populateExtWorkItems(result) {
        FetchExtWorkItems(result.id)
            .then((resultExtWorkItems) => {
                if (resultExtWorkItems && resultExtWorkItems.result?.length > 0) {
                    setExtWorkItems(resultExtWorkItems.result);
                }
            })
            .catch((e) => {
                handleSnack("error", "Error occurred while fetching external work items");
            });
    }

    // Populate misc header
    function populateMiscData(operationData) {
        FetchMiscforService(operationData.serviceEstimateId)
            .then((resultMisc) => {
                if (resultMisc && resultMisc.id) {
                    setMiscData({
                        ...resultMisc,
                        id: resultMisc.id,
                        pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === resultMisc.pricingMethod),
                        type: miscTypeList.filter(function (element) {
                            return resultMisc.type?.includes(element.value);
                        }),
                        totalPrice: resultMisc.totalPrice ? resultMisc.totalPrice : 0,
                    });
                    setMiscViewOnly(true);
                } else {
                    setMiscData({
                        ...miscData,
                        jobCode: operationData?.jobCode || "",
                        jobCodeDescription: operationData.jobCodeDescription || "",
                    });
                }
            })
            .catch((e) => {
                setMiscData({
                    ...miscData,
                    jobCode: operationData?.jobCode || "",
                    jobCodeDescription: operationData.jobCodeDescription || "",
                });
            });
    }

    // change the tab
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const makeDataEditable = (type) => {
        if (["DRAFT", "REVISED"].indexOf(activeElement?.builderStatus) > -1) {
            if (tabValue === "consumables" && consumableViewOnly) setConsumableViewOnly(false);
            if (tabValue === "extwork" && extWorkViewOnly) setExtWorkViewOnly(false);
            else if (tabValue === "othrMisc" && miscViewOnly) setMiscViewOnly(false);
        } else {
            handleSnack("info", "Active Builder cannot be changed, change status to REVISE!");
        }
    };

    // Close consumable search modal
    const handleSearchResConsClose = () => {
        setSearchResultConsOpen(false);
    };

    // Once parts are selected to add clear the search results
    const clearFilteredData = () => {
        setMasterData([]);
    };

    // Consumable Search
    const handleQuerySearchClick = async (type) => {
        if (type === "consumables" && !consumableData.id) handleSnack("warning", "Please update the Consumable details!");
        else if (type === "extwork" && !extWorkData.id) handleSnack("warning", "Please update the External Work details!");
        else {
            $(".scrollbar").css("display", "none");
            var searchStr = "";
            var querySearchSelector = type === "consumables" ? queryConsSearchSelector : type === "parts" ? queryPartsSearchSelector : queryExtSearchSelector;
            querySearchSelector.map(function (item, i) {
                if (i === 0 && item.selectCategory?.value && item.inputSearch) {
                    searchStr = item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
                } else if (item.selectCategory?.value && item.inputSearch && item.selectOperator?.value) {
                    searchStr = searchStr + " " + item.selectOperator.value + " " + item.selectCategory.value + ":" + encodeURI('"' + item.inputSearch + '"');
                } else {
                    searchStr = "";
                }
                return searchStr;
            });

            try {
                if (searchStr) {
                    if (type === "consumables") {
                        const res = await getConsumables(searchStr);
                        setMasterData(res);
                        setSearchResultConsOpen(true);
                    } else if (type === "extwork") {
                        const res = await getExtWork(searchStr);
                        setMasterData(res);
                        setSearchResultExtWorkOpen(true);
                    } else if (type === "parts") {
                        const res = await sparePartSearch(searchStr);
                        setMasterData(res);
                        setSearchResultPartsOpen(true);
                    }
                } else {
                    handleSnack("info", "Please fill the search criteria!");
                }
            } catch (err) {
                handleSnack("error", "Error occurred while fetching results!");
            }
        }
    };

    // Add the selected parts from search result to consumable
    const selectConsumableItem = async (selectedData) => {
        setSearchResultConsOpen(false);
        setConsumableItemData({
            ...consumableItemData,
            consumableCode: selectedData.consumableId,
            description: selectedData.name,
            consumableType: consumableTypeList.find((element) => element.value === selectedData.stockItem),
            supplyingVendorCode: selectedData.sourceOrVendor,
            supplyingVendorName: selectedData.sourceOrVendor,
            unitOfMeasure: selectedData.unit,
        });
        setConsumableItemOpen(true);
    };

    // close the consumable item Create Modal
    const handleConsumableItemClose = () => {
        setConsumableItemOpen(false);
        setSearchVendorResults([]);
        setConsumableItemData(initialConsumableItemData);
        if (isMaterialItemEdit) {
            handleSaveMaterialEditRow(consumableData.id);
        }
    };

    // Add or Update misc data
    const updateMiscHeader = () => {
        if (operationData?.serviceEstimateId) {
            let miscTypes = [];
            miscData.type?.map((element) => miscTypes.push(element.value));
            let data = {
                ...(miscData.id && { id: miscData.id }),
                jobCode: miscData.jobCode,
                jobCodeDescription: miscData.jobCodeDescription,
                payer: miscData.payer,
                type: miscTypes,
            };
            AddMiscToService(operationData?.serviceEstimateId, data)
                .then((result) => {
                    setMiscData({
                        ...result,
                        id: result.id,
                        pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                        type: miscTypeList.find((element) => element.value === result.type),
                    });
                    // populateServiceEstimation("othrMisc");
                    handleSnack("success", "Misc details updated!");
                    setMiscViewOnly(true);
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating misc data!");
                });
        } else {
            let estimateData = {
                reference: segmentItems?.builderId || "RO000174",
                description: generalData?.description || "992K Warranty",
                segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                currency: pricingData?.currency?.value || "USD",
                jobCode: operationData?.jobCode || "JB0361",
                id: null,
                jobCodeDescription: operationData?.jobCodeDescription || "Description114",
                componentCode: operationData?.componentCode || "ABC123",
                componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
            };
            AddServiceHeader(operationData?.id, estimateData)
                .then((estimateResult) => {
                    let miscTypes = [];
                    miscData.type?.map((element) => miscTypes.push(element.value));
                    let data = {
                        ...(miscData.id && { id: miscData.id }),
                        jobCode: miscData.jobCode,
                        jobCodeDescription: miscData.jobCodeDescription,
                        payer: miscData.payer,
                        type: miscTypes,
                    };

                    AddMiscToService(estimateResult.id, data)
                        .then((result) => {
                            setMiscData({
                                ...result,
                                id: result.id,
                                pricingMethod: MISC_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                                type: miscTypeList.find((element) => element.value === result.type),
                            });
                            const _segmentItems = { ...segmentItems };
                            const _segments = _segmentItems["segments"];
                            const selectedSegment = _segments[segmentIndex];
                            const _operations = selectedSegment["operations"][operationIndex];
                            _operations["serviceEstimateId"] = estimateResult?.id;
                            setSegmentItems({ ..._segmentItems });

                            // populateServiceEstimation("othrMisc");
                            handleSnack("success", "Misc details updated!");
                            setMiscViewOnly(true);
                        })
                        .catch((err) => {
                            handleSnack("error", "Error occurred while updating misc data!");
                        });
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating misc data!");
                    // handleSnack("error", "Error occurred while updating service estimate header!");
                });
        }
    };

    // Close ext work search modal
    const handleSearchResExtClose = () => {
        setSearchResultExtWorkOpen(false);
    };

    // exte work Item Add Modal close
    const handleExtWorkItemClose = () => {
        setExtWorkItemOpen(false);
        setSearchVendorResults([]);
        setExtWorkItemData(initialExtWorkItemData);
        if (isOtherCostItemEdit) {
            handleExtWorkEditRow(extWorkData?.id);
        }
    };

    // Select the external work item
    const selectExtWorkItem = async (selectedData) => {
        setSearchResultExtWorkOpen(false);
        setExtWorkItemData({
            ...extWorkItemData,
            activityId: activityIdList.find((element) => element.value === selectedData.activityId),
            description: selectedData.longDescription,
            activityName: selectedData.activityDescription,
            supplyingVendorCode: selectedData.supplyingVendorCode,
            supplyingVendorName: selectedData.supplyingVendorName,
        });
        setExtWorkItemOpen(true);
    };

    // Select the vendor from search result
    const handleVendorConsSelect = (type, currentItem) => {
        setConsumableItemData({
            ...consumableItemData,
            supplyingVendorName: currentItem.fullName,
            supplyingVendorCode: currentItem.customerId,
        });
        setSearchVendorResults([]);
    };

    // Search Consumable
    const handleConsumableSearch = async (searchConsumablefieldName, searchText) => {
        setSearchConsumableResult([]);
        consumableItemData.consumableCode = searchText;
        if (searchText) {
            await getConsumables("consumableId~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchConsumableResult(result);
                        setNoOptionsConsumable(false);
                    } else {
                        setNoOptionsConsumable(true);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    handleSnack("error", "Error occurred while searching the Consumable!");
                });
        }
    };

    // Select the consumable from search result
    const handleConsumableSelect = (type, currentItem) => {
        setConsumableItemData({
            ...consumableItemData,
            consumableCode: currentItem.consumableId,
            description: currentItem.name,
            unitOfMeasure: currentItem.unit,
        });
        setSearchConsumableResult([]);
    };

    // Add or Update Consumable Item
    const addConsumableItem = () => {
        if (consumableData.id) {
            let data = {
                ...(consumableItemData.id && { id: consumableItemData.id }),
                consumableType: consumableItemData.consumableType?.value,
                supplyingVendorCode: consumableItemData.supplyingVendorCode,
                supplyingVendorName: consumableItemData.supplyingVendorName,
                consumableCode: consumableItemData.consumableCode,
                description: consumableItemData.description,
                quantity: consumableItemData.quantity,
                unitOfMeasure: consumableItemData.unitOfMeasure,
                usagePercentage: consumableItemData.usagePercentage,
                currency: consumableItemData.currency,
            };

            AddConsumableItem(consumableData.id, data)
                .then((result) => {
                    setConsumableItemData(initialConsumableItemData);

                    const _consumableItems = [...consumableItems];
                    const conItemsIndex = consumableItems.findIndex((item) => item.id === result.id);
                    if (conItemsIndex !== -1) {
                        _consumableItems[conItemsIndex] = result;
                    } else {
                        _consumableItems.push(result);
                    }
                    setConsumableItems((pre) => [..._consumableItems]);

                    const _materialItems = [...materialItems["result"]];
                    const materialItemsIndex = _materialItems.findIndex((item) => item.id === result.id);
                    if (materialItemsIndex !== -1) {
                        _materialItems[materialItemsIndex] = result;
                    } else {
                        _materialItems.push(result);
                    }
                    setMaterialItems({ result: [..._materialItems], totalRows: _materialItems.length });

                    let _consumableItemsRecords = [...consumableItemsRecords];
                    const index = _consumableItemsRecords.findIndex((item) => item.id === result.id);

                    if (index !== -1) {
                        _consumableItemsRecords[index] = { ...result };
                    } else {
                        _consumableItemsRecords.push(result);
                    }
                    setConsumableItemsRecords([..._consumableItemsRecords]);

                    const _segmentItems = { ...segmentItems };
                    const _segments = _segmentItems["segments"];
                    const selectedSegment = _segments[segmentIndex];
                    const _operations = selectedSegment["operations"][operationIndex];
                    let optconsItemIndex = _operations["consumableDetails"]["consumableItems"].findIndex((item) => item === result.id);
                    if (optconsItemIndex === -1) {
                        _operations["consumableDetails"]["consumableItems"].push(result?.id);
                    }
                    setSegmentItems({ ..._segmentItems });

                    handleSnack("success", `${optconsItemIndex === -1 ? "Added" : "Updated"} consumable item successfully`);
                })
                .catch((err) => {
                    console.log("errors message ::: ", err);
                    handleSnack("error", "Error occurred while adding consumable item!");
                });
            handleConsumableItemClose();
            setQueryConsSearchSelector(initialConsQuery);
        } else {
            handleSnack("warning", "Please update the consumable header details!");
        }
    };

    // Add or Update Consumable Item
    const addExtWorkItem = () => {
        let data = {
            // ...extWorkItemData,
            ...(extWorkItemData.id && { id: extWorkItemData.id }),
            activityId: extWorkItemData.activityId?.value,
            activityName: extWorkItemData.activityName,
            description: extWorkItemData.description,
            supplyingVendorCode: extWorkItemData.supplyingVendorCode,
            supplyingVendorName: extWorkItemData.supplyingVendorName,
            estimatedHours: extWorkItemData.estimatedHours,
            dimensions: extWorkItemData.dimensions?.value,
            ...(extWorkItemData.adjustedPrice && {
                adjustedPrice: extWorkItemData.adjustedPrice,
            }),
            unitOfMeasure: extWorkItemData.unitOfMeasure?.value,
        };

        AddExtWorkItem(extWorkData.id, data)
            .then((result) => {
                setExtWorkItemData(initialExtWorkItemData);

                const _extWorkItems = [...extWorkItems];
                const extWorkItemsIndex = _extWorkItems.findIndex((item) => item.id === result.id);
                if (extWorkItemsIndex !== -1) {
                    _extWorkItems[extWorkItemsIndex] = result;
                } else {
                    _extWorkItems.push(result);
                }
                setExtWorkItems([..._extWorkItems]);

                const _otherCostItems = [...otherCostItems["result"]];
                const otherCostItemsIndex = _otherCostItems.findIndex((item) => item.id === result.id);
                if (otherCostItemsIndex !== -1) {
                    _otherCostItems[otherCostItemsIndex] = result;
                } else {
                    _otherCostItems.push(result);
                }
                setOtherCostItems({ result: [..._otherCostItems], totalRows: _otherCostItems.length });

                let _extWorkItemsRecords = [...extWorkItemsRecords];
                const index = _extWorkItemsRecords.findIndex((item) => item.id === result.id);

                if (index !== -1) {
                    _extWorkItemsRecords[index] = { ...result };
                } else {
                    _extWorkItemsRecords.push(result);
                }
                setExtWorkItemsRecords([..._extWorkItemsRecords]);

                const _segmentItems = { ...segmentItems };
                const _segments = _segmentItems["segments"];
                const selectedSegment = _segments[segmentIndex];
                const _operations = selectedSegment["operations"][operationIndex];
                let optOtherCostItemIndex = _operations["externalWorkDetailsItems"]["externalWorkItems"].findIndex((item) => item === result.id);
                if (optOtherCostItemIndex === -1) {
                    _operations["externalWorkDetailsItems"]["externalWorkItems"].push(result?.id);
                }
                setSegmentItems({ ..._segmentItems });
                handleSnack("success", `${optOtherCostItemIndex === -1 ? "Added" : "Updated"} ext work item successfully`);
            })
            .catch((err) => {
                handleSnack("error", "Error occurred while adding external work item!");
            });
        setQueryExtSearchSelector(initialExtWorkQuery);
        handleExtWorkItemClose();
    };

    // Search Vendors
    const handleVendorSearch = async (searchVendorfieldName, searchText) => {
        setSearchVendorResults([]);
        // if (searchVendorfieldName === "consVendor") {
        //   consumableItemData.supplyingVendorName = searchText;
        // } else {
        // }
        extWorkItemData.supplyingVendorName = searchText;
        if (searchText) {
            await getVendors("fullName~" + searchText)
                .then((result) => {
                    if (result && result.length > 0) {
                        setSearchVendorResults(result);
                        setNoOptionsVendor(false);
                    } else {
                        setNoOptionsVendor(true);
                    }
                })
                .catch((e) => {
                    handleSnack("error", "Error occurred while searching the vendor!");
                });
        }
    };

    // Select the vendor from search result
    const handleVendorSelect = (type, currentItem) => {
        setExtWorkItemData({
            ...extWorkItemData,
            supplyingVendorName: currentItem.fullName,
            supplyingVendorCode: currentItem.customerId,
        });
        setSearchVendorResults([]);
    };

    // Add or Update consumable data
    const updateConsumableHeader = () => {
        if (operationData?.serviceEstimateId) {
            let data = {
                ...(consumableData.id && { id: consumableData.id }),
                jobCode: consumableData.jobCode,
                jobCodeDescription: consumableData.jobCodeDescription,
                payer: consumableData.payer,
            };
            AddConsumableToService(operationData?.serviceEstimateId, data)
                .then((result) => {
                    setConsumableData({
                        ...result,
                        id: result.id,
                        pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                    });

                    const _segmentItems = { ...segmentItems };
                    const _segments = _segmentItems["segments"];
                    const selectedSegment = _segments[segmentIndex];
                    const _operations = selectedSegment["operations"][operationIndex];
                    if (!_operations["consumableDetails"]) {
                        _operations["consumableDetails"] = { ...result, consumableItems: [] };
                        // _operations["consumableDetails"]["consumableItems"].push(result?.id);
                        setSegmentItems({ ..._segmentItems });
                    }
                    handleSnack("success", "Consumable details updated!");
                    setConsumableViewOnly(true);
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating consumable data!");
                });
        } else {
            let estimateData = {
                reference: segmentItems?.builderId || "RO000174",
                description: generalData?.description || "992K Warranty",
                segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                currency: pricingData?.currency?.value || "USD",
                jobCode: operationData?.jobCode || "JB0361",
                id: null,
                jobCodeDescription: operationData?.jobCodeDescription || "Description114",
                componentCode: operationData?.componentCode || "ABC123",
                componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
            };
            AddServiceHeader(operationData?.id, estimateData)
                .then((estimateResult) => {
                    let data = {
                        ...(consumableData.id && { id: consumableData.id }),
                        jobCode: consumableData.jobCode,
                        jobCodeDescription: consumableData.jobCodeDescription,
                        payer: consumableData.payer,
                    };
                    AddConsumableToService(estimateResult?.id, data)
                        .then((result) => {
                            setConsumableData({
                                ...result,
                                id: result.id,
                                pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                            });

                            const _segmentItems = { ...segmentItems };
                            const _segments = _segmentItems["segments"];
                            const selectedSegment = _segments[segmentIndex];
                            const _operations = selectedSegment["operations"][operationIndex];
                            _operations["serviceEstimateId"] = estimateResult?.id;
                            if (!_operations["consumableDetails"]) {
                                _operations["consumableDetails"] = { ...result, consumableItems: [] };
                            }
                            setSegmentItems({ ..._segmentItems });
                            handleSnack("success", "Consumable details updated!");
                            setConsumableViewOnly(true);
                        })
                        .catch((err) => {
                            handleSnack("error", "Error occurred while updating consumable data!");
                        });
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating consumable data!");
                    // handleSnack("error", "Error occurred while updating service estimate header!");
                });
        }
    };

    // Add or Update ext work data
    const updateExtWorkHeader = () => {
        if (operationData?.serviceEstimateId) {
            let data = {
                // ...extWorkData,
                ...(extWorkData.id && { id: extWorkData.id }),
                jobCode: extWorkData.jobCode,
                jobCodeDescription: extWorkData.jobCodeDescription,
                payer: extWorkData.payer,
            };
            AddExtWorkToService(operationData?.serviceEstimateId, data)
                .then((result) => {
                    setExtWorkData({
                        ...result,
                        id: result.id,
                        pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                    });

                    const _segmentItems = { ...segmentItems };
                    const _segments = _segmentItems["segments"];
                    const selectedSegment = _segments[segmentIndex];
                    const _operations = selectedSegment["operations"][operationIndex];
                    if (!_operations["externalWorkDetailsItems"]) {
                        _operations["externalWorkDetailsItems"] = { ...result, externalWorkItems: [] };
                        setSegmentItems({ ..._segmentItems });
                    }

                    handleSnack("success", "External work details updated!");
                    setExtWorkViewOnly(true);
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating external work data!");
                });
        } else {
            let estimateData = {
                reference: segmentItems?.builderId || "RO000174",
                description: generalData?.description || "992K Warranty",
                segmentTitle: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                jobOperation: operationData?.jobCodeDescription + " " + operationData?.componentCodeDescription || "Description114 Air Conditioner Unit",
                currency: pricingData?.currency?.value || "USD",
                jobCode: operationData?.jobCode || "JB0361",
                id: null,
                jobCodeDescription: operationData?.jobCodeDescription || "Description114",
                componentCode: operationData?.componentCode || "ABC123",
                componentCodeDescription: operationData?.componentCodeDescription || "Air Conditioner Unit",
            };
            AddServiceHeader(operationData?.id, estimateData)
                .then((estimateResult) => {
                    let data = {
                        // ...extWorkData,
                        ...(extWorkData.id && { id: extWorkData.id }),
                        jobCode: extWorkData.jobCode,
                        jobCodeDescription: extWorkData.jobCodeDescription,
                        payer: extWorkData.payer,
                    };
                    AddExtWorkToService(estimateResult?.id, data)
                        .then((result) => {
                            setExtWorkData({
                                ...result,
                                id: result.id,
                                pricingMethod: CONS_EXT_PRICE_OPTIONS.find((element) => element.value === result.pricingMethod),
                            });

                            const _segmentItems = { ...segmentItems };
                            const _segments = _segmentItems["segments"];
                            const selectedSegment = _segments[segmentIndex];
                            const _operations = selectedSegment["operations"][operationIndex];
                            _operations["serviceEstimateId"] = estimateResult?.id;
                            if (!_operations["externalWorkDetailsItems"]) {
                                _operations["externalWorkDetailsItems"] = { ...result, externalWorkItems: [] };
                            }
                            setSegmentItems({ ..._segmentItems });

                            handleSnack("success", "External work details updated!");
                            setExtWorkViewOnly(true);
                        })
                        .catch((err) => {
                            handleSnack("error", "Error occurred while updating external work data!");
                        });
                })
                .catch((err) => {
                    handleSnack("error", "Error occurred while updating external work data!");
                    // handleSnack("error", "Error occurred while updating service estimate header!");
                });
        }
    };

    // Open consumable item to view or edit
    const openConsumableRow = (row) => {
        if (isMaterialItemEdit) {
            const _segmentItems = { ...segmentItems };
            const _segments = _segmentItems["segments"];
            const selectedSegment = _segments[segmentIndex];
            const _operations = selectedSegment["operations"][operationIndex];
            setConsumableData({
                ...consumableData,
                id: _operations["consumableDetails"]["id"],
                jobCode: operationData?.jobCode || "",
                jobCodeDescription: operationData.jobCodeDescription || "",
            });
        }
        setConsumableItemData({
            ...row,
            consumableType: consumableTypeList.find((element) => element.value === row.consumableType),
            unitPrice: row.unitPrice ? row.unitPrice : 0,
            extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
            totalPrice: row.totalPrice ? row.totalPrice : 0,
        });
        setConsumableItemOpen(true);
    };

    //Remove Consumable Item
    const handleDeleteConsumableItem = (consItemId) => {
        RemoveConsumableItem(consumableData.id, consItemId)
            .then((res) => {
                handleSnack("success", res);

                const _consumableItems = consumableItems.filter((item) => item?.id !== consItemId);
                setConsumableItems(_consumableItems);

                const _materialItems = materialItems["result"].filter((item) => item?.id !== consItemId);
                setMaterialItems({ result: [..._materialItems], totalRows: _materialItems.length });

                const _consumableItemsRecords = consumableItemsRecords.filter((item) => item?.id !== consItemId);
                setConsumableItemsRecords(_consumableItemsRecords);

                const _segmentItems = { ...segmentItems };
                const _segments = _segmentItems["segments"];
                const selectedSegment = _segments[segmentIndex];
                const _operations = selectedSegment["operations"][operationIndex];
                _operations["consumableDetails"]["consumableItems"].filter((item) => item !== consItemId);
                setSegmentItems({ ..._segmentItems });
            })
            .catch((e) => {
                console.log(e);
                handleSnack("error", "Error occurred while removing the consumable item");
            });
    };

    const columnsConsumables = [
        { field: "consumableCode", headerName: "Consumable ID", flex: 1, width: 70 },
        { field: "consumableType", headerName: "Type", flex: 1, width: 70 },
        { field: "description", headerName: "Description", flex: 1, width: 130 },
        { field: "quantity", headerName: " Quantity", flex: 1, width: 130 },
        { field: "unitOfMeasure", headerName: "Unit of measure", flex: 1, width: 130 },
        { field: "supplyingVendorCode", headerName: "Vendor", flex: 1, width: 130 },
        { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
        { field: "extendedPrice", headerName: "Extended price", flex: 1, width: 130 },
        { field: "currency", headerName: "Currency", flex: 1, width: 130 },
        { field: "usagePercentage", headerName: "% Usage", flex: 1, width: 130 },
        { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
        {
            field: "Actions",
            headerName: "Actions",
            type: "actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <div className=" cursor">
                                <Tooltip title="Edit">
                                    <img className="m-1" src={penIcon} alt="Edit" />
                                </Tooltip>
                            </div>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={() => openConsumableRow(row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <div className=" cursor">
                                <Tooltip title="Remove">
                                    <img className="m-1" src={deleteIcon} alt="Delete" />
                                </Tooltip>
                            </div>
                        }
                        label="Delete"
                        onClick={() => handleDeleteConsumableItem(row.id)}
                        color="inherit"
                    />,
                ];
            },
            flex: 1,
            width: 130,
        },
    ];

    // Search table column for consumable
    const columnsConsumableSearch = [
        { headerName: "Consumable Id", field: "consumableId", flex: 1, width: 70 },
        { headerName: "Name", field: "name", flex: 1, width: 130 },
        { headerName: "Supplying Vendor", field: "sourceOrVendor", flex: 1, width: 130 },
    ];

    // Open ext work item to view or edit
    const openExtWorkRow = (row) => {
        if (isOtherCostItemEdit) {
            const _segmentItems = { ...segmentItems };
            const _segments = _segmentItems["segments"];
            const selectedSegment = _segments[segmentIndex];
            const _operations = selectedSegment["operations"][operationIndex];
            setExtWorkData({
                ...extWorkData,
                id: _operations["externalWorkDetailsItems"]["id"],
                jobCode: operationData?.jobCode || "",
                jobCodeDescription: operationData?.jobCodeDescription || "",
            });
        }
        setExtWorkItemData({
            ...row,
            activityId: activityIdList.find((element) => element.value === row.activityId),
            dimensions: dimensionList.find((element) => element.value === row.dimensions),
            unitOfMeasure: unitOfMeasureOptions.find((element) => element.value === row.unitOfMeasure),
            unitPrice: row.unitPrice ? row.unitPrice : 0,
            extendedPrice: row.extendedPrice ? row.extendedPrice : 0,
            totalPrice: row.totalPrice ? row.totalPrice : 0,
        });
        setExtWorkItemOpen(true);
    };

    //Remove Ext work Item
    const handleDeleteExtWorkItem = (extWorkItemId) => {
        RemoveExtWorkItem(extWorkData.id, extWorkItemId)
            .then((res) => {
                handleSnack("success", res);

                const _extWorkItems = extWorkItems.filter((item) => item?.id !== extWorkItemId);
                setExtWorkItems(_extWorkItems);

                const _otherCostItems = otherCostItems["result"].filter((item) => item?.id !== extWorkItemId);
                setOtherCostItems({ result: [..._otherCostItems], totalRows: _otherCostItems.length });

                const _extWorkItemsRecords = extWorkItemsRecords.filter((item) => item?.id !== extWorkItemId);
                setExtWorkItemsRecords(_extWorkItemsRecords);

                const _segmentItems = { ...segmentItems };
                const _segments = _segmentItems["segments"];
                const selectedSegment = _segments[segmentIndex];
                const _operations = selectedSegment["operations"][operationIndex];
                _operations["externalWorkDetailsItems"]["externalWorkItems"].filter((item) => item !== extWorkItemId);
                setSegmentItems({ ..._segmentItems });
            })
            .catch((e) => {
                handleSnack("error", "Error occurred while removing the ext work item!");
            });
    };

    const columnsExternal = [
        { field: "activityId", headerName: "Activity ID", flex: 1, width: 70 },
        { field: "activityName", headerName: "Activity Name", flex: 1, width: 70 },
        { field: "description", headerName: "Description", flex: 1, width: 70 },
        { field: "estimatedHours", headerName: "Estimated Effort", flex: 1, width: 130 },
        { field: "unitPrice", headerName: "Unit Price", flex: 1, width: 130 },
        { field: "extendedPrice", headerName: "Extended price", flex: 1, width: 130 },
        { field: "totalPrice", headerName: "Total price", flex: 1, width: 130 },
        { field: "dimensions", headerName: "Dimension", flex: 1, width: 130 },
        { field: "supplyingVendorName", headerName: "Supplying Vendor", flex: 1, width: 130 },
        {
            field: "Actions",
            headerName: "Actions",
            type: "actions",
            cellClassName: "actions",
            getActions: ({ row }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <div className=" cursor">
                                <Tooltip title="Edit">
                                    <img className="m-1" src={penIcon} alt="Edit" />
                                </Tooltip>
                            </div>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={() => openExtWorkRow(row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <div className=" cursor">
                                <Tooltip title="Remove">
                                    <img className="m-1" src={deleteIcon} alt="Delete" />
                                </Tooltip>
                            </div>
                        }
                        label="Delete"
                        onClick={() => handleDeleteExtWorkItem(row.id)}
                        color="inherit"
                    />,
                ];
            },
            flex: 1,
            width: 130,
        },
    ];

    // Search table column for consumable
    const columnsExtWorkSearch = [
        { headerName: "Activity Id", field: "activityId", flex: 1, width: 70 },
        { headerName: "Activity Name", field: "activityDescription", flex: 1, width: 130 },
        { headerName: "Supplying Vendor", field: "supplyingVendorName", flex: 1, width: 130 },
    ];

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <div className="d-flex align-items-center p-3">
                    <h4 className="mb-0">{title}</h4>
                    <a className="btn-sm cursor mx-2" onClick={() => makeDataEditable()}>
                        <EditIcon sx={{ fontSize: 29 }} />
                    </a>
                </div>
                <div className="px-3">
                    <Divider />
                </div>
                <Modal.Body className="p-0 bg-white">
                    <Box sx={{ width: "100%", typography: "body1", paddingX: 2 }}>
                        <TabContext value={tabValue}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }} display="flex" alignItems="center" justifyContent="space-between">
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList className="custom-tabs-div" onChange={handleTabChange}>
                                        <Tab label="Consumables" value="consumables" />
                                        <Tab label="External Work" value="extwork" />
                                        <Tab label="Other misc." value="othrMisc" />
                                    </TabList>
                                </Box>
                            </Box>
                            <TabPanel value="consumables">
                                {!consumableViewOnly ? (
                                    <div className="row mt-2 input-fields">
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCode} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.jobCodeDescription} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4"></div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                                                <input
                                                    type="text"
                                                    className="form-control border-radius-10 text-primary"
                                                    value={consumableData.payer}
                                                    onChange={(e) => setConsumableData({ ...consumableData, payer: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={consumableData.totalPrice} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="form-group mt-3 mb-0 text-right">
                                                <button type="button" className="btn btn-light bg-primary text-white" onClick={updateConsumableHeader}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mt-4">
                                        <ReadOnlyField label="JOB CODE" value={consumableData.jobCode} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="JOB CODE DESCRIPTION" value={consumableData.jobCodeDescription} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="PAYER" value={consumableData.payer} className="col-md-4 col-sm-4" />

                                        <ReadOnlyField label="PRICE METHOD" value={consumableData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                                        <ReadOnlyField label="NET PRICE" value={consumableData.totalPrice} className="col-md-4 col-sm-4" />
                                    </div>
                                )}
                                <div className="mt-2 mb-4">
                                    <div className="bg-primary px-3 mb-3 border-radius-6">
                                        <div className="row align-items-center">
                                            <div className="col-10 mr-5">
                                                <div className="d-flex align-items-center bg-primary w-100">
                                                    <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                                                        <h5 className="mr-2 mb-0 text-white">
                                                            <span>Consumables</span>
                                                        </h5>
                                                    </div>
                                                    <SearchComponent
                                                        querySearchSelector={queryConsSearchSelector}
                                                        setQuerySearchSelector={setQueryConsSearchSelector}
                                                        clearFilteredData={clearFilteredData}
                                                        handleSnack={handleSnack}
                                                        searchAPI={getConsumables}
                                                        type={"consumables"}
                                                        searchClick={handleQuerySearchClick}
                                                        options={CONSUMABLE_SEARCH_Q_OPTIONS}
                                                        color={"white"}
                                                        buttonText={"ADD ITEM"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DataGrid sx={GRID_STYLE} rows={consumableItems} columns={columnsConsumables} pageSize={5} rowsPerPageOptions={[5]} autoHeight />
                                </div>
                            </TabPanel>
                            <TabPanel value="extwork">
                                {!extWorkViewOnly ? (
                                    <div className="row mt-2 input-fields">
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCode} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.jobCodeDescription} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4"></div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                                                <input
                                                    type="text"
                                                    class="form-control border-radius-10 text-primary"
                                                    value={extWorkData.payer}
                                                    onChange={(e) => setExtWorkData({ ...extWorkData, payer: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={extWorkData.totalPrice} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="form-group mt-3 mb-0 text-right">
                                                <button type="button" className="btn btn-light bg-primary text-white" onClick={updateExtWorkHeader}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mt-4">
                                        <ReadOnlyField label="JOB CODE" value={extWorkData.jobCode} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="JOB CODE DESCRIPTION" value={extWorkData.jobCodeDescription} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="PAYER" value={extWorkData.payer} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="PRICE METHOD" value={extWorkData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                                        <ReadOnlyField label="NET PRICE" value={extWorkData.totalPrice} className="col-md-4 col-sm-4" />
                                    </div>
                                )}
                                <hr />

                                <div className="">
                                    <div className="bg-primary px-3 mb-3 border-radius-6">
                                        <div className="row align-items-center">
                                            <div className="col-10 mr-5">
                                                <div className="d-flex align-items-center bg-primary w-100">
                                                    <div className="d-flex mr-3" style={{ whiteSpace: "pre" }}>
                                                        <h5 className="mr-2 mb-0 text-white">
                                                            <span>External Work</span>
                                                        </h5>
                                                    </div>
                                                    <SearchComponent
                                                        querySearchSelector={queryExtSearchSelector}
                                                        setQuerySearchSelector={setQueryExtSearchSelector}
                                                        clearFilteredData={clearFilteredData}
                                                        handleSnack={handleSnack}
                                                        searchAPI={getExtWork}
                                                        type={"extwork"}
                                                        searchClick={handleQuerySearchClick}
                                                        options={EXTWORK_SEARCH_Q_OPTIONS}
                                                        color={"white"}
                                                        buttonText="ADD ITEM"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DataGrid sx={GRID_STYLE} rows={extWorkItems} columns={columnsExternal} pageSize={5} autoHeight rowsPerPageOptions={[5]} />
                                </div>
                            </TabPanel>
                            <TabPanel value="othrMisc">
                                {!miscViewOnly ? (
                                    <div className="row mt-2 input-fields">
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCode} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">JOB CODE DESCRIPTION</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.jobCodeDescription} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">TYPE OF MISC.</label>
                                                <Select
                                                    onChange={(e) => setMiscData({ ...miscData, type: e })}
                                                    // closeMenuOnSelect={false}
                                                    options={miscTypeList}
                                                    value={miscData.type}
                                                    isMulti
                                                    styles={FONT_STYLE_SELECT}
                                                />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">NET PRICE</label>
                                                <input type="text" disabled class="form-control border-radius-10 text-primary" value={miscData.totalPrice} />
                                                <div className="css-w8dmq8">*Mandatory</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            <div class="form-group mt-3">
                                                <label className="text-light-dark font-size-12 font-weight-600">PAYER</label>
                                                <input
                                                    type="text"
                                                    class="form-control border-radius-10 text-primary"
                                                    value={miscData.payer}
                                                    onChange={(e) => setMiscData({ ...miscData, payer: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="form-group mt-3 mb-0 text-right">
                                                <button type="button" className="btn btn-light bg-primary text-white" onClick={updateMiscHeader}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mt-4">
                                        <ReadOnlyField label="JOB CODE" value={miscData.jobCode} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="JOB CODE DESCRIPTION" value={miscData.jobCodeDescription} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField label="PAYER" value={miscData.payer} className="col-md-4 col-sm-4" />
                                        <ReadOnlyField
                                            label="TYPE OF MISC."
                                            value={
                                                <>
                                                    {miscData.type?.map((element) => (
                                                        <div>{element?.label}</div>
                                                    ))}
                                                </>
                                            }
                                            className="col-md-4 col-sm-4"
                                        />
                                        <ReadOnlyField label="PRICE METHOD" value={miscData.pricingMethod?.label} className="col-md-4 col-sm-4" />

                                        <ReadOnlyField label="NET PRICE" value={miscData.totalPrice} className="col-md-4 col-sm-4" />
                                    </div>
                                )}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Modal.Body>
            </Modal>

            {searchResultConsOpen && (
                <Modal show={searchResultConsOpen} onHide={handleSearchResConsClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header className="modal-header-border">
                        <Modal.Title>Search Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 bg-white">
                        <div className="card w-100 p-2">
                            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
                                <DataGrid
                                    sx={GRID_STYLE}
                                    rows={masterData}
                                    columns={columnsConsumableSearch}
                                    pageSize={5}
                                    autoHeight
                                    rowsPerPageOptions={[5]}
                                    onRowClick={(e) => selectConsumableItem(e.row)}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {searchResultExtWorkOpen && (
                <Modal show={searchResultExtWorkOpen} onHide={handleSearchResExtClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header className="modal-header-border">
                        <Modal.Title>Search Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0 bg-white">
                        <div className="card w-100 p-2">
                            <div className="" style={{ height: 400, width: "100%", backgroundColor: "#fff" }}>
                                <DataGrid
                                    sx={GRID_STYLE}
                                    rows={masterData}
                                    columns={columnsExtWorkSearch}
                                    pageSize={5}
                                    autoHeight
                                    rowsPerPageOptions={[5]}
                                    onRowClick={(e) => selectExtWorkItem(e.row)}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {consumableItemOpen && (
                <ShopRepairConstItemAddModal
                    consumableItemOpen={consumableItemOpen}
                    handleConsumableItemClose={handleConsumableItemClose}
                    setConsumableItemData={setConsumableItemData}
                    consumableItemData={consumableItemData}
                    consumableTypeList={consumableTypeList}
                    handleConsumableSearch={handleConsumableSearch}
                    searchConsumableResult={searchConsumableResult}
                    handleConsumableSelect={handleConsumableSelect}
                    noOptionsConsumable={noOptionsConsumable}
                    addConsumableItem={addConsumableItem}
                    serviceEstimateData={operationData}
                    handleVendorSearch={handleVendorSearch}
                    searchVenodrResults={searchVenodrResults}
                    handleVendorSelect={handleVendorConsSelect}
                    noOptionsVendor={noOptionsVendor}
                />
            )}

            {extWorkItemOpen && (
                <ShopRepairExtWorkItemAddModal
                    extWorkItemOpen={extWorkItemOpen}
                    handleExtWorkItemClose={handleExtWorkItemClose}
                    setExtWorkItemData={setExtWorkItemData}
                    searchVenodrResults={searchVenodrResults}
                    handleVendorSelect={handleVendorSelect}
                    noOptionsVendor={noOptionsVendor}
                    extWorkItemData={extWorkItemData}
                    serviceEstimateData={operationData}
                    handleVendorSearch={handleVendorSearch}
                    dimensionList={dimensionList}
                    addExtWorkItem={addExtWorkItem}
                    activityIdList={activityIdList}
                    unitOfMeasureOptions={unitOfMeasureOptions}
                />
            )}
        </>
    );
};

export default ShopRepairOtherCostModal;
